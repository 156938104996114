import React, { useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CardMedia,
  IconButton,
  Avatar,
  Button,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import teacherplaceholder from "../assets/teacherplaceholder.png";
import Dialog from "@material-ui/core/Dialog";
import fallbackImage from "../assets/fallbackImage.svg";
import CloseIcon from "@material-ui/icons/Close";
import icon_english from "../assets/icon_english.svg";

const useStyle = makeStyles((theme) => ({
  cardStyle: {
    backgroundColor: theme.palette.common.ltgrey,
    width: "16em",
    height: "11.5em",
  },
  cardMedia: {
    height: "5em",
    width: "7em",
    borderRadius: 5,
    marginTop: 0,
    marginBottom: 0,
  },
  cardIcons: {
    height: "3.2em",
    width: "3.2em",
    marginBottom: 0,
  },

  positionText: {
    width: "5em",
    textAlign: "center",
    marginLeft: "1em",
  },

  dialogContainer: {
    width: "35em",
    height: "30em",
    msOverflowStyle: "none" /* IE 11 */,
    scrollbarWidth: "none",
  },
  dialogTitle: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  dialogImg: {
    height: "9em",
    width: "13em",
    margin: "1em",
  },
  detail: {
    margin: "0.5em",
    marginLeft: "1em",
  },
  overlay: {
    visibilty: "hidden",
    display: "flex",
    width: "16em",
    height: "9.5em",
    position: "absolute",
    backgroundColor: theme.palette.common.blackOpacity2,
    color: "white",
    zIndex: theme.zIndex.drawer,
    cursor: "pointer",
    borderRadius: 5,
  },
}));

function DetailsDialogBox(props) {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <Dialog
      onClose={props.handleClose}
      transitionDuration={{ accept: 0, enter: 700, exit: 300 }}
      classes={{ paper: classes.dialogContainer }}
      aria-labelledby="simple-dialog-title"
      open={props.open}
    >
      <Grid container direction="column" className={classes.dialogContainer}>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          className={classes.dialogTitle}
        >
          <Grid item>
            <Typography variant="h5" style={{ marginLeft: "1em" }}>
              {" "}
              Teacher Details
            </Typography>
          </Grid>
          <Grid item style={{ marginRight: "1em" }}>
            <IconButton onClick={props.handleClose}>
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item>
            <img
              src={
                props.img && props.img === null ? teacherplaceholder : props.img
              }
              alt={props.user.profilePicture}
              className={classes.dialogImg}
            />
          </Grid>
          <Grid item container direction="column" style={{ margin: "1em" }} sm>
            <Grid item>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "black" }}
              >
                {props.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" style={{ color: "black" }}>
                Teacher Id :{" "}
                <span
                  style={{ fontWeight: "bold", color: "black" }}
                >{` ${props.user.empCode}`}</span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" className={classes.detail}>
          <Typography variant="body1">Subjects Handled : &#160; </Typography>
          <Typography variant="body1">{`${props.user.subjectName}`}</Typography>
        </Grid>
        <Grid item container direction="row" className={classes.detail}>
          <Typography variant="body1">Classes Handled : &#160;</Typography>
          <Typography variant="body1">{props.user.className} Std</Typography>
        </Grid>
        <Grid item container direction="row" className={classes.detail}>
          <Typography variant="body1">Experience : &#160;</Typography>
          <Typography variant="body1">{props.user.experience}</Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function DetailsOtherDialogBox(props) {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <Dialog
      onClose={props.handleClose}
      transitionDuration={{ accept: 0, enter: 700, exit: 300 }}
      classes={{ paper: classes.dialogContainer }}
      aria-labelledby="simple-dialog-title"
      open={props.open}
    >
      <Grid container direction="column" className={classes.dialogContainer}>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          className={classes.dialogTitle}
        >
          <Grid item>
            <Typography variant="h5" style={{ marginLeft: "1em" }}>
              {props.type}
            </Typography>
          </Grid>
          <Grid item style={{ marginRight: "1em" }}>
            <IconButton onClick={props.handleClose}>
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item>
            <img
              src={
                props.img && props.img === null ? teacherplaceholder : props.img
              }
              alt={props.user.profilePicture}
              className={classes.dialogImg}
            />
          </Grid>
          <Grid item container direction="column" style={{ margin: "1em" }} sm>
            <Grid item>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "black" }}
              >
                {props.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" style={{ color: "black" }}>
                Employee Id:{" "}
                <span
                  style={{ fontWeight: "bold", color: "black" }}
                >{` ${props.user.empCode}`}</span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" className={classes.detail}>
          <Typography variant="body1">Department : &#160;</Typography>
          <Typography variant="body1">{props.user.department}</Typography>
        </Grid>
        <Grid item container direction="row" className={classes.detail}>
          <Typography variant="body1">Designation : &#160;</Typography>
          <Typography variant="body1">{props.user.designation}</Typography>
        </Grid>
        <Grid item container direction="row" className={classes.detail}>
          <Typography variant="body1">Experience : &#160;</Typography>
          <Typography variant="body1">{props.user.experience}</Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default function DetailCard(props) {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [backopen, setBackOpen] = useState(false);

  const [openOther, setOpenOther] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseOther = () => {
    setOpenOther(false);
  };

  const handleOpen = (event, user) => {
    if (user.subjectName != undefined) {
      setOpen(true);
      setUser(user);
    } else {
      setOpen(true);
      setUser(user);
    }
  };

  const handleOpenOther = (event, user) => {
    setOpenOther(true);
    setUser(user);
  };
  return (
    <React.Fragment>
      <DetailsDialogBox
        open={open}
        setOpen={setOpen}
        user={user}
        name={props.name}
        img={props.img}
        handleClose={handleClose}
      />

      <DetailsOtherDialogBox
        open={openOther}
        setOpen={setOpenOther}
        user={user}
        name={props.name}
        img={props.img}
        type={props.type}
        handleClose={handleCloseOther}
      />
      <Card
        className={classes.cardStyle}
      onMouseEnter={() => {
        setBackOpen(true);
      }}
      onMouseLeave={() => {
        setBackOpen(false);
      }}

      >
        {/* <div
          className={classes.overlay}
          style={{ opacity: backopen ? "1" : "0", transition: "opacity 0.5s" }}
        >
          <div style={{ margin: "auto" }}>Click to view details</div>
        </div> */}
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              spacing={0}
            // style={{ margin: "0.5em", marginBottom: 0, paddingBottom: 0 }}
            >
              <Grid item>
                <CardMedia
                  className={classes.cardMedia}
                  image={props.img === null ? teacherplaceholder : props.img}
                />
                <Typography
                  variant="subtitle1"
                  style={{ fontColor: "black", fontWeight: 'bold', textAlign: 'center' }}
                >
                  {props.name}
                </Typography>
              </Grid>
              <Grid item container direction="column" sm style={{ textAlign: 'right' }}>
                {props.type === "subjectteachers" ? (
                  <React.Fragment>
                    <Grid item style={{ paddingRight: '12px' }}>
                      <Typography
                        variant="subtitle1"
                        style={{ marginTop: 0, color: '#ccc', fontSize: '10px' }}
                      >
                        Subject
                      </Typography>
                    </Grid>
                    <Grid item style={{ paddingRight: '12px' }}>
                      <Typography
                        variant="subtitle1"
                        style={{ marginTop: 0, fontWeight: 'bold', }}
                      >
                        {props.user.subject}
                      </Typography>
                    </Grid>
                    <Grid item >
                      <IconButton variant="subtitle1" disableRipple>
                        <Avatar
                          src={props.icon === null ? icon_english : props.icon}
                          fallbackImage
                          className={classes.cardIcons}
                          alt="icons"
                        />
                      </IconButton>
                    </Grid>

                  </React.Fragment>
                ) : (
                  <Typography
                    variant="subtitle1"
                    className={classes.positionText}
                  >
                    {props.user.staffType}
                  </Typography>
                )}
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                  }}
                  onClick={(event) => {
                    if (props.type === "subjectteachers") {
                      handleOpen(event, props.user, props.type);
                    } else {
                      handleOpenOther(event, props.user, props.type);
                    }
                  }}
                >View Details</Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Card>
    </React.Fragment>
  );
}
