import React, { useState, useEffect } from "react";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import TabMaker from "./TabMaker";
import TableMaker from "./TableMaker";
import { makeStyles } from "@material-ui/core/styles";
import { groupBy } from "lodash";
import { url } from "./store.js";
import { set } from "date-fns";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
}));

const headers = ["Student", "Roll No.", "Parent Name", "Blood Group", "Action"];

export default function StudentDetails() {
  const classes = useStyle();
  const [connectZone, setConnectZone] = useState([]);
  const [renderAg, setRenderAg] = useState(false);
  const [loader, setLoader] = useState(true);
  const bodyObjTextBookLinks = {
    requestList: [
      {
        isEncrypt: false,
        transactionId: "0000001438241244",
        busiCode: "SCHOOL_SEARCH_STUDENT",
        busiParams: {
          branchId: window.localStorage.branchId,

          sectionId: window.localStorage.sectionId,
        },
      },
    ],
    pubInfo: {
      appId: "appId",
      sessionId: window.localStorage.sessionId,
      version: "21",
      timestamp: "20180314175419",
      lang: "en_US",
      userId: "1000016614",
      serviceId: "7021150585",
      circleId: "MU",
      ncsroute: "WE015",
      customerId: "1000016614",
      osType: "windows",
    },
  };

  useEffect(() => {
    setLoader(true);
    const AllLists = [];

    let row = [];
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode:
                window.localStorage.teacherType == "Class"
                  ? "SCHOOL_SEARCH_STUDENT"
                  : "SCHOOL_GET_STUDENTS_BY_SECSUBSYLMAPPING_ID",
              busiParams:
                window.localStorage.teacherType == "Class"
                  ? {
                      branchId: window.localStorage.branchId,

                      sectionId: window.localStorage.sectionId,
                    }
                  : {
                      secSubSylMappingId: window.localStorage.secSylMappingId,
                    },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
                row.push([
                  {
                    title: `${item.studentFirstName} ${item.studentMiddleName} ${item.studentLastName}`,
                    type: "text",
                  },
                  { title: item.rollNo, type: "text" },
                  {
                    title: `${item.fatherFirstName} ${item.fatherMiddleName} ${item.fatherLastName}`,
                    type: "text",
                  },

                  {
                    title: item.bloodGroupName,
                    type: "text",
                  },
                  {
                    title: "",
                    type: "studentDetails",
                    fullDetails: item,
                  },
                ]);
              })
            : row.push([
                { title: "No Data Available", type: "text" },
                { title: "", type: "text" },
                { title: "", type: "text" },
                {
                  title: " ",
                  type: "text",
                },
                { title: "", type: "text" },
                {
                  title: " ",
                  type: "text",
                },
                { title: "", type: "text" },
                {
                  title: " ",
                  type: "text",
                },
                { title: "", type: "text" },
                {
                  title: " ",
                  type: "text",
                },
              ]);

          setConnectZone(row);
        })

        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setInterval(() => {
      setLoader(false);
    }, 3000);
  }, [renderAg]);

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TableMaker
              columns={headers}
              rows={connectZone}
              renderAg={renderAg}
              setRenderAg={setRenderAg}
            />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
