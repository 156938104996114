import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import WorkSpaceHeader from "./Ui/WorkSpaceHeader.js";

import { Grid, Typography, Fade } from "@material-ui/core";

import { useRouteMatch } from "react-router-dom";

import SubjectManager from "./Ui/SubjectManager.js";
import AttendanceTracker from "./Ui/AttendanceTracker.js";
import ApplyLeave from "./Ui/ApplyLeave.js";
import ApplyLeaveTeacher from "./Ui/ApplyleaveTeacher.js";
import AttendanceTeacher from "./Ui/AttendanceTeacher.js";
import MarkAttendance from "./Ui/MarkAttendance.js";
const useStyle = makeStyles((theme) => ({
  mainContainer: {
    ...theme.typography.mainContainer,
    height: "100% ",
    minHeight: "130vh",
  },
  headingItem: {
    ...theme.typography.tilesHeading,
    boxShadow: theme.shadows[5],
  },
}));

export default function Manage(props) {
  const classes = useStyle();
  let { url } = useRouteMatch();

  const [force, setForce] = useState(false);
  useEffect(() => {

  }, []);

  const subjectmanager = (
    <React.Fragment>
      {console.log(window.location.pathname)}
      <Fade
        in={window.location.pathname === `${url}/SubjectManager`}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="SUBJECT MANAGER" moduleId={23} />
          <SubjectManager force={force} setForce={setForce} />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const attendance =
    window.localStorage.roleId === "3" ? (
      <React.Fragment>
        {console.log(window.location.pathname)}
        <Fade
          in={window.location.pathname === `${url}/Attendance`}
          mountOnEnter
          unmountOnExit
          timeout={{ accept: 800, enter: 1000, exit: 300 }}
        >
          <Grid item container direction="column">
            <WorkSpaceHeader
              title="ATTENDANCE"
              moduleId={24}
              attendance={true}
              markAttendance={true}
            />
            <AttendanceTeacher force={force} setForce={setForce} />
          </Grid>
        </Fade>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {console.log(window.location.pathname)}
        <Fade
          in={window.location.pathname === `${url}/Attendance`}
          mountOnEnter
          unmountOnExit
          timeout={{ accept: 800, enter: 1000, exit: 300 }}
        >
          <Grid item container direction="column">
            <WorkSpaceHeader
              title="ATTENDANCE TRACKER"
              moduleId={24}
              annualReport={true}
            />
            <AttendanceTracker force={force} setForce={setForce} />
          </Grid>
        </Fade>
      </React.Fragment>
    );

  const applyleave = (
    <React.Fragment>
          {console.log(window.location.pathname)}
          {console.log(window.localStorage.roleId)}
          {console.log(`${url}/ApplyLeave`)}
      <Fade
        in={window.location.pathname === `${url}/ApplyLeave`}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="APPLY LEAVE" moduleId={30} />
          <ApplyLeave />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const applyleaveteacher = (    
    <React.Fragment>
       {console.log(window.location.pathname)}
       {console.log(window.localStorage.roleId)}
      {console.log(`${url}/ApplyLeave`)}
      <Fade
        in={window.location.pathname === `${url}/ApplyLeave`}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="LEAVE REQUESTS" moduleId={30} />
          <ApplyLeaveTeacher />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const markattendance = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/markAttendance`}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="ATTENDANCE" moduleId={24} back={true} />
          <MarkAttendance />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        className={classes.mainContainer}
        style={{ width: `calc(100% - 12em)` }}
      >
        {window.location.pathname === "/Manage/SubjectManager" ? (
          subjectmanager
        ) : window.location.pathname === "/Manage/Attendance" ? (
          attendance
        ) : window.location.pathname === "/Manage/markAttendance" ? (
          markattendance
        ) : window.location.pathname === "/Manage/ApplyLeave" ? (
          window.localStorage.roleId === "5" ? (
            applyleave
          ) : (
            applyleaveteacher
          )
        ) : (
          <Typography variant="h1">404 Error</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
}
