import React, { useState, useEffect } from "react";

import { Bar } from "react-chartjs-2";
import { defaults } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { Chart } from "chart.js";

require("./roundedbar.js");

function ChartMaker(props) {
  const [state, setState] = useState(props.dataset);
  const theme = useTheme();

  useEffect(() => {
    setState(props.dataset)
  }, [props])


  defaults.global.defaultFontFamily = theme.typography.fontFamily;

  return (
    <React.Fragment>
      <Grid
        item
        container
        style={{
          backgroundColor: "#ffffff",
          marginTop: props.margintop,
          width: props.width,
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        <Grid
          item
          container
          style={{ overflowY: "auto", scrollbarWidth: "none" }}
        >
          <Bar
            height={props.height}
            data={state}
            onElementsClick={props.showClassWisePerf}
            options={{
              "animation": props.showBarVal === true ? {
                "duration": 1,
                "onComplete": function () {
                  var chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                  ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'bottom';

                  this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                      var data = dataset.data[index];
                      ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                  });
                }
              } : {},
              cornerRadius: props.cornerRadius,
              tooltips: { mode: "index" },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min: state.isNegative ? -50 : 0,
                      max: props.maxsize ? props.maxsize : 100,
                      stepSize: props.step ? props.step : 25,
                      callback:
                        props.percentage === true
                          ? function (value, index, values) {
                            return value + "%";
                          }
                          : function (value, index, values) {
                            return value;
                          },
                    },
                    gridLines: {},
                    scaleLabel: {
                      display: props.yAxisLabel ? true : false,
                      labelString: props.yAxisLabel,
                    },
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                    barThickness: 30,
                  },
                ],
              },

              layout: {
                padding: {
                  left: 5,
                  right: 5,
                  top: 40,
                  bottom: 0,
                },
              },
              title: {
                display: false,
                text: "Overall Performance",
                fontSize: 15,
              },
              legend: {
                display: props.legends === false ? false : true,
                position: "bottom",

                labels: {
                  boxWidth: 10,
                  fontSize: 10,
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ChartMaker;
