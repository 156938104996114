import React from "react";

import { Bar } from "react-chartjs-2";
import { defaults } from "react-chartjs-2";
import { Grid, Typography } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles";


require("../Ui/roundedbar.js");

function ChapterDetailedChart(props) {
  const theme = useTheme();

  defaults.global.defaultFontFamily = theme.typography.fontFamily;

 

  return (
    <React.Fragment>
      <Grid
        item
        container
        style={{
          backgroundColor: "#ffffff",
          margin: props.margin,
          width: props.width,
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
        spacing={3}
      >
        <Grid
          item
          container
        >
          <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000' }}>{props.heading}</Typography>
          <Bar
            height={props.height}
            data={{
              labels: props.labels,
              datasets: [
                {
                  backgroundColor: props.paasingStatus.map((i) => i === true ? '#66bb6a' :  'red'),
                  borderColor: props.paasingStatus.map((i) => i === true ? '#66bb6a' :  'red'),
                  borderWidth: 2,
                  data: props.data,
                  pointRadius: 4,
                  barThickness: "flex",
                  barPercentage: 0.5,
                  categoryPercentage: 0.3,
                  borderSkipped: "left",                  
                }
              ]
            }}
            
            options={{
              hover: {
                animationDuration: 0
              },
              animation: {
                duration: 1,
                  onComplete: function() {
                    let chartInstance = this.chart,
                    ctx = chartInstance.ctx;
      
                    ctx.textAlign = 'center';
                    ctx.font = 'bold 15px Venti CF';
                    ctx.zIndex = "0";
      
                    this.data.datasets.forEach(function(dataset, i)
                                               {
                      let meta = chartInstance.controller.getDatasetMeta(i);
                      meta.data.forEach(function(bar, index) {
                        let data = dataset.data[index] + "%";
                        ctx.fillText(data, bar._model.x + 0 , bar._model.y - 5);
                        ctx.font = 'bold 15px Venti CF';
                        ctx.zIndex = "0";
                      });
                    });
                  }
              },
              cornerRadius: 4,
              tooltips: {
                // Disable the on-canvas tooltip
                enabled: false,
    
                custom: function(tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById('chartjs-tooltip');
    
                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        document.body.appendChild(tooltipEl);
                    }
    
                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }
                    
    
                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }
    
                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }
    
                    // Set Text
                    if (tooltipModel.body) {
                        var titleLines = tooltipModel.title || [];
                        var bodyLines = tooltipModel.body.map(getBody);
    
                        var innerHtml = '<thead>';
    
                        titleLines.forEach(function(title) {
                            innerHtml += '<tr><th>' + title + '</th></tr>';
                        });
                        innerHtml += '</thead><tbody>';
    
                        bodyLines.forEach(function(body, i) {
                            var colors = tooltipModel.labelColors[i];
                            var style = 'background: green';
                            style += '; border-color:' + colors.borderColor;
                            style += '; border-width: 2px';
                            var span = '<span style="' + style + '"></span>';
                            innerHtml += '<tr><td>' + span + body + '</td></tr>';
                        });
                        innerHtml += '</tbody>';
    
                        var tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;
                    }
    
                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();
    
                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 0.8;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    tooltipEl.style.fontFamily = "Venti CF";
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = "3px 7px";
                    tooltipEl.style.pointerEvents = 'none';
                    tooltipEl.style.backgroundColor = "#000";
                    tooltipEl.style.color = "#fff";
                    tooltipEl.style.borderRadius = "3px";
                }
            },
            
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min: 0,
                      max: props.maxsize ? props.maxsize : 100,
                      stepSize: props.step ? props.step : 25,
                      callback:
                        props.percentage === true
                          ? function (value, index, values) {
                            return value + "%";
                          }
                          : function (value, index, values) {
                            return value;
                          },
                    },
                    gridLines: {},
                    scaleLabel: {
                      display: props.yAxisLabel ? true : false,
                      labelString: props.yAxisLabel,
                    },
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                  },
                ],
              },
              onClick: props.barClick,
              layout: {
                padding: {
                  left: 5,
                  right: 5,
                  top: 40,
                  bottom: 0,
                },
              },
              legend: {
                display: false,
                position: "bottom",

                labels: {
                  boxWidth: 10,
                  fontSize: 10,
                },
              },
            }}
          />

<Typography component="div" style={{width:'100%',display: 'flex', justifyContent: 'center'}}>
  <Typography component="span" style={{margin: '0 10px', display: 'flex', alignItems: 'center'}}>
    <Typography component="span" style={{width: '20px', height: '20px', display: 'inline-block', borderRadius: '50%', background: '#66bb6a', marginRight: '10px'}}></Typography>
    Strength
  </Typography >
  <Typography component="span" style={{margin: '0 10px', display: 'flex', alignItems: 'center'}}>
  <Typography component="span" style={{width: '20px', height: '20px', display: 'inline-block', borderRadius: '50%', background: 'red', marginRight: '10px'}}></Typography>
    Improvement Needed
  </Typography>
</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ChapterDetailedChart;
