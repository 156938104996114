import React, { useState, useEffect } from "react";
import {
  Grid,
  List,
  ListItem,
  CircularProgress,
  ListItemText,
} from "@material-ui/core";
import TabMaker from "./TabMaker";
import TableMaker from "./TableMaker";
import { groupBy } from "lodash";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import pdf from "../assets/pdficon.jpg";
import { url } from "./store.js";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
}));

const MyDataMaker = (props) => {
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_ALL_MAPPED_TBL_WITH_BRANCH",
              busiParams: {
                branchId: window.localStorage.branchId,
                sectionId: window.localStorage.sectionId,
                subjectId: props.subjectId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())

        .then((data) => {
          let row = [];
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
                //  dataset.labels.push(item.resourceName);
                //  dataset.datasets[0].data.push(
                //parseInt(item.taskPerc.replace("%", ""))
                //    );
                console.log(item);
                row.push(item);
                //   mydata.push([
                //     { title: item.name, type: "text" },
                //     { title: item.description, type: "text" },
                //     { title: item.dueDate, type: "text" },
                //     {
                //       title: item.fileName,
                //       type: item.fileType,
                //       href: item.attachment,
                //     },
                //   ]);
              })
            : row.push({
                attachment: "",
                branchId: 0,
                chapterId: 0,
                chapterName: "",
                chapterSectionId: 0,
                chapterSectionName: null,
                createdBy: 0,
                createdOn: "2020-06-06 05:33:13",
                deleted: false,
                description: "No Data Available",
                dueDate: "",
                fileName: "",
                fileType: "",
                name: "No Data Available",
                resourceId: 85,
                resourceType: 29,
                resourceTypeName: "TBL",
                secSubjectMappingId: 0,
                sectionId: 0,
                status: "",
                subjectId: 0,
                target: "",
                updatedBy: "",
                updatedOn: "",
                url: null,
              });

          setRows(row);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    }
  }, []);

  return (
    <React.Fragment>
      {loader === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <ListMaker data={rows} />
      )}
    </React.Fragment>
  );
};

const ListMaker = (props) => {
  const [render, setRender] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setRender(!render);
  }, [props.data]);

  return (
    <List style={{ marginTop: "-1em" }} dense={true}>
      {props.data.map((item, index) => (
        <ListItem
          component="a"
          href={item.url === null ? undefined : item.url}
          key={index}
          target={item.url === null ? undefined : "_blank"}
        >
          <ListItemText
            style={{
              color:
                theme.typography.myTheme.themeName == "Night"
                  ? "white"
                  : item.url === null
                  ? "black"
                  : "#009DFF",
            }}
          >
            {item.name}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

const listItems = [
  "Mathematics Course Book",
  "Maths Class V Exampler",
  "Chapter 1 - How Many Squares?",
  "Chapter 2 - Parts and Wholes",
  "Chapter 3 - Shapes And Angles",
  "Chapter 4 - How Many Squares?",
];

export default function TextBookLinks() {
  const classes = useStyle();
  const theme = useTheme();
  const [textBookLinksData, settextBookLinksData] = useState([]);
  const [loader, setLoader] = useState(true);
  const subjects = JSON.parse(window.localStorage.getItem("users") || "[]");
  let tabheadings = [];
  try {
    tabheadings = subjects.map((a) => a.subjectName);
  } catch (error) {
    console.error(error);
  }

  const bodyObjTextBookLinks = {
    requestList: [
      {
        isEncrypt: false,
        transactionId: "0000001438241244",
        busiCode: "SCHOOL_GET_ALL_MAPPED_TBL_WITH_BRANCH",
        busiParams: {
          branchId: window.localStorage.branchId,
          sectionId: window.localStorage.sectionId,
          // subjectId: props.subjectId,
        },
      },
    ],
    pubInfo: {
      appId: "appId",
      sessionId: window.localStorage.sessionId,
      version: "21",
      timestamp: "20180314175419",
      lang: "en_US",
      userId: "1000016614",
      serviceId: "7021150585",
      circleId: "MU",
      ncsroute: "WE015",
      customerId: "1000016614",
      osType: "windows",
    },
  };

  useEffect(() => {
    const AllLists = [];
    let subjects = JSON.parse(window.localStorage.getItem("users") || "[]");
    console.log(subjects);
    subjects &&
      subjects.map((item, index) => {
        let temList = (
          <MyDataMaker key={index} secId={item.secSubSylMappingId} subjectId={item.subjectId}/>
        );

        AllLists.push(temList);
      });
    console.log(AllLists);
    settextBookLinksData(AllLists);

    setInterval(() => {
      setLoader(false);
    }, 500);
  }, []);

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const DATA = await fetch(url, {
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //         method: "POST",
  //         body: JSON.stringify(bodyObjTextBookLinks),
  //       });
  //       let tempEvents = [];
  //       const RESPONSE = await DATA.json();
  //       console.log(RESPONSE);
  //       RESPONSE.respData[0].respMsg.result.map((item, index) => {
  //         tempEvents.push(item);
  //       });

  //       const AllLists = [];

  //       subjects.map((data, myindex) => {
  //         tempEvents.map((item, index, arr) => {
  //           if (item.secSubjectMappingId === data.secSubSylMappingId) {
  //             arr[index] = { ...arr[index], subjectMapPosition: myindex };
  //           }
  //         });
  //       });

  //       let grouped = groupBy(tempEvents, (item) => item.subjectMapPosition);

  //       console.log(grouped);
  //       for (const x in grouped) {
  //         let mydata = grouped[x];
  //         let temList = (
  //           <List style={{ marginTop: "-1em" }} key={x} dense={true}>
  //             {mydata.map((item, index) => (
  //               <ListItem
  //                 component="a"
  //                 href={item.url}
  //                 key={item + index}
  //                 target="_blank"
  //               >
  //                 <ListItemText style={{ color: "#009DFF" }}>
  //                   {item.name}
  //                 </ListItemText>
  //               </ListItem>
  //             ))}
  //           </List>
  //         );

  //         AllLists.push(temList);
  //       }
  //       console.log(AllLists);
  //       settextBookLinksData(AllLists);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getData();
  // }, []);

  //const myData = [Lists];

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TabMaker
              tabs={tabheadings}
              data={textBookLinksData}
              subjectBased={true}
            />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
