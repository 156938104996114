import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import WorkSpaceHeader from "./Ui/WorkSpaceHeader.js";

import { Grid, Typography, Fade } from "@material-ui/core";

import { useRouteMatch } from "react-router-dom";
import ChangePassword from "./Ui/ChangePassword.js";
import IssueReport from "./Ui/IssueReport.js";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    ...theme.typography.mainContainer,
    height: "100% ",
    minHeight: "130vh",
  },
  headingItem: {
    ...theme.typography.tilesHeading,
    boxShadow: theme.shadows[5],
  },
}));

export default function Setting(props) {
  const classes = useStyle();
  let { url } = useRouteMatch();

  const changepassword = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/ChangePassword`}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="CHANGE PASSWORD" />
          <ChangePassword />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const reportissue = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/ReportIssue`}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="REPORT ISSUE" />
          <IssueReport />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        className={classes.mainContainer}
        style={{ width: `calc(100% - 12em)` }}
      >
        {window.location.pathname === "/Settings" ? (
          changepassword
        ) : window.location.pathname === "/Settings/ChangePassword" ? (
          changepassword
        ) : window.location.pathname === "/Settings/ReportIssue" ? (
          reportissue
        ) : (
          <Typography variant="h1">404 error</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
}
