import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, CircularProgress, TextField, MenuItem } from "@material-ui/core";
import TabMaker from "./TabMaker";
import ChartMaker from "./ChartMaker.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { makeReqBody, url } from "./store.js";
import { getDisplayDate } from "@material-ui/pickers/_helpers/text-field-helper";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
}));

const MyDataMaker = (props) => {
  const [rows, setRows] = useState({});
  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const [studentlist, setStudentList] = useState([]);
  const [selectedInstanceId, setSelectedInstanceId] = useState(0);

  useEffect(() => {
    setLoader(true);
    if (selectedInstanceId !== 0 || window.localStorage.roleId != "3") {

    let bgColor = [];
    let dataset = {
      labels: [],
      datasets: [
        {
          label: "My Performance",
          backgroundColor: bgColor,
          barThickness: "flex",
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          maxBarThickness: 30,
          borderColor: "#ff6d00",
          borderSkipped: "left",
          data: [],
        },
      ],
    };
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_STUDENT_SUBJECT_MANAGER",
              busiParams: {
                secSubMappingId: props.secId,
                studentInstanceId: window.localStorage.roleId != "3" ? parseInt(window.localStorage.studentInstanceId) : selectedInstanceId,
                boardId: window.localStorage.boardId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          data.respData[0].respMsg.result.map((item, index) => {
            dataset.labels.push(item.resourceName);
            dataset.datasets[0].data.push(
              parseInt(item.taskPerc.replace("%", ""))
            );
            if (item.taskPerc.replace("%", "") > 50) {
              bgColor.push("#00a427")
            } else {
              bgColor.push("#ff0000")
            }
          });
          console.log("dataset", dataset)
          setRows(dataset);
          setInterval(() => {
            setLoader(false);
          }, 2000);
        })

        .catch((data) => {
          console.error(data);
          setInterval(() => {
            setLoader(false);
          }, 1000);
        });
    } catch (error) {
      console.log(error);
      setInterval(() => {
        setLoader(false);
      }, 1000);
    }
    
  }
  }, [selectedInstanceId]);

  useEffect(() => {
    setLoader(true);
    if (window.localStorage.roleId === "3") {
      fetch(url, makeReqBody("SCHOOL_GET_LIST_OF_ACTIVE_STUDENT", {
        boardId: window.localStorage.boardId,
        branchId: window.localStorage.branchId,
        sectionId: window.localStorage.sectionId,
      })).then(res => res.json())
        .then(data => {
          console.log("data", data)
          setStudentList(data.respData[0].respMsg.result);

          setInterval(() => {
            setLoader(false);
          }, 1000);
        })
    }
  }, []);

  return (
    <React.Fragment>
      {loader === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <Grid container direction="column">

          {window.localStorage.roleId === "3" &&
            <Grid item container>
              <Grid item sm={3}>
                <TextField
                  id="studentlist"
                  label="Student List*"
                  select
                  fullWidth
                  variant="outlined"
                  value={selectedInstanceId}
                  onChange={(e) => setSelectedInstanceId(e.target.value)}
                >
                  {studentlist && studentlist.map((item, index) => (
                    <MenuItem key={index} value={item.studentInstanceId}>
                      {item.studentName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>}
          <Grid
            item
            container
            alignItems="center"
            style={{ marginBottom: "0.8em" }}
          >
            <Grid item container sm spacing={4}>
              {
                //   <Grid item>
                //   <Typography variant="body1">
                //     Overall Performance Score
                //   </Typography>
                // </Grid>
              }
            </Grid>
          </Grid>
          {(selectedInstanceId !== 0 || window.localStorage.roleId != "3") &&
            <ChartMaker
              dataset={rows}
              height="95em"
              percentage={true}
              yAxisLabel="Completion"
            />}
        </Grid>
      )}
    </React.Fragment>
  );
};

export default function SubjectManager(props) {
  const classes = useStyle();
  const theme = useTheme();
  const [subjectManager, setSubjectManager] = useState([]);

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);

    let middleware = [];
    let subjects = JSON.parse(window.localStorage.getItem("users") || "[]");
    console.log(subjects);
    subjects &&
      subjects.map((item, index) => {
        let myitem = <MyDataMaker secId={item.secSubSylMappingId} />;

        middleware.push(myitem);

        setSubjectManager(middleware);
      });
    setInterval(() => {
      setLoader(false);
    }, 500);
  }, []);

  let subjects = JSON.parse(window.localStorage.getItem("users") || "[]");
  let tabheadings = [];
  try {
    tabheadings = subjects.map((a) => a.subjectName);
  } catch (error) {
    console.error(error);
  }

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TabMaker tabs={tabheadings} data={subjectManager} />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
