import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Backdrop,
  CircularProgress,
  MenuItem, TableContainer, Table, TableBody, TableHead, TableRow, TableCell,
  FormControl, FormGroup, FormLabel, FormControlLabel, Checkbox,
} from "@material-ui/core";
import ExamTabMakerAnalysis from "../Exam/ExamTabMakerAnalysis";
import ChartMaker from "./ChartMaker.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TrendSetter from "./TrendSetter";
import { useTheme } from "@material-ui/core/styles";
import { url, makeReqBody } from "./store.js";
import { findIndex } from "lodash";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import TableMaker from "./TableMaker";
import ChapterDetailed from "../DetailedAnalysis/ChapterDetailed";
import { ContactSupportOutlined } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  tableReport: {
    backgroundColor: "transparent",
    minwidth: "30em",
    maxHeight: 440,
    '&::-webkit-scrollbar': {
      width: '12px',
      backgroundColor: '#F5F5F5'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
  formControl: {
    width: '100%',
  },
  reportHeading: {
    fontSize: '18px',
    fontWeight: '700',
    margin: '0',
    color: '#000!important',
  }
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ExamAnalysisMaker2 = (props) => {
  return (
    <React.Fragment>
      <Grid item container direction="row" justify="space-between">
        <Grid
          item
          container
          direction="column"
          sm
          style={{ marginRight: "0.5em" }}
        >
          <Grid item container>
            {<TrendSetter data={props.perData} />}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const ExamAnalysisMaker = (props) => {
  const [myMax, setMyMax] = useState(100);

  const getSetMax = (mymax) => {
    let temp = parseInt(mymax / 25);
    mymax = (temp + 1) * 25;
    setMyMax(mymax);
  };

  useEffect(() => {
    let mymax = 0;
    props.perData.datasets[0] &&
      props.perData.datasets[0].data.forEach((item) => {
        if (parseInt(item) > mymax) {
          mymax = parseInt(item);
        }
      });

    console.log(props.perData);
    mymax > 100 ? getSetMax(mymax) : setMyMax(100);
  }, []);

  return (
    <React.Fragment>
      <Grid item container direction="row" justify="space-between">
        <Grid
          item
          container
          direction="column"
          sm
          style={{ marginRight: "0.5em" }}
        >
          <Grid item container>
            {
              <ChartMaker
                dataset={props.perData}
                width="100%"
                maxsize={myMax}
                yAxisLabel={
                  (props.perData.type &&
                    props.perData.type.subCategory &&
                    props.perData.type.subCategory.toLowerCase() ===
                    "highest marks") ||
                    (props.perData.type &&
                      props.perData.type.subCategory &&
                      props.perData.type.subCategory.toLowerCase() ===
                      "top 10 performers") ||
                    (props.perData.type &&
                      props.perData.type.subCategory &&
                      props.perData.type.subCategory.toLowerCase() ===
                      "bottom 10 performers") ||
                    (props.perData.type &&
                      props.perData.type.subCategory &&
                      props.perData.type.subCategory.toLowerCase() ===
                      "average marks") ||
                    (props.perData.type &&
                      props.perData.type.subCategory &&
                      props.perData.type.subCategory.toLowerCase() ===
                      "least marks")
                    ? "Marks"
                    : (props.perData.type &&
                      props.perData.type.subCategory &&
                      props.perData.type.subCategory.toLowerCase() ===
                      "number of failures") ||
                      (props.perData.type &&
                        props.perData.type.subCategory &&
                        props.perData.type.subCategory.toLowerCase() ===
                        "number of 100%")
                      ? "In Numbers"
                      : undefined
                }
              />
            }
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const MyDataMaker = (props) => {
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [studentData, setStudentData] = useState(null);
  const [showDetailAnalysis, setShowDetailAnalysis] = useState(false);
  const [openDownLoadReport, setOpenDownLoadReport] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [studentInstance, setStudentInstance] = React.useState([]);
  const [downloadExamAnalysis, setDownloadExamAnalysis] = useState(false);
  const [downloadDetailAnalysis, setDownloadDetailAnalysis] = useState(false);
  const [downloadClassPerformance, setDownloadClassPerformance] = useState(false);
  const [backLoader, setBackLoader] = useState(false);
  const classes = useStyle();

  // const [performanceData, setPerformanceData] = useState(null);

  useEffect(() => {
    setLoader(true);
    let featuresData = JSON.parse(window.localStorage.getItem("features") || "[]");
    let feature = featuresData && featuresData.filter(item => item.featureId === 38).length > 0;
    try {
      fetch(url,
        makeReqBody("SCHOOL_EA_STUDENT_MARKS", {
          branchId: window.localStorage.branchId,
          examTermId: props.examTermId,
          sectionId: window.localStorage.sectionId,
          secSubMappingId:
            window.localStorage.teacherType == "Class"
              ? 0
              : window.localStorage.secSylMappingId,
        })
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.respData[0].respMsg.result) {
            let stu_data = data.respData[0].respMsg.result.stuMarksRes.map((item) => ({
              ...item,
              disabled: false,
              checked: false,
            }))
            setStudentList(stu_data);
          }
          let row = [];
          data.respData[0].respMsg.result && data.respData[0].respMsg.result.stuMarksRes.length > 0
            ? data.respData[0].respMsg.result.stuMarksRes.map((item, index) => {
              window.localStorage.teacherType == "Class"
                ? row.push([
                  {
                    title: item.studentName,
                    type: "text",
                  },
                  {
                    title: item.rollNo,
                    type: "text",
                  },

                  { title: item.maxMarks, type: "text" },
                  {
                    title: item.marksObtained,
                    type: "text",
                  },
                  {
                    title: "",
                    typeFeature: feature ? "examAnalysisFeature" : "",
                    type: window.localStorage.teacherType == "Class"
                      ? "examAnalysisClass"
                      : "examAnalysisSubject",
                    fullDetails: item,
                  },
                ])
                : row.push([
                  {
                    title: item.studentName,
                    type: "text",
                  },
                  {
                    title: item.rollNo,
                    type: "text",
                  },
                  {
                    title: item.subjectName,
                    type: "text",
                  },
                  { title: item.maxMarks, type: "text" },
                  {
                    title: item.marksObtained,
                    type: "text",
                  },
                  {
                    title: "",
                    typeFeature: feature ? "examAnalysisFeature" : "",
                    type: window.localStorage.teacherType == "Class"
                      ? "examAnalysisClass"
                      : "examAnalysisSubject",
                    fullDetails: item,
                  },
                ]);
            })
            : row.push([
              {
                title: "No Data Available",
                type: "text",
                fullDetails: "hy",
              },
              {
                title: "",
                type: "text",
              },
              { title: "", type: "text" },
              { title: "", type: "text" },
              { title: "", type: "text" },
              {
                title: " ",
                type: "text",
              },
            ]);
          let classPerformnaceObj = data.respData[0].respMsg.result && data.respData[0].respMsg.result.classPerfObj;
          Object.assign(classPerformnaceObj, { studentInstanceId: 0 });
          console.log(classPerformnaceObj);
          let classPerformanceData = window.localStorage.teacherType == "Class" ?
            [{
              title: "Class Performance",
              type: "text",
            },
            {
              title: "",
              type: "text",
            },
            { title: data.respData[0].respMsg.result && data.respData[0].respMsg.result.classPerfObj.totalMarks, type: "text" },
            {
              title: data.respData[0].respMsg.result && data.respData[0].respMsg.result.classPerfObj.average,
              type: "text",
            },
            {
              typeFeature: feature ? "examAnalysisFeature" : "",
              type: window.localStorage.teacherType == "Class"
                ? "examAnalysisClass"
                : "examAnalysisSubject",
              fullDetails: classPerformnaceObj,
            },
            ] :
            [{
              title: "Class Performance",
              type: "text",
            },
            {
              title: "",
              type: "text",
            },
            {
              title: "",
              type: "text",
            },
            { title: data.respData[0].respMsg.result && data.respData[0].respMsg.result.classPerfObj.totalMarks, type: "text" },
            {
              title: data.respData[0].respMsg.result && data.respData[0].respMsg.result.classPerfObj.average,
              type: "text",
            },
            {
              typeFeature: feature ? "examAnalysisFeature" : "",
              type: window.localStorage.teacherType == "Class"
                ? "examAnalysisClass"
                : "examAnalysisSubject",
              fullDetails: classPerformnaceObj,
            },
            ]
          if (data.respData[0].respMsg.result && data.respData[0].respMsg.result.stuMarksRes.length > 0) {
            row.splice(0, 0, classPerformanceData);
          }
          setRows(row);
          setTimeout(() => {
            setLoader(false);
          }, 2000);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, []);

  const examAnalysisDetail = (data) => {
    setStudentData(data);
    setShowDetailAnalysis(true);
  }

  // useEffect(() => {
  //   try {
  //     fetch(
  //       url,
  //       makeReqBody("SCHOOL_GET_CLASS_PERFORMANCE_PERC", {
  //         examTermId: props.examTermId,
  //         sectionId: window.localStorage.sectionId,
  //         subjectId: window.localStorage.teacherType === "Subject" ? window.localStorage.subjectId : ''
  //       })
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setPerformanceData(data.respData[0].respMsg.result);
  //       })
  //       .catch((data) => {
  //         console.error(data);
  //       });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [props.examTermId])


  useEffect(() => {
    console.log(studentInstance);
    console.log(downloadExamAnalysis, downloadDetailAnalysis);
  }, [studentInstance.length, downloadExamAnalysis, downloadDetailAnalysis])

  const addStudent = (index, event) => {
    let checked_student = event.target.value;
    let data_student = [...studentList];
    let studentIstanId = [...studentInstance];
    if (studentIstanId.indexOf(parseInt(checked_student)) === -1) {
      studentIstanId.push(parseInt(checked_student));
      setStudentInstance(studentIstanId);
      data_student[index].checked = true;
      setStudentList(data_student);
    } else {
      let indexLocalIstance = studentIstanId.indexOf(parseInt(checked_student));
      console.log(indexLocalIstance);
      studentIstanId.splice(indexLocalIstance, 1);
      setStudentInstance(studentIstanId);
      data_student[index].checked = false;
      setStudentList(data_student);
      console.log(studentList);

    }

  }

  const selectAllStudent = (event) => {
    if (event.target.checked) {
      let studentdata = [];
      studentdata = studentList.map(item => item.studentInstanceId);
      setStudentInstance(studentdata);

      const data = studentList.map(i => ({ ...i, checked: true }));
      setStudentList(data);

    } else {
      let studentdata = [];
      setStudentInstance(studentdata);

      const data = studentList.map(i => ({ ...i, checked: false }));
      setStudentList(data);
    }
  }

  const classPerformanceCheck = (event) => {
    if (event.target.checked) {
      const data = studentList.map(x => ({ ...x, disabled: true, checked: false }));
      setStudentList(data);
      setStudentInstance([]);
      setDownloadClassPerformance(true);
      setDownloadDetailAnalysis(false);
      setDownloadExamAnalysis(false);

    } else {
      const data = studentList.map(x => ({ ...x, disabled: false }));
      setStudentList(data);
      setDownloadClassPerformance(false);
    }

  }
  const featuresData = JSON.parse(window.localStorage.getItem("features") || "[]");
  const feature = featuresData && featuresData.filter(item => item.featureId === 38).length > 0;

  const downloadReport = () => {
    try {
      setBackLoader(true);
      fetch(
        url,
        makeReqBody("SCHOOL_GET_EXAM_REPORT", {
          branchId: window.localStorage.branchId,
          examTermId: props.examTermId,
          // module: downloadClassPerformance === true ? feature ? '' : "ClassPerfExamAnalysis" : downloadExamAnalysis === true && downloadDetailAnalysis === true ? "ExamDetailedAnalysis" : downloadExamAnalysis === true && downloadDetailAnalysis === false ? "ExamAnalysis" : downloadExamAnalysis === false && downloadDetailAnalysis === true ? "DetailedAnalysis" : "",  
          module: downloadClassPerformance === true ? feature ? '' : "ClassPerfExamAnalysis" : feature ? 'ExamDetailedAnalysis' : 'ExamAnalysis',

          secSubMappingId: window.localStorage.teacherType == "Class" ? 0 : parseInt(window.localStorage.secSylMappingId),
          sectionId: window.localStorage.sectionId,
          studentInstanceId: downloadClassPerformance === true ? null : studentInstance,
        })
      )
        .then((response) => response.json())
        .then((data) => {
          setBackLoader(false);
          if (data.respData[0].respMsg.result && data.respData[0].respMsg.result.url !== null) {
            window.open(data.respData[0].respMsg.result.url);
          }
        })
        .catch((data) => {
          console.error(data);
          setBackLoader(false);
        });
    } catch (error) {
      console.error(error);
      setBackLoader(false);
    }
  }



  return (
    <React.Fragment>
      {loader === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <React.Fragment>

          {window.localStorage.teacherType == "Class" ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) =>
                props.handlePublishDialogOpen(e, props.examTermId)
              }
              style={{
                color: "white",
                marginBottom: "0.5em",
                marginRight: "1em"
              }}
            >
              Publish Report Card
            </Button>
          ) : undefined}
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => props.handleClassPerOpen(e, props.examTermId)}
            style={{
              color: "white",
              marginBottom: "0.5em",
            }}
          >
            Class Performance
          </Button>


          {studentList && studentList.length !== 0 && !showDetailAnalysis && <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenDownLoadReport(true)}
            style={{
              color: "white",
              marginBottom: "0.5em",
              marginLeft: "1em",
            }}
          >
            Download Report
          </Button>}

          {showDetailAnalysis &&
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowDetailAnalysis(false)}
              style={{
                color: "white",
                marginBottom: "0.5em",
                marginLeft: "1em",
              }}
            >
              Back To Student List
            </Button>
          }
          {/* ----- class performnace code ----- */}
          {/* {!showDetailAnalysis &&<Typography component="span" style={{ marginLeft: "10px" }}><Typography component="span" style={{ fontWeight: 700, paddingRight: '5px', fontSize: '18px' }}>Overall Class Performance - </Typography>{performanceData && performanceData.overAllPerc}</Typography>} */}

          {!showDetailAnalysis && <React.Fragment>
            <TableMaker
              columns={props.headers}
              rows={rows}
              toReload={props.toReload}
              setToReLoad={props.setToReLoad}
              examAnalysisDetail={examAnalysisDetail}
              reportCard={props.handleOpenDialog}
            />
          </React.Fragment>
          }

          {showDetailAnalysis && <ChapterDetailed
            examTermId={props.examTermId}
            sectionId={studentData && studentData.sectionId}
            studentInstanceId={studentData && studentData.studentInstanceId}
            render={showDetailAnalysis === true}
            marginTop="10px"
          />
          }

          <Dialog
            open={openDownLoadReport}
            onClose={() => setOpenDownLoadReport(false)}
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => setOpenDownLoadReport(false)}
            >
              Download Report
            </DialogTitle>
            <DialogContent className="customPdfScroll">
              {/* <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="h2" className={classes.reportHeading}>Report Type</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox  
                  name="Exam Analysis"
                  onChange={(event)=>setDownloadExamAnalysis(event.target.checked)}
                  disabled={downloadClassPerformance === true}
                  checked={downloadExamAnalysis === true}                  
                  />}
                label="Exam Analysis"
              />
              {feature && <FormControlLabel
                control={<Checkbox  
                  name="Detail Analysis"
                  onChange={(event)=>setDownloadDetailAnalysis(event.target.checked)}
                  disabled={downloadClassPerformance === true}
                  checked={downloadDetailAnalysis === true}       
                  />}
                label="Detail Analysis"
              />}
            </FormGroup>
        </FormControl> */}

              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox
                      name="Class Performances"
                      onChange={(event) => classPerformanceCheck(event)}
                    />}
                    label="Class Performances"
                  />
                </FormGroup>
              </FormControl>
              <FormControl component="fieldset" className={classes.formControl}>

              </FormControl>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="h2" className={classes.reportHeading}>List of Student</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox
                      name="select all"
                      onChange={(event) => selectAllStudent(event)}
                      checked={studentList.length === studentInstance.length}
                      disabled={downloadClassPerformance === true}
                    />}
                    label="Select All"
                  />
                </FormGroup>
                {studentList && studentList.map((item, index) => {
                  return (<FormGroup key={index}>
                    <FormControlLabel
                      control={<Checkbox
                        name={item.studentName}
                        value={item.studentInstanceId}
                        checked={item.checked}
                        disabled={item.disabled && item.disabled}
                        onChange={(event) => addStudent(index, event)}
                      />}
                      label={item.studentName}
                    />



                  </FormGroup>)
                })}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                color="secondary"
                onClick={() => setOpenDownLoadReport(false)}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ color: "white" }}
                onClick={downloadReport}
                disabled={downloadClassPerformance === false && studentInstance.length == 0}
              >
                Download
              </Button>
            </DialogActions>
          </Dialog>

          <Backdrop className={classes.backdrop} open={backLoader}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default function ExamAnalysisTeacher() {
  const classes = useStyle();
  const theme = useTheme();
  const [subjectManager, setSubjectManager] = useState([]);
  const [tabhead, setTabHead] = useState([]);
  const [loader, setLoader] = useState(true);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [reportHeadings, setReportHeadings] = useState([]);
  const [reportRows, setReportRows] = useState([]);
  const [consolidatedData, setConsolidatedData] = useState([]);
  const [openReportCard, setOpenReportCard] = useState(false);
  const [publishDialog, setPublishDialog] = useState(false);
  const [backDrop, setBackDrop] = useState(false);
  const [notiSnack, setNotiSnack] = React.useState(false);
  const [NotiStatus, setNotiStatus] = React.useState("");
  const [notiMessage, setNotiMessage] = React.useState("");
  const [newData, setNewData] = useState({});
  const [myOptions, setMyOptions] = useState([]);
  const [myloader, setMyLoader] = useState(false);
  const [id, setId] = useState(0);
  const [reRender, setReRender] = useState(false);
  const [maxWidth] = useState("sm");
  const [scroll] = useState("paper");

  const headers =
    window.localStorage.teacherType == "Class"
      ? ["Student", "Roll No.", "Max Marks", "Marks Obtained", "Action"]
      : [
        "Student",
        "Roll No.",
        "Subject",
        "Max Marks",
        "Marks Obtained",
        "Action",
      ];

  const handlePublishDialogClose = () => {
    setPublishDialog(false);
  };

  const handlePublishDialogOpen = (e, id) => {
    setId(id);
    setPublishDialog(true);
  };

  function getRandomColor() {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(() => {
    setLoader(true);
    const getQueryData = async () => {
      let termData = [];
      let subjectName = [];
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_EXAM_TERMS_FOR_BRANCH",
              busiParams: {
                id: window.localStorage.branchId,
                standard: window.localStorage.className.substring(0, 2),
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          data.respData[0].respMsg.result.map((item, index1) => {
            subjectName.push(item.name);
            termData.push(item);
          });


          setTabHead(subjectName);
        })
        .catch((data) => {
          console.log("sorry something went wrong");
        });
      try {
        let AllLists = [];

        termData.map((item, index) => {
          let temList = (
            <MyDataMaker
              key={index}
              headers={headers}
              handlePublishDialogOpen={handlePublishDialogOpen}
              handleClassPerOpen={handleClassPerOpen}
              examTermId={item.examTermId}
              handleOpenDialog={handleOpenDialog}
            />
          );

          AllLists.push(temList);
        });
        setSubjectManager(AllLists);
      } catch (error) {
        console.error(error);
      }
    };

    getQueryData();


    getQueryData().catch((data) => console.error(data));
    setInterval(() => {
      setLoader(false);
    }, 500);
  }, [reRender]);


  const handleClassPerOpen = (e, id) => {
    setId(id);
    try {
      fetch(url,
        makeReqBody("MDM_GET_LOOKUP_SUB_CATG", {
          categoryName: "ClassPerformanceType",
          flag: true,
        })
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("heeyyyyyy");
          console.log(data);
          setMyOptions(data.respData[0].respMsg.results);
          setUploadDialog(true);
        })
        .catch((data) => {
          console.error(data);
          setUploadDialog(true);
        });
    } catch (error) {
      console.error(error);
      setUploadDialog(true);
    }
  };

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
  };

  const handlePublishConfirmDialog = async (e) => {
    setBackDrop(true);
    try {
      await fetch(url,
        makeReqBody("SCHOOL_PUBLISH_REPORT_CARD_SECTION_ID", {
          branchId: window.localStorage.branchId,
          examTermId: id,
          sectionId: window.localStorage.sectionId,
          studentInstanceId: 0,
          userId: window.localStorage.userId,
        })
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.respData[0].respMsg.status.toLowerCase() === "failure") {
            setNotiMessage(data.respData[0].respMsg.message);
            setNotiStatus("error")
            setNotiSnack(true);
            setBackDrop(false);
          } else {
            setNotiMessage(data.respData[0].respMsg.message);
            setNotiStatus("success");
            setNotiSnack(true);
            setBackDrop(false);
            setReRender(!reRender);
          }
        })
        .catch((data) => {
          console.error(data);
          setBackDrop(false);
          // setError(true);
        });
    } catch (error) {
      console.log(error);
      setBackDrop(false);
    }
    handlePublishDialogClose();
  };

  const handleChange = (e) => {
    setMyLoader(true);
    let listdata = [];
    let newlistdata = [];
    let myReqIndex = findIndex(
      myOptions,
      (o) => o.subCategoryId === e.target.value
    );
    let dataset = {
      perData: "",
      type: myOptions[myReqIndex],
      labels: [],
      datasets: [
        {
          label: "Performance",
          backgroundColor: theme.palette.secondary.main,
          barThickness: "flex",
          barPercentage: 0.5,
          categoryPercentage: 0.3,
          // maxBarThickness: 45,
          borderColor: "#ff6d00",
          borderSkipped: "left",
          data: [],
        },
      ],
    };

    fetch(url,
      makeReqBody("SCHOOL_EXAM_ANLY_CLASS_PERF", {
        branchId: parseInt(window.localStorage.branchId),
        classPerfTypeId: parseInt(e.target.value),
        examTermId: id,
        sectionId: parseInt(window.localStorage.sectionId),
        secSubMappingId: parseInt(window.localStorage.secSylMappingId),
        subjectTeacher:
          window.localStorage.teacherType == "Class" ? false : true,
      })
    )
      .then((response) => response.json())
      .then((data) => {
        let component;
        console.log(data);
        // dataset.overallRank = data.respData[0].respMsg.result.overallRank;
        data.respData[0].respMsg.result.map((item, index) => {
          dataset.labels.push(item.key);
          dataset.datasets[0].data.push(item.value.replace("%", ""));
        });
        // data.respData[0].respMsg.result.map((item, index) => {
        //   dataset.labels.push(item.resourceName);
        //   dataset.datasets[0].data.push(
        //     parseInt(item.taskPerc.replace("%", ""))
        //   );
        // });
        setNewData(dataset);
        setMyLoader(false);
      });
  };

  const handleOpenDialog = (e, studentData) => {
    console.log("studentData", studentData)
    fetch(url,
      makeReqBody(studentData.studentInstanceId === 0 && window.localStorage.teacherType == "Class" ? "SCHOOL_GET_CLASS_PERF_REPORT_CARD" : "SCHOOL_REPORT_CARD_BY_STUDENT_INSTANCE_ID", {
        branchId: window.localStorage.branchId,
        examTermId: 0,
        sectionId: studentData.sectionId,
        studentInstanceId: studentData.studentInstanceId,
        userId: window.localStorage.userId,
        branchId: window.localStorage.branchId,
      })
    )
      .then((response) => response.json())
      .then((data) => {
        let heading = ["Subjects"];
        let myData = [];
        let totalData = studentData.studentInstanceId === 0 && window.localStorage.teacherType == "Class" ? [
          [{ title: "Total Marks" }],
          [{ title: "Percentage" }],
        ] :
          [
            [{ title: "Total Marks" }],
            [{ title: "Percentage" }],
            [{ title: "Rank" }],
          ];
        let reportCardData = [];
        data.respData[0].respMsg.result &&
          Object.keys(data.respData[0].respMsg.result).map((item, index) => {
            heading.push(item);
          });

        setReportHeadings(heading);

        Object.keys(data.respData[0].respMsg.result).map((item, index) => {
          // let grouped = groupBy(
          //   data.respData[0].respMsg.result[item].subjectWiseList,
          //   (myitem) => myitem.subjectName
          // );
          totalData[0].push({
            title: data.respData[0].respMsg.result[item].totMarks,
          });
          totalData[1].push({
            title: data.respData[0].respMsg.result[item].percentage,
          });
          totalData[2] && totalData[2].push({
            title: data.respData[0].respMsg.result[item].overallRank,
          });
          let topush = true;

          data.respData[0].respMsg.result[item].subjectWiseList.map(
            (item, index) => {
              let indexto = 0;
              console.log(reportCardData);
              reportCardData.some((data, indexNew) => {
                let myindex = findIndex(
                  data,
                  (o) => o.title === item.subjectName
                );
                console.log(index);
                console.log(myindex);
                if (myindex >= 0) {
                  topush = false;
                  indexto = indexNew;
                } else {
                  topush = true;
                }

                return myindex >= 0;
              });
              if (topush) {
                reportCardData.push([
                  { title: item.subjectName },
                  { title: item.marksObtained },
                ]);
              } else {
                if (reportCardData[indexto] && reportCardData[indexto].length < heading.length) {
                  reportCardData[indexto].push({ title: item.marksObtained });
                }
              }
            }
          );
          myData = reportRows;

          setConsolidatedData(totalData);
        });
        setReportRows(reportCardData);
      });
    setOpenReportCard(true);
  };


  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <ExamTabMakerAnalysis
              tabs={tabhead}
              data={subjectManager}
              subjectBased={true}
            />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>

      <Dialog
        open={uploadDialog}
        onClose={handleCloseUploadDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseUploadDialog}
        >
          Class Performance
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <Grid container direction="column">
            <Grid item container sm>
              <TextField
                select
                label="Performance Type"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              >
                {myOptions.map((item, index) => (
                  <MenuItem key={index} value={item.subCategoryId}>
                    {item.subCategory}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item container sm>
              {myloader === false ? (
                //window.localStorage.teacherType == "Class" ? (
                <ExamAnalysisMaker perData={newData} />
              ) : (
                // ) : (
                // <ExamAnalysisMaker2 perData={newData} />
                //)
                <CircularProgress color="secondary" />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={publishDialog}
        onClose={handlePublishDialogClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlePublishDialogClose}
        >
          Confirm Exam Report Publishing
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <Typography variant="body1" color="initial">
            Are you sure you want to publish the results for this class ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handlePublishDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handlePublishConfirmDialog}
          >
            Publish
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openReportCard}
        maxWidth={maxWidth}
        scroll={scroll}
        onClose={() => setOpenReportCard(false)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setOpenReportCard(false)}
        >
          Report Card
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <TableContainer className={classes.tableReport}>
            <Table aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {reportHeadings.map((item, index) => (
                    <TableCell
                      key={item + index}
                      style={{ cursor: "pointer", fontWeight: "bold" }}
                    >
                      <Grid container>
                        <Grid item>{item}</Grid>
                        {
                          // <Grid item>
                          //   {item === columnToSort ? (
                          //     sortDirection === "asc" ? (
                          //       <ArrowDropUpIcon
                          //         size="small"
                          //         style={{ marginLeft: "0.75em" }}
                          //       />
                          //     ) : (
                          //       <ArrowDropDownIcon
                          //         size="small"
                          //         style={{ marginLeft: "0.75em" }}
                          //       />
                          //     )
                          //   ) : (
                          //     <SortIcon
                          //       size="small"
                          //       style={{ marginLeft: "0.75em" }}
                          //     />
                          //   )}
                          // </Grid>
                        }
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportRows.map((item, index) => (
                  <React.Fragment key={item + index}>
                    <TableRow key={item.title + index}>
                      {item.map((row, index) => (
                        <TableCell
                          key={row + index}
                          style={{
                            maxWidth: "6.5em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            borderBottom: "none",
                            fontWeight: index === 0 ? "bold" : undefined,
                            color:
                              index !== 0
                                ? row.status !== "Pass"
                                  ? "red"
                                  : "inherit"
                                : "inherit",
                          }}
                        >
                          {row.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                ))}
                {consolidatedData.map((item, index) => (
                  <React.Fragment key={item + index}>
                    <TableRow key={item.title + index}>
                      {item.map((row, index) => (
                        <TableCell
                          key={row + index}
                          style={{
                            maxWidth: "6.5em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontWeight: index === 0 ? "bold" : undefined,
                          }}
                        >
                          {row.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      <Backdrop className={classes.backdrop} open={backDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={notiSnack}
        autoHideDuration={3000}
        onClose={() => setNotiSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setNotiSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={NotiStatus}>{notiMessage}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
