import React from 'react';
// import {
//   CircularProgressbar, buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import zero from "../assets/detailed-analysis/0.png";
import ten from "../assets/detailed-analysis/10.png";
import twenty from "../assets/detailed-analysis/20.png";
import thirty from "../assets/detailed-analysis/30.png";
import forty from "../assets/detailed-analysis/40.png";
import fifty from "../assets/detailed-analysis/50.png";
import sixty from "../assets/detailed-analysis/60.png";
import seventy from "../assets/detailed-analysis/70.png";
import eighty from "../assets/detailed-analysis/80.png";
import hundred from "../assets/detailed-analysis/100.png";


export default function CircleChart(props) {
  const percentage = props.percentage;

  // const style= {
  // strokeLinecap: "butt",
  // textColor: percentage >= 80 ? "#4edd71" : percentage >= 51 && percentage < 80 ? "#fba72d" : '#f3565d',
  // pathColor: percentage >= 80 ? "#4edd71" : percentage >= 51 && percentage < 80 ? "#fba72d" : '#f3565d',
  // trailColor: "#d6d6d6"
  // }

  const shadow = percentage >= 80 ? "0 0 4px 2px #4edd71" : percentage >= 51 && percentage < 80 ? "0 0 4px 2px #fba72d" : '0 0 4px 2px #f3565d';

  const background = percentage == 0 ? zero : percentage > 0 && percentage <= 10 ? ten : percentage > 10 && percentage <= 20 ? twenty : percentage > 20 && percentage <= 30 ? thirty : percentage > 30 && percentage <= 40 ? forty : percentage > 40 && percentage <= 50 ? forty : percentage > 50 && percentage <= 60 ? fifty : percentage > 60 && percentage <= 70 ? sixty : percentage > 70 && percentage <= 80 ? seventy : percentage > 80 && percentage <= 90 ? eighty : percentage > 90 && percentage <= 99 ? eighty : percentage == 100 ? hundred : zero;
  return (
    <>
      {/* <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles(style)}
      /> */}

      <div style={{ width: '150px', height: '150px', borderRadius: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: `url(${background}) center bottom / contain no-repeat`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center', margin: '0 0 15px' }}>
        <h6 style={{ fontSize: '18px', fontWeight: '700', fontFamily: `'Montserrat', 'sans-serif'`, color: '#000', margin: '0 0 5px' }}>{`${percentage}%`}</h6>
        <p style={{ fontSize: '14px', fontWeight: '600', fontFamily: `'Montserrat', 'sans-serif'`, color: '#000', margin: '0', textAlign: "center" }}>{props.textName}</p>
      </div>
    </>
  )
}

