import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, makeStyles, Grid, Typography } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  menuCard: {
    height: "7em",
    width: "9em",
    borderRadius: 15,
    padding: 0,
  },
  menuCardUnselected: {
    background: "rgba(0,0,0,0.7)",
    width: "9em",
    minHeight: "2em",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  menuCardSeleccted: {
    background: theme.palette.secondary.main,
    width: "9em",
    minHeight: "2em",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
}));

export default function MenuCards(props) {
  const classes = useStyle();
  // const [isSelected, setIsSelected] = useState(false);
  return (
    <Card
      className={classes.menuCard}
      component={Link}
      to={props.to}
      style={{ textDecoration: "none" }}
      raised={true}
    >
      <Grid container spacing={1} direction="column" justify="flex-end">
        <Grid item container style={{ position: "relative", zIndex: "0.5" }}>
          <img
            src={props.img}
            alt="test"
            style={{ height: "7em", width: "9em" }}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          style={{ position: "absolute" }}
        >
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            className={
              props.isSelected === true
                ? classes.menuCardSeleccted
                : classes.menuCardUnselected
            }
          >
            <Typography
              variant="body1"
              align="center"
              style={{ color: "white" }}
            >
              {props.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
