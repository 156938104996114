import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  AppBar,
  Toolbar,
  Switch,
  Tooltip,
} from "@material-ui/core";
import DisplayPdf from "./DisplayPdf";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import TabMaker from "./TabMaker";
import TableMaker from "./TableMaker";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { groupBy } from "lodash";
import { url } from "./store.js";
import { set } from "date-fns";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

const inverDirect = {
  asc: "desc",
  desc: "asc",
};

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    fontSize: "0.85rem",
  },
  table: {
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? "#616161"
        : "transparent",
    minwidth: "30em",
  },
  tableHead: {
    "& .MuiTableCell-head": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.ltgrey
        : theme.palette.common.white,
    width: "100%",
  },
  toolBar: {
    ...theme.mixins.toolbar,
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },
  dropZone: {
    color: "black !important",
  },

  videoAppBar: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.common.black,
  },
  switchButton: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.light,
    },

    "& .MuiSwitch-colorSecondary": {},
  },
  pdfContainer: {
    marginTop: "none",
  },

  pdfContainer2: {
    width: "200em",
  },
  titlebar: {
    //  height: "6px",
    backgroundColor: theme.palette.common.ltgrey,
  },
  myDropZone: {
    "& .MuiDialogContent-root": {
      scrollbarWidth: "none",
    },
  },

  pdfScroll: {
    overflowY: "auto",

    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
    "& .MuiDialogContent-root": {
      minHeight: '12em',        
    }
  },
  iconButton:{
    padding: '6px',
},
customTooltip: {
  // I used the rgba color for the standard "secondary" color
  fontSize: "0.85rem",
},
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function WritingMaterialVerify(props) {
  const classes = useStyle();
  const theme = useTheme();
  const [connectZone, setConnectZone] = useState([]);
  const [materialAdded, setMaterialAdded] = useState(false);
  const [loader, setLoader] = useState(true);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [dropZone, setDropZone] = useState(false);
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [chapter, setChapter] = useState([]);
  const [section, setSection] = useState([]);
  const [chapterSelected, setChapterSelected] = useState({});
  const [sectionSelected, setSectionSelected] = useState({});
  const [open, setOpen] = React.useState(false);
  const [hrefData, setHrefData] = React.useState();
  const [checked, setChecked] = React.useState(false);
  const [displayFullscreen, setDisplayFullscreen] = React.useState(false);
  const [myAttachment, setMyAttachment] = useState({
    attachment: "",
    fileName: "",
    fileType: "",
  });
  const [studentDetails, setStudentDetails] = useState({});
  const [imageOpen, setImageOpen] = useState(false);

  const handleImageClose = () => {
    setImageOpen(false);
  };
  const handleImageOpen = (e, href) => {
    setImageOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };

  const headers = [
    "Student",
    "Roll No",
    "Due Date",
    "Date Received",
    "Date Verified",
    "Attachment",
    "Comments",
    "Status",
    "Actions",
  ];

  const handleOpenUploadDialog = async () => {
    setChapterSelected({});
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_ALL_CHAPTER_SECTION",
              busiParams: {
                admin: false,
                sectionId: window.localStorage.sectionId,
                subjectId: window.localStorage.subjectId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let mychapters = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mychapters.push(item);
            });
          setChapter(mychapters);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }
    setUploadDialog(true);
  };

  const handleDropZone = () => {
    setDropZone(true);
  };

  const handleSaveMaterial = async (files) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".");
    let myFileType = files[0].type.split("/");

    setMyAttachment({
      ...myAttachment,
      attachment: newattachment,
      fileName: myFileName,
      fileType: myFileType,
    });
    setMaterialName(myFileName);
    setDropZone(false);
  };

  const handleChapterSelect = async (e) => {
    setChapterSelected(e.target.value);
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_CH_SECTION_DETAILS_BY_CID",
              busiParams: {
                admin: false,
                id: e.target.value.chapterId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let mysections = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mysections.push(item);
            });
          setSection(mysections);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    console.log(section);
  };

  const handleDropZoneClose = () => {
    setDropZone(false);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
  };
  const handleUploadReject = () => {
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPDATE_TO_DO_TASK_TXN",
              busiParams: {
                adminRequested: true,
                comments: studentDetails.comments,
                fileName: "",
                fileType: "",
                solutionAttachment: "",
                taskTxnId: studentDetails.taskTxnId,
                txnStatus: "Re-Submit",
                url: "",
                userId: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);
          props.setVerifyRow([]);
          setTimeout(() => {
            props.handleVerifyWritingMaterial(studentDetails);
          }, 1500);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setUploadDialog(false);
  };

  const handleUploadSubmit = () => {
    console.log(studentDetails);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPDATE_TO_DO_TASK_TXN",
              busiParams: {
                adminRequested: true,
                comments: studentDetails.comments,
                fileName: "",
                fileType: "",
                solutionAttachment: "",
                taskTxnId: studentDetails.taskTxnId,
                txnStatus: "Completed",
                url: "",
                userId: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);
          props.setVerifyRow([]);
          setTimeout(() => {
            props.handleVerifyWritingMaterial(studentDetails);
          }, 1500);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setUploadDialog(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const DATA = await fetch(url, {
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //         method: "POST",
  //         body: JSON.stringify(bodyObjTextBookLinks),
  //       });
  //       let tempEvents = [];
  //       const RESPONSE = await DATA.json();
  //       console.log(RESPONSE);
  //       RESPONSE.respData[0].respMsg.result.map((item, index) => {
  //         tempEvents.push(item);
  //       });

  //       const AllLists = [];

  //       subjects.map((data, myindex) => {
  //         tempEvents.map((item, index, arr) => {
  //           if (item.secSubjectMappingId === data.secSubSylMappingId) {
  //             arr[index] = { ...arr[index], subjectMapPosition: myindex };
  //           }
  //         });
  //       });

  //       let grouped = groupBy(tempEvents, (item) => item.subjectMapPosition);

  //       console.log(grouped);
  //       for (const x in grouped) {
  //         let mydata = grouped[x];
  //         let row = [];
  //         console.log(`this is grouuped data of subject ${x}`);
  //         mydata.map((item, index) => {
  //           console.log(item);
  //           row.push([
  //             { title: item.name, type: "text" },
  //             { title: item.description, type: "text" },
  //             { title: item.dueDate, type: "text" },
  //             {
  //               title: item.fileName,
  //               type: item.fileType,
  //               href: item.attachment,
  //             },
  //           ]);
  //         });

  //         let temList = (
  //           <React.Fragment key={x}>
  //             <TableMaker columns={headers} rows={row} />
  //           </React.Fragment>
  //         );

  //         AllLists.push(temList);
  //       }
  //       console.log(AllLists);
  //       setConnectZone(AllLists);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getData();
  // }, []);
  const handlePdfOpen = (e, href) => {
    setOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };

  const handlepdfClose = (event) => {
    setOpen(false);
    window.localStorage.removeItem("idleDisable");
  };
  const toggleView = (event) => {
    setDisplayFullscreen(!displayFullscreen);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleVerification = (e, details) => {
    console.log(details);
    setStudentDetails(details);
    setUploadDialog(true);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        style={{ color: "white", marginLeft: "1em", marginTop: "-0.5em" }}
        onClick={() => props.setRoute("table")}
      >
        Back
      </Button>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        // className={classes.subContainer}
      >
        <Grid item>
          {props.rows.length > 0 ? (
            <TableContainer
              className={classes.table}
              style={{ scrollbarWidth: "none" }}
            >
              <Table aria-label="simple table" className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {headers &&
                      headers.map((item, index) => (
                        <TableCell
                          key={item + index}
                          style={{ cursor: "pointer" }}
                        >
                          <Grid container>
                            <Grid item>{item}</Grid>
                          </Grid>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.rows &&
                    props.rows.map((item, index) => (
                      <React.Fragment key={item + index}>
                        <TableRow key={item.title + index}>
                          {item.map((row, index) => (
                            <TableCell
                              key={row + index}
                              onClick={
                                row.type === "pdf"
                                  ? (e) => handlePdfOpen(e, row.href)
                                  : row.type === "png" ||
                                    row.type === "jpg" ||
                                    row.type === "jpeg"
                                  ? (e) => handleImageOpen(e, row.href)
                                  : row.type === "verifyWritingAction"
                                  ? (e) =>
                                      handleVerification(e, row.fullDetails)
                                  : null
                              }
                              style={{
                                maxWidth: "6.5em",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {index === 0 ? (
                                <Tooltip title={row.title} arrow>
                                  <p
                                    style={{
                                      color:
                                        theme.typography.myTheme.themeName ==
                                        "Night"
                                          ? "white"
                                          : "inherit",
                                    }}
                                  >
                                    {row.title}
                                  </p>
                                </Tooltip>
                              ) : (
                                <span
                                  className={classes.buttonStyle}
                                  style={{
                                    color:
                                      theme.typography.myTheme.themeName ==
                                      "Night"
                                        ? "white"
                                        : row.type === "link" ||
                                          row.type === "video" ||
                                          row.type === "pdf" ||
                                          row.type === "upload" ||
                                          row.type === "doc" ||
                                          row.type === "mp4" ||
                                          row.type === "mp3" ||
                                          row.type === "3gp" ||
                                          row.type === "vigilance" ||
                                          row.type === "png" ||
                                          row.type === "jpg" ||
                                          row.type === "jpeg" ||
                                          row.type === "uploadExam"
                                        ? theme.palette.common.blue
                                        : row.type === "status"
                                        ? "white"
                                        : "inherit",
                                    cursor: "pointer",
                                    backgroundColor:
                                      row.type === "status"
                                        ? row.title.toLowerCase() ===
                                            "Completed".toLowerCase() ||
                                          row.title.toLowerCase() ===
                                            "Submitted".toLowerCase()
                                          ? theme.palette.common.completed
                                          : row.title.toLowerCase() ===
                                              "Expired".toLowerCase() ||
                                            row.title.toLowerCase() ===
                                              "Re-Submit".toLowerCase() ||
                                            row.title.toLowerCase() ===
                                              "Re-Submitted".toLowerCase()
                                          ? theme.palette.common.warning
                                          : row.title.toLowerCase() ===
                                            "Pending".toLowerCase()
                                          ? theme.palette.common.pending
                                          : row.title.toLowerCase() ===
                                            "Pending For Verification".toLowerCase()
                                          ? theme.palette.common.pendingver
                                          : row.title === "To Be Uploaded"
                                          ? theme.palette.common.toupdate
                                          : "inherit"
                                        : "inherit",
                                  }}
                                >
                                  {row.type === "verifyWritingAction" ? (
                                    <AssignmentOutlinedIcon color="secondary" />
                                  ) : (
                                    <Tooltip
                                      title={row.title}
                                      classes={{
                                        tooltip: classes.customTooltip,
                                      }}
                                    >
                                      <span>{row.title}</span>
                                    </Tooltip>
                                  )}
                                </span>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>

      <Dialog
        open={uploadDialog}
        onClose={handleCloseUploadDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseUploadDialog}
        >
          Verify Work
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                disabled
                variant="outlined"
                fullWidth
                label="Student Name"
                value={studentDetails.studentName}
              />
            </Grid>
            <Grid item>
              <TextField
                disabled
                variant="outlined"
                fullWidth
                label="Subject Name"
                value={studentDetails.subjectName}
              />
            </Grid>
            <Grid item>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={studentDetails.comments}
                label="Comments"
                onChange={(e) =>
                  setStudentDetails({
                    ...studentDetails,
                    comments: e.target.value,
                  })
                }
              />
            </Grid>
            {
              // <Grid item>
              // <KeyboardDatePicker
              //       variant="inline"
              //       autoOk={true}
              //       name="date"
              //       inputVariant="outlined"
              //       label="Date"
              //       fullWidth
              //       placeholder="Select Notification Date"
              //       format="DD/MM/yyyy"
              //       value={resource.date}
              //       InputAdornmentProps={{ position: "end" }}
              //       onChange={(date) => {
              //         setResource({
              //           ...notification,
              //           dueDate: moment(date).format("YYYY-MM-DD").toString(),
              //         });
              //       }}
              //     />
              // </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            style={{ color: "red" }}
            onClick={handleUploadReject}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            style={{ color: "white", backgroundColor: "green" }}
            onClick={handleUploadSubmit}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={displayFullscreen}
        open={imageOpen}
        onClose={handleImageClose}
        aria-labelledby="customized-dialog-title"
        className={classes.pdfFix}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleImageClose}>
          {" "}
          <Switch
            checked={checked}
            onChange={toggleChecked}
            onClick={toggleView}
            color="primary"
          />
          <Typography style={{ color: theme.palette.common.tabFont }}>
            Toggle View
          </Typography>
        </DialogTitle>
        <DialogContent style={{ scrollbarWidth: "none" }}>
          <img
            src={hrefData}
            alt="viewer"
            style={{
              height: "auto",
              maxWidth: displayFullscreen === false ? "40em" : undefined,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleImageClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={6000000}
        open={dropZone}
        dropzoneParagraphClass={classes.dropZone}
        dialogProps={{ className: classes.myDropZone }}
        onClose={handleDropZoneClose}
        onSave={(files) => handleSaveMaterial(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                fontSize="small"
                style={{ color: theme.palette.common.tabFont }}
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>

      <Dialog
        fullScreen={displayFullscreen}
        open={open}
        onClose={handlepdfClose}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlepdfClose}
              aria-label="close"
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              PDF Viewer
            </Typography>
            <Switch
              checked={checked}
              onChange={toggleChecked}
              onClick={toggleView}
              color="primary"
            />
            <Typography style={{ color: "black" }}>Toggle View</Typography>
            <Tooltip
            title="Download Pdf"
            classes={{
              tooltip: classes.customTooltip,
            }}>
              <IconButton className={classes.iconButton} onClick={()=> window.open(hrefData)}>
                <GetAppOutlinedIcon color="primary"/>
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <Grid container direction="column" alignItems="center" className={`${classes.pdfScroll} customPdfScroll`}>
          <Grid item >
            <DisplayPdf data={hrefData} displayFullscreen={displayFullscreen} />
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
