import React from "react";
import { Grid, Paper, Typography, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/SchoolHead.png";

import backg from "../assets/backg.png";

const useStyle = makeStyles((theme) => ({
  formControl: {
    minWidth: "23em",
  },

  mainContainer: {
    width: "100%",
    height: "100vh",
    backgroundImage: `linear-gradient(119deg, rgba(110, 128, 228, 0.7) 0%,rgba(61, 160, 241, 0.5) 100%), url(${backg}) `,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: theme.spacing.unit,
  },

  paperLogin: {
    marginTop: "auto",
    marginBottom: "auto",
    width: "35em",
    padding: "27px",
  },
  loginBtn: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
    minWidth: "26em",
    marginTop: "15px",
  },
  logo: {
    width: "6.2em",
    height: "5em",
    marginLeft: "50px",
    marginTop: "20px",
  },
}));

export default function ForgotPassword(props) {
  const classes = useStyle();
   const [requestBody, setRequestBody ]= React.useState({"currentPassword": "", "newPassword": "", "confNewpassword": "", "username" : "admin"})

  const bodyObj = {
    "requestList":[
      {
         "isEncrypt":false,
         "transactionId":"0000001438241244",
         "busiCode":"UAM_CHANGE_PSWRD",
         "busiParams":
                                 {
                                 
                                  "requestHeader": {
                                    "serviceName": "changePassword"
                                  },
                                  "requestBody": requestBody
                             }
     
      }
   ],
   "pubInfo":{
      "appId": "appId",
  "sessionId": "a89034ac-2a77-43c4-b70f-7886c62cb93f",
  "version": "21",
  "timestamp": "20180314175419",
  "lang": "en_US",
  "userId": "1000016614",
  "serviceId": "7021150585",
  "circleId": "MU",
  "ncsroute": "WE015",
  "customerId": "1000016614",
  "osType": "android"
   }
}
 
  const handleInputChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setRequestBody({ ...requestBody, [inputName]: inputValue });
    console.log(
      "the chnage usercredentials are = " + JSON.stringify(requestBody)
    );
  };

  const changePasswordStatus = () => {
         fetch("http://159.89.171.55:31998/ncs-school-service/servlet/Service",
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(bodyObj)
            })
            .then((res) => res)
            .then( (res) => {
                console.log(res)
                

            }).catch( (res) => {
             this.notify(false,"Something went wrong !!")
        })
  }
  return (
    <React.Fragment>
      <Grid
        container
        alignItems="center"
        direction="column"
        className={classes.mainContainer}
      >
        <Grid item className={classes.paperLogin}>
          <Grid item>
            <Paper
              style={{
                borderRadius: "50%",
                width: "200px",
                height: "200px",
                marginLeft: "150px",
                marginBottom: "-120px",
              }}
            >
              <img alt=" " src={logo} className={classes.logo}></img>
            </Paper>
          </Grid>
          <Paper
            style={{ marginTop: "auto", marginBottom: "auto", padding: "50px" }}
          >
            <Grid item>
              <Typography style={{ paddingBottom: "20px", marginLeft: "70px" }}>
                Please Enter The New Password
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justify="center"
              spacing={1}
            >
              <Grid item>
                <TextField
                  className={classes.formControl}
                  id="outlined-password-input"
                  label=" Current Password"
                  onChange= {handleInputChange}
                  name="currentPassword"
                  value= {requestBody.currentPassword}
                  type="Password"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  fullwidth
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.formControl}
                  id="outlined-password"
                  label=" New Password"
                  onChange= {handleInputChange}
                  name="newPassword"
                  value= {requestBody.newPassword}
                  type="Password"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  fullwidth
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.formControl}
                  id="outlined-password-input"
                  label="Confirm New Password "
                  onChange= {handleInputChange}
                   name="confNewPassword"
                  value= {requestBody.confNewPassword}
                  type="Password"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  fullwidth
                />
              </Grid>

              <Grid item>
                <Button
                  className={classes.loginBtn}
                  variant="contained"
                  color="default"
                  fullWidth
                  onClick = {changePasswordStatus}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
