import React, { useEffect, useState, useRef } from "react";
import {
    Grid,
    Typography,
    CircularProgress,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    MenuItem,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    Backdrop,

} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import { url, makeReqBody } from "./store.js";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
    customDropdown: {
        '& .MuiInputLabel-outlined': {
            zIndex: 1,
            transform: 'translate(12px, 14px) scale(1)',
            pointerEvents: 'none',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(12px, -6px) scale(0.75)',
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 12px',
            width: '76%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& .Mui-disabled': {
            background: '#eee',
        },
    },
    customDropdownTop: {
        '& .MuiInputLabel-outlined': {
            zIndex: 1,
            transform: 'translate(12px, 14px) scale(1)',
            pointerEvents: 'none',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(12px, -6px) scale(0.75)',
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 12px',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& .Mui-disabled': {
            background: '#eee',
        },
    },
    questionMapping: {
        "& ::-webkit-scrollbar": {
            width: '7px!important',
            height: '5px',
        },
        "& ::-webkit-scrollbar-track:": {
            background: "#00256f",
        },

        "& ::-webkit-scrollbar-thumb": {
            background: "#f4ce2c",
        },

        "& ::-moz-scrollbar": {
            width: '10px',
            scrollbarWidth: 'thin',
        },

        "& ::-moz-scrollbar-track": {
            background: '#00256f',
        },

        "& ::-moz-scrollbar-thumb": {
            background: "#f4ce2c",
        },
        "& .MuiDialogActions-root": {
            justifyContent: 'space-between!important',
        },
    },

    iconButton: {
        padding: '6px',
    },

    customTooltip: {
        fontSize: "0.85rem",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: "#fff",
    },

}));

const styles = (theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.common.black,
    },
    DialogHeader: {
        backgroundColor: theme.palette.common.ltgrey,
    },
    dropZone: {
        color: "black !important",
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    const theme = useTheme();
    return (
        <MuiDialogTitle
            disableTypography
            {...other}
            className={classes.DialogHeader}
        >
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon style={{ color: theme.palette.common.tabFont }} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function QuestionMappingExam(props) {
    const classes = useStyle();
    const theme = useTheme();
    const [snack, setSnack] = useState(false);
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const [chapter, setChapter] = useState([]);
    const [identifier, setIdentifier] = useState([]);
    const [classTeacherExam, setClassTeacherExam] = useState(null);
    const [myLoader, setMyLoader] = useState(false);
    const [backLoader, setBackLoader] = useState(false);
    const [questionMapping, setQuestionMapping] = useState(false);
    const [questionMappingData, setQuestionMappingData] = useState([
        {
            "chapterIdData": null,
            "chapterSectionIdData": [],
            "chapterId": "",
            "chapterSectionName": "",
            "chapterSectionId": "",
            "chapterSource": "",
            "identifierId": "",
            "totalMarks": "",
            "publishedRegExam": false,
        }
    ]);
    const [reload, setReload] = useState(false);
    const [hideAction, setHideAction] = useState(false);
    const [totalMapping, setTotalMapping] = useState(null);
    const [mappingIndex, setMappingIndex] = useState(null);

    const history = useHistory();

    const myRef = useRef(null)

    useEffect(() => {
        try {
            fetch(
                url,
                makeReqBody("SCHOOL_GET_ALL_CHAPTER_SECTION", {
                    admin: false,
                    sectionId: window.localStorage.teacherType == "Class" ? classTeacherExam.sectionId : window.localStorage.sectionId,
                    subjectId: window.localStorage.teacherType == "Class" ? classTeacherExam.subjectId : window.localStorage.subjectId,
                })
            )
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    let mychapters = [];
                    data.respData[0].respMsg.result &&
                        data.respData[0].respMsg.result.map((item, index) => {
                            mychapters.push({
                                "chapterId": item.chapterId,
                                "chapterSource": item.source,
                                "chapterName": item.chapterName,
                            });
                        });
                    setChapter(mychapters);
                })
                .catch((data) => {
                    console.error(data);
                });
        } catch (data) {
            console.error(data);
        }
    }, [classTeacherExam]);

    const addQuestionMappingRow = () => {
        if (questionMappingData.length > 49) {
            setMessage("You can add upto 50 questions only.");
            setStatus("error")
            setSnack(true);
        } else {
            let data = [...questionMappingData];
            data.push({
                "chapterIdData": null,
                "chapterSectionIdData": [],
                "chapterId": "",
                "chapterSectionName": "",
                "chapterSectionId": "",
                "chapterSource": "",
                "identifierId": "",
                "totalMarks": "",
                "publishedRegExam": false,
            });
            setQuestionMappingData(data);
            console.log(data);
            let scrollHeight = myRef.current.scrollHeight;
            myRef.current.scrollTo(0, scrollHeight);
            // myRef.current.scrollIntoView();
        }

    }

    const addChapterId = (index, item, e) => {
        console.log(e.target.value);
        let qmappingData = [...questionMappingData];
        qmappingData[index].chapterIdData = e.target.value;
        qmappingData[index].chapterId = e.target.value.chapterId;
        qmappingData[index].chapterSource = e.target.value.chapterSource;
        try {
            fetch(
                url,
                makeReqBody("SCHOOL_GET_ALL_CHAPTER_SECTION_BY_ID", {
                    id: e.target.value.chapterId,
                    admin: true,
                })
            )
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    let mychapterSection = data.respData[0].respMsg.result.data;
                    qmappingData[index].chapterSectionId = "";
                    qmappingData[index].chapterSectionIdData = mychapterSection;
                    setQuestionMappingData(qmappingData);
                })
                .catch((data) => {
                    console.error(data);
                });
        } catch (data) {
            console.error(data);
        }
    }

    const addSectionId = (index, e) => {
        let qmappingData = [...questionMappingData];
        qmappingData[index].chapterSectionId = e.target.value;
        setQuestionMappingData(qmappingData);
    }

    useEffect(() => {
        // try {
        //     fetch(
        //         url,
        //         makeReqBody("MDM_GET_LOOKUP_SUB_CATG", {
        //             categoryName: "Identifier",
        //             flag: true,
        //         })
        //     )
        //         .then((response) => response.json())
        //         .then((data) => {
        //             setIdentifier(data.respData[0].respMsg.results);
        //         })
        //         .catch((data) => {
        //             console.error(data);
        //         });
        // } catch (error) {
        //     console.error(error);
        // }

        try {
            fetch(url, makeReqBody("SCHOOL_GET_IDENTI_TYPO_QTYPE_MAP_BOARDID", {
                boardId: window.localStorage.boardId

            }))
                .then((response) => response.json())
                .then((data) => {
                    setIdentifier(data.respData[0].respMsg.result[0].identifierDetails);
                })
                .catch((data) => {
                    console.error(data);
                });
        } catch (error) {
            console.error(error);
        }
    }, []);


    const handleUploadSubmit = () => {
        for (let i = 0; i < questionMappingData.length; i++) {
            if (questionMappingData[i].chapterId === "") {
                setMessage(`Please select chapter for Question no. ${i + 1}`);
                setStatus("error")
                setSnack(true);
                return false;
            }
            if (questionMappingData[i].chapterSectionId === "") {
                setMessage(`Please select chapter section for Question no. ${i + 1}`);
                setStatus("error")
                setSnack(true);
                return false;
            }

            if (questionMappingData[i].identifierId === "") {
                setMessage(`Please select identifier for Question no. ${i + 1}`);
                setStatus("error")
                setSnack(true);
                return false;
            }

            if (questionMappingData[i].totalMarks === "") {
                setMessage(`Please enter Total Marks for Question no. ${i + 1}`);
                setStatus("error")
                setSnack(true);
                return false;
            }
            if (questionMappingData[i].totalMarks < 1 || questionMappingData[i].totalMarks > 20) {
                setMessage("Marks can be between or equal to 1 and 20");
                setStatus("error")
                setSnack(true);
                return false;
            }
        }
        setBackLoader(true);
        try {
            fetch(
                url,
                makeReqBody("SCHOOL_EXAM_QUES_MAPPING", {
                    examId: window.localStorage.teacherType == "Class" ? classTeacherExam.eExamId : props.examId,
                    examTermId: window.localStorage.teacherType == "Class" ? classTeacherExam.examTermId : props.examTermId,
                    examType: window.localStorage.teacherType == "Class" ? classTeacherExam.examType : props.examType,
                    loginUserId: window.localStorage.userId,
                    secSubMappingId: window.localStorage.teacherType == "Class" ? classTeacherExam.secSubMappingId : window.localStorage.secSylMappingId,
                    quesDetail: questionMappingData,
                })
            )
                .then((response) => response.json())
                .then((data) => {
                    setBackLoader(false);
                    setMessage(data.respData[0].respMsg.message);
                    data.respData[0].respMsg.status == "Failure"
                        ? setStatus("error")
                        : setStatus("success");
                    setSnack(true);
                    // setQuestionMapping(false);
                    props.loadList();
                    setReload(!reload);
                    if (window.localStorage.teacherType == "Class") {
                        // setQuestionMappingData([
                        //     {
                        //         "chapterIdData": null,
                        //         "chapterSectionIdData": [],
                        //         "chapterId": "",
                        //         "chapterSectionName": "",
                        //         "chapterSectionId": "",
                        //         "chapterSource": "",
                        //         "identifierId": "",
                        //         "totalMarks": "",
                        //         "publishedRegExam": false,
                        //     }
                        // ]);
                        setClassTeacherExam(props.classTeacherSubject && props.classTeacherSubject[mappingIndex]);
                        setMappingIndex(mappingIndex);
                        props.loadList();
                        setHideAction(false);
                        setReload(!reload);
                    }
                })
                .catch((data) => {
                    console.error(data);
                });
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        if (window.localStorage.teacherType === "Subject" && props.examMapping === true || props.classTeacherSubject && props.classTeacherSubject[mappingIndex] && props.classTeacherSubject[mappingIndex].quesMappingPresent === true) {
            try {
                fetch(
                    url,
                    makeReqBody("SCHOOL_GET_EXAM_QUES_MAPPINGS_BY_EXAMID", {
                        id: window.localStorage.teacherType == "Class" ? classTeacherExam.eExamId : props.examId,
                    })
                )
                    .then((response) => response.json())
                    .then((data) => {
                        let detail = []
                        data.respData[0].respMsg.result.map((item, index) => {
                            detail.push({
                                "chapterIdData": { "chapterId": item.chapterId, "chapterSource": item.chapterSource, "chapterName": item.chapterName },
                                "chapterSectionIdData": [{
                                    "chapterSectionId": item.chapterSectionId,
                                    "sectionName": item.chapterSectionName,
                                }],
                                "chapterId": item.chapterId,
                                "chapterSectionId": item.chapterSectionId,
                                "chapterSectionName": item.chapterSectionName,
                                "chapterSource": item.chapterSource,
                                "identifierId": item.identifierId,
                                "identifierName": item.identifierName,
                                "totalMarks": item.totalMarks,
                                "examQuesMapId": item.examQuesMapId,
                                "disable": true,
                                "publishedRegExam": item.publishedRegExam,
                                "totalExamMarks": item.totalExamMarks,
                            });
                        });


                        if (data.respData[0].respMsg.result[0].publishedRegExam === true) {
                            setHideAction(true);
                        } else {
                            setHideAction(false);
                        }

                        setQuestionMappingData(detail);
                    })
                    .catch((data) => {
                        console.error(data);
                    });
            } catch (error) {
                console.error(error);
            }
        }
    }, [window.localStorage.teacherType === "Subject" && props.examMapping === true, reload, props.classTeacherSubject && props.classTeacherSubject[mappingIndex] && props.classTeacherSubject[mappingIndex].quesMappingPresent === true]);

    const deleteMappingRow = (index, item) => {
        console.log(index);
        console.log(item);
        let qmappingData = [...questionMappingData];
        if (qmappingData.length === 1) {
            setMessage("At least one question required to create an exam.");
            setStatus("error")
            setSnack(true);
        } else {
            if (qmappingData[index].examQuesMapId === undefined) {
                qmappingData.splice(index, 1);
                setQuestionMappingData(qmappingData);
            }
            else if (qmappingData[index].examQuesMapId && qmappingData[index].examQuesMapId != "") {
                setBackLoader(true);
                try {
                    fetch(
                        url,
                        makeReqBody("SCHOOL_DELETE_EXAM_QUES_MAPPING", {
                            examQuesMapId: item.examQuesMapId,
                            loginUserId: window.localStorage.userId,
                        })
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            setBackLoader(false);
                            setMessage(data.respData[0].respMsg.message);
                            data.respData[0].respMsg.status == "Failure"
                                ? setStatus("error")
                                : setStatus("success");
                            setSnack(true);
                            setReload(!reload);
                        })
                        .catch((data) => {
                            console.error(data);
                        });
                } catch (error) {
                    console.error(error);
                }
            }
        }


    }

    const editMappingRow = (index, item) => {
        if (item.chapterId === "") {
            setMessage("Please select chapter");
            setStatus("error")
            setSnack(true);
        } else if (item.chapterSectionId === "") {
            setMessage("Please select chapter section");
            setStatus("error")
            setSnack(true);
        }
        else if (item.identifierId === "") {
            setMessage("Please select identifier");
            setStatus("error")
            setSnack(true);
        }
        else if (item.totalMarks === "") {
            setMessage("Please enter Total Marks");
            setStatus("error")
            setSnack(true);
        } else if (item.totalMarks < 1 || item.totalMarks > 20) {
            setMessage("Marks can be between or equal to 1 and 20");
            setStatus("error")
            setSnack(true);
        }
        else {
            setBackLoader(true);
            try {
                fetch(
                    url,
                    makeReqBody("SCHOOL_UPDATE_EXAM_QUES_MAPPING", {
                        "chapterId": item.chapterId,
                        "chapterSectionId": item.chapterSectionId,
                        "chapterSource": item.source,
                        "examQuesMapId": item.examQuesMapId,
                        "identifierId": item.identifierId,
                        loginUserId: window.localStorage.userId,
                        "totalMarks": item.totalMarks,
                    })
                )
                    .then((response) => response.json())
                    .then((data) => {
                        setBackLoader(false);
                        setMessage(data.respData[0].respMsg.message);
                        data.respData[0].respMsg.status == "Failure"
                            ? setStatus("error")
                            : setStatus("success");
                        setSnack(true);
                        setReload(!reload);
                    })
                    .catch((data) => {
                        console.error(data);
                    });
            } catch (error) {
                console.error(error);
            }
        }
    }

    const saveSingleMappingRow = (index, item) => {
        if (item.chapterId === "") {
            setMessage("Please select chapter");
            setStatus("error")
            setSnack(true);
        } else if (item.chapterSectionId === "") {
            setMessage("Please select chapter section");
            setStatus("error")
            setSnack(true);
        }
        else if (item.identifierId === "") {
            setMessage("Please select identifier");
            setStatus("error")
            setSnack(true);
        }
        else if (item.totalMarks === "") {
            setMessage("Please enter Total Marks");
            setStatus("error")
            setSnack(true);
        } else if (item.totalMarks < 1 || item.totalMarks > 20) {
            setMessage("Marks can be between or equal to 1 and 20");
            setStatus("error")
            setSnack(true);
        }
        else {
            setBackLoader(true);
            try {
                fetch(
                    url,
                    makeReqBody("SCHOOL_EXAM_QUES_MAPPING", {
                        examId: window.localStorage.teacherType == "Class" ? classTeacherExam.eExamId : props.examId,
                        examTermId: window.localStorage.teacherType == "Class" ? classTeacherExam.examTermId : props.examTermId,
                        examType: window.localStorage.teacherType == "Class" ? classTeacherExam.examType : props.examType,
                        loginUserId: window.localStorage.userId,
                        secSubMappingId: window.localStorage.teacherType == "Class" ? classTeacherExam.secSubMappingId : window.localStorage.secSylMappingId,
                        quesDetail: [item],
                    })
                )
                    .then((response) => response.json())
                    .then((data) => {
                        setBackLoader(false);
                        setMessage(data.respData[0].respMsg.message);
                        data.respData[0].respMsg.status == "Failure"
                            ? setStatus("error")
                            : setStatus("success");
                        setSnack(true);
                        setReload(!reload);
                    })
                    .catch((data) => {
                        console.error(data);
                    });
            } catch (error) {
                console.error(error);
            }
        }
    }

    useEffect(() => {
        var sum = 0;
        for (var i = 0; i < questionMappingData.length; i++) {
            if (questionMappingData[i].totalMarks != "") {
                sum += parseFloat(questionMappingData[i].totalMarks);
            }
        }
        setTotalMapping(sum);
    }, [questionMappingData])
    // let scrollHeight = myRef.current.scrollHeight;
    // useEffect(() => {
    //     // myRef.current.scrollTop = 9000000000000000;
    //     myRef.current.scrollIntoView({
    //         behavior: "smooth",
    //         block: "end"
    //       });
    // }, [questionMappingData.length])

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="secondary"
                disabled={props.examType === 3 && props.selectedEpId == ""}
                onClick={() => {
                    if (props.examType !== 3) {
                        setQuestionMapping(true);
                        setReload(!reload);
                    } else {
                        history.push("/ExamMode/EExam/QuestionMapping")
                    }
                }}
                style={{ color: "white", marginLeft: "1em", marginTop: "-0.5em" }}
            >
                Question Mapping
            </Button>
            {/* Question Mapping */}

            <Dialog
                open={questionMapping}
                onClose={() => setQuestionMapping(false)}
                aria-labelledby="customized-dialog-title"
                className={classes.questionMapping}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => setQuestionMapping(false)}
                >
                    Question Mapping
                </DialogTitle>
                <DialogContent ref={myRef}
                >

                    {window.localStorage.teacherType == "Class" && <Grid
                        container
                        direction="row"
                        spacing={2}
                        style={{ marginTop: '10px' }}
                    >
                        <Grid item sm={4}>
                            <TextField
                                id="subjectclass"
                                label="Subject Name"
                                select
                                name="subject"
                                variant="outlined"
                                fullWidth
                                style={{ maxWidth: '100%' }}
                                className={classes.customDropdownTop}
                                value={mappingIndex}
                                onChange={(e) => {
                                    setQuestionMappingData([
                                        {
                                            "chapterIdData": null,
                                            "chapterSectionIdData": [],
                                            "chapterId": "",
                                            "chapterSectionName": "",
                                            "chapterSectionId": "",
                                            "chapterSource": "",
                                            "identifierId": "",
                                            "totalMarks": "",
                                            "publishedRegExam": false,
                                        }
                                    ]);
                                    setClassTeacherExam(props.classTeacherSubject && props.classTeacherSubject[e.target.value]);
                                    setMappingIndex(e.target.value);
                                    setHideAction(false);
                                    setReload(!reload);
                                }}
                            // onChange={(e) => console.log(e.target.value) }                                                    
                            >
                                {props.classTeacherSubject && props.classTeacherSubject.map((item1, index) => (
                                    <MenuItem key={index} value={index}>
                                        {item1.subjectName}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>
                        {props.classTeacherSubject && props.classTeacherSubject[mappingIndex] && props.classTeacherSubject[mappingIndex].quesMappingPresent === true && <Grid item sm={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Typography style={{ fontWeight: '600', marginRight: '20px' }}>
                                {
                                    `Total Exam Marks - 
                           ${props.classTeacherSubject && props.classTeacherSubject[mappingIndex] && props.classTeacherSubject[mappingIndex].totalMarks}
                            ,`
                                }
                            </Typography>
                            <Typography style={{ fontWeight: '600' }}>
                                {
                                    `Total Mapping Marks - 
                           ${totalMapping}`
                                }
                            </Typography>
                        </Grid>}
                    </Grid>
                    }
                    <Grid
                        container
                        direction="column"
                        spacing={2}
                    >
                        {window.localStorage.teacherType === "Subject" && props.examMapping === true && <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingTop: '12px', paddingBottom: '0' }}>
                            <Typography style={{ fontWeight: '600', marginRight: '20px' }}>
                                {
                                    `Total Exam Marks - 
                           ${questionMappingData[0].totalExamMarks}                            ,`
                                }
                            </Typography>
                            <Typography style={{ fontWeight: '600' }}>
                                {
                                    `Total Mapping Marks - 
                           ${totalMapping}
                           `
                                }
                            </Typography>
                        </Grid>}
                        <Grid item style={{ marginBottom: '60px' }}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table}>
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            <TableCell component="th" style={{ width: "8%" }}>
                                                Q.No.
                                            </TableCell>
                                            <TableCell component="th" style={{ width: hideAction === false ? "20%" : "28%" }}>
                                                Chapter Name
                                            </TableCell>
                                            <TableCell component="th" style={{ width: hideAction === false ? "20%" : "28%" }}>
                                                Section Name
                                            </TableCell>
                                            <TableCell component="th" style={{ width: "20%" }}>
                                                Identifier
                                            </TableCell>
                                            <TableCell component="th" style={{ width: "16%" }}>
                                                Total Marks
                                            </TableCell>
                                            {hideAction === false && <TableCell component="th" style={{ width: "16%" }}>
                                                Action
                                            </TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {questionMappingData && questionMappingData.map((item, index) => {
                                            let x = chapter.filter(y => y.chapterId === item.chapterId);
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell style={{ width: "8%" }}>
                                                        Q {index + 1}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography component="div"
                                                            style={{
                                                                maxWidth: hideAction === true ? "16em" : "10em",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            <TextField
                                                                id={`chapter${index}`}
                                                                label="Chapter Name"
                                                                select
                                                                name={`chapter${index}`}
                                                                variant="outlined"
                                                                fullWidth
                                                                value={x.length ? x[0] : ''}
                                                                className={classes.customDropdown}
                                                                onChange={(e) => addChapterId(index, item, e)}
                                                                disabled={
                                                                    item.disable ||
                                                                    window.localStorage.teacherType == "Class" && classTeacherExam === null
                                                                }
                                                            >
                                                                {chapter && chapter.map((item1, index1) => (
                                                                    <MenuItem key={index} value={item1}>
                                                                        {item1.chapterName}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography component="div"
                                                            style={{
                                                                maxWidth: hideAction === true ? "16em" : "10em",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            <TextField
                                                                id={`section${index}`}
                                                                label="Section Name"
                                                                select
                                                                name={`section${index}`}
                                                                variant="outlined"
                                                                fullWidth
                                                                value={item.chapterSectionId}
                                                                className={classes.customDropdown}
                                                                disabled={
                                                                    questionMappingData[index].chapterSectionIdData.length === 0
                                                                }
                                                                onChange={(e) => addSectionId(index, e)}
                                                                disabled={
                                                                    item.disable ||
                                                                    window.localStorage.teacherType == "Class" && classTeacherExam === null
                                                                }
                                                            >
                                                                {questionMappingData[index].chapterSectionIdData.map((item1, index) => (
                                                                    <MenuItem key={index} value={item1.chapterSectionId}>
                                                                        {item1.sectionName}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography component="div"
                                                            style={{
                                                                maxWidth: "10em",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            <TextField
                                                                id={`identifier${index}`}
                                                                label="Identifier"
                                                                select
                                                                name={`identifier${index}`}
                                                                variant="outlined"
                                                                fullWidth
                                                                value={item.identifierId}
                                                                className={classes.customDropdown}
                                                                onChange={(e) => {
                                                                    let qmappingData = [...questionMappingData];
                                                                    qmappingData[index].identifierId = e.target.value;
                                                                    setQuestionMappingData(qmappingData);
                                                                }}
                                                                disabled={
                                                                    item.disable ||
                                                                    window.localStorage.teacherType == "Class" && classTeacherExam === null
                                                                }
                                                            >
                                                                {identifier && identifier.map((item, index) => (
                                                                    <MenuItem key={index} value={item.subCategoryId}>
                                                                        {item.subCategory}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography component="div"
                                                            style={{
                                                                maxWidth: "10em",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            <NumberFormat
                                                                value={item.totalMarks}
                                                                className={classes.customDropdown}
                                                                name={`totalmarks${index}`}
                                                                customInput={TextField}
                                                                variant="outlined"
                                                                id={`totalmarks${index}`}
                                                                fullWidth
                                                                label="Marks"
                                                                allowNegative={false}
                                                                inputProps={{ maxlength: "4", }}
                                                                decimalScale={1}
                                                                disabled={
                                                                    item.disable ||
                                                                    window.localStorage.teacherType == "Class" && classTeacherExam === null
                                                                }
                                                                onChange={(e) => {
                                                                    let qmappingData = [...questionMappingData];
                                                                    qmappingData[index].totalMarks = e.target.value;
                                                                    setQuestionMappingData(qmappingData);
                                                                }}

                                                            />
                                                        </Typography>
                                                    </TableCell>

                                                    {hideAction === false && <TableCell>
                                                        <Tooltip
                                                            title="Delete Row"
                                                            classes={{
                                                                tooltip: classes.customTooltip,
                                                            }}
                                                        >
                                                            <IconButton
                                                                className={classes.iconButton}
                                                                onClick={() => deleteMappingRow(index, item)}
                                                                disabled={
                                                                    window.localStorage.teacherType == "Class" && classTeacherExam === null
                                                                }
                                                            >
                                                                <DeleteOutlinedIcon style={{ color: 'red', fontSize: '26px' }} />
                                                            </IconButton>
                                                        </Tooltip>

                                                        {props.examMapping === true && item.disable === true &&
                                                            (
                                                                <Tooltip
                                                                    title="Edit"
                                                                    classes={{
                                                                        tooltip: classes.customTooltip,
                                                                    }}
                                                                >
                                                                    <IconButton
                                                                        className={classes.iconButton}
                                                                        onClick={() => {
                                                                            let data = [...questionMappingData]
                                                                            data[index].disable = false;
                                                                            setQuestionMappingData(data);
                                                                        }}

                                                                    >
                                                                        <EditOutlinedIcon style={{ color: 'deepskyblue', fontSize: '26px' }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )
                                                        }

                                                        {window.localStorage.teacherType === "Subject" && props.examMapping === true && item.disable === false &&
                                                            <Tooltip
                                                                title="Update Question Mapping Row"
                                                                classes={{
                                                                    tooltip: classes.customTooltip,
                                                                }}
                                                            >
                                                                <IconButton
                                                                    className={classes.iconButton}
                                                                    onClick={() => editMappingRow(index, item)}
                                                                >
                                                                    <DoneOutlineOutlinedIcon style={{ color: 'green', fontSize: '26px' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }

                                                        {window.localStorage.teacherType === "Class" && props.classTeacherSubject && props.classTeacherSubject[mappingIndex] && props.classTeacherSubject[mappingIndex].quesMappingPresent === true && item.disable === false &&
                                                            <Tooltip
                                                                title="Update Question Mapping Row"
                                                                classes={{
                                                                    tooltip: classes.customTooltip,
                                                                }}
                                                            >
                                                                <IconButton
                                                                    className={classes.iconButton}
                                                                    onClick={() => editMappingRow(index, item)}
                                                                >
                                                                    <DoneOutlineOutlinedIcon style={{ color: 'green', fontSize: '26px' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        {window.localStorage.teacherType === "Class" && props.classTeacherSubject && props.classTeacherSubject[mappingIndex] && props.classTeacherSubject[mappingIndex].quesMappingPresent === true && item.examQuesMapId === undefined &&
                                                            <Tooltip
                                                                title="Save Question Mapping"
                                                                classes={{
                                                                    tooltip: classes.customTooltip,
                                                                }}
                                                            >
                                                                <IconButton
                                                                    className={classes.iconButton}
                                                                    onClick={() => saveSingleMappingRow(index, item)}
                                                                >

                                                                    <DoneOutlineOutlinedIcon style={{ color: 'green', fontSize: '26px' }} />

                                                                </IconButton>
                                                            </Tooltip>
                                                        }

                                                        {window.localStorage.teacherType === "Subject" && props.examMapping === true && item.examQuesMapId === undefined &&
                                                            <Tooltip
                                                                title="Save Question Mapping"
                                                                classes={{
                                                                    tooltip: classes.customTooltip,
                                                                }}
                                                            >
                                                                <IconButton
                                                                    className={classes.iconButton}
                                                                    onClick={() => saveSingleMappingRow(index, item)}
                                                                >

                                                                    <DoneOutlineOutlinedIcon style={{ color: 'green', fontSize: '26px' }} />

                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </TableCell>}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography component="div" style={{ marginTop: '15px' }}>
                                Note - Click submit button to view total mapping marks and Click on x to close
                            </Typography>


                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {hideAction === false && <Typography variant="div">
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ color: "white" }}
                            onClick={addQuestionMappingRow}
                            disabled={
                                window.localStorage.teacherType == "Class" && classTeacherExam === null
                            }
                        >
                            Add Question
                        </Button>
                    </Typography>
                    }
                    {window.localStorage.teacherType === "Subject" && props.examMapping != true &&
                        <Typography variant="div">
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ color: "white" }}
                                disabled={
                                    window.localStorage.teacherType == "Class" && classTeacherExam === null
                                }
                                onClick={handleUploadSubmit}
                            >
                                {myLoader === false ? (
                                    "Submit"
                                ) : (
                                    <CircularProgress color={theme.palette.secondary.light} />
                                )}
                            </Button>
                        </Typography>
                    }

                    {window.localStorage.teacherType === "Class" && props.classTeacherSubject && props.classTeacherSubject[mappingIndex] && props.classTeacherSubject[mappingIndex].quesMappingPresent != true &&
                        <Typography variant="div">
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ color: "white" }}
                                disabled={
                                    window.localStorage.teacherType == "Class" && classTeacherExam === null
                                }
                                onClick={handleUploadSubmit}
                            >
                                {myLoader === false ? (
                                    "Submit"
                                ) : (
                                    <CircularProgress color={theme.palette.secondary.light} />
                                )}
                            </Button>
                        </Typography>
                    }

                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={snack}
                autoHideDuration={9000}
                onClose={() => setSnack(false)}
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setSnack(false)}
                        >
                            <CloseIcon
                                style={{ color: theme.palette.common.tabFont }}
                                fontSize="small"
                            />
                        </IconButton>
                    </React.Fragment>
                }
            >
                <Alert severity={status}>{message}</Alert>
            </Snackbar>

            <Backdrop className={classes.backdrop} open={backLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}
