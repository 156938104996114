import React, { useEffect, useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import TabMaker from "./TabMaker";
import TableMaker from "./TableMaker";
import { groupBy } from "lodash";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { url } from "./store.js";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
}));

const parentHeader = ["Chaper", "Status", "Pending For", "Teacher Task", "Comments", "Your Task"];


const headers = ["Chaper", "Status", "Pending For", "Teacher Task", "Comments", "Your Task", "Upload"];

const MyDataMaker = (props) => {
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_TO_DO_TASKS",
              busiParams: {
                active: true,
                studentRequested: true,
                branchId: window.localStorage.branchId,
                secSubjectMappingId: props.secId,
                studentInstanceId: window.localStorage.studentInstanceId,
                resourceType: 2,
                todoTracker: true,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let row = [];
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
              //  dataset.labels.push(item.resourceName);
              //  dataset.datasets[0].data.push(
              //parseInt(item.taskPerc.replace("%", ""))
              //    );

              window.localStorage.roleId == "5" ?
                row.push([
                  {
                    title: item.chapterName,
                    type: "text",
                    taskId: item.taskId,
                    taskTxnId: item.taskTxnId,
                  },
                  { title: item.taskStatus, type: "status" },
                  { title: item.pendingDays, type: "text" },
                  {
                    title: item.taskFileName,
                    type: item.taskFileType,
                    href: item.taskAttachment,
                  },
                  { title: item.comment, type: "text" },
                  {
                    title: item.fileName ? item.fileName : "No File",
                    type: item.fileType,
                    href: item.solutionAttachment,
                  },
                ]) :
                
                row.push([
                  {
                    title: item.chapterName,
                    type: "text",
                    taskId: item.taskId,
                    taskTxnId: item.taskTxnId,
                  },
                  { title: item.taskStatus, type: "status" },
                  { title: item.pendingDays, type: "text" },
                  {
                    title: item.taskFileName,
                    type: item.taskFileType,
                    href: item.taskAttachment,
                  },
                  { title: item.comment, type: "text" },
                  {
                    title: item.fileName ? item.fileName : "No File",
                    type: item.fileType,
                    href: item.solutionAttachment,
                  },
                  {type: "upload", taskStatus: item.taskStatus }
                ]);
            })
            : row.push([
              { title: "No Data Available", type: "text" },
              { title: "", type: "text" },
              { title: "", type: "text" },
              { title: "", type: "text" },
              { title: " ", type: "text", },
              { title: "", type: "text" },
              { title: "", type: "text" },
            ]);
          setRows(row);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, []);

  return (
    <React.Fragment>
      {loader === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <TableMaker
          columns={window.localStorage.roleId == "5" ?
            parentHeader :
            headers}
          rows={rows}
          setToReLoad={props.setToReLoad}
          toReload={props.toReload}
        />
      )}
    </React.Fragment>
  );
};

export default function ToDoHw() {
  const classes = useStyle();
  const theme = useTheme();
  const [toDoHw, setToDoHw] = useState([]);
  const [loader, setLoader] = useState(true);
  const [toReload, setToReLoad] = useState(false);


  useEffect(() => {
    setLoader(true);
    const AllLists = [];
    let subjects = JSON.parse(window.localStorage.getItem("users") || "[]");
    console.log(subjects);
    subjects &&
      subjects.map((item, index1) => {
        let temList = (
          <MyDataMaker
            key={index1}
            secId={item.secSubSylMappingId}
            setToReLoad={setToReLoad}
            toReload={toReload}
          />
        );

        AllLists.push(temList);
      });
    setToDoHw(AllLists);
    setInterval(() => {
      setLoader(false);
    }, 500);
  }, [toReload]);

  // useEffect(() => {
  //   const getData = async () => {
  //     const DATA = await fetch(url, {
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       method: "POST",
  //       body: JSON.stringify(bodyObjToDoHw),
  //     });
  //     let tempEvents = [];
  //     const RESPONSE = await DATA.json();
  //     console.log(RESPONSE);
  //     RESPONSE.respData[0].respMsg.result.map((item, index) => {
  //       tempEvents.push(item);
  //     });

  //     const AllLists = [];

  //     subjects.map((data, myindex) => {
  //       tempEvents.map((item, index, arr) => {
  //         if (item.secSubjectMappingId === data.secSubSylMappingId) {
  //           arr[index] = { ...arr[index], subjectMapPosition: myindex };
  //         }
  //       });
  //     });

  //     let grouped = groupBy(tempEvents, (item) => item.subjectMapPosition);

  //     console.log(grouped);
  //     for (const x in grouped) {
  //       let mydata = grouped[x];
  //       let row = [];
  //       console.log(`this is grouuped data of subject ${x}`);
  //       mydata.map((item, index) => {
  //         console.log(item);
  //         row.push([
  //           { title: item.taskName, type: "text" },
  //           { title: item.taskStatus, type: "status" },
  //           { title: item.pendingDays, type: "text" },
  //           {
  //             title: item.fileName,
  //             type: item.fileType,
  //             href: item.solutionAttachment,
  //           },
  //         ]);
  //       });

  //       let temList = (
  //         <React.Fragment key={x}>
  //           <TableMaker columns={headers} rows={row} />;
  //         </React.Fragment>
  //       );

  //       AllLists.push(temList);
  //     }
  //     console.log(AllLists);
  //     setToDoHw(AllLists);
  //   };
  //   getData().catch((data) => {
  //     console.log("sorry something went wrong");
  //     // setError(true);
  //   });
  // }, []);

  const myTableData = [...toDoHw];

  let subjects = JSON.parse(window.localStorage.getItem("users") || "[]");

  let tabheadings = [];
  try {
    tabheadings = subjects.map((a) => a.subjectName);
  } catch (error) {
    console.error(error);
  }
  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TabMaker
              tabs={tabheadings}
              data={toDoHw}
              subjectBased={true}
            />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
