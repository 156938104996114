import React from "react";

import { Line, defaults } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";

require("../Ui/roundedbar.js");

function LineGraph(props) {
  const [state, setState] = React.useState(props.data);
  const theme = useTheme();
  defaults.global.defaultFontFamily = theme.typography.fontFamily;

  return (
    <React.Fragment>
      <Grid
        item
        container
        style={{
          backgroundColor: "#ffffff",
          marginTop: props.margintop,
          width: props.width,
        }}
      >
        <Grid item container>
          <Line
            data={state}
            options={{
              layout: {
                padding: {
                  left: 5,
                  right: 5,
                  top: 40,
                  bottom: 0,
                },
              },
              tooltips: {
                enabled: true,
                mode: "nearest",
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min: 0,
                      max: 100,

                      stepSize: 25,
                      callback:
                        props.percentage === true
                          ? function (value, index, values) {
                              return value + "%";
                            }
                          : function (value, index, values) {
                              return value;
                            },
                    },
                    gridLines: {
                      color: [
                        "#f5f5f5",
                        "#f5f5f5",
                        "#f5f5f5",
                        "#f5f5f5",
                        "#f5f5f5",
                        "#f5f5f5",
                        "#f5f5f5",
                        "#f5f5f5",
                        "#f5f5f5",
                        "#f5f5f5",
                        "#f5f5f5",
                      ],
                    },
                  },
                ],
                xAxes: [
                  {
                    ticks: {
                      lineHeight: 1.2,
                    },

                    gridLines: {
                      display: false,
                    },
                    offset: true,
                  },
                ],
              },

              legend: {
                display: true,
                position: "bottom",
                labels: {
                  boxWidth: 10,
                  fontSize: 10,
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default LineGraph;
