import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import GeneralUi from "./Ui/GeneralUi.js";

import WorkSpaceHeader from "./Ui/WorkSpaceHeader.js";

import { Grid, Typography, Fade } from "@material-ui/core";

import tablet from "./assets/tablet.png";
import connectZone from "./assets/ConnectZone.png";
import notificationCenter from "./assets/notificationCenter.png";
import calendarIcon from "./assets/calendarIcon.svg";
import icon_teacher from "./assets/icon_teacher.svg";
import { useRouteMatch } from "react-router-dom";

import NotificationList from "./Ui/NotificationList.js";
import ConnectZone from "./Ui/ConnectZone.js";
import MessageTeacher from "./Ui/MessageTeacher.js";
import Messaging from "./Ui/Messaging.js";
import Vigilance from "./Ui/Vigilance.js";
import NotificationTeacher from "./Ui/NotificationListTeacher.js";
import StudentDetails from "./Ui/StudentDetails.js";
import GeneralUiTeacher from "./Ui/GeneralUiTeacher.js";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    ...theme.typography.mainContainer,
    height: "100% ",
    minHeight: "130vh",
  },
  tile1: {
    ...theme.typography.tiles,

    backgroundImage: `url(${notificationCenter})`,
    borderTopLeftRadius: theme.typography.mainContainer.borderTopLeftRadius,
    transition: "width 2s",
  },
  tile2: {
    ...theme.typography.tiles,
    backgroundImage: `url(${tablet})`,
  },
  tile3: {
    ...theme.typography.tiles,
    backgroundImage: `url(${connectZone})`,
  },
  headingItem: {
    ...theme.typography.tilesHeading,
    boxShadow: theme.shadows[5],
  },
}));

export default function LandingPageStudent(props) {
  const classes = useStyle();
  let { path, url } = useRouteMatch();
  const [messageRefresh, setmessageRefresh] = useState(false);

  const teacherDetails = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/TeacherDetails` ? true : false}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader
            title="TEACHER DETAILS"
            imgIcon={icon_teacher}
            moduleId={4}
          />

          <GeneralUi part="teacherDetails" />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const timetable =
    window.localStorage.roleId == 3 ? (
      <React.Fragment>
        <Fade
          in={window.location.pathname === `${url}/TimeTable` ? true : false}
          mountOnEnter
          unmountOnExit
          timeout={{ accept: 800, enter: 1000, exit: 300 }}
        >
          <Grid item container direction="column">
            <WorkSpaceHeader
              title="TIME TABLE"
              imgIcon={icon_teacher}
              moduleId={6}
            />
            <GeneralUiTeacher />
          </Grid>
        </Fade>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Fade
          in={window.location.pathname === `${url}/TimeTable` ? true : false}
          mountOnEnter
          unmountOnExit
          timeout={{ accept: 800, enter: 1000, exit: 300 }}
        >
          <Grid item container direction="column">
            <WorkSpaceHeader
              title="TIME TABLE"
              imgIcon={icon_teacher}
              moduleId={6}
            />
            <GeneralUi part="timeTable" />
          </Grid>
        </Fade>
      </React.Fragment>
    );

  const calendar = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/Calendar`}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader
            title="CALENDAR"
            imgIcon={calendarIcon}
            moduleId={7}
          />
          <GeneralUi part="calendar" />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const notifications = (
    <React.Fragment>
      <Fade
        in={
          window.location.pathname === `${url}/Notification` ||
          window.location.pathname === `${url}`
        }
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader
            title="NOTIFICATIONS"
            moduleId={1}
            vclink={window.localStorage.roleId == 4 ? true : false}
            sendNotification={window.localStorage.roleId == 3 ? true : false}
            messageRefresh={messageRefresh}
            setmessageRefresh={setmessageRefresh}
          />
          <NotificationList messageRefresh={messageRefresh} />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const connectzone = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/ConnectZone` ? true : false}
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="CONNECT ZONE" moduleId={3} />
          <ConnectZone />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const studentDetails = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/StudentDetails` ? true : false}
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="STUDENT DETAILS" moduleId={33} />
          <StudentDetails />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  // const timeTableTeacher = (
  //   <React.Fragment>
  //     <Fade
  //       in={
  //         window.location.pathname === `${url}/TimeTableTeacher` ? true : false
  //       }
  //       mountOnEnter
  //       unmountOnExit
  //       timeout={{ accept: 800, enter: 1000, exit: 300 }}
  //     >
  //       <Grid item container direction="column">
  //         <WorkSpaceHeader
  //           title="TIME TABLE"
  //           imgIcon={icon_teacher}
  //           moduleId={6}
  //         />
  //         <TimeTableTeacher />
  //       </Grid>
  //     </Fade>
  //   </React.Fragment>
  // );

  const messageteacher = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/MessageTeacher` ? true : false}
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader
            title={
              window.localStorage.roleId == 3 ? "MESSAGING" : "MESSAGE TEACHER"
            }
            moduleId={window.localStorage.roleId == 3 ? 5 : 29}
            message={true}
            messageRefresh={messageRefresh}
            setmessageRefresh={setmessageRefresh}
          />
          {window.localStorage.roleId == 3 ? (
            <Messaging
              messageRefresh={messageRefresh}
              setMessageRefresh={setmessageRefresh}
            />
          ) : (
            <MessageTeacher
              messageRefresh={messageRefresh}
              setMessageRefresh={setmessageRefresh}
            />
          )}
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const messaging = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/Messaging` ? true : false}
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader
            title="MESSAGING"
            moduleId={5}
            messageRefresh={messageRefresh}
            setmessageRefresh={setmessageRefresh}
          />
          <Messaging
            messageRefresh={messageRefresh}
            setMessageRefresh={setmessageRefresh}
          />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const vigilance = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/Vigilance` ? true : false}
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="VIGILANCE MODE" moduleId={28} />
          <Vigilance />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        className={classes.mainContainer}
        style={{ width: `calc(100% - 12em)` }}
      >
        {window.location.pathname === "/LandingPageStudent/Notification" ? (
          notifications
        ) : window.location.pathname === "/LandingPageStudent/ConnectZone" ? (
          connectzone
        ) : window.location.pathname ===
          "/LandingPageStudent/TeacherDetails" ? (
          teacherDetails
        ) : window.location.pathname ===
          "/LandingPageStudent/StudentDetails" ? (
          studentDetails
        ) : window.location.pathname === "/LandingPageStudent/TimeTable" ? (
          timetable
        ) : window.location.pathname === "/LandingPageStudent/Calendar" ? (
          calendar
        ) : window.location.pathname === "/LandingPageStudent/Vigilance" ? (
          window.localStorage.roleId == 5 ? (
            vigilance
          ) : (
            <Typography variant="h1" color="secondary">
              This is a restricted area
            </Typography>
          )
        ) : window.location.pathname ===
          "/LandingPageStudent/MessageTeacher" ? (
          window.localStorage.roleId == 5 ? (
            messageteacher
          ) : (
            <Typography variant="h1" color="secondary">
              This is a restricted area
            </Typography>
          )
        ) : window.location.pathname === "/LandingPageStudent/Messaging" ? (
          window.localStorage.roleId == 3 ? (
            messaging
          ) : (
            <Typography variant="h1" color="secondary">
              This is a restricted area
            </Typography>
          )
        ) : (
          <Typography variant="h1">To be designed</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
}
