import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";


import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App className="App" />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
