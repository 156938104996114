import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
  TextField,
  IconButton,
  Snackbar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import TabMaker from "./TabMaker";
import TableMaker from "./TableMaker";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { groupBy } from "lodash";
import { url } from "./store.js";
import { set } from "date-fns";
import moment from "moment";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },

  closeSnackColor: {
    color: theme.palette.common.tabFont,
  },

  myTextFieldStyle: {
    "& .MuiFormLabel-root": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiInputBase-input": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiSelect-icon":{
      color: theme.typography.myTheme.themeName == "Night" ? "#fff" : "inherit", 
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateGroup(props) {
  const classes = useStyle();
  const theme = useTheme();
  const [connectZone, setConnectZone] = useState([]);
  const [renderAg, setRenderAg] = useState(false);
  const [loader, setLoader] = useState(true);
  const [date, setDate] = useState(moment());
  const [group, setGroup] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const headers = ["ck", "Student", "RollNo", "Delete"];
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [snack, setSnack] = useState(false);
  const [reRender, setRerender] = useState(false);
  const [dialog, openDialog] = useState(false);

  useEffect(() => {
    setLoader(true);
    setSelectedGroup(0);
    const AllLists = [];

    let row = [];
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_GROUP_LIST_BY_SECSUBMAPPING_ID",
              busiParams: {
                secSubSylMappingId: window.localStorage.secSylMappingId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
                row.push(item);
              })
            : row.push({
                subjectName: "No Groups Present For This Subject",
                type: "text",
              });

          setGroup(row);
        })

        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }
  }, [props.force, reRender]);

  const handleGroupListing = (e) => {
    setLoader(true);
    setSelectedGroup(e.target.value);
    const AllLists = [];

    let row = [];
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_STUDENT_BY_GROUP_AND_SECSUBMAPPING_ID",
              busiParams: {
                groupId: e.target.value,
                secSubSylMappingId: window.localStorage.secSylMappingId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
                //  dataset.labels.push(item.resourceName);
                //  dataset.datasets[0].data.push(
                //parseInt(item.taskPerc.replace("%", ""))
                //    );

                row.push([
                  {
                    title: "ck",
                    type: "checkbox1",
                    fullDetails: item,
                  },
                  { title: item.studentName, type: "text" },
                  { title: item.rollNo, type: "text" },
                  {
                    title: "",
                    type:
                      item.groupId === null
                        ? "text"
                        : e.target.value === item.groupId
                        ? "DeleteContributor"
                        : "text",
                    fullDetails: item,
                  },
                ]);
              })
            : row.push([
                { title: "No Data Available", type: "text" },
                { title: "", type: "text" },
                { title: "", type: "text" },
                { title: "", type: "text" },
              ]);

          setConnectZone(row);
        })

        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      setLoader(false);
    }, 3000);
  };

  const deleteStudentGrp = () => {
    console.log(selectedGroup);

    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_DELETE_STUDENT_GROUP",
              busiParams: {
                groupId: selectedGroup,
                loginUserId:  parseInt(window.localStorage.userId),
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if(data.respData[0].respMsg.status === "Success"){
            openDialog(false);
            setMessage(data.respData[0].respMsg.message);
            setStatus("success");
            setSnack(true);
            setRerender(!reRender);
          }else{
            setMessage("something went wrong");
            setStatus("error");
            setSnack(true);
          }
            
        })

        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="row"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          <TextField
            label="Select Group"
            value={selectedGroup}
            select
            style={{ minWidth: "15em" }}
            variant="outlined"
            className={classes.myTextFieldStyle}
            onChange={handleGroupListing}
          >
            {group.map((item, index) => (
              <MenuItem key={index} value={item.groupId}>
                {item.groupName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item>
        <IconButton
          onClick={ () => openDialog(true)}
          disabled={selectedGroup === 0 ? true : false }
        >
          <DeleteOutlineIcon color="secondary" />
        </IconButton>
        
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {selectedGroup === 0 ? (
            <Typography variant="h6" color="secondary">
              Please Select A Group
            </Typography>
          ) : loader === false ? (
            <TableMaker
              columns={headers}
              rows={connectZone}
              renderAg={renderAg}
              handleReRender={setRenderAg}
              date={date}
              setDate={setDate}
              groupId={selectedGroup}
              addContributor={true}
              handleGroupListing={handleGroupListing}
            />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
        
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                // style={{ color: theme.palette.common.tabFont }}
                className={classes.closeSnackColor}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>

      <Dialog
        open={dialog}
        onClose={() => openDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this group ? As deleting this will lead you to the loss of data related to the group. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => openDialog(false)}
            style={{ color: theme.palette.secondary.main }}>
            No
          </Button>
          <Button onClick={deleteStudentGrp}
                style={{ color: theme.palette.secondary.main }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
