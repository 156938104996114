import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Link,
  CircularProgress,
  Snackbar,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Backdrop,
  MenuItem,
  Menu,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import trunkienew from "./assets/trunkienew.png";

import projectheen from "./assets/projectheen.svg";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import is from "is_js";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import gbutton from "./assets/gbutton.png";
import ibutton from "./assets/ibutton.png";
import InfoIcon from "@material-ui/icons/Info";

import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { url, urlmultipart, getSession } from "../Ui/store.js";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";

const useStyles = makeStyles((theme) => ({
  root1: {
    flexGrow: 1,
  },
  menuButton1: {
    marginRight: theme.spacing(2),
  },
  title1: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
  AppBar: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 2,
    background:
      "linear-gradient(339deg, rgb(0, 26, 77) 0%, rgb(1, 27, 78) 99%)",
    width: "100vw",
  },
  AppBar1: {
    position: "fixed",

    zIndex: theme.zIndex.drawer + 2,
    background: "transparent",
    marginTop: "4em",
  },
  AppBar2: {
    position: "fixed",

    zIndex: theme.zIndex.drawer + 2,
    background: "black",
    top: "0em",
  },
  pageContainer: {
    width: "100vw",

    backgroundColor: "white",
  },

  pageContainer4: {
    width: "100vw",

    backgroundColor: "#FAFAFA",
  },
  footer: {
    width: "100vw",
    height: "20em",
    backgroundColor: "white",
  },
  current: {
    color: "#F4CE2C",
  },
  notcurrent: {
    color: "white",
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "100em",
      height: "43em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },

  dialogwidth: {
    maxWidth: "80em",
    maxHeight: "100em",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const list1 = [
  "Add questions and answers to the question bank filling all attributes.",
  "Boards include CBSE, State board, IGCSE, ICSE/ICS, IB, IIT JEE and NEET curriculum.",
  "You can contribute to open sections which is listed in dashboard section.",
  "Question and answer contributed from your end shall be verified by a set of experienced and skilled teachers at our end.",
  "You shall receive the payment based on quality, difficulty level and type of question you would choose to contribute.",
  "You shall rework and resubmit if teachers raises a query.",
  "Similarity index will be provided for each and every question and similar questions will be shown.",
  "Only when similarity index is lesser than threshold value, a contributor can contribute questions.",
  "If similarity index is more than threshold, contributor must enter different question as the question already exists in question bank.",
  "Please visit rewards section for more details related to renumeration and incentives.",
  "You may contact us is via Trunkie@Projectheen.com if you face any issues in contributing questions.",
];

const list2 = [
  "You are responsible for question and answer provided by you and you must ensure that its not taken from any other author or source.",
  "Projectheen shall not be responsible for any such acts performed by you.",
  "Contributor shall not use the portal for any other purpose other than defined roles and responsibilities.",
  "Questions contributed by you for the month shall be verified and approved on daily basis by our set of experienced teachers and then rewards shall be paid to the contributor at the end of the month.",
  "Contributor shall be provided with a detailed breakup of every payment made to them based on quality,difficulty level and type of questions contributed.",
  "Projectheen shall terminate your enrolment with us without prior notice if found to misuse our product/services and is a punishable offence.",
];

const list3 = [
  "Help every student to get continuous and quality education",
  "Boost students academic performance.",
  "Help students develop holistically.",
  "Improve teacher’s quality by providing them the latest tools that saves time.",
  "Have one common grading system for the whole nation",
];

export default function ContributeQuestions() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [myAttachment, setMyAttachment] = useState({
    attachment: "",
    fileId: 0,
    fileType: "",
    fileName: "",
  });
  const [myLoader, setMyLoader] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [testChecked, setTestChecked] = useState(false);
  const [snack, setSnack] = useState(false);
  const [dropZone, setDropZone] = useState(false);
  const [dropZoneMCQ, setDropZoneMCQ] = useState(false);
  const [checkedTest, setCheckedTest] = useState(false);
  const [materialName, setMaterialName] = useState("");
  const [backLoader, setBackLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [openMCQ, setOpenMCQ] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleIAgree = () => {
    setLoader(false);
    setOpen(false);
    setTestChecked(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [emailDetails, setEmailDetails] = useState({
    emailId: "",
    firstName: "",
    gender: 1,
    jobTitle: "",
    lastName: "",
    linkedInId: "",
    loginUserId: 0,
    middleName: "",
    primaryAddress: "",
    primaryContactNo: "",
    qualification: "",
    resumeFileId: 0,
    resumeFileName: "",
    resumeFileType: "",
    resumeUrl: "",
    secondaryContactNo: "",
    teachingExp: 0,
    subjectHandled: "",
    preferredSubject: "",
    boardsHandled: "",
    currentlyWorking: false,
    classesHandled: "",
    mcqUploadId: "",
    mcqUploadName: "",
    mcqUploadType: "",
    mcqUploadUrl: "",
    worksInMsOffice: false,
  });
  const history = useHistory();
  const theme = useTheme();

  const handleChange = (e) => {
    setEmailDetails({ ...emailDetails, [e.target.name]: e.target.value });
    console.log(emailDetails);
  };

  const handleCurrentWorking = (e) => {
    setEmailDetails({ ...emailDetails, [e.target.name]: e.target.checked });
    console.log(emailDetails);
  };

  const handleDropZone = () => {
    setDropZone(true);
  };

  const handleDropZoneMCQ = () => {
    setDropZoneMCQ(true);
  };

  const handleDropZoneClose = () => {
    setDropZone(false);
  };
  const handleDropZoneMCQClose = () => {
    setDropZoneMCQ(false);
  };

  useEffect(() => {
    getSession();
  }, []);

  const handleSaveMaterial = async (files, type) => {
    setBackLoader(true);
    var mydata = new FormData();
    mydata.append("file", files[0]);
    const options = {
      headers: {
        Accept: "application/json",
        type: "formData",
      },
      method: "POST",
      body: mydata,
    };
    delete options.headers["Content-Type"];
    await fetch(urlmultipart, options)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        type == "RESUME"
          ? setEmailDetails({
              ...emailDetails,
              resumeUrl: data.fileURL,
              resumeFileName: data.fileName,
              resumeFileType: data.fileType,
              resumeFileId: data.bucketId,
            })
          : setEmailDetails({
              ...emailDetails,
              mcqUploadUrl: data.fileURL,
              mcqUploadName: data.fileName,
              mcqUploadType: data.fileType,
              mcqUploadId: data.bucketId,
            });

        setDropZone(false);
        setDropZoneMCQ(false);
        setBackLoader(false);
      })
      .catch((data) => {
        console.error(data);
        setDropZone(false);
        setDropZoneMCQ(false);
        setBackLoader(false);
      });
  };

  const handleUploadSubmit = () => {
    console.log(emailDetails);
    if (
      emailDetails.firstName === "" ||
      emailDetails.lastName === "" ||
      emailDetails.subjectHandled === "" ||
      emailDetails.preferredSubject === "" ||
      emailDetails.boardsHandled === "" ||
      emailDetails.classesHandled === "" ||
      emailDetails.mcqUploadName === "" ||
      emailDetails.primaryAddress === "" ||
      emailDetails.qualfication === "" ||
      emailDetails.resumeFileName === "" ||
      validatePhone(emailDetails.primaryContactNo) === false ||
      validateEmail(emailDetails.emailId) === false
    ) {
      setShowError(true);
      console.log("cant submit yet");
    } else if (
      emailDetails.currentlyWorking === true &&
      emailDetails.jobTitle === ""
    ) {
      setShowError(true);
      console.log("cant submit yet 2");
    }else if (
      emailDetails.teachingExp > 0 &&
      emailDetails.currentlyWorking === false &&
      emailDetails.jobTitle === ""
    ) {
      setShowError(true);
      console.log("cant submit yet 2");
    } else if (
      emailDetails.teachingExp == 0 &&
      emailDetails.currentlyWorking === true &&
      emailDetails.jobTitle != ""
    ) {
      setShowError(true);
      console.log("cant submit yet 2");
    } else if (testChecked === false) {
      setCheckedTest(true);
    } else {
      setCheckedTest(false);
      setShowError(false);
      setLoader(true);
      try {
        fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            requestList: [
              {
                isEncrypt: false,
                transactionId: "0000001438241244",
                busiCode: "BO_CONTRIBUTOR_REGISTRATION",
                busiParams: emailDetails,
              },
            ],
            pubInfo: {
              appId: "appId",
              sessionId: window.localStorage.sessionId,
              version: "21",
              timestamp: "20180314175419",
              lang: "en_US",
              userId: "1000016614",
              serviceId: "7021150585",
              circleId: "MU",
              ncsroute: "WE015",
              customerId: "1000016614",
              osType: "windows",
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            setMessage(data.respData[0].respMsg.message);
            if (data.respData[0].respMsg.status === "Success") {
              setStatus("success");
              setSnack(true);
              setSendSuccess(true);
              setTimeout( () => {
                setSendSuccess(false);
            }, 7000);
              setEmailDetails({
                emailId: "",
                firstName: "",
                gender: 1,
                jobTitle: "",
                lastName: "",
                linkedInId: "",
                loginUserId: 0,
                middleName: "",
                primaryAddress: "",
                primaryContactNo: "",
                qualification: "",
                resumeFileId: 0,
                resumeFileName: "",
                resumeFileType: "",
                resumeUrl: "",
                secondaryContactNo: "",
                teachingExp: 0,
                subjectHandled: "",
                preferredSubject: "",
                boardsHandled: "",
                currentlyWorking: false,
                classesHandled: "",
                mcqUploadId: "",
                mcqUploadName: "",
                mcqUploadType: "",
                mcqUploadUrl: "",
                worksInMsOffice: false,
              });
              setTestChecked(false);
            } else {
              setStatus("error");
              setSnack(true);
            }
            console.log(data);
            setLoader(false);
          })
          .catch((data) => {
            console.error(data);
            setLoader(false);
          });
      } catch (error) {
        console.log(error);
        setLoader(false);
        setSendSuccess(false);
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phoneNo) => {
    const re = /^[0-9]{10}$/;
    return re.test(parseInt(phoneNo));
  };

  // useEffect(() => {
  //   props.setTheme(ThemeStaticPage);
  // }, []);

  return (
    <React.Fragment>
      <Grid container direction="column">
        <div className={classes.root1}>
          <AppBar classes={{ root: classes.AppBar }}>
            <Toolbar>
              <IconButton
                edge="start"
                className={classes.menuButton1}
                color="inherit"
                aria-label="menu"
                onClick={() => history.push("/")}
              >
                <img src={projectheen} alt="projectheen logo" />
              </IconButton>
              <div className={classes.title1}></div>

              <Button
                variant="contained"
                style={{ backgroundColor: "#F4CE2C", color: "white" }}
                onClick={() => history.push("/Login")}
              >
                Login
              </Button>
              <Typography
                variant="body1"
                style={{
                  color: "white",
                  textTransform: "none",
                  marginLeft: "1em",
                }}
                onClick={handleOpenMenu}
              >
                Join Us
              </Typography>
              <IconButton
                aria-label=""
                style={{ padding: "0" }}
                onClick={handleOpenMenu}
              >
                <ArrowDropDownRoundedIcon style={{ color: "white" }} />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>Teacher</MenuItem>
                <MenuItem
                  onClick={() => {
                    history.push("/ContributeQuestions");
                    setAnchorEl(null);
                  }}
                >
                  Contributor
                </MenuItem>
                <MenuItem>Marketing Partner</MenuItem>
              </Menu>
              {/* <Button
                style={{
                  color: "#F4CE2C",
                  textTransform: "none",
                }}
              >
                Contribute Questions
              </Button> */}
              {/* <Button style={{ color: "white", textTransform: "none" }}>
                Become a Partner
              </Button> */}

              <Button style={{ color: "white", textTransform: "none" }}>
                FAQs
              </Button>
            </Toolbar>
          </AppBar>
        </div>
        <div id="pg1" style={{ margin: 0, padding: 0 }}>
          <Grid
            item
            container
            direction="column"
            className={classes.pageContainer}
          >
            <Grid
              item
              container
              direction="column"
              style={{ marginTop: "6em", marginLeft: "5em" }}
            >
              <Grid item>
                <Typography
                  variant="h2"
                  style={{ color: "#040404" }}
                  gutterBottom
                >
                  Contribute Questions
                </Typography>
                <Typography
                  variant="h4"
                  style={{ color: "#3E3E3E" }}
                  gutterBottom
                >
                  Roles & Responsibility
                </Typography>

                <List dense={true} style={{ maxWidth: "68em" }}>
                  {list1.map((item, index) => (
                    <ListItem key={item + index}>
                      <ListItemIcon>
                        <FiberManualRecordIcon
                          style={{ fontSize: "10px", color: "#001A4E" }}
                        />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          variant="body1"
                          style={{ color: "#3E3E3E" }}
                          gutterBottom
                        >
                          {item}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div id="pg4" style={{ margin: 0, padding: 0 }}>
          <Grid
            item
            container
            direction="column"
            className={classes.pageContainer4}
            style={{ paddingTop: "1em" }}
            alignItems="flex-start"
          >
            <Grid item style={{ marginLeft: "6em" }}>
              <Typography
                variant="h4"
                align="center"
                style={{ color: "#3E3E3E", fontSize: "1.7rem" }}
                gutterBottom
              >
                Enroll With Us
              </Typography>
            </Grid>

            <Grid item container style={{ width: "100%" }}>
              <div
                style={{
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  paddingLeft: "1em",
                  paddingRight: "1em",
                }}
                square
              >
                <Grid container direction="column" spacing={3}>
                  <Grid item container direction="column">
                    {showError === true ? (
                      <Typography
                        variant="subtitle"
                        align="left"
                        style={{
                          color: "red",
                          marginBottom: "0.5em",
                        }}
                        paragraph
                      >
                        * Please enter all mandatory fields to send message
                      </Typography>
                    ) : undefined}
                    {checkedTest === true ? (
                      <Typography
                        variant="subtitle"
                        align="left"
                        style={{
                          color: "red",
                          marginBottom: "0.5em",
                        }}
                        paragraph
                      >
                        * Please agree to the terms and conditions
                      </Typography>
                    ) : undefined}
                  </Grid>

                  <Grid item container spacing={3}>
                    <Grid item container sm>
                      <TextField
                        label="First Name *"
                        variant="outlined"
                        color="primary"
                        name="firstName"
                        value={emailDetails.firstName}
                        fullWidth
                        onChange={handleChange}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    <Grid item container sm>
                      <TextField
                        label="Middle Name"
                        variant="outlined"
                        color="primary"
                        name="middleName"
                        value={emailDetails.middleName}
                        fullWidth
                        onChange={handleChange}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    <Grid item container sm>
                      <TextField
                        label="Last Name *"
                        variant="outlined"
                        color="primary"
                        name="lastName"
                        value={emailDetails.lastName}
                        fullWidth
                        onChange={handleChange}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    {/* <Grid item container sm>
                      <TextField
                        label="Gender *"
                        variant="outlined"
                        color="primary"
                        name="gender"
                        select
                        value={emailDetails.gender}
                        fullWidth
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>Male</MenuItem>
                        <MenuItem value={2}>Female</MenuItem>
                        <MenuItem value={3}>Other</MenuItem>
                      </TextField>
                    </Grid> */}
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item container sm>
                      <TextField
                        label="Subjects Handled*"
                        variant="outlined"
                        color="primary"
                        name="subjectHandled"
                        value={emailDetails.subjectHandled}
                        fullWidth
                        onChange={handleChange}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    <Grid item container sm>
                      <TextField
                        label="Preferred Subjects*"
                        variant="outlined"
                        color="primary"
                        name="preferredSubject"
                        value={emailDetails.preferredSubject}
                        fullWidth
                        onChange={handleChange}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    <Grid item container sm>
                      <TextField
                        label="Qualification*"
                        variant="outlined"
                        color="primary"
                        name="qualification"
                        value={emailDetails.qualification}
                        fullWidth
                        onChange={handleChange}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item container sm>
                      <TextField
                        label="Primary Contact No.*"
                        variant="outlined"
                        color="primary"
                        name="primaryContactNo"
                        value={emailDetails.primaryContactNo}
                        error={
                          emailDetails.primaryContactNo.trim() === ""
                            ? false
                            : validatePhone(emailDetails.primaryContactNo) ===
                              false
                            ? true
                            : false
                        }
                        helperText={
                          emailDetails.primaryContactNo.trim() === ""
                            ? undefined
                            : validatePhone(emailDetails.primaryContactNo) ===
                              false
                            ? "Please enter a valid phone no"
                            : undefined
                        }
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item container sm>
                      <TextField
                        label="Secondary Contact No."
                        variant="outlined"
                        color="primary"
                        name="secondaryContactNo"
                        value={emailDetails.secondaryContactNo}
                        error={
                          emailDetails.secondaryContactNo.trim() === ""
                            ? false
                            : validatePhone(emailDetails.secondaryContactNo) ===
                              false
                            ? true
                            : false
                        }
                        helperText={
                          emailDetails.secondaryContactNo.trim() === ""
                            ? undefined
                            : validatePhone(emailDetails.secondaryContactNo) ===
                              false
                            ? "Please enter a valid phone no"
                            : undefined
                        }
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item container sm>
                      <TextField
                        label="Email Id *"
                        variant="outlined"
                        color="primary"
                        name="emailId"
                        value={emailDetails.emailId}
                        error={
                          emailDetails.emailId.trim() === ""
                            ? false
                            : validateEmail(emailDetails.emailId) === false
                            ? true
                            : false
                        }
                        helperText={
                          emailDetails.emailId.trim() === ""
                            ? undefined
                            : validateEmail(emailDetails.emailId) === false
                            ? "Please enter a valid Email id"
                            : undefined
                        }
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={3}>
                    <Grid item container sm>
                      <TextField
                        label="Address *"
                        variant="outlined"
                        color="primary"
                        name="primaryAddress"
                        value={emailDetails.primaryAddress}
                        // error={
                        //   emailDetails.emalId.trim() === ""
                        //     ? false
                        //     : validateEmail(emailDetails.emalId) === false
                        //     ? true
                        //     : false
                        // }
                        // helperText={
                        //   emailDetails.emalId.trim() === ""
                        //     ? undefined
                        //     : validateEmail(emailDetails.emalId) === false
                        //     ? "Please enter a valid Email id"
                        //     : undefined
                        // }
                        fullWidth
                        onChange={handleChange}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item container sm>
                      <TextField
                        label="Boards Handled*"
                        variant="outlined"
                        color="primary"
                        name="boardsHandled"
                        value={emailDetails.boardsHandled}
                        fullWidth
                        onChange={handleChange}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    <Grid item container sm>
                      <FormControlLabel
                        value="start"
                        control={
                          <Checkbox
                            color="primary"
                            checked={emailDetails.currentlyWorking}
                            name="currentlyWorking"
                            onChange={handleCurrentWorking}
                          />
                        }
                        label="Currently Working"
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item container sm>
                      <TextField
                        label="Current Company*"
                        variant="outlined"
                        color="primary"
                        name="jobTitle"
                        disabled={!emailDetails.currentlyWorking}
                        value={emailDetails.jobTitle}
                        fullWidth
                        onChange={handleChange}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item container sm>
                      <TextField
                        label="Classes Handled*"
                        variant="outlined"
                        color="primary"
                        name="classesHandled"
                        value={emailDetails.classesHandled}
                        fullWidth
                        onChange={handleChange}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    <Grid item container sm>
                      <TextField
                        label="Teaching Experience(in yrs) *"
                        variant="outlined"
                        color="primary"
                        name="teachingExp"
                        value={emailDetails.teachingExp}
                        fullWidth
                        type="number"
                        onKeyDown={(e) =>
                          (e.keyCode === 69 ||
                            e.keyCode === 190 ||
                            e.keyCode === 189) &&
                          e.preventDefault()
                        }
                        onChange={(e) => {
                          setEmailDetails({
                            ...emailDetails,
                            teachingExp: Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 2),
                          });
                        }}
                        inputProps={{ maxLength: 20 }}
                      />
                    </Grid>
                    <Grid item container sm>
                      <FormControlLabel
                        value="start"
                        control={
                          <Checkbox
                            color="primary"
                            checked={emailDetails.worksInMsOffice}
                            name="worksInMsOffice"
                            onChange={handleCurrentWorking}
                          />
                        }
                        label="Comfortable working in MS-office?"
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item container direction="column" sm>
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "black", fontSize: "1em" }}
                        >
                          Upload Resume *
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          onClick={handleDropZone}
                          style={{
                            backgroundColor: "#F4CE2C",
                            color: "white",
                            textTransform: "none",
                          }}
                        >
                          Select File
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "blue", fontWeight: "bold" }}
                        >
                          {emailDetails.resumeFileName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container direction="column" sm>
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "black", fontSize: "1em" }}
                        >
                          Upload MCQs for initial screening *
                          <InfoIcon
                            style={{ fontSize: "medium", cursor: "pointer" }}
                            onClick={() => setOpenMCQ(true)}
                          />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          onClick={handleDropZoneMCQ}
                          style={{
                            backgroundColor: "#F4CE2C",
                            color: "white",
                            textTransform: "none",
                          }}
                        >
                          Select File
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "blue", fontWeight: "bold" }}
                        >
                          {emailDetails.mcqUploadName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container direction="column" sm></Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={3}
                    // style={{ marginBottom: "2em" }}
                  >
                    <Grid item container direction="column" sm>
                      <FormControlLabel
                        value={testChecked}
                        control={
                          <Checkbox color="primary" checked={testChecked} />
                        }
                        onClick={() => setTestChecked(!testChecked)}
                        label={
                          <Typography variant="body1">
                            I have read and agreed to the{" "}
                            <span
                              onClick={() => setOpen(true)}
                              style={{ color: "blue", cursor: "pointer" }}
                            >
                              Terms & Conditions.
                            </span>
                          </Typography>
                        }
                        labelPlacement="end"
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor:
                            testChecked === true
                              ? "#001A4E"
                              : "rgb(0 26 78 / 33%)",
                          marginLeft: "auto",
                          marginRight: "auto",
                          color: "white",
                          width: "20em",
                        }}
                        disabled={!testChecked}
                        onClick={handleUploadSubmit}
                      >
                        {loader === true ? (
                          <CircularProgress style={{ color: "white" }} />
                        ) : (
                          "Submit Application"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item container direction="column">
                    {sendSuccess === true ? (
                      <Typography
                        variant="subtitle"
                        align="center"
                        style={{
                          color: "red",
                          marginBottom: "0.5em",
                        }}
                        paragraph
                      >
                        Your response has been sent to lead teacher team for
                        evaluation. We will get in touch with you shortly. For
                        any queries please contact at
                        <span style={{ color: "#001A4E" }}>
                          {" "}
                          trunkie@projectheen.com
                        </span>
                      </Typography>
                    ) : undefined}
                  </Grid>
                </Grid>
              </div>
              <Grid item>
                <Typography
                  variant="h4"
                  style={{ color: "#3E3E3E", marginLeft: "3em" }}
                >
                  Rewards
                </Typography>

                <List dense={true} style={{ marginLeft: "3.5em" }}>
                  {/* {list3.map((item, index) => ( */}
                    <ListItem>
                      {/* <ListItemIcon>
                        <FiberManualRecordIcon
                          style={{ fontSize: "10px", color: "#001A4E" }}
                        />
                      </ListItemIcon> */}
                      <ListItemText disableTypography>
                        <Typography
                          variant="body1"
                          style={{ color: "#3E3E3E" }}
                          gutterBottom
                        >
                         <Link href="http://www.orimi.com/pdf-test.pdf" target="_blank" style={{color: 'blue'}}>
                          Click to know more about the Rewards
                         </Link>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  {/* ))} */}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid
          item
          container
          direction="column"
          className={classes.footer}
          style={{ paddingTop: "1em" }}
        >
          <Grid
            item
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            style={{ paddingTop: "1em" }}
          >
            <Grid
              item
              container
              direction="column"
              sm
              style={{ marginLeft: "1.5em" }}
            >
              <Grid item>
                <img
                  src={trunkienew}
                  alt="trunkie logo 2"
                  style={{ width: "7.5em" }}
                />
              </Grid>
              <Grid item style={{ width: "30em" }}>
                <Typography
                  variant="body1"
                  style={{ color: "#3E3E3E" }}
                ></Typography>
              </Grid>
              <Grid item container style={{ width: "30em" }} spacing={2}>
                <Grid item>
                  <img
                    src={gbutton}
                    alt="gbutton"
                    component={Link}
                    style={{ width: "11em", cursor: "pointer" }}
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.projectheen.trunkie"
                  />
                </Grid>
                <Grid item>
                  <img
                    src={ibutton}
                    alt="ibutton"
                    component={Link}
                    style={{ width: "11em", cursor: "pointer" }}
                    target="_blank"
                    href="https://apps.apple.com/in/app/trunkie/id1518987239"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Disclaimer Policy
                </Typography>
              </Grid>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Trunkie
                </Typography>
              </Grid>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Terms Of Service
                </Typography>
              </Grid>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Legal
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Cookie Policy
                </Typography>
              </Grid>
              <Grid
                item
                onClick={() => history.push("/PrivacyPolicy")}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Privacy Policy
                </Typography>
              </Grid>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Site Map
                </Typography>
              </Grid>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Terms & Conditions
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm>
              <Grid item>
                <Typography
                  variant="body1"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  Contact Us
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Phone : +9791117461/ 9176517917
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Email : trunkie@projectheen.com / kailash@projectheen.com
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paperWidthSm: classes.dialogwidth }}
        className={classes.dialogContainer}
      >
        <DialogTitle>
          <Typography variant="h3" style={{ color: "black" }} align="center">
            {" "}
            Terms & Condition
          </Typography>{" "}
        </DialogTitle>
        <DialogContent
          style={{
            scrollbarWidth: "none",
          }}
        >
          <List dense={true} style={{ maxWidth: "68em" }}>
            {list2.map((item, index) => (
              <ListItem key={item + index}>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    style={{ fontSize: "10px", color: "#001A4E" }}
                  />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography
                    variant="body1"
                    style={{ color: "#3E3E3E" }}
                    gutterBottom
                  >
                    {item}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>

          <Grid container direction="column" align="center">
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#001A4E",
                marginLeft: "auto",
                marginRight: "auto",
                color: "white",
                width: "10em",
                textTransform: "none",
              }}
              onClick={handleIAgree}
            >
              {loader === true ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                "I, Agree"
              )}
            </Button>
            <br></br>
            <Button
              variant="text"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                color: "rgb(0, 26, 78)",
                width: "10em",
                textTransform: "none",
              }}
              onClick={() => {
                setOpen(false);
                setTestChecked(false);
              }}
            >
              I, Don’t Agree
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openMCQ}
        onClose={() => setOpenMCQ(false)}
        classes={{ paperWidthSm: classes.dialogwidth }}
        className={classes.dialogContainer}
      >
        <DialogTitle>
          <Typography variant="h3" style={{ color: "black" }} align="center">
            {" "}
            Initial Screening
          </Typography>{" "}
        </DialogTitle>
        <DialogContent
          style={{
            scrollbarWidth: "none",
          }}
        >
          <List dense={true} style={{ maxWidth: "68em" }}>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "10px", color: "#001A4E" }}
                />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography
                  variant="body1"
                  style={{ color: "#3E3E3E" }}
                  gutterBottom
                >
                  One MCQ from each chapter from 11th STD CBSE board for your
                  subject. MCQs should be tricky and at high difficulty level,
                  each MCQ should have 4 options and explanation for correct
                  answer to be provided.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "10px", color: "#001A4E" }}
                />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography
                  variant="body1"
                  style={{ color: "#3E3E3E" }}
                  gutterBottom
                >
                  MCQ contributed should be of your own and we will do a
                  plagiarism check for the same.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "10px", color: "#001A4E" }}
                />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography
                  variant="body1"
                  style={{ color: "#3E3E3E" }}
                  gutterBottom
                >
                  You can upload MCQs in PDF format.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          <Grid container direction="column" align="center">
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#001A4E",
                marginLeft: "auto",
                marginRight: "auto",
                color: "white",
                width: "10em",
                textTransform: "none",
              }}
              onClick={() => setOpenMCQ(false)}
            >
              Ok
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={25000000}
        dialogProps={{ className: classes.myDropZone }}
        acceptedFiles={[
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/docx",
          "text/plain",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ]}
        open={dropZone}
        onClose={handleDropZoneClose}
        onSave={(files) => handleSaveMaterial(files, "RESUME")}
        showPreviews={true}
        showFileNamesInPreview={true}
        filesLimit={1}
      />

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={25000000}
        dialogProps={{ className: classes.myDropZone }}
        acceptedFiles={["application/pdf"]}
        open={dropZoneMCQ}
        onClose={handleDropZoneMCQClose}
        onSave={(files) => handleSaveMaterial(files, "MCQ")}
        showPreviews={true}
        showFileNamesInPreview={true}
        filesLimit={1}
      />

      <Backdrop className={classes.backdrop} open={backLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}
