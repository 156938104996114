import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
} from "@material-ui/core";
import TabMaker from "./TabMaker";
import TableMaker from "./TableMaker";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { groupBy } from "lodash";
import { url } from "./store.js";
import { set } from "date-fns";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
  myDropZone: {
    "& .MuiDialogContent-root": {
      scrollbarWidth: "none",
    },
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function AttendanceTeacher() {
  const classes = useStyle();
  const [connectZone, setConnectZone] = useState([]);
  const [materialAdded, setMaterialAdded] = useState(false);
  const [loader, setLoader] = useState(true);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [dropZone, setDropZone] = useState(false);
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [chapter, setChapter] = useState([]);
  const [section, setSection] = useState([]);
  const [chapterSelected, setChapterSelected] = useState({});
  const [sectionSelected, setSectionSelected] = useState({});
  const [myAttachment, setMyAttachment] = useState({
    attachment: "",
    fileName: "",
    fileType: "",
  });

  const headers = [
    "Student",
    "Roll No",
    "Total No Of Days",
    "Total Attendance",
    "Action",
  ];

  const handleOpenUploadDialog = async () => {
    setChapterSelected({});

    setUploadDialog(true);
  };

  const handleDropZone = () => {
    setDropZone(true);
  };

  const handleSaveMaterial = async (files) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".");
    let myFileType = files[0].type.split("/");

    setMyAttachment({
      ...myAttachment,
      attachment: newattachment,
      fileName: myFileName,
      fileType: myFileType,
    });
    setMaterialName(myFileName);
    setDropZone(false);
  };

  const handleChapterSelect = async (e) => {
    setChapterSelected(e.target.value);

    console.log(section);
  };

  const handleDropZoneClose = () => {
    setDropZone(false);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
  };

  const handleUploadSubmit = () => {
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_ADD_RESOURCE",
              busiParams: {
                attachment: myAttachment.attachment,
                branchId: window.localStorage.branchId,
                chapterId: chapterSelected.chapterId,
                chapterSectionId: sectionSelected.chapterSectionId,
                description: sectionSelected.sectionName,
                dueDate: "",
                fileName: myAttachment.fileName[0],
                fileType: myAttachment.fileType[1],
                loginUserId: window.localStorage.userId,
                name: chapterSelected.chapterName,
                resourceType: 31,
                secSubSylMappingId: window.localStorage.secSylMappingId,
                sectionId: window.localStorage.sectionId,
                subjectId: chapterSelected.subjectId,
                target: "internal",
                url: "string",
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);
          setMaterialAdded(!materialAdded);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setUploadDialog(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    setLoader(true);

    let row = [];
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_ATTENDANCE_DETAIL_BY_SECTION_ID",
              busiParams: {
                sectionId:
                  window.localStorage.teacherType == "Class"
                    ? window.localStorage.sectionId
                    : undefined,
                secSubMappingId:
                  window.localStorage.teacherType == "Class"
                    ? undefined
                    : window.localStorage.secSylMappingId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
                //  dataset.labels.push(item.resourceName);
                //  dataset.datasets[0].data.push(
                //parseInt(item.taskPerc.replace("%", ""))
                //    );
                row.push([
                  { title: item.studentName, type: "text" },
                  { title: item.rollNo, type: "text" },
                  { title: item.totalNoOfDays, type: "text" },
                  {
                    title: item.totalAttendance,
                    type: "text",
                  },
                  {
                    title: "",
                    type: "Attendance",
                    fullDetails: item,
                  },
                ]);
              })
            : row.push([
                {
                  title: "No Data Available",
                  type: "text",
                },
                { title: "", type: "text" },
                { title: "", type: "text" },
                {
                  title: " ",
                  type: "text",
                },
                {
                  title: " ",
                  type: "text",
                },
              ]);
        })

        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setConnectZone(row);
    setInterval(() => {
      setLoader(false);
    }, 3000);
  }, [materialAdded]);

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const DATA = await fetch(url, {
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //         method: "POST",
  //         body: JSON.stringify(bodyObjTextBookLinks),
  //       });
  //       let tempEvents = [];
  //       const RESPONSE = await DATA.json();
  //       console.log(RESPONSE);
  //       RESPONSE.respData[0].respMsg.result.map((item, index) => {
  //         tempEvents.push(item);
  //       });

  //       const AllLists = [];

  //       subjects.map((data, myindex) => {
  //         tempEvents.map((item, index, arr) => {
  //           if (item.secSubjectMappingId === data.secSubSylMappingId) {
  //             arr[index] = { ...arr[index], subjectMapPosition: myindex };
  //           }
  //         });
  //       });

  //       let grouped = groupBy(tempEvents, (item) => item.subjectMapPosition);

  //       console.log(grouped);
  //       for (const x in grouped) {
  //         let mydata = grouped[x];
  //         let row = [];
  //         console.log(`this is grouuped data of subject ${x}`);
  //         mydata.map((item, index) => {
  //           console.log(item);
  //           row.push([
  //             { title: item.name, type: "text" },
  //             { title: item.description, type: "text" },
  //             { title: item.dueDate, type: "text" },
  //             {
  //               title: item.fileName,
  //               type: item.fileType,
  //               href: item.attachment,
  //             },
  //           ]);
  //         });

  //         let temList = (
  //           <React.Fragment key={x}>
  //             <TableMaker columns={headers} rows={row} />
  //           </React.Fragment>
  //         );

  //         AllLists.push(temList);
  //       }
  //       console.log(AllLists);
  //       setConnectZone(AllLists);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getData();
  // }, []);

  const theme = useTheme();

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TableMaker columns={headers} rows={connectZone} />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>

      <Dialog
        open={uploadDialog}
        onClose={handleCloseUploadDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseUploadDialog}
        >
          Upload Material
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                disabled
                variant="outlined"
                fullWidth
                label="Subject Name"
                value={
                  JSON.parse(window.localStorage.users)
                    ? JSON.parse(window.localStorage.users)[0].subjectName
                    : null
                }
              />
            </Grid>
            <Grid item>
              <TextField
                select
                variant="outlined"
                fullWidth
                label="Chapter Name"
                onChange={handleChapterSelect}
              >
                {chapter &&
                  chapter.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item.chapterName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                select
                disabled={section.length > 0 ? false : true}
                variant="outlined"
                fullWidth
                label="Section Name"
                onChange={(e) => setSectionSelected(e.target.value)}
              >
                {section &&
                  section.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item.sectionName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            {
              // <Grid item>
              // <KeyboardDatePicker
              //       variant="inline"
              //       autoOk={true}
              //       name="date"
              //       inputVariant="outlined"
              //       label="Date"
              //       fullWidth
              //       placeholder="Select Notification Date"
              //       format="DD/MM/yyyy"
              //       value={resource.date}
              //       InputAdornmentProps={{ position: "end" }}
              //       onChange={(date) => {
              //         setResource({
              //           ...notification,
              //           dueDate: moment(date).format("YYYY-MM-DD").toString(),
              //         });
              //       }}
              //     />
              // </Grid>
            }
            <Grid item container spacing={2}>
              <Grid item onClick={handleDropZone}>
                <Typography
                  variant="h6"
                  color="secondary"
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                >
                  Upload
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  style={{ color: "blue" }}
                >
                  {materialName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCloseUploadDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            disabled={
              myAttachment.attachment === "" || myAttachment.attachment === " "
            }
            onClick={handleUploadSubmit}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        dialogProps={{ className: classes.myDropZone }}
        submitButtonText={"Submit"}
        maxFileSize={6000000}
        open={dropZone}
        onClose={handleDropZoneClose}
        onSave={(files) => handleSaveMaterial(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
