import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Grid,
  Divider,
  Paper,
  TextField,
  Dialog,
  DialogContent,
  Link,
  CircularProgress,
  Snackbar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { ThemeStaticPage } from "../Ui/Theme.js";

import Scrollspy from "react-scrollspy";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import trunkie2 from "./assets/trunkie2.svg";
import trunkieh from "./assets/trunkieh.svg";
import trunkie from "./assets/trunkie.svg";
import trunkienew from "./assets/trunkienew.png";
import pg1 from "./assets/pg1.jpg";
import projectheen from "./assets/projectheen.svg";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import is from "is_js";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import purpose from "./assets/purpose.jpg";
import analysis from "./assets/analysis.jpg";
import pillars from "./assets/pillars.jpg";
import address from "./assets/address.png";
import gbutton from "./assets/gbutton.png";
import ibutton from "./assets/ibutton.png";
import gbutton1 from "./assets/gbutton1.png";
import ibutton1 from "./assets/ibutton1.png";
import dialog from "./assets/dialog.jpg";
import mobile from "./assets/mobile.png";
import small from "./assets/small.mp4";
import Videojs from "./videoplayer.js";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { url } from "../Ui/store.js";
import MuiAlert from "@material-ui/lab/Alert";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";

const useStyles = makeStyles((theme) => ({
  root1: {
    flexGrow: 1,
  },
  menuButton1: {
    marginRight: theme.spacing(2),
  },
  title1: {
    flexGrow: 1,
  },
  AppBar: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 2,
    background: "rgba(0,0,0,0.3)",

    width: "100vw",
  },
  AppBar1: {
    position: "fixed",

    zIndex: theme.zIndex.drawer + 2,
    background: "transparent",
    marginTop: "4em",
  },
  AppBar2: {
    position: "fixed",

    zIndex: theme.zIndex.drawer + 2,
    background: "black",
    top: "0em",
  },
  pageContainer: {
    width: "100vw",
    height: "60em",
    backgroundImage: ` url(${pg1}) `,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  pageContainer2: {
    width: "100vw",
    height: "160em",
    backgroundColor: "white",
  },
  pageContainer3: {
    width: "100vw",
    height: "48em",
    backgroundColor: "white",
  },
  pageContainer4: {
    width: "100vw",
    height: "80em",
    backgroundColor: "#FAFAFA",
  },
  footer: {
    width: "100vw",
    height: "20em",
    backgroundColor: "white",
  },
  current: {
    color: "#F4CE2C",
  },
  notcurrent: {
    color: "white",
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "100em",
      height: "43em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },

  dialogwidth: {
    maxWidth: "80em",
    maxHeight: "100em",
  },
}));

function ElevationScroll(props) {
  const classes = useStyles();

  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,

    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    classes: trigger ? { root: classes.AppBar2 } : { root: classes.AppBar1 },
  });
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const list1 = [
  "Help every student to get continuous and quality education",
  "Boost students academic performance.",
  "Help students develop holistically.",
  "Improve teacher’s quality by providing them the latest tools that saves time.",
  "Have one common grading system for the whole nation",
];

export default function LandingPage() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [snack, setSnack] = useState(false);
   const [anchorEl, setAnchorEl] = React.useState(null);
  const [emailDetails, setEmailDetails] = useState({
    emalId: "",
    firstName: "",
    jobTitle: "",
    lastName: "",
    loginUserId: 1,
    message: "",
    phoneNum: "",
    schoolName: "",
  });
  const history = useHistory();
  const theme = useTheme();
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setEmailDetails({ ...emailDetails, [e.target.name]: e.target.value });
  };

  const sendMessage = async () => {
    setLoader(true);

    try {
      const DATA = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_ADD_REQ_FOR_SERVICE",
              busiParams: emailDetails,
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: "aaabbbcccdddd",
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      }).catch((data) => {
        console.log("sorry something went wrong");
        // setError(true);
      });
      const RESPONSE = await DATA.json();
      console.log(RESPONSE);
      setMessage(RESPONSE.respData[0].respMsg.message);
      if (RESPONSE.respData[0].respMsg.status === "Success") {
        setStatus("Success");
        setSnack("true");
        setEmailDetails({
          emalId: "",
          firstName: "",
          jobTitle: "",
          lastName: "",
          loginUserId: 1,
          message: "",
          phoneNum: "",
          schoolName: "",
        });
      } else {
        setStatus("error");
        setSnack("true");
      }

      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phoneNo) => {
    const re = /^[0-9]{10}$/;
    return re.test(parseInt(phoneNo));
  };

  // useEffect(() => {
  //   props.setTheme(ThemeStaticPage);
  // }, []);

  const videoJsOptions = {
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],

    controls: true,
    sources: [
      {
        src: small,
      },
    ],
  };

  return (
    <React.Fragment>
      <Grid container direction="column">
        <div className={classes.root1}>
          <AppBar classes={{ root: classes.AppBar }}>
            <Toolbar>
              <IconButton
                edge="start"
                className={classes.menuButton1}
                color="inherit"
                aria-label="menu"
              >
                <img src={projectheen} alt="projectheen logo" />
              </IconButton>
              <div className={classes.title1}></div>

              <Button
                variant="contained"
                style={{ backgroundColor: "#F4CE2C", color: "white" }}
                onClick={() => history.push("/Login")}
              >
                Login
              </Button>
              <Typography
           variant="body1"
                style={{
                    color: "white",
                  textTransform: "none",
                   marginLeft: "1em",
                }}
                onClick={handleOpenMenu}
          >
            Join Us
          </Typography>
              <IconButton aria-label="" style={{padding:"0"}} onClick={handleOpenMenu}>
                <ArrowDropDownRoundedIcon style={{color: "white",}}/>
          </IconButton>
              <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>Teacher</MenuItem>
        <MenuItem  onClick={() => history.push("/ContributeQuestions")}>Contributor</MenuItem>
        <MenuItem>Marketing Partner</MenuItem>
      </Menu>
              {/* {
                <Button
                  style={{
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={() => history.push("/ContributeQuestions")}
                >
                  Contribute Questions
                </Button>
                // <Button style={{ color: "white", textTransform: "none", display:"none" }}>
                //   Become a Partner
                // </Button>
              } */}

              <Button style={{ color: "white", textTransform: "none" }}>
                FAQs
              </Button>
            </Toolbar>
          </AppBar>
        </div>
        <ElevationScroll>
          <AppBar classes={{ root: classes.AppBar1 }}>
            <Toolbar>
              <IconButton
                edge="start"
                className={classes.menuButton1}
                color="inherit"
                aria-label="menu"
              >
                <img src={trunkie} alt="trunkie logo" />
              </IconButton>
              <div className={classes.title1}></div>
              <Scrollspy
                items={["pg1", "pg2", "pg4"]}
                currentClassName={classes.current}
                componentTag={Button}
                // scrolledPastClassName={classes.notcurrent}
              >
                <Button href="#pg1" color="secondary">
                  Vision
                </Button>
                <Button href="#pg2" color="secondary">
                  Purpose
                </Button>
                {
                  //                  <Button href="#pg3" color="secondary">
                  // Demo
                  // </Button>
                }
                <Button href="#pg4" color="secondary">
                  Contact
                </Button>
              </Scrollspy>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <div id="pg1" style={{ margin: 0, padding: 0 }}>
          <Grid
            item
            container
            direction="column"
            className={classes.pageContainer}
            style={{ borderBottom: "7px solid #F4CE2C" }}
          >
            <Grid
              item
              container
              direction="column"
              style={{ marginTop: "13em", marginLeft: "5em", width: "40em" }}
            >
              <Grid item>
                <Typography
                  variant="h3"
                  style={{ color: "white" }}
                  gutterBottom
                >
                  Vision
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "white" }}
                  gutterBottom
                >
                  Trunkie strives to revolutionise the education system by
                  guiding students to evolve in 4 key aspects of their careers
                  such as leadership, creativity, analyzing and decision making,
                  to shape themselves better for a finer livelihood. We focus on
                  bringing out the best product from our education system to
                  help them compete globally by having a common overall
                  performance score.
                </Typography>
                <Typography
                  variant="h3"
                  style={{ color: "white" }}
                  gutterBottom
                >
                  Mission
                </Typography>
                <List dense={true}>
                  {list1.map((item, index) => (
                    <ListItem key={item + index}>
                      <ListItemIcon>
                        <FiberManualRecordIcon
                          style={{ fontSize: "10px", color: "white" }}
                        />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          variant="body1"
                          style={{ color: "white" }}
                          gutterBottom
                        >
                          {item}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div id="pg2" style={{ margin: 0, padding: 0 }}>
          <Grid
            item
            container
            direction="column"
            className={classes.pageContainer2}
            style={{ paddingTop: "3em" }}
          >
            <Grid item>
              <Typography
                variant="h3"
                align="center"
                style={{ color: "black" }}
                gutterBottom
              >
                Purpose
              </Typography>
              <Divider
                variant="middle"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "12em",
                  backgroundColor: "#F4CE2C",
                  height: "0.3em",
                }}
                align="center"
              />
            </Grid>
            <Grid item container style={{ marginTop: "2em" }}>
              <Grid item style={{ position: "absolute" }}>
                <img
                  src={purpose}
                  style={{
                    width: "55em",
                    height: "40em",
                    borderBottom: "7px solid #F4CE2C",
                  }}
                  alt="purpose"
                />
              </Grid>
              <Grid
                item
                style={{
                  position: "absolute",
                  zIndex: 2,
                  left: "45em",
                  marginTop: "6em",
                  width: "40em",
                  height: "38em",
                  backgroundColor: "#2C2C2C",
                }}
              >
                <Grid item style={{ width: "38em" }}>
                  <Typography
                    variant="h3"
                    style={{
                      color: "white",
                      paddingTop: "0.5em",
                      paddingLeft: "0.2em",
                    }}
                    gutterBottom
                  >
                    Trunkie Enhances The Way
                  </Typography>

                  <Divider
                    variant="middle"
                    style={{
                      width: "12em",
                      backgroundColor: "#F4CE2C",
                      height: "0.3em",
                    }}
                  />

                  <Typography
                    variant="body1"
                    style={{
                      color: "white",
                      marginTop: "0.5em",
                      paddingLeft: "1em",
                    }}
                    paragraph
                  >
                    Trunkie enhances the way how anything is taught, captured,
                    understood, practiced, performed, analysed, rectified &
                    managed. It doesn't have an influence on what is being
                    taught.
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      color: "white",

                      paddingLeft: "1em",
                    }}
                    paragraph
                  >
                    It enhances student’s performance in exams conducted by
                    Schools/ Colleges/ Universities/ Govt. Boards by minimising
                    the gaps involved in 9 key stages of academic cycle.
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      color: "white",

                      paddingLeft: "1em",
                    }}
                    paragraph
                  >
                    This is done by closing the loop which starts from
                    collection of study materials to performance in final exams
                    via continuous feedback generation from various stake
                    holders such as students, parents, teachers and admins and
                    continuous analysis of student's performance data (exam
                    results) by Artificial Intelligence and Machine Learning.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {
              //purpose next portion
            }
            <Grid item container style={{ marginTop: "50em" }}>
              <Grid item style={{ position: "absolute", right: 0 }}>
                <img
                  src={analysis}
                  style={{
                    width: "55em",
                    height: "40em",
                    borderBottom: "7px solid #F4CE2C",
                  }}
                  alt="analysis"
                />
              </Grid>
              <Grid
                item
                style={{
                  position: "absolute",
                  zIndex: 2,
                  right: "45em",
                  marginTop: "6em",
                  width: "40em",
                  height: "38em",
                  backgroundColor: "#2C2C2C",
                }}
              >
                <Grid item style={{ width: "38em" }}>
                  <Typography
                    variant="h3"
                    style={{
                      color: "white",
                      paddingTop: "0.5em",
                      paddingLeft: "0.2em",
                    }}
                    gutterBottom
                  >
                    Detailed Data Analysis
                  </Typography>

                  <Divider
                    variant="middle"
                    style={{
                      width: "12em",
                      backgroundColor: "#F4CE2C",
                      height: "0.3em",
                    }}
                  />

                  <Typography
                    variant="body1"
                    style={{
                      color: "white",
                      marginTop: "0.5em",
                      paddingLeft: "1em",
                    }}
                    paragraph
                  >
                    Trunkie deploys detailed data analytics to get most accurate
                    results. We have combined three platforms together to ensure
                    everything is available at one place.
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      color: "white",

                      paddingLeft: "1em",
                    }}
                    paragraph
                  >
                    Our primary focus is on the holistic development of the
                    student, thus not only helping them perform better
                    academically but also help them gain important life skills
                    such as LEADERSHIP, CREATIVITY, ANALYSING & DECISION MAKING
                    along the way.
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      color: "white",

                      paddingLeft: "1em",
                    }}
                    paragraph
                  >
                    Data science plays an important role in our model of
                    approach, in which both artificial intelligence and machine
                    learning help us immensely factor how a student performs in
                    their academic lifecycle.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {
              //next section
            }

            <Grid item container style={{ marginTop: "50em" }}>
              <Grid item style={{ position: "absolute" }}>
                <img
                  src={pillars}
                  style={{
                    width: "55em",
                    height: "40em",
                    borderBottom: "7px solid #F4CE2C",
                  }}
                  alt="pillars"
                />
              </Grid>
              <Grid
                item
                style={{
                  position: "absolute",
                  zIndex: 2,
                  left: "50em",
                  marginTop: "6em",
                  width: "40em",
                  height: "38em",
                  backgroundColor: "#2C2C2C",
                }}
              >
                <Grid item style={{ width: "38em" }}>
                  <Typography
                    variant="h3"
                    style={{
                      color: "white",
                      paddingTop: "0.5em",
                      paddingLeft: "0.2em",
                    }}
                    gutterBottom
                  >
                    Performance Boosting System
                  </Typography>

                  <Divider
                    variant="middle"
                    style={{
                      width: "12em",
                      backgroundColor: "#F4CE2C",
                      height: "0.3em",
                    }}
                  />

                  <Typography
                    variant="body1"
                    style={{
                      color: "white",
                      marginTop: "0.5em",
                      paddingLeft: "1em",
                    }}
                    paragraph
                  >
                    Each aspect of our company has a mini goal to achieve,
                    through this interlinking of goals we’ve created the perfect
                    system which incorporates all the four pillars namely the
                    students, parents, teachers and administration seamlessly
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      color: "white",

                      paddingLeft: "1em",
                    }}
                    paragraph
                  >
                    Teachers are a huge contributing factor in our growth
                    project as well, their wellbeing and efficiency is taken
                    care of by our system as well.
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      color: "white",

                      paddingLeft: "1em",
                    }}
                    paragraph
                  >
                    Our vision for the future is to become a reference model in
                    the education system thereby acting as a guide to pick out
                    the best academic products from institutions and ensure
                    longevity in a students career.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div id="pg4" style={{ margin: 0, padding: 0 }}>
          {
            // <Grid
            //   item
            //   container
            //   direction="column"
            //   className={classes.pageContainer3}
            //   style={{ paddingTop: "1em" }}
            //   id="pg3"
            // >
            //   <Grid item>
            //     <Typography
            //       variant="h3"
            //       align="center"
            //       style={{ color: "black" }}
            //       gutterBottom
            //     >
            //       Demo
            //     </Typography>
            //     <Divider
            //       variant="middle"
            //       style={{
            //         marginLeft: "auto",
            //         marginRight: "auto",
            //         width: "12em",
            //         backgroundColor: "#F4CE2C",
            //         height: "0.3em",
            //       }}
            //       align="center"
            //     />
            //   </Grid>
            //   <Grid item container style={{ width: "100vw", marginTop: "5.3em" }}>
            //     <Videojs {...videoJsOptions} height={500} />
            //   </Grid>
            // </Grid>
          }

          <Grid
            item
            container
            direction="column"
            className={classes.pageContainer4}
            style={{ paddingTop: "1em" }}
          >
            <Grid item>
              <Typography
                variant="h3"
                align="center"
                style={{ color: "black" }}
                gutterBottom
              >
                Contact
              </Typography>
              <Divider
                variant="middle"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "12em",
                  backgroundColor: "#F4CE2C",
                  height: "0.3em",
                }}
                align="center"
              />
            </Grid>
            <Grid item container style={{ marginTop: "4em" }}>
              <Grid item container sm style={{ marginLeft: "10em" }}>
                <img src={address} alt="address" style={{ width: "50em" }} />
              </Grid>
              <Grid
                item
                container
                direction="column"
                sm
                style={{ marginLeft: "1em" }}
              >
                <Typography
                  variant="h5"
                  style={{ color: "#3E3E3E", fontWeight: "bold" }}
                  paragraph
                >
                  ADDRESS
                  <br />
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    color: "#3E3E3E",
                    fontWeight: "bold",
                    fontSize: "1.25rem",
                  }}
                  paragraph
                >
                  Projectheen Private Limited
                  <br />
                  No. 2/41, Resolute Towers, 2nd Lane,
                  <br />
                  MC Nicholas Rd, Chennai 600031,India
                  <br />
                  Phone No : +9791117461/ 9176517917
                </Typography>
              </Grid>
            </Grid>
            <Grid item container style={{ width: "100%", marginTop: "4em" }}>
              <Paper
                style={{
                  backgroundColor: "white",
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  paddingLeft: "1em",
                  paddingRight: "1em",
                }}
                square
              >
                <Grid container direction="column" spacing={3}>
                  <Grid item container direction="column">
                    <Typography
                      variant="h4"
                      align="center"
                      style={{
                        color: "#F4CE2C",
                        marginBottom: "1em",
                        fontSize: "2rem",
                      }}
                    >
                      Request For Service
                    </Typography>
                    {validateEmail(emailDetails.emalId) === false ||
                    emailDetails.firstName.trim() === "" ||
                    emailDetails.jobTitle.trim() === "" ||
                    emailDetails.lastName.trim() === "" ||
                    emailDetails.message.trim() === "" ||
                    validatePhone(emailDetails.phoneNum) === false ||
                    emailDetails.schoolName.trim() === "" ? (
                      <Typography
                        variant="subtitle"
                        align="center"
                        style={{
                          color: "red",
                          marginBottom: "0.5em",
                        }}
                      >
                        * Please enter all mandatory fields to send message
                      </Typography>
                    ) : undefined}
                  </Grid>

                  <Grid item container spacing={3}>
                    <Grid item container sm>
                      <TextField
                        label="First Name *"
                        variant="outlined"
                        color="primary"
                        name="firstName"
                        value={emailDetails.firstName}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item container sm>
                      <TextField
                        label="Last Name *"
                        variant="outlined"
                        color="primary"
                        name="lastName"
                        value={emailDetails.lastName}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item container sm>
                      <TextField
                        label="Email *"
                        variant="outlined"
                        color="primary"
                        name="emalId"
                        value={emailDetails.emalId}
                        error={
                          emailDetails.emalId.trim() === ""
                            ? false
                            : validateEmail(emailDetails.emalId) === false
                            ? true
                            : false
                        }
                        helperText={
                          emailDetails.emalId.trim() === ""
                            ? undefined
                            : validateEmail(emailDetails.emalId) === false
                            ? "Please enter a valid Email id"
                            : undefined
                        }
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item container sm>
                      <TextField
                        label="School *"
                        variant="outlined"
                        color="primary"
                        name="schoolName"
                        value={emailDetails.schoolName}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item container sm>
                      <TextField
                        label="Job title *"
                        variant="outlined"
                        color="primary"
                        name="jobTitle"
                        value={emailDetails.jobTitle}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item container sm>
                      <TextField
                        label="Phone *"
                        variant="outlined"
                        color="primary"
                        name="phoneNum"
                        error={
                          emailDetails.phoneNum.trim() === ""
                            ? false
                            : validatePhone(emailDetails.phoneNum) === false
                            ? true
                            : false
                        }
                        helperText={
                          emailDetails.phoneNum.trim() === ""
                            ? undefined
                            : validatePhone(emailDetails.phoneNum) === false
                            ? "Please enter a valid phone no"
                            : undefined
                        }
                        value={emailDetails.phoneNum}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item container sm>
                      <TextField
                        multiline
                        label="Message *"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="message"
                        value={emailDetails.message}
                        rows={4}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={3}
                    style={{ marginBottom: "2em" }}
                  >
                    <Grid item container sm>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: "#F4CE2C", color: "white" }}
                        fullWidth
                        onClick={sendMessage}
                        disabled={
                          validateEmail(emailDetails.emalId) === false ||
                          emailDetails.firstName.trim() === "" ||
                          emailDetails.jobTitle.trim() === "" ||
                          emailDetails.lastName.trim() === "" ||
                          emailDetails.message.trim() === "" ||
                          validatePhone(emailDetails.phoneNum) === false ||
                          emailDetails.schoolName.trim() === ""
                        }
                      >
                        {loader === true ? (
                          <CircularProgress style={{ color: "white" }} />
                        ) : (
                          "Send Message"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <Grid
          item
          container
          direction="column"
          className={classes.footer}
          style={{ paddingTop: "1em" }}
        >
          <Grid
            item
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            style={{ paddingTop: "1em" }}
          >
            <Grid
              item
              container
              direction="column"
              sm
              style={{ marginLeft: "1.5em" }}
            >
              <Grid item>
                <img
                  src={trunkienew}
                  alt="trunkie logo 2"
                  style={{ width: "7.5em" }}
                />
              </Grid>
              <Grid item style={{ width: "30em" }}>
                <Typography
                  variant="body1"
                  style={{ color: "#3E3E3E" }}
                ></Typography>
              </Grid>
              <Grid item container style={{ width: "30em" }} spacing={2}>
                <Grid item>
                  <img
                    src={gbutton}
                    alt="gbutton"
                    component={Link}
                    style={{ width: "11em", cursor: "pointer" }}
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.projectheen.trunkie"
                  />
                </Grid>
                <Grid item>
                  <img
                    src={ibutton}
                    alt="ibutton"
                    component={Link}
                    style={{ width: "11em", cursor: "pointer" }}
                    target="_blank"
                    href="https://apps.apple.com/in/app/trunkie/id1518987239"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Disclaimer Policy
                </Typography>
              </Grid>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Trunkie
                </Typography>
              </Grid>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Terms Of Service
                </Typography>
              </Grid>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Legal
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Cookie Policy
                </Typography>
              </Grid>
              <Grid
                item
                component={Link}
                to="/PrivacyPolicy"
                style={{ textDecoration: "none" }}
              >
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Privacy Policy
                </Typography>
              </Grid>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Site Map
                </Typography>
              </Grid>
              <Grid item component={Link} style={{ textDecoration: "none" }}>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Terms & Conditions
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm>
              <Grid item>
                <Typography
                  variant="body1"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  Contact Us
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Phone : +9791117461/ 9176517917
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body1" style={{ color: "#3E3E3E" }}>
                  Email : trunkie@projectheen.com / kailash@projectheen.com
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paperWidthSm: classes.dialogwidth }}
        className={classes.dialogContainer}
      >
        <DialogContent
          style={{
            backgroundColor: "rgba(0,0,0,0.4)",
            backgroundImage: ` url(${dialog}) ,linear-gradient(172deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 91%)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            overflow: "hidden",
          }}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={6}
            style={{ marginTop: "0.1em" }}
          >
            <Grid item container sm={3}>
              <img src={mobile} alt="mobile" />
            </Grid>
            <Grid
              item
              container
              direction="column"
              sm={9}
              style={{ marginLeft: "0.5em" }}
              spacing={3}
            >
              <Grid item>
                <Typography variant="h3" style={{ color: "white" }}>
                  Join Us Today For A{" "}
                  <span style={{ color: "#F4CE2C" }}>Brighter</span> Tommorow
                </Typography>
              </Grid>

              <Grid item>
                <img
                  src={trunkieh}
                  alt="trunkieh"
                  style={{ marginTop: "1em" }}
                />
              </Grid>

              <Grid item>
                <Typography variant="h3" style={{ color: "white" }}>
                  Download The Trunkie App Now
                </Typography>
              </Grid>

              <Grid item container spacing={2}>
                <Grid
                  item
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  component={Link}
                  href="https://play.google.com/store/apps/details?id=com.projectheen.trunkie"
                >
                  <img src={gbutton1} alt="gbutton1" />
                </Grid>
                <Grid
                  item
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  component={Link}
                  href="https://apps.apple.com/in/app/trunkie/id1518987239"
                >
                  <img src={ibutton1} alt="ibutton1" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
