import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  Grid,
  Typography,
  Button,
  Radio,
  FormControlLabel,
  useControlled,
} from "@material-ui/core";
import { ThemeDay, ThemeEnergy, ThemeNight, ThemStaticPage } from "./Theme.js";
import automatic from "../assets/automatic-car/automatic-car.png";
import day from "../assets/daynight/day-theme.png";
import night from "../assets/daynight/nite-theme.png";
import energy from "../assets/energy2/energy2.png";
import { url } from "./store";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    width: "200em",
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "100em",
      height: "30em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },

  dialogwidth: {
    maxWidth: "80em",
    maxHeight: "100em",
  },
}));

export default function ThemeController(props) {
  const classes = useStyle();
  const [selectedValue, setSelectedValue] = React.useState(
    window.localStorage.theme
  );
  // const [initialselectedValue, setSelectedValue] = React.useState("Auto Mode");
  // const theme = useTheme();

  const changeTheme = async () => {
    console.log("Hii");
    if (selectedValue === "Day") {
      props.setTheme(ThemeDay);
      window.localStorage.theme = "Day";
      window.location.reload();
    } else if (selectedValue === "Night") {
      props.setTheme(ThemeNight);
      window.localStorage.theme = "Night";
      window.location.reload();
    } else if (selectedValue === "Energy") {
      props.setTheme(ThemeEnergy);
      window.localStorage.theme = "Energy";
      window.location.reload();
    } else {
      window.localStorage.theme = "Auto";
      if (
        moment().unix() > window.localStorage.startTimeForTheme &&
        moment().unix() < window.localStorage.endTimeForTheme
      ) {
        //console.log("Auto setting selected setting day them");
        props.setTheme(ThemeDay);
        window.location.reload();
      } else {
        //console.log("Auto setting selected setting night them");
        props.setTheme(ThemeNight);
        window.location.reload();
      }
    }
    props.handleClose();

    const bodyObjforTheme = {
      requestList: [
        {
          isEncrypt: false,
          transactionId: "0000001438241244",

          busiCode: "UAM_UPDATE_THEME",
          busiParams: {
            themeCode:
              selectedValue === "Day"
                ? "Day"
                : selectedValue === "Night"
                ? "Night"
                : selectedValue === "Energy"
                ? "Energy"
                : "Auto",
            updatedBy:
              window.localStorage.roleId == "5"
                ? window.localStorage.parentUserId
                : window.localStorage.userId,
            userId:
              window.localStorage.roleId == "5"
                ? window.localStorage.parentUserId
                : window.localStorage.userId,
          },
        },
      ],
      pubInfo: {
        appId: "appId",
        sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
        version: "21",
        timestamp: "20180314175419",
        lang: "en_US",
        userId: "1000016614",
        serviceId: "7021150585",
        circleId: "MU",
        ncsroute: "WE015",
        customerId: "1000016614",
        osType: "windows",
      },
    };

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(bodyObjforTheme),
    })
      .then((response) => {
        response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((data) => {
        console.error(data);
        // setError(true);
      });
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCancel = () => {
    setSelectedValue(window.localStorage.theme);
    props.handleClose();
  };
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        classes={{ paperWidthSm: classes.dialogwidth }}
        className={classes.dialogContainer}
      >
        <Grid
          container
          direction="columns"
          justify="center"
          alignItems="center"
          spacing={6}
          style={{ marginTop: "0.1em" }}
        >
          <Grid item>
            {" "}
            <Typography variant="h4" style={{ color: "black" }}>
              Select A Theme
            </Typography>{" "}
          </Grid>

          <Grid container justify="center" spacing={4}>
            <Grid item>
              <Grid container direction="column">
                <img alt="" src={automatic}></img>
                <Grid container direction="row" justify="center">
                  <FormControlLabel
                    value="Auto"
                    checked={selectedValue === "Auto"}
                    control={<Radio color="secondary" />}
                    label="Auto Mode"
                    labelPlacement="End"
                    onChange={handleRadioChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <img alt="" src={energy}></img>
                <Grid container direction="row" justify="center">
                  <FormControlLabel
                    value="Energy"
                    checked={selectedValue === "Energy"}
                    control={<Radio color="secondary" />}
                    label="Energy Mode"
                    labelPlacement="End"
                    onChange={handleRadioChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <img alt="" src={day}></img>
                <Grid container direction="row" justify="center">
                  <FormControlLabel
                    value="Day"
                    checked={selectedValue === "Day"}
                    control={<Radio color="secondary" />}
                    label="Day Mode"
                    labelPlacement="End"
                    onChange={handleRadioChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <img alt="" src={night}></img>
                <Grid container direction="row" justify="center">
                  <FormControlLabel
                    value="Night"
                    checked={selectedValue === "Night"}
                    control={<Radio color="secondary" />}
                    label="Night Mode"
                    labelPlacement="End"
                    onChange={handleRadioChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justify="center"
            spacing={4}
            style={{ marginTop: "1em" }}
          >
            <Grid item>
              <Button color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ width: "13em", color: "white" }}
                onClick={changeTheme}
              >
                Apply Theme
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
