import React, { useState, useEffect } from "react";
import {
  Grid, Typography, Paper, Backdrop, CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { url, makeReqBody } from "../Ui/store.js";
import ChapterDetailedChart from "./ChapterDetailedChart";
import CircleChart from "./CircleChart";
import AnalysisCard from "./AnalysisCard";
import ChapterHorizontalChart from "./ChapterHorizontalChart";
import ChartMaker from "../Ui/ChartMaker.js";
import Chart from 'react-apexcharts'
import SmartAnalysisCard from "./SmartAnalysisCard.js";
import { Line } from "react-chartjs-2";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  myCard: {
    transition: "250ms",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  circleCbx: {
    width: '140px',
    height: '140px',
    margin: '10px auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
  performHeading: {
    color: theme.typography.myTheme.themeName === "Night"
      ? theme.palette.common.tabFont
      : "#000",
    fontSize: '1.3rem',
    fontWeight: '700',
  },
  chapterDetailWrapper: {
    // background: '#fff',
    margin: '16px 0 32px',
    // borderRadius: '7px',
    // padding: '15px 0',
    // boxShadow: '0px 0px 9px #00000069',
    width: '100%',
    justifyContent: 'space-between'
  },
  chapterDetailWrapperInner: {
    background: '#fff',
    padding: '15px 0',
    boxShadow: '0px 0px 9px #00000069',
    borderRadius: '7px',
    maxWidth: '49%',
    flexBasis: '49%',
  }

}));


export default function ChapterSectionDetailed(props) {
  const classes = useStyle();
  const [detailMarks, setDetailMarks] = useState(null);
  const [identifierMarks, setIdentifierMarks] = useState([]);
  const [sectionBarLabel, setSectionBarLabel] = useState([]);
  const [sectionBarData, setSectionBarData] = useState([]);
  const [chapterName, setChapterName] = useState("");
  const [paasingStatus, setPaasingStatus] = useState([]);
  const [backLoader, setBackLoader] = useState(false);
  const [chapterHorizontalBarMarks, setChapterHorizontalBarMarks] = useState([]);
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      labels: ["QUICK ATTEMPTS", "ON-TIME-ATTEMPTS", "DELAYED ATTEMPTS"]
    },
  });
  const [dataSet, setDataSet] = useState({
    labels: ["Correct", "Partial Correct", "Wrong", "Unattempt"],
    datasets: [
      {
        backgroundColor: [
          'rgb(1, 190, 46)',
          'rgb(251, 170, 43)',
          'rgb(202, 25, 35)',
          'rgb(0, 0, 0)',
        ],
        borderWidth: 1,
        borderColor: [
          'rgb(1, 190, 46)',
          'rgb(251, 170, 43)',
          'rgb(237 27 48)',
          'rgb(0, 0, 0)',
        ],
        barThickness: "flex",
        barPercentage: 0.5,
        categoryPercentage: 0.3,
        borderSkipped: "left",
        data: []
      },
    ],
  });
  const [typologyChart, setTypologyChart] = useState({});
  const [examType, setExamType] = useState(0)


  useEffect(() => {
    let classPerfBusi = "";
    if (window.localStorage.roleId === "3") {
      if (props.studentInstanceId === 0) {
        classPerfBusi = "SCHOOL_SMART_QBT_CLASS_PERFO_CHAPTER_IDENTIFIER_WISE_DETAILS"
      } else {
        classPerfBusi = "SCHOOL_SMART_QBT_CHAPTER_IDENTIFIER_WISE_DETAILS"
      }
    } else {
      if (window.localStorage.studentInstanceId === 0) {
        classPerfBusi = "SCHOOL_SMART_QBT_CLASS_PERFO_CHAPTER_IDENTIFIER_WISE_DETAILS"
      } else {
        classPerfBusi = "SCHOOL_SMART_QBT_CHAPTER_IDENTIFIER_WISE_DETAILS"
      }
    }
    try {
      setBackLoader(true);
      fetch(
        url,
        makeReqBody(window.localStorage.boardId > 6 ? classPerfBusi : "SCHOOL_GET_CHAPTER_LEVEL_PERC", window.localStorage.boardId <= 6 ? {
          chapterId: props.cId,
          examtermId: props.examTermId,
          sectionId: window.localStorage.sectionId,
          studentInstanceId: window.localStorage.roleId === "3" ? props.studentInstanceId : window.localStorage.studentInstanceId,
          subjectId: props.subjectId,
        } : {
          chapterId: props.cId,
          examTermId: props.examTermId,
          sectionId: window.localStorage.sectionId,
          studentInstanceId: window.localStorage.roleId === "3" ? props.studentInstanceId : window.localStorage.studentInstanceId,
          subjectId: props.subjectId,
          examId: props.examId,
          branchId: window.localStorage.branchId,
        })).then((response) => response.json())
        .then((data) => {
          setExamType(data.respData[0].respMsg.result.examType)
          setChapterHorizontalBarMarks(data.respData[0].respMsg.result.chapterSectionDetailMarks);
          setChapterName(data.respData[0].respMsg.result.chapterSectionDetailMarks[0].chapterName);
          setDetailMarks(data.respData[0].respMsg.result.detailMarks);
          setIdentifierMarks(data.respData[0].respMsg.result.identifierDetailedMarks);
          let labels = data.respData[0].respMsg.result.chapterSectionDetailMarks.map((item) => item.chapterSectionName)
          let section = data.respData[0].respMsg.result.chapterSectionDetailMarks.map((item) => parseFloat(item.overAllPer))
          let passStatus = data.respData[0].respMsg.result.chapterSectionDetailMarks.map((item) => item.percAbovePassingMarks)
          setSectionBarLabel(labels);
          setSectionBarData(section);
          setPaasingStatus(passStatus);

          let typData = []
          let labelsName = [];
          let bgColor = [];
          let isTypoNegative = false;

          data.respData[0].respMsg.result.typologyDetailedMarks.map(x => {
            labelsName.push(x.typologyName);
            typData.push(x.perc);
            if (parseInt(x.perc) >= 0) {
              isTypoNegative = false;
            } else {
              isTypoNegative = true;
            }
            if (x.perc > 50) {
              bgColor.push("#00a427")
            } else {
              bgColor.push("#ff0000")
            }
          })
          let typologyDataSet = {
            labels: labelsName,
            datasets: [{
              data: typData,
              backgroundColor: bgColor,
              borderColor: "#498bff",
            }],
            isNegative: isTypoNegative
          };
          setTypologyChart(typologyDataSet)


          let resdata = data.respData[0].respMsg.result;
          setChartData({
            ...chartData,
            series: [resdata.attempts.quickAttempt, resdata.attempts.onTimeAttempt, resdata.attempts.delayedAttempt],
          })
          let accuracyData = [resdata.accuracy.correctQuesNo, resdata.accuracy.partialCorrect, resdata.accuracy.wrongQuesNo, resdata.accuracy.unattemptedNo]
          let dataset = { ...dataSet };
          dataset.datasets[0].data = accuracyData
          setDataSet(dataset);

          setTimeout(() => {
            setBackLoader(false);
          }, 1000);


        })
        .catch((data) => {
          console.error(data);
          setBackLoader(false);
        });
    } catch (error) {
      console.error(error);
      setBackLoader(false);
    }
  }, [props.cId]);



  return (
    <React.Fragment>
      <Grid item container direction="row" style={{ marginTop: '8px' }} spacing={2}>
        <Grid item sm={12} style={{ paddingBottom: '6px' }}>
          <Typography variant="h5" className={classes.performHeading}>Chapter wise performance - {chapterName}</Typography>
        </Grid>
        {window.localStorage.boardId > 6 ?
          <>
            <Grid item sm>
              <SmartAnalysisCard
                marginTop="-35px"
                color="#00a427"
                showRange={true}
                width={detailMarks && detailMarks.overAllPerc}
                val={`${detailMarks && detailMarks.obtainedMarks}/${detailMarks && detailMarks.totalMarks}`}
                name="OVERALL SCORE"
              />
            </Grid>
            <Grid item sm>
              <SmartAnalysisCard
                marginTop="-35px"
                showRange={true}
                color="#fbaa2b"
                width={detailMarks && detailMarks.attempPerc}
                val={`${props.studentInstanceId === 0 ? detailMarks && detailMarks.attemptQuesByStudent : detailMarks && detailMarks.totalAttemptQues}/${detailMarks && detailMarks.totalQues}`}
                name="ATTEMPT"
              />
            </Grid>
          </> : <>
            <Grid item sm>
              <AnalysisCard
                bgColor="#fbaa2b"
                val={detailMarks && detailMarks.attempPerc}
                name="Attempt"
              />
            </Grid>

            <Grid item sm>
              <AnalysisCard
                bgColor="#498bff"
                val={detailMarks && detailMarks.accuracyPerc}
                name="Accuracy"
              />
            </Grid>

            <Grid item sm>
              <AnalysisCard
                bgColor="#00a427"
                val={detailMarks && detailMarks.overAllPerc}
                name="Overall"
              />
            </Grid>

            {props.studentInstanceId !== 0 && <>
              <Grid item sm>
                <AnalysisCard
                  bgColor="#329ac1"
                  val={`${detailMarks && detailMarks.obtainedMarks}/${detailMarks && detailMarks.totalMarks}`}
                  name="Marks Scored"
                />
              </Grid>
              <Grid item sm>
                <AnalysisCard
                  bgColor="#2ea1b3"
                  val={`${detailMarks && detailMarks.totalAttemptQues}/${detailMarks && detailMarks.totalQues}`}
                  name="Question Attempted"
                />
              </Grid>
            </>}
          </>}
      </Grid>

      {window.localStorage.boardId > 6 &&
        <>
          <Grid item container direction="row" className={classes.chapterDetailWrapper} style={{ margin: "0" }} spacing={3}>
            <Grid item sm={6} className={classes.chapterDetailWrapperInner}>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>ACCURACY</Typography>
              <Grid item style={{ marginTop: "1em", }}>
                <ChartMaker
                  height={132}
                  dataset={dataSet}
                  percentage={true}
                  legends={false}
                  showBarVal={true}
                />
              </Grid>
            </Grid>
            <Grid item sm={6} className={classes.chapterDetailWrapperInner}>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>ATTEMPTS</Typography>
              <Grid item container>
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="donut"
                  width="460"
                  style={{ margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>}

      <Grid item container direction="row" className={classes.chapterDetailWrapper} spacing={3}>
        <Grid item sm={6} className={classes.chapterDetailWrapperInner}>
          <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>{`${chapterName} - Chapter Section Wise Performance`}</Typography>
          {chapterHorizontalBarMarks && chapterHorizontalBarMarks.map((item, index) => {
            return (
              <ChapterHorizontalChart
                name={item.chapterSectionName}
                val={item.overAllPer}
                bgChart={item.percAbovePassingMarks === true ? "#01be2e" : "#ca1923"}
                bgCircleCount={item.percAbovePassingMarks === true ? "#52de6f" : "#f65760"}
                width={`${item.overAllPer}%`}
                count={index + 1}
              />
            )
          })
          }
        </Grid>

        <Grid
          item
          sm={6}
          container
          className={classes.chapterDetailWrapperInner}
        // spacing={3}
        >
          <Grid item container

          >
            <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>Chapter Question Wise Identifier</Typography>
          </Grid>

          {identifierMarks && identifierMarks.map((item, index) => {
            return (
              <Grid key={index} item sm={4}>
                <Typography component="div" style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircleChart
                    percentage={parseFloat(item.perc)}
                    textName={item.identifierName}
                  />
                </Typography>
              </Grid>
            )
          })}
        </Grid>
      </Grid>

      {examType === 3 &&
        <Grid item container direction="row" className={classes.chapterDetailWrapper} style={{ marginTop: "1em", marginBottom: "0" }} spacing={3}>
          <Grid item container className={classes.chapterDetailWrapperInner}>
            <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>TYPOLOGY</Typography>
            <Grid item container style={{ marginTop: "12px" }}>
              <ChartMaker
                height={132}
                dataset={typologyChart}
                percentage={true}
                legends={false}
                showBarVal={true}
              />
            </Grid>
          </Grid>
          {/* <Grid item sm={6} className={classes.chapterDetailWrapperInner}>
            <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>AI SUGGESTION</Typography>
          </Grid> */}
        </Grid>}

      <Backdrop className={classes.backdrop} open={backLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};


