import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  Typography,
  Backdrop,
} from "@material-ui/core";
import TabMaker from "./TabMaker";
import TableMaker from "./TableMaker";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { groupBy } from "lodash";
import { url, urlmultipart } from "./store.js";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
  dropZone: {
    color: "black !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
  myDropZone: {
    "& .MuiDialogContent-root": {
      scrollbarWidth: "none",
    },
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MyDataMaker = (props) => {
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_RESOURCE_BY_BRANCH_ID_RESOURCE_TYPE",
              busiParams: {
                admin: false,
                branchId: window.localStorage.branchId,
                chapterId: 0,
                resourceType: 32,
                secSubjectMappingId: props.secId,
                sectionId: window.localStorage.sectionId,
                subject: 0,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())

        .then((data) => {
          let row = [];
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
                //  dataset.labels.push(item.resourceName);
                //  dataset.datasets[0].data.push(
                //parseInt(item.taskPerc.replace("%", ""))
                //    );

                window.localStorage.roleId == 3
                  ? window.localStorage.teacherType == "Class"
                    ? row.push([
                        { title: item.chapterName, type: "text" },
                        { title: item.chapterSectionName, type: "text" },
                        { title: item.createdOn , type: "text" },
                        {
                          title: item.fileName,
                          type: item.fileType == "mov" ? "mp4" : item.fileType,
                          href: item.videoAlert === "Video is not Available." ? "" : item.attachment,
                          fullDetails: item
                        },
                        {title: item.teacherDescription, type: "text" }
                      ])
                    : row.push([
                        { title: item.chapterName, type: "text" },
                        { title: item.chapterSectionName, type: "text" },
                        { title: item.createdOn , type: "text" },
                        {
                          title: item.fileName,
                          type: item.fileType == "mov" ? "mp4" : item.fileType,
                          href: item.videoAlert === "Video is not Available." ? "" : item.attachment,
                          fullDetails: item
                        },
                        {title: item.teacherDescription, type: "text" },
                        { title: "", type: "Delete", fullDetails: item },
                      ])
                  : row.push([
                      { title: item.chapterName, type: "text" },
                      { title: item.chapterSectionName, type: "text" },
                      { title: item.createdOn, type: "text" },
                      {
                        title: item.fileName,
                        type: item.fileType == "mov" ? "mp4" : item.fileType,
                        href: item.videoAlert === "Video is not Available." ? "" : item.attachment,
                        fullDetails: item
                      },
                      {title: item.teacherDescription, type: "text" }
                    ]);
              })
            : row.push([
                {
                  title: "No Data Available",
                  type: "text",
                  fullDetails: "hy",
                },
                { title: "", type: "text" },
                { title: "", type: "text" },
                { title: "", type: "text" },
                {
                  title: " ",
                  type: "text",
                },
              ]);

          setRows(row);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    }
  }, []);

  return (
    <React.Fragment>
      {window.localStorage.roleId == 3 ? (
        window.localStorage.teacherType == "Subject" ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={props.handleOpenUploadDialog}
            style={{ color: "white", marginBottom: "0.5em" }}
          >
            Upload Material
          </Button>
        ) : undefined
      ) : undefined}
      <React.Fragment>
        {loader === true ? (
          <CircularProgress color="secondary" />
        ) : (
          <TableMaker
            columns={props.headers}
            rows={rows}
            materialAdded={props.materialAdded}
            setMaterialAdded={props.setMaterialAdded}
          />
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default function SubjectRecording() {
  const classes = useStyle();
  const theme = useTheme();
  const [subjectRecording, setSubjectRecording] = useState([]);
  const [loader, setLoader] = useState(true);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [dropZone, setDropZone] = useState(false);
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [materialAdded, setMaterialAdded] = useState(false);
  const [chapter, setChapter] = useState([]);
  const [section, setSection] = useState([]);
  const [chapterSelected, setChapterSelected] = useState({ chapterId: null });
  const [sectionSelected, setSectionSelected] = useState({});
  const [myAttachment, setMyAttachment] = useState({
    attachment: "",
    fileName: "",
    fileType: "",
    fileId: "",
  });
  const [myLoader, setMyLoader] = useState(false);
  const [backLoader, setBackLoader] = useState(false);
  const [teacherDescription, setTeacherDescription] = useState("");

  const headers =
    window.localStorage.roleId == 3
      ? window.localStorage.teacherType == "Class"
        ? ["Chapter", "Section", "Date", "Material", "Description"]
        : ["Chapter", "Section", "Date", "Material", "Description", "Action"]
      : ["Chapter", "Section", "Date", "Material", "Description"];

  const handleOpenUploadDialog = async () => {
    setChapterSelected({});
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_ALL_CHAPTER_SECTION",
              busiParams: {
                admin: false,
                sectionId: window.localStorage.sectionId,
                subjectId: window.localStorage.subjectId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let mychapters = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mychapters.push(item);
            });
          setChapter(mychapters);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }
    setUploadDialog(true);
  };

  const handleDropZone = () => {
    setDropZone(true);
  };

  const handleSaveMaterial = async (files) => {
    setBackLoader(true);
    var mydata = new FormData();
    mydata.append("file", files[0]);
    if(window.localStorage.boardId <= 6){
      mydata.append("isPublic", true);
    }
    const options = {
      headers: {
        Accept: "application/json",
        //"Content-Type": "multipart/form-data",
        type: "formData",
      },
      method: "POST",
      body: mydata,
    };
    delete options.headers["Content-Type"];
    await fetch(urlmultipart, options)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setMyAttachment({
          ...myAttachment,
          attachment: data.fileURL,
          fileName: data.fileName,
          fileType: data.fileType,
          fileId: data.bucketId,
        });
        setMaterialName(data.fileName);
        setDropZone(false);
        setBackLoader(false);
      })
      .catch((data) => {
        console.error(data);
        setDropZone(false);
        setBackLoader(false);
      });
    // console.log("Files:", files[0]);
    // let attachment = await toBase64(files[0]);
    // let newattachment = attachment.split("base64,")[1];
    // let myFileName = files[0].name.split(".");
    // let myFileType = files[0].type.split("/");
  };

  const handleChapterSelect = async (e) => {
    setChapterSelected(e.target.value);
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_CH_SECTION_DETAILS_BY_CID",
              busiParams: {
                admin: false,
                id: e.target.value.chapterId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let mysections = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mysections.push(item);
            });
          setSection(mysections);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    console.log(section);
  };

  const handleDropZoneClose = () => {
    setDropZone(false);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
  };

  const handleUploadSubmit = () => {
    setMyLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_ADD_RESOURCE",
              busiParams: {
                attachment: myAttachment.attachment,
                branchId: window.localStorage.branchId,
                chapterId: chapterSelected.chapterId,
                chapterSectionId: sectionSelected.chapterSectionId,
                description: "",
                dueDate: "",
                fileName: myAttachment.fileName,
                fileType: myAttachment.fileType,
                fileId: myAttachment.fileId,

                loginUserId: window.localStorage.userId,
                name: "",
                resourceType: 32,
                secSubSylMappingId: window.localStorage.secSylMappingId,
                sectionId: window.localStorage.sectionId,
                subjectId: chapterSelected.subjectId,
                target: "internal",
                url: "string",
                multipartReq: 1,
                teacherDescription: teacherDescription
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);
          setMyLoader(false);
          setMyAttachment({
            attachment: "",
            fileName: "",
            fileType: "",
          });
          setMaterialName("");
          setMaterialAdded(!materialAdded);

          setUploadDialog(false);
        })
        .catch((data) => {
          console.error(data);
          setUploadDialog(false);
        });
    } catch (error) {
      console.log(error);
      setUploadDialog(false);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    setLoader(true);
    const AllLists = [];
    let subjects = JSON.parse(window.localStorage.getItem("users") || "[]");
    console.log(subjects);
    subjects &&
      subjects.map((item, index) => {
        let temList = (
          <MyDataMaker
            key={index}
            headers={headers}
            materialAdded={materialAdded}
            setMaterialAdded={setMaterialAdded}
            handleOpenUploadDialog={handleOpenUploadDialog}
            secId={item.secSubSylMappingId}
          />
        );

        AllLists.push(temList);
      });
    setSubjectRecording(AllLists);
    setInterval(() => {
      setLoader(false);
    }, 500);
  }, [materialAdded]);

  const subjects = JSON.parse(window.localStorage.getItem("users") || "[]");
  let tabheadings = [];
  try {
    tabheadings = subjects.map((a) => a.subjectName);
  } catch (error) {
    console.error(error);
  }
  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TabMaker tabs={tabheadings} data={subjectRecording} />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
      <Dialog
        open={uploadDialog}
        onClose={handleCloseUploadDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseUploadDialog}
        >
          Upload Material
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em", scrollbarWidth: "none" }}>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ maxWidth: "40em" }}
          >
            {chapter && chapter.length <= 0 ? (
              <Grid item>
                <Typography variant="h6" color="secondary">
                  Please contact admin for chapter/section mapping
                </Typography>
              </Grid>
            ) : undefined}
            <Grid item style={{ maxWidth: "40em" }}>
              <TextField
                disabled
                variant="outlined"
                fullWidth
                label="Subject Name"
                value={
                  JSON.parse(window.localStorage.users)
                    ? JSON.parse(window.localStorage.users)[0].subjectName
                    : null
                }
              />
            </Grid>
            <Grid item style={{ maxWidth: "40em" }}>
              <TextField
                select
                variant="outlined"
                fullWidth
                label="Chapter Name"
                onChange={handleChapterSelect}
              >
                {chapter &&
                  chapter.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item.chapterName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item style={{ maxWidth: "40em" }}>
              <TextField
                select
                disabled={section.length > 0 ? false : true}
                variant="outlined"
                fullWidth
                label="Section Name"
                onChange={(e) => setSectionSelected(e.target.value)}
              >
                {section &&
                  section.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item.sectionName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item style={{ maxWidth: "40em"}}>
            <TextField
              variant="outlined"
              fullWidth
              label="Description"
              multiline
              rows={2}
              inputProps={{ maxlength: "1995"}}
              onChange={(e)=> setTeacherDescription(e.target.value)}
            />
            </Grid>
            {
              // <Grid item>
              // <KeyboardDatePicker
              //       variant="inline"
              //       autoOk={true}
              //       name="date"
              //       inputVariant="outlined"
              //       label="Date"
              //       fullWidth
              //       placeholder="Select Notification Date"
              //       format="DD/MM/yyyy"
              //       value={resource.date}
              //       InputAdornmentProps={{ position: "end" }}
              //       onChange={(date) => {
              //         setResource({
              //           ...notification,
              //           dueDate: moment(date).format("YYYY-MM-DD").toString(),
              //         });
              //       }}
              //     />
              // </Grid>
            }
            <Grid item container spacing={2}>
              <Grid item onClick={handleDropZone}>
                <Typography
                  variant="h6"
                  color="secondary"
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                >
                  Upload
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  style={{ color: "blue" }}
                >
                  {materialName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCloseUploadDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleUploadSubmit}
            disabled={
              myAttachment.attachment === "" ||
              myAttachment.attachment === " " ||
              myLoader === true ||
              chapter.length === 0
            }
          >
            {myLoader === false ? (
              "Upload"
            ) : (
              <CircularProgress color={theme.palette.secondary.light} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        acceptedFiles={["video/mp4"]}
        maxFileSize={10000000000}
        open={dropZone}
        dropzoneParagraphClass={classes.dropZone}
        dialogProps={{ className: classes.myDropZone }}
        onClose={handleDropZoneClose}
        onSave={(files) => handleSaveMaterial(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        filesLimit={1}
        getDropRejectMessage={() =>
          "file type is not supported please upload a mp4 file"
        }
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>

      <Backdrop className={classes.backdrop} open={backLoader}>
        <CircularProgress color="inherit" />
        <Typography style={{marginLeft: '1em', color: '#fff'}} variant="h5">Please wait, Your video is being uploaded.</Typography>
      </Backdrop>
    </React.Fragment>
  );
}
