import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import ChartMaker from './ChartMaker';
import SmartCircleChart from "../DetailedAnalysis/SmartCircleChart"
import AdeptiveCompletion from './AdeptiveCompletion';



const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
  chartlWrapper: {
    margin: '8px 0',
    width: '100%',
    justifyContent: 'space-between'
  },
  chartWrapperInner: {
    background: '#fff',
    padding: '15px 0',
    boxShadow: '0px 0px 9px #00000069',
    borderRadius: '7px',
    maxWidth: '49%',
    flexBasis: '49%',
  }
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function AdeptiveTestLandingPage() {

  const classes = useStyle();
  const theme = useTheme();

  const [openTestDialog, setOpenTestDialog] = useState(false);
  const [selectedSection, setSelectedData] = useState([]);
  const [subjectWiseChart, setSubjectWiseChart] = useState({});
  const [showCompletion, setShowCompletion] = useState(false);
  const [createExamData, setCreateExamData] = useState({
    subjectName: 0,
    standard: 0,
    chapterName: 0,
    sectionName: [],
    typology: 0,
    questionType: 0,
    difficultyLevel: 0
  });
  const [showDetailedAnalysis, setShowDetailedAnalysis] = useState(false);
  const [selectedSec, setSelectedSec] = useState([]);
  const [sectionDataList, setSectionDataList] = useState([
    { secName: "Section-A", sectionId: 1 },
    { secName: "Section-B", sectionId: 2 },
    { secName: "Section-C", sectionId: 3 },
  ])


  useEffect(() => {
    let labels = ["Maths", "Physics", "Chemistry"];
    let dataVal = [90, 60, 48];
    let barBgColor = dataVal.map(x => x > 80 ? "#00a427" : x >= 60 && x < 80 ? "#f2d718" : "#ed1b30")
    let chartData = {
      labels: labels,
      datasets: [{
        data: dataVal,
        subjectData: [{ subjectId: 1, subjectName: "Maths" }, { subjectId: 2, subjectName: "Physics" }, { subjectId: 3, subjectName: "Chemistry" },],
        backgroundColor: barBgColor,
        borderColor: barBgColor,
      }],
    }

    setSubjectWiseChart(chartData)

  }, []);

  const showClassWisePerf = (e) => {
    if (e.length > 0) {
      setShowCompletion(true)
      var data = subjectWiseChart.datasets[e[0]._datasetIndex].subjectData;
      console.log("Cases", data[e[0]._index]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    if (value[value.length - 1] === "all") {
      setCreateExamData({
        ...createExamData,
        [name]: createExamData.sectionName.length === sectionDataList.length ? [] : sectionDataList
      })
      return;
    }
    setCreateExamData({
      ...createExamData,
      [name]: value
    })

    if (name === "subjectName") {
      setCreateExamData({
        ...createExamData,
        [name]: value,
        standard: 0,
        chapterName: 0,
        sectionName: [],
        typology: 0,
        questionType: 0,
        difficultyLevel: 0
      })
    } else if(name === "chapterName"){
      setCreateExamData({
        ...createExamData,
        [name]: value,
        sectionName: [],
        typology: 0,
        questionType: 0,
        difficultyLevel: 0
      })
    }

  };

  const handleAdaptiveTestCreate = () => {
    console.log("data", createExamData)
  }


  const options = [
    { secName: "Section-A", sectionId: 1 },
    { secName: "Section-B", sectionId: 2 },
    { secName: "Section-C", sectionId: 3 },
  ];

  const handleCloseDialog = () => {
    setOpenTestDialog(false)
    setCreateExamData({
      subjectName: "",
      standard: "",
      chapterName: "",
      sectionName: []
    })
  }

  return (
    <>

      {!showDetailedAnalysis ?
        <Grid
          item
          container
          direction="column"
          spacing={1}
          style={{ padding: "0 1em" }}
        >
          <Grid item container justify='flex-end'>
            <Button
              variant='contained'
              color='secondary'
              style={{ marginRight: "1em" }}
              onClick={() => setShowDetailedAnalysis(true)}
            >Detailed Analysis
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => setOpenTestDialog(true)}
            >Take Exam</Button>
          </Grid>
          <Grid item container spacing={3} className={classes.chartlWrapper}>
            <Grid item sm={6} className={classes.chartWrapperInner}>
              <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '600', color: '#000', marginBottom: '15px' }}>SUBJECT WISE COMPLETION</Typography>
              <Grid item container>
                <ChartMaker
                  height={132}
                  dataset={subjectWiseChart}
                  percentage={true}
                  legends={false}
                  showBarVal={true}
                  showClassWisePerf={showClassWisePerf}
                />
              </Grid>
            </Grid>
            {showCompletion &&
              <Grid item sm={6} className={classes.chartWrapperInner}>
                <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>STANDARD WISE COMPLETION</Typography>
                <Grid item sm={6}>
                  <Typography component="div" style={{ display: 'flex', justifyContent: 'center' }}>
                    <SmartCircleChart
                      percentage={"80"}
                      textName={"11th Standard"}
                    />
                  </Typography>
                </Grid>
              </Grid>}
          </Grid>
        </Grid>
        : <AdeptiveCompletion
          setOpenTestDialog={setOpenTestDialog}
          setShowDetailedAnalysis={setShowDetailedAnalysis}
        />
      }


      <Dialog
        open={openTestDialog}
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle onClose={handleCloseDialog}>Adaptive Test</DialogTitle>
        <DialogContent>
          <Grid item container spacing={2}>
            <Grid item sm={4}>
              <TextField
                id="subject"
                select
                variant='outlined'
                onChange={handleInputChange}
                value={createExamData.subjectName}
                name="subjectName"
                fullWidth
                label="Subject*"
              >
                <MenuItem value={1}>Mathematics</MenuItem>
                <MenuItem value={2}>Physics</MenuItem>
                <MenuItem value={10}>Chemistry</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={4}>
              <TextField
                id="standard"
                select
                variant='outlined'
                fullWidth
                label="Standard*"
                onChange={handleInputChange}
                value={createExamData.standard}
                name="standard"
              >
                <MenuItem value={12}>Class 12</MenuItem>
                <MenuItem value={11}>Class 11</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={4}>
              <TextField
                id="chapter"
                select
                variant='outlined'
                fullWidth
                label="Chapter*"
                onChange={handleInputChange}
                value={createExamData.chapterName}
                name="chapterName"
              >
                <MenuItem value={120}>chapterName-1</MenuItem>
                <MenuItem value={121}>chapterName-2</MenuItem>
              </TextField>
            </Grid>

            <Grid item sm={3}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id="multipal-section-label">Section Name*</InputLabel>
                <Select
                  labelId="multipal-section-label"
                  id="multipal-section-label"
                  multiple
                  label="Section Name*"
                  renderValue={(selected) => selected.map(x => x.secName).join(", ")}
                  value={createExamData.sectionName}
                  onChange={handleInputChange}
                  name="sectionName"
                >
                  <MenuItem value="all">
                    <Checkbox checked={sectionDataList.length > 0 && createExamData.sectionName.length === sectionDataList.length} />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {sectionDataList.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      <Checkbox checked={createExamData.sectionName.indexOf(option) > -1} />
                      <ListItemText primary={option.secName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <TextField
                id="typology"
                select
                variant='outlined'
                fullWidth
                label="Typology*"
                onChange={handleInputChange}
                value={createExamData.typology}
                name="typology"
              >
                <MenuItem value={120}>chapterName-1</MenuItem>
                <MenuItem value={121}>chapterName-2</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={3}>
              <TextField
                id="questionType"
                select
                variant='outlined'
                fullWidth
                label="Question Type*"
                onChange={handleInputChange}
                value={createExamData.questionType}
                name="questionType"
              >
                <MenuItem value={120}>chapterName-1</MenuItem>
                <MenuItem value={121}>chapterName-2</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={3}>
              <TextField
                id="difficulty"
                select
                variant='outlined'
                fullWidth
                label="Difficulty Level*"
                onChange={handleInputChange}
                value={createExamData.difficultyLevel}
                name="difficultyLevel"
              >
                <MenuItem value={1}>Easy</MenuItem>
                <MenuItem value={2}>Medium</MenuItem>
                <MenuItem value={3}>Hard</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='text' color="secondary" onClick={handleCloseDialog}>Cancel</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAdaptiveTestCreate}
          >Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
