import { Backdrop, CircularProgress, Grid, IconButton, Snackbar } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react'
import ExamTabMaker from '../Exam/ExamTabMaker';
import RectificationExamPage from './RectificationExamPage';
import { makeReqBody, url } from './store';
import TableMaker from './TableMaker';
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";


const useStyle = makeStyles((theme) => ({
    subContainer: {
        ...theme.typography.subContainer,
    },
    dialogContainer: {
        "& .MuiDialog-paper": {
            width: "60em",
        },
        "& MuiDialog-paperWidthSm": {
            maxWidth: "100em",
        },
    },
    myDropZone: {
        color: "black !important",
        "& .MuiDialogContent-root": {
            scrollbarWidth: "none",
        },
        "& .MuiTypography-h6": {
            color: "black !important",
        },
        "& .MuiTypography-h5": {
            color: "black !important",
        },

    },
    switchButton: {
        "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.secondary.dark,
        },

        "& .MuiSwitch-track": {
            backgroundColor: theme.palette.common.white,
        },

        "& .MuiSwitch-colorSecondary.Mui-checked": {
            color: theme.palette.secondary.light,
        },

        "& .MuiSwitch-colorSecondary": {},
    },
    pdfContainer: {
        marginTop: "none",
    },

    pdfContainer2: {
        width: "200em",
    },
    titlebar: {
        //  height: "6px",
        backgroundColor: theme.palette.common.ltgrey,
    },
    pdfScroll: {
        overflowY: "auto",

        "& .MuiDialog-paper": {
            width: "60em",
        },
        "& MuiDialog-paperWidthSm": {
            maxWidth: "100em",
        },
        "& .MuiDialogContent-root": {
            minHeight: '12em',
        }
    },

    myDropZone: {
        "& .MuiDialogContent-root": {
            scrollbarWidth: "none",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: "#fff",
    },
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const MyDataMaker = (props) => {
    const classes = useStyle();
    const theme = useTheme();
    const [rows, setRows] = useState([]);
    const [loader, setLoader] = useState(false);
    const [reRender, setRerender] = useState(false);
    const [dropZone, setDropZone] = useState(false);
    const [snack, setSnack] = useState(false);
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const [studentData, setStudentData] = useState({});
    const [backLoader, setBackLoader] = useState(false);





    useEffect(() => {
        setLoader(true);
        try {
            fetch(url, makeReqBody(window.localStorage.boardId > 6 ? "SCHOOL_SMART_QBT_RECTIFICATION" : "SCHOOL_EXAM_RECTIFICATION", {
                examTermId: props.examTermId,
                studentInstanceId: window.localStorage.studentInstanceId,
                sectionId: parseInt(window.localStorage.sectionId),
                branchId: parseInt(window.localStorage.branchId),
                subjectId: 0,
            })).then(resp => resp.json())
                .then(data => {
                    let row = [];
                    data.respData[0].respMsg.result.length > 0 ?
                        data.respData[0].respMsg.result.map(x => {
                            window.localStorage.boardId > 6 ?
                                row.push([
                                    { title: x.examName, type: "text" },
                                    { title: x.createdOn, type: "text" },
                                    { title: x.dueDate, type: "text" },
                                    { title: "Take Exam", type: "takeRectificationExam", fullDetails: x },
                                    { title: x.rectifyStatus, type: "text" },
                                ])
                                : window.localStorage.roleId === "5" ?
                                    row.push([
                                        { title: x.subjectName, type: "text" },
                                        {
                                            title: x.questUrl === null ||
                                                x.questUrl === undefined ||
                                                x.questUrl.trim() === "" ||
                                                x.questfile,
                                            type: x.questUrl === null ||
                                                x.questUrl === undefined ||
                                                x.questUrl.trim() === "" ||
                                                x.questfileType,
                                            href: x.questUrl,
                                        },
                                        { title: x.createdOn, type: "text" },
                                        { title: x.dueDate, type: "text" },
                                        {
                                            title: x.ansUrl === null ||
                                                x.ansUrl === undefined ||
                                                x.ansUrl.trim() === "" ||
                                                x.ansfile,
                                            type: x.ansUrl === null ||
                                                x.ansUrl === undefined ||
                                                x.ansUrl.trim() === "" ||
                                                x.ansfileType,
                                            href: x.ansUrl,
                                        },
                                        { title: x.rectifyStatus, type: "text" },
                                        { title: x.remarks, type: "text" },
                                    ])
                                    : row.push([
                                        { title: x.subjectName, type: "text" },
                                        {
                                            title: x.questUrl === null ||
                                                x.questUrl === undefined ||
                                                x.questUrl.trim() === "" ||
                                                x.questfile,
                                            type: x.questUrl === null ||
                                                x.questUrl === undefined ||
                                                x.questUrl.trim() === "" ||
                                                x.questfileType,
                                            href: x.questUrl,
                                        },
                                        { title: x.createdOn, type: "text" },
                                        { title: x.dueDate, type: "text" },
                                        {
                                            title: x.ansUrl === null ||
                                                x.ansUrl === undefined ||
                                                x.ansUrl.trim() === "" ||
                                                x.ansfile,
                                            type: x.ansUrl === null ||
                                                x.ansUrl === undefined ||
                                                x.ansUrl.trim() === "" ||
                                                x.ansfileType,
                                            href: x.ansUrl,
                                        },
                                        { fullDetails: x, typeFeature: "rectifyAnswerUpload" },
                                        { title: x.rectifyStatus, type: "text" },
                                        { title: x.remarks, type: "text" },
                                    ])
                        })
                        : row.push([
                            { title: "No Data Available", type: "text" },
                            { title: "", type: "text" },
                            { title: "", type: "text" },
                            { title: "", type: "text" },
                            { title: "", type: "text" },
                        ]);

                    setRows(row);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                })
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }, [reRender]);


    const headers = window.localStorage.boardId > 6 ? [
        "Exam Name",
        "Created",
        "Due Date",
        "Action",
        "Status",
    ] : window.localStorage.roleId === "5" ? [
        "Sbject",
        "Generated Rectification Work",
        "Created",
        "Due Date",
        "Uploaded Rectification Work",
        "Status",
        "Comments"
    ] : [
        "Subject",
        "Generated Rectification Work",
        "Created",
        "Due Date",
        "Uploaded Rectification Work",
        "Upload",
        "Status",
        "Comments"
    ];

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleGetStudentData = (data) => {
        setDropZone(true);
        setStudentData(data);
    }


    const handleRectifyUpload = async (files) => {
        setBackLoader(true)
        console.log("Files:", files[0]);
        let attachment = await toBase64(files[0]);
        let newattachment = attachment.split("base64,")[1];
        let myFileName = files[0].name.split(".")[0];
        let myFileType = files[0].type.split("/")[1];

        try {
            fetch(url, makeReqBody("SCHOOL_EXAM_RECTIFICATION_UPLOAD_BY_STUDENT", {
                erectifyId: studentData.erectifyId,
                studentInstanceId: studentData.studentInstanceId,
                loginUserId: window.localStorage.userId,
                uploadMarksDetail: newattachment,
                fileName: myFileName,
                fileType: myFileType,
            })).then(res => res.json())
                .then(data => {
                    if (data.respData[0].respMsg.status === "Success") {
                        setMessage(data.respData[0].respMsg.message);
                        setSnack(true);
                        setStatus("Success")
                    } else {
                        setMessage(data.respData[0].respMsg.message);
                        setSnack(true);
                        setStatus("Error")
                    }
                    setRerender(!reRender)
                    setDropZone(false);
                    setBackLoader(false)

                }).catch(error => {
                    console.error(error);
                    setTimeout(() => {
                        setBackLoader(false)
                        setDropZone(false);
                    }, 1000);
                })
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                setBackLoader(false)
                setDropZone(false);
            }, 1000);
        }
    }


    return (
        <>
            {loader === true ? (
                <CircularProgress color="secondary" />
            ) : (
                <>
                    <TableMaker
                        columns={headers}
                        rows={rows}
                        toReload={props.toReload}
                        setToReLoad={props.setToReLoad}
                        handleTakeRectificationExam={props.handleTakeRectificationExam}
                        handleGetStudentData={handleGetStudentData}
                    />
                </>
            )}

            <DropzoneDialog
                cancelButtonText={"Cancel"}
                submitButtonText={"Submit"}
                maxFileSize={6000000}
                open={dropZone}
                dialogProps={{ className: classes.myDropZone }}
                onClose={() => setDropZone(false)}
                onSave={(files) => handleRectifyUpload(files)}
                showPreviews={true}
                showFileNamesInPreview={true}
                acceptedFiles={["image/*", "application/pdf"]}
                filesLimit={1}
            />


            <Backdrop className={classes.backdrop} open={backLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                style={{ zIndex: theme.zIndex.drawer + 1100 }}
                open={snack}
                autoHideDuration={9000}
                onClose={() => setSnack(false)}
                action={<>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setSnack(false)}
                    >
                        <CloseIcon
                            style={{ color: theme.palette.common.tabFont }}
                            fontSize="small"
                        />
                    </IconButton>
                </>}
            >
                <Alert severity={status}>{message}</Alert>
            </Snackbar>
        </>
    );
};



export default function RectificationWork() {

    const classes = useStyle();
    const theme = useTheme();
    const [toReload, setToReLoad] = useState(false)
    const [loader, setLoader] = useState(true);
    const [tabHeadings, setTabHeadings] = useState([]);
    const [termDataList, setTermDataList] = useState([]);
    const [showTakeExam, setShowTakeExam] = useState(false);
    const [studentData, setStudentData] = useState({})


    const handleTakeRectificationExam = (data) => {
        console.log("data", data)
        setShowTakeExam(true)
        setStudentData(data)
    }


    useEffect(() => {
        setLoader(true);
        const getData = async () => {
            let termData = [];
            let subjectName = [];
            await fetch(url, makeReqBody("SCHOOL_GET_EXAM_TERMS_FOR_BRANCH", window.localStorage.boardId > 6 ? {
                id: window.localStorage.branchId,
                sectionId: window.localStorage.sectionId
            } : {
                id: window.localStorage.branchId,
                standard: window.localStorage.className.substring(0, 2),
            }))
                .then((response) => response.json())
                .then((data) => {
                    data.respData[0].respMsg.result.map((item, index1) => {
                        subjectName.push(item.name);
                        termData.push(item);
                    });

                    setTabHeadings(subjectName);
                })
                .catch((data) => {
                    console.log("sorry something went wrong");
                });
            try {
                let AllLists = [];

                termData.map((item, index) => {
                    let temList = (
                        <MyDataMaker
                            key={index}
                            examTermId={item.examTermId}
                            toReload={toReload}
                            setToReLoad={setToReLoad}
                            handleTakeRectificationExam={handleTakeRectificationExam}
                        />
                    );

                    AllLists.push(temList);
                });
                setTermDataList(AllLists);
            } catch (error) {
                console.error(error);
            }
        };
        getData();

        setInterval(() => {
            setLoader(false);
        }, 500);
    }, [toReload]);


    return (
        <>
            <Grid
                item
                container
                direction="column"
                spacing={1}
                className={classes.subContainer}
            >
                <Grid item>
                    {showTakeExam ?
                        <RectificationExamPage
                            setShowTakeExam={setShowTakeExam}
                            studentData={studentData}
                        />
                        : <>{loader === false ? (
                            <ExamTabMaker tabs={tabHeadings} data={termDataList} subjectBased={true} />
                        ) : (
                            <CircularProgress color="secondary" />
                        )}
                        </>}
                </Grid>
            </Grid>
        </>
    )
}
