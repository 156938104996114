import React, { useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { Grid, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import is from "is_js";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SendIcon from "@material-ui/icons/Send";
import { useHistory } from "react-router-dom";
import { ThemeEnergy } from "../Ui/Theme.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Particles from "react-particles-js";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles((theme) => ({}));

export default function Terms(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const list1 = [
    "To provide you with services, important information and marketing promotions.",
    "To follow up to ensure requested information are met with and any further requirements.",
    "To send notify you of any updates available for the application/product and you will have to update it for making use of its best and latest version.",
    "For general mailing list subscription although you will also have an option to opt out from receiving any promotional e-mails as per standard web practices.",
    "To communicate with you about any issue that you raise with us or which follows from an interaction between us.",
    "To develop existing and new methods that meets the expectations and requirement of our users",
  ];

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData(
      "Communication",
      "Communication Name, Address, Enrolment ID, Phone Numbers, Email address.	Internally authorised employees, Parents Till when you or the School is associated with us",
      "Internally authorised employees, Parents",
      "Till when you or the School is associated with us",
      4.0
    ),
    createData(
      "Identification (Students & Parents)",
      "User id, Password, Name, Enrolment ID, Gender, Date of Birth (“DOB), Image, Blood group, Occupation",
      "Internally authorised employees, Students and Parents",
      "Till when you or the School is associated with us",
      4.3
    ),
    createData(
      "Identification (Teachers & Employees)",
      "User id, Password, Name, Enrolment ID, Gender, Date of Birth (“DOB), Image, Blood group, Designation, Experience, Qualification, Reporting manager, Department Internally authorised employees Till when you or the School is associated with us ",
      "Internally authorised employees",
      "Till when you or the School is associated with us",
      6.0
    ),
    createData(
      "Academic Advancement (Students)",
      "Class, Board, Marks, Attendance Internally authorised employees, Students, Parents	For that academic year except for overall performance score which shall be held till when you or the school is associated with us.",
      "Internally authorised employees, Students, Parents",
      "For that academic year except for overall performance score which shall be held till when you or the school is associated with us.",
      4.3
    ),
    createData(
      "Holistic Education/ Overall Development (Students & Teachers)",
      "Blood Group, Height, Weight, Inputs from other Departments, psychological concerns, etc.",
      "Internally authorised employees, counsellors (who have a separate agreement to maintain confidentiality of information) along with only the respective student and his/her parents or teachers from whom such data is collected",
      "For that academic year except for overall performance score which shall be held till when you or the school is associated with us.",
      3.9
    ),
  ];
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      //style={{ height: "100vh" }}
    >
      <Grid item container direction="column" alignItems="center">
        <Paper
          elevation={10}
          style={{
            width: "84vw",
            // height: is.safari() || is.ie() ? "1000px" : "inherit",
            // maxHeight: is.safari() || is.ie() ? "1000px" : "100vh",
            overflowY: "auto",
          }}
        >
          <Grid
            container
            direction="column"
            style={{
              padding: 10,
              paddingTop: 0,
              marginLeft: "auto",
              marginRight: "auto",
              //height: is.safari() || is.ie() ? "2500px" : "100%",
              width: "68vw",
              overflowY: "auto",
              flexWrap: "inherit",
            }}
          >
            <Grid
              item
              direction="column"
              style={{
                backgroundColor: "white",

                marginLeft: "auto",
                marginRight: "auto",
                // width: "92vw",
              }}
            >
              <Typography variant="h3" align="center" gutterBottom>
                Terms and Conditions
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                1. INTRODUCTION
              </Typography>

              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                1.1. These <b>“TERMS”</b> govern the use of our website
                www.trunkie.projectheen.com and other associated applications/
                products/ websites/ services managed by us. Please read the
                <b>TERMS</b> carefully before accessing/browsing/using our
                website, application or product. These <b>TERMS</b> (which
                include the Privacy Policy and Terms and Conditions applicable
                to your access/use of our website/application/product as updated
                from time to time shall constitute the entire agreement between
                you and us. Your access/browse/use of our website, application,
                product or services through any type of device shall signify
                your knowledge and compliance of the <b>TERMS</b> set forth and
                you shall be legally bound by it. In the event of a minor
                accessing/browsing/using our website/application/product, it
                shall be assumed that he/she has taken the necessary permission
                from his/her guardian.
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                1.2. These <b>TERMS</b> are in compliance of the laws of the
                country, more particularly of the following:
                <Grid item>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon
                          style={{ fontSize: "10px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Information Technology Act, 2000
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon
                          style={{ fontSize: "10px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Information Technology (Intermediaries Guidelines)
                        Rules, 2011
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon
                          style={{ fontSize: "10px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Information Technology (Reasonable security practices
                        and procedures and sensitive personal data or
                        information) Rules, 2011 [“Data Protection Rules, 2011”]
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                2. CHANGES IN TERMS
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                <Grid item>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        We may revise these <b>TERMS</b> at any time by amending
                        this page. Please check this page from time to time to
                        take notice of any changes we make as they are binding
                        on you.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        We may update our site from time to time and change the
                        content at any time.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        We do not guarantee that our site or any content on it
                        will be free from errors or omissions.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        We do not guarantee that our site or any content on it
                        will always be available or be uninterrupted. Access to
                        our site is permitted on a temporary basis. We may
                        suspend, withdraw, discontinue or change all or any part
                        of our site without notice. We will not be liable to you
                        if for any reason our site is unavailable at any time or
                        for any period. You are responsible for making all
                        arrangements necessary for you to have access to our
                        site.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                3. USE OF WEBSITE
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                <Grid item>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        In order to ensure that this website remains available
                        to all users, we may monitor network traffic to identify
                        unauthorised attempts to upload or change information,
                        or to otherwise cause damage to the site.
                        <b>
                          Anyone using this site expressly consents to such
                          monitoring.
                        </b>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Unauthorised attempts to modify, alter, deface, destroy
                        or corrupt any information stored on this site or this
                        system, to defeat or circumvent any security features,
                        to probe, scan or test for vulnerabilities, to breach
                        security or authentication measures, to install or
                        attempt to install unauthorised software or to utilise
                        this system for other than its intended purposes (as
                        expressly provided herein) are expressly prohibited and
                        may result in criminal prosecution
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Any possible criminal activity that is detected while
                        you use our website, application or product will be
                        reported, together with any evidence as required from us
                        under law to the appropriate authorities.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        If you choose or are provided with a user identification
                        code, password or any other piece of information as part
                        of our security procedures, you must treat such
                        information as confidential. You must not disclose it to
                        any third party. We have the right to disable any user
                        identification code or password whether chosen by you or
                        allocated by us at any time if in our reasonable opinion
                        you have failed to comply with any of the provisions of
                        these <b>TERMS</b> of use.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                4. PROTECTION OF USER INFORMATION (PRIVACY POLICY)
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                As a data collector, we are obliged under law to provide clear
                and transparent information about our data processing
                activities. The same is provided under this section which
                constitutes the PRIVACY POLICY and also any other communications
                we send you in this regard.{" "}
              </Typography>
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  4.1. Information/Data collected
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  In order to use our product and services, you will be required
                  to submit the following information:{" "}
                </Typography>
                <Typography>
                  <TableContainer
                    component={Paper}
                    style={{ marginBottom: "1em" }}
                  >
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            Purpose for collecting information (and from whom)
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Data Collected{" "}
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Sharing of data, if any
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Retention Period of such data
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell component="th">
                            {" "}
                            Communication
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Name, Address, Enrolment ID, Phone Numbers, Email
                            address.
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Internally authorised employees, Parents
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Till when you or the School is associated with us
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th">
                            {" "}
                            Identification (Students & Parents)
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            User id, Password, Name, Enrolment ID, Gender, Date
                            of Birth (“DOB), Image, Blood group, Occupation
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Internally authorised employees, Students and
                            Parents
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Till when you or the School is associated with us
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th">
                            {" "}
                            Identification (Teachers & Employees)
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            User id, Password, Name, Enrolment ID, Gender, Date
                            of Birth (“DOB), Image, Blood group, Designation,
                            Experience, Qualification, Reporting manager,
                            Department
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Internally authorised employees
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Till when you or the School is associated with us
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th">
                            {" "}
                            Academic Advancement (Students)
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Class, Board, Marks, Attendance{" "}
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Internally authorised employees, Students, Parents
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            For that academic year except for overall
                            performance score which shall be held till when you
                            or the school is associated with us.
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th">
                            {" "}
                            Holistic Education/ Overall Development (Students &
                            Teachers)
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Blood Group, Height, Weight, Inputs from other
                            Departments, psychological concerns, etc
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            Internally authorised employees, counsellors (who
                            have a separate agreement to maintain
                            confidentiality of information) along with only the
                            respective student and his/her parents or teachers
                            from whom such data is collected.
                          </StyledTableCell>
                          <StyledTableCell align="center" width="25%">
                            For that academic year except for overall
                            performance score which shall be held till when you
                            or the school is associated with us
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  The information collected above fall under categories of
                  “Personal Information”, “Sensitive Personal Information” and
                  “Associated Information” defined under the Data Protection
                  Rules, 2011 and shall be collectively referred to as
                  “Information” herein. Providing such Information will mean
                  that you have read these TERMS and voluntarily consent to
                  providing us with such Information for the intended purposes
                  set forth.{" "}
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  Apart from this, we may also collect some information
                  automatically including the type of mobile device you use,
                  your mobile devices unique device ID, the IP address of your
                  mobile device, your mobile operating system, the type of
                  mobile Internet browsers you use, and information about the
                  way you use the website/application/product along with other
                  relevant information as per the permissions that you provide.{" "}
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  Please note that we intend to safeguard the confidentiality of
                  your Information at all times and have kept various physical,
                  procedural and electronic safeguards to protect it.{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  4.2. Purpose for collection of such Information{" "}
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ textDecoration: "underline" }}
                >
                  4.2.1. Communication Purpose
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  <Grid item>
                    <List dense={true}>
                      <ListItem>
                        <ListItemIcon>
                          <SendIcon
                            style={{ fontSize: "12px", color: "black" }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          To provide you with services, important information
                          and marketing promotions.
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <SendIcon
                            style={{ fontSize: "12px", color: "black" }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          To follow up to ensure requested information are met
                          with and any further requirements.
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <SendIcon
                            style={{ fontSize: "12px", color: "black" }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          To send notify you of any updates available for the
                          application/product and you will have to update it for
                          making use of its best and latest version.
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <SendIcon
                            style={{ fontSize: "12px", color: "black" }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          For general mailing list subscription although you
                          will also have an option to opt out from receiving any
                          promotional e-mails as per standard web practices.
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <SendIcon
                            style={{ fontSize: "12px", color: "black" }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          To communicate with you about any issue that you raise
                          with us or which follows from an interaction between
                          us.
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <SendIcon
                            style={{ fontSize: "12px", color: "black" }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          To develop existing and new methods that meets the
                          expectations and requirement of our users.
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Grid>
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ textDecoration: "underline" }}
                >
                  4.2.2. Identification Purpose
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  The user id and password will be unique to ever user and is to
                  ensure the identification of every user. It is important to
                  note here that this user id and password is our only way of
                  identifying the user and we will not be liable for loss of the
                  same or any unauthorised access of it.{" "}
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ textDecoration: "underline" }}
                >
                  4.2.3. Academic Advancement Purpose
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  Information with regard to academics such as the class, marks
                  and attendance is important to boost the academic performance
                  of the student and keep parents informed on their children's
                  progress. This also helps students to develop their 21st
                  Century skills such as leadership, analysing, decision making
                  and creativity.
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ textDecoration: "underline" }}
                >
                  4.2.4. Overall Development Purpose{" "}
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  Information which relates to other departments such as sports,
                  extra curriculars, discipline, etc., psychological concerns,
                  medical history such as the blood group, height, weight, etc.
                  is collected to help students develop holistically and improve
                  their overall performance to compete globally.{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  4.3. Access to Information{" "}
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  The Unique user ID and password shall be completely encrypted
                  and accessible only by the stakeholders. In case, you forget
                  your password, we will give you an option to reset it with
                  your approval as per standard practices.{" "}
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  All Information shall be filled by you and hence, also made
                  accessible to you. You will also have an option to
                  upgrade/correct/change any details provided as and when
                  necessary. However, unless communicated to us, it shall be
                  assumed that the details submitted by you are correct, true
                  and accurate.{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  4.4. Children’s policy{" "}
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  We recognize the need to provide further privacy protections
                  with respect to the personal information that we may receive
                  relating to children on our Website and Applications. When we
                  receive personal information relating to children, we take
                  additional steps to protect children’s privacy, including -
                  Notifying parents about our information practices with regard
                  to children, including the types of personal information we
                  may collect relating to children, the uses to which we may put
                  that information, and whether and with whom we may share that
                  information; In accordance with applicable law, and our
                  practices, obtaining consent from parents for the collection
                  of personal information of their children, or for sending
                  information about our products and services directly to their
                  children; Limiting our collection of personal information
                  relating to children to no more than is reasonably necessary
                  to participate in our Services.{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  4.5. Disclosure of Information{" "}
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginLeft: "1em" }}
                >
                  We will disclose information only to legal authorities only
                  and if required by law. We may also disclose the Information
                  in other circumstances in good faith (uberrima fides) only if
                  such disclosure is necessary to protect our rights, protect
                  your safety or the safety of others, investigate fraud,
                  address security or technical issues or respond to a
                  government request.{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                5. USE OF COOKIES
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                A cookie is a small file of letters and numbers that we store on
                your browser or the hard drive of your computer if you agree.
                Cookies contain information that is transferred to your
                computer’s hard drive. We use cookies on our website for a
                number of purposes. They help us provide you with a good
                experience when you browse our website and allow us to improve
                our site. By continuing to browse our website, you are agreeing
                to our use of cookies.{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                6. INTELLECTUAL PROPERTY
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                <Grid item>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Unless otherwise indicated, the Site is our proprietary
                        property and all source code, database, functionality
                        software, website designs, audio, video, text,
                        photographs, and graphics on the Site and the trademarks
                        and the logos contained therein are owned or controlled
                        by us or licensed to us and are protected by Copyright
                        and Trademark laws and various other intellectual
                        property rights laws of India and International
                        Conventions.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Except as expressly provided in the Site, you are
                        prohibited from copying, reproducing, modifying,
                        distributing, displaying, performing or transmitting any
                        of the contents of the Site for any purpose. You may use
                        the Site and the contents contained in the site for your
                        own individual non-commercial and informational purpose
                        only.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Certain contents on the Website may belong to third
                        parties. Such contents have been reproduced after taking
                        prior consent from said party and all rights relating to
                        such content will remain with such third party.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        You agree that any content posted/uploaded by you to our
                        website/application/product is owned by you exclusively
                        or you have the required license/permission to use such
                        data and that you will not be violating the intellectual
                        property rights of any third person in using such data.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                7. PROHIBITED USES
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                You may use our website/application/product or any content in
                only for lawful and intended purposes. You may not use it:{" "}
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                <Grid item>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        In any way that breaches any applicable local, national
                        or international law or regulation.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        In any way that is unlawful or fraudulent, or has any
                        unlawful or fraudulent purpose or effect.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        For the purpose of harming or attempting to harm minors
                        in any way.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        To transmit, or procure the sending of, any unsolicited
                        or unauthorised advertising or promotional material, or
                        any other form of similar solicitation (spam).
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        To knowingly transmit any data, or send or upload any
                        material that contains viruses, Trojan horses, worms,
                        time-bombs, keystroke loggers, spyware, adware, or any
                        other harmful programs or similar computer code designed
                        to adversely affect the operation of any computer
                        software or hardware.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        To reproduce, duplicate, copy or resell any part of our
                        website/application/product in contravention of the
                        provisions of TERMS, including its name and Logo.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        To lease, sell, reproduce, copy, sublicense, transfer,
                        assign or manipulate any information, content, goods or
                        services we provide in our website/application/product
                        (including multiple sets of Analysis and Question Bank
                        database, various content resources and videos).
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        To access without authority, interfere with, damage,
                        disrupt, alter or modify:
                      </ListItemText>
                    </ListItem>
                    <Grid item>
                      <List dense={true}>
                        <ListItem style={{ marginLeft: "3em" }}>
                          <ListItemIcon>
                            <FiberManualRecordIcon
                              style={{ fontSize: "10px", color: "black" }}
                            />
                          </ListItemIcon>
                          <ListItemText>any part of our site;</ListItemText>
                        </ListItem>
                        <ListItem style={{ marginLeft: "3em" }}>
                          <ListItemIcon>
                            <FiberManualRecordIcon
                              style={{ fontSize: "10px", color: "black" }}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            any equipment or network on which our site is
                            stored;
                          </ListItemText>
                        </ListItem>
                        <ListItem style={{ marginLeft: "3em" }}>
                          <ListItemIcon>
                            <FiberManualRecordIcon
                              style={{ fontSize: "10px", color: "black" }}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            any software used in the provision of our site; or
                          </ListItemText>
                        </ListItem>
                        <ListItem style={{ marginLeft: "3em" }}>
                          <ListItemIcon>
                            <FiberManualRecordIcon
                              style={{ fontSize: "10px", color: "black" }}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            any equipment or network or software owned or used
                            by any third party.
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Grid>
                  </List>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                8. DISCLAIMER OF ENDORSEMENT
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                <Grid item>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Our website and documents posted on it, may contain
                        hypertext links or pointers to information created and
                        maintained by other public and private organisations. We
                        do not guarantee the accuracy, relevance, usefulness,
                        timeliness or completeness of any linked information.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        We are not responsible for issues or concerns that may
                        be faced by users upon using third party services.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        The inclusion of links or pointers to other sites is not
                        intended to assign importance to those sites and the
                        information contained in them, nor is it intended to
                        endorse, recommend or favour any views expressed, or
                        commercial products or services offered on those sites,
                        or the organisations sponsoring the sites, by trade
                        name, trademark, manufacture or otherwise.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Reference on our website to any specific commercial
                        products, processes or services, or the use of any
                        trade, firm or corporation name is for the information
                        and convenience of the site's visitors, and does not
                        constitute endorsement, recommendation or favouring by
                        the Company.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        The views expressed by any individual within any web
                        forum are the views of that individual only and do not
                        reflect or represent in any way our views.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                9. LIMITATION OF LIABILITY
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                <Grid item>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        To the extent permitted by law, we exclude all
                        conditions, warranties, representations or other terms
                        that may apply to our site or any content on it, whether
                        express or implied.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        We will not be liable to any user for any loss or
                        damage, whether in contract, tort (including
                        negligence), breach of statutory duty, or otherwise
                        arising under or in connection with:
                      </ListItemText>
                    </ListItem>
                    <Grid item>
                      <List dense={true}>
                        <ListItem style={{ marginLeft: "3em" }}>
                          <ListItemIcon>
                            <FiberManualRecordIcon
                              style={{ fontSize: "10px", color: "black" }}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            Use of, or inability to use our site; or
                          </ListItemText>
                        </ListItem>
                        <ListItem style={{ marginLeft: "3em" }}>
                          <ListItemIcon>
                            <FiberManualRecordIcon
                              style={{ fontSize: "10px", color: "black" }}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            Use of or reliance on any content displayed on our
                            site.
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Grid>

                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        We will not be liable for any loss or damage caused by a
                        virus, distributed denial-ofservice attack, or other
                        technologically harmful material that may infect your
                        computer equipment, computer programs, data or other
                        proprietary material due to your use of our site or to
                        your downloading of any content on it, or on any website
                        linked to it.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                10. SUSPENSION AND TERMINATION
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                We will determine, at our discretion, whether there has been a
                breach of any of the TERMS through your use of our site. We may
                take such legal and appropriate action as we deem appropriate on
                such breach which may include, but not be limited to the
                temporary/permanent withdrawal of your right to use our
                website/product/application and/or legal proceedings against you
                without prior notice.{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                11. MISCELLANEOUS
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                <Grid item>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        If, for any reason, a court of competent jurisdiction
                        finds any provision of the TERMS, or portion thereof, to
                        be unenforceable, that provision shall be enforced to
                        the maximum extent permissible so as to give effect to
                        the intent of the parties as reflected by that
                        provision, and the remainder of the TERMS shall continue
                        in full force and effect.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SendIcon
                          style={{ fontSize: "12px", color: "black" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Any failure on our behalf to enforce or exercise any
                        provision of these TERMS, or any related right, shall
                        not constitute a waiver of that provision or right.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                12. CONTACT US
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                Any comments, questions or suggestions about these TERMS or our
                handling of your personal data should be emailed to
                trunkie@projectheen.com. Alternatively, you can contact us at
                our postal address as published in our website.{" "}
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ marginLeft: "1em" }}
              >
                If you do not agree with the TERMS, do not use the website,
                application, product or our services.{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
