import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import comprehensive from "./assets/comprehensive.png";

import goldennotes from "./assets/goldennotes.png";
import exam from "./assets/exam.png";
import writingnotes from "./assets/writingNotes.png";

import { useRouteMatch } from "react-router-dom";
import { Fade, Grow } from "@material-ui/core";

import TabsWrappedLabel from "./Ui/goldenNotes copy";
import GoldenNotesTeacher from "./Ui/GoldenNotesTeacher";
import SubjectRecording from "./Ui/SubjectRecording.js";
import WritingMaterial from "./Ui/WritingMaterial.js";
import WritingMaterialTeacher from "./Ui/WritingMaterialTeacher.js";
import WorkSpaceHeader from "./Ui/WorkSpaceHeader.js";
import TextBookLinks from "./Ui/TextBookLinks.js";
import ExamMaterial from "./Ui/ExamMaterial.js";
import ComprehensiveGuide from "./Ui/ComprehensiveGuide.js";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    ...theme.typography.mainContainer,
    height: "100% ",
    minHeight: "130vh",
  },
  tile1: {
    ...theme.typography.tiles,
    backgroundImage: `url(${comprehensive})`,
    borderTopLeftRadius: theme.typography.mainContainer.borderTopLeftRadius,
  },
  tile2: {
    ...theme.typography.tiles,
    backgroundImage: `url(${goldennotes})`,
  },
  tile3: {
    ...theme.typography.tiles,
    backgroundImage: `url(${writingnotes})`,
  },
  tile4: {
    ...theme.typography.tiles,
    backgroundImage: `url(${exam})`,
  },
  headingItem: {
    ...theme.typography.tilesHeading,
    boxShadow: theme.shadows[5],
  },
  subjectsContainer: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2em",
    background: theme.palette.common.whiteOpaque2,
  },
  subjectList: {
    color: theme.palette.common.black,
  },
  subjectListItemContainer: {
    width: "90%",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  headingTitle: {
    ...theme.typography.h4,
  },
  markedSelected: {
    backgroundColor: "black",
  },
}));

export default function ResourcesGolden(props) {
  const classes = useStyle();

  let { path, url } = useRouteMatch();
  const [fade, setFade] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [value, setValue] = React.useState(0);

  const setSelected = (e, index) => {
    console.log(index);
    setValue(index);
  };

  const goldennotes = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/GoldenNotes` ? true : false}
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <div style={{ width: "100%" }}>
          <WorkSpaceHeader title="GOLDEN NOTES" moduleId={10} />
          {window.localStorage.roleId == 3 ? (
            <GoldenNotesTeacher />
          ) : (
            <TabsWrappedLabel value={value} setValue={setValue} />
          )}
        </div>
      </Fade>
    </React.Fragment>
  );

  const subjectrecording = (
    <React.Fragment>
      <Fade
        in={
          window.location.pathname === `${url}/SubjectRecording` ? true : false
        }
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <div style={{ width: "100%" }}>
          <WorkSpaceHeader title="SUBJECT RECORDING" moduleId={8} />
          <SubjectRecording />
        </div>
      </Fade>
    </React.Fragment>
  );

  const writingmaterial =
    window.localStorage.roleId == 3 ? (
      <React.Fragment>
        <Fade
          in={
            window.location.pathname === `${url}/WritingMaterials`
              ? true
              : false
          }
          timeout={{ accept: 800, enter: 1000, exit: 300 }}
        >
          <div style={{ width: "100%" }}>
            <WorkSpaceHeader
              title="WRITING MATERIAL"
              moduleId={11}
            />
            <WritingMaterialTeacher />
          </div>
        </Fade>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Fade
          in={
            window.location.pathname === `${url}/WritingMaterials`
              ? true
              : false
          }
          timeout={{ accept: 800, enter: 1000, exit: 300 }}
        >
          <div style={{ width: "100%" }}>
            <WorkSpaceHeader
              title="WRITING MATERIAL"
              moduleId={11}
            />
            <WritingMaterial />
          </div>
        </Fade>
      </React.Fragment>
    );

  const textbooklinks = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/TextBookLinks` ? true : false}
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <div style={{ width: "100%" }}>
          <WorkSpaceHeader title="TEXT BOOK LINKS" moduleId={9} />
          <TextBookLinks />
        </div>
      </Fade>
    </React.Fragment>
  );

  const exammaterial = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === `${url}/ExamMaterials` ? true : false}
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <div style={{ width: "100%" }}>
          <WorkSpaceHeader title="EXAM MATERIAL" moduleId={12} />
          <ExamMaterial />
        </div>
      </Fade>
    </React.Fragment>
  );

  const comprehensiveguide = (
    <React.Fragment>
      <Fade
        in={
          window.location.pathname === `${url}/ComprehensiveGuide`
            ? true
            : false
        }
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <div style={{ width: "100%" }}>
          <WorkSpaceHeader title="COMPREHENSIVE GUIDE" moduleId={35} />
          <ComprehensiveGuide />
        </div>
      </Fade>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        className={classes.mainContainer}
        style={{ width: `calc(100% - 12em)` }}
      >
        {window.location.pathname === "/resources" ? (
          goldennotes
        ) : window.location.pathname === "/resources/GoldenNotes" ? (
          goldennotes
        ) : window.location.pathname === "/resources/SubjectRecording" ? (
          subjectrecording
        ) : window.location.pathname === "/resources/WritingMaterials" ? (
          writingmaterial
        ) : window.location.pathname === "/resources/TextBookLinks" ? (
          textbooklinks
        ) : window.location.pathname === "/resources/ExamMaterials" ? (
          exammaterial
        ) : window.location.pathname === "/resources/ComprehensiveGuide" ? (
          comprehensiveguide
        ) : (
          <Typography variant="h1">404 not found</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
}
