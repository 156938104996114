import teacherDetails from "../assets/HomeMenu/teacherDetails.jpg";
import connectzone from "../assets/HomeMenu/connectzone.jpg";
import notifications from "../assets/HomeMenu/notifications.jpg";
import calendar from "../assets/HomeMenu/calender.jpg";
import timetable from "../assets/HomeMenu/timetable.jpg";
import vigilance from "../assets/HomeMenu/vigilance.jpg";
import messageteacher from "../assets/HomeMenu/messageteacher.jpg";

import goldennotes from "../assets/Resources/goldennotes.jpg";
import comprehensive from "../assets/Resources/comprehensive.jpg";
import writingmaterial from "../assets/Resources/writingmaterial.jpg";
import exammaterial from "../assets/Resources/exammaterial.jpg";
import textbooklinks from "../assets/Resources/textbooklinks.jpg";
import subjectrecordings from "../assets/Resources/subjectrecordings.jpg";

import exam from "../assets/UploadSection/exam.jpg";
import writing from "../assets/UploadSection/writing.jpg";

import changepassword from "../assets/Settings/changepassword.jpg";
import reportissue from "../assets/Settings/reportissue.jpg";

import discussionforum from "../assets/StudentGroup/discussionforum.jpg";
import creategroup from "../assets/StudentGroup/creategroup.png";
import postquery from "../assets/StudentGroup/postquery.jpg";

import Cw from "../assets/ToDo/Cw.jpg";
import Hw from "../assets/ToDo/Hw.jpg";
import record from "../assets/ToDo/record.jpg";
import assignment from "../assets/ToDo/assignment.jpg";
import project from "../assets/ToDo/project.jpg";
import observation from "../assets/ToDo/observation.jpg";

import subjectmanager from "../assets/Manage/subjectmanager.jpg";
import attendance from "../assets/Manage/attendance.jpg";
import applyleave from "../assets/Manage/applyleave.jpg";

import examanalysis from "../assets/ExamMode/examanalysis.jpg";
import smartExam from "../assets/ExamMode/smartExam.jpg";
import deatailsAnalysis from "../assets/ExamMode/deatailsAnalysis.jpg"
import timetable2 from "../assets/timeTableimg.jpeg";
import studentDetails from "../assets/studentImg.jpeg";
import { envType } from "./envType";

export const makeReqBody = (busicode, busiparams) => ({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  method: "POST",
  body: JSON.stringify({
    requestList: [
      {
        isEncrypt: false,
        transactionId: "0000001438241244",
        busiCode: busicode,
        busiParams: busiparams,
      },
    ],
    pubInfo: {
      appId: "appId",
      sessionId: window.localStorage.sessionId,
      version: "21",
      timestamp: "20180314175419",
      lang: "en_US",
      userId: "1000016614",
      serviceId: "7021150585",
      circleId: "MU",
      ncsroute: "WE015",
      customerId: "1000016614",
      osType: "windows",
    },
  }),
});

export const myUploadObject = [
  {
    isStudentRequested: true,
    fileName: "",
    fileType: "",
    solutionAttachment: "",
    taskTxnId: null,
    txnStatus: "",
    url: "",
  },

  {
    attachment: "",
    description: "",
    examTxnId: 0,
    fileName: "",
    fileType: "",
    studentInstanceId: 0,
    target: "",
    url: "",
    examType: 0
  },
];

export const getSession = () => {
  try {
    fetch(url, {
      method: "GET",
    })
      .then((response) => response.text())
      .then((data) => {
        window.localStorage.sessionId = data;
      })

      .catch((data) => {
        console.error(data);
      });
  } catch (error) {
    console.error(error);
  }
};

export const TRACKING_ID = envType === "prod" ? "UA-167607951-1" : "UA-180148039-1";

export const courseRegistrationUrl =
  envType === "dev"
    ? "http://dev-adminportal.projectheen.in/courseRegistration"
    : envType === "qa"
      ? "https://qa-adminportal.projectheen.com/courseRegistration"
      : envType === "prod"
        ? "https://admin.projectheen.com/courseRegistration"
        : null;


export const goldenNotesHeaders = [
  "MATHEMATICS",
  "ENGLISH",
  "EVS",
  "HINDI",
  "SCIENCE",
];
export const url =
  envType === "dev"
    ? "http://dev-gtw.projectheen.in/ncs-school-service/servlet/Service"
    : envType === "qa"
      ? "https://qa-gtw.projectheen.com/ncs-school-service/servlet/Service"
      : envType === "prod"
        ? "https://prod-gtw.projectheen.com/ncs-school-service/servlet/Service"
        : "nourl";

//testing-by-sagar
//export const url =
// "http://prod-gtw.projectheen.com/ncs-school-service/servlet/Service";

// export const urlmultipart =
//   "http://schl-prd.projectheen.com/commonapi/uploadMultipart";
export const urlmultipart =
  envType === "dev"
    ? "http://dev-scl-mod.projectheen.in/commonapi/uploadMultipart"
    : envType === "qa"
      ? "https://qa-scl-mod.projectheen.com//commonapi/uploadMultipart"
      : envType === "prod"
        ? "https://schl-prd.projectheen.com/commonapi/uploadMultipart"
        : "nourl";

export const MenuItemsStudent = {
  Home: [
    {
      title: "Notifications",
      to: "/LandingPageStudent/Notification",
      img: notifications,
    },
    {
      title: "Connect Zone",
      to: "/LandingPageStudent/ConnectZone",
      img: connectzone,
    },
    {
      title: "Teacher Details",
      to: "/LandingPageStudent/TeacherDetails",
      img: teacherDetails,
    },
    {
      title: "Time Table",
      to: "/LandingPageStudent/TimeTable",
      img: timetable,
    },
    { title: "Calendar", to: "/LandingPageStudent/Calendar", img: calendar },
  ],
  Resources: [
    {
      title: "Comprehensive Guide",
      to: "/resources/ComprehensiveGuide",
      img: comprehensive,
    },
    { title: "Golden Notes", to: "/resources/GoldenNotes", img: goldennotes },
    {
      title: "Subject Recording",
      to: "/resources/SubjectRecording",
      img: subjectrecordings,
    },
    {
      title: "Text Book Links",
      to: "/resources/TextBookLinks",
      img: textbooklinks,
    },
    {
      title: "Writing Material",
      to: "/resources/WritingMaterials",
      img: writingmaterial,
    },
    {
      title: "Exam Material",
      to: "/resources/ExamMaterials",
      img: exammaterial,
    },
  ],
  ToDoTracker: window.localStorage.boardId > 6 ?
    [
      { title: "DPP", to: "/ToDoTracker/Classwork", img: Cw },
      { title: "Homework", to: "/ToDoTracker/Homework", img: Hw },
      { title: "Assignment", to: "/ToDoTracker/Assignment", img: assignment },
    ] : [
      { title: "Classwork", to: "/ToDoTracker/Classwork", img: Cw },
      { title: "Homework", to: "/ToDoTracker/Homework", img: Hw },
      {
        title: "Record",
        to: "/ToDoTracker/Record",
        img: record,
      },
      { title: "Observation", to: "/ToDoTracker/Observation", img: observation },

      { title: "Assignment", to: "/ToDoTracker/Assignment", img: assignment },
      { title: "Project", to: "/ToDoTracker/Project", img: project },
    ],
  UploadSection: [
    { title: "Writing Works", to: "/UploadSection/WriteWorks", img: writing },
    { title: "Exam Works", to: "/UploadSection/ExamWorks", img: exam },
  ],
  ExamMode: [
    { title: "Exam Room", to: "/ExamMode/eExam", img: exammaterial },
    { title: "Exam Analysis", to: "/ExamMode/ExamAnalysis", img: examanalysis },
    { title: "Detailed Analysis", to: "/ExamMode/detailed-analysis", img: deatailsAnalysis },
    { title: "Rectification Work", to: "/ExamMode/rectification", img: writingmaterial },
  ],
  Manage: [
    {
      title: "Subject Manager",
      to: "/Manage/SubjectManager",
      img: subjectmanager,
    },
    { title: "Attendance", to: "/Manage/Attendance", img: attendance },
  ],
  Settings: [
    {
      title: "Change Password",
      to: "/Settings/ChangePassword",
      img: changepassword,
    },
    { title: "Report Issue", to: "/Settings/ReportIssue", img: reportissue },
  ],
  StudentGroup: window.localStorage.boardId > 6 ? [
    {
      title: "Discussion Forum",
      to: "/StudentGroup/DiscussionForum",
      img: discussionforum,
    },
    {
      title: "Post a Query",
      to: "/StudentGroup/PostAQuery",
      img: postquery,
    },
    { title: "Smart Exams", to: "/StudentGroup/SmartExams", img: smartExam },
    // { title: "Adaptive Test", to: "/StudentGroup/adaptiveTest", img: exam },
  ] : [
    {
      title: "Discussion Forum",
      to: "/StudentGroup/DiscussionForum",
      img: discussionforum,
    },
    {
      title: "Post a Query",
      to: "/StudentGroup/PostAQuery",
      img: postquery,
    },
    { title: "Smart Exams", to: "/StudentGroup/SmartExams", img: smartExam },
  ],
};

export const MenuItemsParents = {
  Home: [
    {
      title: "Notifications",
      to: "/LandingPageStudent/Notification",
      img: notifications,
    },
    {
      title: "Vigilance Mode",
      to: "/LandingPageStudent/Vigilance",
      img: vigilance,
    },
    {
      title: "Connect Zone",
      to: "/LandingPageStudent/ConnectZone",
      img: connectzone,
    },
    {
      title: "Teacher Details",
      to: "/LandingPageStudent/TeacherDetails",
      img: teacherDetails,
    },

    {
      title: "Message Teacher",
      to: "/LandingPageStudent/MessageTeacher",
      img: messageteacher,
    },
    {
      title: "Time Table",
      to: "/LandingPageStudent/TimeTable",
      img: timetable,
    },
    { title: "Calendar", to: "/LandingPageStudent/Calendar", img: calendar },
  ],
  Resources: [
    {
      title: "Comprehensive Guide",
      to: "/resources/ComprehensiveGuide",
      img: comprehensive,
    },
    { title: "Golden Notes", to: "/resources/GoldenNotes", img: goldennotes },
    {
      title: "Subject Recording",
      to: "/resources/SubjectRecording",
      img: subjectrecordings,
    },
    {
      title: "Text Book Links",
      to: "/resources/TextBookLinks",
      img: textbooklinks,
    },
    {
      title: "Writing Material",
      to: "/resources/WritingMaterials",
      img: writingmaterial,
    },
    {
      title: "Exam Material",
      to: "/resources/ExamMaterials",
      img: exammaterial,
    },
  ],
  ToDoTracker: window.localStorage.boardId > 6 ?
    [
      { title: "DPP", to: "/ToDoTracker/Classwork", img: Cw },
      { title: "Homework", to: "/ToDoTracker/Homework", img: Hw },
      { title: "Assignment", to: "/ToDoTracker/Assignment", img: assignment },
    ] : [
      { title: "Classwork", to: "/ToDoTracker/Classwork", img: Cw },
      { title: "Homework", to: "/ToDoTracker/Homework", img: Hw },
      {
        title: "Record",
        to: "/ToDoTracker/Record",
        img: record,
      },
      { title: "Observation", to: "/ToDoTracker/Observation", img: observation },

      { title: "Assignment", to: "/ToDoTracker/Assignment", img: assignment },
      { title: "Project", to: "/ToDoTracker/Project", img: project },
    ],
  UploadSection: [
    { title: "Writing Works", to: "/UploadSection/WriteWorks", img: writing },
    { title: "Exam Works", to: "/UploadSection/ExamWorks", img: exam },
  ],
  ExamMode: [
    { title: "Exam Room", to: "/ExamMode/eExam", img: exammaterial },
    { title: "Exam Analysis", to: "/ExamMode/ExamAnalysis", img: examanalysis },
    { title: "Detailed Analysis", to: "/ExamMode/detailed-analysis", img: deatailsAnalysis },
    { title: "Rectification Work", to: "/ExamMode/rectification", img: writingmaterial },
  ],
  Manage: [
    {
      title: "Subject Manager",
      to: "/Manage/SubjectManager",
      img: subjectmanager,
    },
    { title: "Attendance", to: "/Manage/Attendance", img: attendance },
    {
      title: "Apply Leave",
      to: "/Manage/ApplyLeave",
      img: applyleave,
    },
  ],
  Settings: [
    {
      title: "Change Password",
      to: "/Settings/ChangePassword",
      img: changepassword,
    },
    { title: "Report Issue", to: "/Settings/ReportIssue", img: reportissue },
  ],
  StudentGroup: [
    {
      title: "Discussion Forum",
      to: "/StudentGroup/DiscussionForum",
      img: discussionforum,
    },
    {
      title: "Post a Query",
      to: "/StudentGroup/PostAQuery",
      img: postquery,
    },
    { title: "Smart Exams", to: "/StudentGroup/SmartExams", img: smartExam },
    // { title: "Adaptive Test", to: "/StudentGroup/adaptiveTest", img: exam },
  ],
};

export const MenuItemsTeachers = {
  Home: [
    {
      title: "Notifications",
      to: "/LandingPageStudent/Notification",
      img: notifications,
    },

    {
      title: "Connect Zone",
      to: "/LandingPageStudent/ConnectZone",
      img: connectzone,
    },

    {
      title: "Student Details",
      to: "/LandingPageStudent/StudentDetails",
      img: studentDetails,
    },

    {
      title: "Time Table",
      to: "/LandingPageStudent/TimeTable",
      img: timetable2,
    },
    {
      title: "Messaging",
      to: "/LandingPageStudent/Messaging",
      img: messageteacher,
    },
    { title: "Calendar", to: "/LandingPageStudent/Calendar", img: calendar },
  ],

  Favourites: [],
  Resources: [
    {
      title: "Comprehensive Guide",
      to: "/resources/ComprehensiveGuide",
      img: comprehensive,
    },
    { title: "Golden Notes", to: "/resources/GoldenNotes", img: goldennotes },
    {
      title: "Subject Recording",
      to: "/resources/SubjectRecording",
      img: subjectrecordings,
    },
    {
      title: "Text Book Links",
      to: "/resources/TextBookLinks",
      img: textbooklinks,
    },
    {
      title: "Writing Material",
      to: "/resources/WritingMaterials",
      img: writingmaterial,
    },
    {
      title: "Exam Material",
      to: "/resources/ExamMaterials",
      img: exammaterial,
    },
  ],

  ExamMode: [
    { title: "Exam Room", to: "/ExamMode/eExam", img: exammaterial },
    { title: "Exam Analysis", to: "/ExamMode/ExamAnalysis", img: examanalysis },
    { title: "Rectification Work", to: "/ExamMode/rectification", img: writingmaterial },
  ],
  Manage: [
    {
      title: "Subject Manager",
      to: "/Manage/SubjectManager",
      img: subjectmanager,
    },
    { title: "Attendance", to: "/Manage/Attendance", img: attendance },
    {
      title: "Leave Requests",
      to: "/Manage/ApplyLeave",
      img: applyleave,
    },
  ],
  Settings: [
    {
      title: "Change Password",
      to: "/Settings/ChangePassword",
      img: changepassword,
    },
    { title: "Report Issue", to: "/Settings/ReportIssue", img: reportissue },
  ],
  StudentGroup: [
    {
      title: "Discussion Forum",
      to: "/StudentGroup/DiscussionForum",
      img: discussionforum,
    },
    {
      title: "Manage Groups",
      to: "/StudentGroup/CreateGroup",
      img: creategroup,
    },
    { title: "Smart Exams", to: "/StudentGroup/SmartExams", img: smartExam },
    // { title: "Adaptive Test", to: "/StudentGroup/adaptiveTest", img: exam },
  ],
};
