import React, { useEffect, useRef, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Backdrop, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Paper, Radio, RadioGroup, Snackbar, Table, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeReqBody, url } from './store';
import NumberFormat from 'react-number-format';
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';


const useStyle = makeStyles((theme) => ({
    table: {
        "& .MuiTableCell-root": {
            padding: "6px",
            border: "none"

        },
        "& span": {
            background: "#e3e3e3",
            width: "35px",
            display: "inline-block",
            textAlign: "center",
            padding: "8px",
            borderRadius: "3px",
            color: "#fff"
        }
    },
    questionList: {
        width: "100%",
        // height: "200px",
        overflowY: "scroll",
        marginTop: "2em",

        "& ul": {
            padding: "0 1.5em"
        },

        "& li": {
            background: "#e3e3e3",
            width: "35px",
            display: "inline-block",
            textAlign: "center",
            padding: "8px",
            borderRadius: "3px",
            margin: "4px",
        },
    },

    description: {
        "& p": {
            margin: "0"
        }
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: "#fff",
    },

    markScored: {
        width: "160px",
        padding: "8px",
        backgroundColor: "#ccc",
        color: "#fff",
        borderRadius: "4px",
        textAlign: "center",
    },
}))



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function RectificationExamPage(props) {
    const theme = useTheme()
    const classes = useStyle();
    const [backLoader, setBackLoader] = useState(false);
    const [rectifyQues, setRectifyQues] = useState([])
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [subjectList, setSubjectList] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState(0);
    const [chapterList, setChapterList] = useState([]);
    const [notAnswer, setNotAnswer] = useState(0);
    const [snack, setSnack] = useState(false);
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const [selectAns, setSelectedAns] = useState(0);
    const location = useLocation();
    const history = useHistory()


    useEffect(() => {
        
        console.log("props.studentData", props.studentData)

        setBackLoader(true);
        try {
            fetch(url, makeReqBody(props.studentData.jsonStatus === "CREATED" ? "SCHOOL_SMART_QBT_RECTIFICATION__EXAM" : "SCHOOL_SMART_QBT_RECTIFY_JSON", {
                examId: props.studentData.epExamId,
                examTermId: props.studentData.examTermId,
                studentInstanceId: props.studentData.studentInstanceId,
                sectionId: props.studentData.sectionId,
                branchId: props.studentData.branchId,
                rectifyId: props.studentData.rectifyId,
            })).then(res => res.json()).then(data => {
                if (props.studentData.jsonStatus === "CREATED") {
                    setRectifyQues(data.respData[0].respMsg.result);
                    setSubjectList(data.respData[0].respMsg.result[0].subjectList);
                } else {
                    setRectifyQues(data.respData[0].respMsg.result.rectifyQuestionList);
                    setSubjectList(data.respData[0].respMsg.result.rectifyQuestionList[0].subjectList);
                }

                setTimeout(() => {
                    setBackLoader(false);
                }, 1000);
            })
        } catch (error) {
            console.error(error)
            setTimeout(() => {
                setBackLoader(false);
            }, 1000);
        }

        console.log("props.", props.studentData)

    }, []);

    useEffect(() => {
        let chapterList = [];
        let arr = []
        if (rectifyQues.length > 0) {
            chapterList = rectifyQues[currentQuestion].questionList.map(x => x.chapterName);
            arr = chapterList.filter((item, index) => chapterList.indexOf(item) === index)

            // let notAnswered = rectifyQues.map((x => x.notAnswered)).filter(item => item === false).length;
            let answeredStudent = rectifyQues.map((x => x.answered)).filter(item => item === true).length;
            setSelectedAns(answeredStudent)
            setNotAnswer(rectifyQues.length - answeredStudent);

        }
        setChapterList(arr)
    }, [currentQuestion, rectifyQues]);



    const handleChange = (e, idx, char) => {
        console.log(e.target.value)
        let rectifyQuesLst = [...rectifyQues];
        rectifyQuesLst[currentQuestion].questionList[idx].studentAns = e.target.value;
        rectifyQuesLst[currentQuestion].questionList[idx].stuAns = true;
        rectifyQuesLst[currentQuestion].questionList[idx].charValue = char;

        setRectifyQues(rectifyQuesLst);
    };


    const handleSubmitResult = (action) => {
        if (action === "SUBMIT") {
            for (let i = 0; i < rectifyQues.length; i++) {
                if (rectifyQues[i].answered === false && rectifyQues[i].questionList.length !== 0) {
                    setSnack(true);
                    setMessage("Please answer all questions.");
                    setStatus("error");
                    return;
                }
            }
        }
        let endDate =  props.studentData.dueDate+" "+"24:00:00"
        setBackLoader(true);
        try {
            fetch(url, makeReqBody("SCHOOL_SMART_QBT_SUBMIT_RECTITFY_EXAM", {
                examId: props.studentData.epExamId,
                examTermId: props.studentData.examTermId,
                studentInstanceId: props.studentData.studentInstanceId,
                sectionId: props.studentData.sectionId,
                rectifyId: props.studentData.rectifyId,
                branchId: props.studentData.branchId,
                rectifyQuestionList: rectifyQues,
                action: Date.parse(new Date()) <= Date.parse(endDate) ? action : "EXPIRED"
            })).then(res => res.json()).then(data => {
                console.log("data", data)
                if (data.respData[0].respMsg.status === "Success" && action === "SUBMIT") {
                    setStatus("success");
                    setMessage(data.respData[0].respMsg.message);
                    setSnack(true);
                    setTimeout(() => {
                        props.setShowTakeExam(false)
                    }, 500)
                }
                setTimeout(() => {
                    setBackLoader(false);
                }, 500);
            })
        } catch (error) {
            console.error(error)
            setTimeout(() => {
                setBackLoader(false);
            }, 1000);
        }
    };


    const handleCheckResult = () => {
        goTop.current.scrollIntoView({ behavior: 'smooth', block: "start" })
        let data = [...rectifyQues];
        let stuAns = rectifyQues[currentQuestion].questionList.map((x => x.stuAns)).filter(item => item === true).length;

        if (data[currentQuestion].questionList.length === stuAns) {
            data[currentQuestion].answered = true;
            data[currentQuestion].notAnswered = true;
        } else {
            setSnack(true);
            setMessage("Please choose option.")
            setStatus("error")
            return
        }

        setRectifyQues(data)
        handleSubmitResult("SAVEFORLETTER")
        setBackLoader(false);
    };

    const goTop = useRef()


    return (
        <>
            <Grid
                item
                container
                direction="column"
                spacing={1}
                style={{ marginTop: "-15px" }}
            >
                <Grid item container>
                    <Grid item>
                    <Button variant='contained' color='secondary' onClick={() => props.setShowTakeExam(false)}>Back</Button>
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item>
                        <Typography variant="h6">Exam Name: <strong>{props.studentData.examName}</strong></Typography>
                    </Grid>
                </Grid>

                <Grid item container direction='row' spacing={3}>
                    <Grid item container sm={10} className={classes.description}>
                        <Grid
                            item
                            direction='column'
                            container
                            component={Paper}
                            justifyContent="space-between"
                            style={{
                                width: "100%",
                                position: "relative",
                            }}
                        >
                            <Grid item container style={{ overflowY: "scroll", msOverflowX: "scroll", height: "450px", padding: "2em 2em 1em", }} className="customPdfScroll">
                                {rectifyQues.length !== 0 && <>
                                    <Grid item container alignItems='center' justify='space-between' ref={goTop}>
                                        <Typography variant="h6" style={{ fontWeight: "700", paddingRight: "1em" }}>Question Number : {rectifyQues[currentQuestion].qNo} [<span style={{ fontWeight: "normal" }}>{rectifyQues[currentQuestion].qCode}</span>]</Typography>
                                        <Typography variant="h6" style={{ fontWeight: "700" }}>Rectification Work : {rectifyQues[currentQuestion].identifierName}</Typography>
                                    </Grid>
                                    <Grid item container >
                                        <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: decodeURIComponent(rectifyQues[currentQuestion].question) }}></div>
                                    </Grid>
                                    <Grid item container>
                                        <ul style={{ marginLeft: "12px" }}>
                                            {rectifyQues[currentQuestion].option.map((item, index) => (
                                                <li key={index} style={{ listStyle: "upper-alpha", fontSize: "16px" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(item) }}></div>
                                                </li>
                                            ))}
                                        </ul>
                                    </Grid>
                                    <Typography variant='body1' color='secondary'>Answer by You : {rectifyQues[currentQuestion].answerByStudent}</Typography>
                                    <Grid item container alignItems='center'>
                                        <Typography variant='body1' style={{ color: "green", paddingRight: "3px" }}>Correct Answer : </Typography>
                                        <Typography variant='body1' style={{ color: "green", }} dangerouslySetInnerHTML={{ __html: decodeURIComponent(rectifyQues[currentQuestion].correctAnswer) }}></Typography>
                                    </Grid>
                                    <Grid item container direction='column' >
                                        <Grid item>
                                            <Typography variant='body1'>Explanation : </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body1' dangerouslySetInnerHTML={{ __html: decodeURIComponent(rectifyQues[currentQuestion].explaination) }}></Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justify='space-between' alignItems='center' style={{ marginTop: "1em", marginBottom: "1em" }}>
                                        <Grid item sm={3}>
                                            <Typography className={classes.markScored} variant='body1'>Marks Scored {rectifyQues[currentQuestion].marksObtained}%</Typography>
                                        </Grid>
                                        <Grid item sm={3} style={{ textAlign: "right" }}>
                                            <Typography variant='body1'>Number of Question : {rectifyQues[currentQuestion].questionList.length}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='body1' style={{ width: "100%" }} color='primary'>Chapter Name : {chapterList.toString()}</Typography>
                                    <Grid item container>
                                        <ul style={{
                                            listStyle: "none",
                                            padding: "0",
                                            marginTop: "0",
                                            width: "100%"
                                        }}>
                                            {rectifyQues[currentQuestion].questionList.map((item, index) => (
                                                <>
                                                    <li style={{ overflowY: "scroll", paddingTop: "1em", borderBottom: "1px solid #ccc" }}>
                                                        {(rectifyQues[currentQuestion].identifierId === 19 || rectifyQues[currentQuestion].identifierId === 20) &&
                                                            <Grid item>
                                                                <Typography variant='h6' style={{ fontWeight: "700" }}>Paragraph</Typography>
                                                                <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(item.paragraphDesc) }}></div>
                                                            </Grid>
                                                        }
                                                        <Typography variant="h6" style={{ fontWeight: "700", paddingRight: "10px" }}>Question Number : {index + 1} [<span style={{ fontWeight: "normal" }}>{item.qCode}</span>]</Typography>
                                                        <Grid item container alignItems='center' style={{ marginBottom: "5px" }}>
                                                            <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(item.rectifyQues) }}></div>
                                                        </Grid>
                                                        {rectifyQues[currentQuestion].identifierId === 18 || rectifyQues[currentQuestion].identifierId === 1 || rectifyQues[currentQuestion].identifierId === 19 || rectifyQues[currentQuestion].identifierId === 20 ?
                                                            <>
                                                                <Grid style={{ paddingBottom: "1em", paddingLeft: "1em" }} >
                                                                    <FormControl component="fieldset">
                                                                        <RadioGroup column aria-label="answer" name="row-radio-buttons-group">
                                                                            {item.option.map((option, idx,) => {
                                                                                let char = 65;
                                                                                return (
                                                                                    <FormControlLabel
                                                                                        onChange={(e) => {
                                                                                            if (rectifyQues[currentQuestion].answered === false) {
                                                                                                handleChange(e, index, String.fromCharCode(char + idx))

                                                                                                console.log("char", String.fromCharCode(char + idx))
                                                                                            }
                                                                                        }}
                                                                                        key={idx}
                                                                                        value={option}
                                                                                        control={<Radio
                                                                                            color="secondary"
                                                                                            size="small"
                                                                                        />}
                                                                                        label={<div dangerouslySetInnerHTML={{ __html: decodeURIComponent(option) }}></div>}
                                                                                        name=""
                                                                                        checked={item.studentAns === option}
                                                                                        disabled={
                                                                                            rectifyQues[currentQuestion].answered ||
                                                                                            window.localStorage.roleId === "3" ||
                                                                                            window.localStorage.roleId === "5"
                                                                                        }
                                                                                    />
                                                                                )
                                                                            })}
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                            </>
                                                            : rectifyQues[currentQuestion].identifierId === 17 ?
                                                                <>
                                                                    <Grid item sm={3} style={{ margin: "12px 0 8px" }}>
                                                                        <NumberFormat
                                                                            value={item.studentAns}
                                                                            customInput={TextField}
                                                                            decimalScale={2}
                                                                            variant="outlined"
                                                                            disabled={
                                                                                rectifyQues[currentQuestion].answered ||
                                                                                window.localStorage.roleId === "3" ||
                                                                                window.localStorage.roleId === "5"
                                                                            }
                                                                            fullWidth
                                                                            label="Numerical value"
                                                                            allowNegative={false}
                                                                            onChange={(e) => handleChange(e, index)}
                                                                        // helperText={rectifyQues[currentQuestion].answered ? item.correctAns === item.studentAns ? "Correct" : "Incorrect" : ""}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                                : rectifyQues[currentQuestion].identifierId === 16 ? <>
                                                                    <FormGroup column aria-label="answer">
                                                                        {item.mcq.map((option, idx) => {
                                                                            let char = 65;
                                                                            return (
                                                                                <FormControlLabel
                                                                                    key={idx}
                                                                                    control={<Checkbox color="secondary" />}
                                                                                    value={option.checked}
                                                                                    checked={option.checked}
                                                                                    disabled={
                                                                                        rectifyQues[currentQuestion].answered ||
                                                                                        window.localStorage.roleId === "3" ||
                                                                                        window.localStorage.roleId === "5"
                                                                                    }
                                                                                    label={<div dangerouslySetInnerHTML={{ __html: decodeURIComponent(option.option) }}></div>}
                                                                                    onChange={(e) => {
                                                                                        if (rectifyQues[currentQuestion].answered === false) {
                                                                                            let isVisible = [...rectifyQues];
                                                                                            isVisible[currentQuestion].questionList[index].charValue += String.fromCharCode(char + idx) + ", ";
                                                                                            isVisible[currentQuestion].questionList[index].mcq[idx].checked = e.target.checked;
                                                                                            isVisible[currentQuestion].questionList[index].stuAns = e.target.checked;
                                                                                            setRectifyQues(isVisible)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )
                                                                        })}
                                                                    </FormGroup>
                                                                </>
                                                                    : null}
                                                        {rectifyQues[currentQuestion].answered &&
                                                            <>
                                                                {rectifyQues[currentQuestion].identifierId !== 17 ?
                                                                    <Typography variant='body1' style={{ display: "flex", alignItems: "center", color: item.correctAns === item.charValue ? "green" : "red" }}>Answer by You : {item.charValue}</Typography>
                                                                    : <Typography variant='body1' style={{ display: "flex", alignItems: "center", color: item.correctAns === item.studentAns ? "green" : "red" }}>Answer by You : {item.studentAns}</Typography>
                                                                }
                                                                <Grid item container alignItems='center'>
                                                                    <Typography variant='body1' style={{ color: "green", paddingRight: "3px" }}>Correct Answer: {item.correctAns}</Typography>
                                                                </Grid>

                                                                <Grid item container direction='column' style={{ marginBottom: "8px" }}>
                                                                    <Typography variant='body1'>Explanation :</Typography>
                                                                    <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(item.explaination) }}></div>
                                                                </Grid>
                                                            </>}
                                                    </li>
                                                </>
                                            ))}
                                        </ul>
                                    </Grid>
                                </>}
                            </Grid>
                            <Grid item container justify='flex-end' alignItems='center' style={{ padding: "0em 2em 2em" }}>

                                {rectifyQues && rectifyQues[currentQuestion] && rectifyQues[currentQuestion].questionList.length > 0 && rectifyQues[currentQuestion].answered === false && window.localStorage.roleId === "4" &&
                                    <Button
                                        variant="contained"
                                        color='secondary'
                                        onClick={handleCheckResult}
                                    // disabled={props.studentData.rectifyStatus === "COMPLETED"}
                                    >Check Answers</Button>}
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item container sm={2} direction='column'>
                        <Grid item container direction="row" justify='space-between' spacing={2} style={{ marginBottom: "1em" }}>
                            <Grid item container>
                                <TextField
                                    select
                                    variant="outlined"
                                    fullWidth
                                    label="Subject"
                                    value={selectedSubject}
                                    onChange={(e) => setSelectedSubject(e.target.value)}
                                >
                                    <MenuItem value={0}>All Subject...</MenuItem>
                                    {subjectList.map((item, index) => (
                                        <MenuItem key={index} value={item.subjectId}>
                                            {item.subjectName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            component={Paper}
                            justifyContent="space-between"
                            style={{ padding: "1em 0" }}
                        >
                            <TableContainer style={{ padding: "0.6em", }}>
                                <Table className={classes.table}>
                                    <TableRow>
                                        <TableCell>
                                            <span
                                                style={{
                                                    background: "#ED1B30",
                                                    clipPath: "polygon(0 0, 100% 17%, 100% 83%, 0 100%)",
                                                }}
                                            >{notAnswer}</span>
                                        </TableCell>
                                        <TableCell>Not Answered</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <span
                                                style={{
                                                    backgroundColor: "green",
                                                    borderRadius: "3px"
                                                }}
                                            >{selectAns}</span>
                                        </TableCell>
                                        <TableCell>Answered</TableCell>
                                    </TableRow>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid
                            item
                            container
                            component={Paper}
                            justifyContent="space-between"
                            className={`${classes.questionList}`}
                        >
                            <ul>
                                {rectifyQues.map((item, idx) => (
                                    selectedSubject == 0 ?
                                        <>
                                            <li key={idx}
                                                style={{
                                                    position: "relative",
                                                    cursor: "pointer",
                                                    color: "#fff",
                                                    clipPath: item.notAnswered === false && item.answered === false ? "polygon(0 0, 100% 17%, 100% 83%, 0 100%)" : "",
                                                    backgroundColor: item.answered === true ?
                                                        theme.palette.success.dark : "#ED1B30"
                                                }}
                                                onClick={() => {
                                                    let isVisible = [...rectifyQues]
                                                    if (isVisible[idx].answered === true) {
                                                        isVisible[idx].notAnswered = true;
                                                    }
                                                    if (item.questionList.length === 0) {
                                                        setMessage("No available question.");
                                                        setSnack(true);
                                                        setStatus("warning")
                                                    }

                                                    setRectifyQues(isVisible)
                                                    setCurrentQuestion(idx)
                                                    goTop.current.scrollIntoView({ behavior: 'smooth', block: "start" })
                                                }}
                                            >{item.qNo}</li>
                                        </>
                                        : item.subjectId == selectedSubject ?
                                            <>
                                                <li key={idx}
                                                    style={{
                                                        position: "relative",
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        clipPath: item.notAnswered === false && item.answered === false ? "polygon(0 0, 100% 17%, 100% 83%, 0 100%)" : "",
                                                        backgroundColor: item.answered === true ?
                                                            theme.palette.success.dark : "#ED1B30"
                                                    }}
                                                    onClick={() => {
                                                        let isVisible = [...rectifyQues]
                                                        if (isVisible[idx].answered === true) {
                                                            isVisible[idx].notAnswered = true;
                                                        }
                                                        if (item.questionList.length === 0) {
                                                            setMessage("No available question.");
                                                            setSnack(true);
                                                            setStatus("warning")
                                                        }
                                                        setRectifyQues(isVisible)
                                                        setCurrentQuestion(idx)
                                                        goTop.current.scrollIntoView({ behavior: 'smooth', block: "start" })
                                                    }}
                                                >{item.qNo}</li>
                                            </>
                                            : null
                                ))}

                            </ul>
                        </Grid>
                    </Grid>
                    {(window.localStorage.roleId === "4" && props.studentData.rectifyStatus === "PENDING") &&
                        <Grid item container justify='flex-end' style={{ padding: "0.5em 0.5em 2em 0" }}>
                            <Button
                                disabled={props.studentData.rectifyStatus === "COMPLETED"}
                                variant="outlined"
                                color='secondary'
                                onClick={() => handleSubmitResult("SAVEFORLETER")}
                            >Save for Later</Button>
                            <Button
                                disabled={props.studentData.rectifyStatus === "COMPLETED"}
                                variant="contained"
                                style={{ backgroundColor: "green", color: "#fff", marginLeft: "1.5em" }}
                                onClick={() => handleSubmitResult("SUBMIT")}
                            >Submit</Button>
                        </Grid>}
                </Grid>
            </Grid >


            <Backdrop className={classes.backdrop} open={backLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                style={{
                    zIndex: theme.zIndex.drawer + 1100
                }}
                open={snack}
                autoHideDuration={9000}
                onClose={() => setSnack(false)}
                action={
                    <>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setSnack(false)}
                        >
                            <CloseIcon
                                style={{ color: theme.palette.common.tabFont }}
                                fontSize="small"
                            />
                        </IconButton>
                    </>
                }
            >
                <Alert severity={status}>{message}</Alert>
            </Snackbar>
        </>
    )
}
