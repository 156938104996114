import {
  Card,
  CardContent,
  makeStyles,
  Typography,
  useTheme,
  Grid
} from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  outerBar: {
    // background: "#d6d6d6",
    height: '28px',
    borderRadius: '5px',
    // width: 'calc(100% - 50px)',
    // marginRight: '10px'

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    position: 'relative',

    "& span": {
      color: '#fff',
      fontFamily: `'Montserrat', 'sans-serif'`,
      fontWeight: '600',

      // "&:first-child": {
      //   width: '36px',
      //   height: '36px',
      //   display: 'flex',
      //   alignItems: 'center',
      //   justifyContent: 'center',
      //   borderRadius: '50%',
      //   color: '#eee',
      // }
    },


  },
  innerBar: {
    height: '100%',
    borderRadius: '0 5px 5px 0',
  },
  barTitle: {
    color: theme.typography.myTheme.themeName === "Night" ? '#000' : '#000',
    fontFamily: `'Montserrat', 'sans-serif'`,
    fontWeight: '800'
  },
  valTitle: {
    color: theme.typography.myTheme.themeName === "Night" ? '#000 !important' : '#000 !important',
    fontFamily: `'Montserrat', 'sans-serif'`,
    fontWeight: '800',
    position: "absolute",
    right: "-45px"
  },

}));

export default function ChapterHorizontalChart({
  name,
  val,
  width,
  bgChart,
  barClick,
  count,
  bgCircleCount,
  ...otherProps
}) {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <Grid item container direction="column">
      <Grid>
        <Typography variant="span" className={classes.barTitle} style={{ marginBottom: '5px', display: 'inline-block' }}>{count}. {name}</Typography>
      </Grid>
      <Grid item container sm={11} direction="row" style={{ flexDirection: 'row', alignItems: 'center', marginBottom: '10px', position: "relative" }}>
        <Typography component="div" className={classes.outerBar} onClick={barClick} style={{ cursor: otherProps.pointer === true ? "pointer" : "inherit", width: width, background: bgChart }}>
          {/* <Typography component="div" className={classes.innerBar} style={{width: width, background: bgChart}}>
                adsdasdasdas
                </Typography> */}
          {/* <Typography variant="span" style={{ background: bgCircleCount }}>
            {count}
          </Typography> */}
          <Typography variant="span" className={classes.valTitle}>{`${val}%`}</Typography>
        </Typography>
        {/* <Typography variant="span">{`${val}%`}</Typography>  */}
      </Grid>
    </Grid>
  );
}
