import React, { useState } from "react";

import {Grid, } from "@material-ui/core";
// import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core/styles";
import { Document, Page, pdfjs } from "react-pdf";
import CircularProgress from "@material-ui/core/CircularProgress";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyle = makeStyles((theme) => ({
  pageClass: {
    maxWidth: "600px",
    width: "90vw",
  },
  reactpdfdoc: {
    borderRadius: "50px",
    maxWidth: "50%",
    position: "relative",
  },
  pageControls: {
    position: "absolute",
    bottom: "5%",
    left: "50%",
    background: "white",
    opacity: 0,
    transform: "translateX(-50%)",
    transition: "opacity ease-in-out 0.2s",
    boxShadow: "0 30px 40px 0 rgba(16, 36, 94, 0.2)",
    borderRadius: "4px",
  },
  mainContainer: {},
  mainContainer2: {},
  cardContainer: {
    width: "18em",
    boxShadow: theme.shadows[5],
    borderRadius: 5,
    marginBottom: "1em",
  },
  cardContainer2: {
    width: "20em",
    boxShadow: theme.shadows[5],
    borderRadius: 5,
    marginBottom: "1em",
  },
}));

export default function Test(props) {
  const classes = useStyle();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [checked, setChecked] = React.useState(false);
  const [startLoader, setStartLoader] = React.useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setStartLoader(false);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onLoading() {
    console.log("the onloading comp has been called");
    setStartLoader(true);
  }

  function onLoadingStop() {
    console.log("the onloadingstop comp has been called");
    setStartLoader(false);
  }

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={
          props.displayFullscreen
            ? classes.mainContainer2
            : classes.mainContainer
        }
        style={{ width: props.myWidth ? props.myWidth : "inherit" }}
      >
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Document
              file={props.data}
              loading={onLoading}
              onLoadError={onLoadingStop}
              onMouseEnter={() => setChecked(true)}
              onMouseLeave={() =>
                setTimeout(() => {
                  setChecked(false);
                }, 500)
              }
              style={{ width: props.myWidth ? props.myWidth : "inherit" }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {props.displayFullscreen ? (
                Array.from(new Array(numPages), (el, index) => 
                  <Page 
                   key={`page_${index + 1}`}
                   pageNumber={index + 1} 
                   width={props.myWidth ? props.myWidth : 1000}
                   scale={0.9}                  
                  />
                )
                // <Page
                //   pageNumber={pageNumber}
                //   width={props.myWidth ? props.myWidth : 1000}
                //   scale={0.9}
                // />
              ) : (
                Array.from(new Array(numPages), (el, index) => 
                <Page 
                 key={`page_${index + 1}`}
                 pageNumber={index + 1} 
                 width={700}
                 scale={0.6}              
                />
              )
                // <Page pageNumber={pageNumber} width={700} scale={0.6} />
              )}
              {/* <Grid container justify= "center">

              {pageNumber}</Grid> */}
            </Document>
          </Grid>
          {/* <Grid
            item
            container
            direction="row"
            className={
              props.displayFullscreen
                ? classes.cardContainer2
                : classes.cardContainer
            }
            justify="center"
            style={{ backgroundColor: "white", marginTop: "0.5em" }}
          >
            <Grid item>
              <Button
                color="secondary"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                <ArrowBackIosIcon />
              </Button>
            </Grid>
            <Grid item>
              <Typography>
                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                <ArrowForwardIosIcon />
              </Button>
            </Grid>
          </Grid> */}
        </Grid>
        <Grid item>{startLoader ? <CircularProgress /> : null}</Grid>
      </Grid>
    </React.Fragment>
  );
}
