import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Button, Dialog, DialogContent, DialogActions, TextField, MenuItem, Select, ListItemText, Checkbox, InputLabel, FormControl, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Typography, Backdrop, AppBar, Toolbar, Switch, Tooltip } from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { url, makeReqBody } from "../Ui/store.js";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import NumberFormat from "react-number-format";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import DisplayPdf from "../Ui/DisplayPdf";


import moment from "moment";
import { isNull } from "lodash-es";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
    paddingTop: 0,
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
    "& .MuiDialogContent-root": {
      minHeight: '12em',

    },
    "& ::-webkit-scrollbar": {
      width: '7px!important',
      height: '5px',
    },
    "& ::-webkit-scrollbar-track:": {
      background: "#00256f",
    },

    "& ::-webkit-scrollbar-thumb": {
      background: "#f4ce2c",
    },

    "& ::-moz-scrollbar": {
      width: '10px',
      scrollbarWidth: 'thin',
    },

    "& ::-moz-scrollbar-track": {
      background: '#00256f',
    },

    "& ::-moz-scrollbar-thumb": {
      background: "#f4ce2c",
    },
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
    '& th': {
      fontWeight: 700,
    }
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
  cellWidth: {
    width: "25%",
  },
  toolBar: {
    ...theme.mixins.toolbar,
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },
  dropZone: {
    color: "black !important",
  },

  videoAppBar: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.common.black,
  },
  switchButton: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.light,
    },

    "& .MuiSwitch-colorSecondary": {},
  },
  pdfContainer: {
    marginTop: "none",
  },

  pdfContainer2: {
    width: "200em",
  },
  titlebar: {
    //  height: "6px",
    backgroundColor: theme.palette.common.ltgrey,
  },
  myDropZone: {
    "& .MuiDialogContent-root": {
      scrollbarWidth: "none",
    },
  },

  pdfScroll: {
    overflowY: "auto",

    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
    "& .MuiDialogContent-root": {
      minHeight: '12em',
    }
  },
  iconButton: {
    padding: '6px',
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    fontSize: "0.85rem",
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
    marginBottom: '0.7em',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddSmartExam(props) {
  const theme = useTheme();
  const classes = useStyle();
  const [subject, setSubject] = React.useState([]);
  const [chapter, setChapter] = React.useState([]);
  const [identifier, setIdentifier] = React.useState([]);
  const [loader, setLoader] = useState(false);
  const [smartExams, setSmartExams] = useState(props);
  const [selectSubject, setSelectSubject] = React.useState([]);
  const [selectChapter, setSelectChapter] = React.useState([]);
  const [selectTypology, setSelectTypology] = useState([]);
  const [questionType, setQuestionType] = React.useState([]);
  const [questionTypeSelect, setQuestionTypeSelect] = React.useState([]);
  const [difficultyLevel, setDifficultyLevel] = React.useState([]);
  const [importantRating, setImportantRating] = React.useState([]);
  const [typology, setTypology] = useState([]);
  const [customIdentifier, setCustomIdentifier] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllQType, setSelectAllQType] = useState(false);
  const [selectTypologyAll, setSelectTypologyAll] = useState(false);
  const [selectAllDificulty, setSelectAllDificulty] = useState(false);
  const [selectAllimpRating, setSelectAllimpRating] = useState(false);
  const [displayFullscreen, setDisplayFullscreen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [hrefData, setHrefData] = useState();


  // -------- Question Type, Typologies Data for api Request --------
  const [typologiesData, setTypologiesData] = React.useState([]);
  const [questionTypeData, setQuestionTypeData] = React.useState([]);

  const [difficultyVal, setDifficultyVal] = React.useState([]);
  const [impRatingIdVal, setImpRatingIdVal] = React.useState([]);
  const [noQuestionVal, setNoQuestionVal] = React.useState("");

  const [backLoader, setBackLoader] = useState(false);
  const [reload, setReload] = useState(false);
  const [disable, setDisable] = useState(true);
  const [message, setMessage] = useState("");
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");


  const [showDownload, setShowDownload] = useState(false);
  const [questionUrl, setQuestionUrl] = useState("");

  const [examDataView, setExamDataView] = useState([]);
  const [examIndex, setExamIndex] = React.useState(null);

  useEffect(() => {
    if (props.smartExamPopupType == "Add") {
      let subjects = JSON.parse(window.localStorage.getItem("users") || "[]");
      let subdetails = [];
      subjects && subjects.map((item, index) => {
        subdetails.push({
          subjectId: item.subjectId,
          subjectName: item.subjectName,
          secSubMappingId: item.secSubSylMappingId,
        })
      })
      setSubject(subdetails);
    }

    if (props.smartExamPopupType == "View") {
      try {
        fetch(
          url,
          makeReqBody("SCHOOL_GET_SUBJECT_LIST_FOR_SE", {
            date: moment(props.dateforSub).format("YYYY-MM-DD"),
            studentInstanceId: props.studentInstanceId != "" ? props.studentInstanceId : window.localStorage.studentInstanceId,
            subjectId: window.localStorage.teacherType === "Subject" ? window.localStorage.subjectId : "",
          })
        )
          .then((response) => response.json())
          .then((data) => {
            let subdetails = [];
            data.respData[0].respMsg.result && data.respData[0].respMsg.result.map((item, index) => {
              subdetails.push({
                subjectId: item.subjectId,
                subjectName: item.subjectName,
              })
            })
            setSubject(subdetails);
          })
          .catch((data) => {
            console.error(data);
          });
      } catch (error) {
        console.error(error);
      }
    }

  }, [props.smartExamPopupType == "Add", props.smartExamPopupType == "View", props.dateforSub]);

  useEffect(() => {
    if (props.smartExamPopupType == "Add") {
      try {
        fetch(
          url,
          makeReqBody("SCHOOL_GET_ALL_CHAPTER_SECTION", {
            admin: false,
            sectionId: window.localStorage.sectionId,
            subjectId: selectSubject.subjectId,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            let mychapters = [];
            data.respData[0].respMsg.result &&
              data.respData[0].respMsg.result.map((item, index) => {
                mychapters.push({
                  "chapterName": item.chapterName,
                  "chapterId": item.chapterId,
                  "chapterSource": item.source,
                });
              });
            setSelectChapter([]);
            setSelectTypology([]);
            setQuestionTypeSelect([]);
            setQuestionTypeData([]);
            setTypologiesData([]);
            setChapter(mychapters);
            console.log(mychapters);
          })
          .catch((data) => {
            console.error(data);
          });
      } catch (error) {
        console.error(error);
      }
    }

    if (props.smartExamPopupType == "View") {
      try {
        setBackLoader(true);
        fetch(
          url,
          makeReqBody("SCHOOL_GET_SMART_EXAM", {
            date: moment(props.dateforSub).format("YYYY-MM-DD"),
            studentInstanceId: props.studentInstanceId != "" ? props.studentInstanceId : window.localStorage.studentInstanceId,
            roleId: window.localStorage.roleId,
            smartExamId: 0,
            subjectId: selectSubject.subjectId,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            setExamDataView(data.respData[0].respMsg.result);
            console.log(examDataView);
            setBackLoader(false);
          })
          .catch((data) => {
            console.error(data);
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, [selectSubject, reload]);

  useEffect(() => {
    if (props.smartExamPopupType == "Add") {
      try {
        fetch(
          url,
          makeReqBody("SCHOOL_GET_TYPOLOGY_AND_QUESTYPE_LIST", {
            categoryName: "Typology",
            chapters: selectChapter,
            sectionId: window.localStorage.sectionId,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            setTypology([]);
            setTypology(data.respData[0].respMsg.result);
          })
          .catch((data) => {
            console.error(data);
          });
      } catch (error) {
        console.error(error);
      }
    }

  }, [selectChapter]);


  useEffect(() => {
    if (props.smartExamPopupType == "View" && examIndex != "") {
      setTypology([]);
      setTypology(examDataView[examIndex - 1].typologyRes);
    }
  }, [examIndex]);

  useEffect(() => {
    if (props.smartExamPopupType == "View" && examIndex != "") {
      setChapter([]);
      setChapter(examDataView[examIndex - 1].chapterRes);
    }
  }, [examIndex]);

  useEffect(() => {
    if (props.smartExamPopupType == "Add") {
      try {
        fetch(
          url,
          makeReqBody("SCHOOL_GET_TYPOLOGY_AND_QUESTYPE_LIST", {
            categoryName: "QuestionType",
            chapters: selectChapter,
            sectionId: window.localStorage.sectionId,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            setQuestionType([]);
            setQuestionType(data.respData[0].respMsg.result);
          })
          .catch((data) => {
            console.error(data);
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, [selectChapter]);

  useEffect(() => {
    if (props.smartExamPopupType == "View" && examIndex != "") {
      setQuestionType([]);
      setQuestionType(examDataView[examIndex - 1].quesTypeRes);
    }
  }, [examIndex]);

  useEffect(() => {
    if (props.smartExamPopupType == "View" && examIndex != "") {
      setIdentifier([]);
      setIdentifier(examDataView[examIndex - 1].identifierRes);
    }
  }, [examIndex]);

  useEffect(() => {
    if (props.smartExamPopupType == "Add" && selectSubject != "" && selectChapter.length != 0 && questionTypeSelect.length != 0 && selectTypology.length != 0) {
      setDifficultyVal([]);
      setImpRatingIdVal([]);
      setNoQuestionVal("");

      let questionTypeData = questionTypeSelect.map(item => item.subCategoryId);
      let typologiesData = selectTypology.map(item => item.subCategoryId)
      try {
        fetch(
          url,
          makeReqBody("SCHOOL_GET_IDENTIFIER_FOR_SE", {
            chapters: selectChapter,
            questTypes: questionTypeData,
            typologies: typologiesData,
            sectionId: window.localStorage.sectionId,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            let list = [];
            let listIdentifier = [];
            data.respData[0].respMsg.result.map((item) => {
              list.push({
                difficultyId: [],
                identifierId: item.identifierId,
                identifierName: item.identifierName,
                importantRatingId: [],
                noOfQuestions: null,
                difficulty: item.difficulty,
                impRating: item.impRating,
              });

              listIdentifier.push({
                difficultyId: null,
                identifierId: item.identifierId,
                identifierName: item.identifierName,
                importantRatingId: null,
                noOfQuestions: null,
              });
            })
            setCustomIdentifier(list);
            console.log("Data 1------------", customIdentifier);
            setIdentifier(listIdentifier);
            console.log("Data 2 ----------", identifier);
            setDisable(false);
          })
          .catch((data) => {
            console.error(data);
          });
      } catch (error) {
        console.error(error);
      }
    }
    else {
      setCustomIdentifier([]);
      setIdentifier([]);
      console.log(selectSubject);
      console.log(selectChapter.length);
      console.log(selectTypology.length);
      console.log("QT", questionTypeSelect.length);
    }

  }, [selectSubject, selectChapter, questionTypeSelect, selectTypology]);



  const addDifficulty = (idx, e) => {
    const value = e.target.value;
    const allSelect = customIdentifier[idx].difficulty && customIdentifier[idx].difficulty.map(item => item);
    const allSelectIds = customIdentifier[idx].difficulty && customIdentifier[idx].difficulty.map(item => item.difficultyId);
    if (value[value.length - 1] === "all") {
      customIdentifier[idx].difficultyId = customIdentifier[idx].difficultyId.length === customIdentifier[idx].difficulty.length ? [] : allSelect;
      let selectData = customIdentifier[idx].difficultyId.length === customIdentifier[idx].difficulty.length ? allSelectIds : [];
      let values = [...identifier];
      values[idx].difficultyId = selectData.join(",");
      setIdentifier(values);
      return;
    }
    setSelectAllDificulty(false);
    let selectData = e.target.value;
    let values = [...identifier];
    values[idx].difficultyId = selectData.map(item => item.difficultyId).join(",");
    setIdentifier(values);
    customIdentifier[idx].difficultyId = selectData;
  }

  const addimpRatingId = (idx, e) => {
    const value = e.target.value;
    const allSelect = customIdentifier[idx].impRating && customIdentifier[idx].impRating.map(item => item);
    const allSelectIds = customIdentifier[idx].impRating && customIdentifier[idx].impRating.map(item => item.importantRatingId);
    if (value[value.length - 1] === "all") {
      customIdentifier[idx].importantRatingId = customIdentifier[idx].importantRatingId.length === customIdentifier[idx].impRating.length ? [] : allSelect;
      let selectData = customIdentifier[idx].importantRatingId.length === customIdentifier[idx].impRating.length ? allSelectIds : [];
      let values = [...identifier];
      values[idx].importantRatingId = selectData.join(",");
      setIdentifier(values);
      return;
    }
    setSelectAllimpRating(false);
    let selectData = e.target.value;
    let values = [...identifier];
    values[idx].importantRatingId = selectData.map(item => item.importantRatingId).join(",");
    setIdentifier(values);
    customIdentifier[idx].importantRatingId = selectData
    console.log(importantRating);
  }

  const addNoQuestion = (idx, e) => {
    console.log(e.target.value);
    let values = [...identifier];
    values[idx].noOfQuestions = e.target.value;
    setIdentifier(values);
    console.log(identifier);
    setNoQuestionVal(e.target.value);
  }

  const addExam = () => {
    console.log(identifier);
    let questionTypeData = questionTypeSelect.map(item => item.subCategoryId);
    let typologiesData = selectTypology.map(item => item.subCategoryId);
    console.log(questionTypeData);

    debugger;
    if (identifier.filter(item => item.noOfQuestions >= 51).length > 0) {
      setMessage("You can select only question count between 1 to 50 for each identifier");
      setStatus("error")
      setSnack(true);
      return false;
    }
    if (identifier.filter(item => item.noOfQuestions === 0).length > 0) {
      setMessage("You can select only question count between 1 to 50 for each identifier");
      setStatus("error")
      setSnack(true);
      return false;
    }

    if (identifier.filter(item => item.noOfQuestions === "" || item.noOfQuestions === null).length == customIdentifier.length) {
      setMessage("Atleast one identifier is required");
      setStatus("error")
      setSnack(true);
      return false;
    }

    for (let i = 0; i < identifier.length; i++) {
      if (identifier[i].noOfQuestions > 0 && identifier[i].difficultyId == null || identifier[i].noOfQuestions > 0 && identifier[i].difficultyId == "") {
        setMessage(`Please select Difficulty Level for ${identifier[i].identifierName}.`);
        setStatus("error")
        setSnack(true);
        return false;
      }


      if (identifier[i].noOfQuestions > 0 && identifier[i].importantRatingId == null || identifier[i].noOfQuestions > 0 && identifier[i].importantRatingId == "") {
        setMessage(`Please select Importance Rating for ${identifier[i].identifierName}.`);
        setStatus("error")
        setSnack(true);
        return false;
      }
    }
    try {
      setBackLoader(true);
      fetch(
        url,
        makeReqBody("SCHOOL_CREATE_SMART_EXAM", {
          loginUserId: window.localStorage.userId,
          branchId: window.localStorage.branchId,
          secSubMappingId: selectSubject.secSubMappingId,
          sectionId: window.localStorage.sectionId,
          studentInstanceId: window.localStorage.studentInstanceId,
          subjectId: selectSubject.subjectId,
          chaptersReq: selectChapter,
          questTypeReq: questionTypeData,
          typologyReq: typologiesData,
          identifierReq: identifier,
          subjectName: selectSubject.subjectName,
          studentName: window.localStorage.name,
        })
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.respData[0].respMsg.status.toLowerCase() == "success") {
            setBackLoader(false);
            setMessage(data.respData[0].respMsg.message);
            setStatus("success")
            setSnack(true);
            setQuestionUrl(data.respData[0].respMsg.result.questionURL);
            setShowDownload(true);
            props.refresh();
          }

        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.error(error);
    }
  }

  const clearDataView = () => {
    setSelectSubject("");
    setChapter([]);
    setQuestionType([]);
    // setQuestionType([]);
    setIdentifier([]);
    setExamIndex("");
    setShowDownload(false);
    setSelectTypology([]);
    setSelectChapter([]);
    setQuestionTypeSelect([]);
    setCustomIdentifier([]);
  }


  const handlePdfOpen = (e, href) => {
    setOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };

  const handlepdfClose = (event) => {
    setOpen(false);
    window.localStorage.removeItem("idleDisable");
  };
  const toggleView = (event) => {
    setDisplayFullscreen(!displayFullscreen);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };




  useEffect(() => {
    if (props.smartExams == false || props.smartExamPopupType == "View") {
      clearDataView();
    }

  }, [props.smartExams == false]);

  return (
    <React.Fragment>
      <Dialog
        open={props.smartExams}
        onClose={props.closeSmartExamsPopup}
        aria-labelledby="customized-dialog-title"
        className={classes.dialogContainer}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={props.closeSmartExamsPopup}
        >
          {props.smartExamPopupType == "Add" ? "Add Smart Exam" : props.smartExamPopupType == "View" ? "View Smart Exam" : ""}
        </DialogTitle>
        <DialogContent
        // style={{ scrollbarWidth: "none" }}
        >
          <Grid
            container
            direction="row"
            spacing={2}
          >
            <Grid item md={3}>
              <TextField
                id="subject"
                label="Subject*"
                select
                multiple
                name="subjectId"
                variant="outlined"
                fullWidth
                value={selectSubject}
                className={classes.customDropdown}
                onChange={(e) => {
                  clearDataView();
                  setSelectSubject(e.target.value);
                  setReload(!reload);
                  setCustomIdentifier([]);
                }}
              >
                {subject && subject.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item.subjectName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {props.smartExamPopupType == "View" &&
              <Grid item md={3}>
                <TextField
                  id="smartExamId"
                  label="Smart Exam Id"
                  select
                  multiple
                  name="smartExamId"
                  variant="outlined"
                  fullWidth
                  value={examIndex}
                  className={classes.customDropdown}
                  onChange={(e) => {
                    setExamIndex(e.target.value);
                    setShowDownload(true);
                  }}
                  disabled={
                    selectSubject.length == 0
                  }
                >
                  {examDataView && examDataView.map((item, index) => (
                    <MenuItem key={index} value={index + 1}>
                      {item.sExamCode && item.sExamCode}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            }
            {props.smartExamPopupType == "Add" &&
              <>
                <Grid item md={3}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl, classes.customDropdown}>
                    <InputLabel id="demo-simple-select-outlined-label">Chapter *</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      multiple
                      value={selectChapter}
                      label="Chapter *"
                      onChange={(e) => {
                        console.log(e);
                        const value = e.target.value;
                        const allSelect = [];
                        chapter.map((item) => {
                          allSelect.push(item);
                        });
                        if (value[value.length - 1] === "all") {
                          setSelectAll(true);
                          setSelectChapter(selectChapter.length === chapter.length ? [] : allSelect);
                          selectChapter.length === chapter.length ? setSelectAll(false) : setSelectAll(true);
                          setSelectTypology([]);
                          setQuestionTypeSelect([]);
                          setQuestionTypeData([]);
                          setTypologiesData([]);
                          return;
                        }
                        setSelectAll(false);
                        setSelectChapter(e.target.value);
                        setSelectTypology([]);
                        setQuestionTypeSelect([]);
                        setQuestionTypeData([]);
                        setTypologiesData([]);
                      }}
                      renderValue={(selected) =>
                        selected.map(item => item.chapterName).join(", ")
                      }
                      disabled={
                        selectSubject.length == 0
                      }
                      MenuProps={{
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="all" checked={selectChapter.length == chapter.length} disabled={chapter && chapter.length == 0 || chapter == null}>
                        <Checkbox checked={selectChapter.length == chapter.length && chapter.length != 0} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {chapter && chapter.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          <Checkbox checked={selectChapter.indexOf(item) > -1} />
                          <ListItemText primary={item.chapterName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={3}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl, classes.customDropdown}>
                    <InputLabel id="typology-label">Typology *</InputLabel>
                    <Select
                      labelId="typology-label"
                      id="typology"
                      multiple
                      value={selectTypology}
                      label="Typology *"
                      onChange={(e) => {
                        const value = e.target.value;
                        const allSelect = typology && typology.map(item => item);
                        const allSelectIds = typology && typology.map(item => item.subCategoryId);

                        if (value[value.length - 1] === "all") {
                          setSelectTypology(selectTypology && typology && selectTypology.length === typology.length ? [] : allSelect);
                          // setTypologiesData([])
                          setTypologiesData(selectTypology && typology && selectTypology.length === typology.length ? [] : allSelectIds);
                          console.log("Data typologiesData-----------", typologiesData);
                          return;
                        }
                        let storeData = [];
                        let storeVal = e.target.value;
                        setSelectTypology(storeVal);
                        console.log("Data typologiesData-----------", typologiesData);
                      }}
                      renderValue={(selected) => selected.map(item => item.subCategory).join(", ")}
                      MenuProps={{
                        getContentAnchorEl: null,
                      }}
                      disabled={
                        selectChapter && selectChapter.length == 0
                      }
                    >
                      <MenuItem value="all" checked={selectTypology && typology && selectTypology.length == typology.length} disabled={typology && typology.length == 0}>
                        <Checkbox checked={selectTypology && typology && selectTypology.length == typology.length && typology.length != 0} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {typology && typology.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          <Checkbox checked={selectTypology.indexOf(item) > -1} />
                          <ListItemText primary={item.subCategory} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>


                <Grid item md={3}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl, classes.customDropdown}>
                    <InputLabel id="questionType">Question Type *</InputLabel>
                    <Select
                      labelId="questionType"
                      id="questiontype"
                      multiple
                      value={questionTypeSelect}
                      label="Question Type *"
                      onChange={(e) => {
                        const value = e.target.value;
                        const allSelect = questionType && questionType.map(item => item);
                        const allSelectIds = questionType && questionType.map(item => item.subCategoryId);
                        if (value[value.length - 1] === "all") {
                          setSelectAllQType(true);
                          setQuestionTypeSelect(questionTypeSelect && questionTypeSelect.length === questionType.length ? [] : allSelect);
                          setQuestionTypeData(questionTypeSelect && questionTypeSelect.length === questionType.length ? [] : allSelectIds);
                          questionTypeSelect.length === questionType.length ? setSelectAllQType(false) : setSelectAllQType(true);
                          return;
                        }
                        setSelectAllQType(false);
                        let storeDataQT = [];
                        let storeValQT = e.target.value;
                        setQuestionTypeSelect(storeValQT);
                        console.log("Data typologiesData-----------", questionTypeData);
                      }}
                      renderValue={(selected) => selected.map(item => item.subCategory).join(", ")}
                      MenuProps={{
                        getContentAnchorEl: null,
                      }}
                      disabled={
                        selectChapter && selectChapter.length == 0
                      }
                    >
                      <MenuItem value="all" checked={questionTypeSelect && questionType && questionTypeSelect.length == questionType.length} disabled={questionType && questionType.length == 0}>
                        <Checkbox checked={questionTypeSelect && questionType && questionTypeSelect.length == questionType.length && questionType.length != 0} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {questionType && questionType.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          <Checkbox checked={questionTypeSelect.indexOf(item) > -1} />
                          <ListItemText primary={item.subCategory} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            }
            {props.smartExamPopupType == "View" && examIndex != "" &&
              <>
                <Grid item md={12}>
                  <Typography variant="body1" display="block">
                    <Typography variant="caption" component="strong" style={{ fontSize: '1rem', fontWeight: '600', color: theme.palette.common.black, paddingRight: '.3rem' }}>
                      Chapter :
                    </Typography>
                    <Typography component="span" style={{ fontSize: '.8rem' }}>
                      {chapter && chapter.map((item) => (
                        item.chapterName != null ? item.chapterName : ''
                      )).join(", ")}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <Typography variant="body1" display="block">
                    <Typography variant="caption" component="strong" style={{ fontSize: '1rem', fontWeight: '600', color: theme.palette.common.black, paddingRight: '.3rem' }}>
                      Typology :
                    </Typography>
                    <Typography component="span" style={{ fontSize: '.8rem' }}>

                      {typology && typology.map((item) => (
                        item.typologyName != null ? item.typologyName : ''
                      )).join(", ")}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <Typography variant="body1" display="block">
                    <Typography variant="caption" component="strong" style={{ fontSize: '1rem', fontWeight: '600', color: theme.palette.common.black, paddingRight: '.3rem' }}>
                      Question Type :
                    </Typography>
                    <Typography component="span" style={{ fontSize: '.8rem' }}>

                      {questionType.map((item, index) => (
                        item.questionTypeName != null ? item.questionTypeName : ''
                      )).join(", ")}
                    </Typography>
                  </Typography>
                </Grid>
              </>
            }
          </Grid>
          {props.smartExamPopupType == "Add" &&
            <>
              <TableContainer component={Paper} style={{ marginTop: "2rem" }}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell component="th" className={classes.cellWidth}>
                        Identifier Name
                      </TableCell>
                      <TableCell component="th" className={classes.cellWidth}>
                        No. of Question
                      </TableCell>
                      <TableCell component="th" className={classes.cellWidth}>
                        Difficulty Level
                      </TableCell>
                      <TableCell component="th" className={classes.cellWidth}>
                        Importance Rating
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customIdentifier.length != 0 ? customIdentifier.map((item, index) => <TableRow key={index}>
                      <TableCell>
                        {item.identifierName}
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          value={item.noOfQuestions}
                          customInput={TextField}
                          variant="outlined"
                          id={`NoofQuestion${index}`}
                          fullWidth
                          label="No. of Question"
                          size="small"
                          allowNegative={false}
                          inputProps={{ maxlength: "2", }}
                          decimalScale={0}
                          fixedDecimalScale={false}
                          onChange={(e) => addNoQuestion(index, e)}

                        />
                      </TableCell>
                      <TableCell>
                        <FormControl variant="outlined" fullWidth className={classes.formControl, classes.customDropdown, classes.tableDropdown}>
                          <InputLabel id={`difficulty1${index}`}>Difficulty Level *</InputLabel>
                          <Select
                            labelId={`difficulty1${index}`}
                            id={`difficulty${index}`}
                            label="Difficulty Level"
                            multiple
                            value={item.difficultyId}
                            renderValue={(selected) => selected.map(item => item.difficultyName).join(", ")}
                            onChange={(e) => addDifficulty(index, e)}
                            MenuProps={{
                              getContentAnchorEl: null,
                            }}
                          >
                            <MenuItem value="all" checked={item.difficulty && item.difficultyId.length == item.difficulty.length} disabled={item.difficulty && item.difficulty.length == 0 || item.difficulty == null}>
                              <Checkbox checked={item.difficulty && item.difficultyId.length == item.difficulty.length && item.difficulty.length != 0} />
                              <ListItemText primary="Select All" />
                            </MenuItem>
                            {item.difficulty && item.difficulty.map((item1, index) => (
                              <MenuItem key={index} value={item1}>
                                <Checkbox checked={item.difficultyId.indexOf(item1) > -1} />
                                <ListItemText primary={item1.difficultyName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl variant="outlined" fullWidth className={classes.formControl, classes.customDropdown, classes.tableDropdown}>
                          <InputLabel id={`importance$Lab{index}`}>Importance Rating*</InputLabel>
                          <Select
                            labelId={`importanceLab${index}`}
                            id={`importance${index}`}
                            label="Importance Rating"
                            multiple
                            value={item.importantRatingId}
                            renderValue={(selected) => selected.map(item => item.importantRatingId).join(", ")}
                            onChange={(e) => addimpRatingId(index, e)}
                            MenuProps={{
                              getContentAnchorEl: null,
                            }}
                          >
                            <MenuItem value="all" checked={item.impRating && item.importantRatingId.length == item.impRating.length} disabled={item.impRating && item.impRating.length == 0 || item.impRating == null} >
                              <Checkbox checked={item.impRating && item.importantRatingId.length == item.impRating.length && item.impRating.length != 0} />
                              <ListItemText primary="Select All" />
                            </MenuItem>
                            {item.impRating && item.impRating.map((item1, index) => (
                              <MenuItem key={index} value={item1}>
                                <Checkbox checked={item.importantRatingId.indexOf(item1) > -1} />
                                <ListItemText primary={item1.importantRatingId} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>) :
                      <TableRow>
                        <TableCell colSpan={4} style={{ color: 'red' }}>
                          No Record Found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="div" style={{ marginTop: "15px", color: "red", display: "block" }}>
                <Typography variant="strong" style={{ fontWeight: "bold" }}>Note - </Typography><Typography variant="span">System will generate question paper based on number of questions available in the bank</Typography>
              </Typography>
            </>
          }



          {props.smartExamPopupType == "View" && examIndex != "" && <TableContainer component={Paper} style={{ marginTop: "2rem" }}>
            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell component="th" className={classes.cellWidth}>
                    Identifier Name
                  </TableCell>
                  <TableCell component="th" className={classes.cellWidth}>
                    No. of Question
                  </TableCell>
                  <TableCell component="th" className={classes.cellWidth}>
                    Difficulty Level
                  </TableCell>
                  <TableCell component="th" className={classes.cellWidth}>
                    Importance Rating
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {identifier != null && identifier.length != 0 ? identifier.map((item, index) => <TableRow key={index}>
                  <TableCell>
                    {item.identifierName}
                  </TableCell>
                  <TableCell>
                    {props.smartExamPopupType == "View" ?
                      item.noOfQuest
                      : ""}
                  </TableCell>
                  <TableCell>
                    {props.smartExamPopupType == "View" ?
                      item.difficultyTypeName : ''
                    }
                  </TableCell>
                  <TableCell>
                    {props.smartExamPopupType == "View" ?
                      item.importantRating
                      : ""}
                  </TableCell>
                </TableRow>) :
                  <TableRow>
                    <TableCell colSpan={4} style={{ color: 'red' }}>
                      No Record Found
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>}




        </DialogContent>

        <DialogActions>
          {props.smartExamPopupType == "Add" &&
            <>
              <Button
                variant="text"
                color="secondary"
                onClick={props.closeSmartExamsPopup}
              >
                Cancel
              </Button>

              <Button
                disabled={disable || identifier.length == 0}
                variant="contained"
                color="secondary"
                style={{ color: "white" }}
                onClick={addExam}

              >
                {loader === false ? (
                  "Submit"
                ) : (
                  <CircularProgress color={theme.palette.secondary.light} />
                )}
              </Button>
            </>
          }

          {showDownload && <Button
            variant="contained"
            color="secondary"
            component="a"
            style={{ color: "white" }}
            onClick={(e) => handlePdfOpen(e, examDataView.length != 0 && examIndex != "" ? examDataView[examIndex - 1].questionURL : questionUrl != "" ? questionUrl : "")}
            // href={examDataView.length != 0 && examIndex != "" ? examDataView[examIndex - 1].questionURL : questionUrl != "" ? questionUrl : ""}
            target="_blank"
          >
            {loader === false ? (
              "View Question"
            ) : (
              <CircularProgress color={theme.palette.secondary.light} />
            )}
          </Button>}

          {window.localStorage.roleId != "4" && showDownload && <Button
            variant="contained"
            color="secondary"
            component="a"
            style={{ color: "white" }}
            href={examDataView.length != 0 && examIndex != "" ? examDataView[examIndex - 1].answerURL : ""}
            target="_blank"
          >
            {loader === false ? (
              "Download Answer"
            ) : (
              <CircularProgress color={theme.palette.secondary.light} />
            )}
          </Button>}
        </DialogActions>

        <Backdrop className={classes.backdrop} open={backLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snack}
        autoHideDuration={4500}
        onClose={() => setSnack(false)}
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>

      <Dialog
        fullScreen={displayFullscreen}
        open={open}
        onClose={handlepdfClose}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlepdfClose}
              aria-label="close"
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              PDF Viewer
            </Typography>
            <Switch
              checked={checked}
              onChange={toggleChecked}
              onClick={toggleView}
              color="primary"
            />
            <Typography style={{ color: "black" }}>Toggle View</Typography>
            <Tooltip
              title="Download Pdf"
              classes={{
                tooltip: classes.customTooltip,
              }}>
              <IconButton className={classes.iconButton} onClick={() => window.open(hrefData)}>
                <GetAppOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <Grid container direction="column" alignItems="center" className={`${classes.pdfScroll} customPdfScroll`}>
          <Grid item >
            <DisplayPdf data={hrefData} displayFullscreen={displayFullscreen} />
          </Grid>
        </Grid>
      </Dialog>

    </React.Fragment>
  );
}
