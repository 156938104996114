import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import TableMaker from "./TableMaker";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { groupBy } from "lodash";
import { url } from "./store.js";
import { set } from "date-fns";
import moment from "moment";

const inverDirect = {
  asc: "desc",
  desc: "asc",
};

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? "#616161"
        : "transparent",
    minwidth: "30em",
  },
  tableHead: {
    "& .MuiTableCell-head": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.ltgrey
        : theme.palette.common.white,
    width: "100%",
  },
  toolBar: {
    ...theme.mixins.toolbar,
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },

  videoAppBar: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.common.black,
  },
  switchButton: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.light,
    },

    "& .MuiSwitch-colorSecondary": {},
  },
  pdfContainer: {
    marginTop: "none",
  },

  pdfContainer2: {
    width: "200em",
  },
  titlebar: {
    //  height: "6px",
    backgroundColor: theme.palette.common.ltgrey,
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const theme = useTheme();
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function WritingMaterialVerify(props) {
  const classes = useStyle();
  const theme = useTheme();
  const [loader, setLoader] = useState(true);
  const [renderAg, handleReRender] = useState(false);
  const [myrow, setMyRows] = useState([]);
  const headers = [
    "Student",
    "Roll No",

    "Leave Duration",
    "Subject",
    "Status",
    "Action",
  ];

  useEffect(() => {
    setLoader(true);
    const AllLists = [];

    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_LEAVE_LIST_BY_SECTION_ID",
              busiParams: {
                sectionId: window.localStorage.sectionId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let row = [];
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
                //  dataset.labels.push(item.resourceName);
                //  dataset.datasets[0].data.push(
                //parseInt(item.taskPerc.replace("%", ""))
                //    );

                row.push([
                  { title: item.studentName, type: "text" },
                  { title: item.rollNo, type: "text" },
                  {
                    title: `${item.fromDate} to ${item.toDate}`,
                    type: "text",
                    sortDate: moment(item.fromDate, "DD-MM-YYYY")
                      .format("YYYY-MM-DD")
                      .toString(),
                  },
                  {
                    title: item.subject,
                    type: "text",
                  },
                  {
                    title: item.leaveStatus,
                    type: "leaveStatus",
                  },
                  {
                    title: "",
                    type: "leaveVerify",
                    fullDetails: item,
                  },
                ]);
              })
            : row.push([
                {
                  title: "No Data Available",
                  type: "text",
                  fullDetails: "hy",
                },
                { title: "", type: "text" },
                { title: "", type: "text" },
                {
                  title: " ",
                  type: "text",
                },
              ]);

          setMyRows(row);
        })

        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setInterval(() => {
      setLoader(false);
    }, 2000);
  }, [renderAg]);

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        {" "}
        {loader === false ? (
          <TableMaker
            columns={headers}
            rows={myrow}
            renderAg={renderAg}
            handleReRender={handleReRender}
            searchBox={true}
          />
        ) : (
          <CircularProgress color="secondary" />
        )}
      </Grid>
    </React.Fragment>
  );
}
