import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    CircularProgress,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    MenuItem,
    AppBar,
    Toolbar,
    Switch, Tooltip, Accordion, AccordionActions, AccordionSummary, AccordionDetails,

} from "@material-ui/core";
import moment from "moment";
import ExamTabMaker from "../Exam/ExamTabMaker";
import TableMaker from "./TableMaker";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { url, makeReqBody } from "./store.js";
import { useHistory } from "react-router-dom";
import ReScheduleRegularExam from "../Exam/ReScheduleRegularExam";
import PublishRegularExam from "../Exam/PublishRegularExam";



const useStyle = makeStyles((theme) => ({
    subContainer: {
        ...theme.typography.subContainer,
    },
    table: {
        backgroundColor: "transparent",
        minwidth: "30em",
    },
    tableHead: {
        backgroundColor: theme.palette.common.white,
        width: "100%",
    },

    buttonStyle: {
        padding: "0.5em",
        borderRadius: 5,
    },
    dialogContainer: {
        "& .MuiDialog-paper": {
            width: "60em",
        },
        "& MuiDialog-paperWidthSm": {
            maxWidth: "100em",
        },
    },
    myDropZone: {
        color: "black !important",
        "& .MuiDialogContent-root": {
            scrollbarWidth: "none",
        },
        "& .MuiTypography-h6": {
            color: "black !important",
        },

    },


    subContainer: {
        ...theme.typography.subContainer,
    },
    table: {
        backgroundColor:
            theme.typography.myTheme.themeName === "Night"
                ? "#616161"
                : "transparent",
        minwidth: "30em",
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        fontSize: "0.85rem",
    },
    tableHead: {
        "& .MuiTableCell-head": {
            color:
                theme.typography.myTheme.themeName === "Night"
                    ? theme.palette.common.white
                    : theme.palette.common.black,
        },
        backgroundColor:
            theme.typography.myTheme.themeName === "Night"
                ? theme.palette.common.ltgrey
                : theme.palette.common.white,
        width: "100%",
    },
    toolBar: {
        ...theme.mixins.toolbar,
    },

    buttonStyle: {
        padding: "0.5em",
        borderRadius: 5,
    },
    appBar: {
        position: "sticky",
        backgroundColor: theme.palette.common.ltgrey,
    },
    myDropZone: {
        color: "black !important",
        "& .MuiDialogContent-root": {
            scrollbarWidth: "none",
        },
        "& .MuiTypography-h6": {
            color: "black !important",
        },
        "& .MuiTypography-h5": {
            color: "black !important",
        },

    },

    videoAppBar: {
        backgroundColor: theme.palette.common.ltgrey,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: theme.palette.common.black,
    },
    switchButton: {
        "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.secondary.dark,
        },

        "& .MuiSwitch-track": {
            backgroundColor: theme.palette.common.white,
        },

        "& .MuiSwitch-colorSecondary.Mui-checked": {
            color: theme.palette.secondary.light,
        },

        "& .MuiSwitch-colorSecondary": {},
    },
    pdfContainer: {
        marginTop: "none",
    },

    pdfContainer2: {
        width: "200em",
    },
    titlebar: {
        //  height: "6px",
        backgroundColor: theme.palette.common.ltgrey,
    },
    pdfScroll: {
        overflowY: "auto",

        "& .MuiDialog-paper": {
            width: "60em",
        },
        "& MuiDialog-paperWidthSm": {
            maxWidth: "100em",
        },
        "& .MuiDialogContent-root": {
            minHeight: '12em',
        }
    },
}));


const styles = (theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.common.black,
    },
    DialogHeader: {
        backgroundColor: theme.palette.common.ltgrey,
    },
    dropZone: {
        color: "black !important",
    },
    iconButton: {
        padding: '6px',
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        fontSize: "0.85rem",
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    const theme = useTheme();
    return (
        <MuiDialogTitle
            disableTypography
            {...other}
            className={classes.DialogHeader}
        >
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon style={{ color: theme.palette.common.tabFont }} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const MyDataMaker = (props) => {
    const classes = useStyle();
    const theme = useTheme();
    const [rows, setRows] = useState([]);
    const [loader, setLoader] = useState(false);
    const [snack, setSnack] = useState(false);
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const [reRender, setRerender] = useState(false);
    const history = useHistory();
    const [passData, setPassData] = useState({});
    const [selectedEpId, setSelectedEpId] = useState("")
    const [reUpload, setReUpload] = useState(false);
    const [reScheduleSmartExamData, setReScheduleSmartExamData] = useState(null);
    const [openPublishDialog, setOpenPublishDialog] = useState(false)
    const [publishRegularData, setPublishRegularData] = useState(null);
    const [examPublished, setExamPublished] = useState(false);
    const [openQuesDailog, setOpenQuestionDailog] = useState(false);
    const [examQuesList, setExamQuesList] = useState([])





    useEffect(() => {
        setLoader(true);
        try {
            fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                    requestList: [{
                        isEncrypt: false,
                        transactionId: "0000001438241244",
                        busiCode: "SCHOOL_GET_SMART_QBT_BY_SECTIONID",
                        busiParams: {
                            examTermId: props.examTermId,
                            sectionId: parseInt(window.localStorage.sectionId),
                            subjectId: window.localStorage.teacherType === "Class" ? 0 : parseInt(window.localStorage.subjectId)
                        },
                    }],
                    pubInfo: {
                        appId: "appId",
                        sessionId: window.localStorage.sessionId,
                        version: "21",
                        timestamp: "20180314175419",
                        lang: "en_US",
                        userId: "1000016614",
                        serviceId: "7021150585",
                        circleId: "MU",
                        ncsroute: "WE015",
                        customerId: "1000016614",
                        osType: "windows",
                    },
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    let row = [];
                    data.respData[0].respMsg.result.length > 0 &&
                        data.respData[0].respMsg.result.map((item, index) => {
                            row.push([
                                {
                                    title: `${item.smartQbtExamName}`,
                                    epId: item.epId,
                                    type: "radio",
                                    examId: item.examId,
                                    examTermId: item.examTermId,
                                    subjectId: item.subjectId,
                                    actionOfExam: item.actionOfExam,
                                    smartStatus: item.smartStatus
                                },

                                { title: item.examName, type: "text" },
                                {
                                    title: props.dateFromatFunc(
                                        item.startDateTime,
                                        item.endDateTime
                                    ),
                                    startDate: item.startDateTime,
                                    endDate: item.endDateTime,
                                    type: "schedule",
                                },
                                {
                                    title: item.totalMarks,
                                    type: "text",
                                },
                                {
                                    title: item.passingMarks,
                                    type: "text",
                                },
                                {
                                    fullDetails: item,
                                    type: "viewStuExamQues",
                                },
                                {
                                    title: item.smartStatus,
                                    subjectTeacherResponse: item.subjectTeacherResponse,
                                    fullDetails: item,
                                    type: "smartStatus",
                                    firstIndex: props.firstIndex
                                },
                            ]);
                        })


                    setRows(row);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                })

                .catch((data) => {
                    console.error(data);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                });
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }, [reRender]);


    const headers = [
        "Exam Name",
        "Exam Pattern",
        "Schedule",
        "Total Marks",
        "Passing Marks",
        "Preview",
        "Status",
    ];

    const reScheduleSmartExam = (data) => {
        setReUpload(true);
        setReScheduleSmartExamData(data);
    }

    const closeReScheduleExam = () => {
        setReUpload(false);
    }

    const successReScheduleExam = () => {
        setRerender(!reRender);
        setReUpload(false);
    }

    const publishRegularExam = (data) => {
        setPublishRegularData(data);
        setOpenPublishDialog(true);
        console.log("x", data)
    }

    const closePublishExamRegular = () => {
        setOpenPublishDialog(false);
    }
    const closePublishExamRegularSuccess = () => {
        setRerender(!reRender);
        setOpenPublishDialog(false);
    }



    const handleGetEpIdData = (e, data) => {
        setPassData(data);
        setSelectedEpId(e.target.value)
        window.localStorage.setItem("passData", JSON.stringify(data));
    };


    const handleOpenQuestionDailog = (data) => {
        fetch(url, makeReqBody("SCHOOL_SMART_QBT_GET_SUBJECT_QUES_EXAM", {
            branchId: data.branchId,
            examId: data.examId,
            examTermId: data.examTermId,
            sectionId: data.sectionId,
            subjectId: window.localStorage.teacherType === "Class" ? 0 : window.localStorage.subjectId
        })).then(resp => resp.json())
            .then(data => {
                setExamQuesList(data.respData[0].respMsg.result)
                setOpenQuestionDailog(true)
            }).catch(error => {
                console.error(error)
            })
    };


    // const handlePubjishResult = () => {
    //     fetch(url, makeReqBody("SCHOOL_SMART_QBT_PUBLISHED_RESULT_OF_EXAM", {
    //         examTermId: props.examTermId,
    //     })).then(resp => resp.json())
    //         .then(data => {
    //             if (data.respData[0].message === "success") {
    //                 setSnack(true);
    //                 setStatus("success");
    //                 setMessage("Result Published Successfully")
    //             } else {
    //                 setSnack(true);
    //                 setStatus("error");
    //                 setMessage("Something went wrong")
    //             }
    //             setRerender(!reRender);

    //         })
    // };


    return (
        <React.Fragment>

            {window.localStorage.teacherType === "Subject" &&
                <>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            history.push("/ExamMode/EExam/QuestionMapping");
                            window.localStorage.setItem("examTermId", props.examTermId)
                        }}
                        style={{ color: "white", marginLeft: "1em", marginBottom: "0.5em" }}
                        disabled={selectedEpId === ""}
                    >
                        Question Mapping
                    </Button>

                </>
            }

            {loader === true ? (
                <CircularProgress color="secondary" />
            ) : (
                <>
                    {window.localStorage.teacherType === "Class" && props.firstIndex !== 0 &&
                        <>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={(e) => {
                                    props.handleOpenDialog(e, props.examTermId);
                                }}
                                style={{ color: "white", marginBottom: "0.5em" }}
                            >
                                Create Master Exam
                            </Button>
                        </>
                    }
                    {window.localStorage.teacherType === "Class" && window.localStorage.variantId === "7" && props.firstIndex === 0 && rows.length < 1 &&
                        <>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={(e) => {
                                    props.handleOpenDialog(e, props.examTermId);
                                }}
                                style={{ color: "white", marginBottom: "0.5em" }}
                            >
                                Create Master Exam
                            </Button>
                        </>
                    }
                    {window.localStorage.teacherType === "Class" && window.localStorage.variantId !== "7" && props.firstIndex === 0 &&
                        <>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={(e) => {
                                    props.handleOpenDialog(e, props.examTermId);
                                }}
                                style={{ color: "white", marginBottom: "0.5em" }}
                            >
                                Create Master Exam
                            </Button>
                        </>
                    }
                    <TableMaker
                        columns={headers}
                        rows={rows}
                        toReload={props.toReload}
                        setToReLoad={props.setToReLoad}
                        handleGetEpIdData={handleGetEpIdData}
                        selectedEpId={selectedEpId}
                        reScheduleSmartExam={reScheduleSmartExam}
                        publishRegularExam={publishRegularExam}
                        handleOpenQuestionDailog={handleOpenQuestionDailog}
                    />
                </>
            )}


            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => setSnack(false)}
            >
                <Alert severity={status}>{message}</Alert>
            </Snackbar>


            <ReScheduleRegularExam
                openReScheduleExam={reUpload}
                closeReScheduleExam={closeReScheduleExam}
                examId={reScheduleSmartExamData && reScheduleSmartExamData.examId}
                totalTime={reScheduleSmartExamData && reScheduleSmartExamData.totalTime}
                reScheduleSmartExamData={reScheduleSmartExamData}
                successReScheduleExam={successReScheduleExam}
                examType={3}
                style={{ display: "none" }}
            />


            <PublishRegularExam
                publishExamRegular={openPublishDialog}
                closePublishExamRegular={closePublishExamRegular}
                examId={publishRegularData && publishRegularData.examId}
                publishRegularData={publishRegularData}
                closePublishExamRegularSuccess={closePublishExamRegularSuccess}
            />

            <Dialog
                open={openQuesDailog}
                onClose={() => setOpenQuestionDailog(false)}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
                className="customPdfScroll"
            >
                <DialogTitle id="form-dialog-title">Questions List</DialogTitle>
                <DialogContent className="customPdfScroll">
                    <Grid item style={{ padding: "0 15px" }}>
                        <ul>
                            {examQuesList && examQuesList.map((x, index) => (
                                <Grid item key={index}>
                                    <Typography variant="body1">{x.subjectName}</Typography>
                                    <Grid item container>
                                        {x.quetList.map((item, index) => (
                                            <li key={index} style={{ listStyle: "decimal", marginBottom: "8px", width: "100%" }}>
                                                <Grid item container direction="row" justify="space-between">
                                                    <Typography variant="body1">Question : </Typography>
                                                    <Typography variant="body1">{item.identifierName}</Typography>
                                                </Grid>
                                                <Grid item container direction="column">
                                                    <Grid item>
                                                        <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(item.question) }}></div>
                                                    </Grid>
                                                    {item.identifierId === 18 || item.identifierId === 1 || item.identifierId === 19 || item.identifierId === 20 ?
                                                        <Grid item>
                                                            <ul style={{ marginLeft: "1em" }}>
                                                                {item.option.map((elem, idx) => (
                                                                    <li key={idx} style={{ listStyle: "upper-alpha", marginBottom: "5px" }}>
                                                                        <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(elem) }}></div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Grid>
                                                        : item.identifierId === 16 ?
                                                            <Grid item>
                                                                <ul style={{ marginLeft: "1em" }}>
                                                                    {item.mcq.map((elem, idx) => (
                                                                        <li key={idx}>
                                                                            <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(elem.option) }}></div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </Grid>
                                                            : null
                                                    }
                                                </Grid>
                                            </li>
                                        ))}
                                    </Grid>
                                </Grid>
                            ))}
                        </ul>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenQuestionDailog(false)}
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", background: '#d95350' }}
                    > Close</Button>
                </DialogActions>
            </Dialog>


        </React.Fragment>
    );
};

export default function SmartQBT(props) {
    const classes = useStyle();
    const theme = useTheme();
    const [toReload, setToReLoad] = useState(false)
    const [loader, setLoader] = useState(true);
    const [smartDailog, setSmartDialog] = useState(false);
    const [snack, setSnack] = useState(false);
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [eExam, setEExam] = useState([]);
    const [tabHeadings, setTabHeadings] = useState([]);
    const [viewExamPatternDailog, setViewExamPatternDailog] = useState(false)
    const [viewPatternData, setViewPatternData] = useState([])
    const [createSmartExam, setCreateSmartExam] = useState(null);
    const [examTermId, setExamTermId] = useState(0)
    const [isQuestionSmartQBT, setIsQuestionSmartQBT] = useState(false);
    const [expanded, setExpanded] = useState(0);
    const [smartQbtExamName, setSmartQbtExamName] = useState("")


    const handleQuestinMapSmartQBT = () => {
        setIsQuestionSmartQBT(true)
    }
    const [patternName, setPatternName] = useState();
    const [examDate, setExamDate] = useState(null);

    const [myLoader, setMyLoader] = useState(false);

    const month = {
        "01": "JAN",
        "02": "FEB",
        "03": "MAR",
        "04": "APR",
        "05": "MAY",
        "06": "JUN",
        "07": "JUL",
        "08": "AUG",
        "09": "SEP",
        10: "OCT",
        11: "NOV",
        12: "DEC",
    };

    const dateFromatFunc = (startDateTime, endDateTime) => {

        const sd = startDateTime.split(" ")[0];
        const st = startDateTime.split(" ")[1];
        const et = endDateTime.split(" ")[1];

        const sdSplit = sd.split("-");
        const sy = sdSplit[0];
        const sm = month[sdSplit[1]];
        const sday = sdSplit[2];

        const stSplit = st.split(":");
        const sh = stSplit[0];
        const smin = stSplit[1];

        let startAmPm = "am";
        if (parseInt(sh) >= 12) {
            startAmPm = "pm";
        }

        const etSplit = et.split(":");
        const eh = etSplit[0];
        const emin = etSplit[1];

        let endAmPm = "am";
        if (parseInt(sh) >= 12) {
            endAmPm = "pm";
        }

        let duration =
            sm +
            " " +
            sday +
            "," +
            " " +
            sy +
            "," +
            " " +
            sh +
            ":" +
            smin +
            " " +
            startAmPm +
            " " +
            "to" +
            " " +
            eh +
            ":" +
            emin +
            " " +
            endAmPm;

        return duration;
    };



    useEffect(() => {
        setLoader(true);
        const getData = async () => {
            let termData = [];
            let subjectName = [];
            await fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                    requestList: [
                        {
                            isEncrypt: false,
                            transactionId: "0000001438241244",
                            busiCode: "SCHOOL_GET_EXAM_TERMS_FOR_BRANCH",
                            busiParams: {
                                id: window.localStorage.branchId,
                                sectionId: window.localStorage.sectionId
                            },
                        },
                    ],
                    pubInfo: {
                        appId: "appId",
                        sessionId: window.localStorage.sessionId,
                        version: "21",
                        timestamp: "20180314175419",
                        lang: "en_US",
                        userId: "1000016614",
                        serviceId: "7021150585",
                        circleId: "MU",
                        ncsroute: "WE015",
                        customerId: "1000016614",
                        osType: "windows",
                    },
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    data.respData[0].respMsg.result.map((item, index1) => {
                        subjectName.push(item.name);
                        termData.push(item);
                    });

                    setTabHeadings(subjectName);
                })
                .catch((data) => {
                    console.log("sorry something went wrong");
                });
            try {
                let AllLists = [];

                termData.map((item, index) => {
                    let temList = (
                        <MyDataMaker
                            key={index}
                            firstIndex={index}
                            handleOpenDialog={handleOpenDialog}
                            examTermId={item.examTermId}
                            dateFromatFunc={dateFromatFunc}
                            toReload={toReload}
                            QuestionMapSmartQBT={handleQuestinMapSmartQBT}
                        />
                    );

                    AllLists.push(temList);
                });
                setEExam(AllLists);
            } catch (error) {
                console.error(error);
            }
        };
        getData();

        setInterval(() => {
            setLoader(false);
        }, 500);
    }, [toReload]);



    useEffect(() => {
        setMyLoader(true);
        let patternDataById = [];
        if (createSmartExam !== null) {
            try {
                fetch(url, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                    body: JSON.stringify({
                        requestList: [{
                            isEncrypt: false,
                            transactionId: "0000001438241244",
                            busiCode: createSmartExam.branchepId !== 0 ? "SCHOOL_EXAM_PATTERN_GETBYID_BRANCH_EXAM_PATTERN" : "SCHOOL_EXAM_PATTERN_GET_BY_ID",
                            busiParams: {
                                examPatternId: createSmartExam.branchepId !== 0 ? createSmartExam.branchepId : createSmartExam.masterepId
                            }
                        }],
                        pubInfo: {
                            appId: "appId",
                            sessionId: window.localStorage.sessionId,
                            version: "21",
                            timestamp: "20180314175419",
                            lang: "en_US",
                            userId: "1000016614",
                            serviceId: "7021150585",
                            circleId: "MU",
                            ncsroute: "WE015",
                            customerId: "1000016614",
                            osType: "windows",
                        },
                    }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        data.respData[0].respMsg.result &&
                            data.respData[0].respMsg.result.map((item) => {
                                patternDataById.push(item);
                            });
                        console.log("patternDataById", patternDataById);
                        setMyLoader(false);
                    });
                setViewPatternData(patternDataById);

            } catch (error) {
                console.error(error);
            }
        }

    }, [createSmartExam])


    const handleOpenDialog = async (e, id) => {
        setSmartDialog(true);
        setExamTermId(id);
        setFromDate(null);
        setToDate(null);
        setCreateSmartExam(null)

        setMyLoader(true);
        fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                requestList: [{
                    isEncrypt: false,
                    transactionId: "0000001438241244",
                    busiCode: "SCHOOL_EXAM_PATTERN_BY_SECTIONID",
                    "busiParams": {
                        sectionId: window.localStorage.sectionId,
                        status: true,
                    }
                }],
                pubInfo: {
                    appId: "appId",
                    sessionId: window.localStorage.sessionId,
                    version: "21",
                    timestamp: "20180314175419",
                    lang: "en_US",
                    userId: "1000016614",
                    serviceId: "7021150585",
                    circleId: "MU",
                    ncsroute: "WE015",
                    customerId: "1000016614",
                    osType: "windows",
                },
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                setPatternName(data.respData[0].respMsg.result)
            }).catch((data) => {
                console.error(data);
                setTimeout(() => {
                    setMyLoader(false);
                }, 1000);
            })
    };

    const handleCreateSmartExam = () => {
        if (Date.parse(fromDate) < Date.parse(new Date()) || Date.parse(examDate) < Date.parse(new Date())) {
            setMessage("Exam time cannot be less than system time.");
            setStatus("error");
            setSnack(true);
            return false;
        } else if (smartQbtExamName === "") {
            setMessage("Please Enter the Exam Name.");
            setStatus("error");
            setSnack(true);
            return;
        }
        setLoader(true);
        setMyLoader(true);
        try {

            fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                    requestList: [{
                        isEncrypt: false,
                        transactionId: "0000001438241244",
                        busiCode: "SCHOOL_ADD_EXAM_SMART_QBT",
                        "busiParams": {
                            branchId: parseInt(window.localStorage.branchId),
                            epId: createSmartExam.branchepId !== 0 ? createSmartExam.branchepId : createSmartExam.masterepId,
                            examPatternSrc: createSmartExam.source,
                            examTermId: examTermId,
                            employeeID: parseInt(window.localStorage.employeeId),
                            startDateTime: moment(fromDate).format("YYYY-MM-DD HH:mm:ss"),
                            endDateTime: moment(toDate).format("YYYY-MM-DD HH:mm:ss"),
                            loginUserId: parseInt(window.localStorage.userId),
                            passingMarks: viewPatternData[0].passingMarks,
                            sectionId: parseInt(window.localStorage.sectionId),
                            target: "internal",
                            totalMarks: viewPatternData[0].totalMarks,
                            examType: 3,
                            smartQbtExamName: smartQbtExamName
                        }
                    }],
                    pubInfo: {
                        appId: "appId",
                        sessionId: window.localStorage.sessionId,
                        version: "21",
                        timestamp: "20180314175419",
                        lang: "en_US",
                        userId: "1000016614",
                        serviceId: "7021150585",
                        circleId: "MU",
                        ncsroute: "WE015",
                        customerId: "1000016614",
                        osType: "windows",
                    },
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setMessage(data.respData[0].respMsg.message);
                    setSnack(true);
                    data.respData[0].respMsg.status == "Success"
                        ? setStatus("success")
                        : setStatus("error");
                    console.log("Pattern", data)
                    setToReLoad(!toReload);
                    setLoader(false);
                    setMyLoader(true);
                    setSmartDialog(false);
                    setFromDate(null);
                    setToDate(null);
                    setCreateSmartExam(null);
                })
            console.log("Pattern", createSmartExam)
        } catch (error) {
            console.error(error)
            setMyLoader(true);
        }
    };

    const handleSmartExamClose = () => {
        setSmartDialog(false)
    };

    const handleExpand = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };



    return (
        <React.Fragment>

            <>
                <Grid
                    item
                    container
                    direction="column"
                    spacing={1}
                    className={classes.subContainer}
                >
                    <Grid item>
                        {loader === false ? (
                            <ExamTabMaker tabs={tabHeadings} data={eExam} subjectBased={true} />
                        ) : (
                            <CircularProgress color="secondary" />
                        )}
                    </Grid>
                </Grid>
                <Dialog
                    open={smartDailog}
                    onClose={handleSmartExamClose}
                    aria-labelledby="customized-dialog-title"
                    className={classes.dialogContainer}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSmartExamClose}
                    >
                        Create Master Exam
                    </DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            direction="column"
                            spacing={2}
                        >
                            <Grid item container spacing={1} alignItems="center" justify="space-between">
                                <Grid item sm>
                                    <TextField
                                        id="examName"
                                        label="Exam Name*"
                                        variant="outlined"
                                        fullWidth
                                        value={smartQbtExamName}
                                        onChange={(e) => setSmartQbtExamName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item sm>
                                    <TextField
                                        select
                                        variant="outlined"
                                        fullWidth
                                        label="Exam Pattern Name *"
                                        value={createSmartExam}
                                        onChange={(e) => {
                                            setCreateSmartExam(e.target.value);
                                        }}
                                    >
                                        {patternName && patternName.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item sm>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ color: "white" }}
                                        disabled={createSmartExam == null}
                                        onClick={() => setViewExamPatternDailog(true)}
                                    >
                                        View Exam Pattern
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={1} alignItems="center" sm>
                                <Grid item sm>
                                    <DateTimePicker
                                        label="From *"
                                        disablePast
                                        inputVariant="outlined"
                                        disabled={createSmartExam === null}
                                        ampm={false}
                                        fullWidth
                                        value={fromDate}
                                        onChange={(mydate) => {
                                            if (moment(mydate).format("YYYY-MM-DD HH:mm") === moment(toDate).format("YYYY-MM-DD HH:mm")) {
                                                setStatus("Warning");
                                                setMessage(
                                                    "Start Time should not be equal to End time."
                                                );
                                                setSnack(true);
                                            } else if (moment(mydate).format("YYYY-MM-DD HH:mm") > moment(toDate).format("YYYY-MM-DD HH:mm")) {
                                                setStatus("Warning");
                                                setMessage(
                                                    "Start Time should not be greater than End time."
                                                );
                                                setSnack(true);
                                            } else {
                                                setFromDate(mydate);
                                                setToDate(mydate + (viewPatternData[0].totalTime * 60000));
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item sm>
                                    <DateTimePicker
                                        label="To *"
                                        inputVariant="outlined"
                                        ampm={false}
                                        disabled
                                        fullWidth
                                        disablePast
                                        value={toDate}
                                        onChange={(mydate) => {
                                            if (moment(mydate).format("YYYY-MM-DD HH:mm") === moment(fromDate).format("YYYY-MM-DD HH:mm")) {
                                                setStatus("Warning");
                                                setMessage(
                                                    "Start Time should not be equal to End time."
                                                );
                                                setSnack(true);
                                            }
                                            else if (
                                                moment(mydate, "YYYY-MM-DD").diff(
                                                    moment(fromDate, "YYYY-MM-DD")
                                                ) >= 0
                                            ) {
                                                setToDate(fromDate + (viewPatternData[0].totalTime * 60000));
                                            } else {
                                                setStatus("Warning");
                                                setMessage(
                                                    "End time should be greater than the Start Time"
                                                );
                                                setSnack(true);
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={handleSmartExamClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ color: "white" }}
                            disabled={
                                createSmartExam == null ||
                                toDate == null ||
                                fromDate == null
                            }
                            onClick={handleCreateSmartExam}
                        >
                            {loader === false ? (
                                "Create"
                            ) : (
                                <CircularProgress color={theme.palette.secondary.light} />
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>


                {/* View Exam Pattern Dailog */}
                <Dialog
                    open={viewExamPatternDailog}
                    onClose={() => setViewExamPatternDailog(false)}
                    aria-labelledby="customized-dialog-title"
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => setViewExamPatternDailog(false)}
                    >
                        View Eaxm Pattern
                    </DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            direction="column"
                            spacing={2}
                        >
                            <Grid item container spacing={2}>
                                {viewPatternData.map((elem, idx) => (
                                    <>
                                        <Grid item sm={3}>
                                            <Typography variant="subtitle1">Board: <strong>{elem.boardName}</strong></Typography>
                                        </Grid>

                                        <Grid item sm={3}>
                                            <Typography variant="subtitle1">Sub Board: <strong>{elem.subBoardName}</strong></Typography>
                                        </Grid>
                                        <Grid item sm={3}>
                                            <Typography variant="subtitle1">Exam Name:  <strong>{elem.name}</strong></Typography>
                                        </Grid>
                                    </>
                                ))}

                            </Grid>
                            <Grid item spacing={2}>
                                {viewPatternData[0] && viewPatternData[0].examPatternSubjectWise &&
                                    viewPatternData[0].examPatternSubjectWise.map((item, index) => (
                                        <Grid style={{ marginTop: "2em", marginBottom: '2em' }}>
                                            <Accordion expanded={expanded === index} onChange={handleExpand(index)}>
                                                <AccordionSummary
                                                    style={{ backgroundColor: "#f2f2f2" }}
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`panel1a-content-${index}`}
                                                    id={`panel1a-header${index}`}
                                                >
                                                    <Typography variant="h4"
                                                        style={{
                                                            color: "#000",
                                                            fontWeight: "700",
                                                            fontSize: "1.2em",
                                                            textTransform: "uppercase"
                                                        }}
                                                    >{item.subjectName}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid direction="column" item container spacing={2} >
                                                        {item.identifierDetails.map((el, idx) => (
                                                            <Grid item container spacing={1} key={idx} alignItems="center" justify="space-between">
                                                                <Grid item sm>
                                                                    <Typography variant="subtitle1">Identifier type: <strong>{el.identifierName}</strong></Typography>
                                                                </Grid>
                                                                <Grid item sm>
                                                                    <Typography variant="subtitle1">No Question: <strong>{el.no_Of_question}</strong></Typography>
                                                                </Grid>

                                                                <Grid item sm>
                                                                    <Typography variant="subtitle1">No of mandatory Question: <strong>{el.no_of_mandatoryQuestion}</strong></Typography>
                                                                </Grid>
                                                                <Grid item sm>
                                                                    <Typography variant="subtitle1">Total Marks:  <strong>{el.toMarks}</strong></Typography>
                                                                </Grid>

                                                                <Grid item sm>
                                                                    <Typography variant="subtitle1">Passing marks: <strong>{el.passingMarks}</strong></Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    ))}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ color: "white" }}
                            onClick={() => setViewExamPatternDailog(false)}
                        >
                            Back
                        </Button>
                    </DialogActions>
                </Dialog>


                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={snack}
                    autoHideDuration={9000}
                    onClose={() => setSnack(false)}
                    action={
                        <React.Fragment>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => setSnack(false)}
                            >
                                <CloseIcon
                                    style={{ color: theme.palette.common.tabFont }}
                                    fontSize="small"
                                />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert severity={status}>{message}</Alert>
                </Snackbar>
            </>

        </React.Fragment >
    );
}
