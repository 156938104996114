import React, { useEffect, useState, useLayoutEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { url } from "./store.js";
import moment from "moment";
import TableMaker from "./TableMaker";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
}));

const headers = ["Sr No.", "Teacher Name", "Student Name", "Roll No", "Reply"];

export default function MessageTeacher(props) {
  const classes = useStyle();
  const [myData, setMyData] = useState([]);
  const [loader, setLoader] = useState(true);
  let tabledata;

  useEffect(() => {
    setLoader(true);
    const getMessageData = async () => {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode:
                window.localStorage.teacherType == "Class"
                  ? "SCHOOL_GET_MESSAGE_FOR_CLASS_TEACHER"
                  : "SCHOOL_GET_MESSAGE_FOR_TEACHER",
              busiParams:
                window.localStorage.teacherType == "Class"
                  ? {
                      employeeId: parseInt(window.localStorage.employeeId),
                      sectionId: parseInt(window.localStorage.sectionId),
                      offset: 0,
                    }
                  : {
                      employeeId: parseInt(window.localStorage.employeeId),
                      secSubSylMappingId: parseInt(
                        window.localStorage.secSylMappingId
                      ),
                      offset: 0,
                    },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let tempEvent = [];

          // moment(item.date, "YYYY-MM-DD").toDate(),
          data.respData[0].respMsg.result.messageResList.length > 0
            ? data.respData[0].respMsg.result.messageResList.map(
                (item, index) => {
                  tempEvent.push([
                    {
                      title: index + 1,
                      type: "text",
                    },
                    { title: item.messageFrom, type: "text" },
                    { title: item.messageTo, type: "text" },
                    { title: item.rollNo, type: "text" },

                    {
                      title: "",
                      type: "reply",
                      fullDetails: item,
                    },
                  ]);
                }
              )
            : tempEvent.push([
                {
                  title: "No Data Available",
                  type: "text",
                },
                { title: " ", type: "text" },

                {
                  title: " ",
                  type: "text",
                },
                {
                  title: " ",
                  type: "text",
                },
              ]);

          setMyData(tempEvent);
        })
        .catch((data) => {
          console.error(data);
        });
    };

    getMessageData().catch((data) => console.error(data));
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [props.messageRefresh]);

  const data = [...myData];

  return (
    <React.Fragment>
      <Grid
        container
        className={classes.subContainer}
        direction="column"
        spacing={3}
      >
        <Grid item container>
          {loader === false ? (
            <TableMaker
              columns={headers}
              rows={myData}
              renderAg={props.messageRefresh}
              setRenderAg={props.setMessageRefresh}
            />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
