import React, { useState, useEffect } from "react";
import {
  Grid,
  List,
  ListItem,
  Paper,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Divider,
  IconButton,
  Toolbar,
  AppBar,
  CircularProgress,
  MenuItem,
  TextField,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  Switch,
  CardContent,
  Card,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

import TabMaker from "./TabMaker";
import { url } from "./store.js";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { orderBy } from "lodash";

import CommentIcon from "@material-ui/icons/Comment";

import DiscussionDialog from "./DiscussionDialogTeacher";

import QuestionarrePhotoDialog from "./QuestionarrePhotoDialog";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },

  myTextFieldStyle: {
    "& .MuiFormLabel-root": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiInputBase-input": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiSelect-icon": {
      color: theme.typography.myTheme.themeName == "Night" ? "#fff" : "inherit",
    },
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MyDataMaker = (props) => {
  const [rows, setRows] = useState([]);
  const [listdata, setListData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [studentGroupInfo, setStudentGroupInfo] = useState([]);

  useEffect(() => {
    setLoader(true);
    try {
      let listdata = [];
      let newlistdata = [];

      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_GROUP_DETAILS",
              busiParams: {
                groupId: props.groupId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (
            data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.list &&
            data.respData[0].respMsg.result.list.length > 0
          ) {
            data.respData[0].respMsg.result.list.map((item) => {
              listdata.push(item);
            });
            setStudentGroupInfo(data.respData[0].respMsg.result.studentGroupResponse);


          } else if (data.respData[0].respMsg.result) {
            setStudentGroupInfo(data.respData[0].respMsg.result.studentGroupResponse);
          } else {
            listdata.push({
              groupContributorID: 0,
              studentInstanceId: 0,
              userName: "No User Added",
              userProfilePicture: null,
            });
          }
        });

      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_ALL_QUERY",
              busiParams: {
                groupId: props.groupId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let component;
          setRows(data.respData[0].respMsg.result);
          setListData(listdata);
          setInterval(() => {
            setLoader(false);
          }, 1000);
        });
    } catch (error) {
      console.error(error);
      setInterval(() => {
        setLoader(false);
      }, 1000);
    }
  }, []);

  return (
    <React.Fragment>
      {loader === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <StudentGroupMaker
          mydata={rows}
          mylist={listdata}
          handleDisscussion={props.handleDisscussion}
          secId={props.secId}
          studentGrpName={studentGroupInfo.groupName}
        />
      )}
    </React.Fragment>
  );
};

const StudentGroupMaker = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const [commentOpen, setCommentOpen] = useState(false);
  const [questionForDialog, setQuestionForDialog] = React.useState({});
  const [imageData, setImageData] = useState();
  const [
    questionarePhotoDialogOpen,
    setQuestionarePhotoDialogOpen,
  ] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [displayFullscreen, setDisplayFullscreen] = React.useState(false);

  const toggleView = (event) => {
    setDisplayFullscreen(!displayFullscreen);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleQuestionarreImageClick = (image) => {
    setQuestionarePhotoDialogOpen(true);
    setImageData(image);
  };

  const dataForDialogFunc = (user) => {
    setCommentOpen(true);
    console.log(user);
    setQuestionForDialog(user);
  };

  const handleDialogImageClick = (image) => {
    console.log(image);
    setImageData(image);
    setQuestionarePhotoDialogOpen(true);
  };

  return (
    <React.Fragment>
      {console.log(props.mylist)}
      <Grid container direction="row" justify="space-between">
        <Grid
          item
          container
          sm={9}
          direction="row"
          alignItems="flex-start"
          spacing={2}
          style={{ maxHeight: "85vh", overflowX: "scroll", margin: "0" }}
          // wrap="nowrap"
        >
          {props.mydata && props.mydata.length > 0 ? (
            props.mydata.map((user) => (

              <Grid item container sm={4}>
                <Card style={{width: '100%'}}>
                  <CardContent>
                    <Grid item container direction="row" justify="space-between" >
                      <Grid item container sm={3}>
                        <Avatar
                          size="medium"
                          style={{
                            height: "3em",
                            width: "3em",
                          }}
                          src={user.groupQuery.profilePicture}
                        />
                      </Grid>

                      <Grid
                        item
                        container
                        direction="row"
                        sm={8}
                        alignItems="center"
                        style={{ marginLeft: "0.8em" }}
                      >
                        <Grid
                          item
                          container
                          direction="column"
                          spacing={1}
                        >
                          <Grid item container>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold" }}
                            >
                              {user.groupQuery.query}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            sm
                          >
                            <Grid
                              item
                              style={{
                                color: theme.palette.common.tabFont,
                                fontSize: "0.8em",
                              }}
                            >
                              <React.Fragment>
                                {user && user.groupQueryResponse === null
                                  ? "No Reply"
                                  : `Latest Reply From - ${orderBy(
                                    user.groupQueryResponse,
                                    (o) => o.createdOn,
                                    "desc"
                                  )[0].teacherName
                                  }`}
                              </React.Fragment>
                            </Grid>

                            <Grid
                              item
                              style={{
                                color: theme.palette.common.grey,
                                fontSize: "0.8em",
                                marginLeft: "1em",
                              }}
                            >
                              { }{" "}

                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        justify="flex-end"
                        alignItems="center"
                        style={{ marginTop: '2em' }}
                      >
                        <Grid container item sm={6}>
                          {user.groupQuery.url === null ? null : (
                            <Grid
                              item
                              container
                              onClick={() =>
                                handleQuestionarreImageClick(user.groupQuery.url)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={user.groupQuery.url}
                                alt=""
                                height="45em"
                                width="70em"
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid item sm={6}
                          justify='space-between'
                          alignItems="center"
                          direction="row"
                          style={{ cursor: 'pointer' }}
                          container
                          onClick={() => dataForDialogFunc(user)}>
                          <IconButton edge="end" aria-label="delete">
                            <CommentIcon style={{ color: "#ff3d00" }} />
                          </IconButton>
                          <Typography variant="subtitle1">Start Chat</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="h6" color="secondary">
              No Data Available
            </Typography>
          )}
        </Grid>

        <Grid
          item
          container
          direction="column"
          sm={3}
          style={{ maxHeight: "85vh", overflow: "scroll" }}
          wrap="nowrap"
        >
          {/* this is the right hand side container listing of children */}
          <Paper elevation={3} style={{ height: "85vh", overflowY: "scroll" }}>
            <Grid item style={{ position: "absolute", zIndex: 1 }}>
              {" "}
              <Toolbar
                variant="dense"
                style={{
                  backgroundColor: theme.palette.common.white,
                  width: "25em",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.3em",
                  }}
                >
                  Group Members ({props.studentGrpName})
                </Typography>
              </Toolbar>
              {/* <Toolbar style={{ fontWeight: "bold", fontSize: "1.3em", position: "absolute" }}>
Group Members
</Toolbar> */}
            </Grid>
            <Grid item style={{ marginTop: "3em", zIndex: -1 }}>
              <List>
                {props.mylist &&
                  props.mylist.map((value, index) => (
                    <ListItem key={value + index} button>
                      <ListItemAvatar>
                        <Avatar
                          size="medium"
                          style={{
                            height: "2.5em",
                            width: "2.5em",
                          }}
                          alt={`Avatar n°${value + 1}`}
                          src={value.userProfilePicture}
                        />
                      </ListItemAvatar>
                      <ListItemText id={value} primary={value.userName} />
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {commentOpen ? (
        <DiscussionDialog
          questionForDialog={questionForDialog}
          open={commentOpen}
          onClose={() => setCommentOpen(false)}
          handleDialogImageClick={handleDialogImageClick}
          handleDisscussion={props.handleDisscussion}
          mySubject={props.mySubject}
          secId={props.secId}
        />
      ) : null}
      <Dialog
        fullScreen={displayFullscreen}
        open={questionarePhotoDialogOpen}
        onClose={() => setQuestionarePhotoDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setQuestionarePhotoDialogOpen(false)}
        >
          {" "}
          <Switch
            checked={checked}
            onChange={toggleChecked}
            onClick={toggleView}
            color="primary"
          />
          <Typography style={{ color: theme.palette.common.tabFont }}>
            Toggle View
          </Typography>
        </DialogTitle>
        <DialogContent>
          <img
            src={imageData}
            alt="viewer"
            style={{
              height: "auto",
              maxWidth: displayFullscreen === false ? "40em" : undefined,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={() => setQuestionarePhotoDialogOpen(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default function DiscussionForumTeacher() {
  const classes = useStyle();
  const theme = useTheme();

  const [subject, setSubject] = useState([]);
  const [discussionForum, setDiscussionForum] = useState([]);
  const [loader, setLoader] = useState(true);
  const [mySubject, setMySubject] = useState(
    window.localStorage.teacherType == "Class"
      ? 0
      : window.localStorage.secSylMappingId
  );

  let subjects = JSON.parse(window.localStorage.getItem("users") || "[]");

  //   let tabheadings = [];
  //   try {
  //     tabheadings = subjects.map((a) => a.subjectName);
  //   } catch (error) {
  //     console.error(error);
  //   }

  const handleDisscussion = async (e, l) => {
    if (l === "do") {
      setMySubject(e.target.value);
    }

    setLoader(true);
    let AllList = [];

    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_GROUP_LIST_BY_SECSUBMAPPING_ID",
              busiParams: {
                secSubSylMappingId: e.target.value,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let tabheadings = [];

          // moment(item.date, "YYYY-MM-DD").toDate(),
          if (data.respData[0].respMsg.result.length > 0) {
            try {
              tabheadings =
                data.respData[0].respMsg.result &&
                data.respData[0].respMsg.result.map((a) => a.groupName);
              data.respData[0].respMsg.result &&
                data.respData[0].respMsg.result.map((item, index) => {
                  let component = (
                    <React.Fragment key={index}>
                      <MyDataMaker
                        groupId={item.groupId}
                        handleDisscussion={handleDisscussion}
                        secId={e.target.value}
                      />
                    </React.Fragment>
                  );
                  AllList.push(component);
                });
              setSubject(tabheadings);
            } catch (error) {
              console.error(error);
              setSubject(["Network Error"]);
            }
          } else {
            setSubject(["No Groups Present For This Subject"]);
          }
        })
        .catch((data) => {
          console.error(data);
        });
      console.log(AllList);
      console.log(orderBy(AllList, (o) => o.key, "asc"));

      setDiscussionForum(AllList);
    } catch (error) {
      console.error(error);
    }

    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  useEffect(() => {
    if (window.localStorage.teacherType == "Subject") {
      setLoader(true);
      let AllList = [];

      try {
        fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            requestList: [
              {
                isEncrypt: false,
                transactionId: "0000001438241244",
                busiCode: "SCHOOL_GET_GROUP_LIST_BY_SECSUBMAPPING_ID",
                busiParams: {
                  secSubSylMappingId: mySubject,
                },
              },
            ],
            pubInfo: {
              appId: "appId",
              sessionId: window.localStorage.sessionId,
              version: "21",
              timestamp: "20180314175419",
              lang: "en_US",
              userId: "1000016614",
              serviceId: "7021150585",
              circleId: "MU",
              ncsroute: "WE015",
              customerId: "1000016614",
              osType: "windows",
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            let tabheadings = [];

            // moment(item.date, "YYYY-MM-DD").toDate(),
            if (
              data.respData[0].respMsg &&
              data.respData[0].respMsg.result &&
              data.respData[0].respMsg.result.length > 0
            ) {
              try {
                tabheadings =
                  data.respData[0].respMsg.result &&
                  data.respData[0].respMsg.result.map((a) => a.groupName);
                data.respData[0].respMsg.result &&
                  data.respData[0].respMsg.result.map((item, index) => {
                    let component = (
                      <React.Fragment key={index}>
                        <MyDataMaker
                          groupId={item.groupId}
                          handleDisscussion={handleDisscussion}
                          secId={mySubject}
                        />
                      </React.Fragment>
                    );
                    AllList.push(component);
                  });
                setSubject(tabheadings);
              } catch (error) {
                console.error(error);
                setSubject(["Network Error"]);
              }
            } else {
              setSubject(["No Groups Present For This Subject"]);
            }
          })
          .catch((data) => {
            console.error(data);
          });
        console.log(AllList);
        console.log(orderBy(AllList, (o) => o.key, "asc"));

        setDiscussionForum(AllList);
      } catch (error) {
        console.error(error);
      }

      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  }, []);

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item sm>
          <TextField
            label="Select Subject"
            select
            variant="outlined"
            value={mySubject}
            className={classes.myTextFieldStyle}
            style={{ minWidth: "10em" }}
            onChange={(e) => handleDisscussion(e, "do")}
          >
            {subjects &&
              subjects.map((item, index) => (
                <MenuItem key={index} value={item.secSubSylMappingId}>
                  {item.subjectName}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <React.Fragment>
          {mySubject === 0 ? (
            <Grid item>
              <Typography variant="h6" color="secondary">
                Please Select A Subject
              </Typography>
            </Grid>
          ) : (
            <Grid item>
              {loader === false ? (
                <TabMaker
                  tabs={subject}
                  data={discussionForum}
                  subjectBased={true}
                />
              ) : (
                <CircularProgress color="secondary" />
              )}
            </Grid>
          )}
        </React.Fragment>
      </Grid>
    </React.Fragment>
  );
}
