import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { Grid, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import is from "is_js";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useHistory } from "react-router-dom";
import { ThemeEnergy } from "../Ui/Theme.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Particles from "react-particles-js";

const useStyles = makeStyles((theme) => ({}));

export default function PrivacyPolicy(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const list1 = [
    "To provide you with services, important information and marketing promotions.",
    "To follow up to ensure requested information are met with and any further requirements.",
    "To send notify you of any updates available for the application/product and you will have to update it for making use of its best and latest version.",
    "For general mailing list subscription although you will also have an option to opt out from receiving any promotional e-mails as per standard web practices.",
    "To communicate with you about any issue that you raise with us or which follows from an interaction between us.",
    "To develop existing and new methods that meets the expectations and requirement of our users",
  ];

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData(
      "Communication",
      "Communication Name, Address, Enrolment ID, Phone Numbers, Email address.	Internally authorised employees, Parents Till when you or the School is associated with us",
      "Internally authorised employees, Parents",
      "Till when you or the School is associated with us",
      4.0
    ),
    createData(
      "Identification (Students & Parents)",
      "User id, Password, Name, Enrolment ID, Gender, Date of Birth (“DOB), Image, Blood group, Occupation",
      "Internally authorised employees, Students and Parents",
      "Till when you or the School is associated with us",
      4.3
    ),
    createData(
      "Identification (Teachers & Employees)",
      "User id, Password, Name, Enrolment ID, Gender, Date of Birth (“DOB), Image, Blood group, Designation, Experience, Qualification, Reporting manager, Department Internally authorised employees Till when you or the School is associated with us ",
      "Internally authorised employees",
      "Till when you or the School is associated with us",
      6.0
    ),
    createData(
      "Academic Advancement (Students)",
      "Class, Board, Marks, Attendance Internally authorised employees, Students, Parents	For that academic year except for overall performance score which shall be held till when you or the school is associated with us.",
      "Internally authorised employees, Students, Parents",
      "For that academic year except for overall performance score which shall be held till when you or the school is associated with us.",
      4.3
    ),
    createData(
      "Holistic Education/ Overall Development (Students & Teachers)",
      "Blood Group, Height, Weight, Inputs from other Departments, psychological concerns, etc.",
      "Internally authorised employees, counsellors (who have a separate agreement to maintain confidentiality of information) along with only the respective student and his/her parents or teachers from whom such data is collected",
      "For that academic year except for overall performance score which shall be held till when you or the school is associated with us.",
      3.9
    ),
  ];
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      //style={{ height: "100vh" }}
    >
      <Grid item container direction="column" alignItems="center">
        <Paper
          elevation={10}
          style={{
            width: "84vw",
            // height: is.safari() || is.ie() ? "1000px" : "inherit",
            // maxHeight: is.safari() || is.ie() ? "1000px" : "100vh",
            overflowY: "auto",
          }}
        >
          <Grid
            container
            direction="column"
            style={{
              padding: 10,
              paddingTop: 0,
              marginLeft: "auto",
              marginRight: "auto",
              //height: is.safari() || is.ie() ? "2500px" : "100%",
              width: "80vw",
              overflowY: "auto",
            }}
          >
            <Grid
              item
              direction="column"
              style={{
                backgroundColor: "white",

                marginLeft: "auto",
                marginRight: "auto",
                // width: "92vw",
              }}
            >
              <Typography variant="h3" align="center" gutterBottom>
                Privacy Policy
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "2em" }}>
              <Typography variant="body1" paragraph>
                As a data collector, we are obliged under law to provide clear
                and transparent information about our data processing
                activities. The same is provided under this section which
                constitutes the PRIVACY POLICY and also any other communications
                we send you in this regard.
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="h4"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Information/Data collected
              </Typography>

              <Typography variant="body1" paragraph>
                In order to use our product and services, you will be required
                to submit the following information :
              </Typography>
            </Grid>
            <Grid item style={{ margin: 10 }} direction="column">
              <TableContainer component={Paper} elevation={6}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>
                          {" "}
                          Purpose for collecting information (and from whom)
                          Data Collected Sharing of data, if any Retention
                          Period of such data
                        </b>
                      </TableCell>
                      <TableCell>
                        <b>Data Collected</b>
                      </TableCell>
                      <TableCell>
                        <b>Sharing of data, if any</b>
                      </TableCell>
                      <TableCell>
                        <b>Retention Period of such data</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ fontWeight: "bold" }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell>{row.calories}</TableCell>
                        <TableCell>{row.fat}</TableCell>
                        <TableCell>{row.carbs}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <Typography variant="body1" paragraph>
                The information collected above fall under categories of
                “Personal Information”, “Sensitive Personal Information” and
                “Associated Information” defined under the Data Protection
                Rules, 2011 and shall be collectively referred to as
                <b>“Information”</b> herein. Providing such Information will
                mean that you have read these <b>TERMS</b> and voluntarily
                consent to providing us with such Information for the intended
                purposes set forth.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" paragraph>
                Apart from this, we may also collect some information
                automatically including the type of mobile device you use, your
                mobile devices unique device ID, the IP address of your mobile
                device, your mobile operating system, the type of mobile
                Internet browsers you use, and information about the way you use
                the website/application/product along with other relevant
                information as per the permissions that you provide.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" paragraph>
                Please note that we intend to safeguard the confidentiality of
                your Information at all times and have kept various physical,
                procedural and electronic safeguards to protect it.
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Purpose for collection of such Information
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Communication Purpose
              </Typography>
            </Grid>

            <Grid item>
              <List dense={true}>
                {list1.map((item, index) => (
                  <ListItem key={item + index}>
                    <ListItemIcon>
                      <FiberManualRecordIcon
                        style={{ fontSize: "10px", color: "black" }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Identification Purpose
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" paragraph>
                The user id and password will be unique to ever user and is to
                ensure the identification of every user. It is important to note
                here that this user id and password is our only way of
                identifying the user and we will not be liable for loss of the
                same or any unauthorised access of it.
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Academic Advancement Purpose
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" paragraph>
                Information with regard to academics such as the class, marks
                and attendance is important to boost the academic performance of
                the student and keep parents informed on their children's
                progress. This also helps students to develop their 21st Century
                skills such as leadership, analysing, decision making and
                creativity.
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Overall Development Purpose
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" paragraph>
                Information which relates to other departments such as sports,
                extra curriculars, discipline, etc., psychological concerns,
                medical history such as the blood group, height, weight, etc. is
                collected to help students develop holistically and improve
                their overall performance to compete globally.
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Access to Information
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" paragraph>
                The Unique user ID and password shall be completely encrypted
                and accessible only by the stakeholders. In case, you forget
                your password, we will give you an option to reset it with your
                approval as per standard practices.
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" paragraph>
                All Information shall be filled by you and hence, also made
                accessible to you. You will also have an option to
                upgrade/correct/change any details provided as and when
                necessary. However, unless communicated to us, it shall be
                assumed that the details submitted by you are correct, true and
                accurate.
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Disclosure of Information
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" paragraph>
                We will disclose information only to legal authorities only and
                if required by law. We may also disclose the Information in
                other circumstances in good faith (uberrima fides) only if such
                disclosure is necessary to protect our rights, protect your
                safety or the safety of others, investigate fraud, address
                security or technical issues or respond to a government request.
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="h4"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                USE OF COOKIES
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" paragraph>
                A cookie is a small file of letters and numbers that we store on
                your browser or the hard drive of your computer if you agree.
                Cookies contain information that is transferred to your
                computer’s hard drive. We use cookies on our website for a
                number of purposes. They help us provide you with a good
                experience when you browse our website and allow us to improve
                our site.
                <b>
                  By continuing to browse our website, you are agreeing to our
                  use of cookies.
                </b>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
