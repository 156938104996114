import React, { useState, useEffect } from "react";
import { Tabs, AppBar, Tab, Box } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  AppBarStyle: {
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.ltgrey
        : theme.palette.common.tabs,
  },

  myTab: {
    maxWidth: "85vw",
    "& .MuiSvgIcon-root": {
      fill:
        theme.typography.myTheme.themeName === "Night"
          ? "white !important"
          : "black !important",
    },
  },

  tabStyle: {
    color:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.white
        : theme.palette.common.black,
  },

  tabPanelStyle: {
    paddingTop: "2em",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

export default function ExamWorkTabMaker(props) {
  const [value, setValue] = useState(0);
  // const [position, setPosition] = useState([]);
  const classes = useStyles();
  const theme = useTheme();
  const [mytabs, setTabs] = useState(props.tabs);
  const [mydata, setData] = useState(props.data);
  const handleChange = (event, NewValue) => {
    setValue(NewValue);
    localStorage.setItem("examWorkTab", NewValue);
  };

  useEffect(() => {
    setTabs(props.tabs);
    setData(props.data);
    setValue(localStorage.getItem("examWorkTab") ? parseInt(localStorage.getItem("examWorkTab")) : 0 );
  });

  return (
    <React.Fragment>
      <AppBar position="static" className={classes.AppBarStyle} elevation={0}>
        <Tabs
          value={value}
          className={classes.myTab}
          onChange={handleChange}
          aria-label=""
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {mytabs.map((item, index) => {
            return (
              <Tab
                key={item + index}
                className={classes.tabStyle}
                label={item}
                wrapped={true}
              />
            );
          })}
        </Tabs>
      </AppBar>
      <React.Fragment>
        {mydata && mydata.map((item, index) => (
          <TabPanel
            key={index + item}
            className={classes.tabPanelStyle}
            value={value}
            index={props.subjectBased ? parseInt(item.key) : index} //checks if linking of tab is based on subjects which are coming dynamically or static menu bars
          >
            {item}
          </TabPanel>
        ))}
      </React.Fragment>
    </React.Fragment>
  );
}
