import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import ChartMaker from "./ChartMaker.js";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { url } from "./store.js";
const localizer = momentLocalizer(moment);

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
  myCalendar: {
    "& .rbc-header": {
      backgroundColor: theme.palette.common.calHeader,
      textAlign: "left",
      textTransform: "uppercase",
      padding: "28px",
      paddingLeft: "10px",
      border: "none",
    },
    "& .rbc-today": {
      backgroundColor: theme.palette.common.calTodayCol,
    },
    "& .rbc-month-view": {
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
    },
    "& .rbc-off-range-bg": {
      backgroundColor: "transparent",
    },

    "& .rbc-date-cell": {
      textAlign: "left",
      marginTop: "15px",
      paddingLeft: "10px",
      marginBottom: "5px",
    },

    "& .rbc-day-bg": {
      borderLeft: "none",
    },

    "& .rbc-show-more": {
      textDecoration: "none",
    },

    "& .rbc-toolbar-label": {
      fontWeight: "bold",
      textTransform: "uppercase",
      padding: 0,
      width: "10em",
    },
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

function useAsyncState(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setter = (x) =>
    new Promise((resolve) => {
      setValue(x);
      resolve(x);
    });
  return [value, setter];
}

const dataset = {
  labels: ["W1", "W2", "W3", "W4", "W5"],
  datasets: [
    {
      label: "My Attendance",
      backgroundColor: "#ff6d00",
      barThickness: "flex",
      barPercentage: 0.5,
      categoryPercentage: 0.6,
      // maxBarThickness: 45,
      borderColor: "#ff6d00",
      borderSkipped: "left",
      data: [100, 60, 100, 85, 35],
    },
  ],
};

const eventStyleGetter = (event, start, end, isSelected) => {
  console.log(event);
  var backgroundColor = "#" + event.color;
  var style = {
    backgroundColor: backgroundColor,
    borderRadius: "0px",
    width: "10px",
    height: "10px",
    opacity: 0.7,
    color: "black",
    fontWeight: "bold",
    border: "0px",
    borderRadius: "100%",
    display: "block",
    marginBottom: "5px",
  };
  return {
    style: style,
  };
};

const DialogTitle = withStyles(styles)((props) => {
  const theme = useTheme();
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function AttendanceTracker(props) {
  const classes = useStyle();
  const date = moment();
  const theme = useTheme();
  const [myDataset, setMyDataset] = useState({});
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [monthDate, setMonthDate] = useAsyncState(moment());

  const [renderAg, setRenderAg] = useState(false);

  useEffect(() => {
    setLoading(true);
    let month = monthDate;
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_STUDENT_WEEKLY_ATTENDANCE",
            busiParams: {
              date: moment(month).format("DD-MM-YYYY").toString(),
              studentInstanceId: window.localStorage.studentInstanceId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let tempEvents = [];
        let dataset = {
          labels: [],
          datasets: [
            {
              label: "Weekly Attendance",
              backgroundColor: "#ff6d00",
              barThickness: "flex",
              barPercentage: 0.5,
              categoryPercentage: 0.6,
              maxBarThickness: 30,
              borderColor: "#ff6d00",
              borderSkipped: "left",
              data: [],
            },
          ],
        };
        console.log(data);
        data.respData[0].respMsg.result.weeklyPer.map((item, index) => {
          dataset.labels.push(item.week);
          item.percentage &&
            dataset.datasets[0].data.push(
              parseInt(item.percentage.replace("%", ""))
            );
        });

        console.log(dataset);

        setMyDataset(dataset);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })

      .catch((data) => {
        console.error(data);
        setLoading(false);
      });
  }, [monthDate]);

  useEffect(() => {
    setLoading(true);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_GET_ATTENDANCE_BY_STUINSID",
            busiParams: {
              studentInstanceId: window.localStorage.studentInstanceId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("the data in attendence is = " + JSON.stringify(data));
        let tempEvents = [];

        data.respData[0].respMsg.results.map((item, index) => {
          console.log("the item is = " + JSON.stringify(item));
          tempEvents.push({
            start: moment(item.date, "YYYY-MM-DD hh-mm-ss").toDate(),
            end: moment(item.date, "YYYY-MM-DD hh-mm-ss").toDate(),
            title: "",
            color: item.color,
          });
        });

        setEvents(tempEvents);
      })
      .catch((data) => {
        console.error(data);
      });
  }, []);

  const handlePrev = async (toolbar) => {
    toolbar.onNavigate("PREV");
  };

  const handleNext = async (toolbar) => {
    toolbar.onNavigate("NEXT");
  };

  const CustomToolBar = (toolbar) => (
    <React.Fragment>
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item>
          <IconButton disableRipple onClick={() => handlePrev(toolbar)}>
            <ArrowLeftIcon size="large" color="secondary" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {toolbar.label}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton disableRipple onClick={() => handleNext(toolbar)}>
            <ArrowRightIcon color="secondary" />
          </IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  let components = {
    toolbar: CustomToolBar,
  };

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          <React.Fragment>
            <Grid container direction="row" spacing={2}>
              <Grid item container sm={7}>
                <Calendar
                  popup={true}
                  localizer={localizer}
                  defaultDate={new Date()}
                  views={["month"]}
                  className={classes.myCalendar}
                  events={events}
                  components={components}
                  style={{ height: "75vh", width: "100%" }}
                  eventPropGetter={eventStyleGetter}
                  onNavigate={(e) => setMonthDate(e)}
                />
              </Grid>
              <Grid
                item
                container
                sm={5}
                direction="column"
                style={{ marginTop: "3.3em" }}
                spacing={1}
              >
                <Grid item>
                  {loading === true ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <ChartMaker
                      dataset={myDataset}
                      height={280}
                      percentage={true}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
