import React from 'react'
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';



const useStyle = makeStyles((theme) => ({
    subContainer: {
        ...theme.typography.subContainer,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: "#fff",
    },
    chartlWrapper: {
        margin: '8px 0',
        width: '100%',
        justifyContent: 'space-between'
    },
    chartWrapperInner: {
        background: '#fff',
        padding: '15px 0',
        boxShadow: '0px 0px 9px #00000069',
        borderRadius: '7px',
        maxWidth: '49%',
        flexBasis: '49%',
    }
}));

export default function AdeptiveCompletion(props) {

    const classes = useStyle();
    const theme = useTheme();

    return (
        <>
            <Grid
                item
                container
                direction="column"
                spacing={1}
                style={{ padding: "0 1em" }}
            >
                <Grid item container justify='space-between'>
                    <Grid item>
                        <IconButton style={{ padding: "4px" }}
                        onClick={() => props.setShowDetailedAnalysis(false)} 
                        >
                            <KeyboardBackspaceIcon style={{ color: "#000" }} />
                        </IconButton>
                        <Typography variant='span' style={{fontSize:"1.3em", color: "#000"}}>Standard wise conpletion</Typography>
                    </Grid>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => props.setOpenTestDialog(true)}
                    >Take Exam</Button>
                </Grid>
            </Grid>
        </>
    )
}
