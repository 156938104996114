import React, { useState, useEffect, createRef } from "react";
import { Grid, CircularProgress, Button, Dialog, DialogContent, DialogActions, TextField, MenuItem, Select, Input, ListItemText, Checkbox, InputLabel, FormControl, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Typography, Tooltip } from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SortIcon from "@material-ui/icons/Sort";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { orderBy, max, groupBy, findIndex, cloneDeep, reverse } from "lodash";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
    table: {
        backgroundColor:
          theme.typography.myTheme.themeName === "Night"
            ? "#616161"
            : "transparent",
        minwidth: "30em",
    
        // maxHeight: "60vh",
      },
      customTooltip: {
        // I used the rgba color for the standard "secondary" color
        fontSize: "0.85rem",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: "#fff",
      },
      tableHead: {
        "& .MuiTableHead-root": {
          color:
            theme.typography.myTheme.themeName === "Night"
              ? theme.palette.common.white
              : theme.palette.common.black,
          backgroundColor:
            theme.typography.myTheme.themeName === "Night"
              ? theme.palette.common.ltgrey
              : theme.palette.common.white,
        },
        "& .MuiTableCell-head": {
          color:
            theme.typography.myTheme.themeName === "Night"
              ? theme.palette.common.white
              : theme.palette.common.black,
          backgroundColor:
            theme.typography.myTheme.themeName === "Night"
              ? theme.palette.common.ltgrey
              : theme.palette.common.white,
        },
    
        backgroundColor:
          theme.typography.myTheme.themeName === "Night"
            ? theme.palette.common.ltgrey
            : theme.palette.common.white,
        width: "100%",
        "& .MuiTableRow-head": {
          // position: "sticky",
          // zIndex: 2,
        },
      },
}));


export default function CustomTable({
 header,
 ...props
}) {
  const theme = useTheme();
  const classes = useStyle();
  const inverDirect = {
    asc: "desc",
    desc: "asc",
  };
  const [searchRows, setSearchRows] = useState(props.rows);
  const [columnToSort, setColumnToSort] = React.useState();
  const [sortDirection, setDirection] = React.useState("asc");

  const sortRows = (user, index) => {
    setColumnToSort(user);

    setDirection(columnToSort === user ? inverDirect[sortDirection] : "asc");
    let newRows = searchRows;

    let sec =
      user === "Roll No" ||
      user === "Roll No." ||
      user === "RollNo" ||
      user === "Total Attendance" ||
      user === "Passing Marks" ||
      user === "Total No Of Days" ||
      user === "Sr No." ||
      user === "No. Of Smart Exams Taken"
        ? orderBy(
            newRows,
            (o) => parseInt(o[index].title),
            columnToSort === user ? inverDirect[sortDirection] : "asc"
          )
        : user === "Date" || user === "DueDate" || user === "Cut-Off Time"
        ? orderBy(
            newRows,
            (o) =>
              moment(o[index].title, "DD-MM-YYYY")
                .format("YYYY-MM-DD")
                .toString(),
            columnToSort === user ? inverDirect[sortDirection] : "asc"
          )
        : user === "Resubmit End Date"
        ? orderBy(
            newRows,
            (o) => o[index].date,
            columnToSort === user ? inverDirect[sortDirection] : "asc"
          )
        : user === "Schedule"
        ? orderBy(
            newRows,
            (o) => o[index].mytime,
            columnToSort === user ? inverDirect[sortDirection] : "asc"
          )
        : user === "Leave Duration"
        ? orderBy(
            newRows,
            (o) => o[index].sortDate,
            columnToSort === user ? inverDirect[sortDirection] : "asc"
          )
        : orderBy(
            newRows,
            (o) => o[index].title,
            columnToSort === user ? inverDirect[sortDirection] : "asc"
          );
    setSearchRows(sec);
    console.log(sec);
  };

  const noSort = () => {
    console.log("no sort");
}

  const myTableBody =
    searchRows &&
    searchRows.map((item, index1) => (
      <React.Fragment key={item + index1}>
        <TableRow
          key={item.title + index1}
        >
          {item.map((row, index) => {
            return (
              <TableCell
                key={row + index}
                style={{
                  maxWidth: "10em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  padding: "10px 16px"
                }}
              >
                {row.type == "action" ? 
                (
                    <IconButton
                        onClick={() =>
                          props.viewSmartExam(row)
                        }
                      >
                        <VisibilityOutlinedIcon color="secondary"  fontSize="medium"/>
                    </IconButton>
                )
                : (<Tooltip
                      title={row.title}
                      classes={{
                        tooltip: classes.customTooltip,
                      }}
                 >
                    <span>{row.title}</span>
                </Tooltip>)
          }
              </TableCell>
            );
          })}
        </TableRow>
      </React.Fragment>
    ));



  return (
    <React.Fragment>
      <TableContainer
          className={classes.table}
          style={{ scrollbarWidth: "none" }}
        >
          <Table
            stickyHeader
            aria-label="simple table"
            className={classes.table}
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                {header &&
                  header.map((item, index) => (
                    <TableCell
                      key={item + index}
                      onClick={() => item.type != "action" ? sortRows(item, index) : noSort()}
                      style={{ cursor: "pointer" }}
                    >
                      <Grid container>
                        <Grid item>
                            {item.name}
                        </Grid>
                        {item.type != "action" && <Grid item>
                          {item !== " " && item !== "ck" ? (
                            item === columnToSort ? (
                              sortDirection === "asc" ? (
                                <ArrowDropUpIcon
                                  size="small"
                                  style={{ marginLeft: "0.75em" }}
                                />
                              ) : (
                                <ArrowDropDownIcon
                                  size="small"
                                  style={{ marginLeft: "0.75em" }}
                                />
                              )
                            ) : (
                              <SortIcon
                                size="small"
                                style={{ marginLeft: "0.75em" }}
                              />
                            )
                          ) : undefined}
                        </Grid>
                        }
                      </Grid>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>{myTableBody}</TableBody>
          </Table>
        </TableContainer>
    </React.Fragment>
  );
}
