import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { url } from "./store.js";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

import TableMaker from "./TableMaker";
import { CircularProgress } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
}));

const headers = [
  "Date",
  "Time Tracking",
  "Active Time",
  "Idle Time",
  "Detailed Analysis",
];

export default function Vigilance(props) {
  const classes = useStyle();
  const [myData, setMyData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    const getMessageData = async () => {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "STDNT_FETCH_VIGILANCE",
              busiParams: {
                studentInstanceId: window.localStorage.studentInstanceId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let tempEvent = [];

          // moment(item.date, "YYYY-MM-DD").toDate(),
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
                tempEvent.push([
                  {
                    title: moment(item.date, "YYYY-MM-DD")
                      .format("DD MMM YYYY")
                      .toString(),
                    type: "text",
                  },
                  {
                    title: moment
                      .utc(
                        moment
                          .duration(parseInt(item.total), "minutes")
                          .asMilliseconds()
                      )
                      .format("HH:mm"),
                    type: "text",
                  },
                  {
                    title: moment
                      .utc(
                        moment
                          .duration(parseInt(item.active), "minutes")
                          .asMilliseconds()
                      )
                      .format("HH:mm"),
                    type: "text",
                  },
                  {
                    title: moment
                      .utc(
                        moment
                          .duration(parseInt(item.idle), "minutes")
                          .asMilliseconds()
                      )
                      .format("HH:mm"),
                    type: "text",
                  },
                  {
                    title: "Details",
                    type: "vigilance",
                    details: JSON.parse(item.moduleBreakUp),
                  },
                ]);
              })
            : tempEvent.push([
                {
                  title: "No Data Available",
                  type: "text",
                },
                {
                  title: "",
                  type: "text",
                },
                {
                  title: "",
                  type: "text",
                },
                {
                  title: "",
                  type: "text",
                },
                {
                  title: "",
                  type: "text",
                },
              ]);

          setMyData(tempEvent);
          setLoader(false);
        })
        .catch((data) => {
          console.error(data);
          setLoader(false);
        });
    };

    getMessageData().catch((data) => {
      console.error(data);
      setLoader(false);
    });
  }, []);

  return (
    <React.Fragment>
      <Grid
        container
        className={classes.subContainer}
        direction="column"
        spacing={3}
      >
        <Grid item container>
          {loader === false ? (
            <TableMaker columns={headers} rows={myData} />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
