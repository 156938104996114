import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, fade, useTheme } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { url } from "./store.js";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
    width: "70vw",
  },
  textFieldStyle: {
    backgroundColor: fade(theme.palette.common.white, 0.7),
  },
  myTextFieldStyle: {
    "& .MuiFormLabel-root": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiInputBase-input": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiSelect-icon":{
      color: theme.typography.myTheme.themeName == "Night" ? "#fff" : "inherit", 
    },
  },
  dropZone: {
    color: "black !important",
    "& .MuiTypography-h6": {
      color: "black !important",
    },
  },
  myDropZone: {
    "& .MuiDialogContent-root": {
      scrollbarWidth: "none",
    },
  },
}));

export default function PostAQuery() {
  const classes = useStyle();
  const theme = useTheme();
  const [file, setFile] = useState("");
  const [subject, setSubject] = useState([]);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [myquery, setQuery] = useState({
    fileName: "",
    fileType: "",
    groupContributorId: 0,
    groupId: 0,
    loginUserId: 0,
    query: "",
    queryDescription: "",
    studentInstanceId: 0,
    url: "",
  });
  const [uploadExamDialog, setUploadExamDialog] = useState(false);
  const [uploadExamMessage, setUploadExamMessage] = useState("");
  const [uploadExamStatus, setUploadExamStatus] = useState("");
  const [openUploadExamSnack, setUploadExamSnack] = useState("");

  useEffect(() => {
    const getQueryData = async () => {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_STUDENT_GROUP_DETAILS",
              busiParams: {
                studentInstanceId: window.localStorage.studentInstanceId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          if (data.respData[0].respMsg.result.length > 0) {
            setSubject(data.respData[0].respMsg.result);
          } else {
            setSubject([
              {
                studentInstanceId: 0,
                groupId: 0,
                groupContributorId: 0,
                subjectId: 0,
                subjectName: "Not Assigned to a group",
              },
            ]);
          }
        })
        .catch((data) => {
          console.error(data);
        });
    };

    getQueryData().catch((data) => console.error(data));
  }, []);

  const handleUploadClose = () => {
    setUploadDialog(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleOnUploadSubmit = async (files) => {
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];

    setFile(files[0].name);
    let myfilename = files[0].name.split(".");
    let myfiletype = files[0].type.split("/");
    console.log(myfiletype);
    setQuery({
      ...myquery,
      fileName: myfilename[0],
      fileType: myfiletype[1],
      url: newattachment,
      studentInstanceId: window.localStorage.studentInstanceId,
      loginUserId:
        window.localStorage.roleId === 5
          ? window.localStorage.parentUserId
          : window.localStorage.userId,
    });
    setUploadDialog(false);
  };

  const onPostQuery = () => {
    setLoader(true);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_ADD_STUDENT_QUERY",
            busiParams: myquery,
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUploadExamMessage(data.respData[0].respMsg.message);
        setUploadExamStatus(
          data.respData[0].respMsg.status === "Success" ? "success" : "error"
        );
        setUploadExamSnack(true);

        setLoader(false);
        // moment(item.date, "YYYY-MM-DD").toDate(),
      })
      .catch((data) => {
        console.error(data);
        setLoader(false);
      });

    setQuery({
      fileName: "",
      fileType: "",
      groupContributorId: 0,
      groupId: 0,
      loginUserId: 0,
      query: "",
      queryDescription: "",
      studentInstanceId: 0,
      url: "",
    });
    console.log(myquery);
  };

  const handleClear = () => {
    setQuery({
      fileName: "",
      fileType: "",
      groupContributorId: 0,
      groupId: 0,
      loginUserId: 0,
      query: "",
      queryDescription: "",
      studentInstanceId: 0,
      url: "",
    });
    setFile("");
  };

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={4}
        className={classes.subContainer}
      >
        <Grid item>
          <TextField
            id="myselect"
            variant="outlined"
            placeholder="Enter Subject "
            label="Subject *"
            fullWidth
            select
            color="theme.typography.myTheme.themeName === 'Night'? '#747474':primary"
            className={
              theme.typography.myTheme.themeName == "Night"
                ? classes.myTextFieldStyle
                : classes.textFieldStyle
            }
            onChange={(event) => {
              setQuery({
                ...myquery,
                groupId: event.target.value.groupId,
                groupContributorId: event.target.value.groupContributorId,
                studentInstanceId: window.localStorage.studentInstanceId,
                loginUserId:
                  window.localStorage.roleId === 5
                    ? window.localStorage.parentUserId
                    : window.localStorage.userId,
              });
            }}
          >
            {subject.map((item) => (
              <MenuItem key={item} value={item}>
                {`${item.subjectName} 
                  `}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            id=""
            variant="outlined"
            placeholder="Write Your Query"
            label="Query *"
            fullWidth
            className={
              theme.typography.myTheme.themeName == "Night"
                ? classes.myTextFieldStyle
                : classes.textFieldStyle
            }
            //color="theme.typography.myTheme.themeName === 'Night'? '#747474':primary"
            value={myquery.query}
            onChange={(event) => {
              setQuery({
                ...myquery,
                query: event.target.value,
                studentInstanceId: window.localStorage.studentInstanceId,
                loginUserId:
                  window.localStorage.roleId === 5
                    ? window.localStorage.parentUserId
                    : window.localStorage.userId,
              });
            }}
          />
        </Grid>
        <Grid item container spacing={3}>
          <Grid
            item
            onClick={() => setUploadDialog(true)}
            style={{ cursor: "pointer" }}
          >
            <Typography
              variant="body1"
              color="secondary"
              style={{
                fontWeight: "bold",
                color: theme.palette.common.tabFont,
              }}
            >
              Upload an image
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              color="secondary"
              style={{
                fontWeight: "bold",
                color: theme.palette.common.tabFont,
              }}
            >
              {file}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            id=""
            variant="outlined"
            placeholder="Write Query Description"
            label="Query Description *"
            fullWidth
            multiline
            className={
              theme.typography.myTheme.themeName == "Night"
                ? classes.myTextFieldStyle
                : classes.textFieldStyle
            }
            rows={6}
            color="primary"
            value={myquery.queryDescription}
            onChange={(event) => {
              setQuery({
                ...myquery,
                queryDescription: event.target.value,
                studentInstanceId: window.localStorage.studentInstanceId,
                loginUserId:
                  window.localStorage.roleId === 5
                    ? window.localStorage.parentUserId
                    : window.localStorage.userId,
              });
            }}
          />
        </Grid>

        <Grid item container justify="flex-end" spacing={2}>
          <Grid item>
            <Button variant="text" color="secondary" onClick={handleClear}>
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              style={{ color: "#ffffff" }}
              onClick={onPostQuery}
            >
              {loader === true ? (
                <CircularProgress color={theme.palette.secondary.light} />
              ) : (
                "Post A Query"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        color="secondary"
        maxFileSize={6000000}
        open={uploadDialog}
        dropzoneParagraphClass={classes.dropZone}
        dialogProps={{ className: classes.myDropZone }}
        onClose={handleUploadClose}
        onSave={(files) => handleOnUploadSubmit(files)}
        showPreviews={true}
        acceptedFiles={["image/*"]}
        showFileNamesInPreview={true}
        filesLimit={1}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openUploadExamSnack}
        autoHideDuration={6000}
        onClose={() => setUploadExamSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setUploadExamSnack(false)}
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={uploadExamStatus}>{uploadExamMessage}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
