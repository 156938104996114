import React, { useState, useEffect } from 'react'
import { AppBar, Avatar, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Slide, Toolbar, Typography, useScrollTrigger } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import academicLogo from "../assets/Trunkie-Academy-Logo.png";
import CloseButton from "@material-ui/icons/Close";
import { makeReqBody, url } from './store';
import { useHistory, useLocation } from "react-router-dom";
import GeneralInstructions from './GeneralInstructions';


const useStyle = makeStyles((theme) => ({
    subContainer: {
        ...theme.typography.subContainer,
        zIndex: theme.zIndex.drawer + 9999,
    },

    description: {
        "& h4, & p": {
            margin: "0"
        }
    },
    profileImg: {
        height: theme.spacing(9),
        width: theme.spacing(9),
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 999,
        background: "#a1dde5",
        height: "auto",
        width: "100vw",
        padding: "4px 0"
    },

    listItems: {
        "& li": {
            display: "flex",
            alignItems: "center",
            marginBottom: "1em",
        },
        "& li span": {
            display: "block",
            width: "1.8em",
            height: "1.8em",
            marginRight: "1em",
            marginLeft: "0.5em"
        }
    }
}));

const HideOnScroll = (props) => {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}


const HeaderAppBar = (props) => {
    const classes = useStyle();

    return (
        <HideOnScroll>
            <AppBar position="sticky" className={classes.appBar} color="secondary">
                <Toolbar>
                    <Grid item container alignItems="center" justify="space-between">
                        <Grid item sm={4} container direction='row' spacing={3} alignItems="center" >
                            <Grid item>
                                <Avatar
                                    alt="profle"
                                    className={classes.profileImg}
                                >
                                    <img src={window.localStorage.profilePic} alt="profle" width="100%" />
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography variant='subtitle1'>Candidate Name : <strong>{window.localStorage.name}</strong></Typography>
                            </Grid>
                        </Grid>
                        <Grid item >
                            <Typography variant="h5" style={{ fontWeight: "bold" }}>{props.examData && props.examData.smartQbtExamName}</Typography>
                        </Grid>
                        <Grid item container sm={4} justify='flex-end' alignItems='center'>
                            <Grid item style={{ flex: "auto" }}>
                                <Typography variant='body1'
                                    style={{
                                        width: "200px",
                                        backgroundColor: "green",
                                        padding: "3px 2px",
                                        textAlign: "center",
                                        borderRadius: "24px",
                                        color: "#fff"
                                    }}
                                >Remaining Time: {props.count > 0 ? <>{`0${props.hours}`}:{props.minutes < 10 ? `0${props.minutes}` : props.minutes}:{props.seconds < 10 ? `0${props.seconds}` : props.seconds} </> : "Time Over"}</Typography>
                            </Grid>
                            <Grid item>
                                <img
                                    src={academicLogo}
                                    alt="logo"
                                    width="120px"
                                />
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => {
                                    window.history.back()
                                }}>
                                    <CloseButton />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    )
};


const InstructionsDetails = (props) => {
    const classes = useStyle();
    const [agreed, setAgreed] = useState(false)

    return (
        <>
            <Grid item container style={{ backgroundColor: "#fffffff2", }} className={classes.description}>
                <Grid item container alignItems='center' justify='center' style={{ marginBottom: "1.5em" }}>
                    <Typography variant='h6' style={{ marginBottom: "1em", }}>
                        <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(props.description.examptrnDescription) }}></div>
                    </Typography>
                </Grid>

                {props.description.examPatternSubjectWise && props.description.examPatternSubjectWise.map((x, i) => (
                    <Grid item container key={i} >
                        <Grid item style={{ marginTop: "1em" }}>
                            <Typography variant='h5' style={{ fontWeight: "bold", marginBottom: "0.8em" }}>{x.subjectName}:</Typography>
                            <Typography variant='body1' style={{ display: "flex", }}>
                                <span style={{ paddingRight: "0.6em" }}>{i + 1}.</span>
                                <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(x.description) }}></div>
                            </Typography>
                            {x.identifierDetails.map((item, idx) => (
                                <ul style={{ listStyle: "upper-alpha" }} key={idx}>
                                    <li style={{ display: "flex", alignItems: "flex-start" }}>
                                        <span style={{ fontWeight: "bold", marginRight: "0.6em" }}>{item.identifierName} :</span>
                                        <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(item.info) }}></div>
                                    </li>
                                </ul>
                            ))}
                        </Grid>

                    </Grid>
                ))}


                <Grid item container style={{ marginTop: "1em" }}>
                    <Grid item>
                        <FormGroup>
                            <FormControlLabel
                                style={{ alignItems: "flex-start" }}
                                control={<Checkbox />}
                                label="I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare that I am not in possession of / not wearing / not carrying any prohibited gadget like mobile phone, bluetooth devices etc. /any prohibited material with me into the Examination Hall.I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include ban from future Tests / Examinations."
                                checked={agreed}
                                onChange={(e) => setAgreed(e.target.checked)}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item container alignItems='center' justify='center' style={{ margin: "3em 0" }}>
                        <Grid item sm={3}>
                            <Button
                                variant='contained'
                                color="secondary"
                                disabled={!agreed}
                                fullWidth
                                onClick={props.handleStartExam}
                            >
                                Start Exam
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


        </>
    )
}

export default function Instructions(props) {

    const classes = useStyle();
    const theme = useTheme();
    const location = useLocation();
    const history = useHistory()
    const [agreed, setAgreed] = useState(false)
    const [showInstructionsPage, setShowInstructionsPage] = useState(false);
    const [myLoader, setMyLoader] = useState(false);
    const [examData, setExamData] = useState();
    const [description, setDescription] = useState({});

    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(0)
    const [count, setCount] = useState(0);

    useEffect(() => {
        setExamData(location.state.examData);
        console.log(location.state.examData);

        let data = location.state.examData;
        let eTime = 0;
        eTime = new Date() - new Date(data.startDateTime);
        eTime = Math.floor(eTime / 1000)
        console.log(eTime)
        if (data.reset === "Yes") {
            setCount(data.totalTime*60)
        } else {
            setCount((data.totalTime*60) - eTime)
        }
    }, [location]);

    const secondsToTime = (secs) => {
        var hours = Math.floor(secs / (60 * 60));
        var divisor_for_minutes = secs % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);
        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);
        return {
            h: hours,
            m: minutes,
            s: seconds
        };
    }
    useEffect(() => {
        if (count >= 0) {
            const secondsLeft = setInterval(() => {
                setCount((c) => c - 1);
                let timeLeftVar = secondsToTime(count);
                setHours(timeLeftVar.h);
                setMinutes(timeLeftVar.m);
                setSeconds(timeLeftVar.s);
            }, 1000);
            return () => clearInterval(secondsLeft);
        } else {
            console.log("timeout");
        }
    }, [count]);





    const handleProceed = () => {
        setMyLoader(true);
        try {
            fetch(url, makeReqBody(examData.examPatternSrc === "Branch" ? "SCHOOL_EXAM_PATTERN_GETBYID_BRANCH_EXAM_PATTERN" : "SCHOOL_EXAM_PATTERN_GET_BY_ID", {
                examPatternId: examData.epid
            }
            )).then((response) => response.json())
                .then((data) => {
                    let descriptionsData = {
                        examptrnDescription: data.respData[0].respMsg.result[0].examptrnDescription,
                        examPatternSubjectWise: data.respData[0].respMsg.result[0].examPatternSubjectWise.map(x => ({
                            description: x.description,
                            subject: x.subject,
                            subjectName: x.subjectName,
                            identifierDetails: x.identifierDetails.map(y => ({
                                info: y.info,
                                identifierName: y.identifierName,
                                identifierId: y.identifierId
                            }))
                        }))
                    };
                    setDescription(descriptionsData)
                })
        } catch (error) {
            console.log(error)
            setMyLoader(false);
        }
        setShowInstructionsPage(true);
    };

    const handleStartExam = () => {
        window.localStorage.setItem("description", JSON.stringify(description))
        history.push("/UploadSection/ExamWorks/SystemSmartExam")
    };


    return (
        <>

            <Grid
                item
                spacing={3}
                container
                direction='column'
                className={classes.subContainer}
            >
                <HeaderAppBar
                    examData={examData}
                    minutes={minutes}
                    seconds={seconds}
                    hours={hours}
                    count={count}
                />
                {showInstructionsPage === false ? <>
                    <GeneralInstructions
                        setAgreed={setAgreed}
                        agreed={agreed}
                        handleProceed={handleProceed}
                        proceed={true}
                    />
                </> : <InstructionsDetails
                    description={description}
                    handleStartExam={handleStartExam}
                />
                }
            </Grid>
        </>
    )
}
