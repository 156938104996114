import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Dialog, DialogContent, TextField, MenuItem, IconButton, Typography, Backdrop } from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { url, makeReqBody } from "../Ui/store.js";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import LineGraph from "../GlobalComponent/LineGraph";
import { findIndex, groupBy } from "lodash";


const useStyle = makeStyles((theme) => ({
    subContainer: {
        ...theme.typography.subContainer,
        paddingTop: 0,
    },
    dialogContainer: {
        "& .MuiDialog-paper": {
            width: "60em",
        },
        "& MuiDialog-paperWidthSm": {
            maxWidth: "100em",
        },
        "& .MuiDialogContent-root": {
            minHeight: '12em',
        },
    },
    customDropdown: {
        '& .MuiInputLabel-outlined': {
            zIndex: 1,
            transform: 'translate(14px, 14px) scale(1)',
            pointerEvents: 'none',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px 14px',
        },
        '& .Mui-disabled': {
            background: '#eee',
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: "#fff",
    },
}));

const styles = (theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.common.black,
    },
    DialogHeader: {
        backgroundColor: theme.palette.common.ltgrey,
        marginBottom: '0.7em',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    const theme = useTheme();
    return (
        <MuiDialogTitle
            disableTypography
            {...other}
            className={classes.DialogHeader}
        >
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon style={{ color: theme.palette.common.tabFont }} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


const Graph = (props) => {
    return (
      <React.Fragment>
        <Grid item container direction="row" justify="space-between">
          <Grid
            item
            container
            direction="column"
            sm
            style={{ marginRight: "0.5em" }}
          >
            <Grid item container>
              {
                 <LineGraph
                 width="100%"
                 data={props.perData}
                />
              }
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

export default function SmartExamGraph(props) {
    const theme = useTheme();
    const classes = useStyle();

    const [reportType, setReportType] = useState("");
    const [monthVal, setMonthVal]  = useState("");


    const [myloader, setMyLoader] = useState(false);
    const [monthData, setMonthData] = useState({});
    const [yearData, setYearData] = useState({});
    const [monthDropdown, setMonthDropdown] = useState([]);

    const month = [
        { "value": "01", "text": "JAN" },
        { "value": "02", "text": "FEB" },
        { "value": "03", "text": "MAR" },
        { "value": "04", "text": "APR" },
        { "value": "05", "text": "MAY" },
        { "value": "06", "text": "JUN" },
        { "value": "07", "text": "JUL" },
        { "value": "08", "text": "AUG" },
        { "value": "09", "text": "SEP" },
        { "value": "10", "text": "OCT" },
        { "value": "11", "text": "NOV" },
        { "value": "12", "text": "DEC" },
    ];

    const getRandomColor = () => {
        var letters = "0123456789ABCDEF".split("");
        var color = "#";
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    useEffect(() => {
            setMyLoader(true);

        try {
            fetch(
                url,
                makeReqBody("SCHOOL_GET_ACADEMIC_MONTH_YEAR_BY_SEC_ID", {
                    sectionId: window.localStorage.sectionId
                })
            )
                .then((response) => response.json())
                .then((data) => {                    
                    if (data.respData[0].respMsg.result && data.respData[0].respMsg.result.months.length !== 0){
                        setMonthDropdown(data.respData[0].respMsg.result.months)
                    }

                })
                .catch((data) => {
                    console.error(data);
                });
        } catch (error) {
            console.error(error);
        }
    }, [window.localStorage.sectionId]);
    

    useEffect(() => {
        if(monthVal != ""){
            setMyLoader(true);
        let dataset = {
          labels: [],
          datasets: [],
        };
        try {
            fetch(
                url,
                makeReqBody("SCHOOL_GET_WEEKLY_SE_COUNT", {
                    month: monthVal,
                    studentInstanceId: props.studentInstanceId != "" ? props.studentInstanceId : window.localStorage.studentInstanceId,
                    subjectId: window.localStorage.teacherType === "Subject" ? window.localStorage.subjectId : "",
                })
            )
                .then((response) => response.json())
                .then((data) => {
                    dataset.labels = Object.keys(data.respData[0].respMsg.result);
                    Object.keys(data.respData[0].respMsg.result).map((item, index) => {
                        let grouped = groupBy(
                          data.respData[0].respMsg.result[item],
                          (item) => item.subjectName
                        );        
                        for (let x in grouped) {
                          let mydata = [];
                          let topush = false;
                          grouped[x].map((item, index) => {
                            let newindex = findIndex(
                            dataset.datasets,
                              (o) => o.label === x
                            );
                            console.log(newindex);
                            if (newindex >= 0) {
                              console.log(dataset.datasets[newindex]);
                              dataset.datasets[newindex].data.push(
                                item.count
                              );
                              topush = false;
                            } else {
                              mydata.push(item.count);
                              topush = true;
                            }
                          });
            
                          if (topush) {
                            let mycolor = getRandomColor();
                            dataset.datasets.push({
                              label: x,
                              data: mydata,
                              lineTension: 0,
                              fill: false,
                              borderColor: mycolor,
                              pointBackgroundColor: mycolor,
                              pointRadius: 4,
                              borderWidth: 1.5,
                            });
                        }
                    }
                });
                    setMonthData();
                    setMonthData(dataset);
                    setMyLoader(false);
                })
                .catch((data) => {
                    console.error(data);
                });
        } catch (error) {
            console.error(error);
        }
    }

    }, [monthVal]);

    useEffect(() => {
        if(reportType == "year"){
            setMyLoader(true);
        let dataset = {
          labels: [],
          datasets: [],
        };
        try {
            fetch(
                url,
                makeReqBody("SCHOOL_GET_MONTHLY_SE_COUNT", {
                    studentInstanceId: props.studentInstanceId != "" ? props.studentInstanceId : window.localStorage.studentInstanceId,
                    subjectId: window.localStorage.teacherType === "Subject" ? window.localStorage.subjectId : "",
                    sectionId: window.localStorage.sectionId
                })
            )
                .then((response) => response.json())
                .then((data) => {
                    dataset.labels = Object.keys(data.respData[0].respMsg.result);
                    Object.keys(data.respData[0].respMsg.result).map((item, index) => {
                        let grouped = groupBy(
                          data.respData[0].respMsg.result[item],
                          (item) => item.subjectName
                        );        
                        for (let x in grouped) {
                          let mydata = [];
                          let topush = false;
                          grouped[x].map((item, index) => {
                            let newindex = findIndex(
                            dataset.datasets,
                              (o) => o.label === x
                            );
                            console.log(newindex);
                            if (newindex >= 0) {
                              console.log(dataset.datasets[newindex]);
                              dataset.datasets[newindex].data.push(
                                item.count
                              );
                              topush = false;
                            } else {
                              mydata.push(item.count);
                              topush = true;
                            }
                          });
            
                          if (topush) {
                            let mycolor = getRandomColor();
                            dataset.datasets.push({
                              label: x,
                              data: mydata,
                              lineTension: 0,
                              fill: false,
                              borderColor: mycolor,
                              pointBackgroundColor: mycolor,
                              pointRadius: 4,
                              borderWidth: 1.5,
                            });
                        }
                    }
                });
                    setYearData();
                    setYearData(dataset);
                    setMyLoader(false);
                    
                })
                .catch((data) => {
                    console.error(data);
                });
        } catch (error) {
            console.error(error);
        }
    }

    }, [reportType == "year"]);



    



    const clearData = () => {
        setReportType("");
        setMonthVal("");
    }

    useEffect(() => {
        if(props.closeExamGraph){
            clearData();
        }
    }, [props.closeExamGraph]);


    return (
        <React.Fragment>
            <Dialog
                open={props.openExamGraph}
                onClose={props.closeExamGraph}
                aria-labelledby="customized-dialog-title"
                className={classes.dialogContainer}
                fullWidth
                maxWidth="xl"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={props.closeExamGraph}
                >
                    Smart Exam Graph
                </DialogTitle>
                <DialogContent
                    style={{ scrollbarWidth: "none" }}
                >
                    <Grid
            container
            direction="row"
            spacing={2}
          >
            <Grid item md={6}>         
            <TextField
                        id="subject"
                        label="Select Report Type*"
                        select
                        multiple
                        name="reportType"
                        variant="outlined"
                        fullWidth
                        value={reportType}
                        className={classes.customDropdown}
                        onChange={(e) => {
                        setReportType(e.target.value);
                        }}
                    >
                        <MenuItem value="month">
                            Month Wise
                        </MenuItem>

                        <MenuItem value="year">
                            Year Wise
                        </MenuItem>
                    </TextField>           
                    </Grid>
                    {reportType == "month" && <Grid item md={6}>
                    <TextField
                        id="month"
                        label="Select Month*"
                        select
                        multiple
                        name="month"
                        variant="outlined"
                        fullWidth
                        value={monthVal}
                        className={classes.customDropdown}
                        onChange={(e) => {
                         setMonthVal(e.target.value);
                        }}
                    >
                        {monthDropdown && monthDropdown.map((item, index) => (<MenuItem key={index} value={item}>
                            {item} 
                        </MenuItem>))}
                    </TextField>
                    </Grid>}
                    </Grid>
                    {reportType == "year" && (
                        <>
                        <Typography variant="body1"
                            style={{
                                fontWeight: "bold",
                                color: theme.palette.common.tabFont,
                                textAlign: 'center',
                                position: 'relative',
                                top: '15px'
                            }}
                        >
                            Year Wise Data
                        </Typography>
                        {myloader === false ?
                         (
                            <Graph 
                                perData={yearData}
                            />) : (<CircularProgress color="primary" />
                        )
                         }
                         </>)
                    }

                    {reportType == "month" && monthVal != "" &&(
                        <>
                        <Typography variant="body1"
                           style={{
                            fontWeight: "bold",
                            color: theme.palette.common.tabFont,
                            textAlign: 'center',
                            position: 'relative',
                            top: '15px'
                        }}
                        >
                            Month Wise Data
                        </Typography>
                        {myloader === false ?
                        (
                        <Graph 
                        perData={monthData}
                        />) : (<CircularProgress color="secondary" />
                        )}
                        </>
                        )
                    }
                </DialogContent>
            </Dialog>

        </React.Fragment>
    );
}
