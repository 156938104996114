import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  AppBar,
  Toolbar,
  Switch,
  Card,
  List,
  ListItemText,
  ListItem, Tooltip
} from "@material-ui/core";
import DisplayPdf from "./DisplayPdf";

import MuiAlert from "@material-ui/lab/Alert";

import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";

import Rating from "@material-ui/lab/Rating";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Draggable from "react-draggable";
import Videojs from "./videoplayer";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? "#616161"
        : "transparent",
    minwidth: "30em",
  },
  tableHead: {
    "& .MuiTableCell-head": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.ltgrey
        : theme.palette.common.white,
    width: "100%",
  },
  toolBar: {
    ...theme.mixins.toolbar,
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },
  dropZone: {
    color: "black !important",
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    fontSize: "0.85rem",
  },
  videoAppBar: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.common.black,
  },
  switchButton: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.light,
    },

    "& .MuiSwitch-colorSecondary": {},
  },

  pdfContainer3: {
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },

    "& .MuiDialog-paper": {
      scrollbarWidth: "none",
    },
  },

  pdfContainer: {
    marginTop: "none",
  },

  pdfContainer2: {
    width: "200em",
  },
  titlebar: {
    //  height: "6px",
    backgroundColor: theme.palette.common.ltgrey,
  },
  pdfFix: {
    "& .MuiDialog-paper": {
      scrollbarWidth: "none",
    },
  },
  margin0: {
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
  },
  questionCode: {
    "& .MuiListItemText-primary":{
      color: 'blue',
    },
  },
  pdfScroll: {
    overflowY: "auto",
    
    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
    "& .MuiDialogContent-root": {
      minHeight: '12em',        
    }
  },
  iconButton:{
    padding: '6px',
},
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function CompGuideWorkspace(props) {
  const classes = useStyle();
  const theme = useTheme();

  const [snack, setSnack] = useState(false);
  const [questionOpen, setQuestionOpen] = useState(false);
  const [status] = useState("");
  const [message] = useState("");

  const [videoDialougeOpen, setVideoDialougeOpen] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [hrefData, setHrefData] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [displayFullscreen, setDisplayFullscreen] = React.useState(false);

  const [imageOpen, setImageOpen] = useState(false);
  const [myFileType, setMyFileType] = useState("");

  const handleVideoOpen = (e, href, fileType) => {
    setVideoDialougeOpen(true);
    setHrefData(href);
    setMyFileType(fileType);
    //.localStorage.setItem("idleDisable", true);
  };

  const handleVideoClose = (event) => {
    setVideoDialougeOpen(false);
    //window.localStorage.removeItem("idleDisable");
  };

  const videoJsOptions = {
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],

    controls: true,
    sources: [
      {
        src: hrefData,
      },
    ],
  };

  useEffect(() => {
    if (questionOpen === true) {
      setTimeout(() => {
        document.getElementById("displayQuestion").innerHTML = hrefData;
      }, 1000);
    }
  }, [questionOpen]);

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleImageOpen = (e, href, fileType) => {
    setImageOpen(true);
    setHrefData(href);
    setMyFileType(fileType);
    //window.localStorage.setItem("idleDisable", true);
  };

  const handlePdfOpen = (e, href, fileType) => {
    setOpen(true);
    setHrefData(href);
    setMyFileType(fileType);
    //window.localStorage.setItem("idleDisable", true);
  };

  const handleQuestionOpen = (e, href) => {
    //let myString = window.atob(href);
    let myString = decodeURIComponent(href);
    console.log(myString);
    setHrefData(myString);

    setMyFileType("question");
    setQuestionOpen(true);
    //window.localStorage.setItem("idleDisable", true);
  };

  const handlepdfClose = (event) => {
    setOpen(false);
    // window.localStorage.removeItem("idleDisable");
  };
  const toggleView = (event) => {
    setDisplayFullscreen(!displayFullscreen);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setHrefData({});
          props.setRoute("list");
        }}
        style={{ color: "white", marginLeft: "1em", marginBottom: "0.4em" }}
      >
        <ArrowBackIcon style={{ color: "white" }} />
      </Button>
      {hrefData && hrefData.trim() !== "" ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            console.log(myFileType);
            myFileType === "pdf"
              ? setOpen(true)
              : myFileType === "question"
              ? setQuestionOpen(true)
              : myFileType === "png" ||
                myFileType === "jpg" ||
                myFileType === "jpeg"
              ? setImageOpen(true)
              : myFileType === "video" ||
                myFileType === "mp4" ||
                myFileType === "MOV" ||
                myFileType === "mov" ||
                myFileType === "mp3" ||
                myFileType === "3gp"
              ? setVideoDialougeOpen(true)
              : console.log("unrecognized file");
          }}
          style={{ color: "white", marginBottom: "0.4em", marginLeft: "1em" }}
        >
          Open Previous Dialog
        </Button>
      ) : undefined}
      <Grid
        container
        direction="row"
        //justify="space-between"
        // spacing={2}
        //style={{ width: "85vw" }}
        className={`${classes.pdfScroll}`}
      >
        <Grid item container sm={8} md={8}lg={8}>
          <div className="customPdfScroll" style={{height: "500px", overflowY: 'auto'}}>
          <DisplayPdf
            data={props.hrefData.guideUrl}
            displayFullscreen={true}
            myWidth={700}
          />
          </div>
        </Grid>
        <Grid
          item
          container
          sm={4}
          md={4}
          lg={4}
          // style={{ marginLeft: "-1.5em" }}
          direction="column"
        >
          <Grid item style={{ marginBottom: "1em" }}>
            <Card elevation={3} style={{ width: "20em" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", color: "#000", margin: 10 }}
              >
                Rating
              </Typography>
              <Rating
                name="read-only"
                value={
                  props.hrefData.impRating && props.hrefData.impRating.rating
                }
                readOnly
                style={{ margin: 10, marginTop: 0 }}
              />
            </Card>
          </Grid>
          <Grid item style={{ marginBottom: "1em" }}>
            <Accordion elevation={3} style={{ width: "20em" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", color: "#000" }}
                >
                  Important Points
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ paddingTop: 0, paddingBottom: 0 }}>
                <List dense={true} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  {props.hrefData.importantPoints &&
                    props.hrefData.importantPoints.map((item, index) => (
                      <ListItem
                        onClick={(e) => {
                          item.target &&
                          item.target.toLowerCase() === "external"
                            ? window.open(item.imptPointUrl)
                            : item.fileType === "pdf"
                            ? handlePdfOpen(e, item.imptPointUrl, item.fileType)
                            : item.fileType === "png" ||
                              item.fileType === "jpg" ||
                              item.fileType === "jpeg"
                            ? handleImageOpen(
                                e,
                                item.imptPointUrl,
                                item.fileType
                              )
                            : item.fileType === "video" ||
                              item.fileType === "mp4" ||
                              item.fileType === "MOV" ||
                              item.fileType === "mov" ||
                              item.fileType === "mp3" ||
                              item.fileType === "3gp"
                            ? handleVideoOpen(
                                e,
                                item.imptPointUrl,
                                item.fileType
                              )
                            : console.log("unrecognized file");
                        }}
                        style={{
                          cursor: "pointer",
                          margin: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <ListItemText
                          style={{
                            color: "blue",
                            maxWidth: "10em",
                          }}
                          primaryTypographyProps={{
                            noWrap: true,
                          }}
                        >
                          {item.fileName && item.fileName}
                        </ListItemText>
                      </ListItem>
                    ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {
            //  <Grid item style={{ marginBottom: "1em" }}>
            //     <Accordion elevation={3}>
            //       <AccordionSummary
            //         expandIcon={<ExpandMoreIcon />}
            //         aria-controls="panel1a-content"
            //         id="panel1a-header"
            //       >
            //         <Typography
            //           variant="h6"
            //           style={{ fontWeight: "bold", color: "#000" }}
            //         >
            //           Q-Code Questions & Answers
            //         </Typography>
            //       </AccordionSummary>
            //       <AccordionDetails>
            //         <Typography>
            //           Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            //           Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
            //           eget.
            //         </Typography>
            //       </AccordionDetails>
            //     </Accordion>
            //   </Grid>
          }{" "}
          <Grid item style={{ marginBottom: "1em" }}>
            <Accordion elevation={3} style={{ width: "20em" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", color: "#000" }}
                >
                  Relevant Examples
                </Typography>
              </AccordionSummary>

              <AccordionDetails
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <List dense={true} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  {props.hrefData.relevantExamples &&
                    props.hrefData.relevantExamples.map((item, index) => (
                      <ListItem
                        onClick={(e) => {
                          item.target &&
                          item.target.toLowerCase() === "external"
                            ? window.open(item.exampleUrl)
                            : item.fileType === "pdf"
                            ? handlePdfOpen(e, item.exampleUrl, item.fileType)
                            : item.fileType === "png" ||
                              item.fileType === "jpg" ||
                              item.fileType === "jpeg"
                            ? handleImageOpen(e, item.exampleUrl, item.fileType)
                            : item.fileType === "video" ||
                              item.fileType === "mp4" ||
                              item.fileType === "MOV" ||
                              item.fileType === "mov" ||
                              item.fileType === "mp3" ||
                              item.fileType === "3gp"
                            ? handleVideoOpen(e, item.exampleUrl, item.fileType)
                            : console.log("unrecognized file");
                        }}
                        style={{
                          cursor: "pointer",
                          margin: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <ListItemText
                          style={{
                            color: "blue",
                            maxWidth: "10em",
                          }}
                          primaryTypographyProps={{
                            noWrap: true,
                          }}
                        >
                          {item.fileName && item.fileName}
                        </ListItemText>
                      </ListItem>
                    ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item style={{ marginBottom: "1em" }}>
            <Accordion elevation={3} style={{ width: "20em" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", color: "#000" }}
                >
                  {window.localStorage.roleId == "3"
                    ? "Teaching Tips"
                    : "Learning Tips"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ paddingTop: 0, paddingBottom: 0, maxWidth: "9em" }}
              >
                {window.localStorage.roleId == "3" ? (
                  <List
                    dense={true}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    {props.hrefData.teachingTips &&
                      props.hrefData.teachingTips.map((item, index) => (
                        <ListItem
                          onClick={(e) => {
                            item.target &&
                            item.target.toLowerCase() === "external"
                              ? window.open(item.tipURL)
                              : item.fileType === "pdf"
                              ? handlePdfOpen(e, item.tipURL, item.fileType)
                              : item.fileType === "png" ||
                                item.fileType === "jpg" ||
                                item.fileType === "jpeg"
                              ? handleImageOpen(e, item.tipURL, item.fileType)
                              : item.fileType === "video" ||
                                item.fileType === "mp4" ||
                                item.fileType === "MOV" ||
                                item.fileType === "mov" ||
                                item.fileType === "mp3" ||
                                item.fileType === "3gp"
                              ? handleVideoOpen(e, item.tipURL, item.fileType)
                              : console.log("unrecognized file");
                          }}
                          style={{
                            cursor: "pointer",
                            margin: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <ListItemText
                            style={{
                              color: "blue",
                              maxWidth: "10em",
                            }}
                            primaryTypographyProps={{
                              noWrap: true,
                            }}
                          >
                            {item.fileName && item.fileName}
                          </ListItemText>
                        </ListItem>
                      ))}
                  </List>
                ) : (
                  <List
                    dense={true}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    {props.hrefData.learningTips &&
                      props.hrefData.learningTips.map((item, index) => (
                        <ListItem
                          onClick={(e) => {
                            item.target &&
                            item.target.toLowerCase() === "external"
                              ? window.open(item.tipURL)
                              : item.fileType === "pdf"
                              ? handlePdfOpen(e, item.tipURL, item.fileType)
                              : item.fileType === "png" ||
                                item.fileType === "jpg" ||
                                item.fileType === "jpeg"
                              ? handleImageOpen(e, item.tipURL, item.fileType)
                              : item.fileType === "video" ||
                                item.fileType === "mp4" ||
                                item.fileType === "MOV" ||
                                item.fileType === "mov" ||
                                item.fileType === "mp3" ||
                                item.fileType === "3gp"
                              ? handleVideoOpen(e, item.tipURL, item.fileType)
                              : console.log("unrecognized file");
                          }}
                          style={{
                            cursor: "pointer",
                            margin: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <ListItemText
                            style={{
                              color: "blue",
                              maxWidth: "10em",
                            }}
                            primaryTypographyProps={{
                              noWrap: true,
                            }}
                          >
                            {item.fileName && item.fileName}
                          </ListItemText>
                        </ListItem>
                      ))}
                  </List>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item style={{ marginBottom: "1em" }}>
          <Card elevation={3} style={{ width: "20em" }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", color: "#000", margin: 10, cursor: 'pointer' }}
                onClick={(e) => handlePdfOpen(e, props.hrefData.quesPdfUrl, "pdf")}
              >
               Question Bank
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                fontSize="small"
                style={{ color: theme.palette.common.tabFont }}
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>

      <Draggable
        defaultPosition={{ x: 0, y: 0 }}
        position={null}
        //grid={[25, 25]}
        scale={1}
        // onStart={this.handleStart}
        // onDrag={this.handleDrag}
        // onStop={this.handleStop}
      >
        <Dialog
          fullScreen={displayFullscreen}
          open={open}
          //onClose={handlepdfClose}
          className={classes.pdfContainer3}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <AppBar className={classes.appBar} color="secondary">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handlepdfClose}
                aria-label="close"
              >
                <CloseIcon style={{ color: theme.palette.common.tabFont }} />
              </IconButton>
              <Typography variant="h6" className={classes.title}></Typography>
              <Switch
                checked={checked}
                onChange={toggleChecked}
                onClick={toggleView}
                color="primary"
              />
              <Typography style={{ color: theme.palette.common.tabFont }}>
                Toggle View
              </Typography>
              {/* <Tooltip
            title="Download Pdf"
            classes={{
              tooltip: classes.customTooltip,
            }}>
              <IconButton className={classes.iconButton} onClick={()=> window.open(hrefData)}>
                <GetAppOutlinedIcon color="primary"/>
              </IconButton>
            </Tooltip> */}
            </Toolbar>
          </AppBar>

          <Grid container direction="column" alignItems="center" className={`${classes.pdfScroll} customPdfScroll`}>
            <Grid item>
              <DisplayPdf
                data={hrefData}
                displayFullscreen={displayFullscreen}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Draggable>

      <Draggable
        defaultPosition={{ x: 0, y: 0 }}
        position={null}
        //grid={[25, 25]}
        scale={1}
        // onStart={this.handleStart}
        // onDrag={this.handleDrag}
        // onStop={this.handleStop}
      >
        <Dialog
          fullScreen={displayFullscreen}
          open={imageOpen}
          onClose={handleImageClose}
          aria-labelledby="customized-dialog-title"
          className={classes.pdfFix}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleImageClose}>
            {" "}
            <Switch
              checked={checked}
              onChange={toggleChecked}
              onClick={toggleView}
              color="primary"
            />
            <Typography style={{ color: theme.palette.common.tabFont }}>
              Toggle View
            </Typography>
          </DialogTitle>
          <DialogContent style={{ scrollbarWidth: "none" }}>
            <img
              src={hrefData}
              alt="viewer"
              style={{
                height: "auto",
                maxWidth: displayFullscreen === false ? "40em" : undefined,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              style={{ color: "white" }}
              onClick={handleImageClose}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Draggable>

      <Draggable
        defaultPosition={{ x: 0, y: 0 }}
        position={null}
        //grid={[25, 25]}
        scale={1}
        // onStart={this.handleStart}
        // onDrag={this.handleDrag}
        // onStop={this.handleStop}
      >
        <Dialog
          fullScreen={displayFullscreen}
          open={questionOpen}
          onClose={() => setQuestionOpen(false)}
          aria-labelledby="customized-dialog-title"
          className={classes.pdfContainer3}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setQuestionOpen(false)}
          >
            {" "}
            <Switch
              checked={checked}
              onChange={toggleChecked}
              onClick={toggleView}
              color="primary"
            />
            <Typography style={{ color: theme.palette.common.tabFont }}>
              Toggle View
            </Typography>
          </DialogTitle>
          <DialogContent style={{ scrollbarWidth: "none" }}>
            <div id="displayQuestion" style={{ minWidth: "30em" }} />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              style={{ color: "white" }}
              onClick={() => setQuestionOpen(false)}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Draggable>

      <Draggable
        defaultPosition={{ x: 0, y: 0 }}
        position={null}
        //grid={[25, 25]}
        scale={1}
        // onStart={this.handleStart}
        // onDrag={this.handleDrag}
        // onStop={this.handleStop}
      >
        <Dialog
          className={classes.videoDialouge}
          open={videoDialougeOpen}
          onClose={handleVideoClose}
        >
          <Toolbar
            variant="dense"
            style={{ backgroundColor: theme.palette.common.ltgrey }}
          >
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography></Typography>
              </Grid>
              <Grid item>
                {" "}
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleVideoClose}
                  aria-label="close"
                >
                  <CloseIcon style={{ color: theme.palette.common.tabFont }} />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>

          <Videojs {...videoJsOptions} width={500} />
        </Dialog>
      </Draggable>
    </React.Fragment>
  );
}
