import React, { useState, useEffect, createRef } from "react";
import { Grid, CircularProgress, Button, Typography, TextField, MenuItem, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { url, makeReqBody } from "./store.js";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import AddSmartExam from "../../Components/SmartExam/AddSmartExam";
import CustomTable from "../GlobalComponent/Table";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import SmartExamGraph from "../SmartExam/SmartExamGraph";
import BarChartIcon from '@material-ui/icons/BarChart';


const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
    paddingTop: 0,
  },
  datePicketSmartExam:{
    "& .Mui-disabled" :{
      color: theme.typography.myTheme.themeName == "Night" ? "#aaa" : "inherit",      

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.typography.myTheme.themeName == "Night" ? "#aaa" : "inherit", 
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.typography.myTheme.themeName == "Night" ? "#fff!important" : "inherit", 
    },
    "& .MuiInputLabel-outlined": {
      color: theme.typography.myTheme.themeName == "Night" ? "#fff!important" : "inherit", 
    },
    "& .MuiInputBase-input": {
      color: theme.typography.myTheme.themeName == "Night" ? "#fff!important" : "inherit", 
    },

  },
  smartExamFilterBtn: {
      "& .MuiButton-contained.Mui-disabled":{
        backgroundColor: theme.typography.myTheme.themeName == "Night" ? "#aaa" : "inherit", 
      }
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
    '& th': {
      fontWeight: 700,
    }
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
  cellWidth: {
    width: "25%",
  },
  customDropdown: {
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(14px, 10.5px) scale(1)',
      pointerEvents: 'none',
      color: theme.typography.myTheme.themeName == "Night" ? "#fff" : "inherit",
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px',
      color: theme.typography.myTheme.themeName == "Night" ? "#fff" : "inherit",
    },
    '& .Mui-disabled': {
      background: '#eee',
    },
    "& .MuiOutlinedInput-notchedOutline":{
      borderColor: theme.typography.myTheme.themeName == "Night" ? "#fff!important" : "inherit", 
    },
    "& .MuiSelect-icon":{
      color: theme.typography.myTheme.themeName == "Night" ? "#fff" : "inherit", 
    },
  },

  centerLoader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16vh',
  },
  customTooltip: {
    fontSize: "0.85rem",
  },
  graphBtn:{
    '& svg': {
      color: theme.typography.myTheme.themeName == "Night" ? "#aaa" : 'rgba(0, 0, 0, 0.26)',
  },
},
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SmartExam() {
  const theme = useTheme();
  const classes = useStyle();
  const [smartExams, setSmartExams] = useState(false);
  const [smartExamPopupType, setSmartExamPopupType] = useState("");
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filter, setFilter] = useState({
    fromDate: null,
    toDate: null,
  });
  const [dateforSub, setDateforSub] = useState("");
  const [smartExamIdSub, setSmartExamIdSub] = useState("");
  const [reload, setReload] = useState(false);
  const [message, setMessage] = useState("");
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [studentInstanceId, setStudentInstanceId] = useState("");

  // Smart Graph State
  const [openExamGraph, setOpenExamGraph] = useState(false);

  const headers = [
    { name: "Date", type: "text" },
    { name: "No. Of Smart Exams Taken", type: "text" },
    { name: "Action", type: "action" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_QUESTIONS_COUNT_FOR_SE",
              busiParams: {
                studentInstanceId: studentInstanceId != "" ? studentInstanceId : window.localStorage.studentInstanceId,
                fromDate: filter.fromDate != null ? moment(filter.fromDate).format("YYYY-MM-DD").toString() : null,
                toDate: filter.fromDate != null ? moment(filter.toDate).format("YYYY-MM-DD").toString() : null,
                subjectId: window.localStorage.teacherType === "Subject" ? window.localStorage.subjectId : "",
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let row = [];
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
              row.push([
                { title: item.date, type: "text" },
                { title: item.count, type: "text" },
                { title: item.count, type: "action", date: item.date, smartExamId: item.smartExamId },
              ]);
            })
            : row.push([
              { title: "No Data Available", type: "text" },
              { title: " ", type: " " },
              { title: " ", type: " " },
            ]);

          setRows(row);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    }
  }, [reload, studentInstanceId]);


  useEffect(() => {
    if (window.localStorage.roleId == "3" && window.localStorage.teacherType == "Subject") {
      try {
        fetch(
          url,
          makeReqBody("SCHOOL_GET_STUDENTS_BY_SECSUBSYLMAPPING_ID", {
            secSubSylMappingId: window.localStorage.secSylMappingId,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            let list = [];
            data.respData[0].respMsg.result && data.respData[0].respMsg.result.map((item) => {
              list.push({
                studentFirstName: item.studentFirstName,
                studentLastName: item.studentLastName,
                studentInstanceId: item.studentInstanceId
              })
            });

            setStudentList(list);
            console.log("List-----------", studentList);
          })
          .catch((data) => {
            console.error(data);
          });
      } catch (error) {
        console.error(error);
      }
    }

    if (window.localStorage.roleId == "3" && window.localStorage.teacherType == "Class") {
      try {
        fetch(
          url,
          makeReqBody("SCHOOL_SEARCH_STUDENT", {
            branchId: window.localStorage.branchId,
            sectionId: window.localStorage.sectionId,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            let list = [];
            data.respData[0].respMsg.result && data.respData[0].respMsg.result.map((item) => {
              list.push({
                studentFirstName: item.studentFirstName,
                studentLastName: item.studentLastName,
                studentInstanceId: item.studentInstanceId
              })
            });

            setStudentList(list);
            console.log("List-----------", studentList);
          })
          .catch((data) => {
            console.error(data);
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, [window.localStorage.roleId, window.localStorage.teacherType]);



  const handleOpenSmartExams = () => {
    setSmartExams(true);
    setSmartExamPopupType("Add");
  }

  const viewSmartExam = (row) => {
    setSmartExams(true);
    setDateforSub(row.date);
    setSmartExamIdSub(row.smartExamId);
    setSmartExamPopupType("View");
    console.log(row);
  }

  const closeSmartExamsPopup = () => {
    setSmartExams(false);
  }

  const refresh = () => {
    setReload(!reload);
  }

  const dateFilter = () => {
    if (filter.fromDate == null) {
      setMessage("Please Select From Date");
      setStatus("error")
      setSnack(true);
    }
    else if (filter.toDate == null) {
      setMessage("Please Select To Date");
      setStatus("error")
      setSnack(true);
    }
    else {
      setReload(!reload);
    }
  }

  // smart exam graph

  const openGraph = () => {
    setOpenExamGraph(true);
  }

  const closeExamGraph = () => {
    setOpenExamGraph(false);
  }



  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="row"
        spacing={1}
        className={classes.subContainer}
        justify="space-between"
      >
        <Grid item md={7}>
          <Grid
            item
            container
            direction="row"
            spacing={2}
            alignItems="center"
          >
            {window.localStorage.roleId == "3" &&
              <Grid item style={{ width: '12em' }}>
                <TextField
                  id="studentId"
                  label="Select Student"
                  select
                  multiple
                  name="student_Id"
                  variant="outlined"
                  fullWidth
                  value={studentInstanceId}
                  className={classes.customDropdown}
                  onChange={(e) => {
                    setStudentInstanceId(e.target.value);
                  }}
                >
                  {studentList && studentList.map((item, index) => (
                    <MenuItem key={index} value={item.studentInstanceId}>
                      {`${item.studentFirstName}  ${item.studentLastName}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            }
            <Grid item style={{ width: '12em' }} className={classes.datePicketSmartExam}>
              <DatePicker
                label="From Date"
                inputVariant="outlined"
                value={filter.fromDate}
                size="small"
                disableFuture
                format="DD/MM/YYYY"
                fullWidth
                color="primary"
                onChange={(date) =>
                  setFilter({
                    ...filter,
                    fromDate: moment(date),
                    toDate: null,
                  })
                }
                disabled={
                  window.localStorage.roleId == "3" && studentInstanceId == ""
                }
              />
            </Grid>
            <Grid item style={{ width: '12em' }} className={classes.datePicketSmartExam}>
              <DatePicker
                label="To Date"
                inputVariant="outlined"
                value={filter.toDate}
                minDate={filter.fromDate !== null ? filter.fromDate : ''}
                maxDate={filter.fromDate !== null ? new Date(filter.fromDate).setDate(new Date(filter.fromDate).getDate() + 30) : ''}
                size="small"
                disableFuture
                format="DD/MM/YYYY"
                fullWidth
                color="primary"
                onChange={(date) =>
                  setFilter({
                    ...filter,
                    toDate: moment(date),
                  })
                }
                disabled={
                  window.localStorage.roleId == "3" && studentInstanceId == ""
                }
              />
            </Grid>
            <Grid item style={{ width: '8.5em' }}  className={classes.smartExamFilterBtn}>
              <Button
                variant="contained"
                color="secondary"
                onClick={dateFilter}
                style={{ color: "white", padding: "7px 16px" }}
                disabled={
                  window.localStorage.roleId == "3" && studentInstanceId == ""
                }
              >
                Go
          </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
        >
          <Grid
            item
            container
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <Grid item>
              {loader === false &&
              <Tooltip
              title="Smart Exam Graph"
              classes={{
                tooltip: classes.customTooltip,
              }}
         >
                <IconButton
                  aria-label="close"
                  className={window.localStorage.roleId == "3" && studentInstanceId == "" ? classes.graphBtn : ''}
                  onClick={openGraph}
                  disabled={
                    window.localStorage.roleId == "3" && studentInstanceId == ""
                  }
                >
                  <BarChartIcon color="secondary" style={{fontSize: '32px'}}/>
                </IconButton>
                </Tooltip>
              }
            </Grid>
            {window.localStorage.roleId == "4" &&
              <Grid item>
                {loader === false &&
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(e) => {
                      handleOpenSmartExams();
                    }}
                    style={{ color: "white", padding: "7px 16px" }}
                  >
                    Create Smart Exams
        </Button>
                }
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      {loader === true ? (<Typography component="div" className={classes.centerLoader}><CircularProgress color="secondary" /></Typography>) : (<>
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.subContainer}
        >
          <CustomTable
            header={headers}
            rows={rows}
            viewSmartExam={viewSmartExam}
          />
        </Grid>
      </>)}

      <AddSmartExam
        smartExams={smartExams}
        closeSmartExamsPopup={closeSmartExamsPopup}
        smartExamPopupType={smartExamPopupType}
        dateforSub={dateforSub}
        smartExamIdSub={smartExamIdSub}
        AddSmartExam
        refresh={refresh}
        studentInstanceId={studentInstanceId}
      />

      <SmartExamGraph
        openExamGraph={openExamGraph}
        closeExamGraph={closeExamGraph}
        studentInstanceId={studentInstanceId}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snack}
        autoHideDuration={4500}
        onClose={() => setSnack(false)}
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
