import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  Grid,
} from "@material-ui/core";


const useStyle = makeStyles((theme) => ({
   
    dialogContainer: {
      "& .MuiDialog-paper": {
        width: "100vh",
        height: "50vh",
      },
      "& MuiDialog-paperWidthSm": {
        maxWidth: "100em",
      },
    },
  
    dialogwidth: {
      maxWidth: "100vh",
      maxHeight: "120vh",
    },
  
   
  }));


  export default function QuestionarrePhotoDialog(props) {
    const classes = useStyle();

    return(
        <Dialog
          open={props.open}
            onClose= {props.onClose}
          classes={{ paperWidthSm: classes.dialogwidth }}
          className={classes.dialogContainer}
        >
       
          <React.Fragment>

            <Grid container direction= "column" >
                <img alt = "" src=  {props.imageData} style= {{width: "100%", height: "100%"}}></img>
            
            </Grid>
          </React.Fragment>
     
        </Dialog>
    )
  }