import {
    Card,
    CardContent,
    makeStyles,
    Typography,
    useTheme,
    Grid
} from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
    myCard: {
        borderRadius: "9px",
        margin: " 0",
        padding: '0px 5px!important',
        overflow: 'visible',

        "& .MuiCardContent-root": {
            padding: '12px 16px'
        },
        "& h4": {
            fontWeight: '700',
            fontSize: "2em"
        },
        "& img": {
            maxWidth: '47px',
            maxHeight: '47px',
        },

        "& h4, span": {
            fontFamily: `'Montserrat', 'sans-serif'`,
            color: '#000'
        }
    },
    subjectwide: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
}));

export default function SmartAnalysisCard({
    name,
    val,
    selected,
    color,
    width,
    showRange,
    ...otherProps
}) {
    const classes = useStyle();
    const theme = useTheme();

    return (
        <Card
            className={classes.myCard}
            elevation={7}
            {...otherProps}
        >
            <CardContent style={{ padding: showRange === false ? "20px 16px" : "8px" }}>
                <Grid container spacing={2}>
                    <Grid item sm className={showRange === false && classes.subjectwide}>
                        {otherProps.showImg == true ?
                            <Grid item container style={{marginBottom: "12px"}}>
                                <Grid item sm>
                                    <Typography
                                        variant="h4"
                                        align="left"
                                        gutterBottom
                                        color="secondary"
                                        style={{ marginBottom: 0, color: color }}
                                    >
                                        {val}
                                    </Typography>
                                </Grid>
                                <Grid item sm style={{textAlign: "end"}}>
                                    <img src={otherProps.img}/>
                                </Grid>
                            </Grid> :
                            <Typography
                                variant="h4"
                                align="left"
                                gutterBottom
                                color="secondary"
                                style={{ marginBottom: 0, color: color }}
                            >
                                {val}
                            </Typography>
                        }
                        {showRange === true ?
                            <Grid item container justify="space-between">
                                <Typography
                                    variant="span"
                                    align="left"
                                    gutterBottom
                                >
                                    {name}
                                </Typography>
                                <Typography
                                    variant="span"
                                    align="right"
                                    gutterBottom
                                >
                                    {width}
                                </Typography>
                            </Grid> : <>
                                <Typography
                                    variant="span"
                                    align="right"
                                    gutterBottom
                                >
                                    {name}
                                </Typography>
                            </>}

                        {showRange === true &&
                            <Grid item container
                                style={{ backgroundColor: "#e7e7e7", height: "8px", borderRadius: "8px"}}
                            >
                                <Typography variant="subtitle1" style={{ width: width, backgroundColor: color, borderRadius: "8px" }}></Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
