import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles, fade, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useForm } from "./useForm.js";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { url } from "./store.js";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
    width: "34em",
  },
  textFieldStyle: {
    backgroundColor: fade(theme.palette.common.white, 0.7),
  },

  myTextFieldStyle: {
    "& .MuiFormLabel-root": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiInputBase-input": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ChangePassword(props) {
  const classes = useStyle();
  const theme = useTheme();
  const [values, handleChange] = useForm({
    username: window.localStorage.username,
    currentPassword: "",
    newPassword: "",
    confNewpassword: "",
  });
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [logStart, setLogStart] = useState(false);
  const [failMess, setFailMess] = useState("");
  const [password, setPassword] = useState({
    showPassword1: false,
    showPassword2: false,
    showPassword3: false,
  });

  const handleClickShowPassword1 = () => {
    setPassword({ ...password, showPassword1: !password.showPassword1 });
  };
  const handleClickShowPassword2 = () => {
    setPassword({ ...password, showPassword2: !password.showPassword2 });
  };
  const handleClickShowPassword3 = () => {
    setPassword({ ...password, showPassword3: !password.showPassword3 });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = (event, reason) => {
    setOpenError(false);
    setOpenSuccess(false);
  };

  const handleSuccess = () => {
    console.log("success");
    handleChange({
      target: {
        name: "",
        value: "",
      },
    });

    setOpenSuccess(true);
    setOpenError(false);
    setLogStart(false);
  };

  const handleFailure = (res) => {
    console.log("fail");
    setFailMess(res.message);
    setOpenSuccess(false);
    setOpenError(true);
    setLogStart(false);
  };
  const handleSubmit = async () => {
    setLogStart(true);
    const DATA = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "UAM_CHANGE_PSWRD",
            busiParams: values,
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "android",
        },
      }),
    });
    const RESPONSE = await DATA.json();
    console.log(RESPONSE);
    RESPONSE.respData[0].respMsg.status !== "Failure"
      ? await handleSuccess()
      : await handleFailure(RESPONSE.respData[0].respMsg);
  };

  return (
    <React.Fragment>
      <Grid
        container
        className={classes.subContainer}
        direction="column"
        spacing={3}
      >
        <Grid item>
          <TextField
            id=""
            name="currentPassword"
            type={password.showPassword1 ? "text" : "password"}
            value={
              values.currentPassword === undefined ? "" : values.currentPassword
            }
            variant="outlined"
            label="Current Password"
            fullWidth
            color="primary"
            onChange={handleChange}
            className={
              theme.typography.myTheme.themeName == "Night"
                ? classes.myTextFieldStyle
                : classes.textFieldStyle
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {password.showPassword1 ? (
                      <Visibility
                        style={{ color: theme.palette.common.tabFont }}
                      />
                    ) : (
                      <VisibilityOff
                        style={{ color: theme.palette.common.tabFont }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id=""
            error={
              values.newPassword === "" || values.currentPassword === undefined
                ? false
                : values.newPassword === values.currentPassword
                ? true
                : false
            }
            name="newPassword"
            type={password.showPassword2 ? "text" : "password"}
            value={values.newPassword === undefined ? "" : values.newPassword}
            variant="outlined"
            label="New Password"
            fullWidth
            color="primary"
            helperText={
              values.newPassword === "" || values.currentPassword === undefined
                ? undefined
                : values.newPassword === values.currentPassword
                ? "New password cannot be same as the old password"
                : undefined
            }
            onChange={handleChange}
            className={
              theme.typography.myTheme.themeName == "Night"
                ? classes.myTextFieldStyle
                : classes.textFieldStyle
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {password.showPassword2 ? (
                      <Visibility
                        style={{ color: theme.palette.common.tabFont }}
                      />
                    ) : (
                      <VisibilityOff
                        style={{ color: theme.palette.common.tabFont }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id=""
            error={values.newPassword === values.confNewpassword ? false : true}
            name="confNewpassword"
            type={password.showPassword3 ? "text" : "password"}
            value={
              values.confNewpassword === undefined ? "" : values.confNewpassword
            }
            variant="outlined"
            label="Confirm Password"
            fullWidth
            color="primary"
            onChange={handleChange}
            helperText={
              values.newPassword === values.confNewpassword
                ? undefined
                : "Confirm Password doesn't match"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword3}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {password.showPassword3 ? (
                      <Visibility
                        style={{ color: theme.palette.common.tabFont }}
                      />
                    ) : (
                      <VisibilityOff
                        style={{ color: theme.palette.common.tabFont }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className={
              theme.typography.myTheme.themeName == "Night"
                ? classes.myTextFieldStyle
                : classes.textFieldStyle
            }
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "#ffffff" }}
            fullWidth
            onClick={handleSubmit}
            disabled={
              (!values.currentPassword === undefined &&
                values.currentPassword.trim() == "") ||
              (!values.newPassword === undefined &&
                values.newPassword.trim() == "") ||
              (!values.confNewpassword === undefined &&
                values.confNewpassword.trim() == "") ||
              values.currentPassword === undefined ||
              values.newPassword === undefined ||
              values.confNewpassword === undefined ||
              values.newPassword !== values.confNewpassword
            }
          >
            {logStart ? (
              <CircularProgress color={theme.palette.secondary.dark} />
            ) : (
              `Submit`
            )}
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openError}
        autoHideDuration={6000}
        onClose={handleClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="error">{failMess}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="success">Password Successfully Changed!!</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
