import React, { useState, useEffect } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  TableBody,
  Backdrop,
  CircularProgress,
  Checkbox,
  Paper,
  Badge,
  MenuItem,
  Tooltip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  tooltipClasses,
} from "@material-ui/core";
import { makeReqBody, myUploadObject, urlmultipart } from "./store.js";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AvTimerRoundedIcon from "@material-ui/icons/AvTimerRounded";
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import InfoIcon from '@material-ui/icons/Info';
import { url } from "./store.js";
import SortIcon from "@material-ui/icons/Sort";
import { orderBy, max, groupBy, findIndex, cloneDeep, reverse } from "lodash";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Waypoint } from "react-waypoint";
import {
  AppBar,
  Dialog,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Divider,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import Visibility from "@material-ui/icons/Visibility";
import ReplyRoundedIcon from "@material-ui/icons/ReplyRounded";
import CloseIcon from "@material-ui/icons/Close";
import DisplayPdf from "./DisplayPdf";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Videojs from "./videoplayer.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import "video.js/dist/video-js.css";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ChartMaker from "./ChartMaker.js";
import TrendSetter from "./TrendSetter.js";
import { DatePicker } from "@material-ui/pickers";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import WritingMaterialVerify from "./WritingMaterialVerify.js";
import EditIcon from "@material-ui/icons/Edit";
import EExamVerify from "./EExamVerify.js";
import CompGuideWorkspace from "./CompGuideWorkspace.js";
import { red } from "@material-ui/core/colors";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import AreaChart from "./AreaChart.js";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import partiallyCorrectImg from "../assets/Partially_correct.png";
import correctImg from "../assets/Correct.png";
import wrongImg from "../assets/InCorrect.png";
import unattemptedImg from "../assets/Not_attempt.png";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyle = makeStyles((theme) => ({
  table: {
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? "#616161"
        : "transparent",
    minwidth: "30em",

    // maxHeight: "60vh",

    "& .MuiTableCell-root": {
      padding: "16px 10px"
    },
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    fontSize: "0.85rem",
  },

  smartStatuSTooltip: {
    fontSize: "0.7.5rem",
    backgroundColor: "#fff",
    color: "#000"
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
  tableHead: {
    "& .MuiTableHead-root": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.white
          : theme.palette.common.black,
      backgroundColor:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.ltgrey
          : theme.palette.common.white,
    },
    "& .MuiTableCell-head": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.white
          : theme.palette.common.black,
      backgroundColor:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.ltgrey
          : theme.palette.common.white,
    },

    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.ltgrey
        : theme.palette.common.white,
    width: "100%",
    "& .MuiTableRow-head": {
      // position: "sticky",
      // zIndex: 2,
    },
  },

  pdfFix: {
    "& .MuiDialog-paper": {
      scrollbarWidth: "none",
    },
  },
  myDropZone: {
    "& .MuiDialogContent-root": {
      scrollbarWidth: "none",
    },
  },
  toolBar: {
    ...theme.mixins.toolbar,
  },
  dropZone: {
    color: "black !important",
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },

  videoAppBar: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.common.black,
  },
  switchButton: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.light,
    },

    "& .MuiSwitch-colorSecondary": {},
  },
  pdfContainer: {
    marginTop: "none",
  },
  DialogContentContainer: {
    "& .MuiDialogContent-root": {
      backgroundColor:
        theme.typography.myTheme.themeName == "Night"
          ? "#1f1f1f"
          : theme.typography.myTheme.themeName == "Energy"
            ? "#fdf4ef"
            : "#e2fbff",
    },
  },

  pdfContainer2: {
    width: "200em",
  },
  titlebar: {
    //  height: "6px",
    backgroundColor: theme.palette.common.ltgrey,
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "100em",
      height: "45em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },

  dialogContainer1: {
    "& .MuiDialog-paper": {
      width: "50vw",
      height: "90vh",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },

  dialogwidth: {
    maxWidth: "100em",
    maxHeight: "80em",
  },

  dialogwidth1: {
    maxWidth: "100em",
    maxHeight: "100em",
  },

  pickerStyle: {
    "& .MuiInputBase-input": {
      color: theme.palette.common.tabFont,
    },
    "& .MuiOutlinedInput-colorSecondary": {
      color: theme.palette.common.tabFont,
    },
  },

  tableHeadings: {},

  myTextFieldStyle: {
    "& .MuiFormLabel-root": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiInputBase-input": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiSelect-icon": {
      color: theme.typography.myTheme.themeName == "Night" ? "#fff" : "inherit",
    },
  },

  pdfScroll: {
    overflowY: "auto",

    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
    "& .MuiDialogContent-root": {
      minHeight: '12em',
    }
  },
  iconButton: {
    padding: '6px',
  },
  innerHtml: {
    "& p": {
      margin: "0"
    },
  },
}));

const inverDirect = {
  asc: "desc",
  desc: "asc",
};

function ElevationScroll(props) {
  const classes = useStyle();

  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: false,
    threshold: 0,

    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 3 : 1,
    classes: trigger ? { root: classes.AppBar2 } : { root: classes.AppBar1 },
  });
}

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ChatRender = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const [myList, setMyList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [renderAg, setRenderAg] = useState(false);
  const [len, setLen] = useState(0);
  const [toLoad, setToLoad] = useState(false);
  const [goingUp, setGoingUp] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    console.log(toLoad);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_MSG_RES_BY_MSG_ID",
              busiParams: {
                messageId: props.list.messageId,
                offset: offset,
                teacher: window.localStorage.roleId == "3" ? true : false,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (
            data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.messageResList &&
            data.respData[0].respMsg.result.messageResList.length > 0 &&
            data.respData[0].respMsg.status == "Success"
          ) {
            let newData = cloneDeep(myList);
            let myDat = [];
            myDat.push(...data.respData[0].respMsg.result.messageResList);

            let myData = reverse(myDat);
            newData.unshift(...myData);

            setOffset(data.respData[0].respMsg.result.nextOffsetVal);
            setMyList(newData);
            setLen(newData.length - 1);
            //setToLoad(false);
          } else {
            //setOffset(offset - 10);
            setToLoad(false);
          }
          console.log(goingUp);
          console.log(toLoad);
          if (goingUp === false) {
            document
              .getElementById("last message")
              .scrollIntoView({ behavior: "smooth", block: "end" });
          }
          if (goingUp === true && toLoad === true) {
            document
              .getElementById("last message1")
              .scrollIntoView({ behavior: "smooth", block: "end" });
          }
          // setMessage(data.respData[0].respMsg.message);
        })
        .catch((data) => {
          console.error(data);
          setToLoad(false);
        });
    } catch (error) {
      console.log(error);
      setToLoad(false);
    }
  }, [renderAg]);

  return (
    <Grid container direction="column" spacing={3} id="mychatroom" style={{}}>
      {
        //   <Grid item>
        //   <TextField
        //     label={
        //       window.localStorage.roleId == 5 ? "Teacher Name" : "Student Name"
        //     }
        //     disabled
        //     fullWidth
        //     variant="outlined"
        //     value={
        //       window.localStorage.roleId == 5
        //         ? props.list.message.src == "Teacher"
        //           ? props.list.message.messageFrom
        //           : props.list.message.messageTo
        //         : props.list.message.src == "Teacher"
        //         ? props.list.message.messageTo
        //         : props.list.message.messageFrom
        //     }
        //   />
        //   {console.log(props)}
        // </Grid>
      }
      <Grid item container direction="column" spacing={1} id="mymessages">
        {myList.map((item, index) => (
          <Grid
            item
            key={index}
            style={{
              maxWidth: "20em",
              marginLeft:
                window.localStorage.roleId == 5
                  ? item.src == "Teacher"
                    ? undefined
                    : "auto"
                  : item.src == "Teacher"
                    ? "auto"
                    : undefined,
              marginRight:
                window.localStorage.roleId == 5
                  ? item.src == "Teacher"
                    ? "auto"
                    : undefined
                  : item.src == "Teacher"
                    ? undefined
                    : "auto",
            }}
          >
            {index === len ? (
              <Waypoint
                onEnter={() => {
                  setToLoad(true);
                  setGoingUp(true);
                }}
              />
            ) : undefined}
            {index === 0 ? (
              <Waypoint
                onEnter={() => {
                  console.log(toLoad);
                  if (toLoad === true) {
                    //  setOffset(offset + 10);
                    setRenderAg(!renderAg);
                  }
                }}
              />
            ) : undefined}
            <Paper
              elevation={2}
              style={{
                padding: "1em",
                borderRadius: 30,
                borderTopLeftRadius:
                  window.localStorage.roleId == 5
                    ? item.src == "Teacher"
                      ? 0
                      : 30
                    : item.src == "Teacher"
                      ? 30
                      : 0,
                borderBottomRightRadius:
                  window.localStorage.roleId == 5
                    ? item.src == "Teacher"
                      ? 30
                      : 0
                    : item.src == "Teacher"
                      ? 0
                      : 30,
                backgroundColor:
                  window.localStorage.roleId == 5
                    ? item.src == "Teacher"
                      ? theme.typography.myTheme.themeName == "Night"
                        ? "#7e7e7e"
                        : "white"
                      : theme.palette.secondary.main
                    : item.src == "Teacher"
                      ? theme.palette.secondary.main
                      : theme.typography.myTheme.themeName == "Night"
                        ? "#7e7e7e"
                        : "white",
              }}
            >
              <div id={index} />
              {
                //<Typography
                //     variant="body1"
                //     align={
                //       window.localStorage.roleId == 5
                //         ? item.src == "Teacher"
                //           ? "left"
                //           : "right"
                //         : item.src == "Teacher"
                //         ? "right"
                //         : "left"
                //     }
                //     style={{ fontWeight: "bold" }}
                //   >
                //     {` ${item.responseFrom}`}
                //   </Typography>
              }
              <Typography
                variant="subtitle1"
                align={
                  window.localStorage.roleId == 5
                    ? item.src == "Teacher"
                      ? "left"
                      : "right"
                    : item.src == "Teacher"
                      ? "right"
                      : "left"
                }
                style={{
                  color:
                    window.localStorage.roleId == 5
                      ? item.src == "Teacher"
                        ? theme.typography.myTheme.themeName == "Night"
                          ? "#ffffff"
                          : "black"
                        : "#ffffff"
                      : item.src == "Teacher"
                        ? "#ffffff"
                        : theme.typography.myTheme.themeName == "Night"
                          ? "#ffffff"
                          : "black",
                }}
                gutterBottom
              >
                {item.response}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  color:
                    window.localStorage.roleId == 5
                      ? item.src == "Teacher"
                        ? theme.typography.myTheme.themeName == "Night"
                          ? "#ffffff"
                          : "grey"
                        : "#ffffff"
                      : item.src == "Teacher"
                        ? "#ffffff"
                        : theme.typography.myTheme.themeName == "Night"
                          ? "#ffffff"
                          : "grey",
                }}
                align={
                  window.localStorage.roleId == 5
                    ? item.src == "Teacher"
                      ? "left"
                      : "right"
                    : item.src == "Teacher"
                      ? "right"
                      : "left"
                }
                gutterBottom
              >{`${moment(item.createdOn, "YYYY-MM-DD")
                .format("DD-MMM-YYYY")
                .toString()}`}</Typography>
            </Paper>

            <React.Fragment>
              {index === len ? <div id="last message" /> : undefined}
            </React.Fragment>
            <React.Fragment>
              {index === 2 ? <div id="last message1" /> : undefined}
            </React.Fragment>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const ExamAnalysisMaker = (props) => {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <React.Fragment>
      {window.localStorage.boardId > 6 ?
        <>
          {props.studentExamDetail.studentInstanceID !== 0 ?
            <Grid item container>
              <Grid item sm={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" color="secondary">
                  Student Name : {props.trendData.studentName}
                </Typography>
                <Typography
                  color="secondary"
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                  onClick={(e) => props.handleOpenDialog(e, props.studentExamDetail)}
                >
                  Report Card
                </Typography>
              </Grid>
            </Grid>
            : <Grid item container>
              <Grid item sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography
                  color="secondary"
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                  onClick={(e) => props.handleOpenDialog(e, props.studentExamDetail)}
                >
                  Report Card
                </Typography>
              </Grid>
            </Grid>}
        </> : <>
          {props.studentExamDetail.studentInstanceId !== 0 && window.localStorage.teacherType=== "Class" ?
            <Grid item container>
              <Grid item sm={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" color="secondary">
                  Student Name : {props.trendData.studentName}
                </Typography>
                <Typography
                  color="secondary"
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                  onClick={(e) => props.handleOpenDialog(e, props.studentExamDetail)}
                >
                  Report Card
                </Typography>
              </Grid>
            </Grid>
            : <Grid item container>
              <Grid item sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography
                  color="secondary"
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                  onClick={(e) => props.handleOpenDialog(e, props.studentExamDetail)}
                >
                  Report Card
                </Typography>
              </Grid>
            </Grid>}
        </>
      }
      <Grid item container direction="row" justify="space-between">
        <Typography variant="h6" color="secondary">
          <span style={{ marginRight: "3em" }}>Term : {props.termName}</span>
          <span>
            Class : {`${window.localStorage.className} - ${window.localStorage.sectionName} (${window.localStorage.academicYearDesc})`}
          </span>
        </Typography>
      </Grid>

      <Grid item container direction="row" justify="space-between">
        {/* {props.studentExamDetail.studentInstanceId !== 0 &&<> */}
        {props.toShow === true ? (
          <Grid
            item
            container
            direction="column"
            sm
            style={{ marginRight: "0.5em" }}
          >
            <Grid
              item
              container
              spacing={4}
              alignItems="center"
              style={{ marginBottom: "0.1em", display: "contents" }}
              sm
            >
              <Grid item>
                {window.localStorage.boardId > 6 ?
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "bold",
                    }}
                    color="secondary"
                  >
                    {props.studentExamDetail.studentInstanceID !== 0 ? `Overall Rank : ${props.perData.overallRank}` : 'Class Performance'}
                  </Typography>
                  :
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "bold",
                    }}
                    color="secondary"
                  >
                    {props.studentExamDetail.studentInstanceId !== 0 ? `Overall Rank : ${props.perData.overallRank}` : 'Class Performance'}
                  </Typography>
                }
              </Grid>
            </Grid>

            <Grid item container sm>
              <ChartMaker
                dataset={props.perData}
                percentage={true}
                negativeVal={window.localStorage.boardId > 6 ? true : false}
                showBarVal={window.localStorage.boardId > 6 ? true : false}
                legends={window.localStorage.boardId > 6 ? false : true}
              />
              {props.studentExamDetail.studentInstanceID !== 0 ?
                <Typography variant="body2" style={{ fontWeight: "600", textAlign: "center", margin: "auto" }}><span style={{ paddingRight: "10px" }}>Bar1: Class Performance</span> <span>Bar2: My Performance</span></Typography>
               : <Typography variant="body2" style={{ fontWeight: "600", textAlign: "center", margin: "auto" }}><span style={{ paddingRight: "10px" }}>Class Performance</span></Typography>
              }
              {/* <AreaChart
                width="100%"
                fullWidth
                data={props.perData}
                percentage={true}
                height="270px"
              /> */}
            </Grid>
          </Grid>
        ) : undefined}
        {/* </>} */}
        <Grid item container direction="column" sm>
          <Grid
            item
            container
            spacing={4}
            alignItems="center"
            justify="space-between"
            style={{ marginBottom: "0.1em" }}
            sm
          >
            <Grid item>
              <Typography
                variant="body1"
                style={{
                  fontWeight: "bold",
                }}
                color="secondary"
              >
                Trend Analysis
              </Typography>
            </Grid>
          </Grid>
          <Grid item container sm>
            {/* <TrendSetter data={props.trendData} percentage={true}/> */}
            <AreaChart
              fullWidth
              data={props.trendData}
              percentage={true}
              height="325px"
              negativeVal={window.localStorage.boardId > 6 ? true : false}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};



export default function TableMaker({
  columns,
  rows,
  renderAg,
  setRenderAg,
  handleReRender,
  setToReLoad,
  toReload,
  searchBox,
  markattendance,
  date,
  setDate,
  materialAdded,
  setMaterialAdded,
  groupId,
  addContributor,
  handleGroupListing,
  ...props
}) {
  const classes = useStyle();
  const theme = useTheme();
  const [myrows, setRows] = useState(rows);
  const [searchRows, setSearchRows] = useState(rows);
  const [sortDirection, setDirection] = React.useState("asc");
  const [columnToSort, setColumnToSort] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [displayFullscreen, setDisplayFullscreen] = React.useState(false);
  const [hrefData, setHrefData] = React.useState();
  const [hidePdfDownload, setHidePdfDownload] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [videoDialougeOpen, setVideoDialougeOpen] = React.useState(false);
  const [videoData, setVideoData] = React.useState();
  const [videoTitle, setVideoTitle] = React.useState("");
  const [uploadDialog, setUploadDialog] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [openUploadSnack, setUploadSnack] = useState("");
  const [openVigilance, setOpenVigilance] = useState(false);
  const [tableRender, setTableRender] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [vigilance, setVigilance] = useState({});
  const [mymax, setMax] = useState(100);
  const [examUploadSnack, setExamUploadSnack] = useState(false);
  const [examUploadSnackMessage, setExamUploadSnackMessage] = useState("");
  const [showtimer, setShowTimer] = useState(false);
  const [uploadExamDialog, setUploadExamDialog] = useState(false);
  const [uploadExamMessage, setUploadExamMessage] = useState("");
  const [uploadExamStatus, setUploadExamStatus] = useState("");
  const [openUploadExamSnack, setUploadExamSnack] = useState("");
  const [myremainingtime, setMyremainingtime] = useState();
  const [notiSnack, setNotiSnack] = React.useState(false);
  const [NotiStatus, setNotiStatus] = React.useState("");
  const [notiMessage, setNotiMessage] = React.useState("");
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [deleteDetails, setDeleteDetails] = useState({});
  const [teacherChat, setTeacherChat] = useState(false);
  const [chatData, setChatData] = useState({});
  const [messageStudent, setMessageStudent] = useState(false);
  const [studentMessage, setStudentMessage] = useState({});
  const [route, setRoute] = useState("table");
  const [verifyRow, setVerifyRow] = useState([]);
  const [verifyEExamRow, setVerifyEExamRow] = useState([]);
  const [replyReq, setReplyReq] = useState({
    employeeId: 0,
    loginUserId: 0,
    messageId: 0,
    message: "",
    src: "",
    studentInstanceId: 0,
    secSubSylMappingId: 0,
    sectionId: window.localStorage.sectionId,
  });
  const [trendData, setTrendData] = useState(false);
  const [backDrop, setBackDrop] = useState(false);
  const [newDataset, setNewDataset] = useState({});
  const [applyLeave, setApplyLeaveDialog] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState({});
  const [applyLeaveSnack, setApplyLeaveSnack] = useState(false);
  const [applyLeaveStatus, setApplyLeaveStatus] = useState("");
  const [applyLeaveMessage, setApplyLeaveMessage] = useState("");
  const [deleteDialog1, setDeleteDialog1] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({});
  const [mymessage, setMessage] = useState({
    employeeId: window.localStorage.employeeId,
    loginUserId: window.localStorage.userId,
    message: null,
    secSubSylMappingId: window.localStorage.secSylMappingId,
    sectionId: window.localStorage.sectionId,
    src: "Teacher",
    studentInstanceId: 0,
    searchForMessageId: true,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [annualClassAttendace, setAnnualClassAttendace] = useState({});
  const [attendanceDetails, setAttendanceDetails] = useState({});
  const [examAnalysisTeach, setExamAnalysisTeach] = useState(false);
  const [studentAttendance, setStudentAttendance] = useState({
    date: moment(date).format("YYYY-MM-DD").toString(),
    loginUserId: window.localStorage.userId,
    sectionId: window.localStorage.sectionId,
    studentInstance: [],
    subjectId: 0,
    timeTableId: 0,
  });
  const [addGroupContributor, setAddGroupContrbutor] = useState({
    branchId: window.localStorage.branchId,
    groupId: 0,
    loginUserId: window.localStorage.userId,
    secSubMappingId: window.localStorage.secSylMappingId,

    studentInstanceId: [],
  });
  const [selectAll, setSelectAll] = useState(false);
  const [selectAll1, setSelectAll1] = useState(false);
  const [updateAttendance, setUpdateAttendance] = useState(false);
  const [updateInfo, setUpdateInfo] = useState({});
  const [markConfirm, setMarkConfirm] = useState(false);
  const [chapterSelected, setChapterSelected] = useState({ chapterId: null });
  const [sectionSelected, setSectionSelected] = useState({});
  const [chapter, setChapter] = useState([]);
  const [section, setSection] = useState([]);
  const [resourceEditDialog, setResourceEditDialog] = useState(false);
  const [resourceEditDialog1, setResourceEditDialog1] = useState(false);
  const [myAttachment, setMyAttachment] = useState({
    attachment: "",
    fileName: "",
    fileType: "",
  });
  const [myLoader, setMyLoader] = useState(false);
  const [materialName, setMaterialName] = useState("");
  const [updateDetails, setUpdateDetails] = useState({});
  const [dropZone, setDropZone] = useState(false);
  const [dropZone1, setDropZone1] = useState(false);
  const [studentData, setStudentData] = useState({});

  const [eExamId, setEexamId] = useState(false);

  const [studentExamDetail, setStudentExamDetail] = useState(null);
  const handleUpdateAttendanceOpen = (e, details) => {
    setUpdateInfo(details);
    setUpdateAttendance(true);
  };
  const handleImageClose = () => {
    setImageOpen(false);
  };

  const [teacherDescription, setTeacherDescription] = useState("");

  useEffect(() => {
    setRows(rows);
  }, []);

  const handleOpenChapterSectionDialog = async (details) => {
    setUpdateDetails(details);
    setMaterialName(details.fileName);
    setTeacherDescription(details.teacherDescription)

    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_ALL_CHAPTER_SECTION",
              busiParams: {
                admin: false,
                sectionId: window.localStorage.sectionId,
                subjectId: window.localStorage.subjectId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let mychapters = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mychapters.push(item);
            });
          setChapter(mychapters);
          let index = findIndex(mychapters, (o) => o.chapterId);
          index >= 0
            ? setChapterSelected(mychapters[index])
            : console.log("chapter not found");
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_CH_SECTION_DETAILS_BY_CID",
              busiParams: {
                admin: false,
                id: details.chapterId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let mysections = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mysections.push(item);
            });
          setSection(mysections);
          let index = findIndex(mysections, (o) => o.chapterSectionId);
          index >= 0
            ? setSectionSelected(mysections[index])
            : console.log("section not found");
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setResourceEditDialog(true);
  };

  const handleOpenChapterSectionDialog1 = async (details) => {
    setUpdateDetails(details);
    setTeacherDescription(details.teacherDescription)
    if (details.target === "Internal" || details.target === "internal") {
      setMaterialName(details.fileName);
    } else {
      setMyAttachment({
        ...myAttachment,
        attachment: details.attachment,
        fileName: details.fileName,
      });
    }
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_ALL_CHAPTER_SECTION",
              busiParams: {
                admin: false,
                sectionId: window.localStorage.sectionId,
                subjectId: window.localStorage.subjectId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let mychapters = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mychapters.push(item);
            });
          setChapter(mychapters);
          let index = findIndex(mychapters, (o) => o.chapterId);
          index >= 0
            ? setChapterSelected(mychapters[index])
            : console.log("chapter not found");
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_CH_SECTION_DETAILS_BY_CID",
              busiParams: {
                admin: false,
                id: details.chapterId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let mysections = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mysections.push(item);
            });
          setSection(mysections);
          let index = findIndex(mysections, (o) => o.chapterSectionId);
          index >= 0
            ? setSectionSelected(mysections[index])
            : console.log("section not found");
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setResourceEditDialog1(true);
  };

  const handleChapterSelect = async (e) => {
    setChapterSelected(e.target.value);
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_CH_SECTION_DETAILS_BY_CID",
              busiParams: {
                admin: false,
                id: e.target.value.chapterId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let mysections = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mysections.push(item);
            });
          setSection(mysections);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    console.log(section);
  };

  const handleEditSubmit = (e) => {
    setMyLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPDATE_RESOURCE",
              busiParams: {
                attachment: myAttachment.attachment,
                branchId: window.localStorage.branchId,
                chapterId: chapterSelected.chapterId,
                chapterSectionId: sectionSelected.chapterSectionId,
                description: sectionSelected.sectionName,
                dueDate: "",
                fileName: myAttachment.fileName[0],
                fileType: myAttachment.fileType[1],
                loginUserId: window.localStorage.userId,
                name: chapterSelected.chapterName,
                resourceId: updateDetails.resourceId,
                secSubSylMappingId: window.localStorage.secSylMappingId,
                sectionId: window.localStorage.sectionId,
                subjectId: chapterSelected.subjectId,
                target: "internal",
                url: "string",
                teacherDescription: teacherDescription
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setNotiStatus("error")
            : setNotiStatus("success");
          setNotiSnack(true);
          setMyLoader(false);
          setMaterialAdded(!materialAdded);
          setMyAttachment({
            attachment: "",
            fileName: "",
            fileType: "",
          });
          setMaterialName("");
          setResourceEditDialog(false);
        })
        .catch((data) => {
          console.error(data);
          setUploadDialog(false);
        });
    } catch (error) {
      console.log(error);
      setUploadDialog(false);
    }
  };

  const handleEditSubmit1 = (e) => {
    setMyLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPDATE_RESOURCE",
              busiParams: {
                attachment: myAttachment.attachment,
                branchId: window.localStorage.branchId,
                chapterId: chapterSelected.chapterId,
                chapterSectionId: sectionSelected.chapterSectionId,
                description: sectionSelected.sectionName,
                dueDate: "",
                multipartReq: 1,
                fileName: myAttachment.fileName,
                fileType: myAttachment.fileType,
                fileId: myAttachment.fileId,
                loginUserId: window.localStorage.userId,
                name: chapterSelected.chapterName,
                resourceId: updateDetails.resourceId,
                secSubSylMappingId: window.localStorage.secSylMappingId,
                sectionId: window.localStorage.sectionId,
                subjectId: chapterSelected.subjectId,
                target: myAttachment.target,
                url: "string",
                teacherDescription: teacherDescription
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setNotiStatus("error")
            : setNotiStatus("success");
          setNotiSnack(true);
          setMyLoader(false);
          setMaterialAdded(!materialAdded);
          setMyAttachment({
            attachment: "",
            fileName: "",
            fileType: "",
            fileId: 0,
            target: "",
          });
          setMaterialName("");
          setResourceEditDialog(false);
        })
        .catch((data) => {
          console.error(data);
          setUploadDialog(false);
        });
    } catch (error) {
      console.log(error);
      setUploadDialog(false);
    }
  };

  const handleDropZone = () => {
    setDropZone(true);
  };

  const handleDropZone1 = () => {
    setDropZone1(true);
  };

  const handleSaveMaterial1 = async (files) => {
    setBackDrop(true);
    var mydata = new FormData();
    mydata.append("file", files[0]);
    const options = {
      headers: {
        Accept: "application/json",

        type: "formData",
      },
      method: "POST",
      body: mydata,
    };
    delete options.headers["Content-Type"];
    await fetch(urlmultipart, options)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setMyAttachment({
          ...myAttachment,
          attachment: data.fileURL,
          fileName: data.fileName,
          fileType: data.fileType,
          fileId: data.bucketId,
          target: "Internal",
        });
        setMaterialName(data.fileName);
        setDropZone1(false);
        setBackDrop(false);
      })
      .catch((data) => {
        console.error(data);
        setDropZone1(false);
        setBackDrop(false);
      });
  };
  const handleSaveMaterial = async (files) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".");
    let myFileType = files[0].type.split("/");

    setMyAttachment({
      ...myAttachment,
      attachment: newattachment,
      fileName: myFileName,
      fileType: myFileType,
    });
    setMaterialName(myFileName);
    setDropZone(false);
  };

  const handleDropZoneClose = () => {
    setDropZone(false);
  };

  const handleDropZoneClose1 = () => {
    setDropZone1(false);
  };

  const handleCloseEditDialog = () => {
    setResourceEditDialog(false);
  };

  const handleCloseEditDialog1 = () => {
    setResourceEditDialog1(false);
  };
  const moduleIdMapping = {
    Classwork: 13,
    GoldenNotes: 10,
    ConnectZone: 3,
    TeacherDetails: 4,
    TimeTable: 6,
    Calendar: 7,
    SubjectRecording: 8,
    TextBookLinks: 9,
    WritingMaterials: 11,
    ExamMaterials: 12,
    Homework: 14,
    Record: 15,
    Observation: 16,
    Assignment: 17,
    Project: 18,
    WriteWorks: 19,
    ExamWorks: 20,
    eExam: 21,
    ExamAnalysis: 22,
    SubjectManager: 23,
    Attendance: 24,
    Notification: 1,
    DiscussionForum: 26,
    PostAQuery: 27,
  };

  const handleTeacherChat = async (details, in1, in2) => {
    details.src ? setChatData(details) : setChatData({});

    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPDATE_MESSAGE_READ_STATUS",
              busiParams: {
                loginUserId:
                  window.localStorage.roleId == "5"
                    ? window.localStorage.parentUserId
                    : window.localStorage.userId,
                messageId: details.messageId,
                src: window.localStorage.roleId == "5" ? "Parent" : "Teacher",
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.respData[0].respMsg.status == "Failure") {
            console.log(".");
          } else {
            let newData = cloneDeep(searchRows);
            newData[in1][in2].fullDetails.messageRead = true;
            setSearchRows(newData);
          }
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setReplyReq({
      ...replyReq,
      employeeId:
        window.localStorage.roleId == 5
          ? details.employeeId
          : parseInt(window.localStorage.employeeId),
      loginUserId:
        window.localStorage.roleId == "5"
          ? window.localStorage.parentUserId
          : window.localStorage.userId,
      src: window.localStorage.roleId == "5" ? "Parent" : "Teacher",
      studentInstanceId: details.studentInstanceId,
      messageId: details.messageId,
    });

    setTeacherChat(true);

    // if (details.message.messageRead === false) {
    //   try {
    //     fetch(url, {
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //       },
    //       method: "POST",
    //       body: JSON.stringify({
    //         requestList: [
    //           {
    //             isEncrypt: false,
    //             transactionId: "0000001438241244",
    //             busiCode: "SCHOOL_ADD_MESSAGE_RESPONSE_RESPONSE",
    //             busiParams: {
    //               loginUserId:
    //                 window.localStorage.roleId == "5"
    //                   ? window.localStorage.parentUserId
    //                   : window.localStorage.userId,
    //               messageId: details.message.messageId,
    //             },
    //           },
    //         ],
    //         pubInfo: {
    //           appId: "appId",
    //           sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
    //           version: "21",
    //           timestamp: "20180314175419",
    //           lang: "en_US",
    //           userId: "1000016614",
    //           serviceId: "7021150585",
    //           circleId: "MU",
    //           ncsroute: "WE015",
    //           customerId: "1000016614",
    //           osType: "windows",
    //         },
    //       }),
    //     })
    //       .then((response) => response.json())
    //       .then((data) => {
    //         console.log(data);
    //       })
    //       .catch((data) => {
    //         console.error(data);

    //         // setError(true);
    //       });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    console.log(details);
  };

  const handleClose = () => {
    setMessageStudent(false);
  };

  const handleTeacherChatClose = () => {
    setTeacherChat(false);
  };

  const handleApplyLeaveOpenDialog = (e, details) => {
    console.log(details);
    setLeaveDetails(details);
    setApplyLeaveDialog(true);
  };

  const handleApplyLeaveCloseDialog = () => {
    setApplyLeaveDialog(false);
  };
  const handleApplyLeaveReject = () => {
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPDATE_STUDENT_ LEAVE",
              busiParams: {
                comments: leaveDetails.comments,
                leaveId: leaveDetails.leaveId,
                leaveStatus: 3,
                updatedBy: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setApplyLeaveMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setApplyLeaveStatus("error")
            : setApplyLeaveStatus("success");
          setApplyLeaveSnack(true);
          setInterval(() => {
            handleReRender(!renderAg);
          }, 1500);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setApplyLeaveDialog(false);
  };

  const handleApplyLeaveAccept = () => {
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPDATE_STUDENT_ LEAVE",
              busiParams: {
                comments: leaveDetails.comments,
                leaveId: leaveDetails.leaveId,
                leaveStatus: 2,
                updatedBy: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setApplyLeaveMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setApplyLeaveStatus("error")
            : setApplyLeaveStatus("success");
          setApplyLeaveSnack(true);
          setInterval(() => {
            handleReRender(!renderAg);
          }, 1500);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setApplyLeaveDialog(false);
  };

  const dataset = {
    labels: ["ToDo", "Upload Section", "home", "Resources", "Students Group"],
    datasets: [
      {
        label: "My Performance",
        backgroundColor: theme.palette.secondary.main,
        barThickness: "flex",
        barPercentage: 0.4,
        categoryPercentage: 0.4,
        // maxBarThickness: 45,
        borderColor: "#ff6d00",
        borderSkipped: "left",
        data: [75, 70, 70, 85, 70],
      },
    ],
  };

  const handleUpdateAttendance = (val) => {
    setBackDrop(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPDATE_ATTENDANCE",
              busiParams: {
                attendance: val,
                attendanceId: [updateInfo.attendanceId],
                loginUserId: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setApplyLeaveMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.status == "Failure"
            ? setApplyLeaveStatus("error")
            : setApplyLeaveStatus("success");
          setApplyLeaveSnack(true);
          setBackDrop(false);
          setUpdateAttendance(false);
          setTimeout(() => {
            handleReRender(!renderAg);
          }, 1500);
        })
        .catch((data) => {
          console.error(data);
          setBackDrop(false);
          setUpdateAttendance(false);
        });
    } catch (error) {
      console.log(error);
      setBackDrop(false);
      setUpdateAttendance(false);
    }
  };

  const provideResponse = () => {
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_ADD_MESSAGE",
              busiParams: replyReq,
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          console.log(data);
          setNotiMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.status == "Failure"
            ? setNotiStatus("error")
            : setNotiStatus("success");
          setNotiSnack(true);
          setReplyReq({ ...replyReq, message: "" });
          // setTimeout(() => {
          //   setRenderAg(!renderAg);
          // }, 1500);
        })
        .catch((data) => {
          console.error(data);

          // setError(true);
        });
    } catch (error) {
      console.log(error);
    }
    handleTeacherChatClose();
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const sortRows = (user, index) => {
    setColumnToSort(user);

    setDirection(columnToSort === user ? inverDirect[sortDirection] : "asc");
    let newRows = searchRows;

    let sec =
      user === "Roll No" ||
        user === "Roll No." ||
        user === "RollNo" ||
        user === "Total Attendance" ||
        user === "Passing Marks" ||
        user === "Total No Of Days" ||
        user === "Sr No." ||
        user === "Max Marks" ||
        user === "Rank"
        ? orderBy(
          newRows,
          (o) => parseInt(o[index].title),
          columnToSort === user ? inverDirect[sortDirection] : "asc"
        )
        : user === "Date" || user === "DueDate" || user === "Cut-Off Time"
          ? orderBy(
            newRows,
            (o) =>
              moment(o[index].title, "DD-MM-YYYY")
                .format("YYYY-MM-DD")
                .toString(),
            columnToSort === user ? inverDirect[sortDirection] : "asc"
          )
          : user === "Resubmit End Date"
            ? orderBy(
              newRows,
              (o) => o[index].date,
              columnToSort === user ? inverDirect[sortDirection] : "asc"
            )
            : user === "Schedule"
              ? orderBy(
                newRows,
                (o) => o[index].mytime,
                columnToSort === user ? inverDirect[sortDirection] : "asc"
              )
              : user === "Leave Duration"
                ? orderBy(
                  newRows,
                  (o) => o[index].sortDate,
                  columnToSort === user ? inverDirect[sortDirection] : "asc"
                )
                : orderBy(
                  newRows,
                  (o) => o[index].title.toLowerCase(),
                  columnToSort === user ? inverDirect[sortDirection] : "asc"
                );
    setSearchRows(sec);
    console.log(sec);
  };

  const handleUploadClose = () => {
    setUploadDialog(false);
  };

  const handleUploadExamClose = () => {
    setUploadExamDialog(false);

  };

  const handleVigilanceClose = () => {
    setOpenVigilance(false);
  };

  const handleImageOpen = (e, href) => {
    setImageOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };

  const handlePdfOpen = (e, href, hidePdfDownload) => {
    setOpen(true);
    setHrefData(href);
    setHidePdfDownload(hidePdfDownload);
    window.localStorage.setItem("idleDisable", true);
  };

  const handlePdfComp = (e, href) => {
    setHrefData(href);
    setRoute("Comp");
    window.localStorage.tableRoute = "Comp";
  };

  const handleVideoOpen = (e, href, title) => {
    setVideoDialougeOpen(true);
    setVideoData(href);
    setVideoTitle(title);
    console.log('Download Link', href)
    window.localStorage.setItem("idleDisable", true);
  };

  const handleUploadDialogOpen = (e, status, details) => {
    console.log("ssdds", details);
    if (status === "Completed") {
      console.log("Cannot Upload");
    } else {
      setUploadDialog(true);
    }
  };

  const handleSendMessage = () => {
    if (
      mymessage.employeeId === 0 ||
      mymessage.message === null ||
      mymessage.message === ""
    ) {
      setNotiMessage("Please Enter Required Details");
      setNotiStatus("warning");
      setNotiSnack(true);
    } else {
      try {
        fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            requestList: [
              {
                isEncrypt: false,
                transactionId: "0000001438241244",
                busiCode: "SCHOOL_ADD_MESSAGE",
                busiParams: mymessage,
              },
            ],
            pubInfo: {
              appId: "appId",
              sessionId: window.localStorage.sessionId,
              version: "21",
              timestamp: "20180314175419",
              lang: "en_US",
              userId: "1000016614",
              serviceId: "7021150585",
              circleId: "MU",
              ncsroute: "WE015",
              customerId: "1000016614",
              osType: "windows",
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            console.log(data);
            setNotiMessage(data.respData[0].respMsg.message);
            data.respData[0].respMsg.result.status == "Failure"
              ? setNotiStatus("error")
              : setNotiStatus("success");
            setNotiSnack(true);

            handleClose();
            setTimeout(() => {
              setRenderAg(!renderAg);
            }, 1500);
          })
          .catch((data) => {
            console.error(data);

            // setError(true);
          });
      } catch (error) {
        console.log(error);
      }
      handleClose();
    }
  };

  const handleUploadExamDialogOpen = (e, row, details) => {
    setStudentData(details)
    console.log(row);
    let rightnow = moment();
    let starttime = moment(row.timestart, "YYYY-MM-DD hh:mm:ss");
    let endtime = moment(row.timeend, "YYYY-MM-DD hh:mm:ss");
    let cutofftime = moment(row.cutoff, "YYYY-MM-DD hh:mm:ss");
    console.log(rightnow.diff(starttime, "days"));

    setMyremainingtime(cutofftime.diff(rightnow, "seconds"));
    if (details.resubmitExam === false) {
      if (row.title === null) {
        if (rightnow.diff(starttime) > 0 && rightnow.diff(cutofftime) < 0) {
          setUploadExamDialog(true);
        } else if (rightnow.diff(starttime) < 0) {
          setExamUploadSnack(true);
          setExamUploadSnackMessage("The exam has not yet started");
        } else if (rightnow.diff(cutofftime) > 0) {
          setExamUploadSnack(true);
          setExamUploadSnackMessage(
            "The time to upload exam solution has passed"
          );
        }
      } else {
        setExamUploadSnack(true);
        setExamUploadSnackMessage("Solution has already been uploaded");
      }
    } else {
      if (
        moment().diff(moment(details.resubmitEndDate, "YYYY-MM-DD HH:mm:ss")) <=
        0
      ) {
        setUploadExamDialog(true);
      } else {
        setExamUploadSnack(true);
        setExamUploadSnackMessage(
          "The time to upload exam solution has passed"
        );
      }
    }
  };
  const handleOnUploadExamSubmit = async (files) => {
    setBackDrop(true);
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".");
    let myFileType = files[0].type.split("/");
    myUploadObject[1].studentInstanceId = window.localStorage.studentInstanceId;
    myUploadObject[1].attachment = newattachment;
    myUploadObject[1].fileName = myFileName[0];
    myUploadObject[1].fileType = myFileType[1];
    myUploadObject[1].examType = studentData.examType;
    const bodyObjUpload = {
      requestList: [
        {
          isEncrypt: false,
          transactionId: "0000001438241244",
          busiCode: "SCHOOL_UPLOAD_EXAM_SOLUTION",
          busiParams: myUploadObject[1],
        },
      ],
      pubInfo: {
        appId: "appId",
        sessionId: window.localStorage.sessionId,
        version: "21",
        timestamp: "20180314175419",
        lang: "en_US",
        userId: "1000016614",
        serviceId: "7021150585",
        circleId: "MU",
        ncsroute: "WE015",
        customerId: "1000016614",
        osType: "windows",
      },
    };
    console.log(JSON.stringify(bodyObjUpload));
    const DATA = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(bodyObjUpload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        setUploadExamMessage(data.respData[0].respMsg.message);
        setUploadExamStatus(
          data.respData[0].respMsg.status === "Success" ? "success" : "error"
        );
        setUploadExamSnack(true);
        handleUploadExamClose();
        setTimeout(() => {
          // handleReRender(!renderAg);
          // setTableRender(!tableRender);
          setBackDrop(false);
          setToReLoad(!toReload);
        }, 2000);
      })

      .catch((data) => {
        console.error(data);
        setBackDrop(false);
        setUploadExamStatus("error");
        setUploadExamMessage(
          "OOps Something went wrong please try again later"
        );
        setUploadExamSnack(true);
        handleUploadExamClose();
      });
  };

  const handleOnUploadSubmit = async (files) => {
    setBackDrop(true);
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    console.log(attachment);
    let newattachment = attachment.split("base64,")[1];

    myUploadObject[0].userId = window.localStorage.userId;
    myUploadObject[0].solutionAttachment = newattachment;
    console.log(files[0].type);
    if (files[0].type.includes("application")) {
      myUploadObject[0].fileName = files[0].name.replace(".pdf", "");
      myUploadObject[0].fileType = files[0].type.replace("application/", "");
    } else if (files[0].type.includes("image")) {
      myUploadObject[0].fileName = files[0].name;
      myUploadObject[0].fileType = files[0].type.replace("image/", "");
    }
    const bodyObjUpload = {
      requestList: [
        {
          isEncrypt: false,
          transactionId: "0000001438241244",
          busiCode: "SCHOOL_UPDATE_TO_DO_TASK_TXN",
          busiParams: myUploadObject[0],
        },
      ],
      pubInfo: {
        appId: "appId",
        sessionId: window.localStorage.sessionId,
        version: "21",
        timestamp: "20180314175419",
        lang: "en_US",
        userId: "1000016614",
        serviceId: "7021150585",
        circleId: "MU",
        ncsroute: "WE015",
        customerId: "1000016614",
        osType: "windows",
      },
    };
    console.log(JSON.stringify(bodyObjUpload));
    const DATA = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(bodyObjUpload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        setUploadMessage(data.respData[0].respMsg.message);
        setUploadStatus(
          data.respData[0].respMsg.status === "Success" ? "success" : "error"
        );
        setUploadSnack(true);
      })
      .catch((data) => {
        console.error(data);
        setUploadStatus("error");
        setUploadMessage("OOps Something went wrong please try again later");
        setUploadSnack(true);
      });

    handleUploadClose();

    setTimeout(() => {
      // handleReRender(!renderAg);
      // setTableRender(!tableRender);
      setBackDrop(false);
      setToReLoad(!toReload);
    }, 2000);
  };

  const setUploadData = (e, arr) => {
    console.log(arr);
    myUploadObject[1].examTxnId = arr[0].examTxnId;
    myUploadObject[0].taskTxnId = arr[0].taskTxnId;
  };

  const videoJsOptions = {
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],

    controls: true,
    sources: [
      {
        src: videoData,
      },
    ],
  };

  const handleVigilanceOpen = (modules) => {
    console.log(modules);
    let newData = [];
    let dataset = {
      labels: [],
      datasets: [
        {
          label: "Time in Minutes",
          backgroundColor: theme.palette.secondary.main,
          barThickness: "flex",
          barPercentage: 0.4,
          categoryPercentage: 0.4,
          maxBarThickness: 20,
          borderColor: "#ff6d00",
          borderSkipped: "left",
          data: [],
        },
      ],
    };

    // modules.map((item, index) => {
    //   for (let x in moduleIdMapping) {
    //     if (moduleIdMapping[x] === item.id) {
    //       newData.push({ ...item, moduleName: x });
    //     }
    //   }
    // });
    // console.log(newData);

    modules.map((item, index) => {
      dataset.labels.push(item.name);
      dataset.datasets[0].data.push(item.idle + item.active);
    });

    let maximum = max(dataset.datasets[0].data);
    console.log(maximum);
    setMax(maximum);
    console.log(dataset);
    setVigilance(dataset);
    setOpenVigilance(true);
  };
  const handlepdfClose = (event) => {
    setOpen(false);
    window.localStorage.removeItem("idleDisable");
  };
  const handleVideoClose = (event) => {
    setVideoDialougeOpen(false);
    window.localStorage.removeItem("idleDisable");
  };
  const toggleView = (event) => {
    setDisplayFullscreen(!displayFullscreen);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleDeleteConfirmDialog = () => {
    handleDeleteResource(deleteDetails);
  };

  const handleDeleteConfirmDialog1 = async () => {
    setBackDrop(true);
    console.log(deleteInfo);
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_DELETE_GROUP_CONTRIBUTOR",
              busiParams: {
                groupContributorId: deleteInfo.groupContributorID,
                loginUserId: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          setNotiMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.status == "Failure"
            ? setNotiStatus("error")
            : setNotiStatus("success");
          setNotiSnack(true);
          setDeleteDialog1(false);
          setBackDrop(false);
          setTimeout(() => {
            handleGroupListing({ target: { value: groupId } });
          }, 2000);
        })
        .catch((data) => {
          console.error(data);
          setDeleteDialog1(false);
          setBackDrop(false);
          // setError(true);
        });
    } catch (error) {
      console.log(error);
      setDeleteDialog1(false);
      setBackDrop(false);
    }
  };

  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteOpen = (details) => {
    setDeleteDetails(details);
    setDeleteDialog(true);
  };

  const handleDeleteOpen1 = (details) => {
    setDeleteInfo(details);
    setDeleteDialog1(true);
  };
  const handleMessageOpen = (details) => {
    setStudentMessage(details);

    setMessage({ ...mymessage, studentInstanceId: details.studentInstanceId });
    setMessageStudent(true);
  };

  const handleVerifyWritingMaterial = (details) => {
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_GET_TO_DO_TASK_BY_TASK_ID",
            busiParams: {
              taskId: details.taskId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        let tempEvents = [];
        let row = [];

        if (data.respData[0].respMsg.result.length > 0) {
          data.respData[0].respMsg.result.map((myitem, index) => {
            tempEvents.push(myitem);
          });

          tempEvents.map((myitem, index) => {
            console.log(myitem);
            row.push([
              { title: myitem.studentName, type: "text" },
              { title: myitem.rollNo, type: "text" },
              { title: myitem.dueDate, type: "text" },
              { title: myitem.receviedDate, type: "text" },

              {
                title: myitem.verifiedDate,
                type: "text",
              },
              {
                title: myitem.fileName,
                type: myitem.fileType,
                href: myitem.attachment,
              },
              { title: myitem.comments, type: "text" },

              { title: myitem.taskTxnStatus, type: "status" },
              {
                title: "",
                type: "verifyWritingAction",
                fullDetails: { ...myitem, taskId: details.taskId },
              },
            ]);
          });
        } else {
          row.push([
            { title: "No Data Available", type: "text" },
            { title: " ", type: "text" },
            { title: " ", type: "text" },
            { title: " ", type: "text" },

            {
              title: " ",
              type: "text",
            },
            { title: " ", type: "text" },
          ]);
        }
        setVerifyRow(row);
      });
    setRoute("verify");
  };

  const handleVerifyEExam = (details) => {
    setEexamId(details.eExamId);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_GET_EXAM_TXN_BY_EXAM_ID",
            busiParams: {
              id: details.eExamId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        let tempEvents = [];
        let row = [];
        console.log(details);
        if (data.respData[0].respMsg.result.length > 0) {
          data.respData[0].respMsg.result.map((myitem, index) => {
            tempEvents.push(myitem);
          });

          tempEvents.map((myitem, index) => {
            console.log(myitem);
            row.push([
              { title: myitem.studentName, type: "text" },
              { title: myitem.rollNo, type: "text" },
              { title: myitem.subjectName, type: "text" },
              { title: `${myitem.marks} / ${myitem.totalMarks}`, type: "text" },
              {
                title: myitem.uploadMarksfileName,
                type: myitem.uploadMarksfileType,
                href: myitem.uploadMarksDetail,
              },
              {
                title: myitem.verifiedDate,
                type: "text",
              },
              {
                title: myitem.fileName,
                type: myitem.fileType,
                href: myitem.attachment,
              },

              { title: myitem.examTxnStatus, type: "status" },
              { title: myitem.comments, type: "text" },
              {
                title: "",
                type: "verifyWritingAction",
                fullDetails: { ...myitem, eExamId: details.eExamId },
              },
            ]);
          });
        } else {
          row.push([
            { title: "No Data Available", type: "text" },
            { title: " ", type: "text" },
            { title: " ", type: "text" },
            { title: " ", type: "text" },
            { title: " ", type: "text" },
            { title: " ", type: "text" },
            { title: " ", type: "text" },
            { title: " ", type: "text" },
            { title: " ", type: "text" },
            { title: " ", type: "text" },
          ]);
        }
        setVerifyEExamRow(row);
      });
    setRoute("verifyExam");
  };


  const handleDeleteResource = async (details) => {
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_DELETE_RESOURCE",
              busiParams: { id: details.resourceId },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          setNotiMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setNotiStatus("error")
            : setNotiStatus("success");
          setNotiSnack(true);
          setInterval(() => {
            setMaterialAdded(!materialAdded);
          }, 2000);
        })
        .catch((data) => {
          console.error(data);

          // setError(true);
        });
    } catch (error) {
      console.log(error);
    }
    handleDeleteClose();
  };

  const handleDialogOpen = (e, details) => {
    let newData = [];
    setAttendanceDetails(details);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_GET_STUDENT_ANNUAL_ATTENDANCE",
            busiParams: {
              year: parseInt(moment().format("YYYY").toString()),
              studentInstanceId: details.studentInstanceId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let tempEvents = [];
        let dataset = {
          labels: [],
          datasets: [
            {
              label: "Student Annual Attendance",
              backgroundColor: "#ff6d00",
              barThickness: "flex",
              barPercentage: 0.5,
              categoryPercentage: 0.6,
              maxBarThickness: 30,
              borderColor: "#ff6d00",
              borderSkipped: "left",
              data: [],
            },
          ],
        };
        console.log(data);
        data.respData[0].respMsg.result.monthly.map((item, index) => {
          dataset.labels.push(item.month);
          dataset.datasets[0].data.push(parseInt(item.perc.replace("%", "")));
        });

        console.log(dataset);

        setAnnualClassAttendace(dataset);
        // props.setForce(!props.force);
      })
      .then(() => {
        setOpenDialog(true);
      })
      .catch((data) => {
        console.error(data);

        // setError(true);
      });
  };


  const handleExamAnalysisClass = (e, details) => {
    setStudentExamDetail(details);
    let dataset = {
      perData: "",

      labels: [],
      datasets: details.studentInstanceId === 0 ? [
        {
          label: "Class Performance",
          backgroundColor: "#e0e0e0",
          borderColor: "#f57c00",
          barThickness: "flex",
          barPercentage: 0.5,
          categoryPercentage: 0.3,

          // maxBarThickness: 45,
          borderSkipped: "left",
          data: [],
        }
      ] : [
        {
          label: "Class Performance",
          backgroundColor: "#e0e0e0",
          borderColor: "#f57c00",
          barThickness: "flex",
          barPercentage: 0.5,
          categoryPercentage: 0.3,

          // maxBarThickness: 45,
          borderSkipped: "left",
          data: [],
        },
        {
          label: "My Performance",
          backgroundColor: theme.palette.secondary.main,
          barThickness: "flex",
          barPercentage: 0.5,
          categoryPercentage: 0.3,
          // maxBarThickness: 45,
          borderColor: "#ff6d00",
          borderSkipped: "left",
          data: [],
        },
      ],
    };
    let trendData = {
      studentName: details.studentName,
      labels: [],
      datasets: [],
    };

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_TREND_ANALYSIS_BY_STUDENT_INSTANCE_ID",
            busiParams: {
              branchId: window.localStorage.branchId,
              examTermId: details.examTermId,
              sectionId: window.localStorage.sectionId,
              studentInstanceId: details.studentInstanceId,
              userId: window.localStorage.userId,
              secSubMappingId: window.localStorage.teacherType === "Subject" ? window.localStorage.secSylMappingId : '',
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        data.respData[0].respMsg.result &&
          Object.keys(data.respData[0].respMsg.result).map((item, index) => {
            trendData.labels.push(item);
          });

        //Object.values(data.respData[0].respMsg.result).map(
        //   (item, index) => {
        //     let grouped = groupBy(
        //       data.respData[0].respMsg.result[item],
        //       (item) => item.subjectName
        //     );

        //     for (let x in grouped) {
        //       let mydata = [];
        //       grouped[x].map((item, index) => {
        //         mydata.push(item.totPerc.replace("%", ""));
        //       });

        //       trendData.datasets.push({
        //         label: x,
        //         data: mydata,
        //         lineTension: 0,
        //         fill: false,
        //         //borderColor: "blue",
        //         // pointBackgroundColor: "blue",
        //         pointRadius: 4,
        //         borderWidth: 1.5,
        //       });
        //     }

        //     console.log(grouped);
        //   }
        // );

        Object.keys(data.respData[0].respMsg.result).map((item, index) => {
          let grouped = groupBy(
            data.respData[0].respMsg.result[item],
            (item) => item.subjectName
          );

          for (let x in grouped) {
            let mydata = [];
            let topush = false;
            grouped[x].map((item, index) => {
              let newindex = findIndex(
                trendData.datasets,
                (o) => o.label === x
              );
              console.log(newindex);
              if (newindex >= 0) {
                console.log(trendData.datasets[newindex]);
                trendData.datasets[newindex].data.push(
                  item.totPerc.replace("%", "")
                );
                topush = false;
              } else {
                mydata.push(item.totPerc.replace("%", ""));
                topush = true;
              }
            });

            if (topush) {
              let mycolor = getRandomColor();
              trendData.datasets.push({
                label: x,
                data: mydata,
                lineTension: 0,
                fill: false,
                borderColor: mycolor,
                pointBackgroundColor: mycolor,
                pointRadius: 4,
                borderWidth: 1.5,
              });
            }
          }

          console.log(grouped);
        });

        fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            requestList: [
              {
                isEncrypt: false,
                transactionId: "0000001438241244",
                busiCode: "SCHOOL_GET_PERFORMANCE_BY_STUDENT_INSTANCE_ID",
                busiParams: {
                  branchId: window.localStorage.branchId,
                  examTermId: details.examTermId,
                  sectionId: window.localStorage.sectionId,
                  studentInstanceId: details.studentInstanceId,
                  userId: window.localStorage.userId,
                  secSubMappingId: window.localStorage.teacherType === "Subject" ? window.localStorage.secSylMappingId : '',
                },
              },
            ],
            pubInfo: {
              appId: "appId",
              sessionId: window.localStorage.sessionId,
              version: "21",
              timestamp: "20180314175419",
              lang: "en_US",
              userId: "1000016614",
              serviceId: "7021150585",
              circleId: "MU",
              ncsroute: "WE015",
              customerId: "1000016614",
              osType: "windows",
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            let component;
            console.log(data);
            if (details.studentInstanceId !== 0) {
              dataset.overallRank =
                data.respData[0].respMsg &&
                data.respData[0].respMsg.result &&
                data.respData[0].respMsg.result.overallRank;
              data.respData[0].respMsg &&
                data.respData[0].respMsg.result &&
                data.respData[0].respMsg.result.performanceBar &&
                data.respData[0].respMsg.result.performanceBar.map(
                  (item, index) => {
                    dataset.labels.push(item.subjectName);
                    dataset.datasets[0].data.push(
                      item.averagePerformance !== null ? item.averagePerformance.replace("%", "") : item.averagePerformance
                    );
                    dataset.datasets[1].data.push(
                      item.myperformance !== null ? item.myperformance.replace("%", "") : item.myperformance
                    );
                  }
                );
            } else {
              dataset.overallRank =
                data.respData[0].respMsg &&
                data.respData[0].respMsg.result &&
                data.respData[0].respMsg.result.overallRank;
              data.respData[0].respMsg &&
                data.respData[0].respMsg.result &&
                data.respData[0].respMsg.result.classPerformanceBar &&
                data.respData[0].respMsg.result.classPerformanceBar.map(
                  (item, index) => {
                    dataset.labels.push(item.subjectName);
                    dataset.datasets[0].data.push(
                      item.averagePerformance !== null ? item.averagePerformance.replace("%", "") : item.averagePerformance
                    );
                  }
                );
            }
            console.log(dataset);
            setNewDataset(dataset);
            setTrendData(trendData);
            setExamAnalysisTeach(true);
          });

        console.log(trendData);
      });
  };


  const handleShowSmartQBTGraph = (data) => {
    let stuData = data;
    setStudentExamDetail(data);

    let trendData = {
      studentName: data.studentName,
      labels: [],
      datasets: [],
      isNegative: false,
    };

    fetch(url, makeReqBody("SCHOOL_SMART_QBT_EXAM_TREND_ANALYSIS", {
      branchId: window.localStorage.branchId,
      examTermId: data.examTermId,
      sectionId: window.localStorage.sectionId,
      studentInstanceId: data.studentInstanceID,
      userId: window.localStorage.userId,
      secSubMappingId: window.localStorage.teacherType === "Subject" ? window.localStorage.secSylMappingId : 0,
    })).then((response) => response.json())
      .then((data) => {
        data.respData[0].respMsg !== null &&
          Object.keys(data.respData[0].respMsg.result).map((item, index) => {
            trendData.labels.push(item);
          });

        data.respData[0].respMsg !== null &&
          Object.keys(data.respData[0].respMsg.result).map((item, index) => {
            let grouped = groupBy(
              data.respData[0].respMsg.result[item],
              (item) => item.subjectName
            );
            for (let x in grouped) {
              let mydata = [];
              let topush = false;
              grouped[x].map((item, index) => {
                let newindex = findIndex(
                  trendData.datasets,
                  (o) => o.label === x
                );
                if (newindex >= 0) {
                  trendData.datasets[newindex].data.push(
                    item.totPerc.replace("%", "")
                  );
                  topush = false;
                } else {
                  mydata.push(item.totPerc.replace("%", ""));
                  topush = true;
                }

                if (item.totPerc.replace("%", "") >= 0 && !trendData.isNegative) {
                  trendData.isNegative = false
                } else {
                  trendData.isNegative = true
                }

              });


              if (topush) {
                trendData.datasets.push({
                  label: x,
                  data: mydata,
                  pointRadius: 4,
                  borderWidth: 1.5,
                });
              }
            }
          });
        setTrendData(trendData);
        setExamAnalysisTeach(true);
      });
    let dataset = data.studentInstanceID !== 0 ? {
      perData: "",
      labels: [],
      datasets: [{
        label: "Class Performance",
        backgroundColor: "#e0e0e0",
        borderColor: "#f57c00",
        barThickness: "flex",
        barPercentage: 0.5,
        categoryPercentage: 0.3,
        borderSkipped: "left",
        data: [],
      }, {
        label: "My Performance",
        backgroundColor: ["#ff7c44", "#084791", "#00b940"],
        barThickness: "flex",
        barPercentage: 0.5,
        categoryPercentage: 0.3,
        borderColor: "#ff6d00",
        borderSkipped: "left",
        data: [],
      }],
      isNegative: false,
    } : {
      perData: "",
      labels: [],
      datasets: [{
        label: "Class Performance",
        backgroundColor: "#e0e0e0",
        borderColor: "#f57c00",
        barThickness: "flex",
        barPercentage: 0.5,
        categoryPercentage: 0.3,
        borderSkipped: "left",
        data: [],
      }],
      isNegative: false,
    };

    fetch(url, makeReqBody("SCHOOL_SMART_QBT_EXAM_DETAILS_ANALYSIS", {
      branchId: window.localStorage.branchId,
      examTermId: data.examTermId,
      sectionId: window.localStorage.sectionId,
      userId: window.localStorage.userId,
      studentInstanceId: data.studentInstanceID,
      secSubMappingId: window.localStorage.teacherType === "Subject" ? window.localStorage.secSylMappingId : '',
    })).then((res) => res.json())
      .then((data) => {
        if (stuData.studentInstanceID !== 0) {
          dataset.overallRank =
            data.respData[0].respMsg &&
            data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.overallRank;
          data.respData[0].respMsg &&
            data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.performanceBar &&
            data.respData[0].respMsg.result.performanceBar.map(
              (item, index) => {
                dataset.labels.push(item.subjectName);
                dataset.datasets[0].data.push(
                  item.averagePerformance !== null ? item.averagePerformance.replace("%", "") : item.averagePerformance
                );
                dataset.datasets[1].data.push(
                  item.myperformance !== null ? item.myperformance.replace("%", "") : item.myperformance
                );
                if (Math.floor(item.myperformance.replace("%", "")) >= 0 && Math.floor(item.averagePerformance.replace("%", "")) >= 0 && !dataset.isNegative) {
                  dataset.isNegative = false;
                } else {
                  dataset.isNegative = true;
                }
              }
            );
        } else {
          dataset.overallRank =
            data.respData[0].respMsg &&
            data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.overallRank;
          data.respData[0].respMsg &&
            data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.classPerformanceBar &&
            data.respData[0].respMsg.result.classPerformanceBar.map(
              (item, index) => {
                dataset.labels.push(item.subjectName);
                dataset.datasets[0].data.push(
                  item.averagePerformance !== null ? item.averagePerformance.replace("%", "") : item.averagePerformance
                );
                if (Math.floor(item.averagePerformance.replace("%", "")) >= 0 && !dataset.isNegative) {
                  dataset.isNegative = false;
                } else {
                  dataset.isNegative = true;
                }

              });
        }
        setNewDataset(dataset);
      })
  }

  const handleCloseExamAnalysis = () => {
    setExamAnalysisTeach(false);
    console.log("exam Data", studentExamDetail);
  };

  function getRandomColor() {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const handleCheck = (e, details) => {
    let mydata = [...studentAttendance.studentInstance];
    let myindex = findIndex(
      studentAttendance.studentInstance,
      (o) => o.studentInstanceId === details.studentInstanceId
    );
    if (myindex < 0) {
      mydata.push({
        attendance: 1,
        remarks: "",
        studentInstanceId: details.studentInstanceId,
      });
    } else {
      mydata.splice(myindex, 1);
    }

    setStudentAttendance({ ...studentAttendance, studentInstance: mydata });
    // console.log(studentAttendance);
  };

  const handleCheckGroup = (e, details) => {
    let mydata = [...addGroupContributor.studentInstanceId];
    let myindex = findIndex(
      addGroupContributor.studentInstanceId,
      (o) => o === details.studentInstanceId
    );
    if (myindex < 0) {
      mydata.push(details.studentInstanceId);
    } else {
      mydata.splice(myindex, 1);
    }
    console.log(mydata);

    setAddGroupContrbutor({
      ...addGroupContributor,
      studentInstanceId: mydata,
    });
  };

  const SubmitGroupContributor = () => {
    let newGroup = cloneDeep(addGroupContributor);
    newGroup.groupId = groupId;
    setBackDrop(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_ADD_GROUP_CONTRIBUTOR",
              busiParams: newGroup,
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          console.log(data);
          setNotiMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.status == "Failure"
            ? setNotiStatus("error")
            : setNotiStatus("success");
          setNotiSnack(true);
          setBackDrop(false);
          setAddGroupContrbutor({
            ...addGroupContributor,
            studentInstanceId: [],
          });
          setTimeout(() => {
            handleGroupListing({ target: { value: groupId } });
          }, 1500);
        })
        .catch((data) => {
          console.error(data);
          setBackDrop(false);
        });
    } catch (error) {
      console.log(error);
      setBackDrop(false);
    }
  };

  const submitAttendance = () => {
    let newattendance = cloneDeep(studentAttendance);
    setMarkConfirm(false);
    searchRows.map((item, index) => {
      let myIndex = findIndex(
        newattendance.studentInstance,
        (o) => o.studentInstanceId === item[0].fullDetails.studentInstanceId
      );
      if (myIndex < 0) {
        newattendance.studentInstance.push({
          attendance: 3,
          remarks: "",
          studentInstanceId: item[0].fullDetails.studentInstanceId,
        });
      }
    });
    setBackDrop(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_SAVE _ATTENDANCE",
              busiParams: newattendance,
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          console.log(data);
          setNotiMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.status == "Failure"
            ? setNotiStatus("error")
            : setNotiStatus("success");
          setNotiSnack(true);
          setBackDrop(false);

          setStudentAttendance({ ...studentAttendance, studentInstance: [] });
          setTimeout(() => {
            handleReRender(!renderAg);
          }, 3000);
        })
        .catch((data) => {
          console.error(data);
          setBackDrop(false);

          // setError(true);
        });
    } catch (error) {
      console.log(error);
      setBackDrop(false);
    }
  };

  const isUrlValid = (userInput) => {
    var res = userInput.match(/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/);
    return (res !== null)
  };

  const handleSearch = (e) => {
    console.log(e.target.value);
    console.log(rows[0][0].title);
    console.log(
      rows[0][0].title.toLowerCase().includes(e.target.value.toLowerCase())
    );
    let data = [];
    rows.map((item, index) => {
      if (item[0].title.toLowerCase().includes(e.target.value.toLowerCase())) {
        data.push(item);
      }
    });

    setSearchRows(data);
  };

  const handleAllSelect = () => {
    let mydata = [];
    console.log("hey");
    if (selectAll === false) {
      setSelectAll(true);
      searchRows.map((item, index) => {
        mydata.push({
          attendance: 1,
          remarks: "",
          studentInstanceId: item[0].fullDetails.studentInstanceId,
        });
      });
      console.log(mydata);
      setStudentAttendance({ ...studentAttendance, studentInstance: mydata });
    } else {
      setSelectAll(false);
      setStudentAttendance({ ...studentAttendance, studentInstance: [] });
    }
  };

  const handleAllMySelect = () => {
    let mydata = [];
    if (selectAll1 === false) {
      setSelectAll1(true);
      console.log(searchRows);
      searchRows.map((item, index) => {
        mydata.push(item[0].fullDetails.studentInstanceId);
      });
      console.log(mydata);
      setAddGroupContrbutor({
        ...addGroupContributor,
        studentInstanceId: mydata,
      });
    } else {
      setSelectAll1(false);
      setAddGroupContrbutor({ ...addGroupContributor, studentInstanceId: [] });
    }
  };

  const isSelected = (details) => {
    console.log(details);
    let myindex = findIndex(
      studentAttendance.studentInstance,
      (o) => o.studentInstanceId === details.studentInstanceId
    );
    return myindex >= 0 ? true : false;
  };

  const handleOpenLink = (e, link) => {
    window.open(link);
  };

  const isSelected1 = (details) => {
    console.log(details);
    let myindex = findIndex(
      addGroupContributor.studentInstanceId,
      (o) => o === details.studentInstanceId
    );
    return myindex >= 0 ? true : false;
  };
  const myTableBody =
    searchRows &&
    searchRows.map((item, index1) => (
      <React.Fragment key={item + index1}>
        <TableRow
          key={item.title + index1}
          onClick={(e) => setUploadData(e, item)}
        >
          {item.map((row, index) => {
            let isItemSelected =
              row.type === "checkbox" ? isSelected(row.fullDetails) : undefined;
            let isItemSelected1 =
              row.type === "checkbox1"
                ? isSelected1(row.fullDetails)
                : undefined;
            return (
              <TableCell
                key={row + index + tableRender}
                onClick={
                  row.type === "pdf"
                    ? (e) => handlePdfOpen(e, row.href, row.hidePdfDownload)
                    : row.type === "video" ||
                      row.type === "mp4" ||
                      row.type === "MOV" ||
                      row.type === "mov" ||
                      row.type === "mp3" ||
                      row.type === "3gp"
                      ? (e) => handleVideoOpen(e, row.href)
                      : row.type === "png" ||
                        row.type === "jpg" ||
                        row.type === "jpeg"
                        ? (e) => handleImageOpen(e, row.href)
                        : row.type === "upload" && row.taskStatus !== "Expired" && row.taskStatus !== "Completed"
                          ? (e) => handleUploadDialogOpen(e, row.mystatus, row.details)
                          : row.type === "vigilance"
                            ? () => handleVigilanceOpen(row.details)
                            : row.type === "uploadExam"
                              ? (e) => handleUploadExamDialogOpen(e, row, row.fullDetails)
                              : row.type === "reply"
                                ? () => handleTeacherChat(row.fullDetails, index1, index)
                                : row.type === "Delete"
                                  ? () => handleDeleteOpen(row.fullDetails)
                                  : row.type === "DeleteContributor"
                                    ? () => handleDeleteOpen1(row.fullDetails)
                                    : row.type === "studentDetails"
                                      ? () => handleMessageOpen(row.fullDetails)
                                      : row.type === "Action"
                                        ? () => handleVerifyWritingMaterial(row.fullDetails)
                                        : row.type === "leaveVerify"
                                          ? (e) => handleApplyLeaveOpenDialog(e, row.fullDetails)
                                          : row.type === "Attendance"
                                            ? (e) => handleDialogOpen(e, row.fullDetails)
                                            // : row.type === "examAnalysisSubject"
                                            // ? (e) => handleExamAnalysisSubject(e, row.fullDetails)
                                            // : row.type === "examAnalysisClass"
                                            // ? (e) => handleExamAnalysisClass(e, row.fullDetails)
                                            // :row.feature === "examAnalysisFeature"
                                            // ? (e) => props.examAnalysisDetail(row.fullDetails)
                                            : row.type === "pdfWorkspace"
                                              ? (e) => handlePdfComp(e, row.href)
                                              : row.target === "External" || row.target === "external"
                                                ? (e) => handleOpenLink(e, row.href)
                                                : null
                }
                style={{
                  maxWidth: "10em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <span
                  // className={classes.buttonStyle}
                  style={{
                    display: "inherit",
                    padding: "2px 8px 4px",
                    borderRadius: "3px",
                    maxWidth: "10em",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    color:
                      row.target === "External" ||
                        row.target === "external" ||
                        row.type === "link" ||
                        row.type === "video" ||
                        row.type === "pdf" ||
                        row.type === "upload" ||
                        row.type === "doc" ||
                        row.type === "mp4" ||
                        row.type === "mp3" ||
                        row.type === "3gp" ||
                        row.type === "vigilance" ||
                        row.type === "png" ||
                        row.type === "jpg" ||
                        row.type === "jpeg" ||
                        row.type === "MOV" ||
                        row.type === "mov" ||
                        row.type === "uploadExam"
                        ? theme.typography.myTheme.themeName == "Night"
                          ? theme.palette.common.tabFont
                          : theme.palette.common.blue
                        : row.type === "status" || row.type === "leaveStatus"
                          ? "white"
                          : row.title === "PUBLISHED" || row.title === "COMPLETED" ?
                            theme.palette.common.completed
                            : row.title === "Approval pending" || row.title === "PENDING" ?
                              theme.palette.common.pending
                              : row.title === "CREATED" ?
                                theme.palette.error.main
                                : theme.palette.common.tabFont,
                    cursor:
                      row.type === "text" || row.type === "number"
                        ? undefined
                        : "pointer",
                    backgroundColor:
                      row.type === "status"
                        ? row.title === "Completed"
                          ? theme.palette.success.dark
                          : row.title === "Submitted"
                            ? theme.palette.common.completed
                            : row.title === "Expired" ||
                              row.title === "Re-Submitted" ||
                              row.title === "Re-Submit"
                              ? theme.palette.common.warning
                              : row.title === "Pending"
                                ? theme.palette.common.pending
                                : row.title === "Pending For Verification"
                                  ? theme.palette.common.pendingver
                                  : row.title === "To Be Uploaded"
                                    ? theme.palette.common.toupdate
                                    : "inherit"
                        : row.type === "leaveStatus"
                          ? row.title === "Approved"
                            ? theme.palette.common.completed
                            : row.title === "Rejected"
                              ? theme.palette.common.warning
                              : row.title === "Pending" ||
                                row.title === "Pending for approval"
                                ? theme.palette.common.pending
                                : row.title === "To Be Uploaded"
                                  ? theme.palette.common.toupdate
                                  : "inherit"
                          : "inherit",
                  }}
                >
                  {row.type === "upload" ? (
                    <>
                      {/* row.title === null ? (
                      <span
                        style={{
                          fontWeight: "bold",
                          color: theme.palette.secondary.main,
                        }}
                      >
                        Upload
                      </span>
                    ) : (
                      row.title
                    ) */}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: row.taskStatus !== "Expired" && row.taskStatus !== "Completed" ? theme.palette.secondary.main : "#bf979b"
                        }}
                      >
                        Upload
                      </span>
                    </>


                  ) : row.type === "correctAns" ? (
                    <>
                      <div className={classes.innerHtml} dangerouslySetInnerHTML={{ __html: decodeURIComponent(row.title) }}></div>
                    </>
                  ) : row.type === "schedule" ? (
                    <>
                      <Tooltip title={row.title} >
                        <span>
                          {row.startDate} <br /> {row.endDate}
                        </span>
                      </Tooltip>
                    </>
                  ) : row.type === "smartStatus" ? (
                    <span>
                      {window.localStorage.teacherType === "Class" ? (
                        <>
                          {row.title !== "CREATED" ? (
                            <>
                              <Tooltip
                                title={`Reschedule Exam`}
                                classes={{
                                  tooltip: classes.customTooltip,
                                }}
                              >
                                <IconButton
                                  onClick={() => props.reScheduleSmartExam(row.fullDetails)}
                                  className={classes.iconButton}
                                >
                                  <AvTimerRoundedIcon color="secondary" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={`Publish Exam`}
                                classes={{
                                  tooltip: classes.customTooltip,
                                }}
                              >
                                <IconButton
                                  onClick={() => props.publishRegularExam(row.fullDetails)}
                                  className={classes.iconButton}
                                >
                                  <PublishOutlinedIcon color="secondary" />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              {row.title}
                              <Tooltip
                                title={
                                  <>
                                    {row.subjectTeacherResponse && row.subjectTeacherResponse.map((x, idx) => (
                                      <Typography key={idx} color="inherit">{x.subjectName} : <span style={{
                                        color: x.smartStatus === "PUBLISHED" ?
                                          theme.palette.common.completed
                                          : x.smartStatus === "Approval pending" ?
                                            theme.palette.common.pending
                                            : x.smartStatus === "CREATED" ?
                                              theme.palette.error.main
                                              : theme.palette.common.tabFont
                                      }}>{x.smartStatus}</span></Typography>
                                    ))}
                                  </>

                                }
                                classes={{
                                  tooltip: classes.smartStatuSTooltip,
                                }}
                              >
                                <InfoIcon style={{
                                  verticalAlign: "bottom",
                                  cursor: "pointer"
                                }} />
                              </Tooltip>
                            </>
                          )}
                        </>
                      ) : <Tooltip
                        title={row.title}
                        classes={{
                          tooltip: classes.customTooltip,
                        }}><span style={{ textTransform: "uppercase" }}>{row.title}</span>
                      </Tooltip>}
                    </span>
                  )
                    : row.type === "video" ||
                      row.type === "mp4" ||
                      row.type === "MOV" ||
                      row.type === "mov" ||
                      row.type === "mp3" ||
                      row.type === "3gp" ? (
                      <Grid style={{ display: 'flex' }}>
                        <Typography variant="p" style={{ marginRight: "1em" }}>{row.fullDetails.fileName}</Typography>
                        <Tooltip
                          title={row.fullDetails.videoAlert}
                          classes={{
                            tooltip: classes.customTooltip,
                          }}
                        >
                          <InfoIcon style={{ color: "red" }} />
                        </Tooltip>
                      </Grid>
                    ) : row.type === "uploadExam" ? (
                      row.fullDetails.resubmitExam === false ? (
                        row.title === null ? (
                          <span
                            style={{
                              fontWeight: "bold",
                              color:
                                moment().diff(
                                  moment(row.cutoff, "YYYY-MM-DD HH:mm:ss")
                                ) > 0
                                  ? "grey"
                                  : theme.palette.secondary.main,
                            }}
                          >
                            {" Upload "}

                            {moment().diff(
                              moment(row.cutoff, "YYYY-MM-DD HH:mm:ss")
                            ) < 0 &&
                              moment().diff(
                                moment(row.timeend, "YYYY-MM-DD HH:mm:ss")
                              ) > 0 ? (
                              <CountdownCircleTimer
                                isPlaying
                                duration={row.cutoffduration * 60}
                                size={50}
                                strokeWidth={6}
                                colors={[
                                  ["#004777", 0.33],
                                  ["#F7B801", 0.33],
                                  ["#A30000"],
                                ]}
                                initialRemainingTime={
                                  -1 *
                                  moment().diff(
                                    moment(row.cutoff, "YYYY-MM-DD HH:mm:ss"),
                                    "seconds"
                                  )
                                }
                              >
                                {({ remainingTime }) =>
                                  Math.round(Math.ceil(remainingTime / 60))
                                }
                              </CountdownCircleTimer>
                            ) : undefined}
                          </span>
                        ) : (
                          row.title
                        )
                      ) : (
                        <span
                          style={{
                            fontWeight: "bold",
                            color:
                              moment().diff(
                                moment(
                                  row.fullDetails.resubmitEndDate,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              ) <= 0
                                ? theme.palette.secondary.main
                                : "grey",
                          }}
                        >
                          Upload
                        </span>
                      )
                    ) : row.type === "systemExam" ? (
                      Date.parse(new Date()) <= Date.parse(row.timestart) ?
                        <>Wait for Exam</>
                        : Date.parse(new Date()) <= Date.parse(row.timeend) && row.examStatus === false ?
                          <>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => props.handleStartExam(row.fullDetails)}
                            >Start Exam</Button>
                          </> : row.fullDetails.reset === "Yes" ?
                            <Button
                              disabled={window.localStorage.roleId === "5"}
                              color="secondary"
                              variant="contained"
                              onClick={() => props.handleStartExam(row.fullDetails)}
                            >Start Exam</Button>
                            : <>Exam Over</>
                    ) : row.type === "viewStuExamQues" ? (
                      <Tooltip title="Preview Questions">
                        <IconButton
                          // disabled
                          color="secondary"
                          variant="contained"
                          onClick={() => props.handleOpenQuestionDailog(row.fullDetails)}
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    ) : row.type === "answerStatus" ? (
                      <Tooltip title={row.imgTitle} classes={{ tooltip: classes.customTooltip, }} >
                        <img src={
                          row.imgTitle === "correct" ? correctImg
                            : row.imgTitle === "Wrong" ? wrongImg
                              : row.imgTitle === "partialCorrect" ? partiallyCorrectImg : unattemptedImg
                        }
                          alt={row.imgTitle}
                        />
                      </Tooltip>
                    ) : row.type === "takeRectificationExam" ? (
                      <Tooltip
                        title={row.title}
                        classes={{
                          tooltip: classes.customTooltip,
                        }}>
                        {window.localStorage.roleId === "4" ?
                          <Button
                            variant="text"
                            color="secondary"
                            style={{ textDecoration: "underline" }}
                            onClick={() => props.handleTakeRectificationExam(row.fullDetails)}
                          >{row.fullDetails.rectifyStatus === "COMPLETED" ||row.fullDetails.rectifyStatus === "EXPIRED" ? "View Exam" : "Take Exam"}</Button>
                          : <Button
                            variant="text"
                            color="secondary"
                            style={{ textDecoration: "underline" }}
                            onClick={() => props.handleTakeRectificationExam(row.fullDetails)}
                          >View Exam</Button>
                        }

                      </Tooltip>
                    ) : row.typeFeature === "rectifyStudentData" ? (
                      <Tooltip title="Rectify Details"
                        classes={{
                          tooltip: classes.customTooltip,
                        }}
                      >
                        <IconButton className={classes.iconButton} onClick={(e) => props.handleViewRectifyDetail(row.fullDetails)}
                        >
                          <AssignmentOutlinedIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                    ) : row.typeFeature === "rectifyVerify" ? (
                      <Tooltip title="Verify"
                        classes={{
                          tooltip: classes.customTooltip,
                        }}
                      >
                        <IconButton className={classes.iconButton} onClick={(e) => props.handleRectifyComments(row.fullDetails)}
                        >
                          <AssignmentOutlinedIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                    ) : row.typeFeature === "rectifyAnswerUpload" ? (
                      <Tooltip title="Upload" classes={{ tooltip: classes.customTooltip, }}>
                        <Button
                          startIcon={<CloudUploadOutlinedIcon color="secondary" />}
                          className={classes.iconButton}
                          onClick={(e) => props.handleGetStudentData(row.fullDetails)}
                        >
                          Upload
                        </Button>
                      </Tooltip>
                    ) : row.type === "reply" ? (
                      row.fullDetails.messageRead === true ? (
                        <ReplyRoundedIcon color="secondary" />
                      ) : (
                        <Badge badgeContent={" "} color="secondary" variant="dot">
                          <MailOutlineIcon color="secondary" />
                        </Badge>
                      )
                    ) : row.type === "Delete" ||
                      row.type === "DeleteContributor" ? (
                      <DeleteOutlineIcon color="secondary" />
                    ) : row.type === "studentDetails" ? (
                      <MailOutlineIcon color="secondary" />
                    ) : row.type === "Action" ? (
                      <AssignmentOutlinedIcon color="secondary" />
                    ) : row.type === "verifyExam" ? (
                      <React.Fragment>
                        {row.fullDetails.resultPublished === false &&
                          <Tooltip
                            title={`Reschedule ${row.fullDetails.examType === 1 ? 'eExam' : row.fullDetails.examType === 2 ? 'Regular Exam' : row.fullDetails.examType === 3 ? "SmartQBT Exam" : ''}`}
                            classes={{
                              tooltip: classes.customTooltip,
                            }}
                          >
                            <IconButton
                              onClick={() => props.reScheduleExam(row.fullDetails)}
                              className={classes.iconButton}
                            >
                              <AvTimerRoundedIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        }
                        {row.fullDetails.publishedRegExam === false && <Tooltip
                          title={`Publish ${row.fullDetails.examType === 1 ? 'eExam' : row.fullDetails.examType === 2 ? 'Regular Exam' : ''}`}
                          classes={{
                            tooltip: classes.customTooltip,
                          }}
                        >
                          <IconButton
                            onClick={() => props.publishRegularExam(row.fullDetails)}
                            className={classes.iconButton}
                          >
                            <PublishOutlinedIcon color="secondary" />
                          </IconButton>
                        </Tooltip>
                        }
                        {window.localStorage.teacherType === "Subject" && row.fullDetails.publishedRegExam === true &&
                          <Tooltip
                            title="Verify Exam"
                            classes={{
                              tooltip: classes.customTooltip,
                            }}
                          >
                            <IconButton
                              onClick={() => handleVerifyEExam(row.fullDetails)}
                              className={classes.iconButton}
                            >
                              <AssignmentOutlinedIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        }
                      </React.Fragment>
                    ) : row.type === "EditDelete" ? (
                      <React.Fragment>
                        <IconButton
                          onClick={() =>
                            handleOpenChapterSectionDialog(row.fullDetails)
                          }
                        >
                          <EditIcon color="secondary" />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteOpen(row.fullDetails)}
                        >
                          <DeleteOutlineIcon color="secondary" />
                        </IconButton>
                      </React.Fragment>
                    ) : row.type === "EditDeleteConnect" ? (
                      <React.Fragment>
                        <IconButton
                          onClick={() =>
                            handleOpenChapterSectionDialog1(row.fullDetails)
                          }
                        >
                          <EditIcon color="secondary" />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteOpen(row.fullDetails)}
                        >
                          <DeleteOutlineIcon color="secondary" />
                        </IconButton>
                      </React.Fragment>
                    ) : row.type === "leaveVerify" ? (
                      <AssignmentOutlinedIcon color="secondary" />
                    ) : row.type === "viewSmartExam" ? (
                      <IconButton
                        onClick={() => handleDeleteOpen(row.fullDetails)}
                      >
                        <DeleteOutlineIcon color="secondary" />
                      </IconButton>
                    )
                      : row.type === "EditAttendance" ? (
                        <AssignmentOutlinedIcon
                          color="secondary"
                          onClick={(e) =>
                            handleUpdateAttendanceOpen(e, row.fullDetails)
                          }
                        />
                      ) : row.type === "checkbox" ? (
                        row.fullDetails.attendance === "Present" ||
                          row.fullDetails.attendance === "Absent" ? (
                          <Checkbox
                            color="secondary"
                            checked={true}
                            disabled={true}
                          // onChange={(e) => handleCheck(e, row.fullDetails)}
                          />
                        ) : (
                          <Checkbox
                            color="secondary"
                            checked={isItemSelected}
                            onChange={(e) => handleCheck(e, row.fullDetails)}
                          />
                        )
                      ) : row.type === "radio" && (window.localStorage.boardId <= 6 || window.localStorage.teacherType === "Subject") ? (
                        <>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="questionMaping"
                              defaultValue="0"
                              name="epId"
                              onChange={(e) => { props.handleGetEpIdData(e, row) }}
                            >
                              <Tooltip title={row.title}>
                                <FormControlLabel
                                  value={row.examId}
                                  name="epId"
                                  control={<Radio size="small" />}
                                  label={row.title}
                                  checked={row.examId == props.selectedEpId}
                                />
                              </Tooltip>
                            </RadioGroup>
                          </FormControl>
                        </>
                      ) : row.type === "checkbox1" ? (
                        row.fullDetails.groupId === null ? (
                          <Checkbox
                            color="secondary"
                            checked={isItemSelected1}
                            onChange={(e) => handleCheckGroup(e, row.fullDetails)}
                          />
                        ) : (
                          <Checkbox
                            color="secondary"
                            checked={
                              row.fullDetails.groupId === groupId ? true : false
                            }
                            disabled={true}
                          // onChange={(e) => handleCheckGroup(e, row.fullDetails)}
                          />
                        )
                      ) : row.type === "Attendance" ? (

                        <BarChartIcon color="secondary" />
                      )
                        : (
                          <Tooltip
                            title={row.title}
                            classes={{
                              tooltip: classes.customTooltip,
                            }}
                          >
                            <span>{row.title}</span>
                          </Tooltip>
                        )
                  }
                </span>
                {row.type === "examAnalysisSubject" && (
                  <Tooltip title="View Graph"
                    classes={{
                      tooltip: classes.customTooltip,
                    }}
                  >
                    {/* <IconButton className={classes.iconButton} onClick={(e) => handleExamAnalysisSubject(e, row.fullDetails)}
                 > */}
                    <IconButton className={classes.iconButton} onClick={(e) => handleExamAnalysisClass(e, row.fullDetails)}
                    >
                      <BarChartIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                )}
                {row.type === "examAnalysisClass" && (
                  <Tooltip title="View Graph"
                    classes={{
                      tooltip: classes.customTooltip,
                    }}
                  >
                    <IconButton className={classes.iconButton} onClick={(e) => handleExamAnalysisClass(e, row.fullDetails)}
                    >
                      <BarChartIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                )}
                {row.typeFeature === "examAnalysisFeature" && (
                  <Tooltip title="View Detail Analysis"
                    classes={{
                      tooltip: classes.customTooltip,
                    }}
                  >
                    <IconButton className={classes.iconButton} onClick={(e) => props.examAnalysisDetail(row.fullDetails)}
                    >
                      <AssignmentOutlinedIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                )}

                {row.typeFeature === "smartExamAnalysis" && (
                  <>
                    {window.localStorage.roleId == 3 && <>
                      {row.fullDetails.studentInstanceID !== 0 &&
                        <Tooltip title="Mark Details"
                          classes={{
                            tooltip: classes.customTooltip,
                          }}
                        >
                          <IconButton className={classes.iconButton}
                            onClick={(e) => props.handleViewExamDetail(row.fullDetails)}
                          >
                            <InfoIcon color="secondary" />
                          </IconButton>
                        </Tooltip>}

                      <Tooltip title="Exam Analysis"
                        classes={{
                          tooltip: classes.customTooltip,
                        }}
                      >
                        <IconButton className={classes.iconButton} onClick={() => handleShowSmartQBTGraph(row.fullDetails)}>
                          <BarChartIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                    </>}

                    <Tooltip title={window.localStorage.roleId == 3 ? "Detailed Analysis" : "Mark Details"}
                      classes={{
                        tooltip: classes.customTooltip,
                      }}
                    >
                      <IconButton className={classes.iconButton}
                        onClick={(e) => props.SmartExamAnalysisDetail(row.fullDetails)}
                        disabled={!row.fullDetails.examStatus && window.localStorage.roleId == 4}
                      >
                        <AssignmentOutlinedIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {row.type === "explanation" && (
                  <Tooltip
                    title="View Explanation">
                    <IconButton
                      aria-label="previewQuestion"
                      onClick={() => props.handleShowExplain(row.data)}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </React.Fragment>
    ));

  return (
    <React.Fragment>
      {markattendance ? (
        <React.Fragment>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white", marginBottom: "0.5em" }}
            onClick={() => setMarkConfirm(true)}
          // disabled={studentAttendance.studentInstance.length === 0}
          >
            Mark Present
          </Button>
          <DatePicker
            label="Select Date"
            style={{
              marginBottom: "1em",
              marginLeft: "1em",
              color: theme.palette.common.tabFont,
            }}
            inputVariant="outlined"
            ampm={false}
            //fullWidth
            // disablePast
            className={classes.myTextFieldStyle}
            disableFuture
            color="secondary"
            value={date}
            onChange={(mydate) => {
              setDate(mydate);
              setStudentAttendance({
                ...studentAttendance,
                date: moment(mydate).format("YYYY-MM-DD").toString(),
              });
            }}
          />
        </React.Fragment>
      ) : undefined}
      {searchBox ? (
        <TextField
          label="Search Student"
          onChange={handleSearch}
          className={classes.myTextFieldStyle}
          variant="outlined"
          style={{ width: "20em", marginBottom: "1em" }}
        />
      ) : undefined}
      {addContributor ? (
        <Button
          variant="contained"
          color="secondary"
          style={{ color: "white", marginBottom: "0.5em" }}
          onClick={SubmitGroupContributor}
          disabled={addGroupContributor.studentInstanceId.length === 0}
        >
          Add Group Member
        </Button>
      ) : undefined}

      {route === "table" ? (
        <TableContainer
          className={classes.table}
          style={{ scrollbarWidth: "none" }}
        >
          <Table
            stickyHeader
            aria-label="simple table"
            className={classes.table}
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                {columns &&
                  columns.map((item, index) => (
                    <TableCell
                      key={item + index}
                      onClick={() => sortRows(item, index)}
                      style={{ cursor: "pointer" }}
                    >
                      <Grid container>
                        <Grid item>
                          {item === " " ? (
                            <Checkbox
                              color="secondary"
                              checked={selectAll}
                              onChange={(e) => handleAllSelect(e)}
                            />
                          ) : item === "ck" ? (
                            <Checkbox
                              color="secondary"
                              checked={selectAll1}
                              onChange={(e) => handleAllMySelect(e)}
                            />
                          ) : (
                            item
                          )}
                        </Grid>
                        <Grid item>
                          {item !== " " && item !== "ck" ? (
                            item === columnToSort ? (
                              sortDirection === "asc" ? (
                                <ArrowDropUpIcon
                                  size="small"
                                  style={{ marginLeft: "0.75em" }}
                                />
                              ) : (
                                <ArrowDropDownIcon
                                  size="small"
                                  style={{ marginLeft: "0.75em" }}
                                />
                              )
                            ) : (
                              <SortIcon
                                size="small"
                                style={{ marginLeft: "0.75em" }}
                              />
                            )
                          ) : undefined}
                        </Grid>
                      </Grid>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>{myTableBody}</TableBody>
          </Table>
        </TableContainer>
      ) : route === "verifyExam" ? (
        <EExamVerify
          rows={verifyEExamRow}
          handleVerifyEExam={handleVerifyEExam}
          setVerifyEExamRow={setVerifyEExamRow}
          setRoute={setRoute}
        // eExamId={eExamId}
        />
      ) : route === "Comp" ? (
        <CompGuideWorkspace hrefData={hrefData} setRoute={setRoute} />
      ) : (
        <WritingMaterialVerify
          rows={verifyRow}
          setVerifyRow={setVerifyRow}
          setRoute={setRoute}
          handleVerifyWritingMaterial={handleVerifyWritingMaterial}
        />
      )}
      {
        // <Dialog
        //   open={uploadDialog}
        //   onClose={handleUploadClose}
        //   aria-labelledby=""
        // >
        //   <DialogTitle id="uploadDialog"></DialogTitle>
        //   <DialogContent>
        //     <DialogContentText>Please Attach the required file</DialogContentText>
        //   </DialogContent>
        //   <DialogActions>
        //     <Button
        //       onClick={() => {
        //         handleOnUploadSubmit();
        //       }}
        //       color="secondary"
        //     >
        //       Submit
        //     </Button>
        //   </DialogActions>
        // </Dialog>
      }

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={25000000}
        dialogProps={{ className: classes.myDropZone }}
        acceptedFiles={["image/*", "application/pdf"]}
        open={uploadDialog}
        dropzoneParagraphClass={classes.dropZone}
        onClose={handleUploadClose}
        onSave={(files) => handleOnUploadSubmit(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        filesLimit={1}
      />

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={6000000}
        dialogProps={{ className: classes.myDropZone }}
        dropzoneParagraphClass={classes.dropZone}
        acceptedFiles={["image/*", "application/pdf"]}
        open={uploadExamDialog}
        onClose={handleUploadExamClose}
        onSave={(files) => handleOnUploadExamSubmit(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        filesLimit={1}
      />

      {/* dialouge box for pdf view */}

      <Dialog
        fullScreen={displayFullscreen}
        open={open}
        onClose={handlepdfClose}
        className={classes.pdfFix}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlepdfClose}
              aria-label="close"
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ color: theme.palette.common.tabFont }}
            ></Typography>
            <Switch
              checked={checked}
              onChange={toggleChecked}
              onClick={toggleView}
              color="primary"
            />
            <Typography style={{ color: theme.palette.common.tabFont }}>
              Toggle View
            </Typography>
            {hidePdfDownload !== true &&
              <Tooltip
                title="Download Pdf"
                classes={{
                  tooltip: classes.customTooltip,
                }}>
                <IconButton className={classes.iconButton} onClick={() => window.open(hrefData)}>
                  <GetAppOutlinedIcon color="primary" />
                </IconButton>
              </Tooltip>
            }
          </Toolbar>
        </AppBar>

        <Grid container direction="column" alignItems="center" className={`${classes.pdfScroll} customPdfScroll`}>
          <Grid item>
            <DisplayPdf data={hrefData} displayFullscreen={displayFullscreen} />
          </Grid>
        </Grid>
      </Dialog>

      {videoDialougeOpen ? (
        <Dialog
          className={classes.videoDialouge}
          open={videoDialougeOpen}
          onClose={handleVideoClose}
        >
          <Toolbar
            variant="dense"
            style={{ backgroundColor: theme.palette.common.ltgrey }}
          >
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                {" "}
                <Typography>{videoTitle}</Typography>
              </Grid>
              <Grid item>
                {" "}
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleVideoClose}
                  aria-label="close"
                >
                  <CloseIcon style={{ color: theme.palette.common.tabFont }} />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>

          <Videojs
            downloadurl={videoData}
            {...videoJsOptions} width={500} />
        </Dialog>
      ) : null}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openUploadSnack}
        autoHideDuration={6000}
        onClose={() => setUploadSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setUploadSnack(false)}
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={uploadStatus}>{uploadMessage}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openUploadSnack}
        autoHideDuration={6000}
        onClose={() => setUploadSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setUploadSnack(false)}
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={uploadStatus}>{uploadMessage}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openUploadExamSnack}
        autoHideDuration={6000}
        onClose={() => setUploadExamSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setUploadExamSnack(false)}
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={uploadExamStatus}>{uploadExamMessage}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={examUploadSnack}
        autoHideDuration={6000}
        onClose={() => setExamUploadSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setExamUploadSnack(false)}
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="warning">{examUploadSnackMessage}</Alert>
      </Snackbar>

      <Dialog
        open={openVigilance}
        onClose={handleVigilanceClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleVigilanceClose}
        >
          Vigilance Mode
        </DialogTitle>
        <DialogContent>
          <ChartMaker
            dataset={vigilance}
            width={500}
            height={250}
            maxsize={mymax}
            step={50}
            yAxisLabel="Time in Minutes"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleVigilanceClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={messageStudent}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Send Message
        </DialogTitle>
        <DialogContent style={{ scrollbarWidth: "none" }}>
          <TextField
            id=""
            disabled
            fullWidth
            variant="outlined"
            label="Student Name"
            name="Student Name"
            color="primary"
            value={studentMessage.studentFirstName}
            className={classes.textFieldStyle}
            style={{ marginBottom: "1em", marginTop: "1em" }}
          />

          <TextField
            id=""
            variant="outlined"
            label="Message"
            name="message"
            placeholder="Write Message Here"
            fullWidth
            multiline
            rows={6}
            color="primary"
            className={classes.textFieldStyle}
            onChange={(e) =>
              setMessage({ ...mymessage, message: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            vairant="outline"
            onClick={handleClose}
            color="sceondary"
            style={{ color: theme.palette.secondary.main }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSendMessage}
            color="secondary"
            style={{ color: "white" }}
          >
            Send Message
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={teacherChat}
        onClose={handleTeacherChatClose}
        classes={{
          paperWidthSm:
            window.localStorage.teacherType == "Class"
              ? classes.dialogwidth1
              : undefined,
        }}
        className={classes.dialogContainer1}
        aria-labelledby="customized-dialog-title"
        variant="fullscreen"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleTeacherChatClose}
        >
          {window.localStorage.roleId == 5
            ? chatData && chatData.src == "Teacher"
              ? chatData && chatData.messageFrom
              : chatData && chatData.messageTo
            : chatData && chatData.src == "Teacher"
              ? chatData && chatData.messageTo
              : chatData && chatData.messageFrom}
        </DialogTitle>
        <DialogContent
          className={classes.DialogContentContainer}
          style={{
            backgroundColor:
              theme.typography.myTheme.themeName == "Night"
                ? "#1f1f1f"
                : theme.typography.myTheme.themeName == "Energy"
                  ? "#fdf4ef"
                  : "#e2fbff",
          }}
        >
          <ChatRender
            list={chatData}
            replyReq={replyReq}
            setReplyReq={setReplyReq}
          />
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor:
              theme.typography.myTheme.themeName == "Night"
                ? "#1f1f1f"
                : theme.typography.myTheme.themeName == "Energy"
                  ? "#fdf4ef"
                  : "#e2fbff",
          }}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                placeholder="Your Response"
                multiline
                fullWidth
                rows={3}
                variant="outlined"
                color="secondary"
                value={replyReq.message}
                onChange={(e) =>
                  setReplyReq({ ...replyReq, message: e.target.value })
                }
                style={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item container justify="flex-end">
              <Button
                variant="text"
                color="secondary"
                onClick={handleTeacherChatClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ color: "white" }}
                onClick={provideResponse}
                disabled={replyReq.message.trim() === ""}
              >
                Reply
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleDeleteClose}>
          Confirm Deletion
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em", scrollbarWidth: "none" }}>
          <Typography variant="body1" color="initial">
            Are you sure you want to delete this material ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={handleDeleteClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleDeleteConfirmDialog}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialog1}
        onClose={() => setDeleteDialog1(false)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setDeleteDialog1(false)}
        >
          Confirm Deletion
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <Typography variant="body1" color="initial">
            Are you sure you want to remove this member from the group ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setDeleteDialog1(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={() => handleDeleteConfirmDialog1()}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={displayFullscreen}
        open={imageOpen}
        onClose={handleImageClose}
        aria-labelledby="customized-dialog-title"
        className={classes.pdfFix}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleImageClose}>
          {" "}
          <Switch
            checked={checked}
            onChange={toggleChecked}
            onClick={toggleView}
            color="primary"
          />
          <Typography style={{ color: theme.palette.common.tabFont }}>
            Toggle View
          </Typography>
        </DialogTitle>
        <DialogContent style={{ scrollbarWidth: "none" }}>
          <img
            src={hrefData}
            alt="viewer"
            style={{
              height: "auto",
              maxWidth: displayFullscreen === false ? "40em" : undefined,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleImageClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={notiSnack}
        autoHideDuration={6000}
        onClose={() => setNotiSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setNotiSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={NotiStatus}>{notiMessage}</Alert>
      </Snackbar>

      <Dialog
        open={applyLeave}
        onClose={handleApplyLeaveCloseDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleApplyLeaveCloseDialog}
        >
          Leave Details
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em", scrollbarWidth: "none" }}>
          <Grid container direction="column" spacing={2}>
            <Grid item container>
              <Grid item container direction="column" sm>
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    Student Name
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {leaveDetails.studentName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" sm>
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    Roll No
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{leaveDetails.rollNo}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" sm>
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    Leave Duration
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{ color: "black" }}
                  >{`${moment(leaveDetails.fromDate, "DD-MM-YYYY")
                    .format("DD MMM YYYY")
                    .toString()}-${moment(leaveDetails.toDate, "DD-MM-YYYY")
                      .format("DD MMM YYYY")
                      .toString()}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  Subject
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{leaveDetails.subject}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  Reason
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{leaveDetails.remarks}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  label="Comments *"
                  placeholder={leaveDetails.comments}
                  disabled={
                    leaveDetails.leaveStatus === "Approved" ||
                    leaveDetails.leaveStatus === "Rejected"
                  }
                  value={leaveDetails.comments}
                  onChange={(e) =>
                    setLeaveDetails({
                      ...leaveDetails,
                      comments: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            style={{
              color:
                leaveDetails.leaveStatus === "Rejected" ||
                  leaveDetails.leaveStatus === "Approved" ||
                  leaveDetails.comments === "" ||
                  leaveDetails.comments === " " ||
                  leaveDetails.comments === null ||
                  leaveDetails.comments === undefined
                  ? "grey"
                  : "red",
            }}
            onClick={handleApplyLeaveReject}
            disabled={
              leaveDetails.leaveStatus === "Rejected" ||
              leaveDetails.leaveStatus === "Approved" ||
              leaveDetails.comments === "" ||
              leaveDetails.comments === " " ||
              leaveDetails.comments === null ||
              leaveDetails.comments === undefined
            }
          >
            Reject
          </Button>
          <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor:
                leaveDetails.leaveStatus === "Rejected" ||
                  leaveDetails.leaveStatus === "Approved" ||
                  leaveDetails.comments === "" ||
                  leaveDetails.comments === " " ||
                  leaveDetails.comments === null ||
                  leaveDetails.comments === undefined
                  ? "grey"
                  : "green",
            }}
            onClick={handleApplyLeaveAccept}
            disabled={
              leaveDetails.leaveStatus === "Rejected" ||
              leaveDetails.leaveStatus === "Approved" ||
              leaveDetails.comments === "" ||
              leaveDetails.comments === " " ||
              leaveDetails.comments === null ||
              leaveDetails.comments === undefined
            }
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={updateAttendance}
        onClose={() => setUpdateAttendance(false)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setUpdateAttendance(false)}
        >
          Update Attendance
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <Typography variant="h6" color="secondary">
            Please Update The Attendance Status Of The Student By Clicking One
            Of the Below Buttons
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "red",
            }}
            onClick={() => handleUpdateAttendance(3)}
          >
            Absent
          </Button>
          <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "green",
            }}
            onClick={() => handleUpdateAttendance(1)}
          >
            Present
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={applyLeaveSnack}
        autoHideDuration={6000}
        onClose={() => setApplyLeaveSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setApplyLeaveSnack(false)}
            >
              <CloseIcon
                fontSize="small"
                style={{ color: theme.palette.common.tabFont }}
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={applyLeaveStatus}>{applyLeaveMessage}</Alert>
      </Snackbar>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setOpenDialog(false)}
        >
          Individual Attendance Report
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item container justify="space-between">
              <Grid item>
                <Typography
                  variant="h6"
                  color="initial"
                >{`Student Name: ${attendanceDetails.studentName}`}</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  color="initial"
                >{`Total Attendance: ${attendanceDetails.totalAttendance}`}</Typography>
              </Grid>
            </Grid>
            <Grid item container style={{ scrollbarWidth: "none" }}>
              <ChartMaker
                dataset={annualClassAttendace}
                width="40em"
                style={{ scrollbarWidth: "none" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Backdrop className={classes.backdrop} open={backDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={examAnalysisTeach}
        onClose={handleCloseExamAnalysis}
        aria-labelledby="customized-dialog-title"
        classes={{
          paperWidthSm:
            window.localStorage.teacherType == "Class" && studentExamDetail && studentExamDetail.studentInstanceId !== 0
              ? classes.dialogwidth
              : classes.dialogwidth,
        }}
        className={
          window.localStorage.teacherType == "Class" && studentExamDetail && studentExamDetail.studentInstanceId !== 0
            ? classes.dialogContainer
            : classes.dialogContainer
        }
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseExamAnalysis}
        >
          {studentExamDetail && studentExamDetail.studentInstanceId !== 0 ? 'Student Analysis' : 'Class Performance'}
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em", scrollbarWidth: "none" }}>
          <ExamAnalysisMaker
            trendData={trendData}
            perData={newDataset}
            handleOpenDialog={window.localStorage.boardId > 6 ? props.handleOpenReportCard : props.reportCard}
            studentExamDetail={studentExamDetail}
            toShow={true}
            termName={props.termName}
          // toShow={window.localStorage.teacherType == "Class" ? true : false}
          // mylist={listdata}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            style={{ color: "white" }}
            color="secondary"
            onClick={handleCloseExamAnalysis}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={markConfirm}
        onClose={() => setMarkConfirm(false)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setMarkConfirm(false)}
        >
          Confirm Attendance
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <Typography variant="h6" style={{ color: "black" }}>
            All the children that have not been selected will be marked absent
            if you still wish to proceed click the Confirm Button
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            variant="text"
            style={{ color: "black" }}
            onClick={() => setMarkConfirm(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ color: "white" }}
            color="secondary"
            onClick={submitAttendance}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={resourceEditDialog}
        onClose={handleCloseEditDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseEditDialog}
        >
          Upload Material
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <Grid container direction="column" spacing={2}>
            {chapter && chapter.length <= 0 ? (
              <Grid item>
                <Typography variant="h6" color="secondary">
                  Please contact admin for chapter/section mapping
                </Typography>
              </Grid>
            ) : undefined}
            <Grid item>
              <TextField
                disabled
                variant="outlined"
                fullWidth
                label="Subject Name"
                value={
                  JSON.parse(window.localStorage.users)
                    ? JSON.parse(window.localStorage.users)[0].subjectName
                    : null
                }
              />
            </Grid>
            <Grid item>
              <TextField
                select
                variant="outlined"
                fullWidth
                label="Chapter Name"
                value={chapterSelected}
                onChange={handleChapterSelect}
              >
                {chapter &&
                  chapter.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item.chapterName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                select
                disabled={section.length > 0 ? false : true}
                variant="outlined"
                fullWidth
                label="Section Name"
                value={sectionSelected}
                onChange={(e) => setSectionSelected(e.target.value)}
              >
                {section &&
                  section.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item.sectionName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                label="Description"
                multiline
                rows={2}
                value={teacherDescription}
                inputProps={{ maxlength: "1995" }}
                onChange={(e) => setTeacherDescription(e.target.value)}
              />
            </Grid>
            {
              // <Grid item>
              // <KeyboardDatePicker
              //       variant="inline"
              //       autoOk={true}
              //       name="date"
              //       inputVariant="outlined"
              //       label="Date"
              //       fullWidth
              //       placeholder="Select Notification Date"
              //       format="DD/MM/yyyy"
              //       value={resource.date}
              //       InputAdornmentProps={{ position: "end" }}
              //       onChange={(date) => {
              //         setResource({
              //           ...notification,
              //           dueDate: moment(date).format("YYYY-MM-DD").toString(),
              //         });
              //       }}
              //     />
              // </Grid>
            }
            <Grid item container spacing={2}>
              <Grid item onClick={handleDropZone}>
                <Typography
                  variant="h6"
                  color="secondary"
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                >
                  Upload
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  style={{ color: "blue" }}
                >
                  {materialName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCloseEditDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            disabled={
              myAttachment.attachment === "" &&
              myAttachment.attachment === " " ||
              myLoader === true ||
              chapter.length === 0
            }
            onClick={handleEditSubmit}
          >
            {myLoader === false ? (
              "Upload"
            ) : (
              <CircularProgress color={theme.palette.secondary.light} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={resourceEditDialog1}
        onClose={handleCloseEditDialog1}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseEditDialog1}
        >
          Upload Material
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <Grid container direction="column" spacing={2}>
            {chapter && chapter.length <= 0 ? (
              <Grid item>
                <Typography variant="h6" color="secondary">
                  Please contact admin for chapter/section mapping
                </Typography>
              </Grid>
            ) : undefined}
            <Grid item>
              <TextField
                disabled
                variant="outlined"
                fullWidth
                label="Subject Name"
                value={
                  JSON.parse(window.localStorage.users)
                    ? JSON.parse(window.localStorage.users)[0].subjectName
                    : null
                }
              />
            </Grid>
            <Grid item>
              <TextField
                select
                variant="outlined"
                fullWidth
                label="Chapter Name"
                value={chapterSelected}
                onChange={handleChapterSelect}
              >
                {chapter &&
                  chapter.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item.chapterName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                select
                disabled={section.length > 0 ? false : true}
                variant="outlined"
                fullWidth
                label="Section Name"
                value={sectionSelected}
                onChange={(e) => setSectionSelected(e.target.value)}
              >
                {section &&
                  section.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item.sectionName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                label="Description"
                multiline
                rows={2}
                value={teacherDescription}
                inputProps={{ maxlength: "1995" }}
                onChange={(e) => setTeacherDescription(e.target.value)}
              />
            </Grid>
            {
              // <Grid item>
              // <KeyboardDatePicker
              //       variant="inline"
              //       autoOk={true}
              //       name="date"
              //       inputVariant="outlined"
              //       label="Date"
              //       fullWidth
              //       placeholder="Select Notification Date"
              //       format="DD/MM/yyyy"
              //       value={resource.date}
              //       InputAdornmentProps={{ position: "end" }}
              //       onChange={(date) => {
              //         setResource({
              //           ...notification,
              //           dueDate: moment(date).format("YYYY-MM-DD").toString(),
              //         });
              //       }}
              //     />
              // </Grid>
            }
            <Grid item container spacing={2}>
              {updateDetails.target === "Internal" ||
                updateDetails.target === "internal" ? (
                <React.Fragment>
                  <Grid item onClick={handleDropZone1}>
                    <Typography
                      variant="h6"
                      color="secondary"
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                    >
                      Upload
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      style={{ color: "blue" }}
                    >
                      {materialName}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid item container sm>
                    <TextField
                      id="link"
                      label="Link"
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      value={myAttachment.attachment}
                      error={
                        isUrlValid(myAttachment.attachment) === true
                          ? false
                          : true
                      }
                      helperText={
                        isUrlValid(myAttachment.attachment) === true
                          ? ""
                          : "Please add a valid url"
                      }
                      onChange={(e) => {
                        setMyAttachment({
                          ...myAttachment,
                          attachment: e.target.value,
                          target: "External",
                          fileType: "newlink",
                        });
                      }}
                    />
                  </Grid>
                  <Grid item container sm>
                    <TextField
                      label="Link Name"
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      value={myAttachment.fileName}
                      onChange={(e) => {
                        setMyAttachment({
                          ...myAttachment,
                          fileName: e.target.value,
                          fileType: "newlink",
                        });
                      }}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCloseEditDialog1}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            disabled={
              myAttachment.attachment.trim() !== "" &&
                isUrlValid(myAttachment.attachment) === true &&
                myAttachment.fileName.trim() !== "" && chapterSelected !== 0
                ? false : true || myLoader === true || chapter.length === 0
            }
            onClick={handleEditSubmit1}
          >
            {myLoader === false ? (
              "Upload"
            ) : (
              <CircularProgress color={theme.palette.secondary.light} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <DropzoneDialog
        dialogProps={{ className: classes.myDropZone }}
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={25000000}
        acceptedFiles={["image/*", "application/pdf", "video/*"]}
        open={dropZone}
        onClose={handleDropZoneClose}
        onSave={(files) => handleSaveMaterial(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        filesLimit={1}
      />

      <DropzoneDialog
        dialogProps={{ className: classes.myDropZone }}
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={25000000}
        acceptedFiles={["image/*", "application/pdf", "video/*"]}
        open={dropZone1}
        onClose={() => setDropZone1(false)}
        onSave={(files) => handleSaveMaterial1(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        filesLimit={1}
      />
    </React.Fragment>
  );
}
