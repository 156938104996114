import React, { useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { groupBy } from "lodash";
import Link from "@material-ui/core/Link";
import { Typography, CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { goldenNotesSubjects } from "../Ui/store";
import doc from "../assets/doc.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import pdf from "../assets/pdficon.jpg";
import { url } from "./store.js";
import DisplayPdf from "./DisplayPdf";
import CloseIcon from "@material-ui/icons/Close";
import {
  AppBar,
  Dialog,
  Toolbar,
  IconButton,
  Switch,
  Button,
  DialogActions,
  DialogContent, Tooltip
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import TabMaker from "./TabMaker";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

const useStyles = makeStyles((theme) => ({
  headerPaper: {
    backgroundColor: theme.palette.common.ongrey,
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: "transparent",
    height: "50em",
    overflowY: "scroll",
    msOverflowStyle: "none" /* IE 11 */,
    scrollbarWidth: "none",
  },

  subContainer: {
    ...theme.typography.subContainer,
  },

  chaptersList: {
    backgroundColor: theme.palette.common.ongrey,
    padding: "0.6em",
    marginBottom: "0.5em",
    color: theme.palette.common.black,
    "&:hover": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.white
          : theme.palette.common.black,
      backgroundColor:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.whiteOpaque2
          : "inherit",
    },
  },

  chapterSelected: {
    backgroundColor: theme.palette.common.ongrey,
    padding: "0.6em",
    marginBottom: "0.5em",
    color: "black",
    borderLeft: `3px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.white
          : theme.palette.common.black,
      backgroundColor:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.whiteOpaque2
          : "inherit",
    },
  },
  sectionName: {
    marginTop: "1em",
    color: "black",
  },
  document: {
    padding: "5px",
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },

  toolBar: {
    ...theme.mixins.toolbar,
  },

  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.common.black,
  },
  switchButton: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.light,
    },

    "& .MuiSwitch-colorSecondary": {},
  },
  pdfContainer: {
    marginTop: "none",
  },

  pdfContainer2: {
    width: "200em",
  },

  pdfScroll: {
    overflowY: "auto",
    
    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
    "& .MuiDialogContent-root": {
      minHeight: '12em',  
      
    }
  },
  iconButton:{
    padding: '6px',
},
customTooltip: {
  // I used the rgba color for the standard "secondary" color
  fontSize: "0.85rem",
},
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MyDataMaker = (props) => {
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_RESOURCE_BY_BRANCH_ID_RESOURCE_TYPE",
              busiParams: {
                admin: false,
                branchId: window.localStorage.branchId,
                chapterId: 0,
                resourceType: 30,
                secSubjectMappingId: props.secId,
                sectionId: window.localStorage.sectionId,
                subject: 0,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())

        .then((data) => {
          let grouped = [];
          let AllList = [];
          if (data.respData[0].respMsg.result.length > 0) {
            grouped = groupBy(
              data.respData[0].respMsg.result,
              (item) => item.chapterName
            );
            console.log(grouped);
            setRows(grouped);
          } else {
            setRows([]);
          }

          setTimeout(() => {
            setLoader(false);
          }, 1500);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    }
  }, []);

  return (
    <React.Fragment>
      {loader === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <GoldenNotesMaker
          data={rows[props.item]}
          handlePdfOpen={props.handlePdfOpen}
          handleImageOpen={props.handleImageOpen}
          selectedIndex={props.selectedIndex}
          grouped={rows}
          setSelectedIndex={props.setSelectedIndex}
          setSections={props.setSections}
        />
      )}
    </React.Fragment>
  );
};

const GoldenNotesMaker = (props) => {
  const [state, setstate] = useState(false);
  const [data, setData] = useState(-1);
  const classes = useStyles();

  const theme = useTheme();
  return (
    <React.Fragment>
      {Object.keys(props.grouped).length <= 0 ? (
        <Typography
          variant="h6"
          style={{ color: theme.palette.common.tabFont }}
        >
          No Data Available
        </Typography>
      ) : (
        <Grid container direction="row" spacing={4}>
          <Grid item container sm={4}>
            <List style={{ width: "100%" }}>
              <Grid container direction="column">
                {Object.keys(props.grouped).map((item, index) => (
                  <React.Fragment>
                    <Grid item container alignItems="center">
                      <Grid item container sm={11}>
                        <ListItem
                          className={
                            data == index
                              ? classes.chapterSelected
                              : classes.chaptersList
                          }
                          button
                          onClick={() => {
                            console.log(index);
                            setData(index);

                            props.setSections(props.grouped[item]);
                            console.log(props.selectedIndex);
                          }}
                        >
                          {item}
                        </ListItem>
                      </Grid>
                      <Grid
                        ite
                        sm={1}
                        style={{
                          display: data === index ? "flex" : "none",
                        }}
                      >
                        <ArrowRightIcon color="secondary" size="large" />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </List>
          </Grid>

          <Grid item container sm={8} spacing={3}>
            {Object.keys(props.grouped).map((item, index1) => (
              <MySectionMaker
                key={item}
                data={props.grouped[item]}
                handlePdfOpen={props.handlePdfOpen}
                handleImageOpen={props.handleImageOpen}
                selectedIndex={data}
                index1={index1}
              />
            ))}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const MySectionMaker = (props) => {
  const [state, setstate] = useState(false);
  const [data, setData] = useState(props.data);
  const classes = useStyles();

  const theme = useTheme();
  return state === true ? (
    <CircularProgress color="secomdary" />
  ) : data && data.length > 0 ? (
    data.map((item, index) => {
      return props.selectedIndex === props.index1 ? (
        <Grid item container direction="column" sm={6} key={index}>          
          <Grid item>
            {console.log(item)}
            <Typography style={{ color: theme.palette.common.tabFont }}>
              {item.chapterSectionName}
            </Typography>
          </Grid>
          <Grid item>
            <Divider
              style={{ backgroundColor: theme.palette.common.tabFont }}
            />
          </Grid>
          <Grid
            item
            component={Link}
            onClick={(e) =>
              item.fileType == "pdf"
                ? props.handlePdfOpen(e, item.attachment)
                : props.handleImageOpen(e, item.attachment)
            }
          >
            <Tooltip
            title={item.teacherDescription}
            classes={{
              tooltip: classes.customTooltip,
            }}
          >
            <Typography style={{ color: theme.palette.common.tabFont, display:'inline-block', cursor: 'pointer' }}>
              {item.fileName}
            </Typography>
            </Tooltip>
          </Grid>
          
        </Grid>
      ) : undefined;
    })
  ) : (
    <Typography variant="h6">Please Select a Chapter</Typography>
  );
};

export default function TabsWrappedLabel({ value, setValue }) {
  const classes = useStyles();
  const theme = useTheme();
  const [keyList, setKeyList] = React.useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [sections, setSections] = React.useState([]);
  const [loader, setLoader] = useState(false);
  const [notes, setNotes] = React.useState([]);
  const [pdfOpen, setPdfOpen] = React.useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [displayFullscreen, setDisplayFullscreen] = React.useState(false);
  const [hrefData, setHrefData] = React.useState();
  const [checked, setChecked] = React.useState(false);
  const [table, setTable] = React.useState([]);
  const bodyObjGoldenNotes = {
    requestList: [
      {
        isEncrypt: false,
        transactionId: "0000001438241244",
        busiCode: "SCHOOL_GET_RESOURCE_BY_BRANCH_ID_RESOURCE_TYPE",
        busiParams: {
          admin: false,
          branchId: window.localStorage.branchId,
          chapterId: 0,
          resourceType: 30,
          secSubjectMappingId: 0,
          sectionId: window.localStorage.sectionId,
          subject: 0,
        },
      },
    ],
    pubInfo: {
      appId: "appId",
      sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
      version: "21",
      timestamp: "20180314175419",
      lang: "en_US",
      userId: "1000016614",
      serviceId: "7021150585",
      circleId: "MU",
      ncsroute: "WE015",
      customerId: "1000016614",
      osType: "windows",
    },
  };

  const handleSettingIndex = (index) => {
    console.log(index);
    setSelectedIndex(index);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleImageOpen = (e, href) => {
    setImageOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };

  useEffect(() => {
    setLoader(true);
    const AllLists = [];
    let subjects = JSON.parse(window.localStorage.getItem("users") || "[]");
    console.log(subjects);
    subjects &&
      subjects.map((item, index) => {
        let myComponent = (
          <MyDataMaker
            key={index}
            secId={item.secSubSylMappingId}
            item={item}
            handlePdfOpen={handlePdfOpen}
            handleImageOpen={handleImageOpen}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            setSections={setSections}
          />
        );

        AllLists.push(myComponent);
      });
    setTable(AllLists);

    setInterval(() => {
      setLoader(false);
    }, 500);
  }, []);

  const handlePdfOpen = (e, href) => {
    setPdfOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };

  const handlepdfClose = (event) => {
    setPdfOpen(false);
    window.localStorage.removeItem("idleDisable");
  };

  const toggleView = (event) => {
    setDisplayFullscreen(!displayFullscreen);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const subjects = JSON.parse(window.localStorage.getItem("users") || "[]");

  let tabheadings = [];
  try {
    tabheadings = subjects.map((a) => a.subjectName);
  } catch (error) {
    console.error(error);
  }

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TabMaker tabs={tabheadings} data={table} subjectBased={true} />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
      <Dialog
        fullScreen={displayFullscreen}
        open={pdfOpen}
        onClose={handlepdfClose}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlepdfClose}
              aria-label="close"
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ color: theme.palette.common.tabFont }}
            ></Typography>
            <Switch
              checked={checked}
              onChange={toggleChecked}
              onClick={toggleView}
              color="primary"
            />
            <Typography style={{ color: theme.palette.common.tabFont }}>
              Toggle View
            </Typography>
            {/* <Tooltip
            title="Download Pdf"
            classes={{
              tooltip: classes.customTooltip,
            }}>
              <IconButton className={classes.iconButton} onClick={()=> window.open(hrefData)}>
                <GetAppOutlinedIcon color="primary"/>
              </IconButton>
            </Tooltip> */}
          </Toolbar>
        </AppBar>

        <Grid container direction="column" alignItems="center" className={`${classes.pdfScroll} customPdfScroll`}>
          <Grid item >
            <DisplayPdf data={hrefData} displayFullscreen={displayFullscreen} />
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={imageOpen}
        fullScreen={displayFullscreen}
        onClose={handleImageClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleImageClose}>
          {" "}
          <Switch
            checked={checked}
            onChange={toggleChecked}
            onClick={toggleView}
            color="primary"
          />
          <Typography style={{ color: theme.palette.common.tabFont }}>
            Toggle View
          </Typography>
        </DialogTitle>
        <DialogContent>
          <img
            src={hrefData}
            alt="viewer"
            height="30em"
            style={{
              height: "auto",
              maxWidth: displayFullscreen === false ? "40em" : undefined,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => console.log("hii")}
            color="secondary"
            style={{ color: "white" }}
            onClick={handleImageClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
