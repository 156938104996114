import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import WorkSpaceHeader from "./Ui/WorkSpaceHeader.js";

import { Grid, Typography } from "@material-ui/core";

import WritingWorks from "./Ui/WritingWorks.js";
import ExamWorks from "./Ui/ExamWorks";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    ...theme.typography.mainContainer,
    height: "100% ",
    minHeight: "130vh",
  },
  headingItem: {
    ...theme.typography.tilesHeading,
    boxShadow: theme.shadows[5],
  },
}));

export default function UploadSection(props) {
  const classes = useStyle();

  const WritingWorksContainer = (
    <React.Fragment>
      {console.log(window.location.pathname)}

      <Grid item container direction="column">
        <WorkSpaceHeader title="WRITING WORKS" moduleId={19} />
        <WritingWorks />
        <Grid
          item
          container
          justify="flex-end"
          alignItems="center"
          spacing={2}
          style={{ marginLeft: "-2em", marginTop: "1em" }}
        ></Grid>
      </Grid>
    </React.Fragment>
  );

  const ExamWorksContainer = (
    <React.Fragment>
      {console.log(window.location.pathname)}

      <Grid item container direction="column">
        <WorkSpaceHeader title="EXAM WORKS" moduleId={20} />
        <ExamWorks />
        <Grid
          item
          container
          justify="flex-end"
          alignItems="center"
          spacing={2}
          style={{ marginLeft: "-2em", marginTop: "1em" }}
        ></Grid>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        className={classes.mainContainer}
        style={{ width: `calc(100% - 12em)` }}
      >
        {window.location.pathname === "/UploadSection" ? (
          WritingWorksContainer
        ) : window.location.pathname === "/UploadSection/WriteWorks" ? (
          WritingWorksContainer
        ) : window.location.pathname === "/UploadSection/ExamWorks" ? (
          ExamWorksContainer
        ) : (
          <Typography variant="h1">To be designed</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
}
