import React, { useContext, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { DropzoneDialog } from "material-ui-dropzone";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  TextField,
  CardHeader,
  Card,
  CardMedia,
  Radio,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { groupBy } from "lodash";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useForm2 } from "./useForm.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import userpic from "../assets/userpic.png";
import Avatar from "@material-ui/core/Avatar";
import aimIcon from "../assets/aim_icon.svg";
import { Link, useHistory } from "react-router-dom";
import { isEqual } from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import Hidden from "@material-ui/core/Hidden";
import Badge from "@material-ui/core/Badge";
import { courseRegistrationUrl, url, makeReqBody } from "./store.js";
import teacherfallback from "../assets/teacherplaceholder.png";
import Logout from "../assets/logout.svg";
import palette from "../assets/palette.svg";
import ThemeController from "./ThemeController";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import aimicon2 from "../assets/aimicon_nighttheme.png";
import logout2 from "../assets/logout-iconnightheme.png";
import themeicon2 from "../assets/theme_icon_nightheme.png";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import link from "../assets/link.svg";
import { json } from "is_js";
import { UserContext } from "../../Context.js";
import RefreshIcon from '@material-ui/icons/Refresh';
import { green } from "@material-ui/core/colors";

var ProgressBar = require("progressbar.js");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyle = makeStyles((theme) => ({
  studentPaper: {
    width: "100%",
    height: "8.5em",
    padding: "0",
    marginTop: "auto",
    marginBottom: "auto",

    backgroundColor: "transparent",
  },
  gridItems: {
    padding: 0,
  },
  profileImg: {
    height: theme.spacing(11),
    width: theme.spacing(11),
  },
  headerText: {
    textDecoration: "none",
    textTransform: "none",
  },
  radialProgress: {
    position: "relative",
    color: theme.palette.common.blue,
  },
  iconImage: {
    height: "4.5em",
    width: "4.5em",
  },
  divider: {
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.white
        : theme.palette.common.black,
    height: "5em",
  },
  schoolLogo: {
    width: "4.6em",
    heigth: "4.6em",

    marginTop: "0.5em",
  },
  badgeStyle: {
    "& .MuiBadge-anchorOriginBottomRightCircle": {
      backgroundColor: theme.palette.common.badge,

      cursor: "pointer",
    },
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "60em",
      height: "30em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },

  dialogwidth: {
    maxWidth: "80em",
    maxHeight: "100em",
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const TeacherContent = (props) => {
  const classes = useTheme();
  const [classTeaching, setClassTeaching] = useState({});
  const [subjectName, setSubjectName] = useState("");
  const [myClass, setMyClass] = useState([]);
  const [newClass, setNewClass] = useState([]);
  const [subjectTeaching, setSubjectTeaching] = useState([]);

  useEffect(() => {
    let subdetails = [];
    props.subjectTeachDetails &&
      props.subjectTeachDetails.map((item, index) =>
        subdetails.push({
          ...item,
          fullClass: `${item.className} - ${item.sectionNo}`,
          academicYearDesc: item.academicYearDesc
        })
      );

    let myClasses = groupBy(subdetails, (item) => item.fullClass);

    setClassTeaching(myClasses);
    setSubjectTeaching(myClasses);
    let uniqueSubject = subdetails.reduce((unique, item) => {
      if (!unique.some(obj => obj.className === item.className && obj.sectionNo === item.sectionNo && obj.academicYearDesc === item.academicYearDesc)) {
        unique.push(item);
      }
      return unique;
    }, [])

    // setMyClass(subdetails);
    setMyClass(uniqueSubject);
    props.classDetails && setNewClass(props.classDetails);
  }, []);

  return (
    <React.Fragment>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="body1" color="initial">
            Select Type
          </Typography>
        </Grid>
        <Grid item container direction="row">
          <RadioGroup
            aria-label="gender"
            name="teachertype"
            value={props.teacherType}
            onChange={(e) => props.setTeacherType(e.target.value)}
            row
          >
            {props.classDetails ? (
              <FormControlLabel
                value="Class"
                control={<Radio />}
                label="Class Teacher"
              />
            ) : undefined}
            {props.subjectTeachDetails ? (
              <FormControlLabel
                value="Subject"
                control={<Radio />}
                label="Subject Teacher"
              />
            ) : undefined}

            {!props.subjectTeachDetails && !props.classDetails ? (
              <Typography variant="h6" color="secondary">
                Please get the teacher mapped to either a class or subject
              </Typography>
            ) : undefined}
          </RadioGroup>
        </Grid>

        {props.teacherType === "Class" && props.classDetails ? (
          <Grid item container direction="column">
            <Grid item container>
              <TextField
                id=""
                variant="outlined"
                label="Select Class"
                color="primary"
                select
                fullWidth
                name="Class"
                value={props.checkClass}
                onChange={async (e) => {
                  props.setCheckClass(e.target.value);
                  props.handleClassTeacherSelection(newClass[e.target.value]);
                }}
              >
                {newClass.map((item, index) => (
                  <MenuItem key={index} value={index}>
                    {`${item.boardId > 6 ? `${item.courseName} (${item.jeeClassName})` : item.className} - ${item.sectionNo} (${item.academicYearDesc})`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              {
                //  <Typography variant="body1">
                //   Class{" "}
                //   {`${props.classDetails.className} - ${props.classDetails.sectionNo}`}
                // </Typography>
              }
            </Grid>
          </Grid>
        ) : props.teacherType === "Subject" && props.subjectTeachDetails ? (
          <Grid item container direction="column" spacing={2}>
            <Grid item container>
              <TextField
                id=""
                variant="outlined"
                label="Select Class"
                color="primary"
                select
                fullWidth
                name="Class"
                value={props.selectedClass}
                onChange={(e) => {
                  console.log(e.target);
                  props.handleClassSelection(classTeaching[e.target.value]);
                  props.setSelectedClass(e.target.value);
                }}
              >
                {myClass && myClass.map((item, index) => (
                  <MenuItem key={index + 1} value={item.fullClass} myval={item.fullClass}>
                    {" "}
                    {`${item.boardId > 6 ? `${item.courseName} (${item.jeeClassName})` : item.fullClass} - ${item.sectionNo}(${item.academicYearDesc})`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item container>
              <TextField
                id=""
                variant="outlined"
                label="Select Subject"
                color="primary"
                disabled={
                  props.sujectForClass && props.sujectForClass.length > 0
                    ? false
                    : true
                }
                select
                fullWidth
                name="Subject"
                value={props.selectSubjectTeacher}
                onChange={(e) => props.handleSujectSelect(e.target.value)}
              >
                {props.sujectForClass &&
                  props.sujectForClass.map((item, index) => (
                    <MenuItem key={item + index} value={item}>
                      {console.log("kkkkk", item)}
                      {`${item.subjectName}`}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        ) : undefined}
      </Grid>
    </React.Fragment>
  );
};

export default function StudentInfoPaper(props) {
  const classes = useStyle();
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [progressStart, setProgressStart] = useState(false);
  const [myOptions, setMyOptions] = useState([]);
  const [selectedChildInfo, setSelectedChildInfo] = useState("");
  const [profileLoader, setProfileLoader] = useState(false);
  const [teacherSelectedOpen, setTeacherSelectedOpen] = useState(
    JSON.parse(window.localStorage.getItem("teacherOpen") || "null") ==
      "null" ||
      JSON.parse(window.localStorage.getItem("teacherOpen") || "null") ==
      "false"
      ? true
      : false
  );
  const [editStudent, handleEditStudent] = useState({
    aim: props.wish,
    roleId: window.localStorage.roledId,
    bloodGroup: null,
    loginUserId:
      window.localStorage.roledId == 5
        ? window.localStorage.parentUserId
        : window.localStorage.userId,
    userId: null,
    fileName: null,
    gileType: null,
    userProfilePicture: null,
  });
  const [uploadDialog, setUploadDialog] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [sujectForClass, setSubjectForClass] = useState([]);

  const [openUploadSnack, setUploadSnack] = useState("");
  const [teacherType, setTeacherType] = useState("Class");
  const [selectedSiblingOpen, setselectedSiblingOpen] = useState(
    JSON.parse(window.localStorage.getItem("siblingOpen") || "null") ==
      "null" ||
      JSON.parse(window.localStorage.getItem("siblingOpen") || "null") == false
      ? true
      : false
  );

  const [classDetails, setClassDetails] = useState(
    window.localStorage.isClassTeacher == "true"
      ? JSON.parse(window.localStorage.getItem("classTeacher") || "[]")
      : null
  );
  const [newclassDetails, setNewClassDetails] = useState({});
  const [subjectTeachDetails, setSubjectTeachDetails] = useState(
    window.localStorage.isSubjectTeacher == "true"
      ? JSON.parse(window.localStorage.getItem("myusers") || "[]")
      : null
  );
  const [selectSubjectTeacher, setSelectSubjectTeacher] = useState({});

  const [editTeacherOpen, setEditTeacherOpen] = useState(false);
  const [uploadTeacherDialog, setUploadTeacherDialog] = useState(false);
  const [vcDialog, setvcDialog] = React.useState(false);
  const [vcData, setVcData] = React.useState([]);
  const [copy, setCopy] = React.useState(false);
  const [openVcSnack, setVcSnackOpen] = React.useState(false);
  const [tt, setTT] = useState(false);
  const [editTeacher, setEditTeacher] = useState({
    bloodGroup: "",
    fileName: "",
    fileType: "",
    userProfilePicture: "",
  });
  const [selectedClass, setSelectedClass] = useState({});
  const [checkClass, setCheckClass] = useState(-1);

  const [youTubeData, setYouTubeData] = useState({});
  const [youTubeDialog, setYouTubeDialog] = useState(false);
  const [paymentData, setPaymentData] = useState()
  const [loading, setLoading] = useState(false);

  const handleClassSelection = (e) => {
    setSubjectForClass(e);
  };



  useEffect(() => {
    window.localStorage.teacherOpen == "false"
      ? handleTeacherTypeSelect("use")
      : console.log("no need to show pop up");
  }, []);

  const handleLogout = () => {
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "UAM_SIGN_OUT",
              busiParams: {
                deviceId: window.localStorage.uuid,
                loginProfileId:
                  window.localStorage.roleId == "5"
                    ? window.localStorage.parentUserId
                    : window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setMyOptions(data.respData[0].respMsg.results);
          window.localStorage.clear();
          history.push("/Login");
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleMyWay = async (e) => {
    setselectedSiblingOpen(false);
    setSelectedChildInfo(e.target.value);
    setProgressStart(true);
    window.localStorage.siblingOpen = true;
    let student = JSON.parse(e.target.value);
    // console.log(student);
    window.localStorage.orgImage = student.orgImage;
    window.localStorage.name = `${student.firstName} ${student.lastName === null ? "" : student.lastName
      }`;
    let studentroleId = student.roleId;
    // window.localStorage.username = student.username;
    window.localStorage.branchId = student.branchId;
    window.localStorage.userId = student.userId;

    try {
      const MYRESPONSE = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_DATA_BY_USERID_ROLEID",
              busiParams: {
                roleId: studentroleId,
                userId: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      });

      const MYDATA = MYRESPONSE.json();

      await MYDATA.then((data) => {
        window.localStorage.periods =
          data.respData[0].respMsg.result.noOfPeriods;
        window.localStorage.studentInstanceId =
          data.respData[0].respMsg.result.studentInstanceId;
        window.localStorage.sectionId =
          data.respData[0].respMsg.result.sectionId;
        window.localStorage.className = data.respData[0].respMsg.result.boardId > 6 ?
          data.respData[0].respMsg.result.jeeClassName : data.respData[0].respMsg.result.className;
        window.localStorage.boardId = data.respData[0].respMsg.result.boardId;
        window.localStorage.courseName = data.respData[0].respMsg.result.courseName
        window.localStorage.sectionName =
          data.respData[0].respMsg.result.sectionName;
        window.localStorage.rollNo = data.respData[0].respMsg.result.rollNo;
        window.localStorage.bloodGroup =
          data.respData[0].respMsg.result.bloodGroupName;
        window.localStorage.profilePic =
          data.respData[0].respMsg.result.userProfilePicture;
        window.localStorage.bloodGroupId =
          data.respData[0].respMsg.result.bloodGroup;
        window.localStorage.aim = data.respData[0].respMsg.result.aim;
        window.localStorage.academicYearDesc = data.respData[0].respMsg.result.academicYearDesc;
      });

      console.log(window.localStorage);
    } catch (error) {
      console.error(error);
    }
    console.log(window.localStorage.studentInstanceId);

    await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_GET_STU_SECSUBMAP_BY_STUINSId",
            busiParams: {
              status: true,
              studentInstanceId: window.localStorage.studentInstanceId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then(
        (data) => {
          let users = data.respData[0].respMsg.results;
          localStorage.setItem("users", JSON.stringify(users));
          let use = JSON.parse(localStorage.getItem("users") || "[]");
        }
        // window.localStorage.mySubjects
      )
      .catch((data) => {
        console.error(data);
        // setError(true);
      });

    setProgressStart(false);

    props.setmyForcedRender(!props.forcedRender);
  };

  useEffect(() => {
    let data = JSON.parse(
      window.localStorage.getItem("childrenProfile") || "[]"
    );
    console.log(data);
    if (data.length === 1) {
      console.log(JSON.stringify(data[0]));
      handleMyWay({ target: { value: JSON.stringify(data[0]) } });
    }
  }, []);

  useEffect(() => {
    const bodyObjbloodGroupInfo = {
      requestList: [
        {
          isEncrypt: false,
          transactionId: "0000001438241244",
          busiCode: "MDM_GET_LOOKUP_SUB_CATG",
          busiParams: {
            categoryName: "blood group",
            flag: true,
          },
        },
      ],
      pubInfo: {
        appId: "appId",
        sessionId: window.localStorage.sessionId,
        version: "21",
        timestamp: "20180314175419",
        lang: "en_US",
        userId: "1000016614",
        serviceId: "7021150585",
        circleId: "MU",
        ncsroute: "WE015",
        customerId: "1000016614",
        osType: "windows",
      },
    };
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(bodyObjbloodGroupInfo),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("heeyyyyyy");
          console.log(data);
          setMyOptions(data.respData[0].respMsg.results);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleUploadClose = () => {
    setUploadDialog(false);
  };

  const handleUploadEditTeacherClose = () => {
    setUploadTeacherDialog(false);
  };

  const handleEditTeacherClose = () => {
    setEditTeacherOpen(false);
  };

  const setEditStudent = (e) => {
    handleEditStudent({
      ...editStudent,
      [e.target.name]: e.target.value,
      userId: window.localStorage.userId,
      roleId: window.localStorage.roleId,
    });
  };

  const handleSujectSelect = (e) => {
    console.log("sdsdsds", e.target.value);
    setSelectSubjectTeacher(e.target.value);
  };

  const handleClassTeacherSelection = (e) => {
    setNewClassDetails(e);
    setTT(true);
  };

  const handleTeacherTypeSelect = async (type) => {
    let classTeacher, subjectTeacher;

    window.localStorage.classTeacher
      ? (classTeacher = JSON.parse(
        window.localStorage.getItem("classTeacher") || "[]"
      ))
      : (classTeacher = []);

    window.localStorage.users
      ? (subjectTeacher = JSON.parse(
        window.localStorage.getItem("myusers") || "[]"
      ))
      : (subjectTeacher = []);
    console.log(classTeacher);
    console.log(subjectTeacher);
    setClassDetails(classTeacher);
    setSubjectTeachDetails(subjectTeacher);

    if (type !== "edit") {
      setTeacherSelectedOpen(true);
      window.localStorage.teacherOpen = true;
    } else if (type === "edit") {
      setTeacherSelectedOpen(true);
    }
  };
  const handleDelete = () => {
    setProgressStart(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "UAM_DELETE_USER_PROFILE_PIC",
              busiParams: {
                loginUserId:
                  window.localStorage.roledId == 5
                    ? window.localStorage.parentUserId
                    : window.localStorage.userId,
                userId: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          console.log(data);

          if (window.localStorage.roleId == 5) {
            let student = JSON.parse(selectedChildInfo);
            console.log(student);
            window.localStorage.orgImage = student.orgImage;
            window.localStorage.name = `${student.firstName} ${student.lastName === null ? "" : student.lastName
              }`;
            let studentroleId = student.roleId;
            // window.localStorage.username = student.username;
            window.localStorage.branchId = student.branchId;
            window.localStorage.userId = student.userId;

            try {
              const MYRESPONSE = await fetch(url, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                  requestList: [
                    {
                      isEncrypt: false,
                      transactionId: "0000001438241244",
                      busiCode: "SCHOOL_GET_DATA_BY_USERID_ROLEID",
                      busiParams: {
                        roleId: studentroleId,
                        userId: window.localStorage.userId,
                      },
                    },
                  ],
                  pubInfo: {
                    appId: "appId",
                    sessionId: window.localStorage.sessionId,
                    version: "21",
                    timestamp: "20180314175419",
                    lang: "en_US",
                    userId: "1000016614",
                    serviceId: "7021150585",
                    circleId: "MU",
                    ncsroute: "WE015",
                    customerId: "1000016614",
                    osType: "windows",
                  },
                }),
              });

              const MYDATA = MYRESPONSE.json();

              await MYDATA.then((data) => {
                console.log(data);

                window.localStorage.profilePic =
                  data.respData[0].respMsg.result.userProfilePicture;

                student.userProfilePicture =
                  data.respData[0].respMsg.result.userProfilePicture;

                setSelectedChildInfo(JSON.stringify(student));
                let childupdate = JSON.parse(
                  window.localStorage.childrenProfile
                );
                let mychild = childupdate.map((item, index, arr) => {
                  if (item.userId == window.localStorage.userId) {
                    return { myindex: index, myitem: item };
                  }
                });

                childupdate[mychild.myindex] = student;
                console.log(childupdate);
                window.localStorage.childrenProfile = JSON.stringify(
                  childupdate
                );
              });
              setProgressStart(false);

              setOpen(false);
              props.setmyForcedRender(!props.forcedRender);
            } catch (error) {
              console.error(error);
              setProgressStart(false);

              setOpen(false);
            }
          } else {
            try {
              const MYRESPONSE = await fetch(url, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                  requestList: [
                    {
                      isEncrypt: false,
                      transactionId: "0000001438241244",
                      busiCode: "SCHOOL_GET_DATA_BY_USERID_ROLEID",
                      busiParams: {
                        roleId: window.localStorage.roleId,
                        userId: window.localStorage.userId,
                      },
                    },
                  ],
                  pubInfo: {
                    appId: "appId",
                    sessionId: window.localStorage.sessionId,
                    version: "21",
                    timestamp: "20180314175419",
                    lang: "en_US",
                    userId: "1000016614",
                    serviceId: "7021150585",
                    circleId: "MU",
                    ncsroute: "WE015",
                    customerId: "1000016614",
                    osType: "windows",
                  },
                }),
              });

              const MYDATA = MYRESPONSE.json();

              await MYDATA.then((data) => {
                window.localStorage.profilePic =
                  data.respData[0].respMsg.result.userProfilePicture;
                setProgressStart(false);

                setEditTeacherOpen(false);
                setOpen(false);
                props.setmyForcedRender(!props.forcedRender);
              });
            } catch (error) {
              console.error(error);
              setProgressStart(false);
              setOpen(false);
              setEditTeacherOpen(false);
            }
          }
        })
        .catch((data) => {
          console.error(data);
          setOpen(false);

          setEditTeacherOpen(false);
        });
    } catch (error) {
      console.error(error);
      setOpen(false);

      setEditTeacherOpen(false);
    }

    // props.setmyForcedRender(!props.forcedRender);
  };

  const handleOnUploadSubmit = async (files, event) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    console.log(attachment);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".")[0];
    let myFileType = files[0].type.split("/")[1];

    //setEditStudent(attachment);
    handleEditStudent({
      ...editStudent,
      userProfilePicture: newattachment,
      fileName: myFileName,
      fileType: myFileType,
      userId: window.localStorage.userId,
      roleId: window.localStorage.roleId,
    });

    console.log(event);
    handleUploadClose();
  };

  const handleEditTeacherSubmit = async () => {
    setProgressStart(true);
    console.log(editTeacher);
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "UAM_UPDATE_STU_DETAIL_BY_PARENT",
              busiParams: {
                aim: null,
                roleId: parseInt(window.localStorage.roleId),
                bloodGroup: editTeacher.bloodGroup,
                loginUserId: parseInt(window.localStorage.userId),
                userId: parseInt(window.localStorage.userId),
                fileName: editStudent.fileName,
                fileType: editStudent.fileType,
                userProfilePicture: editStudent.userProfilePicture,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((data) => {
          console.error(data);

          // setError(true);
        });
    } catch (error) {
      console.log(error);
    }

    try {
      const MYRESPONSE = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_DATA_BY_USERID_ROLEID",
              busiParams: {
                roleId: window.localStorage.roleId,
                userId: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      });

      const MYDATA = MYRESPONSE.json();

      await MYDATA.then((data) => {
        console.log(data);

        window.localStorage.bloodGroup =
          data.respData[0].respMsg.result.bloodGroupName;
        window.localStorage.bloodGroupId =
          data.respData[0].respMsg.result.bloodGroup;
        window.localStorage.profilePic =
          data.respData[0].respMsg.result.userProfilePicture;
      });

      props.setmyForcedRender(!props.forcedRender);
      console.log(window.localStorage);
      setProgressStart(false);
      handleEditTeacherClose();
    } catch (error) {
      console.error(error);
      setProgressStart(false);
      handleEditTeacherClose();
    }
  };

  const handleOnUploadEditTeacherSubmit = async (files) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    console.log(attachment);
    let myFileName = files[0].name.split(".");
    let myFileType = files[0].type.split("/");
    let newattachment = attachment.split("base64,")[1];
    setEditTeacher({
      ...editTeacher,
      userProfilePicture: newattachment,
      fileName: myFileName,
      fileType: myFileType,
    });

    handleUploadEditTeacherClose();
  };

  const handleUploadDialogOpen = (e) => {
    setUploadDialog(true);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleEditSubmit = async () => {
    setProgressStart(true);

    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "UAM_UPDATE_STU_DETAIL_BY_PARENT",
              busiParams: editStudent,
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }
    console.log(selectedChildInfo);
    let student = JSON.parse(selectedChildInfo);
    console.log(student);
    window.localStorage.orgImage = student.orgImage;
    window.localStorage.name = `${student.firstName} ${student.lastName === null ? "" : student.lastName
      }`;
    let studentroleId = student.roleId;
    // window.localStorage.username = student.username;
    window.localStorage.branchId = student.branchId;
    window.localStorage.userId = student.userId;

    try {
      const MYRESPONSE = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_DATA_BY_USERID_ROLEID",
              busiParams: {
                roleId: studentroleId,
                userId: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      });

      const MYDATA = MYRESPONSE.json();

      await MYDATA.then((data) => {
        console.log(data);;
        window.localStorage.studentInstanceId =
          data.respData[0].respMsg.result.studentInstanceId;
        window.localStorage.sectionId =
          data.respData[0].respMsg.result.sectionId;
        window.localStorage.className =
          data.respData[0].respMsg.result.className;
        window.localStorage.sectionName =
          data.respData[0].respMsg.result.sectionName;
        window.localStorage.bloodGroup =
          data.respData[0].respMsg.result.bloodGroupName;
        window.localStorage.bloodGroupId =
          data.respData[0].respMsg.result.bloodGroup;
        window.localStorage.profilePic =
          data.respData[0].respMsg.result.userProfilePicture;
        window.localStorage.aim = data.respData[0].respMsg.result.aim;
        window.localStorage.rollNo = data.respData[0].respMsg.result.rollNo;
        student.bloodGroup = data.respData[0].respMsg.result.bloodGroup;
        student.bloodGroupName = data.respData[0].respMsg.result.bloodGroupName;
        student.userProfilePicture =
          data.respData[0].respMsg.result.userProfilePicture;
        student.aim = data.respData[0].respMsg.result.aim;
        window.localStorage.academicYearDesc = data.respData[0].respMsg.result.academicYearDesc;

        setSelectedChildInfo(JSON.stringify(student));
        let childupdate = JSON.parse(window.localStorage.childrenProfile);
        let mychild = childupdate.map((item, index, arr) => {
          if (item.userId == window.localStorage.userId) {
            return { myindex: index, myitem: item };
          }
        });

        childupdate[mychild.myindex] = student;
        console.log(childupdate);
        window.localStorage.childrenProfile = JSON.stringify(childupdate);
      });
      setProgressStart(false);

      setOpen(false);
      props.setmyForcedRender(!props.forcedRender);
      console.log(window.localStorage);
    } catch (error) {
      console.error(error);
      setProgressStart(false);

      setOpen(false);
    }
  };

  const handleEditSubmitStudent = async () => {
    setProgressStart(true);

    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "UAM_UPDATE_STU_DETAIL_BY_PARENT",
              busiParams: editStudent,
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    try {
      const MYRESPONSE = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_DATA_BY_USERID_ROLEID",
              busiParams: {
                roleId: window.localStorage.roleId,
                userId: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      });

      const MYDATA = MYRESPONSE.json();

      await MYDATA.then((data) => {
        console.log(data);
        window.localStorage.studentInstanceId =
          data.respData[0].respMsg.result.studentInstanceId;
        window.localStorage.sectionId =
          data.respData[0].respMsg.result.sectionId;
        window.localStorage.className =
          data.respData[0].respMsg.result.className;
        window.localStorage.sectionName =
          data.respData[0].respMsg.result.sectionName;
        window.localStorage.bloodGroup =
          data.respData[0].respMsg.result.bloodGroupName;
        window.localStorage.bloodGroupId =
          data.respData[0].respMsg.result.bloodGroup;
        window.localStorage.profilePic =
          data.respData[0].respMsg.result.userProfilePicture;
        window.localStorage.aim = data.respData[0].respMsg.result.aim;
        window.localStorage.rollNo = data.respData[0].respMsg.result.rollNo;
        window.localStorage.academicYearDesc = data.respData[0].respMsg.result.academicYearDesc;
      });
      setProgressStart(false);

      setOpen(false);
      props.setmyForcedRender(!props.forcedRender);
      console.log(window.localStorage);
    } catch (error) {
      console.error(error);
      setProgressStart(false);

      setOpen(false);
    }
  };

  const handleTeacherEditSubmit = async () => {
    setProgressStart(true);

    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "UAM_UPDATE_STU_DETAIL_BY_PARENT",
              busiParams: editStudent,
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }
    console.log(selectedChildInfo);
    let student = JSON.parse(selectedChildInfo);
    console.log(student);
    window.localStorage.orgImage = student.orgImage;
    window.localStorage.name = `${student.firstName} ${student.lastName === null ? "" : student.lastName
      }`;
    let studentroleId = student.roleId;
    window.localStorage.username = student.username;
    window.localStorage.branchId = student.branchId;
    window.localStorage.userId = student.userId;

    const bodyObjUserDetails = {
      requestList: [
        {
          isEncrypt: false,
          transactionId: "0000001438241244",
          busiCode: "SCHOOL_GET_DATA_BY_USERID_ROLEID",
          busiParams: {
            roleId: studentroleId,
            userId: window.localStorage.userId,
          },
        },
      ],
      pubInfo: {
        appId: "appId",
        sessionId: window.localStorage.sessionId,
        version: "21",
        timestamp: "20180314175419",
        lang: "en_US",
        userId: "1000016614",
        serviceId: "7021150585",
        circleId: "MU",
        ncsroute: "WE015",
        customerId: "1000016614",
        osType: "windows",
      },
    };

    try {
      const MYRESPONSE = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(bodyObjUserDetails),
      });

      const MYDATA = MYRESPONSE.json();

      await MYDATA.then((data) => {
        console.log(data);
        window.localStorage.studentInstanceId =
          data.respData[0].respMsg.result.studentInstanceId;
        window.localStorage.sectionId =
          data.respData[0].respMsg.result.sectionId;
        window.localStorage.className =
          data.respData[0].respMsg.result.className;
        window.localStorage.sectionName =
          data.respData[0].respMsg.result.sectionName;
        window.localStorage.bloodGroup =
          data.respData[0].respMsg.result.bloodGroupName;
        window.localStorage.bloodGroupId =
          data.respData[0].respMsg.result.bloodGroup;
        window.localStorage.profilePic =
          data.respData[0].respMsg.result.userProfilePicture;
        window.localStorage.aim = data.respData[0].respMsg.result.aim;
        window.localStorage.rollNo = data.respData[0].respMsg.result.rollNo;
        student.bloodGroup = data.respData[0].respMsg.result.bloodGroup;
        student.bloodGroupName = data.respData[0].respMsg.result.bloodGroupName;
        student.userProfilePicture =
          data.respData[0].respMsg.result.userProfilePicture;
        student.aim = data.respData[0].respMsg.result.aim;
        window.localStorage.academicYearDesc = data.respData[0].respMsg.result.academicYearDesc;

        setSelectedChildInfo(JSON.stringify(student));
        let childupdate = JSON.parse(window.localStorage.childrenProfile);
        let mychild = childupdate.map((item, index, arr) => {
          if (item.userId == window.localStorage.userId) {
            return { myindex: index, myitem: item };
          }
        });

        childupdate[mychild.myindex] = student;
        console.log(childupdate);
        window.localStorage.childrenProfile = JSON.stringify(childupdate);
      });
      setProgressStart(false);

      setOpen(false);
      props.setmyForcedRender(!props.forcedRender);
      console.log(window.localStorage);
    } catch (error) {
      console.error(error);
      setProgressStart(false);

      setOpen(false);
    }
  };

  const handleEditProfileOpen = () => {
    setOpen(true);
  };

  const handleRadioClick = (e) => {
    setSelectedChildInfo(e.target.value);
    console.log(e.target.value);
  };

  const siblingContent = (
    <Grid container direction="row" spacing={1}>
      {JSON.parse(window.localStorage.getItem("childrenProfile") || "[]").map(
        (item, index) => (
          <Grid item container direction="column" sm alignItems="center">
            <Grid item container>
              <Card style={{ padding: "0.5em", minWidth: "16em" }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item sm>
                    <Avatar
                      src={item.userProfilePicture}
                      alt="profilepic"
                      className={classes.profileImg}
                    />
                  </Grid>

                  <Grid item sm>
                    <Grid item container spacing={0} direction="column">
                      <Grid item>
                        <Typography
                          variant="h6"
                          style={{
                            color: theme.palette.common.black,
                            fontWeight: "bold",
                          }}
                        >
                          {`${item.firstName === null ? "" : item.firstName} ${item.middleName === null ? "" : item.middleName
                            } ${item.lastName === null ? "" : item.lastName}`}
                        </Typography>
                      </Grid>
                      {
                        // <Grid item>
                        //   <Typography variant="subtitle1">
                        //     {props.standard}
                        //   </Typography>
                        // </Grid>
                        // <Grid item>
                        //   <Typography variant="subtitle1">
                        //     {`Roll No.- ${
                        //       item.rollNo === undefined ? "--" : item.rollNo
                        //     }`}
                        //   </Typography>
                        // </Grid>
                        // <Grid item>
                        //   <Typography variant="subtitle1">
                        //     {`Blood Group - ${
                        //       item.bloodGroup === undefined
                        //         ? "--"
                        //         : item.bloodGroupName
                        //     }`}
                        //   </Typography>
                        // </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item>
              <Radio
                checked={selectedChildInfo === JSON.stringify(item)}
                value={JSON.stringify(item)}
                name="selectchild"
                onClick={handleRadioClick}
              />
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  );

  const handleProceed = async () => {
    setProgressStart(true);
    window.localStorage.siblingOpen = true;
    let student = JSON.parse(selectedChildInfo);
    // console.log(student);
    window.localStorage.orgImage = student.orgImage;
    window.localStorage.name = `${student.firstName} ${student.lastName === null ? "" : student.lastName
      }`;
    let studentroleId = student.roleId;
    // window.localStorage.username = student.username;
    window.localStorage.branchId = student.branchId;
    window.localStorage.userId = student.userId;

    const bodyObjUserDetails = {
      requestList: [
        {
          isEncrypt: false,
          transactionId: "0000001438241244",
          busiCode: "SCHOOL_GET_DATA_BY_USERID_ROLEID",
          busiParams: {
            roleId: studentroleId,
            userId: window.localStorage.userId,
          },
        },
      ],
      pubInfo: {
        appId: "appId",
        sessionId: window.localStorage.sessionId,
        version: "21",
        timestamp: "20180314175419",
        lang: "en_US",
        userId: "1000016614",
        serviceId: "7021150585",
        circleId: "MU",
        ncsroute: "WE015",
        customerId: "1000016614",
        osType: "windows",
      },
    };

    try {
      const MYRESPONSE = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(bodyObjUserDetails),
      });

      const MYDATA = MYRESPONSE.json();

      await MYDATA.then((data) => {
        console.log(data);
        window.localStorage.userData = JSON.stringify(data.respData[0].respMsg.result);
        window.localStorage.boardId = data.respData[0].respMsg.result.boardId;
        window.localStorage.periods =
          data.respData[0].respMsg.result.noOfPeriods;
        window.localStorage.studentInstanceId =
          data.respData[0].respMsg.result.studentInstanceId;
        window.localStorage.sectionId =
          data.respData[0].respMsg.result.sectionId;
        window.localStorage.className = data.respData[0].respMsg.result.boardId > 6 ?
          data.respData[0].respMsg.result.jeeClassName : data.respData[0].respMsg.result.className;
        window.localStorage.sectionName =
          data.respData[0].respMsg.result.sectionName;
        window.localStorage.rollNo = data.respData[0].respMsg.result.rollNo;
        window.localStorage.bloodGroup =
          data.respData[0].respMsg.result.bloodGroupName;
        window.localStorage.profilePic =
          data.respData[0].respMsg.result.userProfilePicture;
        window.localStorage.bloodGroupId =
          data.respData[0].respMsg.result.bloodGroup;
        window.localStorage.aim = data.respData[0].respMsg.result.aim;
        window.localStorage.academicYearDesc = data.respData[0].respMsg.result.academicYearDesc;
        window.localStorage.courseName =
          data.respData[0].respMsg.result.boardId > 6 ?
            data.respData[0].respMsg.result.courseName : "";
      });

      console.log(window.localStorage);
    } catch (error) {
      console.error(error);
    }
    console.log(window.localStorage.studentInstanceId);

    await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_GET_STU_SECSUBMAP_BY_STUINSId",
            busiParams: {
              status: true,
              studentInstanceId: window.localStorage.studentInstanceId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then(
        (data) => {
          let users = data.respData[0].respMsg.results;
          localStorage.setItem("users", JSON.stringify(users));
          let use = JSON.parse(localStorage.getItem("users") || "[]");
        }
        // window.localStorage.mySubjects
      )
      .catch((data) => {
        console.error(data);
        // setError(true);
      });

    setProgressStart(false);

    props.setmyForcedRender(!props.forcedRender);
    setselectedSiblingOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTeacherSelectProceed = () => {
    window.location.reload();
    localStorage.removeItem('examTab');
    localStorage.removeItem('examanalysisTab');
    localStorage.removeItem('examWorkTab');
    localStorage.removeItem('writingWorkTab');

    console.log(selectSubjectTeacher);
    console.log(teacherType);
    if (teacherType === "Class") {
      console.log("classTeacherSelected");
      window.localStorage.sessionEndDate = newclassDetails.endDate
      window.localStorage.sectionName = newclassDetails.sectionNo;
      window.localStorage.sectionId = newclassDetails.sectionId;
      window.localStorage.periods = newclassDetails.noOfPeriod;
      window.localStorage.teacherType = "Class";
      window.localStorage.academicYearDesc = newclassDetails.academicYearDesc;
      // let classinfo = newclassDetails.;
      console.log("classTeacherSelected", newclassDetails);
      localStorage.setItem("users", JSON.stringify(newclassDetails.subjects));
      window.localStorage.setItem("boardId", newclassDetails.boardId);
      window.localStorage.className = newclassDetails.boardId > 6 ?
        newclassDetails.jeeClassName : newclassDetails.className;
      window.localStorage.courseName = newclassDetails.boardId > 6 ? newclassDetails.courseName : "";
    } else {
      console.log("subjectTeacherSelected");
      console.log(selectSubjectTeacher);
      window.localStorage.boardId = selectSubjectTeacher.boardId;
      window.localStorage.className = selectSubjectTeacher.boardId > 6 ?
        selectSubjectTeacher.jeeClassName : selectSubjectTeacher.className;
      window.localStorage.sectionName = selectSubjectTeacher.sectionNo;
      window.localStorage.sectionId = selectSubjectTeacher.sectionId;
      window.localStorage.secSylMappingId =
        selectSubjectTeacher.secSubSylMappingId;
      window.localStorage.teacherType = "Subject";
      window.localStorage.periods = selectSubjectTeacher.noOfPeriod;
      window.localStorage.subjectId = selectSubjectTeacher.subjectId;
      window.localStorage.subjectName = selectSubjectTeacher.subjectName;
      localStorage.setItem("users", JSON.stringify([selectSubjectTeacher]));
      window.localStorage.academicYearDesc = selectSubjectTeacher.academicYearDesc;
      window.localStorage.courseName = selectSubjectTeacher.boardId > 6 ? selectSubjectTeacher.courseName : "";

    }
    props.setmyForcedRender(!props.forcedRender);
    setTeacherSelectedOpen(false);
  };

  const bodyObjforVc = {
    requestList: [
      {
        isEncrypt: false,
        transactionId: "0000001438241244",

        busiCode: "SCHOOL_GET_VCLINK_BY_TEACHER",
        busiParams: {
          employeeId: window.localStorage.employeeId,
        },
      },
    ],
    pubInfo: {
      appId: "appId",
      sessionId: window.localStorage.sessionId,
      version: "21",
      timestamp: "20180314175419",
      lang: "en_US",
      userId: "1000016614",
      serviceId: "7021150585",
      circleId: "MU",
      ncsroute: "WE015",
      customerId: "1000016614",
      osType: "windows",
    },
  };

  const handleVcButtonClicked = async () => {
    console.log("the function vc called");
    setvcDialog(true);
    await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(bodyObjforVc),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        let tempArr = [];
        data.respData[0].respMsg.result.map((item) => {
          let tempObj = {
            class: item.className,
            section: item.sectionNo,
            username: item.hostUserName,
            url: item.url,
            password: item.hostPassword,
            description: item.description,
            isYoutube: item.url.substring(8, item.url.lastIndexOf("/")) === "youtu.be" ? true : false
          };
          tempArr.push(tempObj);
        });
        setVcData(tempArr);

      })
      .catch((data) => {
        console.error(data);
        // setError(true);
      });
  };

  const vcSnacfFunc = () => {
    console.log("testing snack");
    setCopy(true);
  };


  const getPaymentStatus = () => {
    setLoading(true)
    try {
      fetch(url, makeReqBody("SCHOOL_TAIT_CHECK_STUDENT_TRXN", {
        studentInstanceId: window.localStorage.studentInstanceId
      })).then(resp => resp.json())
        .then(data => {
          console.log("data", data);
          setPaymentData(data.respData[0].respMsg.result)
          setInterval(() => {
            setLoading(false)
          }, 2000)
        }).catch(error => {
          setInterval(() => {
            setLoading(false)
          }, 2000)
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    getPaymentStatus()
  }, [])

  const studentInfo = (
    <React.Fragment>
      {window.localStorage.roleId == 3 ? (
        <Grid
          container
          className={classes.studentPaper}
          spacing={5}
          justify="space-between"
          alignItems="center"
        >
          <Grid item container className={classes.gridItems} sm={7}>
            <Grid item container alignItems="center" spacing={2}>
              <Grid item>
                <Badge
                  color="default"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <p
                      style={{
                        color: theme.palette.common.myFont,
                        fontWeight: "bold",
                      }}
                    >
                      +
                    </p>
                  }
                  overlap="circle"
                  className={classes.badgeStyle}
                  onClick={() => setEditTeacherOpen(true)}
                >
                  {props.profileLoader === true ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <Avatar
                      src={props.profilePic}
                      alt="profilepic"
                      children={<img src={teacherfallback} alt="fallback" />}
                      className={classes.profileImg}
                    />
                  )}
                </Badge>
              </Grid>

              <Grid item>
                <Grid item container spacing={0} direction="column">
                  <Grid item>
                    <Typography variant="subtitle1">{props.name} ({props.teacherType})</Typography>
                    {window.localStorage.teacherType == "Subject" ?
                      <Typography variant="subtitle1">Sub : {props.subjectName}</Typography> : null
                    }
                  </Grid>

                  <Grid item>
                    <Typography variant="subtitle1">
                      {props.bloodGroup}
                    </Typography>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item>
                      <Typography variant="subtitle1">
                        {props.standard}
                      </Typography>
                    </Grid>
                    {
                      <Grid item>
                        <IconButton
                          onClick={() => handleTeacherTypeSelect("edit")}
                          style={{
                            color:
                              theme.typography.myTheme.themeName === "Night"
                                ? theme.palette.common.white
                                : theme.palette.common.black,
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container direction="column" sm>
                <Grid item>
                  <img
                    className={classes.schoolLogo}
                    src={props.orgImg}
                    alt="schoollogo"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {window.localStorage.variantId != 7 &&
            <Grid item container sm={2} justify="center">
              <Button
                variant="contained"
                onClick={handleVcButtonClicked}
                style={{
                  color: "white",
                  backgroundColor: "black",
                  textTransform: "none",
                }}
              >
                Online Class Link
              </Button>
            </Grid>
          }
          {vcDialog === true ? (
            <Dialog
              open={vcDialog}
              onClose={() => setvcDialog(false)}
              classes={{ paperWidthSm: classes.dialogwidth }}
              className={classes.dialogContainer}
            >
              <Toolbar
                variant="dense"
                style={{ backgroundColor: theme.palette.common.ltgrey }}
              >
                <Grid container justify="space-between">
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item>
                        {" "}
                        <VideocamOutlinedIcon
                          style={{
                            marginRight: "0.5em",
                            color: theme.palette.common.tabFont,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{ color: theme.palette.common.tabFont }}
                        >
                          Online Class Links
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{
                      cursor: "pointer",
                      color: theme.palette.common.tabFont,
                    }}
                  >
                    <CloseOutlinedIcon onClick={() => setvcDialog(false)} />
                  </Grid>
                </Grid>
              </Toolbar>
              <DialogContent>
                <Grid item container direction="column" spacing={4}>
                  <Grid item container direction="row" justify="flex-start">
                    <Grid item container sm={2}>
                      <Typography
                        variant="h6"
                        style={{
                          color:
                            theme.typography.myTheme.themeName === "Night"
                              ? "black"
                              : "inherit",
                        }}
                      >
                        Class
                      </Typography>
                    </Grid>
                    <Grid item container sm={3}>
                      <Typography
                        variant="h6"
                        style={{
                          color:
                            theme.typography.myTheme.themeName === "Night"
                              ? "black"
                              : "inherit",
                        }}
                      >
                        Links
                      </Typography>
                    </Grid>
                    <Grid item container sm={2} style={{ marginLeft: "1em" }}>
                      <Typography
                        variant="h6"
                        style={{
                          color:
                            theme.typography.myTheme.themeName === "Night"
                              ? "black"
                              : "inherit",
                        }}
                      >
                        Username
                      </Typography>
                    </Grid>
                    <Grid item container sm={2} style={{ marginLeft: "0.6em" }}>
                      <Typography
                        variant="h6"
                        style={{
                          color:
                            theme.typography.myTheme.themeName === "Night"
                              ? "black"
                              : "inherit",
                        }}
                      >
                        Password
                      </Typography>
                    </Grid>
                    <Grid item container sm={2} style={{ marginLeft: "0.6em" }}>
                      <Typography
                        variant="h6"
                        style={{
                          color:
                            theme.typography.myTheme.themeName === "Night"
                              ? "black"
                              : "inherit",
                        }}
                      >
                        Description
                      </Typography>
                    </Grid>
                  </Grid>
                  {vcData.length > 0 ? (
                    vcData.map((item) => (
                      <Grid item container direction="column">
                        <Grid
                          item
                          container
                          direction="row"
                          justify="flex-start"
                          spacing={4}
                        >
                          <Grid item container sm={2}>
                            {item.class + "-" + item.section}
                          </Grid>
                          <Grid
                            item
                            container
                            sm={3}
                            direction="row"
                            alignItems="center"
                            justify="center"
                          >
                            <Grid
                              item
                              container
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              sm
                            >
                              <Typography
                                noWrap
                                style={{
                                  color: "#4dabf5",
                                  textDecoration: "none",
                                  cursor: "pointer"
                                }}
                                component="a"
                                href={item.url === null || item.isYoutube ? undefined : item.url}
                                target={
                                  item.url === null || item.isYoutube ? undefined : "_blank"
                                }
                              // onClick={() => {
                              //   setYouTubeData({
                              //     isYoutube: item.isYoutube,
                              //     embedId: item.isYoutube ? item.url.substring(item.url.lastIndexOf("/") + 1) : null
                              //   })
                              //   setYouTubeDialog(true)
                              // }}
                              >
                                {" "}
                                {item.url}
                              </Typography>
                            </Grid>
                            <Grid item container sm>
                              <CopyToClipboard
                                text={item.url}
                                style={{ marginLeft: "0.5em" }}
                              // onCopy={() => setCopy(true)}
                              >
                                <img
                                  src={link}
                                  alt=""
                                  color="secondary"
                                  className={classes.clipboardButton}
                                  onClick={() => setCopy(true)}
                                />
                              </CopyToClipboard>
                            </Grid>
                          </Grid>
                          <Grid item container sm={2}>
                            {item.username === undefined ||
                              item.username === null ||
                              item.username === ""
                              ? "NA"
                              : item.username}
                          </Grid>
                          <Grid item container sm={2}>
                            {item.password === undefined ||
                              item.password === null ||
                              item.password === ""
                              ? "NA"
                              : item.password}
                          </Grid>
                          <Grid item container sm={2}>
                            {item.description === undefined ||
                              item.description === null ||
                              item.description === ""
                              ? "NA"
                              : item.description}
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Divider style={{ backgroundColor: "#f5f5f5" }} />
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <Typography>No Data Available</Typography>
                  )}
                </Grid>
              </DialogContent>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                open={copy}
                autoHideDuration={6000}
                onClose={() => setCopy(false)}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={() => setCopy(false)}
                    >
                      <CloseIcon
                        style={{ color: theme.palette.common.tabFont }}
                      />
                    </IconButton>
                  </React.Fragment>
                }
              >
                <Alert>Copied</Alert>
              </Snackbar>
            </Dialog>
          ) : null}

          {youTubeData.isYoutube &&
            <Dialog
              open={youTubeDialog}
              onClose={() => setYouTubeDialog(false)}
              fullWidth={true}
              maxWidth={'md'}
            >
              <DialogContent style={{ margin: "auto" }}>
                <iframe
                  width="853"
                  height="480"
                  src={`https://www.youtube.com/embed/${youTubeData.embedId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </DialogContent>
            </Dialog>
          }

          <Grid item container direction="row" justify="flex-end" sm>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              onClick={() => props.themeDialogfunc(true)}
              style={{ cursor: "pointer", width: "9em" }}
              justify="flex-end"
            >
              <Grid item>
                <IconButton disableRipple>
                  <img
                    src={
                      theme.typography.myTheme.themeName === "Night"
                        ? themeicon2
                        : palette
                    }
                    alt="palette"
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">Theme</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                cursor: "pointer",
                width: "9em",
                textDecoration: "none",
              }}
              direction="row"
              alignItems="center"
              justify="flex-end"
              onClick={() => {
                //handleTeacherTypeSelect("edit");
                handleLogout();
                setTimeout(() => {
                  window.localStorage.siblingOpen = false;
                  window.localStorage.teacherOpen = false;
                }, 1000);
                //setselectedSiblingOpen(true);
                setClassDetails({});
                setSubjectTeachDetails([]);
                //setTeacherSelectedOpen(true)

                props.settabValue(0);
              }}
            >
              <Grid item>
                <IconButton disableRipple>
                  <img
                    src={
                      theme.typography.myTheme.themeName === "Night"
                        ? logout2
                        : Logout
                    }
                    alt="logout"
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">Logout</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          className={classes.studentPaper}
          spacing={5}
          justify="space-around"
          alignItems="center"
        >
          <Grid item container className={classes.gridItems} sm={6}>
            <Grid item container alignItems="center" spacing={2}>
              <Grid item>
                <Badge
                  color="default"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <p
                      style={{
                        color: theme.palette.common.myFont,
                        fontWeight: "bold",
                      }}
                    >
                      +
                    </p>
                  }
                  overlap="circle"
                  className={classes.badgeStyle}
                  onClick={handleMenuOpen}
                >
                  <Avatar
                    src={props.profilePic}
                    alt="profilepic"
                    children={<img src={teacherfallback} alt="fallback" />}
                    className={classes.profileImg}
                  />
                </Badge>
              </Grid>

              <Grid item>
                <Grid item container spacing={0} direction="column">
                  <Grid item>
                    <Typography variant="subtitle1">{props.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      {props.standard}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">{props.rollno}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      {props.bloodGroup}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container direction="column" sm>
                <Grid item>
                  <img
                    className={classes.schoolLogo}
                    src={props.orgImg}
                    alt="schoollogo"
                  />
                </Grid>
              </Grid>


            </Grid>
          </Grid>

          <Grid item>
            <Divider orientation="vertical" className={classes.divider} />
          </Grid>

          <Hidden mdDown>
            <Grid item container sm>
              <Grid item container alignItems="center" spacing={2}>
                <Grid item>
                  <img
                    src={
                      theme.typography.myTheme.themeName === "Night"
                        ? aimicon2
                        : aimIcon
                    }
                    alt="wisicon"
                    className={classes.iconImage}
                  />
                </Grid>

                <Grid
                  item
                  sm
                  style={{ width: "20vw", wordWrap: " break-word" }}
                >
                  <Typography variant="subtitle1">{`${props.wish}`}</Typography>
                </Grid>
                {
                  // <Grid
                  //   item
                  //   style={{ cursor: "pointer" }}
                  //   onClick={handleEditProfileOpen}
                  // >
                  //   <EditIcon
                  //     size="large"
                  //     style={{ color: theme.palette.common.black }}
                  //   />
                  // </Grid>
                }
              </Grid>
            </Grid>

            {
              //   <Grid item>
              //   <Divider orientation="vertical" className={classes.divider} />
              // </Grid>
            }
          </Hidden>



          <Grid item container direction="row" justify="flex-end" sm>
            <Grid
              item
              container
              alignItems="center"
              direction="column"
              spacing={2}
              xs
            >
              {paymentData && paymentData.roleId === 4 && paymentData.boardId > 6 &&
                <>
                  <Grid item sm style={{ position: "relative", padding: "4px", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                    <Typography variant="subtitle1">Payment Status</Typography>
                    <Tooltip title="Click here to check Payment status">
                      <IconButton
                        variant="outlined"
                        style={{
                          padding: "4px"
                        }}
                        onClick={getPaymentStatus}
                        disabled={loading}
                      ><RefreshIcon /></IconButton>
                    </Tooltip>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Grid>
                  <Grid item sm>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={paymentData.paymentStatus !== "NOT PAID" ? {
                        color: "#fff",
                        background: green[500]
                      } : {}}
                      disabled={paymentData.paymentStatus !== "NOT PAID"}
                      onClick={() => window.open(courseRegistrationUrl)}>{paymentData.paymentStatus === "NOT PAID" ? "Pay Now" : paymentData.paymentStatus}</Button>
                  </Grid>
                </>}
            </Grid>
            <Grid item container direction="column" style={{ width: "8em", marginTop: "-18px" }}>
              <Grid
                item
                container
                style={{ textDecoration: "none" }}
                direction="row"
                alignItems="center"
                onClick={() => {
                  window.localStorage.roleId == "4"
                    ? props.sendActivityDataToServer()
                    : console.log("hi");
                  handleLogout();
                  setTimeout(() => {
                    window.localStorage.siblingOpen = false;
                    window.localStorage.teacherOpen = false;
                  }, 1000);

                  // setselectedSiblingOpen(true);
                  //setTeacherSelectedOpen(true);
                  props.settabValue(0);
                }}
                xs
              >
                <Grid item>
                  <IconButton disableRipple>
                    <img
                      src={
                        theme.typography.myTheme.themeName === "Night"
                          ? logout2
                          : Logout
                      }
                      alt="logout"
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Logout</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                xs
                onClick={() => props.themeDialogfunc(true)}
                style={{ cursor: "pointer" }}
              >
                <Grid item>
                  <IconButton disableRipple>
                    <img
                      src={
                        theme.typography.myTheme.themeName === "Night"
                          ? themeicon2
                          : palette
                      }
                      alt="palette"
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Theme</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <ThemeController
        open={props.themePaletteOpen}
        setTheme={props.setTheme}
        handleClose={() => props.themeDialogfunc(false)}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        style={{ top: "4em", left: "5.2em" }}
      >
        <MenuItem
          onClick={(event) => {
            handleMenuClose(event);
            handleEditProfileOpen();
          }}
        >
          Edit Profile
        </MenuItem>
        {window.localStorage.roleId == 5 ? (
          <MenuItem
            onClick={(event) => {
              handleMenuClose(event);
              setselectedSiblingOpen(true);
            }}
          >
            Select Child
          </MenuItem>
        ) : undefined}
      </Menu>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Profile
        </DialogTitle>
        <DialogContent style={{ scrollbarWidth: "none" }}>
          <Grid
            container
            direction="row"
            spacing={3}
            style={{ marginTop: "0.3em" }}
          >
            <Grid item container direction="column" sm={5} spacing={1}>
              <Grid item container>
                <img
                  src={props.profilePic ? props.profilePic : teacherfallback}
                  style={{ height: "20em", width: "100%" }}
                  alt="profilepic"
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  sm
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleUploadDialogOpen(e)}
                >
                  <Grid item>
                    <PublishIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="secondary">
                      Edit/Upload
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  sm
                  onClick={handleDelete}
                  style={{ cursor: "pointer" }}
                >
                  <Grid item>
                    <DeleteIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="secondary">
                      Delete
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={7} spacing={2}>
              <Grid item container>
                <TextField
                  id=""
                  variant="outlined"
                  label="Name"
                  fullWidth
                  disabled
                  color="primary"
                  defaultValue={props.name}
                />
              </Grid>
              <Grid item container spacing={1}>
                <Grid item sm>
                  <TextField
                    id=""
                    variant="outlined"
                    label="Class"
                    disabled
                    color="primary"
                    value={props.standard.split(" ")[1]}
                  />
                </Grid>
                <Grid item sm>
                  <TextField
                    id=""
                    variant="outlined"
                    label="Section"
                    disabled
                    color="primary"
                    value={props.standard.split(" ")[3]}
                  />
                </Grid>
                <Grid item sm>
                  <TextField
                    id=""
                    variant="outlined"
                    label="Blood Group"
                    color="primary"
                    select
                    fullwidth
                    name="bloodGroup"
                    onChange={setEditStudent}
                    defaultValue={props.bloodGroupId}
                  >
                    {myOptions?.map((item) => (
                      <MenuItem key={item} value={item.lookupId}>
                        {item.subCategory}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item container>
                <TextField
                  id=""
                  variant="outlined"
                  label="Aim/Inspiration"
                  fullWidth
                  multiline
                  InputLabelProps={{}}
                  rows={3}
                  color="primary"
                  name="aim"
                  onChange={setEditStudent}
                  value={editStudent.aim}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            vairant="outline"
            onClick={handleClose}
            color="sceondary"
            style={{ color: theme.palette.secondary.main }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              window.localStorage.roleId == 5
                ? handleEditSubmit()
                : handleEditSubmitStudent();
            }}
            color="secondary"
            style={{ color: "white" }}
          >
            {progressStart === true ? (
              <CircularProgress color={theme.palette.secondary.light} />
            ) : (
              "Done"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={
          selectedSiblingOpen &&
          window.location.pathname !== "" &&
          window.location.pathname !== "/" &&
          window.location.pathname !== "/Login" &&
          window.location.pathname !== "/ForgotPassword" &&
          window.localStorage.roleId == 5
        }
        style={{ minWidth: "45em" }}
        aria-labelledby="sibling dialog"
      >
        <DialogTitle id="customized-dialog-title">Siblings</DialogTitle>
        <DialogContent>{siblingContent}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleProceed}
            color="secondary"
            style={{ color: "white" }}
            disabled={selectedChildInfo === ""}
          >
            {progressStart === true ? (
              <CircularProgress color={theme.palette.secondary.light} />
            ) : (
              "Proceed"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={
          teacherSelectedOpen &&
          window.location.pathname.trim() !== "" &&
          window.location.pathname !== "/" &&
          window.location.pathname !== "/Login" &&
          window.location.pathname !== "/ForgotPassword" &&
          window.localStorage.roleId == 3
        }
        style={{ minWidth: "45em" }}
        aria-labelledby="teacher dialog"
      >
        <DialogTitle id="customized-dialog-title">Class</DialogTitle>
        <DialogContent>
          <TeacherContent
            teacherType={teacherType}
            checkClass={checkClass}
            setCheckClass={setCheckClass}
            setTeacherType={setTeacherType}
            classDetails={classDetails}
            selectedClass={selectedClass}
            setSelectedClass={setSelectedClass}
            subjectTeachDetails={subjectTeachDetails}
            setSubjectTeachDetails={setSubjectTeachDetails}
            setClassDetails={setClassDetails}
            handleSujectSelect={setSelectSubjectTeacher}
            sujectForClass={sujectForClass}
            selectSubjectTeacher={selectSubjectTeacher}
            setNewClassDetails={setNewClassDetails}
            newclassDetails={newclassDetails}
            handleClassTeacherSelection={handleClassTeacherSelection}
            handleClassSelection={handleClassSelection}
            profileLoader={profileLoader}
            setProfileLoader={setProfileLoader}
          />
        </DialogContent>
        <DialogActions>
          {window.localStorage.isClassTeacher == "false" &&
            window.localStorage.isSubjectTeacher == "false" ? (
            <Button
              variant="text"
              onClick={() => setTeacherSelectedOpen(false)}
              color="secondary"
              style={{ color: "white" }}
            >
              Cancel
            </Button>
          ) : undefined}
          {window.localStorage.isClassTeacher == "true" ||
            window.localStorage.isSubjectTeacher == "true" ? (
            <Button
              variant="contained"
              onClick={handleTeacherSelectProceed}
              color="secondary"
              style={{ color: "white" }}
              disabled={
                !newclassDetails.sectionId && !selectSubjectTeacher.sectionId
              }
            >
              {progressStart === true ? (
                <CircularProgress color={theme.palette.secondary.light} />
              ) : (
                "Proceed"
              )}
            </Button>
          ) : undefined}
        </DialogActions>
      </Dialog>
      <Dialog
        open={editTeacherOpen}
        onClose={handleEditTeacherClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleEditTeacherClose}
        >
          Edit Profile
        </DialogTitle>
        <DialogContent style={{ scrollbarWidth: "none" }}>
          <Grid
            container
            direction="row"
            spacing={3}
            style={{ marginTop: "0.3em" }}
          >
            <Grid item container direction="column" sm={6} spacing={1}>
              <Grid item container>
                <img
                  src={props.profilePic}
                  style={{
                    maxHeight: "20em",
                    height: "auto",
                    width: "100%",
                  }}
                  alt="profilepic"
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  sm
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleUploadDialogOpen(e)}
                >
                  <Grid item>
                    <PublishIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="secondary">
                      Edit/Upload
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  sm
                  onClick={handleDelete}
                  style={{ cursor: "pointer" }}
                >
                  <Grid item>
                    <DeleteIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="secondary">
                      Delete
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={6} spacing={2}>
              <Grid item container>
                <TextField
                  id=""
                  variant="outlined"
                  label="Name"
                  fullWidth
                  disabled
                  color="primary"
                  defaultValue={props.name}
                />
              </Grid>
              <Grid item container spacing={1}>
                <Grid item sm>
                  <TextField
                    id=""
                    variant="outlined"
                    label="Blood Group"
                    color="primary"
                    select
                    fullWidth
                    name="bloodGroup"
                    onChange={(e) =>
                      setEditTeacher({
                        ...editTeacher,
                        bloodGroup: e.target.value,
                      })
                    }
                    defaultValue={props.bloodGroupId}
                  >
                    {myOptions?.map((item) => (
                      <MenuItem key={item} value={item.lookupId}>
                        {item.subCategory}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            vairant="outline"
            onClick={handleEditTeacherClose}
            color="secondary"
            style={{ color: theme.palette.secondary.main }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleEditTeacherSubmit()}
            color="secondary"
            style={{ color: "white" }}
          >
            {progressStart === true ? (
              <CircularProgress color={theme.palette.secondary.light} />
            ) : (
              "Done"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={6000000}
        open={uploadDialog}
        onClose={handleUploadClose}
        onSave={(event, files) => handleOnUploadSubmit(event, files)}
        showPreviews={true}
        acceptedFiles={["image/*"]}
        showFileNamesInPreview={true}
      />
      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={6000000}
        open={uploadTeacherDialog}
        onClose={handleUploadEditTeacherClose}
        onSave={(event, files) => handleOnUploadSubmit(event, files)}
        showPreviews={true}
        acceptedFiles={["image/*"]}
        showFileNamesInPreview={true}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openUploadSnack}
        autoHideDuration={6000}
        onClose={() => setUploadSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setUploadSnack(false)}
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={uploadStatus}>{uploadMessage}</Alert>
      </Snackbar>
    </React.Fragment>
  );

  return <React.Fragment>{studentInfo}</React.Fragment>;
}
