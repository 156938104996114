import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CircularProgress, Tooltip } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { url } from "./store.js";

const useStyles = makeStyles((theme) => ({
  table: {},
  rowStyle: {
    padding: "2em",
  },
  rowStyleHead: {
    padding: "2em",

    paddingBottom: "1em",
    marginLeft: "1em",
  },
  tableHeadClass: {
    "& .MuiTableCell-head": {
      color:
        theme.typography.myTheme.themeName === "Night" ? "pink" : "inherit",
    },
  },
  tableBodyClass: {
    "& .MuiTableCell-body": {
      color:
        theme.typography.myTheme.themeName === "Night" ? "pink" : "inherit",
    },
  },
}));

function createData(day, I, II, III, IV, V, VI, VII) {
  return { day, I, II, III, IV, V, VI };
}

export default function TimeTableClassTeacher() {
  const classes = useStyles();
  const theme = useTheme();
  const [myRows, setMyRows] = useState([]);
  const [timeTableRows, setTimeTableRows] = useState([]);
  const tablerows = {
    Monday: ["MONDAY"],
    Tuesday: ["TUESDAY"],
    Wednesday: ["WEDNESDAY"],
    Thursday: ["THURSDAY"],
    Friday: ["FRIDAY"],
    Saturday: ["SATURDAY"],
  };
  const [loader, setLoader] = useState(true);

  const headers =
    window.localStorage.periods == "1"
      ? [{ head: "DAY" }, { head: "I", time: "(9:00 AM-9:35 AM)" }]
      : window.localStorage.periods == "2"
      ? [
          { head: "DAY" },
          { head: "I", time: "(9:00 AM-9:35 AM)" },
          { head: "II", time: "(9:35 AM-10:10 AM)" },
        ]
      : window.localStorage.periods == "3"
      ? [
          { head: "DAY" },
          { head: "I", time: "(9:00 AM-9:35 AM)" },
          { head: "II", time: "(9:35 AM-10:10 AM)" },
          { head: "III", time: "(10:10 AM-10:30 AM)" },
        ]
      : window.localStorage.periods == "4"
      ? [
          { head: "DAY" },
          { head: "I", time: "(9:00 AM-9:35 AM)" },
          { head: "II", time: "(9:35 AM-10:10 AM)" },
          { head: "III", time: "(10:10 AM-10:30 AM)" },
          { head: "IV", time: "(10:30 AM-11:05 AM)" },
        ]
      : window.localStorage.periods == "5"
      ? [
          { head: "DAY" },
          { head: "I", time: "(9:00 AM-9:35 AM)" },
          { head: "II", time: "(9:35 AM-10:10 AM)" },
          { head: "III", time: "(10:10 AM-10:30 AM)" },
          { head: "IV", time: "(10:30 AM-11:05 AM)" },
          { head: "V", time: "(11:05 AM-11:40 AM)" },
        ]
      : window.localStorage.periods == "6"
      ? [
          { head: "DAY" },
          { head: "I", time: "(9:00 AM-9:35 AM)" },
          { head: "II", time: "(9:35 AM-10:10 AM)" },
          { head: "III", time: "(10:10 AM-10:30 AM)" },
          { head: "IV", time: "(10:30 AM-11:05 AM)" },
          { head: "V", time: "(11:05 AM-11:40 AM)" },
          { head: "VI", time: "(11:40 AM-12:15 PM)" },
        ]
      : window.localStorage.periods == "7"
      ? [
          { head: "DAY" },
          { head: "I", time: "(9:00 AM-9:35 AM)" },
          { head: "II", time: "(9:35 AM-10:10 AM)" },
          { head: "III", time: "(10:10 AM-10:30 AM)" },
          { head: "IV", time: "(10:30 AM-11:05 AM)" },
          { head: "V", time: "(11:05 AM-11:40 AM)" },
          { head: "VI", time: "(11:40 AM-12:15 PM)" },
          { head: "VII", time: "(12:15 AM-12:50 PM)" },
        ]
      : [
          { head: "DAY" },
          { head: "I", time: "(9:00 AM-9:35 AM)" },
          { head: "II", time: "(9:35 AM-10:10 AM)" },
          { head: "III", time: "(10:10 AM-10:30 AM)" },
          { head: "IV", time: "(10:30 AM-11:05 AM)" },
          { head: "V", time: "(11:05 AM-11:40 AM)" },
          { head: "VI", time: "(11:40 AM-12:15 PM)" },
          { head: "VII", time: "(12:15 AM-12:50 PM)" },
          { head: "VIII", time: "(12:15 AM-12:50 PM)" },
        ];

  const bodyObjClass = {
    requestList: [
      {
        isEncrypt: false,
        transactionId: "0000001438241244",
        busiCode: "SCHOOL_GET_TIME_TABLE_BY_SECTION_ID_DATE",
        busiParams: {
          fromDate: "",
          toDate: "",
          sectionId: window.localStorage.sectionId,
        },
      },
    ],
    pubInfo: {
      appId: "appId",
      sessionId: window.localStorage.sessionId,
      version: "21",
      timestamp: "20180314175419",
      lang: "en_US",
      userId: "1000016614",
      serviceId: "7021150585",
      circleId: "MU",
      ncsroute: "WE015",
      customerId: "1000016614",
      osType: "windows",
    },
  };

  useEffect(() => {
    setLoader(true);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(bodyObjClass),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        let myTimeTable = [];
        data.respData[0].respMsg.result.timetableList.map(
          (item, index, arr) => {
            let newTimeTable = item.timeTable.unshift(
              index == 0
                ? { subjectName: "MONDAY" }
                : index == 1
                ? { subjectName: "TUESDAY" }
                : index == 2
                ? { subjectName: "WEDNESDAY" }
                : index == 3
                ? { subjectName: "THURSDAY" }
                : index == 4
                ? { subjectName: "FRIDAY" }
                : { subjectName: "SATURDAY" }
            );
            arr[index].newPeriods = newTimeTable;
            myTimeTable.push(arr[index]);
          }
        );

        console.log(myTimeTable);
        setTimeTableRows(data.respData[0].respMsg.result.timetableList);
      })
      .catch((err) => console.error(err));

    setInterval(() => {
      setLoader(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      {loader === false ? (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead classes={{ root: classes.tableHeadClass }}>
              <TableRow>
                {headers.map((user, index) => (
                  <TableCell
                    classes={{ root: classes.rowStyleHead }}
                    key={user.head + index}
                  >
                    {user.head}
                    <br />
                    {user.head === "DAY" ? "" : ""}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: classes.tableBodyClass }}>
              {timeTableRows.map((row, index) => (
                <TableRow key={row + index} className={classes.rowStyle}>
                  {row.timeTable.map((item, index1, arr) => (
                    <Tooltip title={item.subjectName} arrow>
                      <TableCell
                      // classes={{ root: classes.rowStyle }}
                      // component={index1 === 0 ? "th" : "td"}
                      // scope={index1 === 0 ? "row" : undefined}
                      // align={index1 === 0 ? "left" : undefined}
                      >
                        {item.subjectName}
                      </TableCell>
                    </Tooltip>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </React.Fragment>
  );
}
