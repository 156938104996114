import React, { useEffect, useState } from "react";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import TabMaker from "./TabMaker";
import TableMaker from "./TableMaker";
import { groupBy } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { url } from "./store.js";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
}));
const tabheadings = window.localStorage.boardId > 6 ?
  [
    "DPP",
    "H W",
    "Assignment"
  ] : [
    "CW",
    "HW",
    "ASSIGNMENT",
    "PROJECT",
    "OBSERVATION",

    "RECORD",
  ];

const headers = [
  "Subject",
  "Chapter",
  "Date",
  "DueDate",
  "Attachment",
  "Comments",
];

const MyDataMaker = (props) => {
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_TASK_SECTION_WISE",
              busiParams: {
                active: true,
                sectionId: window.localStorage.sectionId,
                branchId: window.localStorage.branchId,
                secSubjectMappingId: 0,
                studentInstanceId: window.localStorage.studentInstanceId,
                resourceType: props.resourceType,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          let tempEvents = [];
          let row = [];

          if (data.respData[0].respMsg.result.length > 0) {
            data.respData[0].respMsg.result.map((item, index) => {
              tempEvents.push(item);
            });

            tempEvents.map((item, index) => {
              console.log(item);
              row.push([
                { title: item.subjectName, type: "text" },
                { title: item.chapterName, type: "text" },
                { title: item.date, type: "text" },
                { title: item.dueDate, type: "text" },

                {
                  title: item.taskFileName,
                  type: item.taskFileType,
                  href: item.taskAttachment,
                },
                { title: item.comment, type: "text" },
              ]);
            });
          } else {
            row.push([
              { title: "No Data Available", type: "text" },
              { title: " ", type: "text" },
              { title: " ", type: "text" },
              { title: " ", type: "text" },

              {
                title: " ",
                type: "text",
              },
              { title: " ", type: "text" },
            ]);
          }

          setRows(row);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    }
  }, []);

  return (
    <React.Fragment>
      <React.Fragment>
        {loader === true ? (
          <CircularProgress color="secondary" />
        ) : (
          <TableMaker columns={props.headers} rows={rows} />
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default function WritingMaterial() {
  const classes = useStyle();
  const [isReady, setIsReady] = useState(false);
  const [myTableData, setMyTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const myTableData1 = [];
  const writingMaterialArr = window.localStorage.boardId > 6 ?
    [
      { name: "DPP", id: 7 },
      { name: "HW", id: 2 },
      { name: "ASSIGNMENT", id: 3 },
    ] : [
      { name: "CW", id: 1 },
      { name: "HW", id: 2 },
      { name: "ASSIGNMENT", id: 3 },
      { name: "PROJECT", id: 4 },
      { name: "OBSERVATION", id: 5 },
      { name: "RECORD", id: 6 },
    ];

  useEffect(() => {
    setLoader(true);
    const getData = async () => {
      writingMaterialArr.map((item, index1) => {
        let temList = (
          <MyDataMaker headers={headers} key={index1} resourceType={item.id} />
        );
        myTableData1.push(temList);
      });
    };

    getData().catch((data) => {
      console.log("sorry something went wrong");
      let temList = (
        <React.Fragment key={"CW"}>
          <Typography variant="body1">No Data Available</Typography>
        </React.Fragment>
      );
      myTableData1.push(temList);
    });

    setMyTableData(myTableData1);
    setIsReady(true);
    setInterval(() => {
      setLoader(false);
    }, 500);
  }, []);

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TabMaker
              tabs={tabheadings}
              data={myTableData}
              subjectBased={true}
            />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
