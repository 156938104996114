import React, { useEffect, useState } from 'react'
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    MenuItem,
    Snackbar,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import { makeReqBody, url } from './store';
import TableMaker from './TableMaker';
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";


const useStyle = makeStyles(theme => ({
    subContainer: {
        ...theme.typography.subContainer,
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: "#fff",
    },
}))


const styles = (theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.common.black,
    },
    DialogHeader: {
        backgroundColor: theme.palette.common.ltgrey,
    },
    dropZone: {
        color: "black !important",
    },
    iconButton: {
        padding: '6px',
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        fontSize: "0.85rem",
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    const theme = useTheme();
    return (
        <MuiDialogTitle
            disableTypography
            {...other}
            className={classes.DialogHeader}
        >
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon style={{ color: theme.palette.common.tabFont }} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function StudentDetailSmartQBT(props) {

    const [backLoader, setBackLoader] = useState(false);
    const classes = useStyle()
    const [studentDetails, setStudentDetail] = useState({});
    const [rows, setRows] = useState([]);
    const [showExplanation, setShowExplanation] = useState(false)
    const [explanation, setExplanation] = useState("");
    const [examList, setExamList] = useState([]);
    const [selectedExamId, setSelectedExamId] = useState(0);
    const [snack, setSnack] = useState(false);
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const theme = useTheme();

    useEffect(() => {
        if (selectedExamId !== 0 || window.localStorage.roleId !== "3") {
            try {
                setBackLoader(true);
                fetch(url, makeReqBody("SCHOOL_SMART_QBT_GET_QUESTION_BY_STUDENTID", {
                    examTermId: props.examTermId,
                    sectionId: window.localStorage.sectionId,
                    studentInstanceID: props.studentInstanceID,
                    examId: window.localStorage.roleId !== "3" ? props.examId : selectedExamId,
                    subjectId: window.localStorage.teacherType === "Class" || window.localStorage.roleId == 4 || window.localStorage.roleId == 5 ? 0 : parseInt(window.localStorage.subjectId),
                })).then((response) => response.json())
                    .then((data) => {
                        setStudentDetail(data.respData[0].respMsg.result);

                        let row = [];
                        data.respData[0].respMsg.result.questionDescription.length > 0 &&
                            data.respData[0].respMsg.result.questionDescription.map((item, index) => {
                                row.push([
                                    { title: item.questionNo, type: "text" },
                                    { title: item.selectedStuOption, type: "text" },
                                    { title: item.identifierId === 17 ? item.numericAns : item.correctAnswer, type: "correctAns" },
                                    { title: item.marksObtained, type: "text" },
                                    { title: item.subjectName, type: "text" },
                                    { type: "explanation", data: item.explaination },
                                    { imgTitle: item.statusOfQuestion, type: "text", type: "answerStatus" },
                                ]);
                            })
                        setRows(row);
                        setTimeout(() => {
                            setBackLoader(false);
                        }, 1000);
                        if (data.respData[0].respMsg !== null) {
                            setMessage(data.respData[0].respMsg.message);
                            setStatus("success");
                            setSnack(true)
                        }
                    })
                    .catch((data) => {
                        console.error(data);
                        setTimeout(() => {
                            setBackLoader(false);
                        }, 1000);
                        setRows([]);
                        setStudentDetail({});
                        setMessage("Data not found.");
                            setStatus("error");
                            setSnack(true)
                    });
            } catch (error) {
                console.error(error);
                setTimeout(() => {
                    setBackLoader(false);
                }, 1000);
            }
        }
    }, [selectedExamId, props.examTermId, props.render]);


    useEffect(() => {
        setBackLoader(true);
        try {
            fetch(
                url,
                makeReqBody("SCHOOL_SMART_QBT_GET_EXAM_BY_EXAMTERM", {
                    examTermId: props.examTermId,
                    subjectId: window.localStorage.teacherType == "Subject" ? window.localStorage.subjectId : 0,
                })).then((response) => response.json())
                .then((data) => {
                    setExamList(data.respData[0].respMsg.result)
                    setBackLoader(false);
                })
                .catch((data) => {
                    console.error(data);
                    setBackLoader(false);
                });
        } catch (error) {
            console.error(error);
            setBackLoader(false);
        }
    }, [props.examTermId, props.render]);



    const headers = [
        "Question No.",
        "Selected Option",
        "Correct Answer",
        "Marks",
        "Subject",
        "Explanation",
        "status"
    ]

    const handleShowExplain = (data) => {
        setExplanation(data);
        setShowExplanation(true);
    }


    const { marksObtained, timeTaken, attemptQuestion, totalQuestion, totalMarks, correctQuesNo, unattemptedNo, wrongQuesNo, partialCorrect, studentName } = studentDetails;
    return (
        <>
            <Grid
                item
                container
                direction="column"
                className={classes.subContainer}
                spacing={2} alignItems="center"
            >
                {window.localStorage.roleId === "3" &&
                    <Grid item container>
                        <Grid item sm={3}>
                            <TextField
                                id="ExamList"
                                label="Exam List*"
                                select
                                name="examId"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={selectedExamId}
                                onChange={(e) => {
                                    setSelectedExamId(e.target.value);
                                }}
                            >
                                {examList && examList.map((item, index) => (
                                    <MenuItem key={index} value={item.examId}>
                                        {item.smartQbtExamName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>}
                {(selectedExamId !== 0 || window.localStorage.roleId !== "3") &&
                    <>
                        <Grid item container>
                            {window.localStorage.roleId == 3 && (
                                <Grid item sm={3}>
                                    <Typography variant='body1'>Student Name : <strong>{studentName}</strong></Typography>
                                </Grid>
                            )}
                            <Grid item sm={2}>
                                <Typography variant='body1'>Overall Score : <strong>{marksObtained}/{totalMarks}</strong></Typography>
                            </Grid>

                            <Grid item sm={2}>
                                <Typography variant='body1'>Time Taken : <strong>{timeTaken}</strong></Typography>
                            </Grid>
                            <Grid item sm={2} style={{ marginBottom: "0.1em" }}>
                                <Typography variant='body1'>Attempted : <strong>{attemptQuestion}/{totalQuestion}</strong></Typography>
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Typography variant='h6' style={{ fontWeight: "bold" }}>ACCURACY</Typography>
                        </Grid>

                        <Grid container item>
                            <Grid item sm={2}>
                                <Typography variant='body1'>Correct : <strong>{correctQuesNo}</strong></Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography variant='body1'>Partial Correct : <strong>{partialCorrect}</strong></Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography variant='body1'>Wrong : <strong>{wrongQuesNo}</strong></Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography variant='body1'>Unattemped : <strong>{unattemptedNo}</strong></Typography>
                            </Grid>
                        </Grid>

                        <Grid item container>
                            {rows.length !== 0 && backLoader === false ? (
                                <TableMaker
                                    columns={headers}
                                    rows={rows}
                                    handleShowExplain={handleShowExplain}
                                />
                            ) : (
                                <CircularProgress color="secondary" />
                            )}
                        </Grid>
                    </>}

            </Grid>

            <Dialog
                open={showExplanation}
                onClose={() => setShowExplanation(false)}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="form-dialog-title">Explanation</DialogTitle>
                <DialogContent style={{ scrollbarWidth: "none" }}>
                    <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(explanation) }}></div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowExplanation(false)}
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", background: '#d95350' }}
                    > Close</Button>
                </DialogActions>
            </Dialog>

            <Backdrop className={classes.backdrop} open={backLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={snack}
                autoHideDuration={9000}
                onClose={() => setSnack(false)}
                action={<>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setSnack(false)}
                    >
                        <CloseIcon
                            style={{ color: theme.palette.common.tabFont }}
                            fontSize="small"
                        />
                    </IconButton>
                </>}
            >
                <Alert severity={status}>{message}</Alert>
            </Snackbar>
        </>
    )
}
