import React, { useState, useEffect } from "react";
import { Typography, Grid, IconButton } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import DetailCard from "./DetailCard.js";
import TimeTable from "./TimeTable.js";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ListItemText from "@material-ui/core/ListItemText";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { url } from "./store.js";

const localizer = momentLocalizer(moment);

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.common.white,
    height: "60vh",
  },
  subContainer: {
    ...theme.typography.subContainer,
    paddingTop: 0,
  },
  tabStyling: {
    backgroundColor: theme.palette.common.ltgrey,
    width: "100%",
    padding: 0,
  },
  inTabStyling: {
    flexDirection: "row",
  },
  indicatorH: {
    height: "2.5px",
  },
  listStyle: {
    width: "100%",
    height: "100%",
    marginTop: "2.6em",
    border: `1px solid #C8CACC`,
    msOverflowStyle: "none" /* IE 11 */,
    scrollbarWidth: "none",
    overflow: "scroll",
    padding: "1.5em",
  },
  ListItemText: {
    color: "white",
  },
  dividerCus: {
    "& .MuiDivider-root": {
      //backgroundColor: theme.palette.common.tabFont
      backgroundColor: "white",
    },
  },
  myCalendar: {
    "& .rbc-header": {
      backgroundColor: theme.palette.common.calHeader,
      textAlign: "left",
      textTransform: "uppercase",
      padding: "28px",
      paddingLeft: "10px",
      border: "none",
    },

    "& .rbc-month-view": {
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
    },
    "& .rbc-off-range-bg": {
      backgroundColor: "transparent",
    },
    "& .rbc-today": {
      backgroundColor: theme.palette.common.calTodayCol,
    },
    "& .rbc-date-cell": {
      textAlign: "left",
      marginTop: "15px",
      paddingLeft: "10px",
      marginBottom: "5px",
    },

    "& .rbc-day-bg": {
      borderLeft: "none",
    },

    "& .rbc-show-more": {
      textDecoration: "none",
    },

    "& .rbc-toolbar-label": {
      fontWeight: "bold",
      textTransform: "uppercase",
      padding: 0,
      width: "10em",
    },
  },
}));

export default function GeneralUi(props) {
  const classes = useStyle();
  const theme = useTheme();
  const [events, setEvents] = useState([]);
  const [subjectTeacher, setSubjectTeachers] = useState([]);
  const [otherIncharges, setOtherInCharges] = useState([]);
  const [authorityDetails, setAuthorityDetails] = useState([]);
  const bodyObjCalendar = {
    requestList: [
      {
        isEncrypt: false,
        transactionId: "0000001438241244",
        busiCode: "SCHOOL_GET_BRANCH_EVENT",
        busiParams: { id: window.localStorage.branchId },
      },
    ],
    pubInfo: {
      appId: "appId",
      sessionId: window.localStorage.sessionId,
      version: "21",
      timestamp: "20180314175419",
      lang: "en_US",
      userId: "1000016614",
      serviceId: "7021150585",
      circleId: "MU",
      ncsroute: "WE015",
      customerId: "1000016614",
      osType: "windows",
    },
  };

  const myteacherDetails = {
    requestList: [
      {
        isEncrypt: false,
        transactionId: "0000001438241244",
        busiCode: "SCHOOL_GET_TEACHER_SUB_DETAIL",
        busiParams: {
          studentInstanceId: window.localStorage.studentInstanceId,
          branchId: window.localStorage.branchId,
          sectionId: window.localStorage.sectionId,
        },
      },
    ],
    pubInfo: {
      appId: "appId",
      sessionId: window.localStorage.sessionId,
      version: "21",
      timestamp: "20180314175419",
      lang: "en_US",
      userId: "1000016614",
      serviceId: "7021150585",
      circleId: "MU",
      ncsroute: "WE015",
      customerId: "1000016614",
      osType: "windows",
    },
  };

  useEffect(() => {
    if (window.location.pathname === "/LandingPageStudent/TeacherDetails") {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(myteacherDetails),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(
            JSON.stringify(data.respData[0].respMsg.result.stuSubTeachers)
          );
          setSubjectTeachers(data.respData[0].respMsg.result.stuSubTeachers);
          setOtherInCharges(data.respData[0].respMsg.result.inChargeDetails);
          setAuthorityDetails(data.respData[0].respMsg.result.schoolAuthority);
        })
        .catch((data) => {
          console.log("sorry something went wrong");

          // setError(true);
        });
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/LandingPageStudent/Calendar") {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(bodyObjCalendar),
      })
        .then((response) => response.json())
        .then((data) => {
          let tempEvents = [];

          data.respData[0].respMsg.result.map((item, index) => {
            tempEvents.push({
              start: moment(item.fromDate, "DD-MM-YYYY HH:mm:ss")
                .format("YYYY-MM-DD")
                .toString(),
              end: moment(item.toDate, "DD-MM-YYYY HH:mm:ss")
                .format("YYYY-MM-DD")
                .toString(),
              title: item.description,
              color: item.color,
            });
          });
          setEvents(tempEvents);
        })
        .catch((data) => {
          console.log("sorry something went wrong");

          // setError(true);
        });
    }
  }, []);

  const CustomToolBar = (toolbar) => (
    <React.Fragment>
      {console.log(toolbar)}
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item>
          <IconButton disableRipple onClick={() => toolbar.onNavigate("PREV")}>
            <ArrowLeftIcon size="large" color="secondary" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {toolbar.label}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton disableRipple onClick={() => toolbar.onNavigate("NEXT")}>
            <ArrowRightIcon color="secondary" />
          </IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const eventStyleGetter = (event, start, end, isSelected) => {
    console.log(event);
    var backgroundColor = "#" + event.color;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.7,
      color: "black",
      fontWeight: "bold",
      fontSize: "0.6rem",
      border: "0px",
      display: "block",
      marginBottom: "1px",
    };
    return {
      style: style,
    };
  };

  const teacherDetails = (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={5}
        className={classes.subContainer}
      >
        <Grid item>
          <Typography variant="h6">SUBJECT CLASS TEACHERS</Typography>
        </Grid>
        <Grid item>
          <Grid
            item
            container
            direction="row"
            justify={"flex-start"}
            spacing={6}
          >
            {subjectTeacher.map((user, index) => (
              <Grid item key={user + index}>
                <DetailCard
                  type="subjectteachers"
                  user={user}
                  name={`${user.firstName === null ? "" : user.firstName} ${
                    user.middleName === null ? "" : user.middleName
                  } ${user.lastName === null ? "" : user.lastName}`}
                  img={user.profilePicture}
                  icon={user.icon}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h6">SCHOOL AUTHORITY DETAILS</Typography>
        </Grid>
        <Grid item>
          <Grid
            item
            container
            direction="row"
            justify={"flex-start"}
            spacing={6}
          >
            {authorityDetails.map((user, index) => (
              <Grid item key={user + index}>
                <DetailCard
                  type="School Authority"
                  user={user}
                  name={`${user.firstName === null ? "" : user.firstName} ${
                    user.middleName === null ? "" : user.middleName
                  } ${user.lastName === null ? "" : user.lastName}`}
                  img={user.profilePicture}
                  icon={user.icon}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h6">OTHER IN-CHARGE DETAILS</Typography>
        </Grid>
        <Grid item>
          <Grid
            item
            container
            direction="row"
            justify={"flex-start"}
            spacing={6}
          >
            {otherIncharges.map((user, index) => (
              <Grid item key={user + index}>
                <DetailCard
                  type="Other In Charge Details"
                  user={user}
                  name={`${user.firstName === null ? "" : user.firstName} ${
                    user.middleName === null ? "" : user.middleName
                  } ${user.lastName === null ? "" : user.lastName}`}
                  img={user.profilePicture}
                  icon={user.icon}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  let components = {
    toolbar: CustomToolBar,
  };

  const timeTable = (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={5}
        className={classes.subContainer}
      >
        <Grid item>
          <TimeTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const calendar = (
    <React.Fragment>
      <Grid
        item
        container
        direction="row"
        spacing={4}
        className={classes.subContainer}
      >
        <Grid item style={{ paddingTop: "0" }}>
          <Calendar
            popup={true}
            localizer={localizer}
            defaultDate={new Date()}
            views={["month"]}
            className={classes.myCalendar}
            events={events}
            components={components}
            style={{ height: "42em", width: "62vw" }}
            eventPropGetter={eventStyleGetter}
          />
        </Grid>
        <Grid
          item
          style={{
            width: "22vw",
            height: "38.8em",
            marginTop: "0.9em",
            padding: 0,
          }}
        >
          <List className={classes.listStyle}>
            {events.map((event, index) => (
              <React.Fragment>
                <ListItem style={{ marginTop: "1.5em", marginBottom: "0.5em" }}>
                  <Grid container direction="column">
                    <Grid item>
                      <ListItemText
                        disableTypography
                        style={{ color: theme.palette.common.tabFont }}
                      >
                        {moment(event.start, "YYYY-MM-DD")
                          .toDate()
                          .toLocaleString() ===
                        moment(event.end, "YYYY-MM-DD")
                          .toDate()
                          .toLocaleString()
                          ? moment(event.start, "YYYY-MM-DD")
                              .toDate()
                              .toString()
                              .slice(0, 15)
                          : `${moment(event.start, "YYYY-MM-DD")
                              .toDate()
                              .toString()
                              .slice(0, 15)} To ${moment(
                              event.end,
                              "YYYY-MM-DD"
                            )
                              .toString()
                              .slice(0, 15)}`}
                      </ListItemText>
                    </Grid>
                    <Grid
                      item
                      style={{
                        padding: "0.2em",

                        backgroundColor: `#${event.color}`,
                        opacity: "0.7",
                      }}
                    >
                      <Typography align="center" variant="body1">
                        {event.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider
                  className={classes.dividerCus}
                  style={{ backgroundColor: theme.palette.common.tabFont }}
                />
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {props.part === "teacherDetails" ? (
        teacherDetails
      ) : props.part === "timeTable" ? (
        timeTable
      ) : props.part === "calendar" ? (
        calendar
      ) : (
        <Typography variant="h2" color="secondary">
          Error Wrong Path
        </Typography>
      )}
    </React.Fragment>
  );
}
