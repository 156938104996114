import React, { useState } from "react";
import { Paper, Grid, TextField, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import back2 from "../assets/back2.jpg";
import IconButton from "@material-ui/core/IconButton";
import component from "../assets/Image1.png";
import ios from "../assets/Image2.png";
import logo from "../assets/trunkie.png";
import CircularProgress from "@material-ui/core/CircularProgress";
// import AntSwitch from './antswitch';
import { Link, useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import loginicon from "../assets/loginicon.svg";
import { url } from "../Ui/store.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyle = makeStyles((theme) => ({
  formControl: {},

  mainContainer: {
    width: "100%",
    height: "110vh",
    backgroundImage: ` url(${back2}) `,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: theme.spacing.unit,
  },

  paperLogin: {
    marginTop: "auto",
    marginBottom: "auto",
    width: "27em",
    padding: 0,
  },

  downloadBtn: {
    width: "6.5em",
    height: "2em",
  },

  textPrimaryEdit: {
    color: theme.palette.common.blue,
    fontSize: 12,
  },
  submitBtn: {
    backgroundColor: " #03308F",
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: " #4c84fb",
    },
  },
  switchBtn: {
    color: theme.palette.common.blue,
  },
  logo: {
    width: "100%m",
    height: "4em",
  },
  label: {
    color: theme.palette.common.grey,
  },
  inputIcons: {
    height: "1em",
    width: "1em",
  },
  cancel: {
    fontSize: "0.65rem",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export default function LoginTab(props) {
  const classes = useStyle();
  const theme = useTheme();
  const [logStart, setLogStart] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [requestBody, setRequestBody] = React.useState({
    username: "",
  });

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setRequestBody({ ...requestBody, username: inputValue });
  };

  const handleWrongLogin = () => {
    setLogStart(false);
    setOpen(true);

    history.push(`/ForgotPassword`);
  };

  const handleForgotPassSucc = () => {
    setLogStart(false);
    history.push(`/Login`);
    props.setOpenForget(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const login = async () => {
    setLogStart(true);

    const DATA = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "UAM_FORGOT_PSWRD",
            busiParams: requestBody,
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "android",
        },
      }),
    });
    const RESPONSE = await DATA.json();

    RESPONSE.respData[0].respMsg.status !== "Failure"
      ? await handleForgotPassSucc()
      : await handleWrongLogin();
  };

  return (
    <React.Fragment>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        className={classes.mainContainer}
      >
        <Grid
          item
          container
          direction="column"
          justify="center"
          className={classes.paperLogin}
        >
          <Paper
            style={{ marginTop: "auto", marginBottom: "auto", padding: "2em" }}
          >
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justify="center"
              spacing={1}
            >
              <Grid item>
                <img alt=" " src={logo} className={classes.logo}></img>
              </Grid>
              <Grid item container direction="column">
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  spacing={2}
                  style={{ marginBottom: "1em" }}
                >
                  <Grid item>
                    <Typography
                      align="center"
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                      variant="h5"
                    >
                      FORGOT PASSWORD ?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      align="center"
                      className={classes.label}
                      style={{ color: theme.palette.common.subtitleGrey }}
                      variant="subtitle1"
                    >
                      Enter your Login ID to receive a password on your
                      registered mobile number and email address.
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography className={classes.label} variant="subtitle1">
                    Login ID
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    className={classes.formControl}
                    id="input-with-icon-textfield"
                    name="username"
                    value={requestBody.username}
                    placeholder="Enter your Login ID"
                    variant="outlined"
                    size="small"
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={loginicon}
                            className={classes.inputIcons}
                            alt="loginicon"
                          />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item container direction="column" spacing={1}>
                <Grid item>
                  <Button
                    disabled={requestBody.username === "" ? true : false}
                    className={classes.submitBtn}
                    variant="contained"
                    component={Link}
                    color="default"
                    fullWidth
                    onClick={login}
                  >
                    {logStart ? <CircularProgress /> : `Submit`}
                  </Button>
                </Grid>
                <Grid item style={{ marginRight: "auto", marginLeft: "auto" }}>
                  <Button
                    variant="text"
                    component={Link}
                    to="/Login"
                    size="small"
                    style={{ color: "#002B93" }}
                    classes={{ text: classes.cancel }}
                    disableRipple
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            direction="row"
            spacing={1}
            style={{ paddingTop: "1em", paddingBottom: "1em", color: "white" }}
          >
            <Grid item>
              <Typography
                variant="caption"
                style={{ color: "white" }}
              >{`Powered By `}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">Projectheen</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" justify="space-evenly">
            <Grid item>
              <IconButton disableRipple>
                <img
                  alt=""
                  src={component}
                  className={classes.downloadBtn}
                ></img>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton disableRipple>
                <img alt="" src={ios} className={classes.downloadBtn}></img>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="error">Login Id Not Found</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
