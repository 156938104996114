import React, { useEffect, useState } from "react";
import { Grid, Link, Typography, Backdrop } from "@material-ui/core";
import TabMaker from "./TabMaker";
import TableMaker from "./TableMaker";
import {
  AppBar,
  Dialog,
  Toolbar,
  IconButton,
  Switch,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  CircularProgress, Tooltip
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import pdf from "../assets/pdficon.jpg";
import { url, urlmultipart } from "./store.js";
import DisplayPdf from "./DisplayPdf";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },

  toolBar: {
    ...theme.mixins.toolbar,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },

  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.common.black,
  },
  switchButton: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.light,
    },

    "& .MuiSwitch-colorSecondary": {},
  },
  pdfContainer: {
    marginTop: "none",
  },

  pdfContainer2: {
    width: "200em",
  },

  pdfScroll: {
    overflowY: "auto",
    
    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
    "& .MuiDialogContent-root": {
      minHeight: '12em',        
    }
  },

  iconButton:{
    padding: '6px',
},
customTooltip: {
  // I used the rgba color for the standard "secondary" color
  fontSize: "0.85rem",
},
}));
const tabheadings = ["Blueprint, Question Paper & Answer Key", "Syllabus"];

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MySyllabus = (props) => {
  const classes = useTheme();
  const [myRow, setMyRow] = useState([]);
  const [loader, setLoader] = useState(true);

  const headers = ["Name", "Description", "Attachment"];

  useEffect(() => {
    setLoader(true);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_GET_ALL_SYLLABUS_BY_SECID",
            busiParams: {
              sectionId: window.localStorage.sectionId,
              status: false,
              userId: window.localStorage.userId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let row = [];
        let tempSyllabus = [];
        console.log(data);
        data.respData[0].respMsg.results.length > 0
          ? data.respData[0].respMsg.results.map((item, index) => {
              row.push([
                { title: item.name, type: "text" },
                { title: item.description, type: "text" },

                {
                  title: item.fileName,
                  type: item.fileType,
                  href: item.attachment,
                },
              ]);
            })
          : row.push([
              { title: "No Data Available", type: "text" },
              { title: " ", type: "text" },
              { title: " ", type: "text" },
            ]);

        setMyRow(row);
      })
      .catch((data) => {
        console.log("sorry something went wrong");
        console.log(data);
        // setError(true);
      });
    setInterval(() => {
      setLoader(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      <React.Fragment>
        {loader === false ? (
          <TableMaker columns={headers} rows={myRow} />
        ) : (
          <CircularProgress color="secondary" />
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

const MyBluePrint = (props) => {
  const classes = useTheme();
  const [myRow, setMyRow] = useState([]);
  const [loader, setLoader] = useState(true);

  const bodyObjBluePrint = {
    requestList: [
      {
        isEncrypt: false,
        transactionId: "0000001438241244",
        busiCode: "SCHOOL_GET_RESOURCE_BY_BRANCH_ID_RESOURCE_TYPE",
        busiParams: {
          admin: false,
          branchId: window.localStorage.branchId,
          chapterId: 0,
          resourceType: 39,
          secSubjectMappingId: 0,
          sectionId: window.localStorage.sectionId,
          subject: 0,
        },
      },
    ],
    pubInfo: {
      appId: "appId",
      sessionId: window.localStorage.sessionId,
      version: "21",
      timestamp: "20180314175419",
      lang: "en_US",
      userId: "1000016614",
      serviceId: "7021150585",
      circleId: "MU",
      ncsroute: "WE015",
      customerId: "1000016614",
      osType: "windows",
    },
  };

  const headers =
    window.localStorage.roleId == 3
      ? window.localStorage.teacherType == "Class"
        ? ["Name", "Description", "Attachment"]
        : ["Name", "Description", "Attachment", "Action"]
      : ["Name", "Description", "Attachment"];

  useEffect(() => {
    setLoader(true);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(bodyObjBluePrint),
    })
      .then((response) => response.json())
      .then((data) => {
        let row = [];
        let tempSyllabus = [];
        console.log(data);
        data.respData[0].respMsg.result.length > 0
          ? data.respData[0].respMsg.result.map((item, index) => {
              window.localStorage.roleId == 3
                ? window.localStorage.teacherType == "Class"
                  ? row.push([
                      { title: item.name, type: "text" },
                      { title: item.description, type: "text" },

                      {
                        title: item.fileName,
                        type: item.fileType,
                        href: item.attachment,
                      },
                    ])
                  : row.push([
                      { title: item.name, type: "text" },
                      { title: item.description, type: "text" },

                      {
                        title: item.fileName,
                        type: item.fileType,
                        href: item.attachment,
                      },
                      { title: "", type: "Delete", fullDetails: item },
                    ])
                : row.push([
                    { title: item.name, type: "text" },
                    { title: item.description, type: "text" },

                    {
                      title: item.fileName,
                      type: item.fileType,
                      href: item.attachment,
                    },
                  ]);
            })
          : row.push([
              { title: "No Data Available", type: "text" },
              { title: " ", type: "text" },
              { title: " ", type: "text" },
            ]);

        setMyRow(row);
      })
      .catch((data) => {
        console.log("sorry something went wrong");
        console.log(data);
        // setError(true);
      });
    setInterval(() => {
      setLoader(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      {window.localStorage.teacherType == "Subject" ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={props.handleOpenUploadDialog}
          style={{ color: "white", marginBottom: "0.5em" }}
        >
          Upload Material
        </Button>
      ) : undefined}
      <React.Fragment>
        {loader === false ? (
          <TableMaker
            columns={headers}
            rows={myRow}
            materialAdded={props.materialAdded}
            setMaterialAdded={props.setMaterialAdded}
          />
        ) : (
          <CircularProgress color="secondary" />
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default function ExamMaterial() {
  const classes = useStyle();
  const theme = useTheme();
  const [syllabus, setSyllabus] = useState([]);
  const [bluePrint, setBluePrint] = useState([]);
  const [pdfOpen, setPdfOpen] = React.useState(false);
  const [displayFullscreen, setDisplayFullscreen] = React.useState(false);
  const [hrefData, setHrefData] = React.useState();
  const [checked, setChecked] = React.useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [materialAdded, setMaterialAdded] = useState(false);
  const [dropZone, setDropZone] = useState(false);
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [chapter, setChapter] = useState([]);
  const [section, setSection] = useState([]);
  const [blueprintUpload, setBlueprintUpload] = useState({
    name: "",
    description: "",
  });
  const [sectionSelected, setSectionSelected] = useState({});
  const [myAttachment, setMyAttachment] = useState({
    attachment: "",
    fileName: "",
    fileType: "",
  });
  const [loader, setLoader] = useState(true);
  const [myLoader, setMyLoader] = useState(false);
  const [backLoader, setBackLoader] = useState(false);

  const handleOpenUploadDialog = async () => {
    setBlueprintUpload({});

    setUploadDialog(true);
  };

  const handleDropZone = () => {
    setDropZone(true);
  };

  const handleSaveMaterial = async (files) => {
    setBackLoader(true);
    var mydata = new FormData();
    mydata.append("file", files[0]);
    const options = {
      headers: {
        Accept: "application/json",

        type: "formData",
      },
      method: "POST",
      body: mydata,
    };
    delete options.headers["Content-Type"];
    await fetch(urlmultipart, options)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setMyAttachment({
          ...myAttachment,
          attachment: data.fileURL,
          fileName: data.fileName,
          fileType: data.fileType,
          fileId: data.bucketId,
        });
        setMaterialName(data.fileName);
        setDropZone(false);
        setBackLoader(false);
      })
      .catch((data) => {
        console.error(data);
        setDropZone(false);
        setBackLoader(false);
      });
  };

  const handleDropZoneClose = () => {
    setDropZone(false);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
  };

  const handleUploadSubmit = () => {
    setMyLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_ADD_RESOURCE",
              busiParams: {
                attachment: myAttachment.attachment,
                branchId: window.localStorage.branchId,
                chapterId: 0,
                chapterSectionId: 0,
                description: blueprintUpload.description,
                dueDate: "",
                multipartReq: 1,
                fileName: myAttachment.fileName,
                fileType: myAttachment.fileType,
                fileId: myAttachment.fileId,
                loginUserId: window.localStorage.userId,
                name: blueprintUpload.name,
                resourceType: 39,
                secSubSylMappingId: 0,
                sectionId: window.localStorage.sectionId,
                subjectId: 0,
                target: "internal",
                url: "string",
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);

          setMyLoader(false);
          setMaterialName("");
          setMyAttachment({ ...myAttachment, attachment: "" });
          setMaterialAdded(!materialAdded);
          setUploadDialog(false);
        })
        .catch((data) => {
          console.error(data);
          setUploadDialog(false);
        });
    } catch (error) {
      console.log(error);
      setUploadDialog(false);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    setLoader(true);

    setInterval(() => {
      setLoader(false);
    }, 500);
  }, [materialAdded]);

  const blueprint = (
    <MyBluePrint
      handleOpenUploadDialog={handleOpenUploadDialog}
      materialAdded={materialAdded}
      setMaterialAdded={setMaterialAdded}
    />
  );

  const Lists = <MySyllabus />;

  const handlepdfClose = (event) => {
    setPdfOpen(false);
    window.localStorage.removeItem("idleDisable");
  };

  const toggleView = (event) => {
    setDisplayFullscreen(!displayFullscreen);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const myData = [blueprint, Lists];

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TabMaker tabs={tabheadings} data={[blueprint, Lists]} />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
      <Dialog
        fullScreen={displayFullscreen}
        open={pdfOpen}
        onClose={handlepdfClose}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlepdfClose}
              aria-label="close"
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ color: theme.palette.common.tabFont }}
            >
              PDF Viewer
            </Typography>
            <Switch
              checked={checked}
              onChange={toggleChecked}
              onClick={toggleView}
              color="primary"
            />
            <Typography style={{ color: theme.palette.common.tabFont }}>
              Toggle View
            </Typography>
            <Tooltip
            title="Download Pdf"
            classes={{
              tooltip: classes.customTooltip,
            }}>
              <IconButton className={classes.iconButton} onClick={()=> window.open(hrefData)}>
                <GetAppOutlinedIcon color="primary"/>
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <Grid container direction="column" alignItems="center" className={`${classes.pdfScroll} customPdfScroll`}>
          <Grid item>
            <DisplayPdf data={hrefData} displayFullscreen={displayFullscreen} />
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={uploadDialog}
        onClose={handleCloseUploadDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseUploadDialog}
        >
          Upload Material
        </DialogTitle>
        <DialogContent
          style={{ minWidth: "40em", maxWidth: "40em", scrollbarWidth: "none" }}
        >
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ maxWidth: "40em" }}
          >
            <Grid item style={{ maxWidth: "40em" }}>
              <TextField
                variant="outlined"
                fullWidth
                value={blueprintUpload.name}
                label="Name"
                onChange={(e) =>
                  setBlueprintUpload({
                    ...blueprintUpload,
                    name: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item style={{ maxWidth: "40em" }}>
              <TextField
                variant="outlined"
                fullWidth
                value={blueprintUpload.description}
                label="Description"
                onChange={(e) =>
                  setBlueprintUpload({
                    ...blueprintUpload,
                    description: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item container spacing={2} style={{ maxWidth: "40em" }}>
              <Grid item onClick={handleDropZone}>
                <Typography
                  variant="h6"
                  color="secondary"
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                >
                  Upload
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  style={{ color: "blue" }}
                >
                  {materialName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCloseUploadDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleUploadSubmit}
            disabled={
              myAttachment.attachment === "" ||
              myAttachment.attachment === " " ||
              myLoader === true
            }
          >
            {myLoader === false ? (
              "Upload"
            ) : (
              <CircularProgress color={theme.palette.secondary.light} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={6000000}
        open={dropZone}
        acceptedFiles={["image/*", "application/pdf"]}
        onClose={handleDropZoneClose}
        dialogProps={{ className: classes.myDropZone }}
        onSave={(files) => handleSaveMaterial(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        filesLimit={1}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>

      <Backdrop className={classes.backdrop} open={backLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}
