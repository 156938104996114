import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import moment from "moment";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Tabs, Tab, Slide, Dialog, Icon, IconButton, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import { useRouteMatch, useHistory } from "react-router-dom";
import teacherplaceholder from "../assets/teacherplaceholder.png";
import Grid from "@material-ui/core/Grid";
import is from "is_js";
import fallbackschool from "../assets/fallbackImage.png";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import StudentInfoPaper from "./StudentInfoPaper.js";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import sidebarlogo from "../assets/trunkie_logo_sidebar.svg";
import sidebarlogoNight from "../assets/trunkie_logo_sidebar_night.jpeg";
import academicLogo from "../assets/Trunkie-Academy-Logo.png";
import academicLogoNight from "../assets/Trunkie-Academy-Logo-Night.png";
import schoolLogo from "../assets/schoolLogo.png";
import MenuCards from "./MenuCards.js";
import goldennotes from "../assets/Resources/goldennotes.jpg";
import notifications from "../assets/HomeMenu/notifications.jpg";
import connectzone from "../assets/HomeMenu/connectzone.jpg";
import teacherdetails from "../assets/HomeMenu/teacherDetails.jpg";
import timetable from "../assets/HomeMenu/timetable.jpg";
import Cw from "../assets/ToDo/Cw.jpg";
import Hw from "../assets/ToDo/Hw.jpg";
import subjectrecordings from "../assets/Resources/subjectrecordings.jpg";
import textbooklinks from "../assets/Resources/textbooklinks.jpg";
import calendar from "../assets/HomeMenu/calender.jpg";
import writingmaterial from "../assets/Resources/writingmaterial.jpg";
import exammaterial from "../assets/Resources/exammaterial.jpg";
import exam from "../assets/UploadSection/exam.jpg";
import examanalysis from "../assets/ExamMode/examanalysis.jpg";
import smartExam from "../assets/ExamMode/smartExam.jpg";
import deatailsAnalysis from "../assets/ExamMode/deatailsAnalysis.jpg"
import discussionforum from "../assets/StudentGroup/discussionforum.jpg";
import postquery from "../assets/StudentGroup/postquery.jpg";
import record from "../assets/ToDo/record.jpg";
import assignment from "../assets/ToDo/assignment.jpg";
import project from "../assets/ToDo/project.jpg";
import observation from "../assets/ToDo/observation.jpg";
import applyleave from "../assets/Manage/applyleave.jpg";
import writing from "../assets/UploadSection/writing.jpg";
import paper from "../assets/paper-1100254/disabledpaper.png";
import comprehensiveguide from "../assets/Resources/comprehensive.jpg";
import subjectmanager from "../assets/Manage/subjectmanager.jpg";
import attendance from "../assets/Manage/attendance.jpg";
import vigilance from "../assets/HomeMenu/vigilance.jpg";
import studentDetails from "../assets/studentImg.jpeg";
import messageteacher from "../assets/HomeMenu/messageteacher.jpg";
import creategroup from "../assets/StudentGroup/creategroup.png";
import cloneDeep from 'lodash/cloneDeep';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { ThemeEnergy, ThemeDay, ThemStaticPage, ThemeNight } from "./Theme";

import {
  url as myUrl,
  MenuItemsStudent,
  MenuItemsParents,
  MenuItemsTeachers,
  TRACKING_ID,
} from "./store.js";
import FavouritesDialog from "./FavouritesDialog";
import SessionManagement from "../../idleTimer";
import { withStyles } from "@material-ui/styles";
import ReactGA from "react-ga";
import { slice } from "lodash";



const drawerWidth = "12em";
const headerHeight = "9em";
const useStyle = makeStyles((theme) => ({
  AppBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "transparent",
    height: headerHeight,
    width: "100vw",
    marginLeft: "2em",
  },
  AppBar1: {
    position: "sticky",

    zIndex: theme.zIndex.drawer + 2,
    background: theme.palette.common.whiteOpaque2,
    marginTop: is.ie() || is.edge() ? "4em" : headerHeight,
  },
  AppBar2: {
    position: "sticky",

    zIndex: theme.zIndex.drawer + 2,
    background: theme.palette.common.whiteOpaque2,
    marginTop: is.ie() || is.edge() ? "4em" : headerHeight,
  },
  drawerContainer: {
    position: "fixed",
    width: drawerWidth,
    // top: `12.7em`,
    top: "170px",
    backgroundColor: "transparent",
    border: "none",
    minHeight: "calc(100vh - 170px)",
    height: "calc(100vh - 170px)",
    maxHeight: "calc(100vh - 170px)",
    color: theme.palette.common.black,
    msOverflowStyle: "none" /* IE 11 */,
    scrollbarWidth: "none",
  },
  drawerContainer1: {
    position: "fixed",
    width: drawerWidth,
    // top: `12.7em`,
    top: "40px",
    backgroundColor: "transparent",
    border: "none",
    minHeight: "calc(100vh - 40px)",
    height: "calc(100vh - 40px)",
    maxHeight: "calc(100vh - 40px)",
    color: theme.palette.common.black,
    msOverflowStyle: "none" /* IE 11 */,
    scrollbarWidth: "none",
  },
  toolbarMargin: {
    marginBottom: headerHeight,
  },
  collapseList: {
    marginLeft: "2em",
  },
  icons: {
    color: theme.palette.common.white,
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  mainMenuList: {
    width: "100%",
    position: "sticky",
  },
  drawerItem: {
    opacity: 0.7,
  },

  tabStyle: {
    color: theme.palette.common.tabFont,
    textTransform: "none",
    fontSize: "0.9rem",
    width: "3em",
  },

  tabsStyling: {
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.whiteOpaque2
        : "inherit",
    "& .MuiTab-root": {
      minWidth: "11em",
    },
    "& .MuiSvgIcon-root": {
      fill: "black !important",
    },
  },

  drawerItemSelected: {
    "& .MuiListItemText-root": {
      opacity: 1,
      fontWeight: 700,
    },
    "& .MuiListItemIcon-root": {
      opacity: 1,
    },
  },
}));


const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function ElevationScroll(props) {
  const classes = useStyle();

  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: false,
    threshold: 0,

    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 3 : 1,
    classes: trigger ? { root: classes.AppBar2 } : { root: classes.AppBar1 },
  });
}

export default function Header({
  fav,
  setFavProps,
  dataFromHeader,
  handleCurrentPathname,
  themeDialogfunc,
  themePaletteOpen,
  setTheme,
  forcedRender,
  setForcedRender,
  myTheme,
  sendActivityDataToServer,
}) {
  const classes = useStyle();
  const theme = useTheme();
  const history = useHistory();
  const { path, url } = useRouteMatch();

  const [tabValue, settabValue] = useState(
    parseInt(window.localStorage.tabValue) || 0
  );
  const [mydisplay, setDisplay] = useState("block");
  const [checked, setChecked] = useState(true);
  const [menu, setMenu] = useState([]);
  const [favDialogOpen, setDialogOpen] = React.useState(false);
  const [currentPathname, setCurrentPathname] = React.useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [propForFetchCall, setPropForFetchCall] = React.useState(false);
  const [favTiles, setFavTiles] = React.useState([]);
  const [scrollTop, setScrollTop] = useState("");
  const [topPos, setTopPos] = useState(70);
  const [features, setFeatures] = useState([]);
  const [reload, setReload] = useState(true);
  const handleValueChange = (e, newValue) => {
    if (newValue !== 1) {
      settabValue(newValue);
      window.localStorage.tabValue = newValue;
    }
    if (window.localStorage.roleId != 3 && (newValue === 3 || newValue === 2)) {
      window.location.reload(true);
    }
    console.log(parseInt(window.localStorage.tabValue));
  };



  useEffect(() => {
    console.log("TRACKING_ID", TRACKING_ID)
    if (window.localStorage.roleId == 4) {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.pageview(window.location.pathname);
    }
  }, [window.location.pathname]);


  const [alertDialog, setAlertDailog] = useState(false);

  useEffect(() => {
    if (window.localStorage.teacherType === "Class") {
      const date = window.localStorage.sessionEndDate;
      const [day, month, year] = date.split('-');
      const result = [year, month, day].join('-');

      let currentDate = moment();
      currentDate.add(7, 'days')
      let lastPeriod = currentDate.format("YYYY-MM-DD")
      console.log("result", result)
      console.log("lastPeriod", lastPeriod)

      if (Date.parse(lastPeriod) >= Date.parse(result)) {
        setAlertDailog(true)
      }
    }
  }, [])






  useEffect(() => {

    try {
      fetch(myUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_FEATURE",
              busiParams: {
                branchId: window.localStorage.branchId,
                standardId: window.localStorage.boardId > 6 ?
                  window.localStorage.className.length === 1 ?
                    window.localStorage.className.substring(0, 1)
                    : window.localStorage.className.length === 2 ?
                      window.localStorage.className.substring(0, 2)
                      : window.localStorage.className.slice(0, window.localStorage.className.indexOf(","))
                  : window.localStorage.className,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.respData[0].respMsg.result && data.respData[0].respMsg.result.length > 0) {
            features.splice(0, features.length);
            features.push(data.respData[0].respMsg.result);
            window.localStorage.setItem("features", JSON.stringify(data.respData[0].respMsg.result));
            setReload(!reload);
          }
          console.log("Features---------------", features);
        })

        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }
  }, [window.localStorage.roleId, window.localStorage.className, tabValue])

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      // setScrolling(e.target.documentElement.scrollTop > scrollTop);
      if (e.target.documentElement.scrollTop > 80) {
        setTopPos(30);
      } else {
        setTopPos(70);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);



  useEffect(() => {
    console.log();
    window.location.pathname === "/" ||
      window.location.pathname === "/Login" ||
      window.location.pathname === "/index.html" ||
      window.location.pathname === "/ForgotPassword" ||
      window.location.pathname === "/ChangePassword" ||
      window.location.pathname === " " ||
      window.location.pathname === "/PrivacyPolicy" ||
      window.location.pathname === "/Terms" ||
      window.location.pathname === "/ContributeQuestions" ||
      window.location.pathname === "/UploadSection/ExamWorks/Instructions" ||
      window.location.pathname === "/UploadSection/ExamWorks/SystemSmartExam"
      ? setDisplay("none")
      : setDisplay("block");

  });

  useEffect(() => {
    window.addEventListener("mousemove", (ev) => {
      if (window.localStorage.theme === "Auto") {
        if (
          moment().unix() > window.localStorage.startTimeForTheme &&
          moment().unix() < window.localStorage.endTimeForTheme
        ) {
          //console.log("Auto setting selected setting day them");
          setTheme(ThemeDay);
        } else {
          //console.log("Auto setting selected setting night them");
          setTheme(ThemeNight);
        }
      }
    });
  });





  useEffect(() => {
    const resolveAfter2Seconds = async () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(setChecked((prev) => !prev));
        }, 800);
      });
    };
    console.log(window.localStorage.roleId);
    if (window.localStorage.roleId == 4) {
      if (window.localStorage.variantId === "7" && window.localStorage.boardId > 6) {
        let menuItems = cloneDeep(MenuItemsStudent.ExamMode);
        let menuItemsFeature = cloneDeep(MenuItemsStudent.ExamMode);
        if ((features[0] || []).filter(item => item.featureId == 38).length > 0) {
          setMenu(menuItemsFeature);
          history.push("/ExamMode/eExam");
        }
        else {
          if ((menuItems || []).filter(item => item.title === "Detailed Analysis").length > 0) {
            menuItems.splice(menuItems.findIndex(data => data.title === "Detailed Analysis"), 1)
          }
          setMenu(menuItems);
          history.push("/ExamMode/eExam");
        }
      } else {
        if (tabValue === 0) {
          setMenu(MenuItemsStudent.Home);
          if (
            window.location.pathname === "" ||
            window.location.pathname === "/" ||
            window.location.pathname === "/Login" ||
            window.location.pathname === "/index.html" ||
            window.location.pathname === "/PrivacyPolicy" ||
            window.location.pathname === "/Terms" ||
            window.location.pathname === "/ContributeQuestions"
          ) {
            console.log("I was stuck here for stupidly long time");
          } else {
            history.push("/LandingPageStudent/Notification");
          }
        } else if (tabValue === 2) {
          let menuItems = cloneDeep(MenuItemsStudent.Resources);
          let menuItemsFeature = cloneDeep(MenuItemsStudent.Resources);
          if ((features[0] || []).filter(item => item.featureId == 35).length > 0 || window.localStorage.boardId > 6) {
            setMenu(menuItemsFeature);
            history.push("/resources/ComprehensiveGuide");
          }
          else {
            if ((menuItems || []).filter(item => item.title === "Comprehensive Guide").length > 0) {
              menuItems.splice(menuItems.findIndex(data => data.title === "Comprehensive Guide"), 1)
            }
            setMenu(menuItems);
            history.push("/resources/GoldenNotes");
          }
        } else if (tabValue === 3) {
          setMenu(MenuItemsStudent.ToDoTracker);

          history.push("/ToDoTracker/Classwork");
        } else if (tabValue === 4) {
          setMenu(MenuItemsStudent.UploadSection);
          if (
            window.localStorage.boardId > 6 &&
            (window.location.pathname === "/UploadSection/ExamWorks/Instructions" ||
              window.location.pathname === "/UploadSection/ExamWorks/SystemSmartExam")
          ) {
            console.log("I was stuck here for stupidly long time");
          } else {
            history.push("/UploadSection/WriteWorks");
          }
        } else if (tabValue === 5) {
          let menuItems = cloneDeep(MenuItemsStudent.StudentGroup);
          let menuItemsFeature = cloneDeep(MenuItemsStudent.StudentGroup);
          if ((features[0] || []).filter(item => item.featureId == 37).length > 0 || window.localStorage.boardId > 6) {
            if (window.localStorage.variantId === "2" || window.localStorage.variantId === "7") {
              if ((menuItems || []).filter(item => item.title === "Smart Exams").length > 0) {
                menuItems.splice(menuItems.findIndex(data => data.title === "Smart Exams"), 1)
              }
              setMenu(menuItems);
            } else {
              setMenu(menuItemsFeature)
            }
            history.push("/StudentGroup/DiscussionForum");
          } else {
            if ((menuItems || []).filter(item => item.title === "Smart Exams").length > 0) {
              menuItems.splice(menuItems.findIndex(data => data.title === "Smart Exams"), 1)
            }
            setMenu(menuItems);
            history.push("/StudentGroup/DiscussionForum");
          }
        } else if (tabValue === 6) {
          let menuItems = cloneDeep(MenuItemsStudent.ExamMode);
          let menuItemsFeature = cloneDeep(MenuItemsStudent.ExamMode);
          if ((features[0] || []).filter(item => item.featureId == 38).length > 0) {
            setMenu(menuItemsFeature);
            history.push("/ExamMode/eExam");
          }
          else {
            if ((menuItems || []).filter(item => item.title === "Detailed Analysis").length > 0) {
              menuItems.splice(menuItems.findIndex(data => data.title === "Detailed Analysis"), 1)
            }
            setMenu(menuItems);
            history.push("/ExamMode/eExam");
          }
        } else if (tabValue === 7) {
          setMenu(MenuItemsStudent.Manage);
          history.push("/Manage/SubjectManager");
        } else if (tabValue === 8) {
          setMenu(MenuItemsStudent.Settings);
          history.push("/Settings/ChangePassword");
        } else {
          setMenu(MenuItemsStudent.Home);
          history.push("/LandingPageStudent/Notification");
        }
      }
      // -----------------------------------
    } else if (window.localStorage.roleId == 5) {
      if (window.localStorage.variantId === "7") {
        let menuItems = cloneDeep(MenuItemsParents.ExamMode);
        let menuItemsFeature = cloneDeep(MenuItemsParents.ExamMode);
        if ((features[0] || []).filter(item => item.featureId == 38).length > 0) {
          setMenu(menuItemsFeature);
          history.push("/ExamMode/eExam");
        }
        else {
          if ((menuItems || []).filter(item => item.title === "Detailed Analysis").length > 0) {
            menuItems.splice(menuItems.findIndex(data => data.title === "Detailed Analysis"), 1)
          }
          setMenu(menuItems);
          history.push("/ExamMode/eExam");
        }
      } else {
        if (tabValue === 0) {
          setMenu(MenuItemsParents.Home);

          if (
            window.location.pathname === "" ||
            window.location.pathname === "/" ||
            window.location.pathname === "/Login" ||
            window.location.pathname === "/index.html" ||
            window.location.pathname === "/Terms" ||
            window.location.pathname === "/PrivacyPolicy"
          ) {
            console.log("I was stuck here for stupidly long time");
          } else {
            history.push("/LandingPageStudent/Notification");
          }
        } else if (tabValue === 2) {
          let menuItems = cloneDeep(MenuItemsParents.Resources);
          let menuItemsFeature = cloneDeep(MenuItemsParents.Resources);
          if ((features[0] || []).filter(item => item.featureId == 35).length > 0 || window.localStorage.boardId > 6) {
            setMenu(menuItemsFeature);
            history.push("/resources/ComprehensiveGuide");
          }
          else {
            if ((menuItems || []).filter(item => item.title === "Comprehensive Guide").length > 0) {
              menuItems.splice(menuItems.findIndex(data => data.title === "Comprehensive Guide"), 1)
            }
            setMenu(menuItems);
            history.push("/resources/GoldenNotes");
          }
        } else if (tabValue === 3) {
          setMenu(MenuItemsParents.ToDoTracker);

          history.push("/ToDoTracker/Classwork");
        } else if (tabValue === 4) {
          setMenu(MenuItemsParents.UploadSection);
          history.push("/UploadSection/WriteWorks");
        } else if (tabValue === 5) {
          let menuItems = cloneDeep(MenuItemsParents.StudentGroup);
          let menuItemsFeature = cloneDeep(MenuItemsParents.StudentGroup);
          if ((features[0] || []).filter(item => item.featureId == 37).length > 0 || window.localStorage.boardId > 6) {
            if (window.localStorage.variantId === "2" || window.localStorage.variantId === "7") {
              if ((menuItems || []).filter(item => item.title === "Smart Exams").length > 0) {
                menuItems.splice(menuItems.findIndex(data => data.title === "Smart Exams"), 1)
              }
              setMenu(menuItems);
            } else {
              setMenu(menuItemsFeature)
            }
            history.push("/StudentGroup/DiscussionForum");
          } else {
            if ((menuItems || []).filter(item => item.title === "Smart Exams").length > 0) {
              menuItems.splice(menuItems.findIndex(data => data.title === "Smart Exams"), 1)
            }
            setMenu(menuItems);
            history.push("/StudentGroup/DiscussionForum");
          }
        } else if (tabValue === 6) {
          let menuItems = cloneDeep(MenuItemsParents.ExamMode);
          let menuItemsFeature = cloneDeep(MenuItemsParents.ExamMode);
          if ((features[0] || []).filter(item => item.featureId == 38).length > 0) {
            setMenu(menuItemsFeature);
            history.push("/ExamMode/eExam");
          }
          else {
            if ((menuItems || []).filter(item => item.title === "Detailed Analysis").length > 0) {
              menuItems.splice(menuItems.findIndex(data => data.title === "Detailed Analysis"), 1)
            }
            setMenu(menuItems);
            history.push("/ExamMode/eExam");
          }
        } else if (tabValue === 7) {
          setMenu(MenuItemsParents.Manage);
          history.push("/Manage/SubjectManager");
        } else if (tabValue === 8) {
          setMenu(MenuItemsParents.Settings);
          history.push("/Settings/ChangePassword");
        } else {
          setMenu(MenuItemsParents.Home);
          history.push("/LandingPageStudent/Notification");
        }
      }
    } else if (window.localStorage.roleId == 3) {

      if (window.localStorage.variantId === "7" && window.localStorage.boardId > 6) {
        let menuItems = cloneDeep(MenuItemsTeachers.ExamMode);
        let menuItemsFeature = cloneDeep(MenuItemsTeachers.ExamMode);
        if ((features[0] || []).filter(item => item.featureId == 37).length > 0) {
          setMenu(menuItemsFeature);
          history.push("/ExamMode/eExam");
        }
        else {
          if ((menuItems || []).filter(item => item.title === "Smart Exams").length > 0) {
            menuItems.splice(menuItems.findIndex(data => data.title === "Smart Exams"), 1)
          }
          setMenu(menuItems);
          history.push("/ExamMode/eExam");
        }
      } else {
        if (tabValue === 0) {
          setMenu(MenuItemsTeachers.Home);

          if (
            window.location.pathname === "" ||
            window.location.pathname === "/" ||
            window.location.pathname === "/Login" ||
            window.location.pathname === "/index.html"
          ) {
            console.log("I was stuck here for stupidly long time");
          } else {
            history.push("/LandingPageStudent/Notification");
          }
        } else if (tabValue === 2) {
          let menuItems = cloneDeep(MenuItemsTeachers.Resources);
          let menuItemsFeature = cloneDeep(MenuItemsTeachers.Resources);
          if ((features[0] || []).filter(item => item.featureId == 35).length > 0) {
            setMenu(menuItemsFeature);
            history.push("/resources/ComprehensiveGuide");
          }
          else {
            if ((menuItems || []).filter(item => item.title === "Comprehensive Guide").length > 0) {
              menuItems.splice(menuItems.findIndex(data => data.title === "Comprehensive Guide"), 1)
            }
            setMenu(menuItems);
            history.push("/resources/GoldenNotes");
          }
        } else if (tabValue === 5) {
          if (window.localStorage.boardId > 6) {
            let menuItems = cloneDeep(MenuItemsTeachers.StudentGroup);
            if (window.localStorage.variantId === "2" || window.localStorage.variantId === "7") {
              if ((menuItems || []).filter(item => item.title === "Smart Exams").length > 0) {
                menuItems.splice(menuItems.findIndex(data => data.title === "Smart Exams"), 1)
              }
            }
            setMenu(menuItems);
            history.push("/StudentGroup/DiscussionForum");
          } else {
            setMenu(MenuItemsTeachers.StudentGroup);
            history.push("/StudentGroup/DiscussionForum");
          }
        } else if (tabValue === 6) {
          // setMenu(MenuItemsTeachers.ExamMode);
          // history.push("/ExamMode/eExam");
          let menuItems = cloneDeep(MenuItemsTeachers.ExamMode);
          let menuItemsFeature = cloneDeep(MenuItemsTeachers.ExamMode);
          if ((features[0] || []).filter(item => item.featureId == 37).length > 0) {
            setMenu(menuItemsFeature);
            history.push("/ExamMode/eExam");
          }
          else {
            if ((menuItems || []).filter(item => item.title === "Smart Exams").length > 0) {
              menuItems.splice(menuItems.findIndex(data => data.title === "Smart Exams"), 1)
            }
            setMenu(menuItems);
            history.push("/ExamMode/eExam");
          }
        } else if (tabValue === 7) {
          setMenu(MenuItemsTeachers.Manage);
          history.push("/Manage/SubjectManager");
        } else if (tabValue === 8) {
          setMenu(MenuItemsTeachers.Settings);
          history.push("/Settings/ChangePassword");
        } else {
          setMenu(MenuItemsTeachers.Home);
          history.push("/LandingPageStudent/Notification");
        }
      }
    }

    return async () => {
      await resolveAfter2Seconds().then(setChecked((prev) => !prev));
      setChecked(true);
    };

  }, [reload, window.localStorage.teacherType]);

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }
  const moduleImageMapping = {
    CW: {
      displayName: "Class Work",
      img: Cw,
      path: "/ToDoTracker/Classwork",
      tab: 3,
    },
    "Golden notes": {
      displayName: "Golden Notes",
      img: goldennotes,
      path: "/resources/GoldenNotes",
      tab: 2,
    },
    "Connect zone": {
      displayName: "Connect Zone",
      img: connectzone,
      path: "/LandingPageStudent/ConnectZone",
      tab: 0,
    },
    "Teacher details": {
      displayName: "Teacher Details",
      img: teacherdetails,
      path: "/LandingPageStudent/TeacherDetails",
      tab: 0,
    },
    "Time table": {
      displayName: "Time Table",
      img: timetable,
      path: "/LandingPageStudent/TimeTable",
      tab: 0,
    },
    Calendar: {
      displayName: "Calender",
      img: calendar,
      path: "/LandingPageStudent/Calendar",
      tab: 0,
    },
    "Subject recordings": {
      displayName: "Subject Recordings",
      img: subjectrecordings,
      path: "/resources/SubjectRecording",
      tab: 2,
    },
    "Comprehensive Guide": {
      displayName: "Comprehensive Guide",
      img: comprehensiveguide,
      path: "/resources/ComprehensiveGuide",
      tab: 2,
    },
    "Text book links": {
      displayName: "Test Books Links",
      img: textbooklinks,
      path: "/resources/TextBookLinks",
      tab: 2,
    },
    "Writing materials": {
      displayName: "Writing Materials",
      img: writingmaterial,
      path: "/resources/WritingMaterials",
      tab: 3,
    },
    "Exam materials": {
      displayName: "Exam Materials",
      img: exammaterial,
      path: "/resources/ExamMaterials",
    },
    HW: {
      displayName: "Home Work",
      img: Hw,
      path: "/ToDoTracker/Homework",
      tab: 3,
    },
    Record: {
      displayName: "Record",
      img: record,
      path: "/ToDoTracker/Record",
      tab: 3,
    },
    Observation: {
      displayName: "Observation",
      img: observation,
      path: "/ToDoTracker/Observation",
      tab: 3,
    },
    Assignment: {
      displayName: "Assignment",
      img: assignment,
      path: "/ToDoTracker/Assignment",
      tab: 3,
    },
    Project: {
      displayName: "Project",
      img: project,
      path: "/ToDoTracker/Project",
      tab: 3,
    },
    "Writing works": {
      displayName: "Writing Works",
      img: writing,
      path: "/UploadSection/WriteWorks",
      tab: 4,
    },
    "Exam works": {
      displayName: "Exam Works",
      img: exam,
      path: "/UploadSection/ExamWorks",
      tab: 4,
    },
    "Vigilance mode": {
      displayName: "Vigilance",
      img: vigilance,
      path: "/LandingPageStudent/Vigilance",
      tab: 0,
    },
    "Vigilance (Parents)": {
      displayName: "Vigilance",
      img: vigilance,
      path: "/LandingPageStudent/Vigilance",
      tab: 0,
    },
    "Message teacher/ PTM (Parents)": {
      displayName: "Messaging",
      img: messageteacher,
      path: "/LandingPageStudent/Messaging",
      tab: 0,
    },
    "Message teacher": {
      displayName: "Message Teacher",
      img: messageteacher,
      path: "/LandingPageStudent/MessageTeacher",
      tab: 0,
    },
    "e-Exam": {
      displayName: "E Exam",
      img: exammaterial,
      path: "/ExamMode/eExam",
      tab: 6,
    },
    "Smart Exams": {
      displayName: "Smart Exams",
      img: smartExam,
      path: "/StudentGroup/SmartExams",
      tab: 6,
    },
    "Post Exam Analysis": {
      displayName: "Detailed Analysis",
      img: deatailsAnalysis,
      path: "/ExamMode/detailed-analysis",
      tab: 6,
    },
    "Rectification Work": {
      displayName: "Rectification Work",
      img: exam,
      path: "/ExamMode/rectification",
      tab: 6,
    },
    Analysis: {
      displayName: "Analysis",
      img: examanalysis,
      path: "/ExamMode/ExamAnalysis",
      tab: 6,
    },
    "Subject manager": {
      displayName: "Subject Manager",
      img: subjectmanager,
      path: "/Manage/SubjectManager",
      tab: 7,
    },
    "Attendance tracker": {
      displayName: "Attendence",
      img: attendance,
      path: "/Manage/Attendance",
      tab: 7,
    },
    Notification: {
      displayName: "Notification",
      img: notifications,
      path: "/LandingPageStudent/Notification",
      tab: 0,
    },
    "Student Group - Forum": {
      displayName: "Discussion Forum",
      img: discussionforum,
      path: "/StudentGroup/DiscussionForum",
      tab: 5,
    },
    "Student Group - Post Query": {
      displayName: "Post A Query",
      img: postquery,
      path: "/StudentGroup/PostAQuery",
      tab: 5,
    },
    "Student Group - Adaptive Test": {
      displayName: "Adaptive Test",
      img: exam,
      path: "/StudentGroup/adaptiveTest",
      tab: 5,
    },
    "Manage Groups": {
      displayName: "Manage Groups",
      img: creategroup,
      path: "/StudentGroup/CreateGroup",
      tab: 5,
    },
    "Student Details": {
      displayName: "Student Details",
      img: studentDetails,
      path: "/LandingPageStudent/StudentDetails",
      tab: 0,
    },
    "Apply leave": {
      displayName: "Apply Leave",
      img: applyleave,
      path: "/Manage/ApplyLeave",
      tab: 0,
    },
  };

  const handleFavClick = async () => {
    console.log("the handle fav click has been called");
    await fetch(myUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_GET_FAVOURITE_FOR_USER",
            busiParams: {
              id:
                window.localStorage.roleId == "5"
                  ? window.localStorage.parentUserId
                  : window.localStorage.userId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let favarr = [];
        console.log("going to priunt the data");
        console.log(data.respData[0].respMsg.result);
        data.respData[0].respMsg.result.map((item, index) => {
          console.log("data", item);
          let tempArr = {
            title: moduleImageMapping[item.moduleName].displayName,
            img: moduleImageMapping[item.moduleName].img,
            link: moduleImageMapping[item.moduleName].path,
            tab: moduleImageMapping[item.moduleName].tab,
            favId: item.favouriteId,
          };

          favarr.push(tempArr);
        });
        console.log("Data ------------------1", favarr);
        setFavTiles(favarr);
      })
      .catch((data) => {
        console.error(data);
      });
    setDialogOpen(true);
    console.log("the fav tiles have been set = " + JSON.stringify(favTiles));
  };

  const tabChangeFromFav = async (dataFromFav, link) => {
    console.log("the tab number has been received = " + dataFromFav);
    settabValue(dataFromFav);
  };

  return (
    <React.Fragment>
      {
        //<SessionManagement sourcePathName={currentPathname}></SessionManagement>
        <FavouritesDialog
          open={favDialogOpen}
          handleClose={() => setDialogOpen(false)}
          favTiles={favTiles}
          tabChangeFromFav={tabChangeFromFav}
          forcedRender={forcedRender}
          setForcedRender={setForcedRender}
        />
      }

      <div id="myheader" style={{ display: mydisplay }}>
        {/* {console.log(window.localStorage.bloodGroup)} */}
        <AppBar position="absolute" className={classes.AppBar} elevation={0}>
          <Toolbar disableGutters>
            <Grid container direction="column" alignItems="center">
              {window.location.pathname !== "" &&
                window.location.pathname !== "/" &&
                window.location.pathname !== "/Login" ? (
                <StudentInfoPaper
                  sendActivityDataToServer={sendActivityDataToServer}
                  bloodGroupId={window.localStorage.bloodGroupId}
                  name={window.localStorage.name}
                  profilePic={
                    window.localStorage.profilePic == "null" ||
                      window.localStorage.profilePic === null ||
                      window.localStorage.profilePic === undefined
                      ? teacherplaceholder
                      : window.localStorage.profilePic
                  }
                  orgImg={
                    window.localStorage.orgImage == "null" ||
                      window.localStorage.orgImage === null ||
                      window.localStorage.orgImage === undefined ||
                      window.localStorage.orgImage === "" ||
                      window.localStorage.orgImage === " "
                      ? fallbackschool
                      : window.localStorage.orgImage
                  }
                  standard={`${window.localStorage.roleId == 3 ? "Class" : "Standard"
                    } : ${window.localStorage.className == "null" ||
                      window.localStorage.className === null ||
                      window.localStorage.className === undefined
                      ? "--"
                      : window.localStorage.boardId > 6 ?
                        `${window.localStorage.courseName} (${window.localStorage.className})` : window.localStorage.className
                    } - ${window.localStorage.sectionName == "null" ||
                      window.localStorage.sectionName === null ||
                      window.localStorage.sectionName === undefined
                      ? "--"
                      : window.localStorage.sectionName
                    } ${window.localStorage.academicYearDesc == "null" ||
                      window.localStorage.academicYearDesc === null ||
                      window.localStorage.academicYearDesc === undefined
                      ? "(--)" : `(${window.localStorage.academicYearDesc})`}`}
                  bloodGroup={`Blood Group ${window.localStorage.bloodGroup == "null" ||
                    window.localStorage.bloodGroup === null ||
                    window.localStorage.bloodGroup === undefined
                    ? "--"
                    : window.localStorage.bloodGroup
                    }`}
                  rollno={`Roll No. ${window.localStorage.rollNo == "null" ||
                    window.localStorage.rollNo === null ||
                    window.localStorage.rollNo === undefined
                    ? "--"
                    : window.localStorage.rollNo
                    }`}
                  wish={`${window.localStorage.aim == "null" ||
                    window.localStorage.aim === null ||
                    window.localStorage.aim === undefined ||
                    window.localStorage.aim.trim() == ""
                    ? "This is the section where you write about your goals,Hurry up and update it!!"
                    : window.localStorage.aim
                    }`}
                  teacherType={`${window.localStorage.teacherType}-Teacher`}
                  schoolImg={schoolLogo}
                  themeDialogfunc={themeDialogfunc}
                  themePaletteOpen={themePaletteOpen}
                  setTheme={setTheme}
                  theme={myTheme}
                  forcedRender={forcedRender}
                  settabValue={settabValue}
                  setmyForcedRender={setForcedRender}
                  subjectName={window.localStorage.subjectName}
                />
              ) : undefined}
            </Grid>
          </Toolbar>
        </AppBar>
        {
          //  <div className={classes.toolbarMargin} />
        }
      </div>

      <Drawer
        variant="permanent"
        classes={{ paper: topPos == 70 ? classes.drawerContainer : classes.drawerContainer1 }}
        id="myDrawer"
        style={{ display: mydisplay, }}
      >
        <List className={classes.mainMenuList} id="newDrawer">
          {menu.map((item, index) => (
            <Slide
              direction="down"
              in={checked}
              timeout={{ appear: 500, enter: 700, exit: 700 }}
              key={item + index}
            >
              <ListItem key={item}>
                {(window.localStorage.roleId == 3 &&
                  window.localStorage.teacherType == "Class" &&
                  item.title == "Manage Groups") ||
                  (window.localStorage.roleId == 3 &&
                    window.localStorage.teacherType == "Subject" &&
                    item.title == "Leave Requests") ||
                  (window.localStorage.roleId == 5 &&
                    item.title == "Post a Query")
                  ? undefined : (
                    <MenuCards
                      img={item.img}
                      title={item.title}
                      to={item.to}
                      isSelected={
                        window.localStorage.teacherType == "Class"
                          ? item.title === "Attendance"
                            ? window.location.pathname ===
                              "/Manage/markAttendance" ||
                              window.location.pathname === "/Manage/Attendance"
                              ? true
                              : false
                            : window.location.pathname === item.to
                              ? true
                              : false
                          : window.location.pathname === item.to
                            ? true
                            : false
                      }
                    />
                  )}
              </ListItem>
            </Slide>
          ))}
        </List>
        <Grid container>
          <Grid item sm={9}>
            <img width={"100%"}
              src={
                window.localStorage.boardId > 6 ?
                  theme.typography.myTheme.themeName === "Night" ? academicLogoNight : academicLogo
                  : theme.typography.myTheme.themeName === "Night" ? sidebarlogoNight : sidebarlogo
              }
              alt="sidebarlogo"
              style={{
                // width: "8em",
                marginLeft: "1em",
              }}
            />
          </Grid>
          {/* <Grid item container direction="column" sm={3} alignItems="flex-end">
            <ArrowDropUpIcon
              style={{ cursor: "pointer", color: theme.palette.common.tabFont }}
              onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
            />

            <ArrowDropDownIcon
              style={{ cursor: "pointer", color: theme.palette.common.tabFont }}
              onClick={(e) =>
                window.scrollTo({ top: 1000, behavior: "smooth" })
              }
            />
          </Grid> */}
        </Grid>
      </Drawer>
      <ElevationScroll>
        <AppBar
          classes={{ root: classes.AppBar1 }}
          style={{ display: mydisplay }}
          elevation={1}
        >
          {window.localStorage.variantId === "7" ?
            <Grid container direction="row">
              <Tabs
                value={tabValue}
                className={classes.tabsStyling}
                textColor="secondary"
                onChange={handleValueChange}
                aria-label=""
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  className={classes.tabStyle}
                  label="Exam Zone"
                  {...a11yProps(6)}
                />
              </Tabs>
            </Grid> : <Grid container direction="row">
              <Tabs
                value={tabValue}
                className={classes.tabsStyling}
                textColor="secondary"
                onChange={handleValueChange}
                aria-label=""
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  className={classes.tabStyle}
                  label="Home"
                  {...a11yProps(0)}
                  onClick={() =>
                    handleCurrentPathname("/LandingPageStudent/Notification")
                  }
                />
                <Tab
                  className={classes.tabStyle}
                  label="Favourites"
                  {...a11yProps(1)}
                  onClick={handleFavClick}
                />
                <Tab
                  className={classes.tabStyle}
                  label="Resources"
                  {...a11yProps(2)}
                  onClick={() => handleCurrentPathname("/resources/GoldenNotes")}
                />
                <Tab
                  className={classes.tabStyle}
                  label={"To Do Tracker"}
                  style={{
                    display: window.localStorage.roleId == 3 ? "none" : "inherit",
                  }}
                  {...a11yProps(3)}
                  onClick={() => handleCurrentPathname(window.location.pathname)}
                />
                <Tab
                  className={classes.tabStyle}
                  label="Upload Section"
                  style={{
                    display: window.localStorage.roleId == 3 ? "none" : "inherit",
                  }}
                  {...a11yProps(4)}
                  onClick={() => handleCurrentPathname(window.location.pathname)}
                />
                <Tab
                  className={classes.tabStyle}
                  label="Practice Zone"
                  {...a11yProps(5)}
                  onClick={() => handleCurrentPathname(window.location.pathname)}
                />
                <Tab
                  className={classes.tabStyle}
                  label="Exam Zone"
                  {...a11yProps(6)}
                />
                <Tab
                  className={classes.tabStyle}
                  label="Manage"
                  {...a11yProps(7)}
                />
                <Tab
                  className={classes.tabStyle}
                  label="Settings"
                  {...a11yProps(8)}
                />
              </Tabs>
            </Grid>}
        </AppBar>
      </ElevationScroll>

      <Dialog
        open={alertDialog}
        onClose={() => setAlertDailog(false)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle>Alert Message</DialogTitle>
        <DialogContent>
          <Typography variant="h6">This class and section will end soon, Please change the end date or the whole data associated with this class and section will be deleted permanently.</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color="secondary" onClick={() => setAlertDailog(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
