import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  Backdrop,
  CircularProgress,
  Button,
  List, ListItem, ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { url, makeReqBody } from "../Ui/store.js";
import ChapterDetailedChart from "./ChapterDetailedChart";
import CircleChart from "./CircleChart";
import ChapterSectionDetailed from "./ChapterSectionDetailed";
import AnalysisCard from "./AnalysisCard";
import SuggestionBox from "./SuggestionBox";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ChapterHorizontalChart from "./ChapterHorizontalChart";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import attemptico from "../assets/attempt-ico.png";
import accuracyico from "../assets/accuracy-ico.png";
import overallico from "../assets/overall-ico.png";
import ChartMaker from "../Ui/ChartMaker.js";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  customDropdown: {
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(14px, 14px) scale(1)',
      pointerEvents: 'none',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 14px',
    },
    '& .Mui-disabled': {
      background: '#aaa',
    },

    "& .MuiFormLabel-root": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiInputBase-input": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
  },
  circleCbx: {
    width: '130px',
    height: '130px',
    margin: '10px auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
  chapterDetailWrapper: {
    // background: '#fff',
    margin: '32px 0',
    width: '100%',
    justifyContent: 'space-between'
  },
  chapterDetailWrapperInner: {
    background: window.localStorage.theme === "Night" ? "#cccccc" : "#ffffff",
    padding: '15px 0',
    boxShadow: '0px 0px 9px #00000069',
    borderRadius: '7px',
    maxWidth: '49%',
    flexBasis: '49%',
  }


}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function ChapterDetailed(props) {
  const classes = useStyle();
  const [subject, setSubject] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [identifierMarks, setIdentifierMarks] = useState([]);
  const [chapterIds, setChapterIds] = useState([]);
  const [chapterBarLabel, setChapterBarLabel] = useState([]);
  const [chapterBarData, setChapterBarData] = useState([]);
  const [backLoader, setBackLoader] = useState(false);
  const [hideChapter, setHideChapter] = useState(false);
  const [cId, setCid] = useState(null);
  const [paasingStatus, setPaasingStatus] = useState([]);
  const [chapterHorizontalBarMarks, setChapterHorizontalBarMarks] = useState([]);
  const [message, setMessage] = useState("");
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [typologyChart, setTypologyChart] = useState({});

  useEffect(() => {
    if (props.studentInstanceId !== 0) {
      try {
        setBackLoader(true);
        fetch(
          url,
          makeReqBody("SCHOOL_GET_SUB_WISE_PERC", {
            examtermId: props.examTermId,
            sectionId: window.localStorage.roleId === "3" ? props.sectionId : window.localStorage.sectionId,
            studentInstanceId: window.localStorage.roleId === "3" ? props.studentInstanceId : window.localStorage.studentInstanceId,
            subjectId: 0,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            if (window.localStorage.teacherType === "Subject") {
              let subjectid = window.localStorage.subjectId;
              let subject = data.respData[0].respMsg.result.filter(function (item) {
                return item.subjectId === parseInt(subjectid);
              });
              setSubject(subject)
              setSelectedSubject(subject[0]);
            } else {
              if (data.respData[0].respMsg.status === "Failure") {
                setMessage(data.respData[0].respMsg.message);
                setStatus("error")
                setSnack(true);
              } else {
                setSubject(data.respData[0].respMsg.result)
                setSelectedSubject(data.respData[0].respMsg.result[0]);
              }

            }
            setBackLoader(false);
          })
          .catch((data) => {
            console.error(data);
            setBackLoader(false);
          });
      } catch (error) {
        console.error(error);
        setBackLoader(false);
      }
    } else {
      try {
        setBackLoader(true);
        fetch(
          url,
          makeReqBody("SCHOOL_GET_SUBJECT_WISE_CLASS_PERF", {
            examTermId: props.examTermId,
            sectionId: window.localStorage.sectionId,
            studentInstanceId: props.studentInstanceId,
            subjectId: 0,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            if (window.localStorage.teacherType === "Subject") {
              let subjectid = window.localStorage.subjectId;
              let subject = data.respData[0].respMsg.result.filter(function (item) {
                return item.subjectId === parseInt(subjectid);
              });
              setSubject(subject)
              setSelectedSubject(subject[0]);
            } else {
              setSubject(data.respData[0].respMsg.result)
              setSelectedSubject(data.respData[0].respMsg.result[0]);
            }
            setBackLoader(false);
          })
          .catch((data) => {
            console.error(data);
            setBackLoader(false);
          });
      } catch (error) {
        console.error(error);
        setBackLoader(false);
      }
    }
  }, [props.examTermId, props.render])


  useEffect(() => {
    if (selectedSubject !== null) {
      try {
        setBackLoader(true);
        fetch(
          url,
          makeReqBody("SCHOOL_GET_CHAPTER_LEVEL_PERC", {
            chapterId: 0,
            examtermId: props.examTermId,
            sectionId: window.localStorage.sectionId,
            studentInstanceId: window.localStorage.roleId === "3" ? props.studentInstanceId : window.localStorage.studentInstanceId,
            subjectId: selectedSubject.subjectId,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            setIdentifierMarks(data.respData[0].respMsg.result.identifierDetailedMarks);
            setChapterHorizontalBarMarks(data.respData[0].respMsg.result.chaptersDetailedMarks);
            let chapterId = data.respData[0].respMsg.result.chaptersDetailedMarks.map((item) => item.chapterId)
            let labels = data.respData[0].respMsg.result.chaptersDetailedMarks.map((item) => item.chapterName)
            let chapter = data.respData[0].respMsg.result.chaptersDetailedMarks.map((item) => parseFloat(item.overAllPer))
            let passStatus = data.respData[0].respMsg.result.chaptersDetailedMarks.map((item) => item.percAbovePassingMarks)
            setChapterIds(chapterId);
            setChapterBarLabel(labels);
            setChapterBarData(chapter);
            setPaasingStatus(passStatus);


            let typologyDataSet = {
              labels: [],
              datasets: [{
                data: [],
                backgroundColor: "#498bff",
                borderColor: "#498bff",
              }],
            };
            data.respData[0].respMsg.result.typologyDetailedMarks.map(x => {
              typologyDataSet.labels.push(x.typologyName);
              typologyDataSet.datasets[0].data.push(parseInt(x.perc));
            })
            setTypologyChart(typologyDataSet)

            setBackLoader(false);

          })
          .catch((data) => {
            console.error(data);
            setBackLoader(false);
          });
      } catch (error) {
        console.error(error);
        setBackLoader(false);
      }
    }
  }, [selectedSubject])

  const captureImg = () => {
    let node = document.getElementById('my-node');
    htmlToImage.toPng(node)
      .then(function (dataUrl) {
        let img = new Image();
        img.src = dataUrl;
        document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      }).then(() => {
        alert("Hello");
      });
  }


  const downloadReport = () => {
    try {
      setBackLoader(true);
      fetch(
        url,
        makeReqBody("SCHOOL_GET_EXAM_REPORT", {
          branchId: window.localStorage.branchId,
          examTermId: props.examTermId,
          module: "DetailedAnalysis",
          secSubMappingId: 0,
          sectionId: window.localStorage.sectionId,
          studentInstanceId: [parseInt(window.localStorage.studentInstanceId)],
        })
      )
        .then((response) => response.json())
        .then((data) => {
          setBackLoader(false);
          if (data.respData[0].respMsg.result && data.respData[0].respMsg.result.url !== null) {
            window.open(data.respData[0].respMsg.result.url);
          }
        })
        .catch((data) => {
          console.error(data);
          setBackLoader(false);
        });
    } catch (error) {
      console.error(error);
      setBackLoader(false);
    }
  }



  return (
    <React.Fragment>
      <Grid item container direction="row" spacing={2} alignItems="center" style={{ marginTop: props.marginTop }}>
        <Grid item sm={3} style={{ marginBottom: "0.1em" }}>
          <TextField
            id="subject"
            label="Subject*"
            select
            name="subjectId"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={selectedSubject}
            className={classes.customDropdown}
            disabled={hideChapter || window.localStorage.teacherType === "Subject"}
            onChange={(e) => {
              setSelectedSubject(e.target.value);
              console.log(e.target.value);
            }}
          >
            {subject && subject.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item.subjectName} - {item.examTypeName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item >
          {hideChapter && <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={() => setHideChapter(false)}
          >
            Back
          </Button>}

          {/* {subject && subject.length !== 0 && !hideChapter && window.localStorage.roleId !== "3" &&<Typography
                color="secondary"
                component="a"
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginLeft: '20px',
                  display: 'flex',
                  alignItems: 'center'
                }}
                onClick={(e) => downloadReport(e)}
              >
                <GetAppOutlinedIcon color="secondary" size="20"/> Download Report
          </Typography> 
          } */}
          {/* <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={captureImg}
          >
            Capture Image
          </Button> */}
        </Grid>
      </Grid>


      <main id="my-node">

        {subject && subject.length !== 0 && <>
          <Grid item container direction="row" style={{ marginTop: '15px' }} spacing={3}>
            <Grid item sm>
              <AnalysisCard
                img={attemptico}
                marginTop="-35px"
                showImg={true}
                bgColor="#fbaa2b"
                val={selectedSubject && selectedSubject.perDetail.attemptedPer}
                name="Attempt"
              />
            </Grid>

            <Grid item sm>
              <AnalysisCard
                marginTop="-40px"
                showImg={true}
                img={accuracyico}
                bgColor="#498bff"
                val={selectedSubject && selectedSubject.perDetail.accuracyPer}
                name="Accuracy"
              />
            </Grid>

            <Grid item sm>
              <AnalysisCard
                marginTop="-33px"
                showImg={true}
                img={overallico}
                bgColor="#00a427"
                val={selectedSubject && selectedSubject.perDetail.overallPer}
                name="Overall"
              />
            </Grid>
          </Grid>

          {!hideChapter && <>

            <Grid item container direction="row" className={classes.chapterDetailWrapper} spacing={3}>
              <Grid item sm={6} className={classes.chapterDetailWrapperInner}>
                <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>Chapter Wise Performance</Typography>
                {chapterHorizontalBarMarks && chapterHorizontalBarMarks.map((item, index) => {
                  return (
                    <ChapterHorizontalChart
                      name={item.chapterName}
                      val={item.overAllPer}
                      bgChart={item.percAbovePassingMarks === true ? "#01be2e" : "#ca1923"}
                      bgCircleCount={item.percAbovePassingMarks === true ? "#52de6f" : "#f65760"}
                      width={`${item.overAllPer}%`}
                      pointer={true}
                      count={index + 1}
                      barClick={() => {
                        setCid(item.chapterId);
                        setHideChapter(true);
                      }}
                    />
                  )
                })

                }
              </Grid>
              <Grid
                item
                sm={6}
                container
                className={classes.chapterDetailWrapperInner}
              // spacing={3}
              >
                <Grid item container
                >
                  <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>Question Identifier Wise</Typography>
                </Grid>

                {identifierMarks && identifierMarks.map((item, index) => {
                  return (
                    <Grid key={index} item sm={4}>
                      <Typography component="div" style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircleChart
                          percentage={parseFloat(item.perc)}
                          textName={item.identifierName}
                        />
                      </Typography>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>

            {props.studentInstanceId !== 0 && selectedSubject && selectedSubject.examType === 3 &&
              <Grid item container direction="row" className={classes.chapterDetailWrapper} spacing={3}>
                <Grid item sm={6} className={classes.chapterDetailWrapperInner}>
                  <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>TYPOLOGY</Typography>
                  <Grid item container style={{ marginTop: "12px" }}>
                    <ChartMaker
                      height={132}
                      dataset={typologyChart}
                      percentage={true}
                      legends={false}
                      showBarVal={true}
                    />
                  </Grid>
                </Grid>
                {/* <Grid item sm={6} className={classes.chapterDetailWrapperInner}>
                  <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>AI SUGGESTION</Typography>
                </Grid> */}
              </Grid>}

            <SuggestionBox
              red={selectedSubject && selectedSubject.suggestions && selectedSubject.suggestions.Red}
              yellow={selectedSubject && selectedSubject.suggestions && selectedSubject.suggestions.Yellow}
              green={selectedSubject && selectedSubject.suggestions && selectedSubject.suggestions.Green}
            />
          </>

          }
        </>

        }
        {hideChapter &&
          <ChapterSectionDetailed
            render={hideChapter}
            cId={cId}
            subjectId={selectedSubject && selectedSubject.subjectId}
            examTermId={props.examTermId}
            sectionId={props.sectionId}
            studentInstanceId={props.studentInstanceId}
          />
        }
      </main>

      <Backdrop className={classes.backdrop} open={backLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snack}
        autoHideDuration={4500}
        onClose={() => setSnack(false)}
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
};


