import React, { useEffect, useState } from "react";
import IdleTimer from "react-idle-timer";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";

import Cookies from "universal-cookie";
import { url } from "./Components/Ui/store.js";

export default function YourApp(props) {
  let myRef = React.createRef(null);
  const cookies = new Cookies();
  const [time, setTime] = useState(0);

  const history = useHistory();
  const [location, setLocation] = React.useState("");
  //time in seconds to backout before making another server call , this is used because last batch was sent but local storage not reset
  const serverBackoutThreshold = 10;
  //if these many seconds have elapsed a server call will be made irrespective of the batch size
  const lastServerCallThreshold = 300;
  //if the number of object in the batch have reached this number make a server call
  const serverActivityBatchThreshold = 5;
  //server call check interval in miiliseconds
  const monitoringInterval = 1000;
  //record this activity only if these many seconds have elapsed
  const recordActivityThreshold = 5;
  //the idle duration calculation will start if these many seconds have elapsed and user is idle
  const idleThreshold = 30;
  const moduleIdMapping = {
    Classwork: 13,
    GoldenNotes: 10,
    ConnectZone: 3,
    TeacherDetails: 4,
    TimeTable: 6,
    Calendar: 7,
    SubjectRecording: 8,
    TextBookLinks: 9,
    WritingMaterials: 11,
    ExamMaterials: 12,
    Homework: 14,
    Record: 15,
    Observation: 16,
    Assignment: 17,
    Project: 18,
    WriteWorks: 19,
    ExamWorks: 20,
    eExam: 21,
    ExamAnalysis: 22,
    SubjectManager: 23,
    Attendance: 24,
    Notification: 1,
    DiscussionForum: 26,
    PostAQuery: 27,
  };
  function getModuleId(currentLoc) {
    const currentModuleName = currentLoc.substring(
      currentLoc.lastIndexOf("/") + 1,
      currentLoc.length
    );
    console.log("currentModuleName is " + currentModuleName);
    const moduleId =
      moduleIdMapping[currentModuleName] !== undefined
        ? moduleIdMapping[currentModuleName]
        : -9;
    return moduleId;
  }

  const [duration, setDuration] = React.useState(0);
  const [idle, setIdle] = React.useState("");
  let interval = 0;
  let lastActive = getCurrentMilli();
  let lastIdle = getCurrentMilli();

  const [path, setPath] = React.useState(window.location.pathname);
  const [userActivity, setUserACtivity] = React.useState({
    startDateTime: getCurrentDateTime(),
    endDateTime: "",
    idle: 0,
    active: 0,
    moduleId: getModuleId(window.location.pathname),
    src: "web",
    studentInstanceId: parseInt(
      window.localStorage.getItem("studentInstanceId")
    ),
    vigilanceId: 1,
  });

  const clearUserActivity = () => {
    console.log("data is send so clear the activity now");
    window.localStorage.setItem("totalIdle", 0);
    window.localStorage.setItem("totalActive", 0);
    window.localStorage.setItem("modeuleStartTime", getCurrentMilli());
    // setLastActive(getCurrentMilli());
    // setLastIdle(getCurrentMilli());
    lastActive = getCurrentMilli();
    lastIdle = getCurrentMilli();
  };

  const onBlur = () => {
    console.log("Tab is blurred");
    addCurrentModuleDataAndUpdateServer();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", (ev) => {
      addCurrentModuleDataAndUpdateServer();
    });
    window.addEventListener("blur", onBlur);
  }, []);

  useEffect(() => {
    if (window.localStorage.roleId == 4) {
      console.log("the component has mounted");
      window.localStorage.removeItem("idleDisable");
      window.localStorage.setItem("totalIdle", 0);
      window.localStorage.setItem("totalActive", 0);
      window.localStorage.setItem("modeuleStartTime", getCurrentMilli());
      return () => {
        console.log("the current location " + window.location.pathname);
        console.log(
          "localstorage last location " +
            window.localStorage.getItem("lastLocation")
        );
        if (
          window.localStorage.getItem("lastLocation") !==
            window.location.pathname &&
          window.localStorage.getItem("lastLocation") !== null &&
          window.localStorage.getItem("lastLocation") !== "/" &&
          window.localStorage.getItem("lastLocation") !== "/Login"
        ) {
          console.log(
            "set endtime user moving to different module" +
              window.location.pathname
          );
          const moduleEndTime = getCurrentDateTime();
          if (isActivityElligible()) {
            userActivity.startDateTime = getCurrentDateTimeFromMilli(
              window.localStorage.getItem("modeuleStartTime")
            );
            userActivity.vigilanceId = 1;
            userActivity.src = "web";
            userActivity.endDateTime = moduleEndTime;
            userActivity.idle = window.localStorage.getItem("totalIdle");
            // userActivity.active =
            //   window.localStorage.getItem("totalActive") === "0"
            //     ? Math.round(
            //         (getCurrentMilli() -
            //           window.localStorage.getItem("modeuleStartTime")) /
            //           1000
            //       )
            //     : window.localStorage.getItem("totalActive");

            // the active duration can be negative wehn there is data in the storage and user is on a module but the threshold has been breached dso the active can be in minus
            var currentActive = Math.round(
              (getCurrentMilli() -
                parseInt(window.localStorage.getItem("modeuleStartTime")) -
                parseInt(window.localStorage.getItem("totalIdle")) * 1000) /
                1000
            );

            if (currentActive <= 0) {
              userActivity.active = 0;
              userActivity.idle =
                parseInt(window.localStorage.getItem("totalIdle")) +
                currentActive;
            } else {
              userActivity.active = currentActive;
            }

            userActivity.studentInstanceId = parseInt(
              window.localStorage.getItem("studentInstanceId")
            );
            userActivity.deviceUUID = window.localStorage.uuid;
            userActivity.moduleId =
              window.localStorage.getItem("lastLocation") !== null
                ? getModuleId(window.localStorage.getItem("lastLocation"))
                : 1;
            console.log("the end time is = " + userActivity.endDateTime);
            let activityDataFromStorage = JSON.parse(
              window.localStorage.getItem("activityMonitor")
            );
            if (activityDataFromStorage === null) {
              activityDataFromStorage = [userActivity];
            } else {
              activityDataFromStorage.push(userActivity);
            }
            console.log(
              "setting local storage " + JSON.stringify(activityDataFromStorage)
            );
            window.localStorage.setItem(
              "activityMonitor",
              JSON.stringify(activityDataFromStorage)
            );
          } else {
            console.log(
              "activity time on this module very less not recording this"
            );
          }

          window.localStorage.setItem("lastLocation", window.location.pathname);
          setUserACtivity({
            startDateTime: getCurrentDateTimeFromMilli(
              window.localStorage.getItem("modeuleStartTime")
            ),
            endDateTime: "",
            idle: 0,
            active: 0,
            moduleId: getModuleId(window.location.pathname),
            // studentInstanceId: parseInt(
            //   window.localStorage.getItem("studentInstanceId")
            // ),
          });
        }
      };
    }
  }, [window.location.pathname]);

  function checkForServerUpdate() {
    if (
      (JSON.parse(window.localStorage.getItem("activityMonitor")) != null &&
        JSON.parse(window.localStorage.getItem("activityMonitor")).length >=
          serverActivityBatchThreshold) ||
      (window.localStorage.activityMonitor &&
        JSON.parse(window.localStorage.getItem("activityMonitor")).length >=
          0 &&
        window.localStorage.isIdle == "true" &&
        newtime > lastServerCallThreshold)
    ) {
      sendActivityDataToServer();
    }
  }

  function sendActivityDataToServer() {
    const dataToDump = {
      list: JSON.parse(window.localStorage.getItem("activityMonitor")),
    };

    window.localStorage.setItem("lastServerUpdateTime", getCurrentMilli());
    window.localStorage.removeItem("activityMonitor");

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "STDNT_BULK_UPLOAD_VIGILANCE",
            busiParams: dataToDump,
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
      })
      .catch((res) => {
        //this.notify(false,"Something went wrong !!")
      });
    //since the server call is already made clear the user activity
    clearUserActivity();
  }

  setInterval(function () {
    checkForServerUpdate();
  }, monitoringInterval);

  function isActivityElligible() {
    return (
      (getCurrentMilli() -
        parseInt(window.localStorage.getItem("modeuleStartTime"))) /
        1000 >
      recordActivityThreshold
    );
  }

  function addCurrentModuleDataAndUpdateServer() {
    if (window.localStorage.roleId == 4) {
      if (
        isActivityElligible() &&
        window.location.pathname != "/" &&
        window.location.pathname != "/Login"
      ) {
        userActivity.startDateTime = getCurrentDateTimeFromMilli(
          window.localStorage.getItem("modeuleStartTime")
        );

        const moduleEndTime = getCurrentDateTime();
        userActivity.vigilanceId = 1;
        userActivity.src = "web";
        userActivity.endDateTime = moduleEndTime;
        userActivity.idle = window.localStorage.getItem("totalIdle");

        var currentActive = Math.round(
          (getCurrentMilli() -
            parseInt(window.localStorage.getItem("modeuleStartTime")) -
            parseInt(window.localStorage.getItem("totalIdle")) * 1000) /
            1000
        );

        if (currentActive <= 0) {
          userActivity.active = 0;
          userActivity.idle =
            parseInt(window.localStorage.getItem("totalIdle")) + currentActive;
        } else {
          userActivity.active = currentActive;
        }

        userActivity.studentInstanceId = parseInt(
          window.localStorage.getItem("studentInstanceId")
        );
        userActivity.deviceUUID = window.localStorage.uuid;
        userActivity.moduleId = getModuleId(window.location.pathname);

        let activityDataFromStorage = JSON.parse(
          window.localStorage.getItem("activityMonitor")
        );
        if (activityDataFromStorage === null) {
          activityDataFromStorage = [userActivity];
        } else {
          activityDataFromStorage.push(userActivity);
        }

        window.localStorage.setItem(
          "activityMonitor",
          JSON.stringify(activityDataFromStorage)
        );
        // sendActivityDataToServer();
      } else {
        console.log("wait ill do it later");
      }
    }
  }

  function getCurrentDate() {
    var timeZoneOffsetMilli =
      parseInt(new Date().getTimezoneOffset()) * 60 * 1000;
    var now = new Date(parseInt(getCurrentMilli()) + timeZoneOffsetMilli);
    var y = now.getFullYear();
    var m = now.getMonth() + 1;
    var d = now.getDate();
    var mm = m < 10 ? "0" + m : m;
    var dd = d < 10 ? "0" + d : d;
    return "" + y + "-" + mm + "-" + dd;
  }

  function getCurrentDateTime() {
    var timeZoneOffsetMilli = getOffsetMill();
    var now = new Date(parseInt(getCurrentMilli()) + timeZoneOffsetMilli);
    var h = now.getHours();
    var hh = h < 10 ? "0" + h : h;
    var min = now.getMinutes();
    var minutes = min < 10 ? "0" + min : min;
    var sec = now.getSeconds();
    var seconds = sec < 10 ? "0" + sec : sec;
    const finalDate =
      getCurrentDate() + " " + hh + ":" + minutes + ":" + seconds;
    console.log(finalDate);
    return finalDate;
  }

  function getCurrentDateTimeFromMilli(milli) {
    var timeZoneOffsetMilli = getOffsetMill();
    var now = new Date(parseInt(milli) + timeZoneOffsetMilli);
    var y = now.getFullYear();
    var m = now.getMonth() + 1;
    var d = now.getDate();
    var mm = m < 10 ? "0" + m : m;
    var dd = d < 10 ? "0" + d : d;
    var h = now.getHours();
    var hh = h < 10 ? "0" + h : h;
    var min = now.getMinutes();
    var minutes = min < 10 ? "0" + min : min;
    var sec = now.getSeconds();
    var seconds = sec < 10 ? "0" + sec : sec;
    return (
      "" + y + "-" + mm + "-" + dd + " " + hh + ":" + minutes + ":" + seconds
    );
  }

  function getOffsetMill() {
    var timeZoneOffsetMilli =
      parseInt(new Date().getTimezoneOffset()) * 60 * 1000;
    // var timeZoneOffsetMilli = 0;
    return timeZoneOffsetMilli;
  }

  function getCurrentMilli() {
    return parseInt(Date.now());
  }

  function getAdditionalIdleDuration() {
    let additionalIdle = 0;
    if (
      window.localStorage.getItem("totalIdle") != "undefined" &&
      window.localStorage.getItem("totalIdle") != "null" &&
      parseInt(window.localStorage.getItem("totalIdle")) > 0
    ) {
      let temp = getCurrentMilli() - lastIdle;
      if (parseInt(temp) > 0) {
        additionalIdle = temp;
      }
    }
    return additionalIdle;
  }

  const _onAction = (e) => {
    // console.log("event type logging = " + e.type);
    if (document.hidden !== "undefined" && document.hidden) {
      console.log("tab left");
      addCurrentModuleDataAndUpdateServer();
    }
  };

  const _onActive = (e) => {
    let sum = 0;
    clearInterval(interval);
    newtime = 0;
    window.localStorage.isIdle = false;
    //setLastActive(getCurrentMilli());
    lastActive = getCurrentMilli();

    //when idleDisable is Set user is reading/watching so idle will not be calculated
    if (!window.localStorage.getItem("idleDisable")) {
      const idleDuration = Math.round((getCurrentMilli() - lastIdle) / 1000);
      console.log("inside onActive idle duration is " + idleDuration);
      if (window.localStorage.getItem("totalIdle") == "null") {
        window.localStorage.setItem("totalIdle", 0);
      }
      sum = parseInt(window.localStorage.getItem("totalIdle")) + idleDuration;
      console.log("calculating idle");
      window.localStorage.setItem("totalIdle", sum);
      const currenttimeMilli = getCurrentMilli();
      lastIdle = currenttimeMilli;
      lastActive = currenttimeMilli;
    } else {
      console.log("not calculating idle.......");
      //setLastIdle(getCurrentMilli());
    }
  };

  var newtime = 0;

  const _onIdle = (e) => {
    window.localStorage.isIdle = true;
    let latesttime = 0;
    interval = setInterval(() => {
      newtime++;
    }, 1000);
    lastIdle = getCurrentMilli();

    //new logic starts
    if (window.localStorage.getItem("totalIdle") == "null") {
      window.localStorage.setItem("totalIdle", 0);
    }
    const sum =
      parseInt(window.localStorage.getItem("totalIdle")) + idleThreshold;

    //new logic ends
  };

  return (
    <div>
      <IdleTimer
        ref={(ref) => {
          myRef = ref;
        }}
        element={document}
        onAction={_onAction}
        onActive={_onActive}
        onIdle={_onIdle}
        debounce={250}
        timeout={1000 * idleThreshold}
      />
      {/* your app here */}
    </div>
  );
}
