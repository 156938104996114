import React, { useState, useEffect } from "react";
import Card from "./Cards.js";
import { Grid, CircularProgress } from "@material-ui/core";
import { orderBy } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import { url } from "./store.js";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  cardStyle: {
    "& .makeStyles-card-320": {
      backgroundColor: "black",
    },
  },
}));

export default function NotificationList(props) {
  const classes = useStyle();
  const [general, setGeneralData] = useState([]);
  const [classNoti, setClassNotiData] = useState([]);
  const [error, setError] = React.useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_BRANCH_NOTIFICATION",
              busiParams: { id: window.localStorage.branchId },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let tempEvents = [];

          data.respData[0].respMsg.result.map((item, index) => {
            tempEvents.push({
              dateSort: moment(item.date, "DD-MM-YYYY").toDate(),
              date: moment(item.date, "DD-MM-YYYY")
                .format("DD MMM YYYY")
                .toString(),
              created: item.createdOn,
              task: item.heading,
              status: item.status,
              description: item.description,
            });
          });
          let temp = orderBy(tempEvents, (o) => o.dateSort, "desc");
          setGeneralData(temp);
        })
        .catch((data) => {
          console.error(data);
          setError(true);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_CLASS_NOTIFICATION_BY_BRANCH_SECTION_ID",
              busiParams: {
                admin: window.localStorage.sectionId ? false : "undefined",
                branchId: window.localStorage.sectionId
                  ? 0
                  : window.localStorage.branchId,
                sectionId: window.localStorage.sectionId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let tempEvents = [];

          data.respData[0].respMsg.result.map((item, index) => {
            tempEvents.push({
              dateSort: moment(item.date, "DD-MM-YYYY").toDate(),
              date: moment(item.date, "DD-MM-YYYY")
                .format("DD MMM YYYY")
                .toString(),
              task: item.heading,
              status: item.status,
              created: item.createdOn,
              description: item.description,
              subjectName: item.subjectName,
            });
          });
          let temp = orderBy(tempEvents, (o) => o.dateSort, "desc");
          setClassNotiData(temp);
        })
        .catch((data) => {
          console.error(data);

          setError(true);
        });
    } catch (error) {
      console.error(error);
    }

    setInterval(() => {
      setLoader(false);
    }, 2000);
  }, [props.messageRefresh]);

  return (
    <React.Fragment>
      <Grid item container direction="row" className={classes.subContainer}>
        <Grid item container sm>
          <Card title="General Notification" data={general} error={error} />
        </Grid>
        <Grid item container sm>
          {loader === false ? (
            <Card
              title="Class Specific Notification"
              data={classNoti}
              error={error}
            />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
