import React, { useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Grid,
  Toolbar,
  Typography,
  Card,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import FavoriteBorderSharpIcon from "@material-ui/icons/FavoriteBorderSharp";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import paper from "../assets/paper-1100254/disabledpaper.png";
import { useRouteMatch, useHistory } from "react-router-dom";
import dustbin from "../assets/bin.svg";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { url } from "./store.js";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    width: "200em",
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      height: "37em",
      width: "60em",
      overflow: "hidden",
    },
  },

  dialogwidth: {
    maxWidth: "70em",
    maxHeight: "100em",
  },

  menuCard: {
    height: "13em",
    width: "18em",
    borderRadius: 12,
    cursor: "pointer",
  },
  deleteButton: {
    "&:hover": {
      color: "red",
    },
  },
}));

const ForMappingGrids = (props) => {
  const classes = useStyle();
  let dummyObj = {
    title: "",
    img: paper,
    link: "",
    favId: "",
  };
  let remainingLength = props.favGridLength - props.favTiles.length;
  console.log("the remaining length is = " + remainingLength);
  console.log("total fav items " + props.favTiles.length);
  if (remainingLength <= props.favGridLength) {
    for (var i = 0; i < remainingLength; i++) {
      props.favTiles.push(dummyObj);
    }
    return props.favTiles.map((k) => (
      <Grid item xs={4} style={{ paddingRight: 0 }}>
        <Card
          className={classes.menuCard}
          style={{ textDecoration: "none" }}
          raised={true}
        >
          <Grid container direction="column" justify="flex-end" xs={12}>
            <Grid
              item
              onClick={() => {
                if (k.link !== "") {
                  props.tabChangeFromFav(k.tab);
                  setTimeout(() => {
                    props.handleFavItemClick(k.link);
                  }, 1000);
                }
              }}
            >
              {k.title === "" ? (
                <img
                  src={k.img}
                  alt="test"
                  style={{ height: "13em", width: "100%" }}
                />
              ) : (
                <img
                  src={k.img}
                  alt="test"
                  style={{ height: "13em", width: "100%" }}
                />
              )}
            </Grid>
            <Grid
              item
              container
              direction="column"
              style={{ position: "absolute" }}
              justify="center"
            >
              {k.title === "" ? null : (
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                  style={{
                    background: "rgba(0,0,0)",
                    width: "18em",
                    minHeight: "3.4em",
                    borderBottomLeftRadius: 12,
                    borderBottomRightRadius: 12,
                  }}
                >
                  <Grid
                    item
                    onClick={() => {
                      props.handleFavItemClick(k.link);
                      props.tabChangeFromFav(k.tab);
                    }}
                  >
                    {" "}
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "1.1rem",
                        fontWeight: 300,
                        marginLeft: "0.5em",
                      }}
                    >
                      {k.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    onClick={(e) =>
                      props.deleteFav(e, k.favId, {
                        vertical: "top",
                        horizontal: "center",
                      })
                    }
                  >
                    <IconButton
                      alt=""
                      height="17em"
                      width="17em"
                      style={{ color: "white" }}
                      className={classes.deleteButton}
                      id={k.favId}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    ));
  }
};

export default function FavouritesDialog(props) {
  const classes = useStyle();
  const theme = useTheme();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const favGridLength = 6;

  const deleteFav = (e, favId, newState) => {
    console.log(e);
    e.preventDefault();
    console.log("the id has been received =" + favId);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_DELETE_FAVOURITE",
            busiParams: {
              id: favId,
              userId:
                window.localStorage.roleId == 5
                  ? window.localStorage.parentUserId
                  : window.localStorage.userId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("going to priunt the data");
        console.log(data.result);
        setOpen(true);
        props.handleClose();
        console.log("the favTiles is = " + JSON.stringify(props.favTiles));
        // data.respData[0].respMsg.result.map((item, index) => {});
        props.setForcedRender(!props.forcedRender);
      })
      .catch((data) => {
        console.error(data);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleFavItemClick = (link) => {
    console.log(link);
    if (link === " " || link === "") {
      console.log("cant go anywhere");
    } else {
      history.push(link);
    }
    props.handleClose(true);
  };

  return (
    <React.Fragment>
      {/* {console.log("the props have been recieved = " + props.tabChangeFromFav)} */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Favourite has been deleted
        </Alert>
      </Snackbar>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        classes={{ paperWidthSm: classes.dialogwidth }}
        className={classes.dialogContainer}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          Favourites
        </DialogTitle>
        <DialogContent style={{ width: "60em" }}>
          <Grid container direction="row" justify="center" spacing={3}>
            <ForMappingGrids
              favTiles={props.favTiles}
              deleteFav={deleteFav}
              handleFavItemClick={handleFavItemClick}
              favGridLength={favGridLength}
              tabChangeFromFav={props.tabChangeFromFav}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
