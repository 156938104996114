import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Backdrop,
    CircularProgress,
    Button,
    Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { url, makeReqBody } from "../Ui/store.js";
import ChapterSectionDetailed from "./ChapterSectionDetailed";
import SuggestionBox from "./SuggestionBox";
import ChapterHorizontalChart from "./ChapterHorizontalChart";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useTheme } from "@material-ui/styles";
import Overall from "../assets/overall.svg"
import Timetaken from "../assets/timetaken.svg"
import Attempt from "../assets/attempt.svg"

import Chart from 'react-apexcharts'
import ChartMaker from "../Ui/ChartMaker.js";
import SmartAnalysisCard from "./SmartAnalysisCard.js";
import SmartCircleChart from "./SmartCircleChart.js";


const useStyle = makeStyles((theme) => ({
    subContainer: {
        ...theme.typography.subContainer,
    },
    customDropdown: {
        '& .MuiInputLabel-outlined': {
            zIndex: 1,
            transform: 'translate(14px, 14px) scale(1)',
            pointerEvents: 'none',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px 14px',
        },
        '& .Mui-disabled': {
            background: '#aaa',
        },

        "& .MuiFormLabel-root": {
            color:
                theme.typography.myTheme.themeName === "Night"
                    ? theme.palette.common.tabFont
                    : "inherit",
        },
        "& .MuiInputBase-input": {
            color:
                theme.typography.myTheme.themeName === "Night"
                    ? theme.palette.common.tabFont
                    : "inherit",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor:
                theme.typography.myTheme.themeName === "Night"
                    ? theme.palette.common.tabFont
                    : "inherit",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor:
                theme.typography.myTheme.themeName === "Night"
                    ? theme.palette.common.tabFont
                    : "inherit",
        },
    },
    circleCbx: {
        width: '130px',
        height: '130px',
        margin: '10px auto',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: "#fff",
    },
    chapterDetailWrapper: {
        // background: '#fff',
        margin: '8px 0',
        width: '100%',
        justifyContent: 'space-between'
    },
    chapterDetailWrapperInner: {
        background: window.localStorage.theme === "Night" ? "#cccccc" : "#ffffff",
        padding: '15px 0',
        boxShadow: '0px 0px 9px #00000069',
        borderRadius: '7px',
        maxWidth: '49%',
        flexBasis: '49%',
    }


}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function SubjectWiseDetailed(props) {

    const classes = useStyle();
    const theme = useTheme()
    const [subject, setSubject] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState(0);
    const [identifierMarks, setIdentifierMarks] = useState([]);
    const [chapterHorizontalBarMarks, setChapterHorizontalBarMarks] = useState([]);
    const [message, setMessage] = useState("");
    const [snack, setSnack] = useState(false);
    const [status, setStatus] = useState("");
    const [backLoader, setBackLoader] = useState(false);
    const [hideChapter, setHideChapter] = useState(false);
    const [cId, setCid] = useState(0);
    const [performanceData, setPerformanceData] = useState(null)
    const [attemptChartData, setAttemptChartData] = useState({
        labels: ["QUICK ATTEMPTS", "ON-TIME-ATTEMPTS", "DELAYED ATTEMPTS"],
        datasets: [{
            label: "Overall",
            backgroundColor: [
                'rgba(1, 190, 46, 0.5)',
                'rgba(251, 170, 43, 0.5)',
                'rgba(255, 0, 0, 0.5)',
            ],
            borderWidth: 1,
            borderColor: [
                'rgba(1, 190, 46, 0.5)',
                'rgba(251, 170, 43, 0.5)',
                'rgba(255, 0, 0, 0.5)',
            ],
            barThickness: "flex",
            barPercentage: 0.5,
            categoryPercentage: 0.3,
            borderSkipped: "left",
            data: []
        }, {
            label: "Subject",
            backgroundColor: [
                'rgba(1, 190, 46, 1)',
                'rgba(251, 170, 43, 1)',
                'rgba(255, 0, 0, 1)',
            ],
            borderWidth: 1,
            borderColor: [
                'rgba(1, 190, 46, 1)',
                'rgba(251, 170, 43, 1)',
                'rgba(255, 0, 0, 1)',
            ],
            barThickness: "flex",
            barPercentage: 0.5,
            categoryPercentage: 0.3,
            borderSkipped: "left",
            data: []
        }],
    });
    const [dataSet, setDataSet] = useState(window.localStorage.teacherType === "Class" || window.localStorage.roleId === "4" || window.localStorage.roleId === "5" ? {
        labels: ["Correct", "Partial Correct", "Wrong", "Unattempt"],
        datasets: [
            {
                label: "Overall",
                backgroundColor: ["#01be2eab", "#498bff9c", "#ff520096", "#80808085"],
                borderWidth: 1,
                borderColor: ["#01be2eab", "#498bff9c", "#ff520096", "#80808085"],
                barThickness: "flex",
                barPercentage: 0.5,
                categoryPercentage: 0.3,
                borderSkipped: "left",
                data: []
            },
            {
                label: "Subject",
                backgroundColor: ["#00a427", "#498bff", "#ff0000", "#808080"],
                borderWidth: 1,
                borderColor: ["#00a427", "#498bff", "#ff0000", "#808080"],
                barThickness: "flex",
                barPercentage: 0.5,
                categoryPercentage: 0.3,
                borderSkipped: "left",
                data: []
            },
        ],
    } : {
        labels: ["Correct", "Partial Correct", "Wrong", "Unattempt"],
        datasets: [{
            label: "Subject",
            backgroundColor: ["#00a427", "#498bff", "#ff0000", "#808080"],
            borderWidth: 1,
            borderColor: ["#00a427", "#498bff", "#ff0000", "#808080"],
            barThickness: "flex",
            barPercentage: 0.5,
            categoryPercentage: 0.3,
            borderSkipped: "left",
            data: []
        }],
    });
    const [typologyChart, setTypologyChart] = useState({});
    const [overallIdentifierMarks, setOverallIdentifierMarks] = useState([]);
    const [examList, setExamList] = useState([]);
    const [selectedExamId, setSelectedExamId] = useState(0)



    useEffect(() => {
        setBackLoader(true);
        try {
            fetch(
                url,
                makeReqBody("SCHOOL_SMART_QBT_GET_EXAM_BY_EXAMTERM", {
                    examTermId: props.examTermId,
                    subjectId: window.localStorage.teacherType == "Subject" ? window.localStorage.subjectId : 0,
                })).then((response) => response.json())
                .then((data) => {
                    setExamList(data.respData[0].respMsg.result)
                    setBackLoader(false);
                })
                .catch((data) => {
                    console.error(data);
                    setBackLoader(false);
                });
        } catch (error) {
            console.error(error);
            setBackLoader(false);
        }
    }, [props.examTermId, props.render]);


    useEffect(() => {
        if (selectedExamId !== 0) {
            try {
                fetch(
                    url,
                    makeReqBody("SCHOOL_GET_SUBJECTTEACHER_RESPONSE", {
                        examTermId: props.examTermId,
                        examId: selectedExamId
                    })).then((response) => response.json())
                    .then((data) => {
                        setSubject(data.respData[0].respMsg.result)
                        if (window.localStorage.teacherType == "Subject") {
                            setSelectedSubject(parseInt(window.localStorage.subjectId))
                        }
                    })
                    .catch((data) => {
                        console.error(data);
                    });
            } catch (error) {
                console.error(error);
            }
        }
    }, [selectedExamId]);

    useEffect(() => {
        if (selectedExamId !== 0) {
            try {
                setBackLoader(true);
                fetch(
                    url,
                    makeReqBody(props.studentInstanceId !== 0 ? "SCHOOL_SMART_QBT_GET_EXAM_ANALYSIS_BY_STUDENT" : "SCHOOL_SMART_QBT_CLASS_PERFORMANCE_ANALYSIS", {
                        examId: selectedExamId,
                        examTermId: props.examTermId,
                        sectionId: window.localStorage.sectionId,
                        studentInstanceId: window.localStorage.roleId == 3 ? props.studentInstanceId : window.localStorage.studentInstanceId,
                        subjectId: window.localStorage.teacherType == "Subject" ? window.localStorage.subjectId : selectedSubject,
                        branchId: window.localStorage.branchId
                    })).then((response) => response.json())
                    .then((data) => {
                        if (data.respData[0].respMsg === null) {
                            setPerformanceData(null)
                        } else {
                            setPerformanceData(data.respData[0].respMsg.result);
                        }
                        console.log("data", data.respData[0].respMsg.result)
                        setOverallIdentifierMarks(data.respData[0].respMsg.result.identifierDetailedMarks)
                        let resdata = data.respData[0].respMsg.result;

                        let attemptData = { ...attemptChartData };
                        let attData = [resdata.quickAttemt, resdata.onTimeAttempt, resdata.delayedAttempt]

                        let accuracyData = [resdata.correctQuesNo, resdata.partialCorrect, resdata.wrongQuesNo, resdata.unattemptedNo]
                        let dataset = { ...dataSet };
                        if (window.localStorage.teacherType === "Class" || window.localStorage.roleId === "4" || window.localStorage.roleId === "5") {
                            if (selectedSubject === 0) {
                                dataset.datasets[0].data = accuracyData
                                dataset.datasets[1].data = [];
                                attemptData.datasets[0].data = attData;
                                attemptData.datasets[1].data = [];
                            } else {
                                dataset.datasets[1].data = accuracyData;
                                dataset.datasets[1].label = resdata.subjectName;
                                attemptData.datasets[1].data = attData;
                                attemptData.datasets[1].label = resdata.subjectName;
                            }
                        } else {
                            if (selectedSubject !== 0) {
                                dataset.datasets[0].data = accuracyData
                                attemptData.datasets[0].data = attData;
                            }
                        }
                        setDataSet(dataset)
                        setAttemptChartData(attemptData)


                        let typData = []
                        let labelsName = [];
                        let bgColor = [];
                        let isTypoNegative = false;
                        data.respData[0].respMsg.result.typologyDetailedMarks.map(x => {
                            labelsName.push(x.typologyName);
                            typData.push(x.perc);
                            if (parseInt(x.perc) >= 0) {
                                isTypoNegative = false;
                            } else {
                                isTypoNegative = true;
                            }
                            if (x.perc > 50) {
                                bgColor.push("#00a427")
                            } else {
                                bgColor.push("#ff0000")
                            }
                        })
                        let typologyDataSet = {
                            labels: labelsName,
                            datasets: [{
                                data: typData,
                                backgroundColor: bgColor,
                                borderColor: "#498bff",
                            }],
                            isNegative: isTypoNegative
                        };
                        setTypologyChart(typologyDataSet)

                        setBackLoader(false);
                    })
                    .catch((data) => {
                        console.error(data);
                        setBackLoader(false);
                    });
            } catch (error) {
                console.error(error);
                setBackLoader(false);
            }
        }
    }, [selectedExamId, selectedSubject]);


    useEffect(() => {
        if (selectedSubject !== 0) {
            try {
                fetch(
                    url,
                    makeReqBody(props.studentInstanceId === 0 ? "SCHOOL_SMART_QBT_CLASS_PERFO_CHAPTER_IDENTIFIER_WISE_DETAILS" : "SCHOOL_SMART_QBT_CHAPTER_IDENTIFIER_WISE_DETAILS", {
                        examTermId: props.examTermId,
                        examId: selectedExamId,
                        sectionId: window.localStorage.sectionId,
                        studentInstanceId: window.localStorage.studentInstanceId || props.studentInstanceId,
                        subjectId: selectedSubject,
                        chapterId: 0,
                        branchId: window.localStorage.branchId
                    })).then((response) => response.json())
                    .then((data) => {
                        setIdentifierMarks(data.respData[0].respMsg.result.identifierDetailedMarks);
                        setChapterHorizontalBarMarks(data.respData[0].respMsg.result.chaptersDetailedMarks);
                    })
                    .catch((data) => {
                        console.error(data);
                    });
            } catch (error) {
                console.error(error);
            }
        }
    }, [selectedSubject, selectedExamId]);




    return (
        <>
            <Grid item container direction="row" spacing={2} alignItems="center" style={{ marginTop: props.marginTop }}>
                {props.index === 0 && window.localStorage.variantId === "7" &&
                    <Grid item container >
                        <Typography variant="body2">Note: All the analysis for mock exam is based on real time, it may vary based on number of students taking the exam at a given point of time.</Typography>
                    </Grid>}
                <Grid item sm={3} style={{ marginBottom: "0.1em" }}>
                    <TextField
                        id="ExamList"
                        label="Exam List*"
                        select
                        name="examId"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={selectedExamId}
                        className={classes.customDropdown}
                        disabled={hideChapter}
                        onChange={(e) => {
                            setSelectedExamId(e.target.value);
                            setSelectedSubject(0)
                            console.log(e.target.value);
                        }}
                    >
                        {examList && examList.map((item, index) => (
                            <MenuItem key={index} value={item.examId}>
                                {item.smartQbtExamName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {window.localStorage.teacherType != "Subject" &&
                    <Grid item sm={3} style={{ marginBottom: "0.1em" }}>
                        <TextField
                            id="subject"
                            label="Subject*"
                            select
                            name="subjectId"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={selectedSubject}
                            className={classes.customDropdown}
                            disabled={hideChapter}
                            onChange={(e) => {
                                setSelectedSubject(e.target.value);
                            }}
                        >
                            <MenuItem value={0}>Overall</MenuItem>
                            {subject && subject.map((item, index) => (
                                <MenuItem key={index} value={item.subjectId}>
                                    {item.subjectName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>}
                <Grid item>
                    {hideChapter &&
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ color: "white" }}
                            onClick={() => setHideChapter(false)}
                        >
                            Back
                        </Button>}
                </Grid>

                {performanceData !== null && selectedExamId !== 0 &&
                    <>
                        <Grid item container>
                            <Grid item container direction="row" style={{ marginTop: '15px' }} spacing={3}>
                                <Grid item sm>
                                    <SmartAnalysisCard
                                        showRange={true}
                                        marginTop="-35px"
                                        color="#00a427"
                                        width={`${performanceData.overallPercentage}%`}
                                        val={performanceData.overAllMarks}
                                        name="OVERALL SCORE"
                                        showImg={true}
                                        img={Overall}
                                    />
                                </Grid>

                                <Grid item sm>
                                    <SmartAnalysisCard
                                        showRange={true}
                                        marginTop="-40px"
                                        color="#498bff"
                                        width={`${performanceData.overallTimePercentage}%`}
                                        val={performanceData.overAllTime}
                                        name="TIME TAKEN"
                                        showImg={true}
                                        img={Timetaken}
                                    />
                                </Grid>

                                <Grid item sm>
                                    <SmartAnalysisCard
                                        showRange={true}
                                        marginTop="-33px"
                                        color="#fbaa2b"
                                        width={`${performanceData.attemptQuestion}%`}
                                        val={performanceData.overAllAttempt}
                                        name="ATTEMPT"
                                        showImg={true}
                                        img={Attempt}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {selectedSubject !== 0 &&
                            <Grid item container style={{ marginTop: '15px' }}>
                                <Grid item container>
                                    <Typography variant="h4" style={{ textTransform: "uppercase" }}>{performanceData.subjectName}</Typography>
                                </Grid>
                                <Grid item container direction="row" style={{ marginTop: '15px' }} spacing={3}>
                                    <Grid item sm>
                                        <SmartAnalysisCard
                                            showRange={true}
                                            marginTop="-35px"
                                            color="#00a427"
                                            width={`${performanceData.spercentage}%`}
                                            val={performanceData.subWiseMarks}
                                            name="OVERALL SCORE"
                                        />
                                    </Grid>
                                    <Grid item sm>
                                        <SmartAnalysisCard
                                            showRange={true}
                                            marginTop="-40px"
                                            color="#498bff"
                                            width={`${performanceData.subWiseTimePercentage}%`}
                                            val={performanceData.subWiseTime}
                                            name="TIME TAKEN"
                                        />
                                    </Grid>

                                    <Grid item sm>
                                        <SmartAnalysisCard
                                            showRange={true}
                                            marginTop="-33px"
                                            color="#fbaa2b"
                                            val={performanceData.subWiseAttempt}
                                            width={`${performanceData.sattemptQuestion}%`}
                                            name="ATTEMPT"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {!hideChapter &&
                            <>
                                <Grid item container direction="row" style={{ marginTop: '15px' }} spacing={3} className={classes.chapterDetailWrapper}>
                                    <Grid item sm={6} className={classes.chapterDetailWrapperInner}>
                                        <Typography variant="h5" style={{ fontWeight: "bold" }}>ACCURACY</Typography>
                                        <Grid item style={{ marginTop: "1em", }}>
                                            <ChartMaker
                                                height={132}
                                                dataset={dataSet}
                                                percentage={true}
                                                legends={false}
                                                showBarVal={true}
                                            />
                                        </Grid>
                                        {(window.localStorage.teacherType === "Class" || window.localStorage.roleId === "4" || window.localStorage.roleId === "5") ?
                                            <Typography variant="body2" style={{ fontWeight: "600", textAlign: "center" }}><span style={{ paddingRight: "10px" }}>Bar1: Overall</span> <span>Bar2: Subject</span></Typography>
                                            : <Typography variant="body2" style={{ fontWeight: "600", textAlign: "center" }}><span style={{ paddingRight: "10px" }}>Bar1:  Subject</span></Typography>
                                        }
                                    </Grid>
                                    <Grid item sm={6} className={classes.chapterDetailWrapperInner} >
                                        <Typography variant="h5" style={{ fontWeight: "bold" }}>ATTEMPTS</Typography>
                                        <Grid item container>
                                            <ChartMaker
                                                height={132}
                                                dataset={attemptChartData}
                                                percentage={true}
                                                legends={false}
                                                showBarVal={true}
                                            />
                                        </Grid>
                                        {(window.localStorage.teacherType === "Class" || window.localStorage.roleId === "4" || window.localStorage.roleId === "5") ?
                                            <Typography variant="body2" style={{ fontWeight: "600", textAlign: "center" }}><span style={{ paddingRight: "10px" }}>Bar1: Overall</span> <span>Bar2: Subject</span></Typography>
                                            : <Typography variant="body2" style={{ fontWeight: "600", textAlign: "center" }}><span style={{ paddingRight: "10px" }}>Bar1:  Subject</span></Typography>
                                        }
                                    </Grid>
                                </Grid>

                                {selectedSubject === 0 &&
                                    <Grid item container direction="row-reverse" style={{ marginTop: '15px' }} spacing={3} className={classes.chapterDetailWrapper}>
                                        <Grid item sm={6} style={{ alignContent: "flex-start" }} container className={classes.chapterDetailWrapperInner} >
                                            <Grid item container>
                                                <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>Question Identifier Wise</Typography>
                                            </Grid>
                                            {overallIdentifierMarks && overallIdentifierMarks.map((item, index) => {
                                                return (
                                                    <Grid key={index} item sm={6}>
                                                        <Typography component="div" style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <SmartCircleChart
                                                                percentage={parseFloat(item.perc)}
                                                                textName={item.identifierName}
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>

                                        <Grid item sm={6} container direction="column" style={{ padding: "0 10px" }}>
                                            <Grid item container className={classes.chapterDetailWrapperInner} style={{ maxWidth: "100%", padding: "12px", }}>
                                                <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>TYPOLOGY</Typography>
                                                <Grid item container style={{ marginTop: "12px" }}>
                                                    <ChartMaker
                                                        height={132}
                                                        dataset={typologyChart}
                                                        percentage={true}
                                                        legends={false}
                                                        showBarVal={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item container component={Paper} style={{ padding: "12px", boxShadow: "0px 0px 9px #00000069" }}>
                                                <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>AI SUGGESTION</Typography>
                                                <Grid item container style={{ height: "210px" }}>
                                                </Grid>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>}
                            </>
                        }


                        {selectedSubject !== 0 && !hideChapter &&
                            <>
                                <Grid item container direction="row" className={classes.chapterDetailWrapper} spacing={3}>
                                    <Grid item sm={6} className={classes.chapterDetailWrapperInner}>
                                        <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>Chapter Wise Performance</Typography>
                                        {chapterHorizontalBarMarks && chapterHorizontalBarMarks.map((item, index) => {
                                            return (
                                                <ChapterHorizontalChart
                                                    name={item.chapterName}
                                                    val={item.overAllPer}
                                                    bgChart={item.percAbovePassingMarks === true ? "#01be2e" : "#ca1923"}
                                                    bgCircleCount={item.percAbovePassingMarks === true ? "#52de6f" : "#f65760"}
                                                    width={`${item.overAllPer}%`}
                                                    pointer={true}
                                                    count={index + 1}
                                                    barClick={() => {
                                                        setCid(item.chapterId);
                                                        setHideChapter(true);
                                                    }}
                                                />
                                            )
                                        })}
                                    </Grid>
                                    <Grid item sm={6} container className={classes.chapterDetailWrapperInner} >
                                        <Grid item container>
                                            <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>Question Identifier Wise</Typography>
                                        </Grid>
                                        {identifierMarks && identifierMarks.map((item, index) => {
                                            return (
                                                <Grid key={index} item sm={6}>
                                                    <Typography component="div" style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <SmartCircleChart
                                                            percentage={parseFloat(item.perc)}
                                                            textName={item.identifierName}
                                                        />
                                                    </Typography>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>

                                <Grid item container direction="row" className={classes.chapterDetailWrapper} spacing={3}>
                                    <Grid item sm={6} className={classes.chapterDetailWrapperInner}>
                                        <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>TYPOLOGY</Typography>
                                        <Grid item container style={{ marginTop: "12px" }}>
                                            <ChartMaker
                                                height={132}
                                                dataset={typologyChart}
                                                percentage={true}
                                                legends={false}
                                                showBarVal={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item sm={6} className={classes.chapterDetailWrapperInner}>
                                        <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000', marginBottom: '15px' }}>AI SUGGESTION</Typography>
                                    </Grid> */}
                                </Grid>
                                <SuggestionBox
                                    red={performanceData && performanceData.suggestions && performanceData.suggestions.Red}
                                    yellow={performanceData && performanceData.suggestions && performanceData.suggestions.Yellow}
                                    green={performanceData && performanceData.suggestions && performanceData.suggestions.Green}
                                />
                            </>}
                    </>}

                {hideChapter &&
                    <ChapterSectionDetailed
                        render={hideChapter}
                        cId={cId}
                        examId={performanceData.examId}
                        subjectId={selectedSubject}
                        examTermId={props.examTermId}
                        sectionId={props.sectionId}
                        studentInstanceId={props.studentInstanceId}
                    />
                }
            </Grid>

            <Backdrop className={classes.backdrop} open={backLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={snack}
                autoHideDuration={4500}
                onClose={() => setSnack(false)}
            >
                <Alert severity={status}>{message}</Alert>
            </Snackbar>
        </>
    )
}
