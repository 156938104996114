import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Grid } from '@material-ui/core'

const AreaChart = (props) => {

    const [data, setData] = useState(
        {
            series: [],
            options: {
                chart: {
                    height: 290,
                    type: 'area',
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    }
                },
                stroke: {
                    curve: 'smooth'
                },
                legend: {

                    offsetX: 0,
                    offsetY: 0,
                },
                xaxis: {
                    categories: [],
                    labels: {
                        style: {
                            fontSize: '10px'
                        }
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + "%";
                        }
                    },
                    max: props.data.labels && props.data.labels.length !== 0 && 100,
                    tickAmount: props.data.isNegative ? 6 : 4,
                    min: props.data.isNegative ? -50 : 0,
                    axisBorder: {
                        show: true,
                        color: '#e5e5e5',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        color: '#e5e5e5',
                        width: 6,
                        offsetX: 0,
                        offsetY: 1
                    },
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    padding: {
                        bottom: 40
                    }
                },
                theme: window.localStorage.theme === "Night" ? {
                    mode: 'dark',
                    palette: 'palette4',
                    monochrome: {
                        enabled: false,
                        color: '#546E7A',
                        shadeTo: 'dark',
                        shadeIntensity: 0.65
                    },
                } : {
                    mode: 'light',
                    palette: 'palette1',
                    monochrome: {
                        enabled: false,
                        color: '#255aee',
                        shadeTo: 'light',
                        shadeIntensity: 0.65
                    },
                }

            },

        }
    )
    const [state, setState] = useState(props.data);
    const [width, setWidth] = useState('100%');


    useEffect(() => {
        let ndata = props.data && props.data.datasets !== undefined ? props.data.datasets : [];
        let colors = [];
        ndata = ndata.map(item => ({
            ...item,
            name: item.label
        }))
        ndata.map(item => (
            colors.push(item.borderColor)
        ))

        // console.log('props.data', props.data)
        setData({
            ...data,
            series: ndata,

            options: {
                labels: state.labels,
                // colors: colors,
            }
        })
    }, [])


    // console.log('sssssssssss', props.data)
    return (
        <Grid
            item
            container
            style={{
                backgroundColor: window.localStorage.theme === "Night" ? "#424242" : "#ffffff",
                marginTop: props.margintop,
                width: props.width,
                // overflowY: "auto",
                scrollbarWidth: "none",
            }}
        >
            <Grid
                item
                container
                // style={{ overflowY: "auto", scrollbarWidth: "none" }}
            >
                <div style={{ width: '100%' }}>
                    <Chart
                        options={data.options}
                        series={data.series}
                        type="area"
                        width={width}
                        height={props.height}
                    />
                </div>
            </Grid>
        </Grid>

    )
};

export default AreaChart
