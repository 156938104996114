import React, { useState, useEffect, useLayoutEffect, createContext, useMemo } from "react";
import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, Typography } from "@material-ui/core";
import SessionManagement from "./idleTimer";

import { Route, Switch, withRouter, useHistory } from "react-router-dom";
import {
  ThemeEnergy,
  ThemeDay,
  ThemStaticPage,
  ThemeNight,
} from "./Components/Ui/Theme.js";
import LandingPageStudent from "./Components/LandingPageStudent.js";
import Login from "./Components/Login/login";
import ForgotPassword from "./Components/Login/forgotPassword";
import ChangePassword from "./Components/Login/changedPassword";
import MomentUtils from "@date-io/moment";
import ResourcesGolden from "./Components/resourcesGolden.js";
import ToDoTracker from "./Components/ToDoTracker.js";
import UploadSection from "./Components/UploadSection.js";
import ExamMode from "./Components/ExamMode.js";
import Manage from "./Components/Manage.js";
import StudentGroup from "./Components/StudentsGroup.js";
import Settings from "./Components/Settings.js";
import Header from "./Components/Ui/Header.js";
import StaticPage from "./Components/StaticPage/landingPage";
import moment from "moment";
import { url } from "./Components/Ui/store.js";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import PrivacyPolicy from "./Components/StaticPage/PrivacyPolicy";
import Terms from "./Components/StaticPage/Terms";
import ContributeQuestions from "./Components/StaticPage/ContributeQuestions";
import QuestionMapSmartQBT from "./Components/Ui/QuestionMapSmartQBT";
import SystemSmartExam from "./Components/Exam/SystemSmartExam";
import Instructions from "./Components/Ui/Instructions";
import { UserContext } from "./Context"

const useBeforeFirstRender = (fun) => {
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => setHasRendered(true), [hasRendered]);

  if (!hasRendered) {
    fun();
  }
};

function App(props) {
  // const [value, setValue] = useState(0);
  const fav = useFav();
  const history = useHistory();
  const [openForget, setOpenForget] = useState(false);
  const [forcedRender, setForcedRender] = useState(false);
  const [themePaletteOpen, setThemePalleteOpen] = React.useState(false);
  const [favDialogOpen, setDialogOpen] = React.useState(false);
  const [currentPathname, setCurrentPathname] = React.useState();
  const [theme, setTheme] = React.useState(
    window.localStorage.theme === "Night"
      ? ThemeNight
      : window.localStorage.theme === "Day"
        ? ThemeDay
        : ThemeEnergy
  );
  const [dataFromChild, setDataFromChild] = useState({})

  function getCurrentMilli() {
    return parseInt(Date.now());
  }

  const handleCloseForget = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenForget(false);
  };

  const favDialogfunc = (dialogState) => {
    console.log(dialogState);
    setDialogOpen(dialogState);
  };

  const themeDialogfunc = (dialogState) => {
    console.log(dialogState);
    setThemePalleteOpen(dialogState);
  };

  const handleCurrentPathnamefunc = (dataFromHeader) => {
    console.log("data from header" + dataFromHeader);
    setCurrentPathname(dataFromHeader);
  };

  const handleSetTheme = (themeReceived) => {
    setTheme(themeReceived);
  };
  useBeforeFirstRender(() => {
    if (window.localStorage.sectionId) {
      console.log("continue");
    } else if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "" &&
      window.location.pathname !== "/ContributeQuestions" &&
      window.location.pathname !== "/PrivacyPolicy" &&
      window.location.pathname !== "/Terms" &&
      window.location.pathname !== " "
    ) {
      history.push("/Login");
    }
  }, []);

  function sendActivityDataToServer() {
    const dataToDump = {
      list: JSON.parse(window.localStorage.getItem("activityMonitor")),
    };
    console.log("the datatoDump is = " + dataToDump);
    window.localStorage.setItem("lastServerUpdateTime", getCurrentMilli());
    window.localStorage.removeItem("activityMonitor");

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "STDNT_BULK_UPLOAD_VIGILANCE",
            busiParams: dataToDump,
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
      })
      .catch((res) => {
        //this.notify(false,"Something went wrong !!")
      });
    //since the server call is already made clear the user activity
    //  clearUserActivity();
  }

  useEffect(() => {
    window.addEventListener("mousemove", (ev) => {
      if (window.localStorage.theme === "Auto") {
        if (
          moment().unix() > window.localStorage.startTimeForTheme &&
          moment().unix() < window.localStorage.endTimeForTheme
        ) {
          //console.log("Auto setting selected setting day them");
          setTheme(ThemeDay);
        } else {
          //console.log("Auto setting selected setting night them");
          setTheme(ThemeNight);
        }
      }
    });
  });
  useEffect(() => {
    //console.log(JSON.parse(window.localStorage.getItem("Theme")));
  }, []);


  const [user, setUser] = useState(JSON.parse(window.localStorage.userData || null));

  const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  const getDataFromChild = (data) => {
    console.log("child", data);
    setDataFromChild(data)
  }

  return (
    <UserContext.Provider value={{providerValue, getDataFromChild, dataFromChild}}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          {window.location.pathname !== "" ||
            window.location.pathname !== "/" ||
            window.location.pathname !== "/Login" ||
            window.location.pathname !== "/UploadSection/ExamWorks/Instructions" ||
            window.location.pathname !== "/UploadSection/ExamWorks/SystemSmartExam" ||
            window.location.pathname !== "/ContributeQuestions" ? (
            <Header
              fav={fav.fav}
              removeProps={fav.deleteFav}
              dataFromHeader={fav.favReceived}
              favDialogfunc={favDialogfunc}
              favDialogOpen={favDialogOpen}
              themeDialogfunc={themeDialogfunc}
              themePaletteOpen={themePaletteOpen}
              handleCurrentPathname={handleCurrentPathnamefunc}
              setTheme={setTheme}
              myTheme={theme}
              forcedRender={forcedRender}
              setForcedRender={setForcedRender}
              sendActivityDataToServer={sendActivityDataToServer}
            />
          ) : undefined}

          {window.localStorage.roleId == 4 ? (
            <SessionManagement></SessionManagement>
          ) : undefined}

          <Switch>
            <Route
              exact
              path="/"
              component={() => (
                <ThemeProvider theme={ThemStaticPage}>
                  <StaticPage setTheme={handleSetTheme} />
                </ThemeProvider>
              )}
            />
            <Route
              exact
              path="/ExamMode/EExam/QuestionMapping"
              component={() => (
                <QuestionMapSmartQBT />
              )}
            />
            <Route
              exact
              path="/UploadSection/ExamWorks/Instructions"
              component={() => (
                <Instructions />
              )}
            />

            <Route
              exact
              path="/UploadSection/ExamWorks/SystemSmartExam"
              component={() => (
                <SystemSmartExam />
              )}
            />


            <Route
              exact
              path="/ContributeQuestions"
              component={() => (
                <ThemeProvider theme={ThemStaticPage}>
                  <ContributeQuestions setTheme={handleSetTheme} />
                </ThemeProvider>
              )}
            />

            <Route
              exact
              path="/index.html"
              component={() => <StaticPage setTheme={handleSetTheme} />}
            />
            <Route
              exact
              path="/PrivacyPolicy"
              component={() => <PrivacyPolicy setTheme={handleSetTheme} />}
            />
            <Route
              exact
              path="/Terms"
              component={() => <Terms setTheme={handleSetTheme} />}
            />
            <Route
              path="/Login"
              default
              component={() => (
                <Login
                  openForget={openForget}
                  setTheme={handleSetTheme}
                  handleCloseForget={handleCloseForget}
                />
              )}
            />
            <Route
              exact
              path="/ForgotPassword"
              component={() => (
                <ForgotPassword
                  openForget={openForget}
                  setOpenForget={setOpenForget}
                  handleCloseForget={handleCloseForget}
                />
              )}
            />
            <Route exact path="/ChangePassword" component={ChangePassword} />

            <Route
              path="/resources"
              component={() => (
                <ResourcesGolden
                  setFavProps={fav.favReceived}
                  allFavs={fav.fav}
                  removeProps={fav.deleteFav}
                />
              )}
            />
            <Route
              path="/LandingPageStudent"
              component={() => (
                <LandingPageStudent
                  setFavProps={fav.favReceived}
                  allFavs={fav.fav}
                  removeProps={fav.deleteFav}
                />
              )}
            />

            <Route
              path="/ToDoTracker"
              component={() => (
                <ToDoTracker
                  setFavProps={fav.favReceived}
                  allFavs={fav.fav}
                  removeProps={fav.deleteFav}
                />
              )}
            />
            <Route
              path="/UploadSection"
              component={() => (
                <UploadSection
                  setFavProps={fav.favReceived}
                  allFavs={fav.fav}
                  removeProps={fav.deleteFav}
                />
              )}
            />
            <Route
              path="/StudentGroup"
              component={() => (
                <StudentGroup
                  setFavProps={fav.favReceived}
                  allFavs={fav.fav}
                  removeProps={fav.deleteFav}
                />
              )}
            />
            <Route
              path="/Manage"
              component={() => (
                <Manage
                  setFavProps={fav.favReceived}
                  allFavs={fav.fav}
                  removeProps={fav.deleteFav}
                />
              )}
            />
            <Route
              path="/ExamMode"
              component={() => (
                <ExamMode
                  setFavProps={fav.favReceived}
                  allFavs={fav.fav}
                  removeProps={fav.deleteFav}
                />
              )}
            />

            <Route
              path="/Settings"
              component={() => (
                <Settings
                  setFavProps={fav.favReceived}
                  allFavs={fav.fav}
                  removeProps={fav.deleteFav}
                />
              )}
            />
          </Switch>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

function useFav() {
  const [fav, setFav] = React.useState(new Set());
  const favReceived = (favNew) => {
    console.log("toggle bookmark status for " + favNew);
    console.log(typeof fav);
    if (fav.has(favNew)) {
      let tempFavSet = new Set(fav);
      tempFavSet.delete(favNew);
      setFav(new Set(tempFavSet));
    } else {
      setFav(new Set(fav.add(favNew)));
    }
  };

  const deleteFav = (dataFromChild) => {
    console.log(dataFromChild);
    if (fav.has(dataFromChild)) {
      let tempFavSet = new Set(fav);
      tempFavSet.delete(dataFromChild);
      setFav(new Set(tempFavSet));
    }
  };

  return {
    fav: fav,
    deleteFav: deleteFav,
    favReceived: favReceived,
  };
}
export default App;
