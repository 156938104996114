// taken from https://github.com/videojs/video.js/blob/master/docs/guides/react.md
import React from 'react';
import videojs from 'video.js';
import GetAppOutlined from "@material-ui/icons/GetAppOutlined";
import { IconButton } from '@material-ui/core';


export default class VideoPlayer extends React.Component {
  componentDidMount() {
    // instantiate video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      console.log('onPlayerReady', this);
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }


  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    const { downloadurl } = this.props;
    return (
      <>
        {window.localStorage.roleId == 4 && window.localStorage.boardId <= 6 &&
          <IconButton
            aria-label="download"
            color="inherit"
            onClick={() => window.open(downloadurl)}
            style={{
              position: 'absolute',
              top: '0px',
              left: '10px'
            }}
          >
            <GetAppOutlined color="primary" />
          </IconButton>
        }

        <div data-vjs-player >
          <video ref={node => (this.videoNode = node)} className="video-js" />
        </div>
      </>

    );
  }
}