
import React from 'react'
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, List, ListItem, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import ChatIcon from "@material-ui/icons/Chat";
import GeneralIns from "../assets/GeneralIns.jpg";
import listItemGreen from "../assets/ListItemGreen.png";



const useStyle = makeStyles((theme) => ({
    subContainer: {
        ...theme.typography.subContainer,
        zIndex: theme.zIndex.drawer + 9999,
    },

    description: {
        "& h4, & p": {
            margin: "0"
        }
    },
    profileImg: {
        height: theme.spacing(9),
        width: theme.spacing(9),
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 999,
        background: "#a1dde5",
        height: "auto",
        width: "100vw",
        padding: "4px 0"
    },

    listItems: {
        "& li": {
            display: "flex",
            alignItems: "center",
            marginBottom: "1em",
        },
        "& li span": {
            display: "block",
            width: "1.8em",
            height: "1.8em",
            marginRight: "1em",
            marginLeft: "0.5em"
        }
    },
    listAiInst: {
        "& .MuiListItem-root": {
            fontSize: "1.1em",
            padding: "2px 0",
            marginLeft: "1em",
            display: "list-item",
            listStyleType: "disclosure-closed"
        }
    }
}));

export default function GeneralInstructions(props) {
    const classes = useStyle();
    const theme = useTheme();
    return (
        <>
            <Grid
                item
                container
                style={{ backgroundColor: "#fffffff2", }}
                direction="column"
                spacing={1}
                className={classes.subContainer}
            >
                <Grid item container alignItems='center' justify='center' style={{ marginBottom: "3em" }}>
                    <Typography variant='h4' style={{ textTransform: "uppercase", fontWeight: "bold", marginBottom: "1em" }}>Please read the instructions carefully</Typography>
                </Grid>
                <Grid item alignItems='center' >
                    <Typography variant='h5' style={{ fontWeight: "bold", marginBottom: "0.8em" }}>General Instructions:</Typography>
                    <Typography variant='body1'>
                        <span style={{ paddingRight: "0.6em" }}>1.</span>
                        The clock will be set at the server. The countdown timer in the top right corner of screen will display the remaining time available for you to complete the examination. When the timer reaches zero, the examination will end by itself. You will not be required to end or submit your examination.
                    </Typography>
                    <Typography variant='body1' className={classes.listItems}>
                        <span style={{ paddingRight: "0.6em" }}>2.</span>
                        The Questions Palette displayed on the right side of screen will show the status of each question using one of the following symbols:
                        <ul style={{ marginleft: "1em" }}>
                            <li>1.
                                <span style={{
                                    backgroundColor: theme.palette.common.ltgrey,
                                    borderRadius: "3px"
                                }}></span>
                                You have not visited the question yet.</li>
                            <li>2.
                                <span style={{
                                    background: "red",
                                    clipPath: "polygon(0 0, 100% 17%, 100% 83%, 0 100%)",
                                }}></span> You have not answered the question.
                            </li>
                            <li>3.
                                <span style={{
                                    backgroundColor: "green",
                                    borderRadius: "3px"
                                }}></span> You have answered the question.
                            </li>
                            <li>4.
                                <span style={{
                                    backgroundColor: "#ffb031",
                                    borderRadius: "50%",
                                }}></span> You have NOT answered the question, but have marked the question for review.
                            </li>
                            <li>5.
                                <span style={{
                                    backgroundColor: "#0514d3",
                                    borderRadius: "50%",
                                    overflow: "hidden"
                                }}>
                                    <ChatIcon fontSize="small"
                                        style={{
                                            marginLeft: "11px",
                                            marginBottom: "-13px",
                                            color: "#fff",
                                        }}
                                    />
                                </span>
                                The question(s) "Answered and Marked for Review" will be considered for evolution.
                            </li>
                        </ul>
                    </Typography>
                    <Typography variant='body1'>
                        <span style={{ paddingRight: "0.6em" }}>3.</span>
                        You can click on the "&#60;" arrow which Appears to the left of question palette to collapse the question palette thereby maximizing the question window. To view the question palette again, you can click on "&#62;"  which appears on the right side of question window.
                    </Typography>
                    <Typography variant='body1'>
                        <span style={{ paddingRight: "0.6em" }}>4.</span>
                        You can click on  to navigate to the bottom and  to navigate to top of the question are, without scrolling.
                    </Typography>
                </Grid>
                <Grid item style={{ marginTop: "1em" }}>
                    <Typography variant='h5' style={{ fontWeight: "bold", marginBottom: "0.8em" }}>Navigating to a Question:</Typography>
                    <Typography variant='body1'>
                        <span style={{ paddingRight: "0.6em" }}>5.</span> To answer a question, do the following:
                        <ul style={{ listStyle: "none", margin: "0.5em 0" }}>
                            <li>a. Click on the question number in the Question Palette at the right of your screen to go to that numbered question directly. Note that using this option does NOT save your answer to the current question.</li>
                            <li>b. Click on <strong>Save & Next</strong> to save your answer for the current question and then go to the next question.</li>
                            <li>c. Click on <strong> Mark for Review & Next</strong> to save your answer for the current question, mark it for review, and then go to the next question.</li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid item style={{ marginTop: "1em" }}>
                    <Typography variant='h5' style={{ fontWeight: "bold", marginBottom: "0.8em" }}>Answering a Question:</Typography>
                    <Typography variant='body1'>
                        <span style={{ paddingRight: "0.6em" }}>6.</span> Procedure for answering a multiple choice type question:
                        <ul style={{ listStyle: "none", margin: "0.3em 0" }}>
                            <li>a. To select you answer, click on the button of one of the options.</li>
                            <li>b. To deselect your chosen answer, click on the button of the chosen option again or click on the <strong>Clear Response</strong> button.</li>
                            <li>c. To change your chosen answer, click on the button of another option</li>
                            <li>To save your answer, you MUST click on the Save & Next button.</li>
                            <li>To mark the question for review, click on the Mark for Review & Next button.</li>
                        </ul>
                    </Typography>
                    <Typography variant='body1'>
                        <span style={{ paddingRight: "0.6em" }}>7.</span>
                        To change your answer to a question that has already been answered, first select that question for answering and then follow the procedure for answering that type of question.</Typography>
                </Grid>
                <Grid item style={{ marginTop: "1em" }}>
                    <Typography variant='h5' style={{ fontWeight: "bold", marginBottom: "0.8em" }}>Navigating through sections:</Typography>

                    <Typography variant='body1'>
                        <span style={{ paddingRight: "0.6em" }}>8.</span>
                        After click the Save & Next button on the last question for a section, you will automatically be taken to the first question of the next section.
                    </Typography>
                    <Typography variant='body1'>
                        <span style={{ paddingRight: "0.6em" }}>9.</span>
                        You can shuffle between sections and questions during the examination as per your convenience only during the time stipulated.
                    </Typography>
                </Grid>
                <Grid item style={{ marginTop: "1em" }}>
                    <Typography variant='h4' style={{ color: "red", fontWeight: "600", textAlign: "center" }}><span style={{ color: "#000" }}>TRUNKIE EXAMS</span> IS PROCTORED BY ARTIFICIAL INTELLIGENCE !</Typography>
                    <Typography component="div" style={{ textAlign: "center" }}>
                        <img src={GeneralIns} alt="GeneralIns" width="300px" />
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant='h5' style={{ fontWeight: "600", color: "green", fontSize: "1.5em" }}>• 	Dos while taking Trunkie Exams:</Typography>
                    <List className={classes.listAiInst}>
                        <ListItem disableGutters>Take the test in a room alone and place the camera in such a way so that your face is placed in the middle of the screen and is visible to the proctor throughout the test.</ListItem>
                        <ListItem disableGutters>Ensure proper and stable internet connection is available in the room you are taking the test for uninterrupted exam.</ListItem>
                        <ListItem disableGutters>Take the exam alone in a room with no one sitting next to you, our AI based audio streaming will detect foreign noises and will be documented for further internal examination.</ListItem>
                        <ListItem disableGutters>Take the exam in a room where proper lighting is present.</ListItem>
                        <ListItem disableGutters>Ensure there is complete silence while taking the test as system will identify the disturbance and will be documented for further internal examination.</ListItem>
                        <ListItem disableGutters>Candidates must keep the laptop completely charged during the test and in case of using a desktop, they should ensure that it is connected to an UPS.</ListItem>
                        <ListItem disableGutters>Integrated/ attached webcam and microphone mandatorily required. The examination system should be permitted access (if requested) to the microphone during the examination.</ListItem>
                    </List>
                </Grid>
                <Grid item>
                    <Typography variant='h5' style={{ fontWeight: "600", color: "red", fontSize: "1.5em" }}>•	Don’ts while taking Trunkie Exams:</Typography>
                    <List className={classes.listAiInst}>
                        <ListItem disableGutters>Candidates should not take the test from a public place or a noisy background.</ListItem>
                    <ListItem disableGutters>Candidates should not be taking the test sitting in an informal posture on a couch or a bed.</ListItem>
                    <ListItem disableGutters>Test-takers should not be switch off their web camera.</ListItem>
                    <ListItem disableGutters>There should not be any other person present in the test-taking zone.</ListItem>
                    <ListItem disableGutters>Candidates should not engage in conversation with anyone else.</ListItem>
                    <ListItem disableGutters>Test-takers should not be leaving the exam area while the test is in progress.</ListItem>
                    <ListItem disableGutters>Do not use your mobile phones while taking the exams, AI based video streaming will detect abnormal movement of eyes beyond defined angle and will be documented for further internal examination.</ListItem>
                    <ListItem disableGutters>Do not open new tab or search for answers from any other devices during the exam.</ListItem>
                    <ListItem disableGutters>Use of wired/ wireless headphones/ earphones/ other audio devices are strictly prohibited.</ListItem>
                    <ListItem disableGutters>The examination system shall not permit the use of video conferencing services, screen sharing/ remote screen projection services, virtual machines, etc.; in the event the system detects attempts to use such tools or services, the candidate may be disqualified from the examination.</ListItem>
                    </List>
                </Grid>
                <Grid item>
                <Typography variant='h4' style={{ color: "red", fontWeight: "600", textAlign: "center" }}>Recommended system requirements to take Trunkie Exams</Typography>

                <List className={classes.listAiInst}>
                    <ListItem disableGutters>Desktop computers and laptop computers</ListItem>
                    <ListItem disableGutters>Operating System: For Desktops and Laptops: Window 7 or above (Windows 10 recommended), Mac OS 10.13 and above, and Linux (ubuntu versions 18.04, 16.04, 15.04 only) systems.</ListItem>
                    <ListItem disableGutters>Minimum Configuration (Applicable for Desktops and Laptops only):</ListItem>
                    <ListItem disableGutters>Processor: Core 2 Duo and above; Processor speed: 1.5 GHz and above; RAM: minimum 1 GB.</ListItem>
                    <ListItem disableGutters>Browser:</ListItem>
                    <ListItem disableGutters>Google Chrome (84.0.4147.135 or later). </ListItem>
                    <ListItem disableGutters>The user account must have administrator privileges to install the required applications.</ListItem>
                    <ListItem disableGutters>Pop-up blockers on the web browser must be disabled. </ListItem>
                    <ListItem disableGutters>The antivirus must be disabled. </ListItem>
                    <ListItem disableGutters>Minimum Internet Bandwidth: 512 Kbps minimum; the remote proctoring software streams exam data, including audio and video, directly to the cloud as you take Trunkie Exams 2022. In order to allow the continuous transfer of exam data, the specified minimum connection speed must be maintained at all times.</ListItem>
                    <ListItem disableGutters>Ensure system time is set in accordance with Indian Standard Time (GMT +5:30).</ListItem>
                </List>
                </Grid>



                {props.proceed &&
                    <Grid item container style={{ marginTop: "1em" }}>
                        <Grid item style={{ marginTop: "1em" }}>
                            <Typography variant='body1' style={{ color: "red", fontWeight: "600", margin: "1em 0" }}>Please note all questions will appear in your default language. This language can be changed for a particular question later on.</Typography>
                        </Grid>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel
                                    style={{ alignItems: "flex-start" }}
                                    control={<Checkbox />}
                                    label="I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare that I am not in possession of / not wearing / not carrying any prohibited gadget like mobile phone, bluetooth devices etc. /any prohibited material with me into the Examination Hall.I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include ban from future Tests / Examinations."
                                    checked={props.agreed}
                                    onChange={(e) => props.setAgreed(e.target.checked)}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item container alignItems='center' justify='center' style={{ margin: "3em 0" }}>
                            <Grid item sm={3}>
                                <Button
                                    variant='contained'
                                    color="secondary"
                                    disabled={!props.agreed}
                                    fullWidth
                                    onClick={props.handleProceed}
                                >
                                    Proceed
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    )
}
