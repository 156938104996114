import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import WorkSpaceHeader from "./Ui/WorkSpaceHeader.js";

import { Fade, Grid, Typography } from "@material-ui/core";

import DiscussionForum from "./Ui/DiscussionForum.js";
import PostAQuery from "./Ui/PostAQuery.js";
import CreateGroup from "./Ui/CreateGroup.js";
import DiscussionForumTeacher from "./Ui/DiscussionForumTeacher.js";
import SmartExam from "./Ui/SmartExam.js";
import AdeptiveTestLandingPage from "./Ui/AdeptiveTestLandingPage";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    ...theme.typography.mainContainer,
    height: "100% ",
    minHeight: "130vh",
  },
  headingItem: {
    ...theme.typography.tilesHeading,
    boxShadow: theme.shadows[5],
  },
}));

export default function StudentGroup(props) {
  const classes = useStyle();
  const [renderAg, setRenderAg] = useState(false);

  const DiscussionForumContainer =
    window.localStorage.roleId == 3 ? (
      <React.Fragment>
        {console.log(window.location.pathname)}

        <Grid item container direction="column">
          <WorkSpaceHeader title="DISCUSSION FORUM" moduleId={26} />

          <DiscussionForumTeacher />
        </Grid>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {console.log(window.location.pathname)}

        <Grid item container direction="column">
          <WorkSpaceHeader title="DISCUSSION FORUM" moduleId={26} />

          <DiscussionForum />
        </Grid>
      </React.Fragment>
    );

  const PostAQueryContainer = (
    <React.Fragment>
      {console.log(window.location.pathname)}

      <Grid item container direction="column">
        <WorkSpaceHeader title="Post A Query" moduleId={27} />

        <PostAQuery />
      </Grid>
    </React.Fragment>
  );


  const AdaptiveTestContainer = (
    <>
      <Fade
        in={window.location.pathname === "/StudentGroup/adaptiveTest"}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="Adaptive Test" moduleId={41} />
          <AdeptiveTestLandingPage />
        </Grid>
      </Fade>
    </>
  );

  const CreateGroupContainer = (
    <React.Fragment>
      {console.log(window.location.pathname)}

      <Grid item container direction="column">
        <WorkSpaceHeader
          title="MANAGE GROUPS"
          creategroup={true}
          setForce={setRenderAg}
          force={renderAg}
          moduleId={34}
        />

        <CreateGroup force={renderAg} setForce={setRenderAg} />
      </Grid>
    </React.Fragment>
  );

  const smartExams = (
    <React.Fragment>
      {console.log(window.location.pathname)}
      <Fade
        in={window.location.pathname === "/StudentGroup/SmartExams"}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="Smart Exams" moduleId={37} />
          <SmartExam />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        className={classes.mainContainer}
        style={{ width: `calc(100% - 12em)` }}
      >

        {window.location.pathname === "/StudentGroup/SmartExams" ? (
          smartExams
        )
          : window.location.pathname === "/StudentGroup" ? (
            DiscussionForumContainer
          ) : window.location.pathname === "/StudentGroup/DiscussionForum" ? (
            DiscussionForumContainer
          ) : window.location.pathname === "/StudentGroup/PostAQuery" ? (
            PostAQueryContainer
          ) : window.location.pathname === "/StudentGroup/CreateGroup" ? (
            CreateGroupContainer
          )  : (
            <Typography variant="h1">To be designed</Typography>
          )}
      </Grid>
      {/* : window.location.pathname === "/StudentGroup/adaptiveTest" ? (
            AdaptiveTestContainer
          ) */}
    </React.Fragment>
  );
}
