import React, { useState } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Grid,
  Typography,
  Avatar,
  Divider,
  Button,
  DialogContent,
  IconButton,
  TextField,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { url } from "./store";
import moment from "moment";
import { orderBy } from "lodash";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import kid5 from "../assets/kid5.jfif";
import MuiAlert from "@material-ui/lab/Alert";
import CommentIcon from "@material-ui/icons/Comment";
import { DropzoneDialog } from "material-ui-dropzone";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    width: "200em",
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "80vw",
      height: "90vh",
      maxHeight: "90vh",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
      maxHeight: "90vh",
    },
  },

  dialogwidth: {
    maxWidth: "100em",
    maxHeight: "90vh",
  },

  menuCard: {
    height: "13em",
    width: "18em",
    borderRadius: 12,
    cursor: "pointer",
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
    zIndex: 3,
  },

  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();

  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function DiscussionDialogTeacher(props) {
  const classes = useStyle();
  const theme = useTheme();
  // const [myAttachment, setMyAttachment] = useState({
  //   attachment: "",
  //   fileName: "",
  //   fileType: "",
  // });
  const [reply, setReply] = useState({
    employeeId: window.localStorage.employeeId,
    groupQueryId: props.questionForDialog.groupQuery.groupQueryId,
    loginUserId: window.localStorage.userId,
    queryStatus: "Open",
    response: "",
    fileName:  "",
    fileType: "",
    url: ""
  });
  const [loader, setLoader] = useState(false);
  const [notiSnack, setNotiSnack] = React.useState(false);
  const [NotiStatus, setNotiStatus] = React.useState("");
  const [notiMessage, setNotiMessage] = React.useState("");
  const [uploadDialog, setUploadDialog] = useState(false);
  const [file, setFile] = useState("");

  const handleReply = () => {
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_ADD_GROUP_QUERY_RESPONSE",
              busiParams: reply,
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          console.log(data);
          setNotiMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.status == "Failure"
            ? setNotiStatus("error")
            : setNotiStatus("success");
          setNotiSnack(true);
          setLoader(false);
          props.onClose();
          // props.setmessageRefresh(!props.messageRefresh);
          setReply({
            ...reply,
            response: "",
            url: "",
            fileName: "",
            fileType: ""
          });
          setTimeout(() => {
            props.handleDisscussion(
              {
                target: {
                  value: props.secId,
                },
              },
              "no"
            );
          }, 1500);
        })
        .catch((data) => {
          console.error(data);
          setLoader(false);
          // setError(true);
        });
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleUploadClose = () => {
    setUploadDialog(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
  });

  const handleOnUploadSubmit = async (files) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".")[0];
    let myFileType = files[0].type.split("/")[1];

    // setMyAttachment({
    //   ...myAttachment,
    //   attachment: newattachment,
    //   fileName: myFileName,
    //   fileType: myFileType,
    // });
    setReply({
      ...reply,
       url: newattachment,
      fileName: myFileName,
      fileType: myFileType
    });
    setUploadDialog(false);

    setFile(myFileName);
  };

  return (
    <React.Fragment>
      {console.log(props.mylist)}
      <Dialog
        open={props.open}
        onClose={props.onClose}
        classes={{ paperWidthSm: classes.dialogwidth }}
        className={`${classes.dialogContainer}`}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
          Query
        </DialogTitle>
        <DialogContent style={{ minHeight: "40em" }} >
          <Grid
            container
            direction="column"
            style={{
              backgroundColor: "white",
              width: "80vw",
            }}
          >
            {/* questionare container starts */}
            <Grid
              item
              container
              style={{
                position: "absolute",
                zIndex: 1,
                backgroundColor: "white",
                marginTop: "1em",
              }}
            >
              <Grid
                container
                direction="column"
                justify="center"
                style={{
                  backgroundColor: theme.palette.common.white,
                }}
              >
                <Grid item container direction="row">
                  <Grid item container sm={1}>
                    <Avatar
                      size="medium"
                      style={{ height: "3em", width: "3em" }}
                    >
                      <img
                        alt=""
                        src={props.questionForDialog.groupQuery.profilePicture}
                        style={{ height: "3em", width: "3em" }}
                      ></img>
                    </Avatar>
                  </Grid>
                  <Grid item container sm={8} direction="row" spacing={1}>
                    <Grid item container>
                      <b>{props.questionForDialog.groupQuery.query}</b>
                    </Grid>
                    <Grid item container>
                      {props.questionForDialog.groupQuery.queryDescription}{" "}
                    </Grid>
                    <Grid item container direction="row" spacing={2}>
                      {
                        //       <Grid item>
                        //         {" "}
                        //         <Typography variant="body1">
                        //           {props.questionForDialog.groupQuery.queryStatus ===
                        //           "open" ? (
                        //             <span
                        //               style={{
                        //                 backgroundColor: "#ffc107",
                        //                 color: "white",
                        //                 padding: "0.2em",
                        //                 borderRadius: 5,
                        //                 width: "15em",
                        //               }}
                        //             >
                        //               {props.questionForDialog.groupQuery.queryStatus}
                        //             </span>
                        //           ) : (
                        //             <span
                        //               style={{
                        //                 backgroundColor: "#64dd17",
                        //                 color: "white",
                        //                 padding: "0.2em",
                        //                 borderRadius: 5,
                        //                 width: "15em",
                        //               }}
                        //             >
                        //               {props.questionForDialog.groupQuery.queryStatus}
                        //             </span>
                        //           )}
                        //         </Typography>
                        //       </Grid>
                      }
                      <Grid item>
                        By {props.questionForDialog.groupQuery.studentName}
                      </Grid>
                      <Grid item>-</Grid>
                      <Grid item>
                        {moment(
                          props.questionForDialog.groupQuery.createdDate,
                          "DD-MM-YYYY"
                        )
                          .format("DD MMM YYYY  ")
                          .toString()}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={1}
                    onClick={() =>
                      props.handleDialogImageClick(
                        props.questionForDialog.groupQuery.url
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {props.questionForDialog.groupQuery.url ? (
                      <img
                        alt=""
                        src={props.questionForDialog.groupQuery.url}
                        width="60em"
                        height="50em"
                        
                      ></img>
                    ) : undefined}
                  </Grid>
                </Grid>

                <Grid item>
                  <Divider
                    style={{
                      marginTop: "1em",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <React.Fragment>
            <Grid
              item
              container
              direction="column"
              justify="center"
              className="customPdfScroll"
              style={{
                marginTop:
                  window.localStorage.teacherType == "Subject"
                    ? "11em"
                    : undefined,
                maxHeight:
                  window.localStorage.teacherType == "Subject"
                    ? "13em"
                    : undefined,
                    overflowY: "scroll",
              }}
              wrap="nowrap"
            >
              <Grid
                item
                container
                style={{
                  position: "absolute",
                  top: "14em",
                  zIndex: 1,
                  backgroundColor: "white",
                }}
              >
                <Grid item>
                  <Grid container direction="row">
                    <Grid item>
                      <CommentIcon
                        style={{ marginRight: "0.5em", color: "orange" }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>Responses</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {props.questionForDialog.groupQueryResponse === null ? (
                <Typography variant="h6">No Response Available</Typography>
              ) : (
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  style={{
                    padding: "1em",
                    position:
                      window.localStorage.teacherType == "Class"
                        ? "absolute"
                        : undefined,
                    top:
                      window.localStorage.teacherType == "Class"
                        ? "17em"
                        : undefined,

                    minHeight: "12em",
                    maxHeight: "12em",
                    marginBottom: "auto",
                    //overflow: "scroll",
                  }}
                  wrap="nowrap"
                >
                  {orderBy(
                    props.questionForDialog.groupQueryResponse,
                    (o) => o.createdOn,
                    "desc"
                  ).map((user, index) => (
                    <React.Fragment>
                      <Grid
                        item
                        container
                        direction="row"
                        style={{ marginBottom: "auto" }}
                        sm
                      >
                        <Grid item container sm={1}>
                          <Avatar
                            size="medium"
                            style={{ height: "3em", width: "3em" }}
                          >
                            <img
                              alt=""
                              src={user.profilePicture}
                              style={{ height: "3em", width: "3em" }}
                            ></img>
                          </Avatar>
                        </Grid>
                        <Grid
                          item
                          container
                          sm={9}
                          direction="column"
                          spacing={1}
                        >
                          <Grid item container direction="row" spacing={2}>
                            <Grid item>By {user.teacherName}</Grid>
                            <Grid item>-</Grid>
                            <Grid item>
                              {moment(user.createdOn, "DD-MM-YYYY")
                                .format("DD MMM YYYY")
                                .toString()}
                            </Grid>
                          </Grid>
                          <Grid item container>
                            {user.response}
                          </Grid>
                        </Grid>
                        <Grid item sm={2}>
                        {user.url ? (<img
                              alt=""
                              src={user.url}
                              style={{maxHeight: "45px", cursor: 'pointer'}}
                              onClick={() =>
                                props.handleDialogImageClick(user.url)
                              }
                            />) : undefined}
                        </Grid>
                      </Grid>
                      <Grid item style={{ margin: "0.7em 0" }}>
                        <Divider variant="middle" />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              direction="column"
              style={{
                // position: "absolute",
                // // top: "29em",
                // bottom: "0em",
                // zIndex: 3,
                backgroundColor: "white",
                // width: "80vw",
              }}
            >
              {window.localStorage.teacherType == "Subject" ? (
                <React.Fragment>
                  <Grid item container sm md lg xl>
                    <TextField
                      id=""
                      label="Write Response"
                      multiline
                      variant="outlined"
                      rows={3}
                      style={{ width: "75em" }}
                      value={reply.reply}
                      onChange={(e) => {
                        console.log(props);
                        setReply({ ...reply, response: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item container spacing={3}>
                      <Grid
                        item                       
                      >
                        <Typography
                          variant="body1"
                          color="secondary"
                          style={{
                            fontWeight: "bold",
                            color: theme.palette.common.tabFont,
                            cursor: "pointer",
                            margin: "10px 0"
                          }}
                          onClick={() => setUploadDialog(true)}
                        >
                          Upload an image
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          color="secondary"
                          style={{
                            fontWeight: "bold",
                color: theme.palette.common.tabFont,
                 margin: "10px 0"
              }}
            >
              {file}
            </Typography>
          </Grid>
        </Grid>
                  <Grid item container sm>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ color: "white", marginTop: "0.5em" }}
                      onClick={handleReply}
                      disabled={loader === true || reply.response.trim() === ""}
                    >
                      {loader === true ? (
                        <CircularProgress
                          color={theme.palette.secondary.light}
                        />
                      ) : (
                        "Reply"
                      )}
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : undefined}
            </Grid>
          </React.Fragment>
        </DialogContent>
      </Dialog>

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        color="secondary"
        maxFileSize={6000000}
        open={uploadDialog}
        dropzoneParagraphClass={classes.dropZone}
        dialogProps={{ className: classes.myDropZone }}
        onClose={handleUploadClose}
        onSave={(files) => handleOnUploadSubmit(files)}
        showPreviews={true}
        acceptedFiles={["image/jpeg","image/png","image/gif"]}
        showFileNamesInPreview={true}
        filesLimit={1}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={notiSnack}
        autoHideDuration={6000}
        onClose={() => setNotiSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setNotiSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={NotiStatus}>{notiMessage}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
