import { useState } from "react";

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  return [
    values,
    (e) => {
      if (e.target.name !== "") {
        setValues({
          ...values,
          [e.target.name]: e.target.value,
          username: window.localStorage.username,
        });
      } else {
        setValues({});
      }
    },
  ];
};

export const useForm2 = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  return [
    values,
    (e, profilepic) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        userId: window.localStorage.userId,
        userProfilePicture: profilepic ? profilepic : "",
      });
    },
  ];
};

export const useForm3 = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  return [
    values,
    (e) => {
      console.log(values);
      let mysrc = parseInt(window.localStorage.roleId) === 5 ? "Parent" : "";
      setValues({
        ...values,
        [e.target.name]: e.target.value,

        loginUserId: parseInt(window.localStorage.parentUserId),

        sectionId: parseInt(window.localStorage.sectionId),
        src: mysrc,
        studentInstanceId: parseInt(window.localStorage.studentInstanceId),
      });
    },
  ];
};

export const useForm4 = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  return [
    values,
    (e) => {
      console.log(window.localStorage.roleId);
      let mysrc = parseInt(window.localStorage.roleId) === 5 ? "Parent" : "";
      setValues({
        ...values,
        [e.target.name]: e.target.value,

        loginUserId: parseInt(window.localStorage.parentUserId),
        studentInstanceId: parseInt(window.localStorage.studentInstanceId),
      });
    },
  ];
};
