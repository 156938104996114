import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {CircularProgress, Grid } from "@material-ui/core";
import { groupBy } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { url } from "./store.js";

const useStyles = makeStyles((theme) => ({
  table: {},
  rowStyle: {
    padding: "2em",
  },
  cellStyle: {
    padding: "2em",
    maxWidth: "5em",
  },
  rowStyleHead: {
    padding: "2em",

    paddingBottom: "1em",
    marginLeft: "1em",
  },
  tableHeadClass: {
    "& .MuiTableCell-head": {
      color:
        theme.typography.myTheme.themeName === "Night" ? "pink" : "inherit",
    },
  },
  tableBodyClass: {
    "& .MuiTableCell-body": {
      color:
        theme.typography.myTheme.themeName === "Night" ? "pink" : "inherit",
    },
  },
}));

function createData(day, I, II, III, IV, V, VI, VII, VIII, IX, X) {
  return { day, I, II, III, IV, V, VI, VII, VIII, IX, X };
}

const headers = [
  { head: "DAY" },
  { head: "I", time: "(9:00 AM-9:35 AM)" },
  { head: "II", time: "(9:35 AM-10:10 AM)" },
  { head: "III", time: "(10:10 AM-10:30 AM)" },
  { head: "IV", time: "(10:30 AM-11:05 AM)" },
  { head: "V", time: "(11:05 AM-11:40 AM)" },
  { head: "VI", time: "(11:40 AM-12:15 PM)" },
  { head: "VII", time: "(12:15 AM-12:50 PM)" },
  { head: "VIII", time: "(11:05 AM-11:40 AM)" },
  { head: "IX", time: "(11:40 AM-12:15 PM)" },
  { head: "X", time: "(12:15 AM-12:50 PM)" },
];

export default function SimpleTable() {
  const classes = useStyles();
  const theme = useTheme();
  const [myRows, setMyRows] = useState([]);
  const tablerows = {
    Monday: [{ subjectName: "MONDAY", className: "" }],
    Tuesday: [{ subjectName: "TUESDAY", className: "" }],
    Wednesday: [{ subjectName: "WEDNESDAY", className: "" }],
    Thursday: [{ subjectName: "THURSDAY", className: "" }],
    Friday: [{ subjectName: "FRIDAY", className: "" }],
    Saturday: [{ subjectName: "SATURDAY", className: "" }],
  };
  const [loader, setLoader] = useState(true);

  const bodyObjClassNoti = {
    requestList: [
      {
        isEncrypt: false,
        transactionId: "0000001438241244",
        busiCode: "SCHOOL_GET_TIME_TABLE",
        busiParams: {
          id: window.localStorage.employeeId,
          student: false,
        },
      },
    ],
    pubInfo: {
      appId: "appId",
      sessionId: window.localStorage.sessionId,
      version: "21",
      timestamp: "20180314175419",
      lang: "en_US",
      userId: "1000016614",
      serviceId: "7021150585",
      circleId: "MU",
      ncsroute: "WE015",
      customerId: "1000016614",
      osType: "windows",
    },
  };

  useEffect(() => {
    setLoader(true);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(bodyObjClassNoti),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        let ttable = groupBy(
          data.respData[0].respMsg.result,
          (item) => item.day
        );
        console.log(ttable);

        ttable.Mon &&
          ttable.Mon.map((item, index) => {
            console.log(index, item.period);
            if (index + 1 === parseInt(item.period)) {
              tablerows.Monday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            } else {
              tablerows.Monday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            }
          });
        ttable.Tue &&
          ttable.Tue.map((item, index) => {
            console.log(index, item.period);
            if (index + 1 === parseInt(item.period)) {
              tablerows.Tuesday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            } else {
              tablerows.Tuesday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            }
          });
        ttable.Wed &&
          ttable.Wed.map((item, index) => {
            console.log(index, item.period);
            if (index + 1 === parseInt(item.period)) {
              tablerows.Wednesday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            } else {
              tablerows.Wednesday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            }
          });
        ttable.Thu &&
          ttable.Thu.map((item, index) => {
            console.log(index, item.period);
            if (index + 1 === parseInt(item.period)) {
              tablerows.Thursday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            } else {
              tablerows.Thursday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            }
          });
        ttable.Fri &&
          ttable.Fri.map((item, index) => {
            console.log(index, item.period);
            if (index + 1 === parseInt(item.period)) {
              tablerows.Friday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            } else {
              tablerows.Friday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            }
          });
        ttable.Sat &&
          ttable.Sat.map((item, index) => {
            console.log(index, item.period);
            if (index + 1 === parseInt(item.period)) {
              tablerows.Saturday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            } else {
              tablerows.Saturday.push({
                subjectName: item.subjectName,
                className: item.sectionName,
              });
            }
          });
        let mytable = [];
        mytable.push(tablerows.Monday);
        mytable.push(tablerows.Tuesday);
        mytable.push(tablerows.Wednesday);
        mytable.push(tablerows.Thursday);
        mytable.push(tablerows.Friday);
        mytable.push(tablerows.Saturday);
        setMyRows(mytable);
        console.log(mytable);
      })
      .catch((err) => console.error(err));

    setInterval(() => {
      setLoader(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      {loader === false ? (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead classes={{ root: classes.tableHeadClass }}>
              <TableRow>
                {headers.map((user, index) => (
                  <TableCell
                    classes={{ root: classes.rowStyleHead }}
                    key={user.head + index}
                  >
                    {user.head}
                    <br />
                    {user.head === "DAY" ? "" : ""}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: classes.tableBodyClass }}>
              {myRows.map((row, index) => (
                <TableRow key={row + index} className={classes.rowStyle}>
                  {row.map((item, index1) => (
                    <TableCell
                      classes={{ root: classes.cellStyle }}
                      component={index1 === 0 ? "th" : "td"}
                      scope={index1 === 0 ? "row" : undefined}
                      align={index1 === 0 ? "left" : undefined}
                    >
                      <Grid container direction="column">
                        <Grid item>
                          {" "}
                          {item.subjectName.split(",").length > 1
                            ? item.subjectName.replace(",", " ")
                            : item.subjectName}
                        </Grid>
                        <Grid item>
                          <span style={{ fontWeight: "bold" }}>
                            {item.className}
                          </span>
                        </Grid>
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </React.Fragment>
  );
}
