import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles, fade, useTheme } from "@material-ui/core/styles";
import { Button, IconButton, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { url } from "./store";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
    width: "65vw",
  },
  textFieldStyle: {
    backgroundColor: fade(theme.palette.common.white, 0.7),
  },

  myTextFieldStyle: {
    "& .MuiFormLabel-root": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiInputBase-input": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiSelect-icon":{
      color: theme.typography.myTheme.themeName == "Night" ? "#fff" : "inherit", 
    },
  },
}));

export default function IssueReport(props) {
  const classes = useStyle();
  const [issue, setIssue] = useState([]);
  const [system, setSystem] = useState([]);
  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const [addIssue, setAddIssue] = useState({
    branchId: window.localStorage.branchId,
    description: "",
    impactedSystem: "",
    issueStatus: 1,
    issueType: "",
    loginUserId:
      window.localStorage.roleId == 5
        ? window.localStorage.parentUserId
        : window.localStorage.userId,
    userId: window.localStorage.userId,
  });
  const [uploadExamMessage, setUploadExamMessage] = useState("");
  const [uploadExamStatus, setUploadExamStatus] = useState("");
  const [openUploadExamSnack, setUploadExamSnack] = useState("");

  useEffect(() => {
    const getQueryData = async () => {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "MDM_GET_LOOKUP_SUB_CATG",
              busiParams: {
                categoryName: "issue type",
                flag: true,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          // moment(item.date, "YYYY-MM-DD").toDate(),
          if (data.respData[0].respMsg.results.length > 0) {
            setIssue(data.respData[0].respMsg.results);
          } else {
            setIssue([
              {
                subCategoryId: 0,
                subCategory: "Apparently we cant",
              },
            ]);
          }
        })
        .catch((data) => {
          console.error(data);
        });
    };

    getQueryData().catch((data) => console.error(data));
  }, []);

  useEffect(() => {
    const getQueryData = async () => {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "MDM_GET_LOOKUP_SUB_CATG",
              busiParams: {
                categoryName: "Impacted System",
                flag: true,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          // moment(item.date, "YYYY-MM-DD").toDate(),
          if (data.respData[0].respMsg.results.length > 0) {
            setSystem(data.respData[0].respMsg.results);
          } else {
            setSystem([
              {
                subCategoryId: 0,
                subCategory: "Apparently we cant",
              },
            ]);
          }
        })
        .catch((data) => {
          console.error(data);
        });
    };

    getQueryData().catch((data) => console.error(data));
  }, []);

  const onAddIssue = () => {
    setLoader(true);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_ADD_ISSUE",
            busiParams: addIssue,
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUploadExamMessage(data.respData[0].respMsg.message);
        setUploadExamStatus(
          data.respData[0].respMsg.status === "Success" ? "success" : "error"
        );
        setUploadExamSnack(true);

        setLoader(false);
        // moment(item.date, "YYYY-MM-DD").toDate(),
      })
      .catch((data) => {
        console.error(data);
        setLoader(false);
      });

    setAddIssue({
      branchId: window.localStorage.branchId,
      description: "",
      impactedSystem: "",
      issueStatus: 1,
      issueType: "",
      loginUserId:
        window.localStorage.roleId == 5
          ? window.localStorage.parentUserId
          : window.localStorage.userId,
      userId: window.localStorage.userId,
    });
  };

  return (
    <React.Fragment>
      <Grid
        container
        className={classes.subContainer}
        direction="column"
        spacing={3}
      >
        <Grid item>
          <TextField
            id=""
            variant="outlined"
            label="Issue Type"
            select
            fullWidth
            color="primary"
            value={addIssue.issueType}
            className={
              theme.typography.myTheme.themeName == "Night"
                ? classes.myTextFieldStyle
                : classes.textFieldStyle
            }
            onChange={(e) =>
              setAddIssue({ ...addIssue, issueType: e.target.value })
            }
          >
            {issue.map((item) => (
              <MenuItem key={item} value={item.subCategoryId}>
                {item.subCategory}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            id=""
            variant="outlined"
            label="Select Platform"
            select
            fullWidth
            color="primary"
            className={
              theme.typography.myTheme.themeName == "Night"
                ? classes.myTextFieldStyle
                : classes.textFieldStyle
            }
            value={addIssue.impactedSystem}
            onChange={(e) =>
              setAddIssue({ ...addIssue, impactedSystem: e.target.value })
            }
          >
            {system.map((item) => (
              <MenuItem key={item} value={item.subCategoryId}>
                {item.subCategory}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            id=""
            variant="outlined"
            label="Enter Issue Description"
            fullWidth
            multiline
            rows={6}
            color="primary"
            className={
              theme.typography.myTheme.themeName == "Night"
                ? classes.myTextFieldStyle
                : classes.textFieldStyle
            }
            value={addIssue.description}
            onChange={(e) =>
              setAddIssue({ ...addIssue, description: e.target.value })
            }
          />
        </Grid>

        <Grid item container spacing={2}>
          <Grid item>
            <Typography color="secondary">* For more details send a mail to trunkie@projectheen.com</Typography>
          </Grid>
        </Grid>

        <Grid item container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="text"
              color="secondary"
              onClick={() =>
                setAddIssue({
                  branchId: window.localStorage.branchId,
                  description: "",
                  impactedSystem: "",
                  issueStatus: 1,
                  issueType: "",
                  loginUserId:
                    window.localStorage.roleId == 5
                      ? window.localStorage.parentUserId
                      : window.localStorage.userId,
                  userId: window.localStorage.userId,
                })
              }
            >
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              style={{ color: "#ffffff" }}
              onClick={onAddIssue}
              disabled={
                addIssue.issueType === "" ||
                addIssue.impactedSystem === "" ||
                addIssue.description === ""
              }
            >
              {loader === true ? (
                <CircularProgress color={theme.palette.secondary.light} />
              ) : (
                "Report Issue"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openUploadExamSnack}
        autoHideDuration={6000}
        onClose={() => setUploadExamSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setUploadExamSnack(false)}
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={uploadExamStatus}>{uploadExamMessage}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
