import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import TabMaker from "./TabMaker";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { url } from "./store.js";
import CompGuideWorkspace from "./CompGuideWorkspace";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
  chapterSelected: {
    borderLeft: `5px solid ${theme.palette.secondary.main}`,
    backgroundColor: "lightgrey",
    margin: 10,
    cursor: "pointer",
  },
  chapterNotSelected: {
    "& .MuiListItem-root": { borderLeft: "none" },
    backgroundColor: "lightgrey",
    margin: 10,
    cursor: "pointer",
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SectionMaker = (props) => {
  const theme = useTheme();
  return (
    <React.Fragment>
      {props.sectionSelect.map((item1, index1) => (
        <Grid item container sm={6} direction="column">
          <Grid item>
            <Typography variant="h6" color="secondary">
              {item1.sectionName}
            </Typography>
          </Grid>
          <Grid item>
            <Divider
              style={{
                width: "18em",
                backgroundColor: theme.palette.common.tabFont,
                marginBottom: "0.8em",
              }}
            />
          </Grid>
          <React.Fragment>
            {item1 &&
              item1.comprehensiveGuide.map((item, index) => (
                <Grid
                  item
                  style={{ marginBottom: "0.5em", cursor: "pointer" }}
                  onClick={() => {
                    props.setHrefData(item);
                    props.setRoute("notlist");
                  }}
                >
                  {item.fileName}
                </Grid>
              ))}
          </React.Fragment>
        </Grid>
      ))}
    </React.Fragment>
  );
};

const MainCompView = (props) => {
  const [sectionSelect, setSectionSelect] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [chap, setChap] = useState(props.myChapter);
  const classes = useStyle();
  const theme = useTheme();
  const handleSectionSelect = (e, chap, index) => {
    setSelectedIndex(index);
    setSectionSelect([]);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_COMPREHENSIVE_GUIDE_BY_CHAPTERID",
              busiParams: {
                chapterId: chap.chapterId,
                branchId: window.localStorage.branchId,
                source: chap.source,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let mychapters = [];
          data.respData[0].respMsg.result &&
          data.respData[0].respMsg.result.length > 0
            ? setSectionSelect(data.respData[0].respMsg.result)
            : console.log("hii");
        })

        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <Grid container direction="row">
        <Grid
          item
          sm={5}
          style={{
            maxHeight: "50em",
            overflowY: "scroll",
            scrollbarWidth: "none",
          }}
        >
          <List style={{ width: "90%" }}>
            {chap.map((item, index) => (
              <React.Fragment>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ margin: 0 }}
                >
                  <Grid item sm={11} style={{ margin: 0 }}>
                    <ListItem
                      key={index}
                      className={
                        selectedIndex === index
                          ? classes.chapterSelected
                          : classes.chapterNotSelected
                      }
                      style={{ margin: 5 }}
                      onClick={(e) => handleSectionSelect(e, item, index)}
                    >
                      <ListItemText>{item.chapterName}</ListItemText>
                    </ListItem>
                  </Grid>
                  <Grid item sm={1}>
                    {selectedIndex === index ? (
                      <ArrowRightIcon
                        style={{
                          color: theme.palette.secondary.main,
                          fontSize: "2.5rem",
                        }}
                      />
                    ) : undefined}
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </List>
        </Grid>
        <Grid item container sm={7} style={{ paddingLeft: "2em" }}>
          <SectionMaker
            sectionSelect={sectionSelect}
            setSectionSelect={setSectionSelect}
            setRoute={props.setRoute}
            setHrefData={props.setHrefData}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const CompWorkSpaceHandler = (props) => {
  const [route, setRoute] = useState("list");
  const [hrefData, setHrefData] = useState({});

  return route === "list" ? (
    <MainCompView
      setRoute={setRoute}
      hrefData={hrefData}
      setHrefData={setHrefData}
      myChapter={props.myChapter}
    />
  ) : (
    <CompGuideWorkspace hrefData={hrefData} setRoute={setRoute} />
  );
};

const MyDataMaker = (props) => {
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [myChapter, setMyChapter] = useState([]);

  useEffect(() => {
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_ALL_CHAPTER_SECTION",
              busiParams: {
                admin: false,
                sectionId: window.localStorage.sectionId,
                subjectId: props.secId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let mychapters = [];
          data.respData[0].respMsg.result &&
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
                mychapters.push(item);
              })
            : mychapters.push({ chapterName: "No Data Available" });
          setMyChapter(mychapters);
          setInterval(() => {
            setLoader(false);
          }, 1500);
        })

        .catch((data) => {
          console.error(data);
          setInterval(() => {
            setLoader(false);
          }, 1500);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    }
  }, []);

  return (
      <React.Fragment>
        {loader === true ? (
          <CircularProgress color="secondary" />
        ) : (
          <CompWorkSpaceHandler myChapter={myChapter} />
        )}
      </React.Fragment>
  );
};

export default function ComprehensiveGuide() {
  const classes = useStyle();
  const [connectZone, setConnectZone] = useState([]);
  const [materialAdded, setMaterialAdded] = useState(false);
  const [loader, setLoader] = useState(true);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [dropZone, setDropZone] = useState(false);
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [chapter, setChapter] = useState([]);
  const [section, setSection] = useState([]);
  const [chapterSelected, setChapterSelected] = useState({ chapterId: null });
  const [sectionSelected, setSectionSelected] = useState({});
  const [myAttachment, setMyAttachment] = useState({
    attachment: "",
    fileName: "",
    fileType: "",
  });
  const [myLoader, setMyLoader] = useState(false);

  const headers =
    window.localStorage.roleId == 3
      ? window.localStorage.teacherType == "Class"
        ? ["Chapter", "Section", "Date", "Material"]
        : ["Chapter", "Section", "Date", "Material", "Action"]
      : ["Chapter", "Section", "Date", "Material"];

  const handleOpenUploadDialog = async () => {
    setChapterSelected({});
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_ALL_CHAPTER_SECTION",
              busiParams: {
                admin: false,
                sectionId: window.localStorage.sectionId,
                subjectId: window.localStorage.subjectId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let mychapters = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mychapters.push(item);
            });
          setChapter(mychapters);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }
    setUploadDialog(true);
  };

  const handleDropZone = () => {
    setDropZone(true);
  };

  const handleSaveMaterial = async (files) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".");
    let myFileType = files[0].type.split("/");

    setMyAttachment({
      ...myAttachment,
      attachment: newattachment,
      fileName: myFileName,
      fileType: myFileType,
    });
    setMaterialName(myFileName);
    setDropZone(false);
  };

  const handleChapterSelect = async (e) => {
    setChapterSelected(e.target.value);
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_CH_SECTION_DETAILS_BY_CID",
              busiParams: {
                admin: false,
                id: e.target.value.chapterId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let mysections = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mysections.push(item);
            });
          setSection(mysections);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    console.log(section);
  };

  const handleDropZoneClose = () => {
    setDropZone(false);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    setLoader(true);
    const AllLists = [];
    let subjects = JSON.parse(window.localStorage.getItem("users") || "[]");
    console.log(subjects);
    subjects &&
      subjects.map((item, index) => {
        let temList = (
          <MyDataMaker
            key={index}
            headers={headers}
            materialAdded={materialAdded}
            setMaterialAdded={setMaterialAdded}
            handleOpenUploadDialog={handleOpenUploadDialog}
            secId={item.subjectId}
          />
        );

        AllLists.push(temList);
      });
    setConnectZone(AllLists);
    setInterval(() => {
      setLoader(false);
    }, 700);
  }, [materialAdded]);

  const theme = useTheme();
  const subjects = JSON.parse(window.localStorage.getItem("users") || "[]");

  let tabheadings = [];
  try {
    tabheadings = subjects.map((a) => a.subjectName);
  } catch (error) {
    console.error(error);
  }

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TabMaker tabs={tabheadings} data={connectZone} />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
