import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { makeStyles, fade, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";
import RestoreIcon from "@material-ui/icons/Restore";
import IconButton from "@material-ui/core/IconButton";
import { orderBy } from "lodash";
import moment from "moment";
import { url } from "./store.js";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Tooltip } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
    width: "65vw",
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    fontSize: "1rem",
  },
  textFieldStyle: {
    //backgroundColor: fade(theme.palette.common.white, 0.7),

    "& .MuiFormLabel-root": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiInputBase-input": {
      color:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.typography.myTheme.themeName == "Night"
          ? theme.palette.common.tabFont
          : "inherit",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ApplyLeave(props) {
  const classes = useStyle();
  const [selectedDate, handleDateChange] = useState();
  const theme = useTheme();
  const [error, setError] = useState(false);
  const [succ, setSucc] = useState(false);
  const [loader, setLoader] = useState(false);
  const [myloader, setMyLoader] = useState(true);
  const [leaveTaken, setLeaveTaken] = useState([]);
  const [leaveAdded, setLeaveAdded] = useState(false);
  const [notiSnack, setNotiSnack] = React.useState(false);
  const [NotiStatus, setNotiStatus] = React.useState("");
  const [notiMessage, setNotiMessage] = React.useState("");

  const [leave, setLeave] = useState({
    fromDate: null,
    loginUserId: 0,
    remarks: null,
    studentInstanceId: 0,
    subject: null,
    toDate: null,
  });

  useEffect(() => {
    setMyLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_STUDENT_ LEAVE_BY_STUINSID",
              busiParams: {
                status: true,
                studentInstanceId: parseInt(
                  window.localStorage.studentInstanceId
                ),
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setLeaveTaken(data.respData[0].respMsg.result);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

    setInterval(() => {
      setMyLoader(false);
    }, 2000);
  }, [leaveAdded]);

  const handleButtonClick = async () => {
    setLoader(true);
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_ADD_STUDENT_ LEAVE",
              busiParams: leave,
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.respData[0].respMsg.status === "Success") {
            setSucc(true);
            setLeaveAdded(!leaveAdded);
          } else {
            setNotiStatus("error");
            setNotiMessage(data.respData[0].respMsg.message);
            setNotiSnack(true);
          }
        })
        .catch((data) => {
          console.error(data);
          setError(true);
        });
    } catch (error) {
      console.log(error);
      setError(true);
    }
    setLeave({
      ...leave,
      fromDate: null,
      loginUserId: 0,
      remarks: " ",
      studentInstanceId: 0,
      subject: " ",
      toDate: null,
    });
    setLoader(false);
  };

  return (
    <React.Fragment>
      <Grid container direction="row">
        <Grid
          item
          container
          className={classes.subContainer}
          direction="column"
          spacing={3}
          sm={9}
        >
          <Grid item container spacing={5}>
            <Grid item>
              <DatePicker
                variant="inline"
                autoOk={true}
                name="fromDate"
                disablePast
                inputVariant="outlined"
                className={classes.textFieldStyle}
                label="From *"
                placeholder="Select Start Date"
                format="DD/MM/yyyy"
                value={leave.fromDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={(date) => {
                  setLeave({
                    ...leave,
                    fromDate: moment(date).format("YYYY-MM-DD").toString(),
                    loginUserId: parseInt(window.localStorage.parentUserId),
                    studentInstanceId: parseInt(
                      window.localStorage.studentInstanceId
                    ),
                  });
                }}
              />
            </Grid>
            <Grid item>
              <DatePicker
                name="toDate"
                autoOk={true}
                variant="inline"
                disablePast
                inputVariant="outlined"
                className={classes.textFieldStyle}
                label="To *"
                placeholder="Select End Date"
                format="DD/MM/yyyy"
                value={leave.toDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={(mydate) => {
                  if (
                    moment(mydate, "YYYY-MM-DD").diff(
                      moment(leave.fromDate, "YYYY-MM-DD")
                    ) >= 0
                  ) {
                    setLeave({
                      ...leave,
                      toDate: moment(mydate).format("YYYY-MM-DD").toString(),
                      loginUserId: parseInt(window.localStorage.parentUserId),
                      studentInstanceId: parseInt(
                        window.localStorage.studentInstanceId
                      ),
                    });
                  } else {
                    setNotiStatus("Warning");
                    setNotiMessage(
                      "To date should be greater than or equal to from date"
                    );
                    setNotiSnack(true);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              id=""
              name="subject"
              variant="outlined"
              label="Subject *"
              placeholder="Wrie Subject Here"
              fullWidth
              color="primary"
              value={leave.subject}
              className={classes.textFieldStyle}
              onChange={(event) => {
                setLeave({
                  ...leave,
                  subject: event.target.value,
                  loginUserId: parseInt(window.localStorage.parentUserId),
                  studentInstanceId: parseInt(
                    window.localStorage.studentInstanceId
                  ),
                });
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id=""
              name="remarks"
              variant="outlined"
              label="Reason *"
              placeholder="Wrie Reason Here"
              fullWidth
              multiline
              rows={6}
              color="primary"
              value={leave.remarks}
              className={classes.textFieldStyle}
              onChange={(event) => {
                setLeave({
                  ...leave,
                  remarks: event.target.value,
                  loginUserId: parseInt(window.localStorage.parentUserId),
                  studentInstanceId: parseInt(
                    window.localStorage.studentInstanceId
                  ),
                });
              }}
            />
          </Grid>

          <Grid item container justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ color: "#ffffff" }}
                onClick={handleButtonClick}
                disabled={
                  leave.toDate === null ||
                  leave.fromDate === null ||
                  leave.subject === null ||
                  leave.remarks === null
                }
              >
                {loader ? (
                  <CircularProgress color={theme.palette.secondary.light} />
                ) : (
                  "Apply Leave"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {myloader ? (
          <CircularProgress color="secondary" />
        ) : (
          <Grid
            item
            container
            direction="column"
            sm={3}
            style={{
              backgroundColor: "white",
              overflow: "scroll",
              maxHeight: "28em",
              width: "30em",
            }}
          >
            <Grid
              item
              container
              spacing={1}
              alignItems="center"
              style={{
                position: "absolute",
                marginLeft: "0.08em",
                width: "21.2em",
                backgroundColor: theme.palette.common.tabs,
              }}
            >
              <Grid item style={{ marginTop: "0.5em" }}>
                <IconButton>
                  <RestoreIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Leave History
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              style={{ marginTop: "4em" }}
            >
              {leaveTaken &&
                orderBy(leaveTaken, (o) => o.fromDate, "desc").map(
                  (item, index) => (
                    <React.Fragment key={item + index}>
                      <Tooltip
                        classes={{
                          tooltip: classes.customTooltip,
                        }}
                        title={`Teachers comment: ${
                          item.comments === null ? "NA" : item.comments
                        }`}
                        aria-label={item.comment}
                        placement="left"
                        style={{ fontSize: "1rem" }}
                        arrow
                      >
                        <Grid
                          item
                          container
                          direction="column"
                          style={{ padding: "0.5em", cursor: "pointer" }}
                        >
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              {`${moment(item.fromDate, "YYYY-MM-DD")
                                .format("DD MMM YYYY")
                                .toString()} ${
                                item.fromDate == item.toDate ? " " : " to "
                              } ${
                                item.fromDate == item.toDate
                                  ? ""
                                  : moment(item.toDate, "YYYY-MM-DD")
                                      .format("DD MMM YYYY")
                                      .toString()
                              }`}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="caption"
                              style={{ color: "black" }}
                            >
                              {item.subject}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            style={{
                              width: "8em",
                              borderRadius: 10,
                              padding: 5,

                              backgroundColor:
                                item.leaveStatus === "Approved"
                                  ? theme.palette.common.completed
                                  : item.leaveStatus === "Rejected"
                                  ? theme.palette.common.warning
                                  : item.leaveStatus === "Pending" ||
                                    item.leaveStatus === "Pending for approval"
                                  ? theme.palette.common.pending
                                  : item.leaveStatus === "To Be Uploaded"
                                  ? theme.palette.common.toupdate
                                  : "inherit",
                            }}
                          >
                            <Typography
                              variant="caption"
                              style={{ color: "white" }}
                              align="center"
                            >
                              {item.leaveStatus}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="caption"
                              style={{ color: "black" }}
                            >
                              {item.remarks}
                            </Typography>
                          </Grid>
                          <Grid item style={{ marginTop: "0.8em" }}>
                            <Divider
                              style={{
                                backgroundColor: theme.palette.common.tabFont,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Tooltip>
                    </React.Fragment>
                  )
                )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={succ}
        autoHideDuration={6000}
        onClose={() => setSucc(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSucc(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="success">Leave Added Successfully!!</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setError(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="error">
          Some Error Has Occured Please Try Again late!!
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={notiSnack}
        autoHideDuration={6000}
        onClose={() => setNotiSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setNotiSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={NotiStatus}>{notiMessage}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
