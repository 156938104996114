import React from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Grid, Typography, Paper, Divider, Dialog } from "@material-ui/core";
import moment from "moment";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: "50vh",
    maxHeight: "80vh",
    marginBottom: "1em",
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.grey
        : theme.palette.common.white,
    width: "42vw",
    minWidth: "20em",
    overflowY: "auto",
    overscrollBehavior: "contained",
    borderRadius: 0,
    padding: 0,
  },

  cardGrid: {
    minHeight: "50vh",

    width: "100%",
    overflowY: "scroll",

    borderRadius: 0,
    padding: 0,
    scrollbarWidth: "none",
  },

  headers: {
    backgroundColor: theme.palette.common.ltgrey,
    width: "42vw",
    minWidth: "20em",
    position: "absolute",
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "0em",
  },

  todoheaderFont: {
    fontWeight: "bold",
  },

  todoItems: {
    marginLeft: "0.5em",
    paddingTop: 0,
    cursor: "pointer",
  },
  tabSelect: {
    backgroundColor: theme.palette.common.blue,
  },

  date: {
    color:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.white
        : theme.palette.common.blue,
  },

  tabStyle: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },

  notificationDialog: {
    backgroundColor: theme.palette.common.ltgrey,
    width: "60vw",
    minWidth: "20em",
    position: "absolute",
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "0em",
  },

  dialogContainer: {
    maxWidth: "100em",
    maxHeight: "45em",
  },

  dialogwidth: {
    maxWidth: "100em",
    maxHeight: "45em",
  },
}));

export default function Cards(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [notificationDialogOpen, setNotificationDialogOpen] = React.useState(
    false
  );
  const [dialogData, setDialogData] = React.useState("");

  const handleDialogOpen = (item) => {
    setNotificationDialogOpen(true);

    setDialogData(item);
  };

  return (
    <React.Fragment>
      <Paper
        className={classes.card}
        classes={{ root: classes.cardColor }}
        style={{ scrollbarWidth: "none" }}
      >
        <Grid
          item
          container
          direction="column"
          spacing={3}
          className={classes.cardGrid}
        >
          <Grid item>
            <Grid
              item
              container
              className={classes.headers}
              direction="row"
              spacing={2}
            >
              <Grid item>
                <NotificationsNoneIcon
                  style={{ color: theme.palette.common.tabFont }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h6" paragraph>
                  {props.title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {props.data.map((item, index) => {
            let datenow = moment().format("YYYY-MM-DD").toString();
            let createdDate = moment(item.created, "DD-MM-YYYY")
              .format("YYYY-MM-DD")
              .toString();

            return (
              <React.Fragment key={item + index}>
                <Grid item>
                  <Grid
                    item
                    container
                    direction="column"
                    spacing={2}
                    onClick={() => handleDialogOpen(item)}
                    className={classes.todoItems}
                    style={{ marginTop: index === 0 ? "2em" : undefined }}
                  >
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        className={classes.date}
                        style={{
                          fontWeight: moment().isSame(createdDate, "day")
                            ? "bolder"
                            : "inherit",
                        }}
                      >
                        {item.date}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {console.log(item)}
                      {item.subjectName && item.subjectName.trim() !== "" ? (
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontWeight: moment().isSame(createdDate, "day")
                              ? "bolder"
                              : "inherit",
                          }}
                        >
                          {`Subject Name: ${item.subjectName}`}
                        </Typography>
                      ) : undefined}

                      <Typography
                        variant="subtitle1"
                        style={{
                          fontWeight: moment().isSame(createdDate, "day")
                            ? "bolder"
                            : "inherit",
                        }}
                      >
                        {item.task}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Divider
                        style={{
                          backgroundColor:
                            theme.typography.myTheme.themeName == "Night"
                              ? theme.palette.common.tabFont
                              : theme.palette.common.ltgrey,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Paper>
      {notificationDialogOpen ? (
        <Dialog
          open={notificationDialogOpen}
          onClose={() => setNotificationDialogOpen(false)}
        >
          <Grid container direction="column">
            <Grid
              item
              container
              justify="space-between"
              style={{
                backgroundColor:
                  theme.typography.myTheme.themeName === "Night"
                    ? theme.palette.common.grey
                    : theme.palette.common.ltgrey,
                padding: "0.65em",
              }}
            >
              <Grid item>
                <NotificationsNoneIcon
                  style={{ color: theme.palette.common.tabFont }}
                />
              </Grid>
              <Grid item>
                <Typography style={{ color: theme.palette.common.tabFont }}>
                  {dialogData.task}
                </Typography>
              </Grid>
              <Grid item style={{ cursor: "pointer" }}>
                <CloseSharpIcon
                  style={{ color: theme.palette.common.tabFont }}
                  onClick={() => setNotificationDialogOpen(false)}
                />
              </Grid>
            </Grid>

            <Grid item container direction="column" style={{ padding: "1em" }}>
              <Grid item style={{ fontWeight: "bold" }}>
                {dialogData.date}
              </Grid>
              <Grid item>{dialogData.description}</Grid>
            </Grid>
          </Grid>
        </Dialog>
      ) : null}
    </React.Fragment>
  );
}
