import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, FormGroup, Grid,
    IconButton, InputLabel,
    ListItemText, MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
    Tooltip, Typography, useMediaQuery
} from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { makeReqBody, url } from './store';
import { useLocation, useHistory } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import NumberFormat from 'react-number-format';
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import TableMaker from './TableMaker';
import InfoIcon from '@material-ui/icons/Info';




const useStyle = makeStyles((theme) => ({
    mainContainer: {
        ...theme.typography.mainContainer,
        height: "100% ",
        minHeight: "130vh",
    },
    subContainer: {
        ...theme.typography.subContainer,
    },
    buttonStyle: {
        padding: "0.5em",
        borderRadius: 5,
    },

    customTooltip: {
        fontSize: "0.85rem",
    },

    toolBar: {
        ...theme.mixins.toolbar,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: "#fff",
    },
    table: {
        " & .MuiTableCell-root": {
            textAlign: "center"
        }
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function QuestionMapSmartQBT(props) {
    const classes = useStyle();
    const theme = useTheme();
    const [loader, setLoader] = useState(false);
    const [backLoader, setBackLoader] = useState(false);
    const [snack, setSnack] = useState(false);
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const location = useLocation();
    const [identifierData, setIdentifierData] = useState([])
    const [chapter, setChapter] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [toReload, setToReLoad] = useState(false);
    const [myLoader, setMyLoader] = useState(false);
    const [actionOfExam, setActionOfExam] = useState("")



    const history = useHistory();

    const [standard, setstandard] = useState(window.localStorage.className.split(","))
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [showTableData, setShowTableData] = useState(false);
    const [saveDraft, setSaveDraft] = useState(false);
    const [showQuestionPreview, setShowQuestionPreview] = useState(false);
    const [questionContent, setQuestionContent] = useState("");
    const [passData, setPassData] = useState(JSON.parse(window.localStorage.passData));
    const [openQuesDailog, setOpenQuestionDailog] = useState(false);
    const [examQuesList, setExamQuesList] = useState([])



    const addMore = {
        standard: [],
        chapterId: 0,
        sectionName: [],
        noOfQuestions: 0,
        importantRating: [],
        difficultyLevel: [],
        questionType: [],
        typology: [],
        showRadioGroup: false,
        tableRows: [],
        showTableData: false,
        questionPicker: "",
        previousQuestions: "yes",
        selectedQuesData: [],
        chapterList: [],
    }



    useEffect(() => {
        setLoader(true);
        setBackLoader(true);
        console.log("passData", passData)
        try {
            fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                    requestList: [{
                        isEncrypt: false,
                        transactionId: "0000001438241244",
                        busiCode: passData.actionOfExam === "CREATED" ? "SCHOOL_GET_SMART_QBT_SUBJECT_IDENTIFIER" : "SCHOOL_GET_SMART_QBT_GET_JSON_FROM_AWS_S3",
                        "busiParams": {
                            boardId: window.localStorage.boardId,
                            branchId: window.localStorage.branchId,
                            sectionId: parseInt(window.localStorage.sectionId),
                            subjectId: window.localStorage.subjectId,
                            examTermId: passData.examTermId,
                            epId: passData.epId,
                            examId: passData.examId,
                        }
                    }],
                    pubInfo: {
                        appId: "appId",
                        sessionId: window.localStorage.sessionId,
                        version: "21",
                        timestamp: "20180314175419",
                        lang: "en_US",
                        userId: "1000016614",
                        serviceId: "7021150585",
                        circleId: "MU",
                        ncsroute: "WE015",
                        customerId: "1000016614",
                        osType: "windows",
                    },
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setLoader(false);
                    setActionOfExam(passData.smartStatus);

                    if (passData.actionOfExam === "CREATED") {
                        data.respData[0].respMsg.result.userId = parseInt(window.localStorage.userId)
                        data.respData[0].respMsg.result.identifierDetails.forEach((element, index, array) => {
                            array[index].addChapter = [];
                            array[index].addChapter.push({
                                standard: [],
                                chapterId: "",
                                sectionName: [],
                                noOfQuestions: 0,
                                importantRating: [],
                                difficultyLevel: [],
                                questionType: [],
                                typology: [],
                                previousQuestions: "yes",
                                chapterList: []
                            });
                        })
                    }
                    if (passData.actionOfExam === "SAVEASDRAFT") {
                        setSaveDraft(true)
                    }

                    setIdentifierData(passData.actionOfExam === "CREATED" ? data.respData[0].respMsg.result : data.respData[0].respMsg.result.identifierData);

                    setBackLoader(false);

                    let sum = 0;
                    let noOfQues = passData.actionOfExam === "CREATED" ? data.respData[0].respMsg.result.identifierDetails : data.respData[0].respMsg.result.identifierData.identifierDetails;
                    for (let i = 0; i < noOfQues.length; i++) {
                        sum += parseInt(noOfQues[i].no_Of_question);
                    }
                    setTotalQuestions(sum)
                    console.log("totalQestion", sum)



                }).catch((data) => {
                    console.error(data);
                    setTimeout(() => {
                        setLoader(false);
                        setBackLoader(false);
                    }, 1000);
                })
        } catch (error) {
            console.error(error)
            setLoader(false)
        }
    }, []);

    useEffect(() => {
        setMyLoader(true);
        try {
            fetch(
                url,
                makeReqBody("SCHOOL_GET_ALL_CHAPTER_SECTION", {
                    admin: false,
                    sectionId: window.localStorage.sectionId,
                    subjectId: window.localStorage.subjectId,
                })).then((response) => response.json())
                .then((data) => {
                    setChapter(data.respData[0].respMsg.result);

                    setMyLoader(true);
                })
                .catch((data) => {
                    console.error(data);
                });
        } catch (error) {
            console.error(error);
        }
    }, []);


    const handlepreviewBtn = () => {
        fetch(url, makeReqBody("SCHOOL_SMART_QBT_GET_SUBJECT_QUES_EXAM", {
            branchId: window.localStorage.branchId,
            examId: passData.examId,
            examTermId: passData.examTermId,
            sectionId: window.localStorage.sectionId,
            subjectId: window.localStorage.subjectId
        })).then(resp => resp.json())
            .then(data => {
                setExamQuesList(data.respData[0].respMsg.result)
                setOpenQuestionDailog(true)
            }).catch(error => {
                console.error(error)
            })
    };




    const handleClearData = (index, idx) => {
        const identifierDataObj = { ...identifierData };
        identifierDataObj.identifierDetails[index].addChapter[idx] = addMore;
        setIdentifierData(identifierDataObj);
    };


    const handleAddMoreChapter = (index) => {
        const identifierDataObj = { ...identifierData };
        identifierDataObj.identifierDetails[index].addChapter.push(addMore);
        setIdentifierData(identifierDataObj)

    };

    const handleRemove = (index, idx) => {

        const identifierDataObj = { ...identifierData };
        identifierDataObj.identifierDetails[index].addChapter.splice(idx, 1);
        setIdentifierData(identifierDataObj)
    }

    const handleSearch = async (index, idx) => {

        const addChapterDataObj = { ...identifierData };


        let chapterId = addChapterDataObj.identifierDetails[index].addChapter[idx].chapterId;
        let chapterSectionId = addChapterDataObj.identifierDetails[index].addChapter[idx].sectionName;
        let difficultyId = addChapterDataObj.identifierDetails[index].addChapter[idx].difficultyLevel.map(x => x.difficultyId);
        let impRatingId = addChapterDataObj.identifierDetails[index].addChapter[idx].importantRating.map(x => x.ratingId);
        let qTypeId = addChapterDataObj.identifierDetails[index].addChapter[idx].questionType.map(x => x.questionTypeId);
        let typologyId = addChapterDataObj.identifierDetails[index].addChapter[idx].typology.map(x => x.typologyId);


        setMyLoader(true);
        try {
            await fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                    requestList: [{
                        isEncrypt: false,
                        transactionId: "0000001438241244",
                        busiCode: "SCHOOL_GET_SMART_QBT_QUESTIONS_OF_ITQ",
                        busiParams: {
                            branchId: parseInt(window.localStorage.branchId),
                            chapterId: chapterId,
                            chapterSectionId: chapterSectionId,
                            difficultyLevel: difficultyId.toString(),
                            importantRating: impRatingId.toString(),
                            questionType: qTypeId.toString(),
                            typlogy: typologyId.toString(),
                            examTermId: passData.examTermId,
                            sectionId: window.localStorage.sectionId,
                            identifierId: identifierData.identifierDetails[index].identifierId.toString()
                        }
                    }],
                    pubInfo: {
                        appId: "appId",
                        sessionId: window.localStorage.sessionId,
                        version: "21",
                        timestamp: "20180314175419",
                        lang: "en_US",
                        userId: "1000016614",
                        serviceId: "7021150585",
                        circleId: "MU",
                        ncsroute: "WE015",
                        customerId: "1000016614",
                        osType: "windows",
                    },
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    let questions = data.respData[0].respMsg.result;
                    addChapterDataObj.identifierDetails[index].addChapter[idx].count = questions[0].count;
                    // addChapterDataObj.identifierDetails[index].addChapter[idx].rows = questions[0].questionDetails;

                    if (data.respData[0].respMsg.status == "Success") {
                        setShowTableData(true);
                    }

                    addChapterDataObj.identifierDetails[index].addChapter[idx].tableRows = data.respData[0].respMsg.result[0].questionDetails;
                    addChapterDataObj.identifierDetails[index].addChapter[idx].showRadioGroup = true

                    setIdentifierData(addChapterDataObj);
                    setMyLoader(false);


                })
        } catch (error) {
            console.error(error)
        }
    };


    const handleAddStandard = (e, index, idx) => {
        const value = e.target.value;
        const identifierDataObj = { ...identifierData }
        let standardData = identifierDataObj.identifierDetails[index].addChapter[idx];
        if (value[value.length - 1] === "all") {
            standardData.standard = standardData.standard.length === standard.length ? [] : standard
        } else {
            standardData.standard = value;
        }
        standardData.chapterId = 0;
        standardData.sectionName = [];
        standardData.difficultyLevel = [];
        standardData.typology = [];
        standardData.questionType = [];
        standardData.importantRating = [];
        standardData.noOfQuestions = 0;
        standardData.showTableData = false;
        standardData.showRadioGroup = false;
        standardData.questionPicker = "";
        standardData.tableRows = [];

        let mychapters = [];
        chapter.map((item, index) => {
            for (let i = 0; i < standardData.standard.length; i++) {
                if (item.standardId == standardData.standard[i]) {
                    mychapters.push({
                        chapterName: item.chapterName,
                        chapterId: item.chapterId,
                        standardId: item.standardId,
                    });
                }
            }
        });

        standardData.chapterList = mychapters;
        setIdentifierData(identifierDataObj);
    };


    const addChapterId = (e, index, idx) => {
        const { value } = e.target;

        const addChapterDataObj = { ...identifierData }
        addChapterDataObj.identifierDetails[index].addChapter[idx].chapterId = value;
        addChapterDataObj.identifierDetails[index].addChapter[idx].sectionName = [];
        addChapterDataObj.identifierDetails[index].addChapter[idx].difficultyLevel = [];
        addChapterDataObj.identifierDetails[index].addChapter[idx].typology = [];
        addChapterDataObj.identifierDetails[index].addChapter[idx].tableRows = [];
        addChapterDataObj.identifierDetails[index].addChapter[idx].questionType = [];
        addChapterDataObj.identifierDetails[index].addChapter[idx].importantRating = [];
        addChapterDataObj.identifierDetails[index].addChapter[idx].noOfQuestions = 0;
        addChapterDataObj.identifierDetails[index].addChapter[idx].showTableData = false;
        addChapterDataObj.identifierDetails[index].addChapter[idx].showRadioGroup = false;
        addChapterDataObj.identifierDetails[index].addChapter[idx].questionPicker = "";

        setMyLoader(true)
        try {
            fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                    requestList: [{
                        isEncrypt: false,
                        transactionId: "0000001438241244",
                        busiCode: "SCHOOL_GET_CH_SECTION_DETAILS_BY_CID",
                        busiParams: {
                            admin: false,
                            id: value,
                        },
                    }],
                    pubInfo: {
                        appId: "appId",
                        sessionId: window.localStorage.sessionId,
                        version: "21",
                        timestamp: "20180314175419",
                        lang: "en_US",
                        userId: "1000016614",
                        serviceId: "7021150585",
                        circleId: "MU",
                        ncsroute: "WE015",
                        customerId: "1000016614",
                        osType: "windows",
                    },
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    let mychapterSection = data.respData[0].respMsg.result.map(item => ({
                        chapterSectionId: item.chapterSectionId,
                        sectionName: item.sectionName,
                    }));
                    addChapterDataObj.identifierDetails[index].addChapter[idx].chapterSectionIdData = mychapterSection;
                    setIdentifierData(addChapterDataObj);
                    setMyLoader(false);
                })
                .catch((data) => {
                    console.error(data);
                    setMyLoader(false);
                });
        } catch (data) {
            console.error(data);
        }
    };


    const addSectionId = (e, index, idx) => {
        const value = e.target.value;
        const addChapterDataObj = { ...identifierData };
        addChapterDataObj.identifierDetails[index].addChapter[idx].difficultyLevel = []
        addChapterDataObj.identifierDetails[index].addChapter[idx].typology = []
        addChapterDataObj.identifierDetails[index].addChapter[idx].questionType = []
        addChapterDataObj.identifierDetails[index].addChapter[idx].importantRating = [];
        addChapterDataObj.identifierDetails[index].addChapter[idx].noOfQuestions = 0;
        addChapterDataObj.identifierDetails[index].addChapter[idx].showTableData = false;
        addChapterDataObj.identifierDetails[index].addChapter[idx].showRadioGroup = false;
        addChapterDataObj.identifierDetails[index].addChapter[idx].questionPicker = "";



        if (value[value.length - 1] === "all") {
            let sectionValue = addChapterDataObj.identifierDetails[index].addChapter[idx];
            sectionValue.sectionName = sectionValue.sectionName.length === sectionValue.chapterSectionIdData.length ? [] : sectionValue.chapterSectionIdData;
        } else {
            addChapterDataObj.identifierDetails[index].addChapter[idx].sectionName = value;
        }

        let chapterId = addChapterDataObj.identifierDetails[index].addChapter[idx].chapterId;
        setMyLoader(true);

        try {
            fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                    requestList: [{
                        isEncrypt: false,
                        transactionId: "0000001438241244",
                        busiCode: "SCHOOL_GET_SMART_QBT_DETAILS_OF_ITQ",
                        busiParams: {
                            branchId: parseInt(window.localStorage.branchId),
                            chapterId: chapterId,
                            chapterSectionId: identifierData.identifierDetails[index].addChapter[idx].sectionName,
                            identifierId: identifierData.identifierDetails[index].identifierId
                        }
                    }],
                    pubInfo: {
                        appId: "appId",
                        sessionId: window.localStorage.sessionId,
                        version: "21",
                        timestamp: "20180314175419",
                        lang: "en_US",
                        userId: "1000016614",
                        serviceId: "7021150585",
                        circleId: "MU",
                        ncsroute: "WE015",
                        customerId: "1000016614",
                        osType: "windows",
                    },
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("Data", data.respData[0].respMsg);

                    let myAttribute = data.respData[0].respMsg.result;

                    addChapterDataObj.identifierDetails[index].addChapter[idx].difficultDetailsList = myAttribute.difficultDetails;
                    addChapterDataObj.identifierDetails[index].addChapter[idx].questionDetailsList = myAttribute.questionDetails;
                    addChapterDataObj.identifierDetails[index].addChapter[idx].impRatingList = myAttribute.ratingDetails;
                    addChapterDataObj.identifierDetails[index].addChapter[idx].typologyList = myAttribute.typologyDetails;

                    setIdentifierData(addChapterDataObj);
                    setMyLoader(false);

                })
        } catch (error) {
            console.error(error)
        }


    };

    const radioGroupChange = (e, index, idx) => {
        const addChapterDataObj = { ...identifierData }
        addChapterDataObj.identifierDetails[index].addChapter[idx].questionPicker = e.target.value;

        let prevQData = addChapterDataObj.identifierDetails[index].addChapter[idx];

        if (prevQData.questionPicker !== "auto") {
            prevQData.previousQuestions = "yes"
        }

        setIdentifierData(addChapterDataObj);
        setSaveDraft(true);
    }


    const handlePreviousQuestion = (e, index, idx) => {
        const addChapterDataObj = { ...identifierData }
        addChapterDataObj.identifierDetails[index].addChapter[idx].previousQuestions = e.target.value;
        setIdentifierData(addChapterDataObj);
        setSaveDraft(true);
        console.log("function call")
    }


    const headers = [
        "Q.No",
        "Question ID",
        "Question Prev",
        "Section Name",
        "Important Rating",
        "Difficulty Level",
        "Appear in Previous"
    ];

    const handleCheckQuestion = (e, index, addIdx, index2) => {
        const addChapterDataObj = { ...identifierData }
        addChapterDataObj.identifierDetails[index].addChapter[addIdx].tableRows[index2].checked = e.target.checked
        console.log("e", e.target.checked)
        setIdentifierData(addChapterDataObj);
    };

    const handleSaveAsDraft = () => {
        setLoader(true);
        try {
            fetch(url,
                makeReqBody(window.localStorage.boardId > 6 ? "SCHOOL_GET_SMART_QBT_SAVE_AS_DRAFT" : "SCHOOL_SAVE_AS_DRAFT_EXAM_IN_S3",
                    { identifierData })
            ).then((response) => response.json())
                .then((data) => {
                    if (data.respData[0].respMsg.status === "Success") {
                        setStatus("success");
                        setMessage("Saved as Draft Successfully");
                        setSnack(true);
                        setTimeout(() => {
                            setLoader(false);
                        }, 1000)

                    } else {
                        setStatus("error");
                        setMessage(data.respData[0].respMsg.message);
                        setSnack(true);
                        setTimeout(() => {
                            setLoader(false);
                        }, 1000)
                    }
                    console.log(data);
                    setMyLoader(false);
                })
                .catch((data) => {
                    console.error(data);
                    setMyLoader(false);
                });
        } catch (data) {
            console.error(data);
        }
    };

    const handleSubmitQues = () => {
        let noOfQ = [];
        let count = [];
        let checkedValue = [];
        let questionPicker = [];
        let chapterIdData = [];
        let chapterSection = [];
        let impRatingId = [];
        let difficultyData = [];
        let questionTypeData = [];
        let typologyData = [];
        let previousQues = [];
        let previousQuesVal = [];

        previousQuesVal = identifierData.identifierDetails.map(item => item.addChapter.map(x => x.previousQuestions));
        previousQues = identifierData.identifierDetails.map((item) => item.addChapter.map(x => x.tableRows && x.tableRows.map(y => y.previousQues).filter(x => x === false)))
        let no_Of_question = identifierData.identifierDetails.map(item => item.no_Of_question);
        count = identifierData.identifierDetails.map((item) => item.addChapter.map(x => x.count));
        questionPicker = identifierData.identifierDetails.map((item) => item.addChapter.map(x => x.questionPicker));
        noOfQ = identifierData.identifierDetails.map((item) => item.addChapter.map(x => x.noOfQuestions));
        checkedValue = identifierData.identifierDetails.map((item) => item.addChapter.map(x => x.tableRows && x.tableRows.map(y => y.checked).filter(x => x === true)))
        chapterIdData = identifierData.identifierDetails.map(item => item.addChapter.map(x => x.chapterId));
        chapterSection = identifierData.identifierDetails.map(item => item.addChapter.map(x => x.sectionName));
        impRatingId = identifierData.identifierDetails.map(item => item.addChapter.map(x => x.importantRating));
        difficultyData = identifierData.identifierDetails.map(item => item.addChapter.map(x => x.difficultyLevel));
        questionTypeData = identifierData.identifierDetails.map(item => item.addChapter.map(x => x.questionType));
        typologyData = identifierData.identifierDetails.map(item => item.addChapter.map(x => x.typology));


        console.log("previousQues", previousQues)
        console.log("previousQuesVal", previousQuesVal)

        let totalNoOfQuestions = 0;
        let noQes = noOfQ.flat();
        for (var i = 0; i < noQes.length; i++) {
            totalNoOfQuestions += parseInt(noQes[i]);
        }

        let selectedNoOfQues = [0, 0, 0, 0, 0, 0];
        for (var i = 0; i < noOfQ.length; i++) {
            for (var j = 0; j < noOfQ[i].length; j++) {
                selectedNoOfQues[i] += parseInt(noOfQ[i][j])

            }
        }
        for (var i = 0; i < noOfQ.length; i++) {
            for (var j = 0; j < noOfQ[i].length; j++) {
                if (noOfQ[i][j] == 0 ||
                    chapterIdData[i][j] == 0 ||
                    chapterSection[i][j].length == 0 ||
                    impRatingId[i][j].length == 0 ||
                    difficultyData[i][j].length == 0 ||
                    questionTypeData[i][j].length == 0 ||
                    typologyData[i][j].length == 0 ||
                    questionPicker[i][j] == ""
                ) {
                    setMessage("Please Fill the mandatory fields.");
                    setStatus("warning")
                    setSnack(true);
                    return false;
                } else if (noOfQ[i][j] > count[i][j]) {
                    setMessage("No. of question should be less then available question count");
                    setStatus("warning")
                    setSnack(true);
                    return false;
                } else if (questionPicker[i][j] === "manual" && noOfQ[i][j] != checkedValue[i][j].length) {
                    setMessage(`Please select ${noOfQ[i][j]} Questions to this chapter`);
                    setStatus("warning")
                    setSnack(true);
                    return;
                } else if (no_Of_question[i] !== selectedNoOfQues[i]) {
                    setMessage(`You have to select ${no_Of_question[i]} Questions only to this Identifier`);
                    setStatus("warning")
                    setSnack(true);
                    return;
                } else if (totalQuestions !== totalNoOfQuestions) {
                    setMessage(`You have to select total ${totalQuestions} Questions.`);
                    setStatus("warning")
                    setSnack(true);
                    return;
                } else if (questionPicker[i][j] === "auto" && previousQuesVal[i][j] === "") {
                    setMessage(`Please choose Previous Exam Qestions.`);
                    setStatus("warning")
                    setSnack(true);
                    return;
                } else if (previousQuesVal[i][j] === "no" && previousQues[i][j].length < noOfQ[i][j]) {
                    setMessage(`Question count is lesser in question bank, Please recheck selection filters.`);
                    setStatus("warning")
                    setSnack(true);
                    return;
                }
            }

        };

        setMyLoader(true)
        try {
            fetch(
                url,
                makeReqBody(window.localStorage.boardId > 6 ? "SCHOOL_GET_SMART_QBT_SAVE_EXAM_BY_SUBJECT_WISE" : "SCHOOL_SAVE_SUBMIT_EXAM_IN_S3", { identifierData }))
                .then((response) => response.json())
                .then((data) => {
                    setMyLoader(false);
                    if (data.respData[0].respMsg.status === "Success") {
                        setStatus("success");
                        setMessage(data.respData[0].respMsg.message);
                        setSnack(true);
                        setTimeout(() => {
                            history.push("/ExamMode/eExam")
                        }, 500)
                    } else {
                        setStatus("error");
                        setMessage(data.respData[0].respMsg.message);
                        setSnack(true);
                    }
                    console.log(data);
                })
                .catch((data) => {
                    console.error(data);
                    setMyLoader(false);
                });
        } catch (data) {
            console.error(data);
        }
    };




    return (
        <>
            <Grid
                container
                direction="row"
                className={classes.mainContainer}
                style={{ width: `calc(100% - 12em)` }}
            >
                <Grid
                    container
                    direction="column"
                    spacing={2}
                    className={classes.subContainer}
                >
                    <Grid item container spacing={2}>
                        {loader === true ? (
                            <CircularProgress color="secondary" />
                        ) : (
                            <>
                                <Grid item container justify="space-between">
                                    <Grid item sm={8} row container alignItems="center" >
                                        <Tooltip title="Go Back" arrow>
                                            <IconButton onClick={() => {
                                                window.localStorage.removeItem("passData")
                                                history.goBack()
                                            }}>
                                                <KeyboardBackspaceIcon style={{ color: "#000" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Typography variant="h5">Back To Exam Zone</Typography>

                                    </Grid>
                                </Grid>

                                <Grid item container justify="space-between">
                                    <Grid item sm={2}>
                                        <Typography variant="h5" style={{ fontWeight: "bold" }}>Question Mapping</Typography>
                                    </Grid>
                                    <Grid item sm={2} >
                                        <Typography variant="h5" style={{ fontWeight: "bold", textAlign: "center" }}>Total Question - {totalQuestions}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item container spacing={2}>
                                    {identifierData && identifierData.identifierDetails &&
                                        identifierData.identifierDetails.map((item, index) => (
                                            <Grid item sm={12} key={index}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        style={{ backgroundColor: "#f2f2f240" }}
                                                    >
                                                        <Grid container justify="space-between">
                                                            <Grid item sm={4}>
                                                                <Typography variant="h5" style={{ fontWeight: "bold" }}>{identifierData.subjectName} - {item.identifierName}</Typography>
                                                            </Grid>
                                                            <Grid item sm={2}>
                                                                <Typography variant="h6"
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        color: "blue",
                                                                        textAlign: "center"
                                                                    }}
                                                                >No. Of Questions - {item.no_Of_question}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid container direction="column" spacing={2} >
                                                            {item.addChapter && item.addChapter.map((item1, addIdx) => (
                                                                <Grid item container spacing={2} key={addIdx} style={{ marginTop: addIdx !== 0 && "1em" }} >
                                                                    <Grid item container sm={9} spacing={2}>
                                                                        {window.localStorage.boardId > 6 &&
                                                                            <Grid item sm={3}>
                                                                                <FormControl variant="outlined" fullWidth >
                                                                                    <InputLabel id="mutiple-stadard-label">Standard *</InputLabel>
                                                                                    <Select
                                                                                        disabled={actionOfExam === "PUBLISHED"}
                                                                                        labelId="mutiple-stadard-label"
                                                                                        multiple
                                                                                        label="Standard *"
                                                                                        name={`standard${addIdx}`}
                                                                                        value={item1.standard}
                                                                                        onChange={(e) => handleAddStandard(e, index, addIdx)}
                                                                                        renderValue={(selected) => selected.join(", ")}
                                                                                        MenuProps={{ getContentAnchorEl: null, }}
                                                                                    >
                                                                                        <MenuItem
                                                                                            disabled={item1.standard.length == standard.length}
                                                                                            value="all"
                                                                                        >
                                                                                            <Checkbox checked={item1.standard.length == standard.length} />
                                                                                            <ListItemText primary="Select All" />
                                                                                        </MenuItem>
                                                                                        {standard.map((option) => (
                                                                                            <MenuItem key={option} value={option}>
                                                                                                <Checkbox checked={item1.standard.indexOf(option) > -1} />
                                                                                                <ListItemText primary={option} />
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Grid>}
                                                                        {window.localStorage.boardId > 6 ?
                                                                            <Grid item sm={3}>
                                                                                <TextField
                                                                                    id=""
                                                                                    labelId="mutiple-chapter-label"
                                                                                    select
                                                                                    variant="outlined"
                                                                                    disabled={actionOfExam === "PUBLISHED"}
                                                                                    fullWidth
                                                                                    label="Chapter Name *"
                                                                                    name="chapterName"
                                                                                    value={item1.chapterId}
                                                                                    onChange={(e) => addChapterId(e, index, addIdx)}
                                                                                >
                                                                                    {item1.chapterList && item1.chapterList.map((item, idx) => (
                                                                                        <MenuItem key={idx} value={item.chapterId}>
                                                                                            {item.chapterName}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid> :
                                                                            <Grid item sm={3}>
                                                                                <TextField
                                                                                    id=""
                                                                                    labelId="mutiple-chapter-label"
                                                                                    select
                                                                                    variant="outlined"
                                                                                    disabled={actionOfExam === "PUBLISHED"}
                                                                                    fullWidth
                                                                                    label="Chapter Name *"
                                                                                    name="chapterName"
                                                                                    value={item1.chapterId}
                                                                                    onChange={(e) => addChapterId(e, index, addIdx)}
                                                                                >
                                                                                    {chapter && chapter.map((item, idx) => (
                                                                                        <MenuItem key={idx} value={item.chapterId}>
                                                                                            {item.chapterName}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid>}

                                                                        <Grid item sm={3}>
                                                                            <FormControl variant="outlined" fullWidth >
                                                                                <InputLabel id="Chapter-dropdown">Chapter Sec Name *</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-outlined-label"
                                                                                    id="demo-simple-select-outlined"
                                                                                    multiple
                                                                                    value={item1.sectionName}
                                                                                    label="Chapter Sec Name *"
                                                                                    onChange={(e) => addSectionId(e, index, addIdx)}
                                                                                    renderValue={(selected) =>
                                                                                        selected.map(item => item.sectionName).join(", ")
                                                                                    }
                                                                                    MenuProps={{
                                                                                        getContentAnchorEl: null,
                                                                                    }}
                                                                                    disabled={item1.chapterId === 0 || actionOfExam === "PUBLISHED"}
                                                                                >
                                                                                    <MenuItem value="all" disabled={item1.chapterSectionIdData == 0 || item1.chapterSectionIdData == null}>
                                                                                        <Checkbox checked={item1.chapterSectionIdData && item1.sectionName.length === item1.chapterSectionIdData.length} />
                                                                                        <ListItemText primary="Select All" />
                                                                                    </MenuItem>
                                                                                    {item1.chapterSectionIdData && item1.chapterSectionIdData.map((item, index) => (
                                                                                        <MenuItem key={index} value={item}>
                                                                                            <Checkbox checked={item1.sectionName.indexOf(item) > -1} />
                                                                                            <ListItemText primary={item.sectionName} />
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>


                                                                        <Grid item sm={3}>
                                                                            <NumberFormat
                                                                                value={item1.noOfQuestions}
                                                                                customInput={TextField}
                                                                                variant="outlined"
                                                                                disabled={actionOfExam === "PUBLISHED"}
                                                                                id="no_Of_question"
                                                                                fullWidth
                                                                                label="No. Of Questions *"
                                                                                allowNegative={false}
                                                                                // inputProps={{ maxLength: "2" }}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    if (value >= 0) {
                                                                                        const identifierDataObj = { ...identifierData }
                                                                                        identifierDataObj.identifierDetails[index].addChapter[addIdx].noOfQuestions = value;
                                                                                        setIdentifierData(identifierDataObj)
                                                                                    } else {
                                                                                        setStatus("Warning");
                                                                                        setMessage("Negative Value Not Allowed");
                                                                                        setSnack(true);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item sm={3}>
                                                                            <FormControl variant="outlined" fullWidth >
                                                                                <InputLabel id="Chapter-dropdown">Important Rating *</InputLabel>
                                                                                <Select
                                                                                    labelId={`${addIdx}-simple-select-outlined-label`}
                                                                                    id={`${addIdx}-simple-select-outlined-label`}
                                                                                    multiple
                                                                                    value={item1.importantRating}
                                                                                    label="Important Rating *"
                                                                                    onChange={(e) => {
                                                                                        const value = e.target.value;
                                                                                        const identifierDataObj = { ...identifierData }

                                                                                        if (value[value.length - 1] === "all") {
                                                                                            identifierDataObj.identifierDetails[index].addChapter[addIdx].importantRating = item1.importantRating.length === item1.impRatingList.length ? [] : item1.impRatingList;
                                                                                            setIdentifierData(identifierDataObj);
                                                                                            return;
                                                                                        }
                                                                                        identifierDataObj.identifierDetails[index].addChapter[addIdx].importantRating = value;
                                                                                        setIdentifierData(identifierDataObj);
                                                                                    }}
                                                                                    renderValue={(selected) =>
                                                                                        selected.map(item => item.ratingName).join(", ")
                                                                                    }
                                                                                    MenuProps={{
                                                                                        getContentAnchorEl: null,
                                                                                    }}
                                                                                    disabled={item1.chapterId === 0 || actionOfExam === "PUBLISHED"}
                                                                                >
                                                                                    <MenuItem value="all" disabled={item1.impRatingList == 0 || item1.impRatingList == null}>
                                                                                        <Checkbox checked={item1.impRatingList && item1.importantRating.length == item1.impRatingList.length} />
                                                                                        <ListItemText primary="Select All" />
                                                                                    </MenuItem>
                                                                                    {item1.impRatingList && item1.impRatingList.map((item, index) => (
                                                                                        <MenuItem key={index} value={item}>
                                                                                            <Checkbox checked={item1.importantRating.indexOf(item) > -1} />
                                                                                            <ListItemText primary={item.ratingName} />
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>

                                                                        <Grid item sm={3}>
                                                                            <FormControl variant="outlined" fullWidth >
                                                                                <InputLabel id="Chapter-dropdown">Difficulty Level *</InputLabel>
                                                                                <Select
                                                                                    labelId={`${addIdx}-simple-select-outlined-label`}
                                                                                    id={`${addIdx}-simple-select-outlined-label`}
                                                                                    multiple
                                                                                    value={item1.difficultyLevel}
                                                                                    label="Difficulty Level *"
                                                                                    onChange={(e) => {
                                                                                        const value = e.target.value;
                                                                                        const identifierDataObj = { ...identifierData }

                                                                                        if (value[value.length - 1] === "all") {
                                                                                            identifierDataObj.identifierDetails[index].addChapter[addIdx].difficultyLevel = item1.difficultyLevel.length === item1.difficultDetailsList.length ? [] : item1.difficultDetailsList;
                                                                                            setIdentifierData(identifierDataObj);
                                                                                            return;
                                                                                        }
                                                                                        identifierDataObj.identifierDetails[index].addChapter[addIdx].difficultyLevel = value;
                                                                                        setIdentifierData(identifierDataObj);
                                                                                    }}
                                                                                    renderValue={(selected) =>
                                                                                        selected.map(item => item.difficultyName).join(", ")
                                                                                    }
                                                                                    MenuProps={{
                                                                                        getContentAnchorEl: null,
                                                                                    }}
                                                                                    disabled={item1.chapterId === 0 || actionOfExam === "PUBLISHED"}
                                                                                >
                                                                                    <MenuItem value="all" disabled={item1.difficultDetailsList == 0 || item1.difficultDetailsList == null}>
                                                                                        <Checkbox checked={item1.difficultDetailsList && item1.difficultyLevel.length == item1.difficultDetailsList.length} />
                                                                                        <ListItemText primary="Select All" />
                                                                                    </MenuItem>
                                                                                    {item1.difficultDetailsList && item1.difficultDetailsList.map((item, index) => (
                                                                                        <MenuItem key={index} value={item}>
                                                                                            <Checkbox checked={item1.difficultyLevel.indexOf(item) > -1} />
                                                                                            <ListItemText primary={item.difficultyName} />
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>

                                                                        <Grid item sm={3}>
                                                                            <FormControl variant="outlined" fullWidth >
                                                                                <InputLabel id="Chapter-dropdown">Question Type *</InputLabel>
                                                                                <Select
                                                                                    labelId={`${addIdx}-simple-select-outlined-label`}
                                                                                    id={`${addIdx}-simple-select-outlined-label`}
                                                                                    multiple
                                                                                    value={item1.questionType}
                                                                                    label="Question Type *"
                                                                                    onChange={(e) => {
                                                                                        console.log(e);
                                                                                        const value = e.target.value;
                                                                                        const identifierDataObj = { ...identifierData }

                                                                                        if (value[value.length - 1] === "all") {
                                                                                            identifierDataObj.identifierDetails[index].addChapter[addIdx].questionType = item1.questionType.length === item1.questionDetailsList.length ? [] : item1.questionDetailsList;
                                                                                            setIdentifierData(identifierDataObj);
                                                                                            return;
                                                                                        }
                                                                                        identifierDataObj.identifierDetails[index].addChapter[addIdx].questionType = value;
                                                                                        setIdentifierData(identifierDataObj);
                                                                                    }}
                                                                                    renderValue={(selected) =>
                                                                                        selected.map(item => item.questionType).join(", ")
                                                                                    }
                                                                                    MenuProps={{
                                                                                        getContentAnchorEl: null,
                                                                                    }}
                                                                                    disabled={item1.chapterId === 0 || actionOfExam === "PUBLISHED"}
                                                                                >
                                                                                    <MenuItem value="all" disabled={item1.questionDetailsList == 0 || item1.questionDetailsList == null}>
                                                                                        <Checkbox checked={item1.questionDetailsList && item1.questionType.length == item1.questionDetailsList.length} />
                                                                                        <ListItemText primary="Select All" />
                                                                                    </MenuItem>
                                                                                    {item1.questionDetailsList && item1.questionDetailsList.map((item, index) => (
                                                                                        <MenuItem key={index} value={item}>
                                                                                            <Checkbox checked={item1.questionType.indexOf(item) > -1} />
                                                                                            <ListItemText primary={item.questionType} />
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>

                                                                        <Grid item sm={3}>
                                                                            <FormControl variant="outlined" fullWidth >
                                                                                <InputLabel id="Chapter-dropdown">Typology *</InputLabel>
                                                                                <Select
                                                                                    labelId={`${addIdx}-simple-select-outlined-label`}
                                                                                    id={`${addIdx}-simple-select-outlined-label`}
                                                                                    multiple
                                                                                    value={item1.typology}
                                                                                    label="Typology *"
                                                                                    onChange={(e) => {
                                                                                        console.log(e);
                                                                                        const value = e.target.value;
                                                                                        const identifierDataObj = { ...identifierData }

                                                                                        if (value[value.length - 1] === "all") {
                                                                                            identifierDataObj.identifierDetails[index].addChapter[addIdx].typology = item1.typology.length === item1.typologyList.length ? [] : item1.typologyList;
                                                                                            setIdentifierData(identifierDataObj);
                                                                                            return;
                                                                                        }
                                                                                        identifierDataObj.identifierDetails[index].addChapter[addIdx].typology = value;
                                                                                        setIdentifierData(identifierDataObj);
                                                                                    }}
                                                                                    renderValue={(selected) =>
                                                                                        selected.map(item => item.typlogyName).join(", ")
                                                                                    }
                                                                                    MenuProps={{
                                                                                        getContentAnchorEl: null,
                                                                                    }}
                                                                                    disabled={item1.chapterId === 0 || actionOfExam === "PUBLISHED"}
                                                                                >
                                                                                    <MenuItem value="all" disabled={item1.typologyList == 0 || item1.typologyList == null}>
                                                                                        <Checkbox checked={item1.typologyList && item1.typology.length == item1.typologyList.length} />
                                                                                        <ListItemText primary="Select All" />
                                                                                    </MenuItem>
                                                                                    {item1.typologyList && item1.typologyList.map((item, index) => (
                                                                                        <MenuItem key={index} value={item}>
                                                                                            <Checkbox checked={item1.typology.indexOf(item) > -1} />
                                                                                            <ListItemText primary={item.typlogyName} />
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>

                                                                    </Grid>
                                                                    <Grid item container sm={3} alignItems="center" >
                                                                        <Grid item sm={9}>
                                                                            <Button
                                                                                disabled={actionOfExam === "PUBLISHED"}
                                                                                variant="contained"
                                                                                startIcon={addIdx !== 0 && <IndeterminateCheckBoxIcon />}
                                                                                color={addIdx === 0 ? "secondary" : "error"}
                                                                                onClick={addIdx === 0 ? () => handleAddMoreChapter(index) :
                                                                                    () => handleRemove(index, addIdx)}
                                                                            >
                                                                                {addIdx === 0 ? "Add More Chapter" : "Remove Chaper"}
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid item sm={3}>
                                                                            <Button
                                                                                disabled={actionOfExam === "PUBLISHED"}
                                                                                variant="text"
                                                                                color="secondary"
                                                                                onClick={() => handleClearData(index, addIdx)}
                                                                            >
                                                                                Clear
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid item sm={6}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                disabled={
                                                                                    item1.importantRating.map(x => x.ratingId) == 0 ||
                                                                                    item1.difficultyLevel.map(x => x.difficultyId) == 0 ||
                                                                                    item1.questionType.map(x => x.questionTypeId) == 0 ||
                                                                                    item1.typology.map(x => x.typologyId) == 0 ||
                                                                                    actionOfExam === "PUBLISHED"
                                                                                }
                                                                                style={{ color: "white", }}
                                                                                onClick={() => handleSearch(index, addIdx)}
                                                                            >
                                                                                Search
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid item sm={6}>
                                                                            <NumberFormat
                                                                                value={item1.noOfQuestions}
                                                                                customInput={TextField}
                                                                                variant="outlined"
                                                                                id="count"
                                                                                fullWidth
                                                                                disabled
                                                                                value={item1.count}
                                                                                label="Available Count"
                                                                            />

                                                                        </Grid>
                                                                    </Grid>

                                                                    {item1.showRadioGroup == true &&
                                                                        <Grid item container sm={12}>
                                                                            <Grid item sm={7}>
                                                                                <FormControl component="fieldset" disabled={actionOfExam === "PUBLISHED"}>
                                                                                    <RadioGroup
                                                                                        aria-label="questionMaping"
                                                                                        name="quesionPicker"
                                                                                        row
                                                                                        value={item1.questionPicker}
                                                                                        onChange={(e) => radioGroupChange(e, index, addIdx)}
                                                                                    >
                                                                                        <FormControlLabel
                                                                                            value="manual"
                                                                                            name="manual"
                                                                                            control={<Radio size="small" />}
                                                                                            label="Manual Question Mapping"
                                                                                        />
                                                                                        <FormControlLabel
                                                                                            value="auto"
                                                                                            name="auto"
                                                                                            control={<Radio size="small" />}
                                                                                            label="System Generated Question Mapping"
                                                                                        />
                                                                                    </RadioGroup>
                                                                                </FormControl>
                                                                            </Grid>
                                                                            {item1.questionPicker === "auto" &&
                                                                                <Grid container item sm={5} direction="row" alignItems="center">
                                                                                    <Grid item sm={5}>
                                                                                        <Typography variant="h5" style={{ fontSize: "1.2em", fontWeight: "bold" }} >Previous Exam Qestions</Typography>
                                                                                    </Grid>
                                                                                    <Grid item sm={7}>
                                                                                        <FormControl component="fieldset" disabled={actionOfExam === "PUBLISHED"}>
                                                                                            <RadioGroup
                                                                                                aria-label="questionMaping"
                                                                                                name="previousQuestions"
                                                                                                row
                                                                                                value={item1.previousQuestions}
                                                                                                onChange={(e) => handlePreviousQuestion(e, index, addIdx)}
                                                                                            >
                                                                                                <FormControlLabel
                                                                                                    value="yes"
                                                                                                    name="yes"
                                                                                                    control={<Radio size="small" />}
                                                                                                    label="YES"
                                                                                                />
                                                                                                <FormControlLabel
                                                                                                    value="no"
                                                                                                    name="no"
                                                                                                    control={<Radio size="small" />}
                                                                                                    label="NO"
                                                                                                />
                                                                                            </RadioGroup>
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            }
                                                                        </Grid>
                                                                    }

                                                                    {item1.questionPicker === "manual" &&
                                                                        <Grid item container sm={12}>
                                                                            <TableContainer
                                                                                component={Paper}
                                                                                style={{ width: '100%', overflowY: 'scroll', maxHeight: 380 }}
                                                                                className={`${classes.table} customPdfScroll`}
                                                                            >
                                                                                <Table stickyHeader aria-label="sticky table">
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            {headers.map((head, index) => (
                                                                                                <TableCell key={index}>{head}</TableCell>
                                                                                            ))}
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {item1.tableRows && item1.tableRows.map((item, index2) => (
                                                                                            <TableRow key={index2}>
                                                                                                <TableCell>
                                                                                                    <FormGroup style={{ alignItems: "center" }}>
                                                                                                        <FormControlLabel
                                                                                                            control={<Checkbox />}
                                                                                                            label={`${index2 + 1}`}
                                                                                                            disabled={actionOfExam === "PUBLISHED"}
                                                                                                            color="secondary"
                                                                                                            value={item.checked}
                                                                                                            checked={item.checked === true ? true : false}
                                                                                                            onChange={(e) => handleCheckQuestion(e, index, addIdx, index2)}
                                                                                                        />
                                                                                                    </FormGroup>
                                                                                                </TableCell>
                                                                                                <TableCell>{item.qCode}</TableCell>
                                                                                                <TableCell>
                                                                                                    <Grid style={{ display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
                                                                                                        <Typography variant="subtitle1">Preview</Typography>
                                                                                                        <Tooltip
                                                                                                            title="Preview Question">
                                                                                                            <IconButton
                                                                                                                aria-label="previewQuestion"
                                                                                                                onClick={() => {
                                                                                                                    setQuestionContent(item.question);
                                                                                                                    setShowQuestionPreview(true);
                                                                                                                }}
                                                                                                            >
                                                                                                                <InfoIcon />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </Grid>

                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    <Tooltip title={item.sectionName}>
                                                                                                        <span>{item.sectionName}</span>
                                                                                                    </Tooltip>
                                                                                                </TableCell>
                                                                                                <TableCell>{item.ratingName}</TableCell>
                                                                                                <TableCell>{item.difficultyName}</TableCell>
                                                                                                <TableCell>{item.previousQues === false ? "NO" : "YES"}</TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </Grid>
                                                                    }

                                                                </Grid>
                                                            ))}
                                                        </Grid>

                                                    </AccordionDetails>

                                                </Accordion>
                                            </Grid>
                                        ))}
                                </Grid>


                                <Grid container justify="flex-end">
                                    {window.localStorage.boardId > 6 &&
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            // disabled={saveDraft === false}
                                            style={{ color: "white", marginRight: "1em" }}
                                            onClick={handlepreviewBtn}
                                        >
                                            Preview
                                        </Button>}
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ color: "white", marginRight: "1em" }}
                                        onClick={handleSaveAsDraft}
                                        disabled={actionOfExam === "PUBLISHED"}
                                    >
                                        Save as Draft
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disabled={actionOfExam === "PUBLISHED"}
                                        style={{ color: "white", }}
                                        onClick={handleSubmitQues}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>

                </Grid>
            </Grid>

            <Dialog
                open={showQuestionPreview}
                onClose={() => setShowQuestionPreview(false)}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="form-dialog-title">Question</DialogTitle>
                <DialogContent style={{ scrollbarWidth: "none" }}>
                    <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(questionContent) }}></div>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => setShowQuestionPreview(false)}
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", background: '#d95350' }}
                    > Close</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openQuesDailog}
                onClose={() => setOpenQuestionDailog(false)}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
                className="customPdfScroll"
            >
                <DialogTitle id="form-dialog-title">Questions List</DialogTitle>
                <DialogContent className="customPdfScroll">
                    <Grid item style={{ padding: "0 15px" }}>
                        <ul>
                            {examQuesList && examQuesList.map((x, index) => (
                                <Grid item key={index}>
                                    <Typography variant="body1">{x.subjectName}</Typography>
                                    <Grid item container>
                                        {x.quetList.map((item, index) => (
                                            <li key={index} style={{ listStyle: "decimal", marginBottom: "8px", width: "100%" }}>
                                                <Grid item container direction="row" justify="space-between">
                                                    <Typography variant="body1">Question : </Typography>
                                                    <Typography variant="body1">{item.identifierName}</Typography>
                                                </Grid>
                                                <Grid item container direction="column">
                                                    <Grid item>
                                                        <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(item.question) }}></div>
                                                    </Grid>
                                                    {item.identifierId === 18 || item.identifierId === 1 || item.identifierId === 19 || item.identifierId === 20 ?
                                                        <Grid item>
                                                            <ul style={{ marginLeft: "1em" }}>
                                                                {item.option.map((elem, idx) => (
                                                                    <li key={idx} style={{ listStyle: "upper-alpha", marginBottom: "5px" }}>
                                                                        <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(elem) }}></div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Grid>
                                                        : item.identifierId === 16 ?
                                                            <Grid item>
                                                                <ul style={{ marginLeft: "1em" }}>
                                                                    {item.mcq.map((elem, idx) => (
                                                                        <li key={idx}>
                                                                            <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(elem.option) }}></div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </Grid>
                                                            : null
                                                    }
                                                </Grid>
                                            </li>
                                        ))}
                                    </Grid>
                                </Grid>
                            ))}
                        </ul>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenQuestionDailog(false)}
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", background: '#d95350' }}
                    > Close</Button>
                </DialogActions>
            </Dialog>


            <Backdrop className={classes.backdrop} open={backLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={snack}
                autoHideDuration={9000}
                onClose={() => setSnack(false)}
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setSnack(false)}
                        >
                            <CloseIcon
                                style={{ color: theme.palette.common.tabFont }}
                                fontSize="small"
                            />
                        </IconButton>
                    </React.Fragment>
                }
            >
                <Alert severity={status}>{message}</Alert>
            </Snackbar>
        </>
    )
}
