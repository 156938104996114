import {
  Card,
  CardContent,
  makeStyles,
  Typography,
  useTheme,
  Grid
} from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  myCard: {
    borderRadius: "9px",
    margin: " 0",
    padding: '0px 5px!important',
    overflow: 'visible',

    "& .MuiCardContent-root": {
      padding: '12px 16px'
    },
    "& h4": {
      fontWeight: '700',
    },
    "& img":{
      maxWidth: '80px',
      maxHeight: '80px',
    },

    "& h4, span": {
      fontFamily: `'Montserrat', 'sans-serif'`,
      color: '#fff'
    }
  },
}));

export default function AnalysisCard({
  name,
  val,
  selected,
  ...otherProps
}) {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <Card
      className={classes.myCard}
      elevation={7}
      style={{ background: otherProps.bgColor }}
      {...otherProps}
    >
      <CardContent>
        <Grid container spacing={2}>
          {otherProps.showImg == true &&
            <Grid item sm={3}  style={{marginTop: otherProps.marginTop}}>
              <img src={otherProps.img}/>
            </Grid>
          }
          <Grid item sm={otherProps.showImg == true ? 9 : 12}>
            <Typography
              variant="h4"
              align="left"
              gutterBottom
              color="secondary"
              style={{ marginBottom: 0 }}
            >
              {val}
            </Typography>
            <Typography
              variant="span"
              align="left"
              gutterBottom
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
