import React from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Grid,
  Typography,
  Avatar,
  Divider,
  DialogContent,
  IconButton,
  Hidden,
} from "@material-ui/core";
import moment from "moment";
import { orderBy } from "lodash";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import kid5 from "../assets/kid5.jfif";

import CommentIcon from "@material-ui/icons/Comment";
import deeksha from "../assets/deeksha.png";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    width: "200em",
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "80vw",
      height: "90vh",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },

  dialogwidth: {
    maxWidth: "100em",
    maxHeight: "90vh",
    overflow: 'hidden'
  },

  menuCard: {
    height: "13em",
    width: "18em",
    borderRadius: 12,
    cursor: "pointer",
  },

  // scrollwidth: {
  //   background: 'red',
  //   overflow: 'scroll',
  // }

}));

const responses = [
  {
    avatar: deeksha,
    name: " Mrs. Asha Khanna",
    time: "May 07. 2020. 9:12a.m",
    response:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus",
  },
  {
    avatar: deeksha,
    name: "Mrs. Asha Khanna",
    time: "May 07. 2020. 9:12a.m",
    response:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus skjhfsagfas sjhgfshjgfhj, skjfhksja",
  },
  {
    avatar: kid5,
    name: "Mrs. Rashmi Desai",
    time: "May 07. 2020. 9:12a.m",
    response:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus",
  },
  {
    avatar: kid5,
    name: "Mrs. Rashmi Desai",
    time: "May 07. 2020. 9:12a.m",
    response:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus",
  },
  {
    avatar: kid5,
    name: "Mrs. Rashmi Desai",
    time: "May 07. 2020. 9:12a.m",
    response:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus",
  },
  {
    avatar: kid5,
    name: "Mrs. Rashmi Desai",
    time: "May 07. 2020. 9:12a.m",
    response:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus",
  },
  {
    avatar: kid5,
    name: "Mrs. Rashmi Desai",
    time: "May 07. 2020. 9:12a.m",
    response:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus",
  },
  {
    avatar: kid5,
    name: "Mrs. Rashmi Desai",
    time: "May 07. 2020. 9:12a.m",
    response:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus",
  },
  {
    avatar: kid5,
    name: "Mrs. Rashmi Desai",
    time: "May 07. 2020. 9:12a.m",
    response:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus",
  },
];

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function FavouritesDialog(props) {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <React.Fragment>
      {console.log(props.mylist)}
      <Dialog
        open={props.open}
        onClose={props.onClose}
        classes={{ paperWidthSm: classes.dialogwidth }}
      //className={classes.dialogContainer}
      >
        {console.log(props.questionForDialog)}
        <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
          Query
        </DialogTitle>
        <DialogContent  className="customPdfScroll">
          <Grid
            container
            direction="column"
            style={{ backgroundColor: "white", width: "80vw" }}
            spacing={6}
           
          >
            {/* questionare container starts */}
            <Grid
              item
              container
              style={{
                position: "absolute",
                zIndex: 1,
                backgroundColor: "white",
                marginTop: "1em",
              }}
              
            >
              <Grid
                container
                direction="column"
                justify="center"
                style={{
                  backgroundColor: theme.palette.common.white,
                }}
               
              >
                <Grid item container direction="row">
                  <Grid item container sm={1}>
                    <Avatar
                      size="medium"
                      style={{ height: "3em", width: "3em" }}
                    >
                      <img
                        alt=""
                        src={props.questionForDialog.groupQuery.profilePicture}
                        style={{ height: "3em", width: "3em" }}
                      ></img>
                    </Avatar>
                  </Grid>
                  <Grid item container sm={8} direction="row" spacing={1}>
                    <Grid item container>
                      <b>{props.questionForDialog.groupQuery.query}</b>
                    </Grid>
                    <Grid item container>
                      {props.questionForDialog.groupQuery.queryDescription}{" "}
                    </Grid>
                    <Grid item container direction="row" spacing={2}>
                      {
                        //   <Grid item>
                        //   {" "}
                        //   <Typography variant="body1">
                        //     {props.questionForDialog.groupQuery.queryStatus ===
                        //     "open" ? (
                        //       <span
                        //         style={{
                        //           backgroundColor: "#ffc107",
                        //           color: "white",
                        //           padding: "0.2em",
                        //           borderRadius: 5,
                        //           width: "15em",
                        //         }}
                        //       >
                        //         {props.questionForDialog.groupQuery.queryStatus}
                        //       </span>
                        //     ) : (
                        //       <span
                        //         style={{
                        //           backgroundColor: "#64dd17",
                        //           color: "white",
                        //           padding: "0.2em",
                        //           borderRadius: 5,
                        //           width: "15em",
                        //         }}
                        //       >
                        //         {props.questionForDialog.groupQuery.queryStatus}
                        //       </span>
                        //     )}
                        //   </Typography>
                        // </Grid>
                      }
                      <Grid item>
                        By {props.questionForDialog.groupQuery.studentName}
                      </Grid>
                      <Grid item>-</Grid>
                      <Grid item>
                        {moment(
                          props.questionForDialog.groupQuery.createdDate,
                          "DD-MM-YYYY"
                        )
                          .format("DD MMM YYYY  ")
                          .toString()}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={1}
                    onClick={() =>
                      props.handleDialogImageClick(
                        props.questionForDialog.groupQuery.url
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {props.questionForDialog.groupQuery.url ? (
                      <img
                        alt=""
                        src={props.questionForDialog.groupQuery.url}
                        width="60em"
                        height="50em"
                      ></img>
                    ) : undefined}
                  </Grid>
                </Grid>

                <Grid item>
                  <Divider style={{ marginTop: "1em" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <React.Fragment></React.Fragment>

          <React.Fragment>
            <Grid
              container
              direction="column"
              justify="center"
              style={{ marginTop: "15em" }}
            >
              <Grid
                item
                container
                style={{
                  position: "absolute",
                  top: "14em",
                  zIndex: 1,
                  backgroundColor: "white",
                }}
              >
                <Grid item>
                  <Grid container direction="row">
                    <Grid item>
                      <CommentIcon
                        style={{ marginRight: "0.5em", color: "orange" }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>Responses</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
                <Grid item  >
              {props.questionForDialog.groupQueryResponse === null ? (
                <Typography variant="h6">No Response Available</Typography>
              ) : (
                orderBy(
                  props.questionForDialog.groupQueryResponse,
                  (o) => o.createdOn,
                  "desc"
                ).map((user) => (
                  <Grid container direction="column"
                    style={{
                      padding: "1em",
                    }}>
              <Grid item container direction="row">
                <Grid item container sm={1}>
                  <Avatar
                    size="medium"
                    style={{ height: "3em", width: "3em" }}
                  >
                    <img
                      alt=""
                      src={user.profilePicture}
                      style={{ height: "3em", width: "3em" }}
                    ></img>
                  </Avatar>
                </Grid>
                <Grid
                  item
                  container
                  sm={9}
                  direction="column"
                  spacing={1}
                >
                  <Grid item container direction="row" spacing={2}>
                    <Grid item>By {user.teacherName}</Grid>
                    <Grid item>-</Grid>
                    <Grid item>
                      {moment(user.createdOn, "DD-MM-YYYY")
                        .format("DD MMM YYYY")
                        .toString()}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    {user.response}
                  </Grid>
                </Grid>

                <Grid item sm={2}>{user.url ? (
                  <img
                    alt=""
                    src={user.url}
                    width="60em"
                    height="50em"
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      props.handleDialogImageClick(user.url)}
                  ></img>
                ) : undefined}


                </Grid>
              </Grid>
              <Grid item style={{ marginTop: "1em" }}>
                <Divider variant="middle" />
              </Grid>
            </Grid>
            ))
              )}
              </Grid>
          </Grid>
        </React.Fragment>
      </DialogContent>
    </Dialog>
    </React.Fragment >
  );
}
