import React, { useEffect, useState } from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import TabMaker from "./TabMaker";
import {
  AppBar,
  Dialog,
  Toolbar,
  IconButton,
  Switch,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import pdf from "../assets/pdficon.jpg";
import { url } from "./store.js";
import DisplayPdf from "./DisplayPdf";
import TimeTableTeacher from "./TimeTableTeacher.js";
import TimeTableTeacherClass from "./TimeTableTeacherClass.js";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },

  toolBar: {
    ...theme.mixins.toolbar,
  },

  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.common.black,
  },
  switchButton: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.light,
    },

    "& .MuiSwitch-colorSecondary": {},
  },
  pdfContainer: {
    marginTop: "none",
  },

  pdfContainer2: {
    width: "200em",
  },
}));

export default function GeneralUiTeacher() {
  const classes = useStyle();
  const theme = useTheme();

  const teacherTable = <TimeTableTeacher />;

  const teacherTableClass = <TimeTableTeacherClass />;

  const myData = [teacherTable, teacherTableClass];

  const tabheadings = [
    "My Schedule",
    `Class ${window.localStorage.className}-${window.localStorage.sectionName} Time Table`,
  ];

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          <TabMaker tabs={tabheadings} data={myData} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
