import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  MenuItem,
  CircularProgress,
  Toolbar,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import { url } from "./store.js";
import { useForm3 } from "./useForm.js";
import MuiAlert from "@material-ui/lab/Alert";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import Snackbar from "@material-ui/core/Snackbar";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import link from "../assets/link.svg";
import ChartMaker from "./ChartMaker.js";
import { useSpring, animated } from "react-spring";
import GeneralInstructions from "./GeneralInstructions.js";

const useStyle = makeStyles((theme) => ({
  imageIcon: {
    width: "2em",
    height: "2em",
    marginRight: "1.0em",
  },

  textFieldStyle: {
    width: "40em",
  },

  subContainer: {
    ...theme.typography.subContainer,
    paddingTop: "0.3em",
  },
  backButton: {
    color: theme.palette.common.white,
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "45em",
      height: "35em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },

  dialogwidth: {
    maxWidth: "45em",
    maxHeight: "50em",
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  clipboardButton: {
    cursor: "pointer",
    "&:hover": {
      color: "black",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const options = ["issue 1", "issue 2", "issue 3", "issue 4"];

export default function WorkSpacecHeader(props) {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [openNoti, setNotiOpen] = useState(false);
  const [subjectTeachers, setSubjectTeachers] = useState([]);
  const theme = useTheme();
  const [mymessage, setMessage] = useForm3({
    employeeId: 0,
    loginUserId: 0,
    message: null,
    secSubSylMappingId: 0,
    sectionId: 0,
    src: "string",
    studentInstanceId: 0,
  });
  const [openError, setOpenError] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(false);
  const [messageForFav, setMessageForFav] = React.useState("");
  const [favMessageStatus, setFavMessageStatus] = React.useState("");
  const [openFavError, setOpenFavError] = React.useState(false);
  const [notiSnack, setNotiSnack] = React.useState(false);
  const [NotiStatus, setNotiStatus] = React.useState("");
  const [notiMessage, setNotiMessage] = React.useState("");
  const [progressStart, setProgressStart] = React.useState(false);
  const [vcDialogOpen, setVcDialogOpen] = React.useState(false);
  const [studentVcData, setStudentVcData] = React.useState([]);
  const [copy, setCopy] = React.useState(false);
  const [annualClassAttendace, setAnnualClassAttendace] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [notification, setNotification] = useState({
    date: null,
    mynotification: "",
    heading: "",
    expirydate: null,
  });
  const [createGroupDialog, setCreateGroupDialog] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [subId, setSubId] = useState(0);
  const [mess, setMes] = useState({});
  const [openAttDialog, setAttOpenDialog] = useState(false);
  const [vigilance, setVigilance] = useState({});
  const [mymax, setMax] = useState(100);
  const [state, setMyState] = useState(false);
  const [showinstruction, setShowInstruction] = useState(false);

  const [youTubeData, setYouTubeData] = useState({});
  const [youTubeDialog, setYouTubeDialog] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const AnimatedIcon = animated(FavoriteIcon);
  const myprops = useSpring({
    x: state ? 1 : 0,
    config: { duration: 1300 },
  });

  const handleNotiClose = () => {
    setNotiOpen(false);
  };

  const handleCreateNotification = () => {
    setProgressStart(true);

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_ADD_CLASS_NOTIFICATION",
            busiParams: {
              branchId: window.localStorage.branchId,
              date: notification.date,
              description: notification.mynotification,
              employeeId: window.localStorage.employeeId,
              expiryDate: notification.expirydate,
              heading: notification.heading,
              loginUserId: window.localStorage.userId,
              secSubSylMappingId:
                window.localStorage.teacherType == "Class"
                  ? 0
                  : window.localStorage.secSubSylMappingId,
              sectionId: window.localStorage.sectionId,
              subjectId:
                window.localStorage.teacherType == "Class"
                  ? 0
                  : window.localStorage.subjectId,
              subjectName:
                window.localStorage.teacherType == "Class"
                  ? " "
                  : window.localStorage.subjectName,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setNotiMessage(data.respData[0].respMsg.message);
        data.respData[0].respMsg.status == "Failure"
          ? setNotiStatus("error")
          : setNotiStatus("success");
        setNotiSnack(true);
        setNotification({
          ...notification,
          date: null,
          expirydate: null,
          mynotification: "",
          heading: "",
        });
        props.setmessageRefresh(!props.messageRefresh);
      });
    setNotiOpen(false);
    setProgressStart(false);
  };

  const handleCreateGroup = () => {
    setProgressStart(true);

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_ADD_STUDENT_GROUP",
            busiParams: {
              branchId: window.localStorage.branchId,
              groupName: groupName,
              loginUserId: window.localStorage.userId,
              secSubSylMappingId: window.localStorage.secSylMappingId,
              sectionId: window.localStorage.sectionId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setNotiMessage(data.respData[0].respMsg.message);
        data.respData[0].respMsg.status == "Failure"
          ? setNotiStatus("error")
          : setNotiStatus("success");
        setNotiSnack(true);
        //props.setmessageRefresh(!props.messageRefresh);
        setCreateGroupDialog(false);
        setProgressStart(false);
        setTimeout(() => {
          props.setForce(!props.force);
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
        setCreateGroupDialog(false);
        setProgressStart(false);
      });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialogClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = () => {
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_MSG_TEACHER_LIST",
              busiParams: {
                sectionId: parseInt(window.localStorage.sectionId),
                studentInstanceId: parseInt(
                  window.localStorage.studentInstanceId
                ),
                secSubSylMappingId: 0,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let teacherlist = data.respData[0].respMsg.result;

          setSubjectTeachers(teacherlist);
        })
        .catch((data) => {
          console.error(data);

          // setError(true);
        });
    } catch (error) {
      console.log(error);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = (modules) => {
    let newData = [];

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_GET_STUDENT_ANNUAL_ATTENDANCE",
            busiParams: {
              sectionId: window.localStorage.sectionId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let tempEvents = [];
        let dataset = {
          labels: [],
          datasets: [
            {
              label: "Annual Attendance",
              backgroundColor: "#ff6d00",
              barThickness: "flex",
              barPercentage: 0.5,
              categoryPercentage: 0.6,
              maxBarThickness: 30,
              borderColor: "#ff6d00",
              borderSkipped: "left",
              data: [],
            },
          ],
        };
        console.log(data);
        data.respData[0].respMsg.result.monthly.map((item, index) => {
          dataset.labels.push(item.month);
          dataset.datasets[0].data.push(parseInt(item.perc.replace("%", "")));
        });

        console.log(dataset);

        setAnnualClassAttendace(dataset);
        // props.setForce(!props.force);
      })
      .then(() => {
        setOpenDialog(true);
      })
      .catch((data) => {
        console.error(data);

        // setError(true);
      });
  };

  const handleAnnualAttDialogOpen = (modules) => {
    let newData = [];

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_GET_STUDENT_ANNUAL_ATTENDANCE",
            busiParams: {
              year: parseInt(moment().format("YYYY").toString()),
              studentInstanceId: window.localStorage.studentInstanceId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let tempEvents = [];
        let dataset = {
          labels: [],
          datasets: [
            {
              label: "Annual Attendance",
              backgroundColor: "#ff6d00",
              barThickness: "flex",
              barPercentage: 0.5,
              categoryPercentage: 0.6,
              maxBarThickness: 30,
              borderColor: "#ff6d00",
              borderSkipped: "left",
              data: [],
            },
          ],
        };
        console.log(data);
        data.respData[0].respMsg.result.monthly.map((item, index) => {
          dataset.labels.push(item.month);
          dataset.datasets[0].data.push(parseInt(item.perc.replace("%", "")));
        });

        console.log(dataset);

        setVigilance(dataset);
        // props.setForce(!props.force);
      })
      .then(() => {
        setAttOpenDialog(true);
      })
      .catch((data) => {
        console.error(data);

        // setError(true);
      });
  };

  const handleAttClose = () => {
    setAttOpenDialog(false);
  };

  const handleSendMessage = () => {
    console.log(mymessage);
    let newmessage = { ...mymessage };
    newmessage.messageId = mess.messageId;
    if (
      mymessage.employeeId === 0 ||
      mymessage.message === null ||
      mymessage.message === ""
    ) {
      setOpenError(true);
    } else {
      try {
        fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            requestList: [
              {
                isEncrypt: false,
                transactionId: "0000001438241244",
                busiCode: "SCHOOL_ADD_MESSAGE",
                busiParams: newmessage,
              },
            ],
            pubInfo: {
              appId: "appId",
              sessionId: window.localStorage.sessionId,
              version: "21",
              timestamp: "20180314175419",
              lang: "en_US",
              userId: "1000016614",
              serviceId: "7021150585",
              circleId: "MU",
              ncsroute: "WE015",
              customerId: "1000016614",
              osType: "windows",
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            console.log(data);
            setNotiMessage(data.respData[0].respMsg.message);
            data.respData[0].respMsg.status == "Failure"
              ? setNotiStatus("error")
              : setNotiStatus("success");
            setNotiSnack(true);
            props.setmessageRefresh(!props.messageRefresh);
            setMessage({
              target: {
                name: "message",
                value: "",
              },
            });
          })
          .catch((data) => {
            console.error(data);

            // setError(true);
          });
      } catch (error) {
        console.log(error);
      }
      setOpen(false);
    }
  };

  useEffect(() => {
    setSelected(false);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: "SCHOOL_GET_FAVOURITE_FOR_USER",
            busiParams: {
              id:
                window.localStorage.roleId == "5"
                  ? window.localStorage.parentUserId
                  : window.localStorage.userId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: window.localStorage.sessionId,
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("going to priunt the data");
        console.log(data.result);
        data.respData[0].respMsg.result.map((item, index) => {
          console.log("the props module id is " + props.moduleId);
          console.log("the props item id is " + item.moduleId);
          if (props.moduleId === item.moduleId) {
            setSelected(true);
          }
        });
      })
      .catch((data) => {
        console.error(data);
      });
  }, [props.moduleId]);

  const handleFavClick = (id) => {
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_ADD_TO_FAVOURITE",
              busiParams: {
                branchId: window.localStorage.branchId,
                moduleId: id,
                userId:
                  window.localStorage.roleId == 5
                    ? window.localStorage.parentUserId
                    : window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("going to priunt the data");
          console.log(JSON.stringify(data.message));
          setMessageForFav(data.respData[0].respMsg.message);

          if (
            data.respData[0].respMsg.message ===
            "You are only allowed to add six favourites" ||
            data.respData[0].respMsg.status === "Failure"
          ) {
            setFavMessageStatus("error");
            // setSelected(false);
          } else {
            setFavMessageStatus("success");
            setSelected(true);
            if (
              data.respData[0].respMsg.message !==
              "Module Already Exist in Favourite List"
            ) {
              setMyState(!state);
            }
          }
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.error(error);
    }

    setOpenFavError(true);
  };

  const handleSecSyl = (k) => {
    setMessage(k);
  };

  const bodyObjforVc = {
    requestList: [
      {
        isEncrypt: false,
        transactionId: "0000001438241244",

        busiCode: "SCHOOL_GET_VCLINK_BY_SECTION_ID",
        busiParams: {
          branchId: window.localStorage.branchId,
          sectionId: window.localStorage.sectionId,
          status: true,
        },
      },
    ],
    pubInfo: {
      appId: "appId",
      sessionId: window.localStorage.sessionId,
      version: "21",
      timestamp: "20180314175419",
      lang: "en_US",
      userId: "1000016614",
      serviceId: "7021150585",
      circleId: "MU",
      ncsroute: "WE015",
      customerId: "1000016614",
      osType: "windows",
    },
  };

  const handleVclinkDialog = async () => {
    console.log("the function vc called");
    setVcDialogOpen(true);
    await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(bodyObjforVc),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        let tempArr = [];
        data.respData[0].respMsg.result.map((item) => {
          let tempObj = {
            username:
              item.attendeeUserName === undefined ||
                item.attendeeUserName === null ||
                item.attendeeUserName === ""
                ? "NA"
                : item.attendeeUserName,
            url: item.url,
            password:
              item.attendeePassword === undefined ||
                item.attendeePassword === null ||
                item.attendeePassword === ""
                ? "NA"
                : item.attendeePassword,
            description:
              item.description === undefined ||
                item.description === null ||
                item.description === ""
                ? "NA"
                : item.description,
            isYoutube: item.url.substring(8, item.url.lastIndexOf("/")) === "youtu.be" ? true : false
          };
          tempArr.push(tempObj);
        });
        console.log("embedId", tempArr)

        setStudentVcData(tempArr);
      })
      .catch((data) => {
        console.error(data);
        // setError(true);
      });
  };
  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        className={classes.subContainer}
      >
        <Grid item container justify="flex-start" alignItems="center" sm>
          <Grid item style={{ display: props.imgIcon ? "inherit" : "none" }}>
            <img
              src={props.imgIcon}
              alt={props.imgIcon}
              className={classes.imageIcon}
            />
          </Grid>
          <Grid item>
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              {props.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify="flex-end"
          alignItems="center"
          style={{ marginRight: "1em" }}
          sm
        >
            {window.localStorage.variantId !== "7" && window.localStorage.roleId === "4" &&
              <Grid item>
              <Button
                variant="contained"
                onClick={handleVclinkDialog}
                style={{
                  color: "white",
                  backgroundColor: "black",
                  textTransform: "none",
                  marginRight: "1em"
                }}
              >
                Online Class Link
              </Button>
            </Grid>}
          {props.message === true ? (
            <Grid item>
              <Button
                variant="contained"
                style={{ color: "white" }}
                color="secondary"
                onClick={() => handleOpen()}
              >
                Message
              </Button>
            </Grid>
          ) : props.sendNotification === true ? (
            <Grid item>
              <Button
                variant="contained"
                style={{ color: "white", textTransform: "none" }}
                color="secondary"
                onClick={() => setNotiOpen(true)}
              >
                Send Notification
              </Button>
            </Grid>
          ) : props.annualReport === true ? (
            <Grid item>
              <Button
                variant="contained"
                onClick={handleAnnualAttDialogOpen}
                style={{
                  color: "white",
                }}
                color="secondary"
              >
                Annual Report
              </Button>
            </Grid>
          ) : props.attendance === true ? (
            <Grid item>
              <Button
                variant="contained"
                onClick={handleDialogOpen}
                style={{
                  color: "white",
                }}
                color="secondary"
              >
                Annual Report
              </Button>
            </Grid>
          ) : props.creategroup === true ? (
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setCreateGroupDialog(true)}
                style={{
                  color: "white",
                }}
                color="secondary"
              >
                Create Group
              </Button>
            </Grid>
          ) : props.back === true ? (
            <Grid item>
              <Button
                variant="contained"
                component={Link}
                to="/Manage/Attendance"
                style={{
                  color: "white",
                }}
                color="secondary"
              >
                Back
              </Button>
            </Grid>
          ) : props.showInstruction ? (
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowInstruction(true)}
              >
                Instructions
              </Button>
            </Grid>
          ) : (
            <p></p>
          )}
          <React.Fragment>
            {props.markAttendance === true &&
              window.localStorage.teacherType == "Class" ? (
              <Grid item>
                <Button
                  variant="contained"
                  component={Link}
                  to="/Manage/markAttendance"
                  style={{
                    color: "white",
                    marginLeft: "1em",
                  }}
                  color="secondary"
                >
                  mark attendance
                </Button>
              </Grid>
            ) : undefined}
          </React.Fragment>

          {window.localStorage.variantId !== "7" && <>
            <Grid
              item
              onClick={() => {
                handleFavClick(props.moduleId);
              }}
            >
              {props.moduleId ? (
                <IconButton
                  size="medium"
                  disableRipple
                  style={{
                    color:
                      theme.typography.myTheme.themeName === "Night"
                        ? selected
                          ? "red"
                          : "white"
                        : "grey",
                  }}
                >
                  <AnimatedIcon
                    // color={
                    //   selected
                    //     ? theme.palette.primary.red
                    //     : theme.palette.common.grey
                    // }
                    style={{
                      color: selected
                        ? theme.palette.common.red
                        : theme.palette.common.newgrey,
                      transform: myprops.x
                        .interpolate({
                          range: [0, 0.2, 0.5, 0.6, 0.8, 1],
                          output: [1, 0.3, 1.5, 0.8, 1],
                        })
                        .interpolate((x) => `scale(${x})`),
                    }}
                  />
                </IconButton>
              ) : undefined}
            </Grid>

            <Grid item>
              {props.moduleId ? (
                selected ? (
                  <Typography variant="h6">Added To Favourites</Typography>
                ) : (
                  <Typography variant="h6">Add To Favourites</Typography>
                )
              ) : undefined}
            </Grid>
          </>}
        </Grid>
      </Grid>

      {props.sendNotification === true ? (
        <Dialog
          open={openNoti}
          onClose={handleNotiClose}
          aria-labelledby="customized-dialog-title"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleNotiClose}>
            <Grid item container alignItems="stretch">
              <Grid item>
                <NotificationsNoneIcon />
              </Grid>
              <Grid item>Notification</Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid item container direction="column">
              <Grid item>
                {" "}
                <TextField
                  id=""
                  variant="outlined"
                  label="Heading *"
                  placeholder="Write Heading Here"
                  name="heading"
                  color="primary"
                  inputProps={{
                    maxlength: 50,
                  }}
                  className={classes.textFieldStyle}
                  style={{ marginBottom: "1em", marginTop: "0.2em" }}
                  onChange={(e) =>
                    setNotification({
                      ...notification,
                      heading: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <TextField
                  id=""
                  variant="outlined"
                  label="Notification *"
                  name="notification"
                  placeholder="Write Message Here"
                  fullWidth
                  multiline
                  inputProps={{
                    maxlength: 1000,
                  }}
                  rows={6}
                  color="primary"
                  className={classes.textFieldStyle}
                  onChange={(e) =>
                    setNotification({
                      ...notification,
                      mynotification: e.target.value,
                    })
                  }
                  style={{ marginBottom: "1em", marginTop: "0.2em" }}
                />
              </Grid>
            </Grid>

            <Grid item container direction="row" justify="space-between">
              <Grid item>
                <KeyboardDatePicker
                  variant="inline"
                  autoOk={true}
                  name="date"
                  inputVariant="outlined"
                  label="Date *"
                  fullWidth
                  disablePast
                  placeholder="Select Notification Date"
                  format="DD/MM/yyyy"
                  value={notification.date}
                  InputAdornmentProps={{ position: "end" }}
                  onChange={(date) => {
                    setNotification({
                      ...notification,
                      date: moment(date).format("YYYY-MM-DD").toString(),
                    });
                  }}
                />
              </Grid>

              <Grid item>
                <KeyboardDatePicker
                  variant="inline"
                  autoOk={true}
                  name="expirydate"
                  fullWidth
                  inputVariant="outlined"
                  label="Expiry Date *"
                  placeholder="Select Notification Date"
                  format="DD/MM/yyyy"
                  disablePast
                  value={notification.expirydate}
                  InputAdornmentProps={{ position: "end" }}
                  onChange={(mydate) => {
                    console.log(notification.expirydate);
                    console.log(notification.date);
                    console.log(
                      moment(mydate, "YYYY-MM-DD").diff(
                        moment(notification.date, "YYYY-MM-DD")
                      )
                    );
                    if (
                      moment(mydate, "YYYY-MM-DD").diff(
                        moment(notification.date, "YYYY-MM-DD")
                      ) >= 0
                    ) {
                      setNotification({
                        ...notification,
                        expirydate: moment(mydate)
                          .format("YYYY-MM-DD")
                          .toString(),
                      });
                    } else {
                      setNotiStatus("Warning");
                      setNotiMessage(
                        "Expiry date should be greater than the chosen date"
                      );
                      setNotiSnack(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              vairant="outline"
              onClick={handleNotiClose}
              color="sceondary"
              style={{ color: theme.palette.secondary.main }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleCreateNotification}
              color="secondary"
              style={{ color: "white", textTransform: "none" }}
              disabled={
                notification.date === null ||
                notification.date === "" ||
                notification.mynotification === "" ||
                notification.heading === "" ||
                notification.expirydate === null ||
                notification.expirydate === null
              }
            >
              {progressStart === true ? (
                <CircularProgress color={theme.palette.secondary.light} />
              ) : (
                "Send Notification"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      ) : undefined}

      {vcDialogOpen === true ? (
        <Dialog
          open={vcDialogOpen}
          onClose={() => setVcDialogOpen(false)}
          classes={{ paperWidthSm: classes.dialogwidth }}
          className={classes.dialogContainer}
        >
          {console.log(
            "the data has been set = " + JSON.stringify(studentVcData)
          )}
          <Toolbar
            variant="dense"
            style={{ backgroundColor: theme.palette.common.ltgrey }}
          >
            <Grid container justify="space-between">
              <Grid item>
                <Grid container direction="row">
                  <Grid item>
                    {" "}
                    <VideocamOutlinedIcon
                      style={{
                        marginRight: "0.5em",
                        color: theme.palette.common.tabFont,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: theme.palette.common.tabFont }}>
                      Online Class Links
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  cursor: "pointer",
                  color: theme.palette.common.tabFont,
                }}
              >
                <CloseOutlinedIcon onClick={() => setVcDialogOpen(false)} />
              </Grid>
            </Grid>
          </Toolbar>
          <DialogContent>
            <Grid item container direction="column" spacing={4}>
              <Grid item container direction="row" justify="flex-start">
                <Grid item container sm={5}>
                  <Typography
                    variant="h6"
                    style={{
                      color:
                        theme.typography.myTheme.themeName === "Night"
                          ? "black"
                          : "inherit",
                    }}
                  >
                    Links
                  </Typography>
                </Grid>
                <Grid item container sm={2} style={{ marginLeft: "1em" }}>
                  <Typography
                    variant="h6"
                    style={{
                      color:
                        theme.typography.myTheme.themeName === "Night"
                          ? "black"
                          : "inherit",
                    }}
                  >
                    Username
                  </Typography>
                </Grid>
                <Grid item container sm={2} style={{ marginLeft: "0.6em" }}>
                  <Typography
                    variant="h6"
                    style={{
                      color:
                        theme.typography.myTheme.themeName === "Night"
                          ? "black"
                          : "inherit",
                    }}
                  >
                    Password
                  </Typography>
                </Grid>
                <Grid item container sm={2} style={{ marginLeft: "0.6em" }}>
                  <Typography
                    variant="h6"
                    style={{
                      color:
                        theme.typography.myTheme.themeName === "Night"
                          ? "black"
                          : "inherit",
                    }}
                  >
                    Description
                  </Typography>
                </Grid>
              </Grid>
              {studentVcData.length > 0 ? (
                studentVcData.map((item) => (
                  <Grid item container direction="column">
                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-start"
                      spacing={4}
                    >
                      <Grid
                        item
                        container
                        sm={5}
                        direction="row"
                        alignItems="center"
                        justify="center"
                      >
                        <Grid
                          item
                          container
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          sm
                        >
                          <Typography
                            noWrap
                            style={{
                              color: "#4dabf5",
                              textDecoration: "none",
                              cursor: "pointer"
                            }}
                            component="a"
                            href={item.url === null || item.isYoutube ? undefined : item.url}
                            target={item.url === null || item.isYoutube ? undefined : "_blank"}
                            // onClick={() => {
                            //   setYouTubeData({
                            //     isYoutube: item.isYoutube,
                            //     embedId: item.isYoutube ? item.url.substring(item.url.lastIndexOf("/") + 1) : null
                            //   })
                            //   setYouTubeDialog(true)
                            // }}
                          >
                            {" "}
                            {item.url}
                          </Typography>
                        </Grid>
                        <Grid item container sm>
                          <CopyToClipboard
                            text={item.url}
                            style={{ marginLeft: "0.5em" }}
                          // onCopy={() => setCopy(true)}
                          >
                            <img
                              src={link}
                              alt=""
                              color="secondary"
                              className={classes.clipboardButton}
                              onClick={() => setCopy(true)}
                            />
                          </CopyToClipboard>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        sm={2}
                      // style={{
                      //   overflow: "hidden",
                      //   textOverflow: "ellipsis",
                      // }}
                      >
                        {item.username === undefined ||
                          item.username === null ||
                          item.username === ""
                          ? "NA"
                          : item.username}
                      </Grid>
                      <Grid
                        item
                        container
                        sm={2}
                      // style={{
                      //   overflow: "hidden",
                      //   textOverflow: "ellipsis",
                      // }}
                      >
                        {item.password === undefined ||
                          item.password === null ||
                          item.password === ""
                          ? "NA"
                          : item.password}
                      </Grid>
                      <Grid
                        item
                        container
                        sm={2}
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.description === undefined ||
                          item.description === null ||
                          item.description === ""
                          ? "NA"
                          : item.description}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Divider style={{ backgroundColor: "#f5f5f5" }} />
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography>No Data Available</Typography>
              )}
            </Grid>
          </DialogContent>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={copy}
            autoHideDuration={6000}
            onClose={() => setCopy(false)}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => setCopy(false)}
                >
                  <CloseIcon style={{ color: theme.palette.common.tabFont }} />
                </IconButton>
              </React.Fragment>
            }
          >
            <Alert>Copied</Alert>
          </Snackbar>
        </Dialog>
      ) : null}

      {youTubeData.isYoutube &&
        <Dialog
          open={youTubeDialog}
          onClose={() => setYouTubeDialog(false)}
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogContent style={{ margin: "auto" }}>
            <iframe
              width="853"
              height="480"
              src={`https://www.youtube.com/embed/${youTubeData.embedId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </DialogContent>
        </Dialog>
      }

      {props.message === true ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Send Message
          </DialogTitle>
          <DialogContent>
            <TextField
              id=""
              variant="outlined"
              label="Teacher"
              placeholder="Select Teacher"
              select
              color="primary"
              className={classes.textFieldStyle}
              style={{ marginBottom: "1em", marginTop: "1em" }}
              onChange={(e) => {
                console.log(e.target.value);

                setMessage({
                  target: {
                    value: e.target.value.employeeId,
                    name: "employeeId",
                  },
                });
                setMes(e.target.value);
              }}
            >
              {subjectTeachers &&
                subjectTeachers.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {`${item.employeeName}`}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              id=""
              variant="outlined"
              label="Message"
              name="message"
              placeholder="Write Message Here"
              fullWidth
              multiline
              rows={6}
              color="primary"
              className={classes.textFieldStyle}
              onChange={setMessage}
            />
          </DialogContent>
          <DialogActions>
            <Button
              vairant="outline"
              onClick={handleClose}
              color="sceondary"
              style={{ color: theme.palette.secondary.main }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSendMessage}
              color="secondary"
              style={{ color: "white" }}
            >
              Send Message
            </Button>
          </DialogActions>
        </Dialog>
      ) : undefined}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openError}
        autoHideDuration={6000}
        onClose={() => setOpenError(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenError(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="error">
          Please Select Teacher and Enter a message
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openFavError}
        autoHideDuration={6000}
        onClose={() => setOpenFavError(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenFavError(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={favMessageStatus}>{messageForFav}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={notiSnack}
        autoHideDuration={6000}
        onClose={() => setNotiSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setNotiSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={NotiStatus}>{notiMessage}</Alert>
      </Snackbar>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setOpenDialog(false)}
        >
          Annual Attendance Report
        </DialogTitle>
        <DialogContent>
          <ChartMaker dataset={annualClassAttendace} width="40em" />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleOpenDialogClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={createGroupDialog}
        onClose={() => setCreateGroupDialog(false)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setCreateGroupDialog(false)}
        >
          Create Group
        </DialogTitle>
        <DialogContent>
          <TextField
            id=""
            label="Group Name"
            variant="outlined"
            value={groupName}
            fullWidth
            onChange={(e) => setGroupName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleCreateGroup}
            disabled={groupName.trim() === "" || progressStart === true}
          >
            {progressStart ? (
              <CircularProgress color={theme.palette.secondary.light} />
            ) : (
              "Create Group"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAttDialog}
        onClose={handleAttClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleAttClose}>
          Annual Attendance
        </DialogTitle>
        <DialogContent>
          <ChartMaker
            dataset={vigilance}
            width="40em"
            maxsize={mymax}
            percentage={true}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleAttClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showinstruction}
        onClose={() => setShowInstruction(false)}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title" onClose={() => setShowInstruction(false)}>
          Instructions
        </DialogTitle>
        <DialogContent>
          <GeneralInstructions />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowInstruction(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
