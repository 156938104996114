import { makeStyles, withStyles, Typography, useTheme, Grid, List, ListItem, ListItemText, IconButton, Tooltip, Dialog, DialogContent, TableContainer, Paper, TableHead, TableRow, TableBody, TableCell, Table } from "@material-ui/core";
import React, { useState } from "react";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import suggestionBoxImg from "../assets/suggestionBox.png"
import { Doughnut } from 'react-chartjs-2';
import TableMaker from "../Ui/TableMaker";
import { green, red, yellow } from "@material-ui/core/colors";
// import 'chartjs-plugin-labels';

const useStyle = makeStyles((theme) => ({
    listCard: {
        backgroundColor: theme.palette.background.paper,
        //boxShadow: "0px 4px 5px -2px rgb(243 86 93 / 20%), 0px 7px 10px 1px rgb(243 86 93 / 10%), 0px 2px 16px 1px rgb(243 86 93 / 12%)",
        borderRadius: "5px",
        padding: '8px 16px',
        "& .MuiTypography-body1": {
            fontSize: '1.1rem',
        },
        margin: theme.spacing(0, 0, 2),

        "& .MuiListItem-root": {
            padding: "2px 0",

            "& span": {
                color: '#fff',
                fontFamily: `'Montserrat', 'sans-serif'`,
                fontSize: '15px',
            }
        }
    },

    title: {
        margin: theme.spacing(0),
        borderBottom: '1px  solid #f1e4e4',

        "& strong, span": {
            fontFamily: `'Montserrat', 'sans-serif'`,
            color: '#fff',
            fontSize: '15px',
        },
        "& strong": {
            fontSize: '28px !important',
            fontWeight: '800',
            marginRight: '5px'

        }
    },
    dialogContainer: {
        minWidth: "650px"
    },
}));

const styles = (theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.common.black,
    },
    DialogHeader: {
        backgroundColor: theme.palette.common.ltgrey,
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    const theme = useTheme();
    return (
        <MuiDialogTitle
            disableTypography
            {...other}
            className={classes.DialogHeader}
        >
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon style={{ color: theme.palette.common.tabFont }} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


export default function SuggestionBox(props) {
    const classes = useStyle();
    const theme = useTheme();
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const data = {
        labels: [
            'Zone Red',
            'Zone Yellow',
            'Zone Green'
        ],
        datasets: [{
            data: [props.red && props.red.length, props.yellow && props.yellow.length, props.green && props.green.length],
            backgroundColor: ['#f3565d', '#fba72d', '#4edd71'],
        }],
    };


    const headers = [
        "Chapter Name",
        "Identifier",
        "Markes Scored Zone",
        "Exam Priorty Zone",
        "Band"
    ];

    const rows = [
        { chapterName: "Algebra", identifierName: "MCQ", examZone: 1, scoredZone: 1, band: "green" },
        { chapterName: "Geometric", identifierName: "MCQ", examZone: 1, scoredZone: 2, band: "green" },
        { chapterName: "Triangles", identifierName: "MCQ", examZone: 1, scoredZone: 3, band: "green" },
        { chapterName: "Trigonometry", identifierName: "MCQ", examZone: 2, scoredZone: 1, band: "yellow" },
        { chapterName: "Calculus", identifierName: "MCQ", examZone: 2, scoredZone: 2, band: "yellow" },
        { chapterName: "Probability", identifierName: "MCQ", examZone: 2, scoredZone: 3, band: "red" },
        { chapterName: "Linear Programming", identifierName: "MCQ", examZone: 3, scoredZone: 1, band: "yellow" },
        { chapterName: "Matrices", identifierName: "MCQ", examZone: 3, scoredZone: 2, band: "red" },
        { chapterName: "Integrals ", identifierName: "MCQ", examZone: 3, scoredZone: 3, band: "red" },
    ];

    return (
        <Grid
            item
            container
            style={{
                margin: "32px 0",
                width: "100%",
                // backgroundColor: theme.palette.background.paper,
                borderTop: '2px solid #ccc'
            }}
            spacing={3}
        >
            <Grid item container
            >
                <Typography variant="h5" style={{ fontSize: '1.3rem', fontWeight: '700', color: '#000' }}>Suggestion Box Scope for Improvement
                    <Tooltip title="View Information" arrow>
                        <IconButton onClick={() => setShowInfoPopup(true)}>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Typography>
            </Grid>


            {/* <Grid item xs={12} sm={12}>
            <Typography component="div" style={{width: '280px', height: '280px'}}>
                <Doughnut 
                    data={data}
                    width={280}
                    height={280}
                    options={{
                        legend: {
                        display: false
                        },
                        maintainAspectRatio: false,
                        responsive: true,
                        cutoutPercentage: 60,
                        // plugins:{
                        //     labels: {
                        //     render: 'value',
                        //     fontSize: 18,
                        //     fontStyle: '600',
                        //     fontColor: '#fff',
                        //     fontFamily: `'Montserrat', 'sans-serif'`,
                        //     }
                        // }
                    }}
                />
            </Typography>
        </Grid> */}

            <Grid item xs={12} sm={12} spacing={2} container>
                {props.red && props.red.length != 0 &&
                    <Grid item xs={12} sm={4} style={{ paddingTop: '0' }}>
                        <Typography component="div" className={classes.listCard} elevation={7} style={{ backgroundColor: '#f3565d' }}>
                            <Typography component="div" className={classes.title}>
                                <Typography component="strong">{props.red.length}</Typography> <Typography variant="span">Zone Red</Typography>
                            </Typography>
                            <List>
                                {props.red.map((item, index) => {
                                    return (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={`${item.chapterName}, ${item.identifierName}`}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Typography>
                    </Grid>}

                {props.yellow && props.yellow.length != 0 && <Grid item xs={12} sm={4} style={{ paddingTop: '0' }}>

                    <Typography component="div" className={classes.listCard} style={{ background: '#fba72d' }}>
                        <Typography component="div" className={classes.title}>
                            <Typography component="strong">{props.yellow.length}</Typography> <Typography variant="span">Zone Yellow</Typography>
                        </Typography>
                        <List>
                            {props.yellow.map((item, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={`${item.chapterName}, ${item.identifierName}`}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Typography>
                </Grid>}

                {props.green && props.green.length != 0 && <Grid item xs={12} sm={4} style={{ paddingTop: '0' }}>

                    <Typography component="div" className={classes.listCard} style={{ background: '#4edd71' }}>
                        <Typography component="div" className={classes.title}>
                            <Typography component="strong">{props.green.length}</Typography> <Typography variant="span">Zone Green</Typography>
                        </Typography>
                        <List>
                            {props.green.map((item, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={`${item.chapterName}, ${item.identifierName}`}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Typography>
                </Grid>}
            </Grid>



            <Dialog
                onClose={() => setShowInfoPopup(false)}
                transitionDuration={{ accept: 0, enter: 700, exit: 300 }}
                classes={{ paper: classes.dialogContainer }}
                aria-labelledby="simple-dialog-title"
                open={showInfoPopup}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => setShowInfoPopup(false)}
                >
                    Suggestion Box Information
                </DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead style={{ backgroundColor: "#f1f1f1" }}>
                                <TableRow>
                                    {headers.map((head, index) => (
                                        <TableCell key={index}>{head}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((x, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell component="th" scope="row">
                                            {x.chapterName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {x.identifierName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {x.examZone}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {x.scoredZone}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <span style={{
                                                display: "block",
                                                width: "20px",
                                                height: "20px",
                                                backgroundColor: x.band,
                                                borderRadius: "50%"
                                            }}></span>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container spacing={3} style={{ marginTop: "0.6em", marginBottom: "1em" }}>
                        <Grid item direction="row" container alignItems="center">
                            <Typography variant="subtitle1" style={{ color: "#000", fontWeight: "bold" }}>Exam Priority Zone : </Typography>
                            <Typography variant="caption" style={{ color: "#000" }}>
                                3 - Low Difficulty & More scope,
                                2 - Medium difficulty & Medium Scope,
                                1 - High Difficulty & Less Scope
                            </Typography>
                        </Grid>
                        <Grid item  direction="row" container alignItems="center">
                            <Typography variant="subtitle1" style={{ color: "#000", fontWeight: "bold" }}>Markes Scored : </Typography>
                            <Typography variant="caption" style={{ color: "#000" }}>
                                3 - 0% to &#60;= 50%, 2 - 51% to  &#60;= 80%, 1 - &#62; 80%
                            </Typography>
                        </Grid>
                        <Grid direction="row" container alignItems="center" item sm={8}>

                        </Grid>
                    </Grid>
                </DialogContent>

            </Dialog>
        </Grid>
    );
}

