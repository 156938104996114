import React, { useState, Suspense, useEffect } from "react";
import {
  Paper,
  Grid,
  TextField,
  Checkbox,
  Button,
  Link as myLink,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import MuiAlert from "@material-ui/lab/Alert";
import Switch from "@material-ui/core/Switch";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import back2 from "../assets/back2.jpg";
import IconButton from "@material-ui/core/IconButton";
import component from "../assets/Image1.png";
import ios from "../assets/Image2.png";
import logo from "../assets/trunkie.png";
import moment from "moment";
import {
  ThemeEnergy,
  ThemeDay,
  ThemeNight,
  ThemStaticPage,
} from "../Ui/Theme.js";
// import AntSwitch from './antswitch';
import { Link, useHistory } from "react-router-dom";
import passicon from "../assets/passicon.svg";
import loginicon from "../assets/loginicon.svg";
import { url, getSession } from "../Ui/store.js";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { v4 as uuidv4 } from "uuid";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyle = makeStyles((theme) => ({
  formControl: {
    "& .MuiOutlinedInput-root": {
      "& .Mui-error": {
        borderWidth: "10px",
      },
    },
  },
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  mainContainer: {
    width: "100%",
    height: "110vh",
    backgroundImage: ` url(${back2}) `,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: theme.spacing.unit,
  },

  paperLogin: {
    marginTop: "2em",
    marginBottom: "auto",
    width: "27em",
    padding: 0,
  },

  downloadBtn: {
    width: "6.5em",
    height: "2em",
  },

  textPrimaryEdit: {
    color: theme.palette.common.blue,
    fontSize: 12,
  },
  loginBtn: {
    backgroundColor: " #03308F",
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: " #4c84fb",
    },
  },
  switchBtn: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: " #4c84fb",
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: " #03308F",
    },

    "& .MuiSwitch-colorSecondary": {},
  },
  logo: {
    width: "100%m",
    height: "4em",
  },
  label: {
    color: theme.palette.common.grey,
  },
  inputIcons: {
    height: "1em",
    width: "1em",
  },
}));

export default function LoginTab(props) {
  const classes = useStyle();
  const theme = useTheme();
  const [emptyError, setEmptyError] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [logStart, setLogStart] = useState(false);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [sectionId, setSectionId] = useState(0);
  const [restricted, setRestricted] = useState(false);
  const textFieldForUsernameRef = React.useRef(null);
  const textFieldForPasswordRef = React.useRef(null);
  const buttonForLoginRef = React.useRef(null);
  const [startTime, setStartTime] = React.useState();
  const [termsChecked, setTermsChecked] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [requestBody, setRequestBody] = React.useState({
    deviceType: "web",
    deviceUUID: uuidv4(),
    srcType: "Other",
    password: "",

    username: "",
  });

  const handleTermsChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  useEffect(() => {
    getSession();
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setRequestBody({ ...requestBody, [inputName]: inputValue });
  };

  const handleWrongLogin = (mess) => {
    setError(true);
    setErrorMessage(mess);
    setLogStart(false);
    setOpen(true);
  };

  const handleLoginSucc = async (resp) => {
    console.log("STUDENT", resp.result)
    if (resp.result.userProfile.roleId === 4) {
      window.localStorage.variantId = resp.result.variantId
      window.localStorage.boardId = resp.result.boardId
      window.localStorage.orgImage = resp.result.userProfile.orgImage;
      window.localStorage.name = `${resp.result.userProfile.firstName} ${resp.result.userProfile.lastName === null
        ? ""
        : resp.result.userProfile.lastName
        }`;
      window.localStorage.roleId = resp.result.userProfile.roleId;
      window.localStorage.username = resp.result.userProfile.username;
      window.localStorage.branchId = resp.result.userProfile.branchId;
      window.localStorage.userId = resp.result.userProfile.userId;
      window.localStorage.bloodGroup = resp.result.userProfile.bloodGroupName;
      window.localStorage.bloodGroupId = resp.result.userProfile.bloodGroup;
      window.localStorage.profilePic =
        resp.result.userProfile.userProfilePicture;
      window.localStorage.aim = resp.result.userProfile.aim;
      window.localStorage.uuid = resp.result.sessionDetail.deviceUUID;
      window.localStorage.theme = resp.result.userProfile.themeCode;
      try {
        const MYRESPONSE = await fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            requestList: [
              {
                isEncrypt: false,
                transactionId: "0000001438241244",

                busiCode: "SCHOOL_GET_DATA_BY_USERID_ROLEID",
                busiParams: {
                  roleId: window.localStorage.roleId,
                  userId: window.localStorage.userId,
                },
              },
            ],
            pubInfo: {
              appId: "appId",
              sessionId: window.localStorage.sessionId,
              version: "21",
              timestamp: "20180314175419",
              lang: "en_US",
              userId: "1000016614",
              serviceId: "7021150585",
              circleId: "MU",
              ncsroute: "WE015",
              customerId: "1000016614",
              osType: "windows",
            },
          }),
        });

        const MYDATA = MYRESPONSE.json();

        await MYDATA.then((data) => {
          console.log(data);
          window.localStorage.userData = JSON.stringify(data.respData[0].respMsg.result);
          window.localStorage.periods =
            data.respData[0].respMsg.result.noOfPeriods;
          window.localStorage.studentInstanceId =
            data.respData[0].respMsg.result.studentInstanceId;
          window.localStorage.sectionId =
            data.respData[0].respMsg.result.sectionId;
          window.localStorage.className = resp.result.boardId > 6 ?
            data.respData[0].respMsg.result.jeeClassName : data.respData[0].respMsg.result.className;
          window.localStorage.sectionName =
            data.respData[0].respMsg.result.sectionName;
          window.localStorage.rollNo = data.respData[0].respMsg.result.rollNo;
          window.localStorage.academicYearDesc = data.respData[0].respMsg.result.academicYearDesc;
          window.localStorage.courseName =
            data.respData[0].respMsg.result.boardId > 6 ?
              data.respData[0].respMsg.result.courseName : "";

          console.log("sssss", data)
          data.respData[0].respMsg.result.themeConfigTimings.map(
            (item, index) => {
              let time = item.split(" ");
              time[0] === "DayThemeTime"
                ? (window.localStorage.daytime = time[2])
                : (window.localStorage.nighttime = time[2]);
            }
          );
        });

        const dateTimeFormat = "DD-MM-YYYY HH:mm:ss";

        //if (window.localStorage.theme == "Auto") {
        let daystarttime = `${moment().format("DD-MM-YYYY").toString()} ${window.localStorage.daytime.split("-")[0]
          }`;
        console.log(daystarttime);
        let dayendtime = `${moment().format("DD-MM-YYYY").toString()} ${window.localStorage.daytime.split("-")[1] + ":00"
          }`;
        console.log("the day end time is " + dayendtime);

        let nightstarttime = `${moment().format("DD-MM-YYYY").toString()} ${window.localStorage.nighttime.split("-")[0]
          }`;
        let nightendtime = `${moment()
          .add("1", "days")
          .format("DD-MM-YYYY")
          .toString()} ${window.localStorage.nighttime.split("-")[1]}`;
        let rightnow1 = moment().format("DD-MM-YYYY HH:mm");
        let rightnow = moment(rightnow1);
        console.log(rightnow);
        console.log("tryin to catch time" + moment(rightnow, dateTimeFormat));
        window.localStorage.startTimeForTheme = moment(
          daystarttime,
          dateTimeFormat
        ).unix();
        console.log("checking line call");
        window.localStorage.endTimeForTheme = moment(
          dayendtime,
          dateTimeFormat
        ).unix();

        if (window.localStorage.theme == "Auto") {
          if (
            moment().unix() > window.localStorage.startTimeForTheme &&
            moment().unix() < window.localStorage.endTimeForTheme
          ) {
            console.log("checking line call");
            props.setTheme(ThemeDay);
          } else {
            console.log("night");
            props.setTheme(ThemeNight);
          }
        } else if (window.localStorage.theme == "Night") {
          props.setTheme(ThemeNight);
        } else if (window.localStorage.theme == "Day") {
          props.setTheme(ThemeDay);
        } else if (window.localStorage.theme == "Enrgy") {
          props.setTheme(ThemeEnergy);
        }

        //}

        console.log(window.localStorage);
      } catch (error) {
        console.error(error);
      }

      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_STU_SECSUBMAP_BY_STUINSId",
              busiParams: {
                status: true,
                studentInstanceId: window.localStorage.studentInstanceId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let users = data.respData[0].respMsg.results;
          localStorage.setItem("users", JSON.stringify(users));
          let use = JSON.parse(localStorage.getItem("users") || "[]");
          console.log(use);
        })
        .catch((data) => {
          console.error(data);
        });

      console.log(window.localStorage.tabValue);

      if (resp.result.variantId === 7 && resp.result.boardId > 6) {
        history.push("/ExamMode/eExam");
      } else {
        history.push("/LandingPageStudent/Notification");
      }
      window.localStorage.setItem("lastLocation", window.location.pathname);

      setError(false);
      setLogStart(false);
      setOpen(false);
    } else if (resp.result.userProfile.roleId === 3) {
      localStorage.setItem(
        "classTeacher",
        JSON.stringify(resp.result.classTeacher)
      );
      window.localStorage.variantId = resp.result.variantId
      window.localStorage.teacherOpen = false;
      window.localStorage.theme = resp.result.userProfile.themeCode;
      window.localStorage.orgImage = resp.result.userProfile.orgImage;
      window.localStorage.name = `${resp.result.userProfile.firstName} ${resp.result.userProfile.lastName === null
        ? ""
        : resp.result.userProfile.lastName
        }`;
      window.localStorage.roleId = resp.result.userProfile.roleId;
      window.localStorage.username = resp.result.userProfile.username;
      window.localStorage.departmentId = resp.result.userProfile.departmentId;
      window.localStorage.employeeId = resp.result.userProfile.employeeId;
      window.localStorage.branchId = resp.result.userProfile.branchId;
      window.localStorage.userId = resp.result.userProfile.userId;
      window.localStorage.bloodGroup = resp.result.userProfile.bloodGroupName;
      window.localStorage.bloodGroupId = resp.result.userProfile.bloodGroup;
      window.localStorage.profilePic =
        resp.result.userProfile.userProfilePicture;
      window.localStorage.aim = resp.result.userProfile.aim;
      console.log(resp.result.classTeacher);
      resp.result.classTeacher == null || resp.result.classTeacher == undefined
        ? (window.localStorage.isClassTeacher = false)
        : (window.localStorage.isClassTeacher = true);
      resp.result.subjects == null || resp.result.subjects == undefined
        ? (window.localStorage.isSubjectTeacher = false)
        : (window.localStorage.isSubjectTeacher = true);
      localStorage.setItem("myusers", JSON.stringify(resp.result.subjects));
      localStorage.setItem("users", JSON.stringify(resp.result.subjects));
      localStorage.setItem(
        "classTeacher",
        JSON.stringify(resp.result.classTeacher)
      );
      resp.result.userProfile.themeConfigTimings.map((item, index) => {
        let time = item.split(" ");
        time[0] === "DayThemeTime"
          ? (window.localStorage.daytime = time[2])
          : (window.localStorage.nighttime = time[2]);
      });

      const dateFormat = "DD-MM-YYYY";
      const dateTimeFormat = "DD-MM-YYYY HH:mm:ss";

      //if (window.localStorage.theme == "Auto") {
      let daystarttime = `${moment().format("DD-MM-YYYY").toString()} ${window.localStorage.daytime.split("-")[0]
        }`;
      console.log(daystarttime);
      let dayendtime = `${moment().format("DD-MM-YYYY").toString()} ${window.localStorage.daytime.split("-")[1] + ":00"
        }`;
      console.log("the day end time is " + dayendtime);

      let rightnow1 = moment().format("DD-MM-YYYY HH:mm");
      let rightnow = moment(rightnow1);
      console.log(rightnow);
      console.log("tryin to catch time" + moment(rightnow, dateTimeFormat));
      window.localStorage.startTimeForTheme = moment(
        daystarttime,
        dateTimeFormat
      ).unix();
      console.log("checking line call");
      window.localStorage.endTimeForTheme = moment(
        dayendtime,
        dateTimeFormat
      ).unix();

      if (window.localStorage.theme == "Auto") {
        if (
          moment().unix() > window.localStorage.startTimeForTheme &&
          moment().unix() < window.localStorage.endTimeForTheme
        ) {
          console.log("checking line call");
          props.setTheme(ThemeDay);
        } else {
          console.log("night");
          props.setTheme(ThemeNight);
        }
      } else if (window.localStorage.theme == "Night") {
        props.setTheme(ThemeNight);
      } else if (window.localStorage.theme == "Day") {
        props.setTheme(ThemeDay);
      } else if (window.localStorage.theme == "Energy") {
        props.setTheme(ThemeEnergy);
      }
      if (resp.result.variantId === 7) {
        history.push("/ExamMode/eExam");
      } else {
        history.push("/LandingPageStudent/Notification");
      }


      window.localStorage.setItem("lastLocation", window.location.pathname);

      setError(false);
      setLogStart(false);
      setOpen(false);
    } else if (resp.result.userProfile.roleId === 5) {
      // setRestricted(true);
      window.localStorage.siblingOpen = false;
      window.localStorage.variantId = resp.result.variantId
      window.localStorage.theme = resp.result.userProfile.themeCode;
      window.localStorage.username = resp.result.userProfile.username;
      window.localStorage.roleId = resp.result.userProfile.roleId;
      window.localStorage.parentUserId = resp.result.userProfile.userId;
      await localStorage.setItem(
        "childrenProfile",
        JSON.stringify(resp.result.userProfile.childsUserProfile)
      );

      resp.result.userProfile.themeConfigTimings.map((item, index) => {
        let time = item.split(" ");
        time[0] === "DayThemeTime"
          ? (window.localStorage.daytime = time[2])
          : (window.localStorage.nighttime = time[2]);
      });

      //console.log("Day Time"+window.localStorage.daytime);
      //console.log("night Time"+window.localStorage.nighttime);
      const dateFormat = "DD-MM-YYYY";
      const dateTimeFormat = "DD-MM-YYYY HH:mm:ss";

      //if (window.localStorage.theme == "Auto") {
      let daystarttime = `${moment().format("DD-MM-YYYY").toString()} ${window.localStorage.daytime.split("-")[0]
        }`;
      console.log(daystarttime);
      let dayendtime = `${moment().format("DD-MM-YYYY").toString()} ${window.localStorage.daytime.split("-")[1] + ":00"
        }`;
      console.log("the day end time is " + dayendtime);

      let nightstarttime = `${moment().format("DD-MM-YYYY").toString()} ${window.localStorage.nighttime.split("-")[0]
        }`;
      let nightendtime = `${moment()
        .add("1", "days")
        .format("DD-MM-YYYY")
        .toString()} ${window.localStorage.nighttime.split("-")[1]}`;
      let rightnow1 = moment().format("DD-MM-YYYY HH:mm");
      let rightnow = moment(rightnow1);
      console.log(rightnow);
      console.log("tryin to catch time" + moment(rightnow, dateTimeFormat));
      window.localStorage.startTimeForTheme = moment(
        daystarttime,
        dateTimeFormat
      ).unix();
      console.log("checking line call");
      window.localStorage.endTimeForTheme = moment(
        dayendtime,
        dateTimeFormat
      ).unix();

      if (window.localStorage.theme == "Auto") {
        if (
          moment().unix() > window.localStorage.startTimeForTheme &&
          moment().unix() < window.localStorage.endTimeForTheme
        ) {
          console.log("checking line call");
          props.setTheme(ThemeDay);
        } else {
          console.log("night");
          props.setTheme(ThemeNight);
        }
      } else if (window.localStorage.theme == "Night") {
        props.setTheme(ThemeNight);
      } else if (window.localStorage.theme == "Day") {
        props.setTheme(ThemeDay);
      } else if (window.localStorage.theme == "Energy") {
        props.setTheme(ThemeEnergy);
      }

      if (resp.result.variantId === 7) {
        history.push("/ExamMode/eExam");
      } else {
        history.push("/LandingPageStudent/Notification");
      }

      console.log(resp);

      setLogStart(false);
    } else {
      setRestricted(true);
    }
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const login = async () => {
    try {
      if (requestBody.username === "" || requestBody.password === "") {
        setEmptyError(true);
        setError(true);
      } else {
        setLogStart(true);
        window.localStorage.teacherOpen = false;
        const DATA = await fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            requestList: [
              {
                isEncrypt: false,
                transactionId: "0000001438241244",
                busiCode: "UAM_SIGN_IN",
                busiParams: requestBody,
              },
            ],
            pubInfo: {
              appId: "appId",
              sessionId: window.localStorage.sessionId,
              version: "21",
              timestamp: "20180314175419",
              lang: "en_US",
              userId: "1000016614",
              serviceId: "7021150585",
              circleId: "MU",
              ncsroute: "WE015",
              customerId: "1000016614",
              osType: "windows",
            },
          }),
        }).catch((data) => {
          console.log("sorry something went wrong");
          // setError(true);

        });
        const RESPONSE = await DATA.json();



        RESPONSE.respData[0].respMsg.status !== "Failure"
          ? await handleLoginSucc(RESPONSE.respData[0].respMsg)
          : await handleWrongLogin(RESPONSE.respData[0].respMsg.message);
        console.log(error);

        //.respData[0].respMsg==='SignIn successfully'?
        //history.push(`/LandingPageStudent`): history.push(`/`)
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const handleEnter = (event) => {
  //   if (event.keyCode === 13) {
  //     const form = event.target.form;
  //     console.log("the form is" + form);
  //     const index = Array.prototype.indexOf.call(form, event.target);
  //     form.elements[index + 1].focus();
  //     event.preventDefault();
  //   }
  // };

  // function MyInput(props) {
  //   return <input onKeyDown={handleEnter} {...props} />;
  // }

  return (
    <React.Fragment>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        className={classes.mainContainer}
      >
        <Grid item container direction="column" alignItems="center">
          <Grid
            item
            container
            direction="column"
            justify="center"
            className={classes.paperLogin}
          >
            <Paper
              style={{ marginTop: "auto", marginBottom: "auto", padding: "2em" }}
            >
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justify="center"
                spacing={1}
              >
                <Grid item>
                  <img alt=" " src={logo} className={classes.logo}></img>
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <Typography className={classes.label} variant="subtitle1">
                      Login ID
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      autoFocus={true}
                      inputRef={textFieldForUsernameRef}
                      error={error}
                      className={classes.formControl}
                      id="input-with-icon-textfield"
                      name="username"
                      value={requestBody.username}
                      placeholder="Username"
                      variant="outlined"
                      size="small"
                      onChange={handleInputChange}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       <img
                      //         src={loginicon}
                      //         className={classes.inputIcons}
                      //         alt="loginicon"
                      //       />
                      //     </InputAdornment>
                      //   ),
                      // }}
                      InputProps={{
                        onKeyPress: (event) => {
                          const { key } = event;

                          if (key === "Enter") {
                            textFieldForPasswordRef.current.focus();
                          }
                        },

                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={loginicon}
                              className={classes.inputIcons}
                              alt="loginicon"
                            />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <Typography className={classes.label} variant="subtitle1">
                      Password
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      inputRef={textFieldForPasswordRef}
                      error={error}
                      className={classes.formControl}
                      id="input-with-icon-textfield"
                      name="password"
                      value={requestBody.password}
                      onChange={handleInputChange}
                      placeholder="Password"
                      variant="outlined"
                      size="small"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        onKeyPress: (event) => {
                          const { key } = event;

                          if (key === "Enter") {
                            termsChecked === true
                              ? buttonForLoginRef.current.click()
                              : console.log("no need to click button");
                          }
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={passicon}
                              className={classes.inputIcons}
                              alt="passIcon"
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item container style={{ marginTop: "1em" }}>
                  <Checkbox
                    checked={termsChecked}
                    onChange={handleTermsChange}
                    style={{ color: "#3f51b5" }}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <Typography variant="body1">
                    By clicking on login, you agree to the
                    <br />
                    <span
                      onClick={() => history.push("/Terms")}
                      style={{
                        color: "#3f51b5",
                        cursor: "pointer",
                        textDecoration: "underline",
                        marginLeft: "2.5em",
                      }}
                    >
                      Terms & Conditions.
                    </span>
                  </Typography>
                </Grid>
                <Grid item container>
                  <Button
                    buttonRef={buttonForLoginRef}
                    className={classes.loginBtn}
                    variant="contained"
                    component={Link}
                    color="default"
                    disabled={termsChecked === false}
                    fullWidth
                    onClick={login}
                  >
                    {logStart ? <CircularProgress /> : `Login`}
                  </Button>
                </Grid>
                <Grid item container direction="row">
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justify="flex-start"
                    sm
                  >
                    {
                      //  <Grid item>
                      //     <Switch className={classes.switchBtn} />
                      //   </Grid>
                      //   <Grid item>
                      //     <Typography variant="subtitle1">Remember me</Typography>
                      //   </Grid>
                    }
                  </Grid>

                  <Grid item container justify="flex-end" sm>
                    <Button
                      classes={{ root: classes.textPrimaryEdit }}
                      variant="text"
                      component={Link}
                      to="/ForgotPassword"
                      disableRipple
                      className={classes.button}
                    >
                      Forgot password?
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              direction="row"
              spacing={1}
              style={{ paddingTop: "1em", paddingBottom: "1em", color: "white" }}
            >
              <Grid item>
                <Typography
                  variant="caption"
                  style={{ color: "white" }}
                >{`Powered By `}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">Projectheen</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" justify="space-evenly">
              <Grid item>
                <IconButton
                  disableRipple
                  target="_blank"
                  component={myLink}
                  href="https://play.google.com/store/apps/details?id=com.projectheen.trunkie"
                >
                  <img
                    alt=""
                    src={component}
                    className={classes.downloadBtn}
                  ></img>
                </IconButton>
              </Grid>
              <Grid item>
                <Suspense fallback={<h1>Loading profile...</h1>}>
                  <IconButton
                    disableRipple
                    target="_blank"
                    component={myLink}
                    href="https://apps.apple.com/in/app/trunkie/id1518987239"
                  >
                    <img alt="" src={ios} className={classes.downloadBtn}></img>
                  </IconButton>
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={emptyError}
        autoHideDuration={6000}
        onClose={() => setEmptyError(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setEmptyError(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="warning">Enter Username And Password !!</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={props.openForget}
        autoHideDuration={6000}
        onClose={props.handleCloseForget}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="success">
          Email Sent Successfully Please Check Email For Reset Link!!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={restricted}
        autoHideDuration={6000}
        onClose={() => setRestricted(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setRestricted(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="warning">This user is restricted</Alert>
      </Snackbar>

    </React.Fragment>
  );
}
